<template>
  <a-spin
    :tip="tip"
    :spinning="spinning"
  >
    <a-card
      :bordered="false"
      id="medical_info"
      class="header-solid mb-24"
    >
      <template #title>
        <h5 class="mb-0 font-semibold">病史及用药信息</h5>
      </template>

      <!-- <a-button
      slot="extra"
      type="primary"
      icon="file-search"
      class="px-10 py-5 "
      @click="clickPrescriptionBtn"
    >查看历史记录</a-button> -->

      <form-create
        v-model="cformMedicalApi"
        :rule="cformMedicalRule"
        :option="cformAppointOption"
        @submit="saveMedicalRequest"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="11"
          :span="12"
        >
          <a-button
            @click="cformMedicalApi.submit()"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { savePatientMedicalInfo, getPatientMedicalInfo } from '@/api/dms/patient'

export default ({
  // 客户病史及用药信息card
  name: 'MedicalInfo',
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    cformAppointOption: {
      type: Object,
      default: { // 一列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      }
    }
  },
  data () {
    return {
      cformMedicalApi: {},
      cformMedicalRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '疾病信息',
          field: 'disease',
          props: { mode: 'multiple', placeholder: '请选择疾病信息', maxTagCount: 5, maxTagTextLength: 10, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择疾病信息' }
          ]
        },
        {
          type: 'input',
          title: '其他疾病及备注',
          field: 'disease_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他疾病信息及备注' }
        },
        {
          type: 'input',
          title: '服药信息',
          field: 'medication',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写服药信息' }
        },
        {
          type: 'input',
          title: '个人病史',
          field: 'personal_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写个人病史' }
        },
        {
          type: 'input',
          title: '家族病史',
          field: 'family_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写家族病史' }
        },
        {
          type: 'input',
          title: '其他备注说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注说明' }
        }
      ],
      spinning: false,
      tip: '处理中...',
      pparms: {}
    }
  },
  created () {
    this.$nextTick(() => {
      this.cformMedicalApi.setValue({ patient_id: this.parentParams.patient })
      this.cformMedicalApi.updateRules({ // 更新规则
        'disease': { options: this.$options.filters.selectOptionsData('patient.disease') }
      })
    })
    // 每次都是新增问诊记录了，不需要在回显
    // this.loadPatientOriInfo()
  },
  methods: {
    loadPatientOriInfo () {
      // 调用之前单独触发是否显示spinning，请求完成后统一取消
      this.tip = '加载中...'
      getPatientMedicalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformMedicalApi.setValue({ id: res.data.id, disease: JSON.parse(res.data.disease), disease_remarks: res.data.disease_remarks, medication: res.data.medication, personal_history: res.data.personal_history, family_history: res.data.family_history, other_remarks: res.data.other_remarks })
        }
        this.spinning = false
      })
    },
    saveMedicalRequest (data, formObj) {
      if (this.parentParams.serviceRecord < 1) {
        this.$message.error('请先保存客户预约目的及期望相关信息！')
        return false
      }
      this.tip = '处理中...'
      this.spinning = true
      const that = this
      savePatientMedicalInfo(Object.assign({ service_record_id: this.parentParams.serviceRecord }, data)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '病史及用药信息保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              that.spinning = false
              return that.$router.go(-1)
            },
            onCancel () {
              that.loadPatientOriInfo()
              return false
            }
          })
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    },
  },
})

</script>