<template>
  <div>

    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        :bodyStyle="cardBodyStyle"
      >
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="4"
                  :sm="24"
                >
                  <a-form-item label="所属">
                    <a-select
                      v-model="queryParam.is_open"
                      :allowClear="true"
                      placeholder="全部"
                      style="width:100%;"
                    >
                      <a-select-option value="1">
                        公开
                      </a-select-option> <a-select-option value="0">
                        私有
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="菜谱名称"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.foodRecipeDefListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = { is_open: '1' })"
                    >重置</a-button>

                    <router-link
                      v-if="sourceMark !== 'choose'"
                      :to="'/DailyFoodMenus/addDailyFoodMenu'"
                    >
                      <a-button
                        style="margin-left: 8px"
                        type="danger"
                        icon="plus"
                      >新增</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="foodRecipeDefListTable"
          :size="tableSize"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >

          <div
            slot="img_show"
            slot-scope="text, record"
            style="max-width:25px;height:25px;cursor:pointer;"
          >
            <img
              v-if="record.img_show !== undefined && record.img_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.img_show"
              @click="handlePreview(record.img_show)"
            />
          </div>

          <span
            slot="daily_food_menu_tag"
            slot-scope="text,record"
          >
            <a-tag
              color="green"
              v-for="(item,idx) in record.daily_food_menu_tag"
              :key="idx"
            >
              {{ item.tag_text }}
            </a-tag>
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a @click="clickView(record)">详情</a>

              <a-divider type="vertical" />

              <a
                v-if="sourceMark === 'choose'"
                @click="clickAddBtn(record)"
              >添加</a>
              <a
                v-if="sourceMark === null"
                @click="clickEdit(record)"
              >编辑</a>

            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

    <a-modal
      v-model="modalVisibleView"
      title="食谱详情"
      :width="1280"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button
          type="primary"
          @click="handleOkView"
        >
          关闭
        </a-button>
      </template>

      <DailyFoodMenuView :dailyFoodMenuId="dailyFoodMenu" />
    </a-modal>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="货品主图"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>

  </div>
</template>

<script>
import { listDailyFoodMenu } from '@/api/dms/dailyFoodMenu'
import DailyFoodMenuView from './DailyFoodMenuView'

export default {
  name: 'DailyFoodMenuList',
  components: {
    DailyFoodMenuView
  },
  data () {
    return {
      spinning: false,
      defaultColumns: [
        {
          title: 'ID',
          width: 55,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'img_show',
          scopedSlots: { customRender: 'img_show' }
        },
        {
          title: '菜单名称',
          width: 160,
          dataIndex: 'menu_name',
          ellipsis: true
        },
        {
          title: '介绍',
          width: 260,
          dataIndex: 'menu_intro',
          ellipsis: false
        },
        {
          title: '热量',
          width: 100,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 100,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 100,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 100,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '标签',
          ellipsis: true,
          width: 300,
          dataIndex: 'daily_food_menu_tag',
          scopedSlots: { customRender: 'daily_food_menu_tag' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 120,
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      chooseColumns: [
        {
          title: '图片',
          ellipsis: true,
          width: 60,
          dataIndex: 'img_show',
          scopedSlots: { customRender: 'img_show' }
        },
        {
          title: '介绍',
          width: 300,
          dataIndex: 'menu_intro',
          ellipsis: false
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '标签',
          ellipsis: true,
          width: 150,
          dataIndex: 'daily_food_menu_tag',
          scopedSlots: { customRender: 'daily_food_menu_tag' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 120,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columns: [],
      tableScroll: { x: 1200 },
      pageSize: 10,
      // 查询参数is_open: "1"
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ create_user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['tags', 'recipe'] }, parameter, this.queryParam)
        return listDailyFoodMenu(requestParameters)
          .then(res => {
            // console.log(res)
            return res
          })
      },
      modalVisibleView: false,
      dailyFoodMenu: null,
      previewVisible: false,
      previewImg: '',
      cardBodyStyle: { padding: 0, paddingTop: '16px' },
    }
  },
  props: {
    tableSize: {
      type: String,
      default: 'default'
    },
    sourceMark: {
      type: String,
      default: null
    }
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {
    if (this.sourceMark === 'choose') {
      this.columns = this.chooseColumns
      this.cardBodyStyle = { padding: 0, paddingTop: '16px' }
    } else {
      this.columns = this.defaultColumns
      this.cardBodyStyle = { padding: '16px' }
    }
  },
  methods: {
    clickView (record) {
      console.log(record)
      this.dailyFoodMenu = record.id
      this.modalVisibleView = true
    },
    handleOkView () {
      this.modalVisibleView = false
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    clickEdit (record) {
      sessionStorage.setItem('DailyFoodMenus.addDailyFoodMenu', JSON.stringify({ fm: record.id }))
      this.$router.push({ path: '/DailyFoodMenus/addDailyFoodMenu' })
    },
    clickAddBtn (record) {
      this.$emit('sureChooseOneDayFood', record)
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
