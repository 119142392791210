<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <a-col
                  :md="12"
                  :sm="24"
                >
                  <a-form-item label="问卷标签">
                    <a-select
                      showSearch
                      v-model="queryParam.tag"
                      mode="multiple"
                      :maxTagCount="5"
                      placeholder="无"
                      :options="surveyTag"
                      :filter-option="surveyFilterOption"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入问卷标题搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.listSurveyTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="listSurveyTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="process_status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'survey.process_status' | showText(text)"
            />
          </span>
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'survey.status' | showText(text)"
            />
          </span>
          <span
            slot="misc_survey_tag"
            slot-scope="tags"
          >
            <a-tag
              color="orange"
              v-for="tag in tags"
              :key="tag.tag_id"
            >
              {{tag.tag_text}}
            </a-tag>
          </span>
          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span>
                <a @click="clickView(record)">预览</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="'/MiscSurveys/editSurvey' | isShowButton"
              />
              <a
                :hidden="'/MiscSurveys/editSurvey' | isShowButton"
                @click="clickEdit(record)"
              >编辑</a>
              <a-divider type="vertical" />
              <a @click="clickShare(record)">分享</a>
            </template>
          </span>
        </s-table>

        <!-- 问卷查看的抽屉窗口 -->
        <survey-view
          :parentParams="surveyViewParam"
          :surveyViewDrawerVisible="surveyViewDrawerVisible"
          @surveyViewDrawerVisibleChange="surveyViewDrawerVisibleChange"
        />

        <!-- 问卷编辑抽屉窗口 -->
        <a-drawer
          width="650"
          title="调查问卷信息编辑"
          :maskClosable="false"
          placement="right"
          :closable="true"
          :visible="editDrawerVisible"
          @close="closeEditDrawer"
          id="drawer-body-div"
        >
          <a-spin
            tip="处理中..."
            size="large"
            :spinning="editDrawerSpinning"
          >
            <div>
              <form-create
                v-model="cformEditApi"
                :rule="cformEditRule"
                :option="cformEditOption"
              ></form-create>

              <a-row>
                <a-col
                  :offset="9"
                  :span="15"
                >
                  <a-button
                    @click="closeEditDrawer"
                    type="default"
                    class="mr-5"
                  >关闭</a-button>
                  <a-button
                    @click="submitEditBtnClick"
                    type="primary"
                  >确认保存</a-button>
                </a-col>
              </a-row>
            </div>
          </a-spin>
        </a-drawer>

        <!-- 问卷分享二维码弹窗 -->
        <survey-share
          :parentParams="surveyShareParam"
          :surveyShareVisible="surveyShareVisible"
          @surveyShareVisibleChange="surveyShareVisibleChange"
        />
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listSurveyData, saveSurveyEdit } from '@/api/dms/survey'
import { SurveyView, SurveyShare } from '@/components/Surveys'
import { rmFile, getSelectListTag } from '@/api/manage'

export default {
  name: 'ListSurvey',
  components: { SurveyView, SurveyShare },
  data () {
    return {
      spinning: false,
      surveyViewDrawerVisible: false,
      surveyViewParam: {},
      columns: [
        {
          title: 'ID',
          width: 65,
          dataIndex: 'id'
        },
        {
          title: '问卷标题',
          // width: 180,
          ellipsis: true,
          dataIndex: 'title'
        },
        {
          title: '问卷标签',
          // width: 180,
          ellipsis: true,
          dataIndex: 'misc_survey_tag',
          scopedSlots: { customRender: 'misc_survey_tag' }
        },
        {
          title: '问卷总数',
          ellipsis: true,
          width: 70,
          align: 'center',
          dataIndex: 'total_count'
        },
        {
          title: '已填写数',
          ellipsis: true,
          width: 70,
          align: 'center',
          dataIndex: 'response_count'
        },
        {
          title: '结束时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'end_time'
        },
        {
          title: '问卷进展',
          width: 75,
          ellipsis: true,
          dataIndex: 'process_status',
          scopedSlots: { customRender: 'process_status' }
        },
        {
          title: '当前状态',
          width: 70,
          ellipsis: true,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '是否匿名',
          width: 70,
          align: 'center',
          dataIndex: 'is_anonymous',
          customRender: (text) => {
            return text > 0 ? '是' : '否'
          }
        },
        {
          title: '用户唯一',
          width: 70,
          align: 'center',
          dataIndex: 'is_user_unique',
          customRender: (text) => {
            return text > 0 ? '是' : '否'
          }
        },
        {
          title: 'IP唯一',
          width: 70,
          align: 'center',
          dataIndex: 'is_ip_unique',
          customRender: (text) => {
            return text > 0 ? '是' : '否'
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 145,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ create_user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['surveyTag'] }, parameter, this.queryParam)
        return listSurveyData(requestParameters)
          .then(res => {
            console.log(res)
            return res
          })
      },
      editDrawerVisible: false,
      editDrawerSpinning: false,
      //实例对象
      cformEditApi: {},
      //表单生成规则
      cformEditRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user_id',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '问卷标题',
          field: 'title',
          props: { placeholder: '请填写问卷标题' },
          validate: [
            { required: true, message: '请确认输入问卷标题', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '问卷总数',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          field: 'total_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写问卷总数', min: 0, step: 1, max: 99999999, addonAfter: '份' },
          validate: [
            { required: true, message: '请填写问卷总数' }
          ]
        },
        {
          type: 'DatePicker',
          title: '结束时间',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          field: 'end_time', style: { width: '100%' },
          props: { placeholder: '请选择问卷结束时间', type: 'date', showTime: true, format: 'YYYY-MM-DD HH:mm:ss' },
          validate: [
            { required: true, message: '请选择问卷结束时间' }
          ]
        },
        {
          type: 'select',
          title: '进展状态',
          field: 'process_status',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请选择问卷进展状态' },
          options: [],
          validate: [
            { required: true, message: '请选择问卷进展状态' }
          ]
        },
        {
          type: 'a-radio-group',
          title: '是否匿名',
          value: '1',
          field: 'is_anonymous',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { buttonStyle: 'solid', placeholder: '请选择是否匿名' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否匿名', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '用户唯一',
          value: '0',
          field: 'is_user_unique',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { buttonStyle: 'solid', placeholder: '请选择是否用户唯一' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否用户唯一', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: 'IP唯一',
          value: '0',
          field: 'is_ip_unique',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { buttonStyle: 'solid', placeholder: '请选择是否IP唯一' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否IP唯一', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        },
        {
          type: 'upload',
          field: 'bg_img_url',
          title: '背景图',
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/survey_bg' },
            limit: 1,
            listType: 'picture-card'
          },
        }
      ],
      //组件参数配置
      cformEditOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 20 }
        },
        submitBtn: false
      },
      bgImgUrl: '',
      surveyShareParam: {},
      surveyShareVisible: false,
      surveyTag: []
    }
  },
  created () {
    this.spinning = true

    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['0', '1', '10', '20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.surveyTag = res.data
      }

      this.spinning = false
    })
  },
  filters: {
    statusTypeFilter (status) {
      return status.toString() === '10' ? 'success' : 'error'
    }
  },
  methods: {
    clickEdit (record) {
      this.editDrawerVisible = true
      this.$nextTick(() => {
        this.cformEditApi.updateRules({ 'process_status': { options: this.$options.filters.selectOptionsData('survey.process_status') } })
        let setValObj = { id: record.id, title: record.title, total_count: record.total_count, end_time: record.end_time, bg_img_url: record.bg_img_url, is_anonymous: record.is_anonymous.toString(), is_user_unique: record.is_user_unique.toString(), is_ip_unique: record.is_ip_unique.toString(), process_status: record.process_status.toString(), remarks: record.remarks }
        this.bgImgUrl = record.bg_img_url
        if (record.bg_img_url_show !== undefined && record.bg_img_url_show !== '' && record.bg_img_url_show !== null) {
          setValObj.bg_img_url = record.bg_img_url_show
        }
        this.cformEditApi.setValue(setValObj)
      })
    },
    clickShare (record) {
      this.surveyShareParam = record
      this.surveyShareVisible = true
    },
    clickView (record) {
      this.surveyViewParam = record
      this.surveyViewDrawerVisible = true
    },
    surveyViewDrawerVisibleChange (v) {
      this.surveyViewDrawerVisible = v
    },
    closeEditDrawer () {
      this.editDrawerVisible = false
      this.$refs.listSurveyTable.refresh(true)
    },
    submitEditBtnClick () {
      this.cformEditApi.validate((valid2, fail2) => {
        if (valid2) {
          this.editDrawerSpinning = true
          let cuFormData = this.cformEditApi.formData()
          cuFormData.bg_img_url = this.bgImgUrl
          saveSurveyEdit(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformEditApi.resetFields()
              this.bgImgUrl = ''
              this.closeEditDrawer()
              this.$message.success('调查问卷发布成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.editDrawerSpinning = false
          })
        } else {
          console.log(fail2)
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.bgImgUrl = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.bgImgUrl }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.bgImgUrl = ''
          this.cformEditApi.setValue({ bg_img_url: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    surveyShareVisibleChange (v) {
      this.surveyShareVisible = v
    },
    surveyFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].text !== undefined) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
  }
}

</script>

<style lang="scss" scoped>
</style>