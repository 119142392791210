<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>营养处方</span> -->
          <br>
          <!-- <span>Nutrition Prescription</span> -->
        </div>
        <div>
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div class="nuContentWithSider">
        <table class="analysisTable fontNuBase">
          <tbody>
            <template v-if="nutritionInfo.life_id !== null && nutritionInfo.life_id > 0">
              <tr class="thead_tr">
                <th colspan="4">
                  生活干预、运动计划
                </th>
              </tr>
              <tr>
                <td class="analysisTabletd1">
                  生活干预
                </td>
                <td colspan="3">{{ nutritionInfo.life_plan }}</td>
              </tr>
              <tr>
                <td class="analysisTabletd1">
                  运动计划
                </td>
                <td colspan="3">{{ nutritionInfo.sport_plan }}</td>
              </tr>
            </template>

            <template v-if="nutritionInfo.prescription_sport.length > 0">
              <tr>
                <th colspan="4">
                </th>
              </tr>
              <tr class="thead_tr">
                <th colspan="4">
                  运动内容推荐
                </th>
              </tr>
              <template v-for="(spv,spi) in nutritionInfo.prescription_sport">
                <tr :key="'t1_'+spi">
                  <td class="analysisTabletd1 height100">
                    运动方式#{{ spi+1 }}
                  </td>
                  <td class="height100">{{ $options.filters.showText('patient.activity_type',spv.sport_type) }}</td>
                  <td class="analysisTabletd1 height100">
                    运动时间#{{ spi+1 }}
                  </td>
                  <td class="height100">{{ spv.duration }}</td>
                </tr>
                <tr :key="'t2_'+spi">
                  <td class="analysisTabletd1 height100">
                    运动频率#{{ spi+1 }}
                  </td>
                  <td class="height100">{{ spv.frequency }}</td>
                  <td class="analysisTabletd1 height100">
                    运动举例#{{ spi+1 }}
                  </td>
                  <td class="height100">{{ spv.example }}</td>
                </tr>
                <tr :key="'t3_'+spi">
                  <td class="analysisTabletd1">
                    运动方案#{{ spi+1 }}
                  </td>
                  <td colspan="3">{{ spv.plan_content }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div class="nuContentRightSider">
        <div class="nuContentRightSiderText2">
          <div class="fontNuBase">营养处方</div>
          <div class="fontNuS4">Nutrition Prescription</div>
        </div>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import NutritionFooter from './NutritionFooter'

export default {
  name: 'NutritionPage6',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    nutritionInfo: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nuContentRightSiderText2 {
  top: 650px;
}
</style>
