import request from '@/utils/request'

const apiUrls = {
  getScheduleDefList: '/HealthServiceScheduleDefs/getScheduleDefList',
  addScheduleDef: '/HealthServiceScheduleDefs/addScheduleDef',
  getScheduleDefInfo: '/HealthServiceScheduleDefs/getScheduleDefInfo',
  updateScheduleDef: '/HealthServiceScheduleDefs/updateScheduleDef'
}

export function getScheduleDefList (parameter) {
  return request({
    url: apiUrls.getScheduleDefList,
    method: 'get',
    params: parameter
  })
}

export function addScheduleDef (data) {
  return request({
    url: apiUrls.addScheduleDef,
    method: 'post',
    data
  })
}

export function getScheduleDefInfo (parameter) {
  return request({
    url: apiUrls.getScheduleDefInfo,
    method: 'get',
    params: parameter
  })
}

export function updateScheduleDef (data) {
  return request({
    url: apiUrls.updateScheduleDef,
    method: 'post',
    data
  })
}
