// VueJS
import Vue from 'vue'

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
Vue.use(Antd);

// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

// Template Layouts
// import DefaultLayout from './layouts/Default.vue'
// import DashboardLayout from './layouts/Dashboard.vue'
// import DashboardRTLLayout from './layouts/DashboardRTL.vue'
// import UserLayout from './layouts/UserLayout.vue'

// Adding template layouts to the vue components.
// Vue.component("layout-default", DefaultLayout);
// Vue.component("layout-dashboard", DashboardLayout);
// Vue.component("layout-dashboard-rtl", DashboardRTLLayout);
// Vue.component("layout-user233", UserLayout);

// Main application view
import App from './App.vue'

import store from './store/'
import VueCookies from 'vue-cookies'
import bootstrap from './core/bootstrap'
import formCreate from '@form-create/ant-design-vue' // 全局引入form-create表单生成组件
Vue.use(VueCookies)
Vue.use(formCreate)
// Vue Router
import router from './router'
import './permission' // 权限控制
import './utils/filter' // 过滤器
import STable from '@/components/Table' //全局引入sTable组件
Vue.component('STable',STable)

// App Styling
import './scss/app.scss';

Vue.config.productionTip = false

// -------注册远程js使用的注册组件（目前问诊coze那个ai在用） 必须在new Vue之前------
Vue.component('remote-js', {
  props: {
    src: {
      type: String,
      required: true
    }
  },
  render: function (createElement) {
    var self = this
    return createElement('script', {
      attrs: {
        type: 'text/javascript',
        src: this.src
      },
      on: {
        load: function (event) {
          self.$emit('load', event)
        },
        error: function (event) {
          self.$emit('error', event)
        },
        readystatechange: function (event) {
          if (this.readyState === 'complete') {
            self.$emit('load', event)
          }
        }
      }
    })
  },
})
// -------------------------------

// Initialize Vue
new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')