<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="商品类型">
                    <a-tree-select
                      show-search
                      style="width: 100%"
                      v-model="queryParam.goods_type"
                      :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                      placeholder="请选择商品类型"
                      allow-clear
                      :filterTreeNode="filterTreeNode"
                      :tree-data="typeListData"
                      treeDataSimpleMode
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入规格名称搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.listGoodsSpecDefsTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = { })"
                    >重置</a-button>
                    <router-link
                      :to="'/EmallGoods/addGoodsSpecDefs'"
                      :hidden="'/EmallGoods/addGoodsSpecDefs' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增规格</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="listGoodsSpecDefsTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="spec_val"
            slot-scope="text"
            :title="showValTitle(text)"
          >
            <span v-if="text !== undefined && text.length !== undefined && text.length > 0">
              <a-badge
                style="margin-right:5px;"
                v-for="(tv,i1) in text"
                :key="i1"
                status="success"
                :text="tv.spec_val"
              />
            </span>
          </span>

          <span
            slot="disp_type"
            slot-scope="text"
          >
            {{ 'emall_goods_spec.disp_type' | showText(text) }}
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EmallGoods/editGoodsSpecDefs' | isShowButton">
                <a @click="clickEdit(record)">编辑</a>
              </span>
              <!-- <a-divider
                type="vertical"
                :hidden="['/EmallGoods/deleteGoodsSpecDefs'] | isShowSeparator(['/EmallGoods/editGoodsSpecDefs'])"
              />
              <span :hidden="'/EmallGoods/deleteGoodsSpecDefs' | isShowButton">
                <a @click="clickDel(record)">删除</a>
              </span> -->
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEmallGoodsSpecDef } from '@/api/dms/emallGoodSpec'
import { selectListTypeByTt } from '@/api/dms/goodsType'

export default {
  name: 'EmallGoodsSpecDefList',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      typeListData: [],
      columns: [
        {
          title: '#',
          width: 80,
          dataIndex: 'id'
        },
        {
          title: '规格名称',
          width: 150,
          dataIndex: 'spec_name'
        },
        {
          title: '商品类型',
          ellipsis: true,
          width: 110,
          dataIndex: 'type_name'
        },
        {
          title: '规格值',
          ellipsis: true,
          dataIndex: 'emall_goods_spec_val',
          scopedSlots: { customRender: 'spec_val' }
        },
        {
          title: '展示类型',
          ellipsis: true,
          width: 95,
          dataIndex: 'disp_type',
          scopedSlots: { customRender: 'disp_type' }
        },
        {
          title: '排序号',
          ellipsis: true,
          width: 80,
          dataIndex: 'sort'
        },
        {
          title: '更新时间',
          width: 160,
          ellipsis: true,
          dataIndex: 'update_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return listEmallGoodsSpecDef(requestParameters)
          .then(res => {
            return res
          })
      }
    }
  },
  created () {
    this.spinning = true
    selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.typeListData = res.data
      }
      this.spinning = false
      this.tip = '处理中...'
    })
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('EmallGoods.editGoodsSpecDefs', JSON.stringify({ def: record.id }))
      this.$router.push({ path: '/EmallGoods/editGoodsSpecDefs' })
    },
    clickDel (record) {
      // 删除
      // sessionStorage.setItem('EmallGoods.editShops', JSON.stringify({ shop: record.id }))
      // this.$router.push({ path: '/EmallGoods/editGoodsSpecDefs' })
    },
    showValTitle (vals) {
      const showVal = []
      if (vals.length !== undefined && vals.length > 0) {
        vals.forEach(vv => {
          showVal.push(vv.spec_val)
        })
      }
      return showVal.join(',')
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-badge-status-text {
  margin-left: 3px !important;
}
</style>
