<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">

    <a-row
      type="flex"
      :gutter="[24,24]"
      justify="space-around"
      align="middle"
    >

      <!-- Sign In Form Column -->
      <a-col
        :span="24"
        :md="12"
        :lg="{span: 12, offset: 0}"
        :xl="{span: 6, offset: 2}"
        class="col-form"
      >
        <h2 class="mb-15">{{ mainIntro }}</h2>
        <h5 class="font-regular text-muted">{{ slogan }}</h5>
        <a-alert
          v-if="isLoginError"
          :type="errorOrsuccess"
          showIcon
          style="margin-bottom: 24px;"
          :message="loginErrorMsg"
        />
        <!-- Sign In Form -->
        <a-form
          :form="loginForm"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-form-item
            class="mb-10"
            label=""
            :colon="false"
          >
            <a-input
              v-decorator="[
						'user_name',
						{ rules: [{ required: true, message: '请确认输入账号！' }] },
						]"
              placeholder="账号"
            />
          </a-form-item>
          <a-form-item
            class="mb-5"
            :label="false"
            :colon="false"
          >
            <a-input
              v-decorator="[
						'user_passwd',
						{ rules: [{ required: true, message: '请确认输入密码！' }] },
						]"
              type="password"
              placeholder="密码"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              :loading="loginBtn"
              class="login-form-button"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->
        <p class="font-semibold text-muted text-right">营养师入驻？<router-link
            to="/user/signUp"
            class="font-bold text-dark"
          >点击注册</router-link></p>
      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col
        :span="24"
        :md="12"
        :lg="12"
        :xl="12"
        class="col-img"
      >
        <img
          src="/images/img-signin.png"
          alt=""
        >
      </a-col>
      <!-- Sign In Image Column -->

    </a-row>

  </div>
</template>

<script>
// import defaultSettings from '@/config/defaultSettings'
import { getBasicPlatformInfo } from '@/api/login'
import { mapActions } from 'vuex'

export default ({
  data () {
    return {
      mainIntro: this.$store.state.app.mainIntro,
      slogan: this.$store.state.app.slogan,
      loginForm: this.$form.createForm(this),
      isLoginError: false,
      errorOrsuccess: 'error',
      loginErrorMsg: '账户或密码错误！',
      loginBtn: false
    }
  },
  created () {
    getBasicPlatformInfo().then(res => {
      if (res.status === 1) {
        if (typeof res.page_title !== 'undefined' && res.page_title !== null) {
          this.$store.commit('title', res.page_title)
          document.title = res.page_title
        }
        if (typeof res.year !== 'undefined' && res.year !== null) {
          this.$store.commit('year', res.year)
          this.year = res.year
        }
        if (typeof res.company !== 'undefined' && res.company !== null) {
          this.$store.commit('company', res.company)
        }
        if (typeof res.company_e !== 'undefined' && res.company_e !== null) {
          this.$store.commit('companyEng', res.company_e)
        }
        if (typeof res.logo_only !== 'undefined' && res.logo_only !== null) {
          this.$store.commit('logoOnly', '/' + res.logo_only)
        }
        if (typeof res.main_intro !== 'undefined' && res.main_intro !== null) {
          this.mainIntro = res.main_intro
          this.$store.commit('mainIntro', res.main_intro)
        }
        if (typeof res.slogan !== 'undefined' && res.slogan !== null) {
          this.slogan = res.slogan
          this.$store.commit('slogan', res.slogan)
        }
      }
    })
  },
  methods: {
    ...mapActions(['Login']),
    // Handles input validation after submission.
    handleSubmit (e) {
      this.loginForm.getFieldDecorator('platform', { initialValue: process.env.VUE_APP_PLATFORM_MARK, rules: [{ required: true }] })
      e.preventDefault()
      this.loginBtn = true
      this.isLoginError = false
      this.loginForm.validateFields((err, values) => {
        if (!err) {
          this.Login(values).then(res => this.loginSuccess(res))
        } else {
          setTimeout(() => {
            this.loginBtn = false
          }, 600)
        }
      })
    },
    loginSuccess (res) {
      this.isLoginError = true
      if (res.status !== undefined && res.status === 1) {
        this.errorOrsuccess = 'success'
        this.loginErrorMsg = '登录成功！'
        setTimeout(() => {
          this.$router.push({ path: '/' })
        }, 1000)
      } else {
        // 失败
        this.loginBtn = false
        this.errorOrsuccess = 'error'
        this.loginErrorMsg = res.msg || '请确认输入正确的账号和密码！'
      }
    }
  }
})

</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>