<!-- 
	This is the Dashboards CRM page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <!-- Charts -->
    <a-row
      :gutter="24"
      type="flex"
      align="stretch"
    >
      <a-col
        :span="24"
        :lg="16"
      >

        <a-row
          :gutter="24"
          type="flex"
          align="stretch"
        >

          <!-- Top widgets -->
          <a-col
            :span="24"
            :lg="12"
            class="mb-24"
          >
            <WidgetLineChartAppoint></WidgetLineChartAppoint>
          </a-col>

          <a-col
            :span="24"
            :lg="12"
            class="mb-24"
          >
            <WidgetLineChartRecord></WidgetLineChartRecord>
          </a-col>
          <!-- / Top widgets -->

          <!-- Calendar -->
          <a-col :span="24">

            <a-card
              :bordered="false"
              class="header-solid mb-24"
            >
              <template #title>
                <div class="right-box">
                  <a-button
                    type="link"
                    icon="sync"
                    size="large"
                    :disabled="refreshBtn"
                    :loading="refreshBtn"
                    @click="refreshClientAppointMentList"
                  >
                    刷新
                  </a-button>
                  <a-button
                    type="primary"
                    icon="plus"
                    :disabled="refreshBtn"
                    :loading="refreshBtn"
                    @click="clickDayAppointShow"
                  >
                    添加日程
                  </a-button>
                </div>
                <h6 class="font-semibold m-0">我的预约日历</h6>
                <p>{{ date }}</p>
              </template>
              <FullCalendar :options="calendarOptions" />
            </a-card>

            <!-- 添加日程的抽屉窗口 -->
            <day-appointment-drawer
              :parentParams="dayAppointParam"
              :dayAppointVisible="dayAppointVisible"
              @dayAppointVisibleChange="dayAppointVisibleChange"
            />

            <!-- 日程处理的抽屉窗口 -->
            <day-appointment-deal
              :parentParams="dayAppointDealParam"
              :dayAppointDealVisible="dayAppointDealVisible"
              @dayAppointDealVisibleChange="dayAppointDealVisibleChange"
            />

          </a-col>
          <!-- / Calendar -->

        </a-row>

      </a-col>

      <a-col
        :span="24"
        :lg="8"
      >

        <!-- Information card -->
        <CardInfo2 class="mb-24 h-auto"></CardInfo2>
        <!-- / Information card -->

        <!-- Categories list -->
        <CardCategories
          class="mb-24 h-auto"
          :data="categoriesData"
          :title="'最近预约'"
        ></CardCategories>
        <!-- Categories list -->

        <!-- BirthDay Notification Card -->
        <CardBirthdayNotification></CardBirthdayNotification>
        <!-- / BirthDay Notification Card -->

      </a-col>

      <!-- <a-col
        :span="12"
        class="mb-24"
      >
        <CardTransactions2
          title="服务费用"
          date="23 - 30 March 2024"
          :data="transactionsData"
        ></CardTransactions2>
      </a-col>
      <a-col
        :span="12"
        class="mb-24"
      >
        <CardTransactions2
          title="商城订单"
          :data="transactionsData2"
          date="01 - 07 April 2024"
        ></CardTransactions2>
      </a-col> -->
    </a-row>
    <!-- / Charts -->

  </div>

</template>

<script>

// Bar chart for Summary card.
import WidgetLineChartRecord from '../../components/Widgets/WidgetLineChartRecord'
import WidgetLineChartAppoint from '../../components/Widgets/WidgetLineChartAppoint'

// Information card 2.
import CardInfo2 from '../../components/Cards/CardInfo2'

// Notification Card
import CardBirthdayNotification from "../../components/Cards/CardBirthdayNotification"

// Categories List Card
import CardCategories from '../../components/Cards/CardCategories'

// Transactions List Card
import CardTransactions2 from '../../components/Cards/CardTransactions2'

import { DayAppointmentDrawer, DayAppointmentDeal } from '@/components/Schedule'

// FullCalendar and plugins
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
// import { getSchedulesByUser } from '@/api/dms/schedule'
import { getClientAppointmentByUser } from '@/api/dms/clientAppointment'

const categoriesData = []

// "Your Transactions" list data.
const transactionsData = [
  {
    title: "张三咨询",
    datetime: "27 March 2020, at 12:30 PM",
    amount: "2,500",
    type: 1,// 0 is for pending, 1 is for deposit, -1 is for withdrawal.
    status: 'success',
  },
  {
    title: "张飞飞食谱",
    datetime: "23 March 2021, at 04:30 AM",
    amount: "2,000",
    type: 1,
    status: 'danger',
  },
  {
    title: "孙尚香 #22213",
    datetime: "19 March 2021, at 12:30 AM",
    amount: "750",
    type: 1,
    status: 'danger',
  },
]

// "Your Transactions" list data.
const transactionsData2 = [
  {
    title: "via PayPal",
    datetime: "07 June 2021, at 09:00 AM",
    amount: "4,999",
    type: 1,
    status: 'success',
  },
  {
    title: "Partner #90211",
    datetime: "07 June 2021, at 05:50 AM",
    amount: "700",
    type: 1,
    status: 'success',
  },
  {
    title: "Services",
    datetime: "07 June 2021, at 07:10 PM",
    amount: "1,800",
    type: 1,
    status: 'success',
  },
]

export default ({
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    WidgetLineChartRecord,
    WidgetLineChartAppoint,
    CardInfo2,
    CardBirthdayNotification,
    CardCategories,
    CardTransactions2,
    DayAppointmentDrawer,
    DayAppointmentDeal
  },
  data () {
    return {
      transactionsData,
      transactionsData2,
      categoriesData,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],//要使用的组件需要先引入
        locale: 'zh-cn',
        allDayText: '全天',
        contentHeight: 'auto',
        initialView: 'dayGridMonth',
        selectable: true,
        initialDate: moment().format('YYYY-MM-DD'),
        // editable: true,
        headerToolbar: {//设置头部工具栏显示的按钮
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        },
        buttonText: {//设置头部工具栏的按钮文本
          today: '今天',
          dayGridMonth: '月',
          timeGridWeek: '周',
          timeGridDay: '天',
          list: '日程'
        },
        dayMaxEvents: 2,//设置日期视图中显示的事件个数，
        eventLimit: true,
        eventLimitText: '更多',
        events: {
          // 统一设置背景色，边框，字体，在下面还可以单独设置
          backgroundColor: '#ffffff1a',
          textColor: '#141414',
          borderColor: '#ff0000',
          events: []
        },
        eventClick: (e) => this.calenderClick(e)
      },
      refreshBtn: false,
      dayAppointVisible: false,
      dayAppointParam: {},
      calendarOriResData: [],
      dayAppointDealParam: {},
      dayAppointDealVisible: false
    }
  },
  computed: {
    // Current date for calendar.
    date () {
      var today = new Date()
      var weekday = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      var mDay = weekday[today.getDay()]

      return moment().format('yyyy-MM-DD') + ' ' + mDay
    }
  },
  created () {
    // 获取数据
    this.loadCalendarData()
  },
  methods: {
    loadCalendarData () {
      this.refreshBtn = true
      getClientAppointmentByUser({ user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          const calendarDateArr = []
          this.categoriesData = []
          this.calendarOriResData = [...res.data]

          res.data.map((tv, ti) => {
            if (tv.appoint_type <= 1) {
              // 正常的客户预约记录
              calendarDateArr.push({
                id: ti,
                title: tv.patient_name,
                // start: tv.appoint_date,
                // 把seq排序号加到开始时间的秒上面，日历显示的顺序就会按时间大小排序显示
                start: moment(tv.appoint_date).add(tv.appoint_seq, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                end: tv.expire_date,
                display: 'list-item'
              })
              if (this.categoriesData.length < 5) {
                this.categoriesData.push({
                  id: tv.id,
                  patient: tv.patient_id,
                  title: tv.patient_name,
                  subtitle: "<span class='font-weight-bold'>预约时间：</span>" + tv.appoint_date,
                  icon: 'aliwangwang',
                })
              }
            }
            if (parseInt(tv.appoint_type) === 5) {
              // 当前医生用户自己的日程数据
              calendarDateArr.push({
                id: ti,
                title: tv.remarks,
                // 日程没有排seq顺序所以加id值，日历显示的顺序就会按时间大小排序显示
                start: moment(tv.appoint_date).add(tv.id, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                end: tv.expire_date,
                borderColor: '#1890FF',
                display: 'list-item'
              })
            }
          })
          this.calendarOptions.events.events = calendarDateArr
        } else {
          this.$message.error(res.msg || '获预约日历取信息失败！请刷新后重试！')
        }
        this.refreshBtn = false
      }).catch(() => {
        this.$message.error('获预约日历取信息失败！！请刷新后重试！')
        this.refreshBtn = false
        return false
      })
    },
    refreshClientAppointMentList () {
      this.loadCalendarData()
    },
    dayAppointVisibleChange (v) {
      this.dayAppointVisible = v
      if (!v) {
        this.loadCalendarData()
      }
    },
    clickDayAppointShow () {
      this.dayAppointVisible = true
    },
    dayAppointDealVisibleChange (v) {
      this.dayAppointDealVisible = v
      if (!v) {
        this.loadCalendarData()
      }
    },
    calenderClick (e) {
      console.log(e, e.event.id)
      if (this.calendarOriResData[e.event.id] !== undefined) {
        const cuCalenderRow = JSON.parse(JSON.stringify(this.calendarOriResData[e.event.id]))
        if (parseInt(cuCalenderRow.appoint_type) <= 1) {
          // 跳转到预约处理问诊页面
          sessionStorage.setItem('HisPatients.setServiceRecord', JSON.stringify({ appoint: cuCalenderRow.id, patient: cuCalenderRow.patient_id }))
          this.$router.push({ path: '/HisPatients/setServiceRecord' })
        }
        if (parseInt(cuCalenderRow.appoint_type) === 5) {
          // 弹窗显示日程处理
          this.dayAppointDealParam = cuCalenderRow
          this.dayAppointDealVisible = true
        }
      } else {
        this.$message.error('获预约日历取信息失败！！请刷新后重试！')
        return false
      }
    }
  }
})

</script>

<style lang="scss">
.right-box {
  float: right;
  margin-top: -12px;
}
</style>