import request from '@/utils/request'

const apiUrls = {
  selectListRole: '/AdminRoles/selectListRole',
  getRoleList: '/AdminRoles/getRoleList',
  saveRole: '/AdminRoles/saveRole',
  getRoleInfo: '/AdminRoles/getRoleInfo',
  updateRole: '/AdminRoles/updateRole',
  saveRoleMenu: '/AdminRoles/saveRoleMenu'
}

export function selectListRole (parameter) {
  return request({
    url: apiUrls.selectListRole,
    method: 'get',
    params: parameter
  })
}

export function getRoleList (parameter) {
  return request({
    url: apiUrls.getRoleList,
    method: 'get',
    params: parameter
  })
}

export function saveRole (data) {
  return request({
    url: apiUrls.saveRole,
    method: 'post',
    data
  })
}

export function getRoleInfo (data) {
  return request({
    url: apiUrls.getRoleInfo,
    method: 'post',
    data
  })
}

export function updateRole (data) {
  return request({
    url: apiUrls.updateRole,
    method: 'post',
    data
  })
}

export function saveRoleMenu (data) {
  return request({
    url: apiUrls.saveRoleMenu,
    method: 'post',
    data
  })
}
