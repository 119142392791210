<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        id="detailBox"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.push({ path: '/EmallGoods/listShopGoods' })"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-descriptions title="商品基础信息">
          <a-descriptions-item label="展示品名">{{ originInfo.goods_name }}</a-descriptions-item>
          <a-descriptions-item
            label="展示标题"
            :span="2"
          >{{ originInfo.title }}</a-descriptions-item>

          <a-descriptions-item label="商品类型">{{ originInfo.type_name }}</a-descriptions-item>
          <a-descriptions-item
            label="详细描述"
            :span="2"
          >{{ originInfo.sub_title }}</a-descriptions-item>

          <a-descriptions-item label="当前状态"><span :class="originInfo.online_status === 20 ? 'font-success' : 'font-error'">{{ 'emall_goods.status' | showText(originInfo.status) }}</span></a-descriptions-item>
          <a-descriptions-item label="是否上架"><span :class="originInfo.online_status === 1 ? 'font-success' : 'font-error'">{{ 'emall_goods.online_status' | showText(originInfo.online_status) }}</span></a-descriptions-item>
          <a-descriptions-item label="运费单价">{{ originInfo.freight > 0 ? originInfo.freight : 0 }} 元/ {{ 'emall_goods.freight_type' | showText(originInfo.freight_type) }}</a-descriptions-item>

          <a-descriptions-item label="成本价">{{ originInfo.cost_price }}</a-descriptions-item>
          <a-descriptions-item label="标价">{{ originInfo.unit_price }}</a-descriptions-item>
          <a-descriptions-item label="实际售价">{{ originInfo.promotion_unit_price }}</a-descriptions-item>

          <a-descriptions-item label="实体/虚拟商品">{{ 'emall_goods.is_virtual' | showText(originInfo.is_virtual) }}</a-descriptions-item>
          <a-descriptions-item
            label="库存管控"
            :span="originInfo.inventory_control > 0 ? 1 : 2"
          >{{ 'emall_goods.inventory_control' | showText(originInfo.inventory_control) }}</a-descriptions-item>
          <a-descriptions-item
            label="库存管控数量"
            v-if="originInfo.inventory_control > 0"
          >{{ originInfo.inventory }}</a-descriptions-item>

          <a-descriptions-item
            label="销售时间"
            :span="3"
          >{{ originInfo.id !== undefined ? originInfo.start_time + ' ~ ' + originInfo.end_time :'' }}</a-descriptions-item>
          <a-descriptions-item label="商品主图">
            <div
              style="max-width:80px;height:80px;cursor:pointer;"
              v-if="originInfo.feature_image_show !== undefined && originInfo.feature_image_show !== ''"
            >
              <img
                title="点击查看原图"
                style="width: 100%"
                :src="originInfo.feature_image_show"
                @click="handlePreview(originInfo.feature_image_show)"
              />
            </div>
          </a-descriptions-item>
        </a-descriptions>

        <a-divider />
        <div class="title">商品SKU信息</div>
        <a-table
          :columns="goodsColumns"
          :dataSource="originInfo.emall_goods_sku"
          :pagination="false"
          rowKey="id"
        >
          <span
            slot="status"
            slot-scope="text"
            :class="text === 20 ? 'font-success' : 'font-error'"
          >
            {{ 'emall_goods_sku.status' | showText(text) }}
          </span>
        </a-table>

        <a-row class="form-row text-center mt-20">
          <a-button
            type="default"
            icon="arrow-left"
            @click="$router.push({ path: '/EmallGoods/listShopGoods' })"
          >返回商品信息管理列表</a-button>
        </a-row>
      </a-card>
    </a-spin>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="店铺图片"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>
  </div>
</template>

<script>
import { getEmallGoodDetailInfo } from '@/api/dms/emallGood'

export default {
  data () {
    return {
      spinning: true,
      tip: '数据加载中...',
      originInfo: {},
      goodsColumns: [
        {
          title: '#',
          dataIndex: 'id',
          width: 70,
          ellipsis: true
        },
        {
          title: '商品规格',
          dataIndex: 'emall_goods_sku_spec',
          ellipsis: true,
          customRender: (text, row, index) => {
            const showTxt = []
            if (text.length !== undefined && text.length > 0) {
              text.map(tv => {
                showTxt.push(tv.spec_name + '(' + tv.spec_val + ')')
              })
            }
            return showTxt.join(',')
          }
        },
        {
          title: 'SKU商品名称',
          dataIndex: 'goods_name',
          ellipsis: true
        },
        {
          title: '成本价',
          dataIndex: 'cost_price',
          width: 80,
          ellipsis: true
        },
        {
          title: '标价',
          dataIndex: 'unit_price',
          width: 80,
          ellipsis: true
        },
        {
          title: '实际售价',
          dataIndex: 'promotion_unit_price',
          width: 80,
          ellipsis: true
        },
        {
          title: '库存数',
          dataIndex: 'inventory',
          width: 80,
          ellipsis: true
        },
        {
          title: '销量',
          dataIndex: 'sell_count',
          width: 80,
          ellipsis: true
        },
        {
          title: '当前状态',
          width: 80,
          dataIndex: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' }
        }
      ],
      previewVisible: false,
      previewImg: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoods.viewShopGoods'))
    if (this.params === null || this.params.shopGood === undefined || this.params.shopGood < 1) {
      this.$message.error('请确认选择商城店铺！')
      return this.$router.go(-1)
    }
    // 获取数据
    getEmallGoodDetailInfo({ shopGood: this.params.shopGood }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shopGood) {
        res.data.status = res.data.status.toString()
        this.originInfo = res.data
        console.log(this.originInfo)

      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
      this.spinning = false
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#detailBox {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
