// eslint-disable-next-line
import { UserLayout, BasicLayout } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

// 基础路由的最外层固定写法
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    // meta: { title: 'menu.home' },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboards/CRM'),
        meta: { title: '首页', keepAlive: false, icon: 'home' }
        // meta: { title: 'menu.home', keepAlive: false, icon: 'home', permission: ['dashboard'] }
      },
      // {
      //   path: '/calendar',
      //   name: 'Calendar',
      //   component: () => import('@/views/Dashboards/Calendar'),
      //   meta: { title: '日历', keepAlive: false, icon: 'calendar' }
      // },
      // {
      //   path: '/test',
      //   name: 'FormActiveTest',
      //   component: () => import('@/views/Dashboards/FormActiveTest'),
      //   meta: { title: '动态表单测试', keepAlive: false, icon: 'form' }
      // },
      // todo 写个人中心相关的页面的时候再来配置
      {
        path: '/account/settings',
        name: 'Settings',
        layout: "dashboard",
        hidden: true,
        meta: {
          title: '个人中心',
          sidebarMap: ['account', 'settings'],
          breadcrumbs: ['Account', 'Settings'],
        },
        component: () => import('@/views/Account/Settings.vue'),
      },

      // {
      //   path: '/account',
      //   component: RouteView,
      //   redirect: '/account/center',
      //   name: 'account',
      //   hidden: true,
      //   meta: { title: '个人页', icon: 'user', keepAlive: true, permission: ['user'] },
      //   children: [
      //     {
      //       path: '/account/settings',
      //       name: 'settings',
      //       component: () => import('@/views/Account/Settings'),
      //       meta: { title: '个人设置', hideHeader: true, permission: ['user'] },
      //       redirect: '/account/Settings',
      //       hideChildrenInMenu: true,
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 前端不需要后端权限管理的路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/Login')
      },
      {
        path: 'signUp',
        name: 'signUp',
        component: () => import(/* webpackChunkName: "user" */ '@/views/Authentication/Sign-Up/Illustration')
      }
    ]
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404')
  },
  {
    path: '/error',
    name: 'ErrorMsg',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/ErrorMsg')
  },
  // 没写component的layout不要框架布局，直接就是白页面
  {
    path: '/mobileInputSurvey',
    name: 'MobileInputSurvey',
    component: () => import('@/views/miscSurveys/mobileInputSurvey/MobileInputSurvey'),
    meta: { title: '问卷调查' }
  },
  {
    path: '/vedioPlayer',
    name: 'VedioPlayer',
    component: () => import('@/views/Dashboards/VedioPlayer'),
    meta: { title: '问卷调查' }
  }
]
