<template>
  <a-modal
    v-model="surveyShareVisibleCu"
    cancelText=""
    okText="下载二维码图片"
    title="调查问卷二维码分享"
    @ok="handleOkShare"
    @cancel="handleCancelShare"
  >
    <div
      class="text-center"
      ref="imageDivWrapper"
    >
      <template v-if="parentParams.bg_img_url_show !== undefined && parentParams.bg_img_url_show.indexOf('load-error.jpg') < 0">
        <img
          :src="parentParams.bg_img_url_show"
          style="width:100%;"
        >
      </template>
      <canvas
        id="qrcodeCanvas"
        :class="parentParams.bg_img_url_show !== undefined && parentParams.bg_img_url_show.indexOf('load-error.jpg') < 0 ? 'qr-box-div' : ''"
      ></canvas>
    </div>
  </a-modal>
</template>

<script>
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'

export default ({
  name: 'SurveyShare',
  props: {
    surveyShareVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    surveyShareVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候处理二维码参数
        this.surveyShareVisibleCu = this.surveyShareVisible
        if (this.surveyShareVisible) {
          console.log(this.parentParams)
          this.$nextTick(() => {
            const qrParam = this.parentParams.url + this.parentParams.id
            QRCode.toCanvas(document.getElementById('qrcodeCanvas'), qrParam, { width: '200', height: '200', margin: 1 })
          })
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      surveyShareVisibleCu: false,
      qrBoxStyle: {}
    }
  },
  created () {

  },
  methods: {
    handleCancelShare () {
      this.surveyShareVisibleCu = false
      this.$emit('surveyShareVisibleChange', false)
    },
    handleOkShare () {
      // const clientWidth = this.$refs.imageDivWrapper.offsetWidth
      // const clientHeight = this.$refs.imageDivWrapper.offsetHeight
      // const kh = [clientWidth, clientHeight]

      html2canvas(this.$refs.imageDivWrapper, { useCORS: true, logging: true }).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png')
        // this.download(dataURL, kh)
        this.download(dataURL)
        // console.log(canvas)
      })
    },
    // getUrlBase64 (url, kh) {
    //   return new Promise((resolve) => {
    //     let canvas = document.createElement('canvas')
    //     const ctx = canvas.getContext('2d')
    //     const img = new Image()
    //     img.crossOrigin = 'Anonymous' // 允许跨域
    //     img.src = url
    //     img.onload = () => {
    //       // eslint-disable-next-line prefer-destructuring
    //       canvas.height = kh[1]
    //       // eslint-disable-next-line prefer-destructuring
    //       canvas.width = kh[0]
    //       ctx.drawImage(img, 0, 0, kh[0], kh[1])
    //       const dataURL = canvas.toDataURL('image/png')
    //       canvas = null
    //       resolve(dataURL)
    //     }
    //   })
    // },
    // download (imgUrl, kh) {
    download (imgUrl) {
      // this.getUrlBase64(imgUrl, kh).then((base64) => {
      const link = document.createElement('a')
      // link.href = base64
      link.href = imgUrl
      link.download = '问卷调查分享.png'
      link.click()
      // })
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
.qr-box-div {
  position: absolute;
  top: calc((100% - 200px) / 2);
  left: calc((100% - 200px) / 2);
}
</style>