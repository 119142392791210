<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>

    <!-- Dashboard Layout -->
    <a-layout
      class="layout-dashboard"
      id="layout-dashboard"
      :class="[navbarFixed ? 'navbar-fixed' : '', ! sidebarCollapsed ? 'has-sidebar' : '', layoutClass, sidebarMinimized ? 'sidebar-minimized' : '' ]"
    >

      <!-- Main Sidebar 左侧菜单-->
      <DashboardSidebar
        :sidebarCollapsed="sidebarCollapsed"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
        @toggleSidebar="toggleSidebar"
      ></DashboardSidebar>
      <!-- / Main Sidebar -->

      <!-- Layout Content -->
      <a-layout>

        <!-- Layout Header's 内容的顶部 -->
        <DashboardHeader
          :sidebarCollapsed="sidebarCollapsed"
          :navbarFixed="navbarFixed"
          :sidebarMinimized="sidebarMinimized"
          @toggleSidebar="toggleSidebar"
          @minimizeSidebar="minimizeSidebar"
        ></DashboardHeader>
        <!-- / Layout Header's Conditionally Fixed Wrapper -->

        <!-- Page Content 中间内容-->
        <a-layout-content>
          <router-view :navbarFixed="navbarFixed" />
        </a-layout-content>
        <!-- / Page Content -->

        <!-- Layout Footer 底部 -->
        <BasicFooter></BasicFooter>
        <!-- / Layout Footer -->

        <!-- 返回顶部的按钮事件没写，要用的时候再写。。。 -->
        <!-- <a-button
          class="fab"
          shape="circle"
          @click=""
        >
          <a-icon
            style="display:block;"
            type="arrow-up"
            theme="outlined"
          />
          回顶部
        </a-button> -->

        <!-- Sidebar Overlay -->
        <div
          class="sidebar-overlay"
          @click="sidebarCollapsed = true"
          v-show="! sidebarCollapsed"
        ></div>
        <!-- / Sidebar Overlay -->

      </a-layout>
      <!-- / Layout Content -->

    </a-layout>
    <!-- / Dashboard Layout -->

  </div>
</template>

<script>

import DashboardSidebar from '../components/Sidebars/DashboardSidebar'
import DashboardHeader from '../components/Headers/DashboardHeader'
import BasicFooter from '../components/Footers/BasicFooter'

export default ({
  components: {
    DashboardSidebar,
    DashboardHeader,
    BasicFooter
  },
  data () {
    return {

      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Sidebar minimized status.
      sidebarMinimized: false,

      // Main sidebar color.
      sidebarColor: "primary",

      // Main sidebar theme : light, white, dark.
      sidebarTheme: "light",

      // Header fixed status.
      navbarFixed: false

    }
  },
  methods: {

    // Toggle sidebar's collapsed status.
    toggleSidebar (value) {
      this.sidebarCollapsed = value
    },

    // Toggle sidebar's minimized status.
    minimizeSidebar (value) {
      this.sidebarMinimized = !this.sidebarMinimized
    },

    // Toggle navbar's fixed status.
    toggleNavbarPosition (value) {
      this.navbarFixed = value
    },

    // Change sidebar's theme.
    updateSidebarTheme (value) {
      this.sidebarTheme = value
    },

    // Change sidebar's color.
    updateSidebarColor (value) {
      this.sidebarColor = value
    },

  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass () {
      return this.$route.meta.layoutClass
    }
  },
})

</script>
