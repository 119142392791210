<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <!-- 食谱的基础信息部分 -->
        <h5>食谱定制相关信息</h5>
        <form-create
          v-model="cformFoodApi"
          :rule="cformFoodRule"
          :option="cformOption"
        >
        </form-create>

        <a-row>
          <a-col
            :offset="11"
            :span="12"
          >
            <a-button
              icon="check"
              @click="clickPatMenuSave(true)"
              type="primary"
            >确认保存</a-button>
          </a-col>
        </a-row>
      </a-card>

      <a-card
        :bordered="false"
        class="mt-20"
      >
        <h5>一日食谱配置
          <div class="float-right mr-15 mt--5">
            <a-button
              icon="plus"
              type="primary"
              @click="addOneDay"
            >增加一天</a-button>
          </div>
        </h5>
        <div v-if="chooseFoodArr.length > 0">
          <a-tabs
            v-model="activeTabKey"
            hide-add
            type="editable-card"
            @edit="onTabEdit"
            @change="tabChange"
          >
            <a-tab-pane
              v-for="(fdv,fdi) in chooseFoodArr"
              :key="fdi"
              :tab="'第 '+(fdi + 1)+' 天 '"
              :closable="fdv.closable"
            >
            </a-tab-pane>
          </a-tabs>

          <!-- 选择指定tab时只显示指定tab的数据 -->
          <template v-if="chooseFoodArr[activeTabKey] !== undefined">
            <a-row :gutter="20">
              <a-col :span="18">
                <div class="mb-10 text-right">
                  <a-button
                    icon="plus"
                    size="small"
                    @click="(e)=>addOneDayMenu(e)"
                  >选自现成食谱</a-button>
                </div>
                <div
                  v-for="(etype,ti) in eatingTypeArr"
                  :key="ti"
                >
                  <h6>
                    {{ etype.label }}
                    <div
                      class="float-right right-icon-text"
                      @click="clickShowMenu('et_'+etype.value)"
                    >
                      <a-icon
                        v-if="cuShowMenuTable === 'et_'+etype.value"
                        type="minus-circle"
                      />
                      <a-icon
                        v-else
                        type="plus-circle"
                      />
                      添加自菜谱
                    </div>
                  </h6>
                  <div>
                    <div>
                      <div
                        v-if="chooseFoodArr[activeTabKey]['et_'+etype.value] !== undefined && chooseFoodArr[activeTabKey]['et_'+etype.value].length > 0"
                        class="layout-dashboard"
                        style="width:100%; "
                      >
                        <a-table
                          :columns="showRecipeColumns"
                          :data-source="chooseFoodArr[activeTabKey]['et_'+etype.value]"
                          size="middle"
                          :pagination="false"
                          rowKey="id"
                          :scroll="{ x: 1200 }"
                        >
                          <span
                            slot="action"
                            slot-scope="text, record,idx"
                          >
                            <template>
                              <a @click="clickViewRecipe(record)">详情</a>
                              <a-divider type="vertical" />
                              <a-popconfirm
                                title="确定从该日食谱中删除该菜单？"
                                ok-text="删除"
                                cancel-text="取消"
                                @confirm="clickRemoveRecipe('et_'+etype.value,idx)"
                              >
                                <a class="font-error">删除</a>
                              </a-popconfirm>
                            </template>
                          </span>
                        </a-table>
                      </div>
                      <p
                        v-else
                        class="font-error"
                      >暂无数据... 请在可选菜单列表中选择菜单食谱</p>
                    </div>
                    <div
                      class="mt-20"
                      v-if="cuShowMenuTable === 'et_'+etype.value"
                    >
                      <a-collapse
                        class="inner-collapse"
                        :activeKey="activeTabKey+etype.value"
                      >
                        <a-collapse-panel
                          :key="activeTabKey+etype.value"
                          header="可选菜单列表"
                        >
                          <food-recipe-list
                            :sourceMark="'choose'"
                            :arrIdx="{type:'et_'+etype.value}"
                            @sureChooseRecipe="sureChooseRecipe"
                          />
                        </a-collapse-panel>
                      </a-collapse>
                    </div>
                  </div>
                  <a-divider class="mb-15" />
                </div>
                <a-row class="mt-20">
                  <a-col
                    :offset="8"
                    :span="12"
                  >
                    <a-button
                      icon="check"
                      @click="clickPatMenuDaySave(true,true)"
                      type="primary"
                      class="mr-10"
                    >保存并同步一日模板</a-button>
                    <a-button
                      icon="check"
                      @click="clickPatMenuDaySave(true)"
                      type="primary"
                    >确认保存</a-button>
                  </a-col>
                </a-row>
              </a-col>

              <a-col :span="6">
                <!-- 食谱的各种营养元素展示 -->
                <daily-food-menu-view-chart
                  :dailyFoodMenuDetail="chooseFoodArr[activeTabKey].food_num > 0 ? chooseFoodArr[activeTabKey].calcPower : {}"
                  :maxCalory="maxCalory"
                />
              </a-col>
            </a-row>
          </template>

        </div>
        <a-empty
          v-else
          class="mb-30"
          description="暂无已选择食谱数据"
        />
      </a-card>
      <!-- 食谱详情弹窗组件 -->
      <food-recipe-detail-modal
        :recipeDetail="recipeDetail"
        :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
        @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
      />

      <!-- 一日食谱的弹窗列表 -->
      <a-modal
        :visible="dailyFoodVisible"
        title="一日食谱列表"
        :width="1280"
        :maskClosable="false"
        :forceRender="true"
        :destroyOnClose="true"
        @cancel="dailyFoodCancel"
      >
        <template slot="footer">
          <a-button
            type="primary"
            @click="dailyFoodCancel"
          >
            关闭
          </a-button>
        </template>
        <a-row class="layout-dashboard">
          <a-col>
            <daily-food-menu-list
              :sourceMark="'choose'"
              @sureChooseOneDayFood="sureChooseOneDayFood"
            />
          </a-col>
        </a-row>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { getPatientDietaryInfo, getPatientNutritionInfo } from '@/api/dms/patient'
import { savePatientMenuFoodInfo, saveOneFoodMenuDay, removeOneFoodMenuDay, removeOneFoodMenuDayRecipe, viewPatientFoodMenu } from '@/api/dms/patientFoodMenu'
import { FoodRecipeList, FoodRecipeDetailModal } from '@/components/FoodRecipes'
import { DailyFoodMenuList, DailyFoodMenuViewChart } from '@/components/DailyFoodMenus'
import { rmFile } from '@/api/manage'
import { mathAddNum, mathSubtractNum } from '@/utils/util'
import moment from 'moment'

export default {
  name: 'SetPatientFoodMenu',
  components: {
    FoodRecipeList,
    FoodRecipeDetailModal,
    DailyFoodMenuList,
    DailyFoodMenuViewChart
  },
  data () {
    return {
      spinning: false,
      showRecipeColumns: [
        {
          title: '序号',
          dataIndex: 'id',
          width: 50,
          customRender: (text, row, idx) => {
            return idx + 1
          }
        },
        {
          title: '名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: true,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 50,
          dataIndex: 'eating_count',
          ellipsis: true,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '适合人数',
          width: 70,
          dataIndex: 'person',
          ellipsis: true,
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '操作',
          ellipsis: true,
          width: 90,
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      cformFoodApi: {},
      cformFoodRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'hidden',
          field: 'service_type'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'input',
          title: '食谱名称',
          field: 'menu_name',
          props: { placeholder: '请填写食谱名称' },
          validate: [
            { required: true, message: '请填写食谱名称', whitespace: true }
          ]
        },
        {
          type: 'RangePicker',
          title: '开始结束日期',
          field: 'start_date', style: { width: '100%' },
          value: [moment().format('YYYY-MM-DD'), moment().add(2, 'days').format('YYYY-MM-DD')],
          props: { ranges: { '最近三天': [moment(), moment().add(2, 'days')], '最近一周': [moment(), moment().add(6, 'days')], '最近一月': [moment(), moment().add(1, 'months').add(-1, 'days')] }, valueFormat: 'YYYY-MM-DD' },
          validate: [
            { required: true, message: '请确认选择开始结束日期' }
          ]
        },
        {
          type: 'input',
          title: '食谱介绍',
          field: 'menu_intro',
          props: { type: 'textarea', autoSize: { minRows: 4 }, placeholder: '请填写食谱介绍' }
        },
        {
          type: 'upload',
          field: 'img_url',
          title: '封面图',
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/food_menu/patient' },
            limit: 1,
            listType: 'picture-card'
          },
        },
        {
          type: 'input',
          title: '不喜欢的食物',
          field: 'food_dislike',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不喜欢的食物' }
        },
        {
          type: 'input',
          title: '过敏信息',
          field: 'allergies',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写过敏信息' }
        },
        {
          type: 'input',
          title: '不耐受的食物',
          field: 'food_intolerant',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不耐受的食物' }
        },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'input',
          title: '其他备注',
          field: 'remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注' }
        },
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      chooseFoodArr: [],
      recipeDetail: {},
      foodRecipeDetailModalVisible: false,
      dailyFoodVisible: false,
      cuShowMenuTable: null,
      eatingTypeArr: [],
      fmenu_img: '',
      activeTabKey: 0,
      menuNameDef: '',
      nutrientMapColumn: [],
      maxCalory: 3200
    }
  },
  created () {
    // console.log(this.$options.filters.getDicTextFieldValueByKey('nutrient.map', 'column', 'type', [1]))
    // console.log(this.$options.filters.getDicTextArrByKey('nutrient.map', 'type', [1]))
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthPatientFoodMenus.setPatientFoodMenu'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    if (this.params.fm !== undefined && this.params.fm > 0) {
      // 直接获取原数据回显
      viewPatientFoodMenu({ menu: this.params.fm, user: this.$store.getters.userInfo.user.id, join: ['day', 'recipe'] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          res.data.start_date = [res.data.start_date, res.data.end_date]
          this.fmenu_img = res.data.img_url
          res.data.img_url = res.data.img_show
          this.cformFoodApi.setValue(res.data)
          // 回显已有的天数数据
          res.data.patient_food_menu_day.map(dv => {
            const dayTmp = { id: dv.id, menu_intro: dv.menu_intro, img_url: dv.img_url, day: dv.day, food_num: dv.patient_food_menu_day_recipe.length, calcPower: { calory: dv.calory, joule: dv.joule, protein: dv.protein, fat: dv.fat, carbohydrate: dv.carbohydrate, other_nutrition_info: JSON.parse(dv.other_nutrition_info) } }
            dv.patient_food_menu_day_recipe.map(pv => {
              if (dayTmp['et_' + pv.eating_type] === undefined) {
                dayTmp['et_' + pv.eating_type] = []
              }
              dayTmp['et_' + pv.eating_type].push(JSON.parse(pv.recipe_def_detail))
            })
            this.chooseFoodArr.push(dayTmp)
          })
          console.log(res.data, this.chooseFoodArr)
          // 赋值服务记录id，后面要检测是否有营养处方
          this.params.recordOri = res.data.service_record_id
        }
        this.initFormVal()
        this.spinning = false
        this.loadDietaryInfo(false)
      })

    } else {
      this.loadDietaryInfo(true)
    }
  },
  methods: {
    moment,
    loadDietaryInfo (setmark = true) {
      this.spinning = true
      // 饮食习惯  回显过敏食物相关信息
      getPatientDietaryInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          if (setmark) {
            this.cformFoodApi.setValue({ food_dislike: res.data.food_dislike, allergies: res.data.allergies_remarks, food_intolerant: res.data.food_intolerant_remarks, supplement: res.data.supplement })
          }
          // 记录值给计算热量的时候的最大参考值
          this.maxCalory = res.data.calorie
        }
        if (setmark) {
          let setValObj = { patient_id: this.params.patient }
          if (this.params.recordOri !== undefined && this.params.recordOri > 0) {
            setValObj.service_record_id = this.params.recordOri
          }
          if (this.params.type !== undefined && this.params.type > 0) {
            setValObj.service_type = this.params.type
          }
          this.cformFoodApi.setValue(setValObj)
          this.initFormVal()
          this.setDefMenuName()
        }
        // 检测是否回显营养处方的最大热量值
        if (this.params.recordOri !== undefined && this.params.recordOri > 0) {
          this.loadNutritionInfo()
        } else {
          this.spinning = false
        }
      })
    },
    loadNutritionInfo () {
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.params.recordOri }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.maxCalory = resNut.data.calorie
        }
        this.spinning = false
      })
    },
    initFormVal () {
      this.eatingTypeArr = this.$options.filters.selectOptionsData('health.eating_type')
      // 获取所有的其他营养元素的字段
      this.nutrientMapColumn = this.$options.filters.getDicTextFieldValueByKey('nutrient.map', 'column', 'type', [1])
    },
    setDefMenuName () {
      this.menuNameDef = '定制食谱' + moment().format('YYYYMMDDHHmmss')
      this.cformFoodApi.updateRules({ menu_name: { props: { placeholder: this.menuNameDef } } })
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.fmenu_img = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.fmenu_img }).then((res) => {
        this.spinning = false
        this.fmenu_img = ''
        if (res.status !== undefined && res.status === 1) {
          this.cformFoodApi.setValue({ img_url: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    addOneDay () {
      // 增加一天
      this.chooseFoodArr.push({ menu_intro: '', img_url: '', food_num: 0, calcPower: { calory: 0, joule: 0, protein: 0, fat: 0, carbohydrate: 0, other_nutrition_info: {} } })
    },
    addOneDayMenu (e, fdi) {
      e.stopPropagation()
      // 点击增选择一日食谱按钮
      if (this.chooseFoodArr[this.activeTabKey] === undefined) {
        this.$message.error('请确认当前日期！')
        return false
      }
      this.dailyFoodVisible = true
    },
    sureChooseRecipe (recipeInfo) {
      // 组件确认加入一个食谱
      if (recipeInfo.arrIdx !== undefined && recipeInfo.recipe !== undefined && recipeInfo.recipe.id !== undefined && recipeInfo.arrIdx.type !== undefined && this.chooseFoodArr[this.activeTabKey] !== undefined) {
        if (this.chooseFoodArr[this.activeTabKey][recipeInfo.arrIdx.type] === undefined) {
          this.chooseFoodArr[this.activeTabKey][recipeInfo.arrIdx.type] = []
        }
        const isCheck = this.chooseFoodArr[this.activeTabKey][recipeInfo.arrIdx.type].filter((cup) => cup.id === recipeInfo.recipe.id)
        if (isCheck.length > 0) {
          this.$message.error('该菜单菜谱已选择！')
          return false
        } else {
          console.log(recipeInfo.recipe)
          this.calcPowerData(recipeInfo.recipe)
          this.chooseFoodArr[this.activeTabKey][recipeInfo.arrIdx.type].push(recipeInfo.recipe)
          this.chooseFoodArr[this.activeTabKey].food_num += 1
          this.chooseFoodArr = [...this.chooseFoodArr]
        }
      } else {
        this.$message.error('请确认所选菜单菜谱存在可操作！')
        return false
      }
    },
    calcPowerData (recipe, mark = 'add') {
      // 计算各种营养值
      if (this.chooseFoodArr[this.activeTabKey] === undefined) {
        return false
      }
      const cuPd = JSON.parse(JSON.stringify(this.chooseFoodArr[this.activeTabKey].calcPower))
      this.chooseFoodArr[this.activeTabKey].calcPower.calory = (mark === 'add') ? mathAddNum(cuPd.calory, recipe.calory) : mathSubtractNum(cuPd.calory, recipe.calory)
      this.chooseFoodArr[this.activeTabKey].calcPower.joule = (mark === 'add') ? mathAddNum(cuPd.joule, recipe.joule) : mathSubtractNum(cuPd.joule, recipe.joule)
      this.chooseFoodArr[this.activeTabKey].calcPower.protein = (mark === 'add') ? mathAddNum(cuPd.protein, recipe.protein) : mathSubtractNum(cuPd.protein, recipe.protein)
      this.chooseFoodArr[this.activeTabKey].calcPower.fat = (mark === 'add') ? mathAddNum(cuPd.fat, recipe.fat) : mathSubtractNum(cuPd.fat, recipe.fat)
      this.chooseFoodArr[this.activeTabKey].calcPower.carbohydrate = (mark === 'add') ? mathAddNum(cuPd.carbohydrate, recipe.carbohydrate) : mathSubtractNum(cuPd.carbohydrate, recipe.carbohydrate)
      this.chooseFoodArr[this.activeTabKey].calcPower.calory_unit = recipe.calory_unit
      this.chooseFoodArr[this.activeTabKey].calcPower.joule_unit = recipe.joule_unit
      this.chooseFoodArr[this.activeTabKey].calcPower.protein_unit = recipe.protein_unit
      this.chooseFoodArr[this.activeTabKey].calcPower.fat_unit = recipe.fat_unit
      this.chooseFoodArr[this.activeTabKey].calcPower.carbohydrate_unit = recipe.carbohydrate_unit
      // const recipeOther = ['saturated_fat', 'fatty_acid', 'mufa', 'pufa', 'cholesterol', 'sugar', 'fiber_dietary', 'natrium', 'alcohol', 'vitamin_a', 'carotene', 'vitamin_d', 'vitamin_e', 'vitamin_k', 'thiamine', 'lactoflavin', 'vitamin_b6', 'vitamin_b12', 'vitamin_c', 'niacin', 'folacin', 'pantothenic', 'biotin', 'choline', 'phosphor', 'kalium', 'magnesium', 'calcium', 'iron', 'zinc', 'iodine', 'selenium', 'copper', 'fluorine', 'manganese']
      for (let rk in recipe) {
        if (this.nutrientMapColumn.indexOf(rk) >= 0) {
          // 需要计算的微量元素
          const cuOt = JSON.parse(JSON.stringify(this.chooseFoodArr[this.activeTabKey].calcPower.other_nutrition_info))
          cuOt[rk] = cuOt[rk] === undefined ? '' : cuOt[rk]
          this.chooseFoodArr[this.activeTabKey].calcPower.other_nutrition_info[rk] = (mark === 'add') ? mathAddNum(cuOt[rk], recipe[rk]) : mathSubtractNum(cuOt[rk], recipe[rk])
        }
      }
      this.chooseFoodArr = [...this.chooseFoodArr]
    },
    clickRemoveRecipe (field, idx) {
      // 删除指定已选的菜谱
      if (this.chooseFoodArr[this.activeTabKey][field][idx] === undefined) {
        this.$message.error('请确认所选菜谱存在可操作！')
        return false
      }
      let fmData = this.cformFoodApi.formData()
      if (fmData.id !== undefined && this.chooseFoodArr[this.activeTabKey].id !== undefined && this.chooseFoodArr[this.activeTabKey].id > 0) {
        // 有id则需要删除后台数据
        this.spinning = true
        removeOneFoodMenuDayRecipe({ fm: fmData.id, fmd: this.chooseFoodArr[this.activeTabKey].id, user: this.$store.getters.userInfo.user.id, etype: field, recipe_def: this.chooseFoodArr[this.activeTabKey][field][idx].id }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.calcPowerData(this.chooseFoodArr[this.activeTabKey][field][idx], 'subtract')
            this.chooseFoodArr[this.activeTabKey][field].splice(idx, 1)
            this.chooseFoodArr[this.activeTabKey].food_num -= 1
            this.chooseFoodArr = [...this.chooseFoodArr]
          } else {
            this.$message.error(res.msg || '食谱配置信息删除失败！请稍后重试！')
          }
          this.spinning = false
        })
      } else {
        this.calcPowerData(this.chooseFoodArr[this.activeTabKey][field][idx], 'subtract')
        this.chooseFoodArr[this.activeTabKey][field].splice(idx, 1)
        this.chooseFoodArr[this.activeTabKey].food_num -= 1
        this.chooseFoodArr = [...this.chooseFoodArr]
      }
    },
    clickViewRecipe (record) {
      // 查看已选食谱详情弹窗
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    foodRecipeDetailModalVisibleChange (v) {
      // 食谱详情弹窗事件监听
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    },
    clickPatMenuSave (showMsg = false, nextSave = false, nextShowMsg = false, isSame = false) {
      // 先保存主表 若没有输入食谱名称则写个默认
      this.spinning = true
      let fmData = this.cformFoodApi.formData()
      if (fmData.menu_name === undefined || fmData.menu_name === null || fmData.menu_name === '') {
        fmData.menu_name = this.menuNameDef
      }
      fmData.img_url = this.fmenu_img
      fmData.choose_foods = this.chooseFoodArr
      savePatientMenuFoodInfo(fmData).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          // 写id
          this.cformFoodApi.setValue({ id: res.data.fm })
          if (showMsg) { // 点击保存按钮才提示，切换tab自动保存的不提示
            this.$message.success('食谱定制相关信息保存成功！')
          }
          if (nextSave) {
            // 保存当前选择的tab食谱数据
            this.oneMenuFoodDaySave(nextShowMsg, isSame)
          }
        } else {
          this.$message.error(res.msg || '食谱定制相关信息保存失败！请刷新后重试！')
        }
      })
    },
    dailyFoodCancel () {
      this.dailyFoodVisible = false
    },
    sureChooseOneDayFood (v) {
      if (this.chooseFoodArr[this.activeTabKey] === undefined) {
        this.$message.error('请确认选择当前日期！')
        return false
      }
      if (v.daily_food_menu_recipe !== undefined && v.daily_food_menu_recipe.length > 0) {
        v.daily_food_menu_recipe.map(fmv => {
          const cuMenu = JSON.parse(JSON.stringify(fmv))
          cuMenu.id = cuMenu.recipe_def_id
          const cuField = 'et_' + fmv.eating_type
          if (this.chooseFoodArr[this.activeTabKey][cuField] === undefined) {
            this.chooseFoodArr[this.activeTabKey][cuField] = []
          }
          const isCheck = this.chooseFoodArr[this.activeTabKey][cuField].filter((cup) => cup.id === cuMenu.id)
          if (isCheck.length < 1) {
            console.log(cuMenu)
            this.calcPowerData(cuMenu)
            this.chooseFoodArr[this.activeTabKey][cuField].push(cuMenu)
            this.chooseFoodArr[this.activeTabKey].food_num += 1
          }
        })
        this.chooseFoodArr = [...this.chooseFoodArr]
        this.dailyFoodVisible = false
      } else {
        this.$message.error('该菜谱暂无菜单信息！')
        return false
      }
    },
    clickShowMenu (field) {
      if (this.cuShowMenuTable === field) {
        this.cuShowMenuTable = null
      } else {
        this.cuShowMenuTable = field
      }
    },
    onTabEdit (targetKey, action) {
      if (action === 'remove') {
        this.removeTab(targetKey)
      }
    },
    removeTab (targetKey) {
      const that = this
      this.$confirm({
        title: '确定删除第 ' + (targetKey + 1) + ' 天食谱配置数据？',
        content: false,
        cancelText: '取消',
        okText: '确认删除',
        onOk () {
          that.spinning = true
          // 有id的则需要删除数据
          let fmData = that.cformFoodApi.formData()
          if (fmData.id !== undefined && that.chooseFoodArr[that.activeTabKey] !== undefined && that.chooseFoodArr[that.activeTabKey].id !== undefined && that.chooseFoodArr[that.activeTabKey].id > 0) {
            removeOneFoodMenuDay({ fm: fmData.id, fmd: that.chooseFoodArr[that.activeTabKey].id, user: that.$store.getters.userInfo.user.id }).then((res) => {
              if (res.status !== undefined && res.status === 1) {
                that.removeTabArrDataByKey(targetKey)
              } else {
                that.$message.error(res.msg || '食谱配置信息删除失败！请稍后重试！')
                that.spinning = false
              }
            })
          } else {
            // 直接删除数组中的数据
            that.removeTabArrDataByKey(targetKey)
          }
        },
        onCancel () { }
      })
    },
    removeTabArrDataByKey (targetKey) {
      this.spinning = true
      const timer = setTimeout(() => {
        let activeTabKey = this.activeTabKey
        let lastIndex = targetKey - 1
        this.chooseFoodArr.splice(targetKey, 1)
        if (this.chooseFoodArr.length > 0 && activeTabKey === targetKey) {
          if (lastIndex >= 0) {
            activeTabKey = lastIndex
          } else {
            activeTabKey = 0
          }
        }
        this.chooseFoodArr = [...this.chooseFoodArr]
        this.$message.success('第 ' + (this.activeTabKey + 1) + ' 天食谱配置信息删除成功！')
        this.activeTabKey = activeTabKey
        clearTimeout(timer)
        this.spinning = false
      }, 1000)
    },
    tabChange (k) {
      this.activeTabKey = k
      this.cuShowMenuTable = null
      // this.clickPatMenuDaySave(false)
    },
    clickPatMenuDaySave (isVaild = false, isSame = false) {
      // 保存当前展示的标签日期数据 如果没有保存主表数据则需要先保存
      if (this.chooseFoodArr[this.activeTabKey] === undefined) {
        this.$message.error('请确认所选菜谱存在可操作！')
        return false
      }
      if (this.chooseFoodArr[this.activeTabKey].food_num < 1) {
        // 没有选择食谱的时候切换tab不保存，点击按钮则验证提示不为空
        if (isVaild) {
          this.$message.error('请确认选择菜单！')
        }
        return false
      }
      // 检测有没有主表id没有则需要先存主表
      let fmData = this.cformFoodApi.formData()
      if (fmData.id === undefined || fmData.id === null || fmData.id < 1) {
        this.clickPatMenuSave(false, true, isVaild, isSame)
      } else {
        this.oneMenuFoodDaySave(isVaild, isSame)
      }
    },
    oneMenuFoodDaySave (showMsg = false, isSame = false) {
      this.spinning = true
      let fmData = this.cformFoodApi.formData()
      saveOneFoodMenuDay({ fm: fmData.id, day: (this.activeTabKey + 1), foods: this.chooseFoodArr[this.activeTabKey], user: this.$store.getters.userInfo.user.id, isSame, calory_max: this.maxCalory }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          // 写id
          this.chooseFoodArr[this.activeTabKey].id = res.data.fmd
          if (showMsg) { // 点击保存按钮才提示，切换tab自动保存的不提示
            this.$message.success('第 ' + (this.activeTabKey + 1) + ' 天食谱配置信息保存成功！')
          }
        } else {
          this.$message.error(res.msg || '食谱配置信息保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -5px;
}
/deep/ .inner-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.right-icon-text {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
</style>