<template>

  <!-- Notification Card -->
  <a-card
    :bordered="false"
    class="card-notification header-solid mb-24"
  >
    <a-row
      type="flex"
      align="middle"
      v-for="(item,idx) in clients"
      :key="idx"
      class="mb-10"
    >
      <a-col :span="4">
        <a-avatar
          :size="60"
          shape="square"
          :src="item.gender === 1? '/images/avatar-1.jpg' : '/images/avatar-2.jpg'"
        />
      </a-col>
      <a-col :span="16">
        <p class="font-semibold">客户：{{ item.name }} 的生日 {{ item.birth_date }}快要来咯~</p>
        <!-- <a-button
          type="primary"
          size="small"
        >Send message</a-button> -->
      </a-col>
    </a-row>
  </a-card>
  <!-- / Notification Card -->

</template>

<script>
import { getPatientByBirthday } from '@/api/dms/patient'

export default ({
  data () {
    return {
      clients: []
    }
  },
  created () {
    getPatientByBirthday({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }).then((resBd) => {
      if (resBd.status !== undefined && resBd.status === 1) {
        resBd.data.forEach(element => {
          this.clients.push(element)
        })
      }
      console.log(this.clients)
    })
  }
})

</script>