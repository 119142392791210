import request from '@/utils/request'

const apiUrls = {
    // 手机端要使用的请求走auth绕过权限
    saveSurveyResponseInfo: '/auth/MiscSurveyResponses/saveSurveyResponseInfo',
    sureSubmitSurveyResponse: '/auth/MiscSurveyResponses/sureSubmitSurveyResponse',
    listSurveyResponse: '/MiscSurveyResponses/listSurveyResponse',
    getSurveyResponseDetail: '/MiscSurveyResponses/getSurveyResponseDetail',
    surveyResponseBindPatient: '/MiscSurveyResponses/surveyResponseBindPatient',
    surveyResponseDelete: '/MiscSurveyResponses/surveyResponseDelete'
}

export function saveSurveyResponseInfo (data) {
    return request({
        url: apiUrls.saveSurveyResponseInfo,
        method: 'post',
        data
    })
}

export function sureSubmitSurveyResponse (data) {
    return request({
        url: apiUrls.sureSubmitSurveyResponse,
        method: 'post',
        data
    })
}

export function listSurveyResponse (parameter) {
    return request({
        url: apiUrls.listSurveyResponse,
        method: 'get',
        params: parameter
    })
}

export function getSurveyResponseDetail (parameter) {
    return request({
        url: apiUrls.getSurveyResponseDetail,
        method: 'get',
        params: parameter
    })
}

export function surveyResponseBindPatient (data) {
    return request({
        url: apiUrls.surveyResponseBindPatient,
        method: 'post',
        data
    })
}

export function surveyResponseDelete (data) {
    return request({
        url: apiUrls.surveyResponseDelete,
        method: 'post',
        data
    })
}
