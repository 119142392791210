<template>
  <a-modal
    v-model="modalVisible1"
    title="修改用户密码"
    okText="确认保存"
    :maskClosable="false"
    :okButtonProps="okButtonProps1"
    @ok="handleModalOk1"
    @cancel="closeModal"
  >
    <a-spin
      tip="处理中..."
      :spinning="spinningM1"
    >
      <form-create
        v-model="cformApi"
        :rule="cformRule"
        :option="cformOption"
      ></form-create>
    </a-spin>
  </a-modal>
</template>

<script>
import { resetUserPwd } from '@/api/dms/user'

export default ({
  name: 'ChangeUserPwd',
  data () {
    return {
      spinningM1: false,
      modalVisible1: false,
      okButtonProps1: {},
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'user'
        },
        {
          type: 'input',
          title: '新密码',
          field: 'user_passwd',
          props: { type: 'password', placeholder: '请填写新密码' },
          validate: [
            { required: true, min: 6, max: 12, message: '请填写6~12位新密码', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '密码确认',
          field: 'user_passwd2',
          props: { type: 'password', placeholder: '请再次填写登录密码' },
          validate: [
            { required: true, min: 6, max: 12, message: '请再次填写登录密码', whitespace: true },
            { validator: this.compareToPassword }
          ]
        },
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
    }
  },
  created () {
  },
  methods: {
    compareToPassword (rule, value, callback) {
      let ct
      if (value && value !== this.cformApi.getValue('user_passwd')) {
        ct = '两次输入密码不一致！'
      }
      return callback(ct)
    },
    closeModal () {
      this.cformApi.clearValidateState()
      this.cformApi.resetFields()
      this.modalVisible1 = false
      this.spinningM1 = false
    },
    showModal (paramData) {
      this.modalVisible1 = true
      this.spinningM1 = false
      this.$nextTick(() => {
        if (paramData.user !== undefined && paramData.user > 0) {
          this.cformApi.setValue({ user: paramData.user })
        } else {
          this.modalVisible1 = false
          this.$message.error('请确认当前选择用户！')
        }
      })
    },
    handleModalOk1 () {
      this.cformApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformApi.formData()
          this.spinningM1 = true
          this.okButtonProps1 = { props: { loading: true } }
          resetUserPwd(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.closeModal()
              this.$emit('complete')
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinningM1 = false
            this.okButtonProps1 = {}
          })
        } else {
          console.log(fail2)
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
  }
})

</script>

<style lang="scss" scoped>
</style>