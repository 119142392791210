<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="全部"
                      :options="'user.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入用户名/手机号搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.adminUserTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/Enterprises/addUser'"
                      :hidden="'/Enterprises/addUser' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增人员</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="adminUserTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="gender"
            slot-scope="text"
          >
            {{ 'ryx.gender' | showText(text.toString()) }}
          </span>

          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'user.status' | showText(text)"
            />
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/Enterprises/changeUserRoles' | isShowButton">
                <a @click="clickChangeRoles(record)">角色修改</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/Enterprises/editUser','/Enterprises/resetUserPwd','/Enterprises/changeUserStatus'] | isShowSeparator(['/Enterprises/changeUserRoles'])"
              />

              <a-dropdown :hidden="['/Enterprises/resetUserPwd','/Enterprises/editUser','/Enterprises/changeUserStatus'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/Enterprises/resetUserPwd' | isShowButton"
                    key="1"
                  >
                    <a @click="clickResetPwd(record)">修改密码</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/Enterprises/editUser' | isShowButton"
                    key="2"
                  >
                    <a @click="clickEdit(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-divider :hidden="'/Enterprises/changeUserStatus' | isShowSeparator(['/Enterprises/editUser','/Enterprises/resetUserPwd'])" />
                  <a-menu-item
                    :hidden="'/Enterprises/changeUserStatus' | isShowButton"
                    v-if="record.status === 2"
                    @click="clickChangeStatus(record,3)"
                    key="3"
                  >禁用</a-menu-item>
                  <a-menu-item
                    :hidden="'/Enterprises/changeUserStatus' | isShowButton"
                    v-else
                    @click="clickChangeStatus(record,2)"
                    key="4"
                  >启用</a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

    <ChangeUserPwd
      ref="changeUserPwdModal"
      @complete="$refs.adminUserTable.refresh(true)"
    />
    <ChangeUserRoles
      ref="changeUserRolesModal"
      @complete="$refs.adminUserTable.refresh(true)"
    />
    <!-- <a-modal
      v-model="modalVisible1"
      title="修改用户密码"
      okText="确认保存"
      :maskClosable="false"
      :okButtonProps="okButtonProps1"
      @ok="handleModalOk1"
    >
      <a-spin
        tip="处理中..."
        :spinning="spinningM1"
      >
        <a-form
          class="adminUserForm"
          :form="resetPwdForm"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-form-item label="新密码">
            <a-input
              placeholder="请填写新密码"
              v-decorator="['user_passwd', { rules: [{ required: true,min:6,max:12, message: '请填写6~12位登录密码', whitespace: true }] }]"
            />
          </a-form-item>
          <a-form-item label="密码确认">
            <a-input
              placeholder="请再次输入密码"
              v-decorator="['user_passwd2', { rules: [{ required: true, message: '请再次填写登录密码', whitespace: true },{
                validator: compareToPassword
              }] }]"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal> -->

    <!-- <a-modal
      v-model="modalVisible2"
      title="修改用户角色"
      okText="确认保存"
      :maskClosable="false"
      :okButtonProps="okButtonProps2"
      @ok="handleModalOk2"
    >
      <a-spin
        :tip="tipM2"
        :spinning="spinningM2"
      >
        <a-form
          class="adminUserForm"
          :form="changeRolesForm"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="用户角色">
            <a-select
              mode="multiple"
              :maxTagCount="1"
              :maxTagTextLength="15"
              :allowClear="true"
              show-search
              optionFilterProp="children"
              placeholder="请选择用户角色"
              :options="roleListData"
              v-decorator="[
                'role_id',
                { rules: [{ required: true, message: '请选择用户角色' }] }
              ]"
            >
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal> -->

  </div>
</template>

<script>
import { listUser, changeStatus } from '@/api/dms/user'
// import { selectListRole } from '@/api/dms/adminRole', resetUserPwd, changeRoles
import { ChangeUserPwd, ChangeUserRoles } from '@/components/UserManage'

const statusMap = {
  1: {
    status: 'default',
    text: '待激活'
  },
  2: {
    status: 'success',
    text: '正常'
  },
  3: {
    status: 'error',
    text: '注销'
  }
}

export default {
  name: 'ListUser',
  components: { ChangeUserPwd, ChangeUserRoles },
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 90,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '用户名',
          width: 150,
          dataIndex: 'user_name',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'nick_name',
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 90,
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '所属部门',
          dataIndex: 'department_name',
          ellipsis: true
        },
        {
          title: '状态',
          width: 95,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '最后登录IP',
          dataIndex: 'last_ip',
          ellipsis: true
        },
        {
          title: '最后登录时间',
          width: 150,
          dataIndex: 'last_login'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 170,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listUser(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: 1500 }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      },
      // spinningM1: false,
      // modalVisible1: false,
      // okButtonProps1: {},
      // resetPwdForm: this.$form.createForm(this),
      // tipM2: '加载中...',
      // spinningM2: false,
      // modalVisible2: false,
      // okButtonProps2: {},
      // changeRolesForm: this.$form.createForm(this),
      // roleListData: []
    }
  },
  filters: {
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {
    // this.resetPwdForm.getFieldDecorator('user', { preserve: true, rules: [{ required: true }] })
    // this.changeRolesForm.getFieldDecorator('user', { preserve: true, rules: [{ required: true }] })
    // this.changeRolesForm.getFieldDecorator('role_id')
    // // 根据企业获取企业角色
    // selectListRole({ plattype: this.$store.getters.userInfo.platform.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
    //   if (res.status !== undefined && res.status === 1) {
    //     this.roleListData = res.data
    //   } else {
    //     this.$message.error(res.msg || '获取可选角色失败！请刷新后重试！')
    //   }
    // })
  },
  computed: {

  },
  methods: {
    clickChangeRoles (record) {
      // 重置表单
      // this.changeRolesForm.resetFields()
      // // 判断是否有角色并需要删除角色中的1
      // const oldRole = (record.role_id !== null && record.role_id !== '') ? record.role_id.split(',') : null
      // this.changeRolesForm.setFieldsValue({ user: record.id })
      // this.modalVisible2 = true
      // // 根据选择用户回显原有角色
      // this.spinningM2 = true
      // this.tipM2 = '加载中...'
      // this.okButtonProps2 = { props: { loading: true } }
      // const allRoleIds = []
      // this.roleListData.map((item) => {
      //   allRoleIds.push(item.value)
      // })
      // if (oldRole !== null) {
      //   // 去除1超管
      //   if (oldRole.indexOf('1') >= 0) {
      //     oldRole.splice(oldRole.indexOf('1'), 1)
      //   }
      //   // 显示已有的角色
      //   const oldRoleInit = []
      //   oldRole.map(ov => {
      //     if (allRoleIds.indexOf(ov) >= 0) {
      //       oldRoleInit.push(ov)
      //     }
      //   })
      //   this.changeRolesForm.setFieldsValue({ role_id: oldRoleInit })
      // }
      // this.spinningM2 = false
      // this.tipM2 = '处理中...'
      // this.okButtonProps2 = {}

      this.$refs.changeUserRolesModal.showModal({ user: record.id, role_id: record.role_id })
    },
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('Enterprises.editUser', JSON.stringify({ user: record.id }))
      this.$router.push({ path: '/Enterprises/editUser' })
    },
    clickChangeStatus (record, cstatus) {
      if ([2, 3].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({
          title: '该操作不允许！'
        })
        return false
      }
      const that = this
      this.$confirm({
        title: '确认修改该账号状态？',
        content: false,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          that.spinning = true
          changeStatus({ enterprise: that.$store.getters.userInfo.user.target_enterprise, platform: that.$store.getters.userInfo.platform.id, user: record.id, cstatus }).then(res => {
            if (res.status !== undefined && res.status === 1) {
              that.$message.success('操作成功！')
            } else {
              that.$message.error(res.msg || '操作失败！请刷新后重试！')
            }
            that.spinning = false
            that.$refs.adminUserTable.refresh(true)
          })
        },
        onCancel () { }
      })
    },
    clickResetPwd (record) {
      // this.resetPwdForm.resetFields()
      // this.resetPwdForm.setFieldsValue({ user: record.id })
      // this.modalVisible1 = true
      this.$refs.changeUserPwdModal.showModal({ user: record.id })
    },
    // handleModalOk1 () {
    //   this.resetPwdForm.validateFields((err, values) => {
    //     if (!err) {
    //       this.spinningM1 = true
    //       this.okButtonProps1 = { props: { loading: true } }
    //       resetUserPwd(values).then((res) => {
    //         if (res.status !== undefined && res.status === 1) {
    //           this.resetPwdForm.resetFields()
    //           this.$message.success('保存成功！')
    //           this.modalVisible1 = false
    //           this.$refs.adminUserTable.refresh(true)
    //         } else {
    //           this.$message.error(res.msg || '保存失败！请刷新后重试！')
    //         }
    //         this.spinningM1 = false
    //         this.okButtonProps1 = {}
    //       })
    //     }
    //   })
    // },
    // compareToPassword (rule, value, callback) {
    //   let ct
    //   if (value && value !== this.resetPwdForm.getFieldValue('user_passwd')) {
    //     ct = '两次输入密码不一致！'
    //   }
    //   callback(ct)
    // },
    // handleModalOk2 () {
    //   this.changeRolesForm.validateFields((err, values) => {
    //     if (!err) {
    //       this.spinningM2 = true
    //       this.okButtonProps2 = { props: { loading: true } }
    //       values.platform = this.$store.getters.userInfo.platform.id
    //       values.enterprise = this.$store.getters.userInfo.user.target_enterprise
    //       changeRoles(values).then((res) => {
    //         if (res.status !== undefined && res.status === 1) {
    //           this.changeRolesForm.resetFields()
    //           this.$message.success('保存成功！')
    //           this.modalVisible2 = false
    //           this.$refs.adminUserTable.refresh(true)
    //         } else {
    //           this.$message.error(res.msg || '保存失败！请刷新后重试！')
    //         }
    //         this.spinningM2 = false
    //         this.okButtonProps2 = {}
    //       })
    //     }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.adminUserForm {
  /deep/ .ant-form-item {
    margin-bottom: 10px;
  }
  /deep/ .ant-form-item:last-child {
    margin-bottom: 0;
  }
}
</style>
