import request from '@/utils/request'

const apiUrls = {
  selectListTypeByTt: '/GoodsTypes/selectListTypeByTt',
  getSequence: '/GoodsTypes/getSequence'
}

export function selectListTypeByTt (parameter) {
  return request({
    url: apiUrls.selectListTypeByTt,
    method: 'get',
    params: parameter
  })
}

export function getSequence (parameter) {
  return request({
    url: apiUrls.getSequence,
    method: 'get',
    params: parameter
  })
}
