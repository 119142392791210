import request from '@/utils/request'

const apiUrls = {
  listEmallShop: '/EmallShops/listEmallShop',
  addEmallShop: '/EmallShops/addEmallShop',
  getEmallShopDetailInfo: '/EmallShops/getEmallShopDetailInfo',
  getEmallShopInfo: '/EmallShops/getEmallShopInfo',
  editEmallShop: '/EmallShops/editEmallShop',
  editEmallShopGoods: '/EmallShops/editEmallShopGoods',
  changeStatus: '/EmallShops/changeStatus',
  getEmallShopSelectList: '/EmallShops/getEmallShopSelectList'
}

export function listEmallShop (parameter) {
  return request({
    url: apiUrls.listEmallShop,
    method: 'get',
    params: parameter
  })
}

export function addEmallShop (data) {
  return request({
    url: apiUrls.addEmallShop,
    method: 'post',
    data
  })
}

export function getEmallShopDetailInfo (parameter) {
  return request({
    url: apiUrls.getEmallShopDetailInfo,
    method: 'get',
    params: parameter
  })
}

export function getEmallShopInfo (parameter) {
  return request({
    url: apiUrls.getEmallShopInfo,
    method: 'get',
    params: parameter
  })
}

export function editEmallShop (data) {
  return request({
    url: apiUrls.editEmallShop,
    method: 'post',
    data
  })
}

export function editEmallShopGoods (data) {
  return request({
    url: apiUrls.editEmallShopGoods,
    method: 'post',
    data
  })
}

export function changeStatus (data) {
  return request({
    url: apiUrls.changeStatus,
    method: 'post',
    data
  })
}

export function getEmallShopSelectList (parameter) {
  return request({
    url: apiUrls.getEmallShopSelectList,
    method: 'get',
    params: parameter
  })
}
