<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <!-- 食谱的基础信息部分 -->
        <h5>食谱基本信息</h5>
        <form-create
          v-model="cformFoodApi"
          :rule="cformFoodRule"
          :option="cformOption"
        >
        </form-create>

        <h5>一日食谱配置
        </h5>
        <div>
          <a-row :gutter="20">
            <a-col :span="18">
              <div
                v-for="(etype,ti) in eatingTypeArr"
                :key="ti"
              >
                <h6>
                  {{ etype.label }}
                  <div
                    class="float-right right-icon-text"
                    @click="clickShowMenu('et_'+etype.value)"
                  >
                    <a-icon
                      v-if="cuShowMenuTable === 'et_'+etype.value"
                      type="minus-circle"
                    />
                    <a-icon
                      v-else
                      type="plus-circle"
                    />
                    添加自菜谱
                  </div>
                </h6>
                <div>
                  <div>
                    <div
                      v-if="chooseFoodArr['et_'+etype.value] !== undefined && chooseFoodArr['et_'+etype.value].length > 0"
                      class="layout-dashboard"
                      style="width:100%; "
                    >
                      <a-table
                        :columns="showRecipeColumns"
                        :data-source="chooseFoodArr['et_'+etype.value]"
                        size="middle"
                        :pagination="false"
                        rowKey="id"
                        :scroll="{ x: 1200 }"
                      >
                        <span
                          slot="action"
                          slot-scope="text, record,idx"
                        >
                          <template>
                            <a @click="clickViewRecipe(record)">详情</a>
                            <a-divider type="vertical" />
                            <a-popconfirm
                              title="确定从该日食谱中删除该菜单？"
                              ok-text="删除"
                              cancel-text="取消"
                              @confirm="clickRemoveRecipe('et_'+etype.value,idx)"
                            >
                              <a class="font-error">删除</a>
                            </a-popconfirm>
                          </template>
                        </span>
                      </a-table>
                    </div>
                    <p
                      v-else
                      class="font-error"
                    >暂无数据... 请在可选菜单列表中选择菜单食谱</p>
                  </div>
                  <div
                    class="mt-20"
                    v-if="cuShowMenuTable === 'et_'+etype.value"
                  >
                    <a-collapse
                      class="inner-collapse"
                      :activeKey="'et_'+etype.value"
                    >
                      <a-collapse-panel
                        :key="'et_'+etype.value"
                        header="可选菜单列表"
                      >
                        <food-recipe-list
                          :sourceMark="'choose'"
                          :arrIdx="{type:'et_'+etype.value}"
                          @sureChooseRecipe="sureChooseRecipe"
                        />
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
                <a-divider class="mb-15" />
              </div>
              <a-row class="mt-20">
                <a-col
                  :offset="11"
                  :span="12"
                >
                  <a-button
                    icon="check"
                    @click="clickPatMenuDaySave()"
                    type="primary"
                  >确认保存</a-button>
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="6">
              <!-- 食谱的各种营养元素展示 -->
              <daily-food-menu-view-chart :dailyFoodMenuDetail="chooseFoodArr.food_num > 0 ? chooseFoodArr.calcPower : {}" />
            </a-col>
          </a-row>
        </div>
      </a-card>
      <!-- 食谱详情弹窗组件 -->
      <food-recipe-detail-modal
        :recipeDetail="recipeDetail"
        :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
        @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
      />
    </a-spin>
  </div>
</template>

<script>
import { saveDailyFoodMenu, removeOneFoodMenuRecipe, viewDailyFoodMenu } from '@/api/dms/dailyFoodMenu'
import { FoodRecipeList, FoodRecipeDetailModal } from '@/components/FoodRecipes'
import { DailyFoodMenuViewChart } from '@/components/DailyFoodMenus'
import { rmFile, getSelectListTag } from '@/api/manage'
import { mathAddNum, mathSubtractNum } from '@/utils/util'
import moment from 'moment'

export default {
  name: 'AddDailyFoodMenu',
  components: {
    FoodRecipeList,
    FoodRecipeDetailModal,
    DailyFoodMenuViewChart
  },
  data () {
    return {
      spinning: false,
      showRecipeColumns: [
        {
          title: '序号',
          dataIndex: 'id',
          width: 50,
          customRender: (text, row, idx) => {
            return idx + 1
          }
        },
        {
          title: '名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: true,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 50,
          dataIndex: 'eating_count',
          ellipsis: true,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '适合人数',
          width: 70,
          dataIndex: 'person',
          ellipsis: true,
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '操作',
          ellipsis: true,
          width: 90,
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      cformFoodApi: {},
      cformFoodRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '食谱名称',
          field: 'menu_name',
          props: { placeholder: '请填写食谱名称' },
          validate: [
            { required: true, message: '请填写食谱名称', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '是否共享',
          value: '0',
          field: 'is_open',
          props: {
            size: "small", buttonStyle: 'solid', placeholder: '请选择是否共享'
          },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否共享', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '适用场景',
          field: 'fm_tags',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { mode: 'tags', placeholder: '请选择适用场景', allowClear: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'input',
          title: '食谱介绍',
          field: 'menu_intro',
          props: { type: 'textarea', autoSize: { minRows: 4 }, placeholder: '请填写食谱介绍' }
        },
        {
          type: 'upload',
          field: 'img_url',
          title: '封面图',
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/food_menu/patient' },
            limit: 1,
            listType: 'picture-card'
          },
        },
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      chooseFoodArr: { food_num: 0, calcPower: { calory: 0, joule: 0, protein: 0, fat: 0, carbohydrate: 0, other_nutrition_info: {} } },
      recipeDetail: {},
      foodRecipeDetailModalVisible: false,
      cuShowMenuTable: null,
      eatingTypeArr: [],
      fmenu_img: '',
      menuNameDef: '',
      nutrientMapColumn: []
    }
  },
  created () {
    this.spinning = true
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('DailyFoodMenus.addDailyFoodMenu'))
    // 删除参数避免一直存着，此页面是新增、编辑共用的
    sessionStorage.removeItem('DailyFoodMenus.addDailyFoodMenu')
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformFoodApi.updateRules({ // 更新规则
          'fm_tags': { options: res.data }
        })
        this.eatingTypeArr = this.$options.filters.selectOptionsData('health.eating_type')
        // 获取所有的其他营养元素的字段
        this.nutrientMapColumn = this.$options.filters.getDicTextFieldValueByKey('nutrient.map', 'column', 'type', [1])
        this.setDefMenuName()
        if (this.params !== null && this.params.fm !== undefined && this.params.fm > 0) {
          this.loadOriFoodMenuData()
        } else {
          this.spinning = false
        }
      } else {
        this.spinning = false
      }
    })
  },
  methods: {
    moment,
    setDefMenuName () {
      this.menuNameDef = '定制食谱' + moment().format('YYYYMMDDHHmmss')
      this.cformFoodApi.updateRules({ menu_name: { props: { placeholder: this.menuNameDef } } })
    },
    loadOriFoodMenuData () {
      this.spinning = true
      viewDailyFoodMenu({ menu: this.params.fm, enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, join: ['tags', 'recipe'] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.fmenu_img = res.data.img_url
          // 回显编辑数据
          const tagArr = []
          res.data.daily_food_menu_tag.map(tgv => {
            tagArr.push(tgv.tag_id.toString())
          })
          this.cformFoodApi.setValue({ id: res.data.id, menu_name: res.data.menu_name, is_open: res.data.is_open.toString(), menu_intro: res.data.menu_intro, fm_tags: tagArr, img_url: res.data.img_show })
          // 组装菜单的列表数据
          res.data.daily_food_menu_recipe.map((recipe) => {
            const eType = 'et_' + recipe.eating_type
            if (this.chooseFoodArr[eType] === undefined) {
              this.chooseFoodArr[eType] = []
            }
            const isCheck = this.chooseFoodArr[eType].filter((cup) => cup.recipe_def_id === recipe.recipe_def_id)
            if (isCheck.length < 1) {
              this.calcPowerData(recipe)
              this.chooseFoodArr[eType].push(recipe)
              this.chooseFoodArr.food_num += 1
            }
          })
        }
        this.spinning = false
      })
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.fmenu_img = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.fmenu_img }).then((res) => {
        this.spinning = false
        this.fmenu_img = ''
        if (res.status !== undefined && res.status === 1) {
          this.cformFoodApi.setValue({ img_url: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    sureChooseRecipe (recipeInfo) {
      // 组件确认加入一个食谱
      if (recipeInfo.arrIdx !== undefined && recipeInfo.recipe !== undefined && recipeInfo.recipe.id !== undefined && recipeInfo.arrIdx.type !== undefined) {
        if (this.chooseFoodArr[recipeInfo.arrIdx.type] === undefined) {
          this.chooseFoodArr[recipeInfo.arrIdx.type] = []
        }
        const isCheck = this.chooseFoodArr[recipeInfo.arrIdx.type].filter((cup) => cup.id === recipeInfo.recipe.id)
        if (isCheck.length > 0) {
          this.$message.error('该菜单菜谱已选择！')
          return false
        } else {
          console.log(recipeInfo.recipe)
          this.calcPowerData(recipeInfo.recipe)
          this.chooseFoodArr[recipeInfo.arrIdx.type].push(recipeInfo.recipe)
          this.chooseFoodArr.food_num += 1
        }
      } else {
        this.$message.error('请确认所选菜单菜谱存在可操作！')
        return false
      }
    },
    calcPowerData (recipe, mark = 'add') {
      // 计算各种营养值
      const cuPd = JSON.parse(JSON.stringify(this.chooseFoodArr.calcPower))
      this.chooseFoodArr.calcPower.calory = (mark === 'add') ? mathAddNum(cuPd.calory, recipe.calory) : mathSubtractNum(cuPd.calory, recipe.calory)
      this.chooseFoodArr.calcPower.joule = (mark === 'add') ? mathAddNum(cuPd.joule, recipe.joule) : mathSubtractNum(cuPd.joule, recipe.joule)
      this.chooseFoodArr.calcPower.protein = (mark === 'add') ? mathAddNum(cuPd.protein, recipe.protein) : mathSubtractNum(cuPd.protein, recipe.protein)
      this.chooseFoodArr.calcPower.fat = (mark === 'add') ? mathAddNum(cuPd.fat, recipe.fat) : mathSubtractNum(cuPd.fat, recipe.fat)
      this.chooseFoodArr.calcPower.carbohydrate = (mark === 'add') ? mathAddNum(cuPd.carbohydrate, recipe.carbohydrate) : mathSubtractNum(cuPd.carbohydrate, recipe.carbohydrate)
      this.chooseFoodArr.calcPower.calory_unit = recipe.calory_unit
      this.chooseFoodArr.calcPower.joule_unit = recipe.joule_unit
      this.chooseFoodArr.calcPower.protein_unit = recipe.protein_unit
      this.chooseFoodArr.calcPower.fat_unit = recipe.fat_unit
      this.chooseFoodArr.calcPower.carbohydrate_unit = recipe.carbohydrate_unit

      for (let rk in recipe) {
        if (this.nutrientMapColumn.indexOf(rk) >= 0) {
          // 需要计算的微量元素
          const cuOt = JSON.parse(JSON.stringify(this.chooseFoodArr.calcPower.other_nutrition_info))
          cuOt[rk] = cuOt[rk] === undefined ? '' : cuOt[rk]
          this.chooseFoodArr.calcPower.other_nutrition_info[rk] = (mark === 'add') ? mathAddNum(cuOt[rk], recipe[rk]) : mathSubtractNum(cuOt[rk], recipe[rk])
        }
      }
    },
    clickRemoveRecipe (field, idx) {
      // 删除指定已选的菜谱
      if (this.chooseFoodArr[field][idx] === undefined) {
        this.$message.error('请确认所选菜谱存在可操作！')
        return false
      }
      let fmData = this.cformFoodApi.formData()
      if (fmData.id !== undefined && this.chooseFoodArr[field][idx].fmr_id !== undefined) {
        // 有id则需要删除后台数据
        this.spinning = true
        removeOneFoodMenuRecipe({ fm: fmData.id, user: this.$store.getters.userInfo.user.id, etype: field, recipe_def: this.chooseFoodArr[field][idx].fmr_id }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.calcPowerData(this.chooseFoodArr[field][idx], 'subtract')
            this.chooseFoodArr[field].splice(idx, 1)
            this.chooseFoodArr.food_num -= 1
          } else {
            this.$message.error(res.msg || '食谱配置信息删除失败！请稍后重试！')
          }
          this.spinning = false
        })
      } else {
        this.calcPowerData(this.chooseFoodArr[field][idx], 'subtract')
        this.chooseFoodArr[field].splice(idx, 1)
        this.chooseFoodArr.food_num -= 1
      }
    },
    clickViewRecipe (record) {
      // 查看已选食谱详情弹窗
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    foodRecipeDetailModalVisibleChange (v) {
      // 食谱详情弹窗事件监听
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    },
    clickShowMenu (field) {
      if (this.cuShowMenuTable === field) {
        this.cuShowMenuTable = null
      } else {
        this.cuShowMenuTable = field
      }
    },
    clickPatMenuDaySave () {
      if (this.chooseFoodArr.food_num < 1) {
        // 验证提示不为空
        this.$message.error('请确认选择菜单！')
        return false
      }
      this.spinning = true
      let fmData = this.cformFoodApi.formData()
      if (fmData.menu_name === undefined || fmData.menu_name === null || fmData.menu_name === '') {
        fmData.menu_name = this.menuNameDef
      }
      fmData.img_url = this.fmenu_img
      fmData.choose_foods = this.chooseFoodArr
      const that = this
      saveDailyFoodMenu(fmData).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$confirm({
            title: '一日食谱保存成功！',
            content: false,
            cancelText: '继续添加一日食谱',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              that.cformFoodApi.resetFields()
              that.fmenu_img = ''
              that.chooseFoodArr = { food_num: 0, calcPower: { calory: 0, joule: 0, protein: 0, fat: 0, carbohydrate: 0, other_nutrition_info: {} } }
              that.setDefMenuName()
            }
          })
        } else {
          this.$message.error(res.msg || '食谱定制相关信息保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -5px;
}
/deep/ .inner-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.right-icon-text {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
</style>