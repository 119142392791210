<template>
  <a-drawer
    width="600"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="dietaryDrawerVisibleCu"
    title="饮食习惯信息修改"
    @close="closeDietaryDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <form-create
        id="dietary-info"
        v-model="cformDietaryApi"
        :rule="cformDietaryRule"
        :option="cformOption"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="8"
          :span="12"
        >
          <a-button
            icon="close"
            @click="closeDietaryDrawer"
            type="default"
            class="mr-5"
          >关闭</a-button>
          <a-button
            icon="check"
            @click="clickSave"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>

    <!-- 饮食模式的抽屉窗口 -->
    <dietary-pattern-detail
      :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
      :parentParams="dietaryPatternParams"
      @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
    />
  </a-drawer>
</template>

<script>
import { getPatientNewestBodyInfo, savePatientDietaryInfo } from '@/api/dms/patient'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { DietaryPatternDetail } from '@/components/DietaryPattern'

export default ({
  name: 'DietaryInfoDrawer',
  props: {
    dietaryDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  components: {
    DietaryPatternDetail
  },
  watch: {
    dietaryDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.dietaryDrawerVisibleCu = this.dietaryDrawerVisible
        if (this.dietaryDrawerVisible) {
          this.spinning = true

          // 获取已有信息
          this.loadPatientOriData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      dietaryDrawerVisibleCu: false,
      cformDietaryApi: {},
      cformDietaryRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '一周运动量',
          field: 'activity_level',
          props: { size: 'small', placeholder: '请选一周运动量' },
          options: [],
          on: {
            change: (v) => this.calorieCalcChange(v)
          }
        },
        {
          type: 'input',
          title: '热量摄入建议',
          field: 'calorie', style: { width: '100%' },
          props: { type: 'number', size: 'small', placeholder: '请填写热量摄入建议', min: 0, step: 1, max: 1000000, addonAfter: 'Kcal' }
        },
        {
          type: 'slider',
          title: '碳水摄入建议',
          field: 'carbohydrates',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          //tooltipVisible: true,
          props: { placeholder: '请选择碳水摄入建议', min: 0, step: 1, max: 100, marks: { 55: '55%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'carbohydrates')
          }
        },
        {
          type: 'slider',
          title: '蛋白质摄入',
          field: 'protein',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          //tooltipVisible: true,
          props: { placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, marks: { 15: '15%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'protein')
          }
        },
        {
          type: 'slider',
          title: '脂肪摄入建议',
          field: 'fat',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          //tooltipVisible: true,
          props: { placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, marks: { 30: '30%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'fat')
          }
        },
        {
          type: 'input',
          title: '日饮水量',
          field: 'water_intake',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { type: 'number', size: 'small', placeholder: '请填写日饮水量', min: 0, step: 1, max: 127, addonAfter: '升' },
        },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          props: { size: 'small', placeholder: '请填写营养补充剂' }
        },
        {
          type: 'input',
          title: '营养建议',
          field: 'recommendation',
          props: { size: 'small', placeholder: '请填写营养建议' }
        },
        {
          type: 'select',
          title: '饮食模式推荐',
          field: 'dietary_pattern_id',
          props: { mode: 'multiple', size: 'small', placeholder: '请选饮食模式推荐', maxTagCount: 2, maxTagTextLength: 7, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          col: {
            span: 19
          },
          wrap: {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
          }
        },
        {
          type: 'a-button',
          name: 'a-button',
          class: 'ml-15',
          style: 'margin-top:8px;',
          col: {
            span: 5
          },
          props: { size: 'small', icon: 'file-protect', type: 'primary' },
          children: ['详情'],
          on: {
            click: () => this.clickshowPattern()
          }
        },
        {
          type: 'select',
          title: '喜好饮食类型',
          field: 'favorite_diet_type',
          props: { mode: 'multiple', placeholder: '请选择喜好饮食类型', maxTagCount: 2, maxTagTextLength: 10, allowClear: true, showSearch: true, size: 'small', optionFilterProp: 'children' },
          options: [],
        },
        {
          type: 'input',
          title: '喜好饮食备注',
          field: 'favorite_diet_type_remarks',
          props: { size: 'small', placeholder: '请填写喜好饮食备注' }
        },
        {
          type: 'input',
          field: 'food_favorite',
          title: '喜欢的食物',
          props: { size: 'small', placeholder: '请填写喜欢的食物' }
        },
        {
          type: 'input',
          field: 'food_dislike',
          title: '不喜欢的食物',
          props: { size: 'small', placeholder: '请填写不喜欢的食物' }
        },
        {
          type: 'input',
          title: '过敏食物',
          field: 'allergies_remarks',
          props: { size: 'small', placeholder: '请填写过敏食物' }
        },
        {
          type: 'input',
          title: '不耐受食物',
          field: 'food_intolerant_remarks',
          props: { size: 'small', placeholder: '请填写不耐受食物' }
        },
        {
          type: 'input',
          title: '缺乏营养',
          field: 'nutrition_deficiency_remarks',
          props: { size: 'small', placeholder: '请填写缺乏营养' }
        },
        {
          type: 'input',
          title: '其他备注信息',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, size: 'small', placeholder: '请填写其他备注信息' }
        },
      ],
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 20 }
        },
        submitBtn: false
      },
      params: {},
      bodyInfo: [],
      cpfOri: { carbohydrates: 55, protein: 15, fat: 30 },
      dietaryPatternSelectArr: [],
      dietaryPatternParams: {},
      dietaryPatternDrawerVisible: false
    }
  },
  created () {

  },
  methods: {
    loadPatientOriData () {
      this.$nextTick(() => {
        // 获取体格信息
        getPatientNewestBodyInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
          if (res2.status !== undefined && res2.status === 1) {
            this.bodyInfo = res2.data
          }
        })
        // 获取饮食模式下拉数据
        selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.dietaryPatternSelectArr = res.data
            this.cformDietaryApi.updateRules({ // 更新规则
              'dietary_pattern_id': { options: this.dietaryPatternSelectArr }
            })
            this.cformDietaryApi.setValue({ dietary_pattern_id: JSON.parse(this.parentParams.oriInfo.dietary_pattern_id) })
          }
        })
        this.cformDietaryApi.updateRules({ // 更新规则
          'favorite_diet_type': { options: this.$options.filters.selectOptionsData('patient.favorite_diet_type') },
          'activity_level': { options: this.$options.filters.selectOptionsData('calorie.calc') },
        })
        // 有传入原数据才需要回显
        if (this.parentParams.oriInfo.id !== undefined && this.parentParams.oriInfo.id > 0) {
          console.log(this.parentParams.oriInfo)
          this.cformDietaryApi.setValue(this.parentParams.oriInfo)
          this.cformDietaryApi.updateRules({
            carbohydrates: { props: { marks: { [this.parentParams.oriInfo.carbohydrates]: this.parentParams.oriInfo.carbohydrates + '%' } } },
            protein: { props: { marks: { [this.parentParams.oriInfo.protein]: this.parentParams.oriInfo.protein + '%' } } },
            fat: { props: { marks: { [this.parentParams.oriInfo.fat]: this.parentParams.oriInfo.fat + '%' } } }
          })
        } else {
          this.cformDietaryApi.setValue({ patient_id: this.parentParams.patient })
        }
        this.spinning = false
      })
    },
    closeDietaryDrawer () {
      this.dietaryDrawerVisibleCu = false
      this.$emit('dietaryDrawerVisibleChange', false)
    },
    calorieCalcChange (v) {
      console.log(this.bodyInfo)
      // 写个中间默认值
      let cuBmr = 1600
      for (let bfn in this.bodyInfo) {
        if (bfn.indexOf('bmr') >= 0) {
          cuBmr = bodyFormData[bfn]
        }
      }
      // 根据选择的运动量和bmr计算推荐热量
      this.cformDietaryApi.setValue({ calorie: parseInt(cuBmr * v) })
    },
    cpfChange (v, field) {
      // 另外两个字段同步自动计算
      if (this.cpfOri[field] === undefined) {
        return false
      }
      const upNuRulesArr = {}
      const upNuValuesArr = {}
      upNuRulesArr[field] = { props: { marks: { [v]: v + '%' } } }
      upNuValuesArr[field] = v
      // 用差值先计算小值，再用100%计算剩下的值
      const diff = v - this.cpfOri[field]
      // 当前字段增加变大，另外两个字段就这个差值的平均后减小
      // const diff1 = Math.ceil((v - this.cpfOri[field]) / 2)
      // const diff2 = Math.floor((v - this.cpfOri[field]) / 2)
      // const diffMin = Math.abs(diff1) <= Math.abs(diff2) ? diff1 : diff2
      // const diffMax = Math.abs(diff1) >= Math.abs(diff2) ? diff1 : diff2
      const otherCpfArr = JSON.parse(JSON.stringify(this.cpfOri))
      delete otherCpfArr[field]
      const valArr = Object.values(otherCpfArr)
      const keyArr = Object.keys(otherCpfArr)
      // 比较剩余哪个字段的默认值大，对应减小diffMax
      // 第一个大为默认
      // let key0Val = valArr[0] - diffMax
      // let key1Val = valArr[1] - diffMin
      let key0Val = 1
      let key1Val = 1
      // 按剩余2个字段的百分比计算小值
      if (valArr[0] <= valArr[1]) {
        const minNum = Math.floor(valArr[0] / (valArr[0] + valArr[1]) * diff)
        key0Val = valArr[0] - minNum
        key1Val = 100 - v - key0Val
      } else {
        const minNum = Math.floor(valArr[1] / (valArr[0] + valArr[1]) * diff)
        key1Val = valArr[1] - minNum
        key0Val = 100 - v - key1Val
      }
      key0Val = key0Val < 0 ? 0 : key0Val
      key0Val = key0Val > 100 ? 100 : key0Val
      key1Val = key1Val < 0 ? 0 : key1Val
      key1Val = key1Val > 100 ? 100 : key1Val

      upNuRulesArr[keyArr[0]] = { props: { marks: { [key0Val]: key0Val + '%' } } }
      upNuRulesArr[keyArr[1]] = { props: { marks: { [key1Val]: key1Val + '%' } } }
      this.cformDietaryApi.updateRules(upNuRulesArr)
      this.cformDietaryApi.setValue({ [keyArr[0]]: key0Val, [keyArr[1]]: key1Val, field: v })
    },
    clickshowPattern () {
      const cuPattern = this.cformDietaryApi.getValue('dietary_pattern_id')
      if (cuPattern === undefined || cuPattern < 0) {
        this.$message.error('请先选择左侧推荐饮食模式！')
        return false
      }
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    clickSave () {
      this.cformDietaryApi.validate((valid, fail) => {
        if (valid) {
          this.spinning = true
          savePatientDietaryInfo(Object.assign({}, this.cformDietaryApi.formData())).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.cformDietaryApi.resetFields()
              this.closeDietaryDrawer()
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })

        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
/deep/ .ant-form-item {
  margin-bottom: 8px;
}
</style>