<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="6">
                <a-col
                  :md="4"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="全部"
                      :options="'client_appointment.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="预约时间">
                    <a-range-picker
                      inputReadOnly
                      :allowClear="false"
                      style="width:100%;"
                      :ranges="{ '今天': [moment(), moment()], '本周': [moment().startOf('week'), moment().endOf('week')], '本月': [moment().startOf('month'), moment().endOf('month')], '最近三月': [moment(), moment().add(3, 'months')], '本年': [moment().startOf('year'), moment()] }"
                      valueFormat="YYYY-MM-DD"
                      v-model="queryParam.appoint_date"
                    >
                      <a-icon
                        slot="suffixIcon"
                        type="calendar"
                      />
                    </a-range-picker>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="客户联系电话搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.logsListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = { appoint_date:[moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],status:'10' })"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="logsListTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="appoint_type"
            slot-scope="text"
          >
            {{ 'client_appointment.appoint_type' | showText(text) }}
          </span>
          <span
            slot="service_type"
            slot-scope="text"
          >
            {{ 'client_appointment.service_type' | showText(text) }}
          </span>
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'client_appointment.status' | showText(text)"
            />
          </span>
          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/HisPatients/viewPatient' | isShowButton">
                <a @click="clickView(record)">详情</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/HisPatients/editPatient'] | isShowSeparator(['/HisPatients/viewPatient'])"
              />

              <a-dropdown :hidden="['/HisPatients/editPatient'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientDietaryInfo' | isShowButton"
                    v-if="record.status === 10"
                    @click="clickDietary(record)"
                    key="1"
                  >问诊&预约处理</a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientMeasureInfo' | isShowButton"
                    @click="clickMeasure(record)"
                    key="2"
                  >检测结果录入</a-menu-item>
                  <a-menu-divider :hidden="['/HisPatients/addPatientFoodDaryInfo','/HisPatients/addPatientActiveInfo','/HisPatients/addPatientGoalInfo'] | isShowSeparator(['/HisPatients/addPatientDietaryInfo','/HisPatients/addPatientMeasureInfo'])" />
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientFoodDaryInfo' | isShowButton"
                    @click="clickFoodDary(record)"
                    key="3"
                  >日常餐饮录入</a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientActiveInfo' | isShowButton"
                    @click="clickActive(record)"
                    key="4"
                  >活动日志录入</a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientGoalInfo' | isShowButton"
                    @click="clickGoal(record)"
                    key="5"
                  >目标日志录入</a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

  </div>
</template>

<script>
import { getClientAppointmentList } from '@/api/dms/clientAppointment'
import moment from 'moment'

export default {
  name: 'ListAppointmentForDoc',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '客户姓名',
          width: 100,
          dataIndex: 'patient_name',
          ellipsis: true
        },
        {
          title: '联系电话',
          width: 135,
          dataIndex: 'contact_phone',
          ellipsis: true
        },
        {
          title: '预约时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'appoint_date'
        },
        {
          title: '预约序号',
          width: 75,
          ellipsis: true,
          align: 'center',
          dataIndex: 'appoint_seq'
        },
        {
          title: '预约备注',
          ellipsis: true,
          dataIndex: 'remarks'
        },
        {
          title: '预约类型',
          width: 70,
          ellipsis: true,
          dataIndex: 'appoint_type',
          scopedSlots: { customRender: 'appoint_type' }
        },
        {
          title: '服务方式',
          width: 70,
          ellipsis: true,
          dataIndex: 'service_type',
          scopedSlots: { customRender: 'service_type' }
        },
        {
          title: '当前状态',
          width: 80,
          ellipsis: true,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 110,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: { appoint_date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], status: '10' },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ service_user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, dep_id: this.$store.getters.userInfo.user.department_id, appoint_type: [0, 1] }, parameter, this.queryParam)
        return getClientAppointmentList(requestParameters)
          .then(res => {
            return res
          })
      }
    }
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {

  },
  methods: {
    moment,
    clickEdit (record) {
      // 编辑
      // sessionStorage.setItem('HisPatients.editPatient', JSON.stringify({ patient: record.patient_id }))
      // this.$router.push({ path: '/HisPatients/editPatient' })
    },
    clickView (record) {

    },
    clickDietary (record) {
      // 第一版单独存，没有service_record记录的版本
      // sessionStorage.setItem('HisPatients.addPatientDietaryInfo', JSON.stringify({ patient: record.patient_id, clientAppointment: record.id, dealTime: record.deal_time, dealRemarks: record.deal_remarks }))
      // this.$router.push({ path: '/HisPatients/addPatientDietaryInfo' })
      // 第二版分三步依次填写版本，要先存service_record
      sessionStorage.setItem('HisPatients.setServiceRecord', JSON.stringify({ patient: record.patient_id, appoint: record.id }))
      this.$router.push({ path: '/HisPatients/setServiceRecord' })
    },
    clickMeasure (record) {
      sessionStorage.setItem('HisPatients.addPatientMeasureInfo', JSON.stringify({ patient: record.patient_id }))
      this.$router.push({ path: '/HisPatients/addPatientMeasureInfo' })
    },
    clickFoodDary (record) {
      sessionStorage.setItem('HisPatients.addPatientFoodDaryInfo', JSON.stringify({ patient: record.patient_id }))
      this.$router.push({ path: '/HisPatients/addPatientFoodDaryInfo' })
    },
    clickActive (record) {
      sessionStorage.setItem('HisPatients.addPatientActiveInfo', JSON.stringify({ patient: record.patient_id }))
      this.$router.push({ path: '/HisPatients/addPatientActiveInfo' })
    },
    clickGoal (record) {
      sessionStorage.setItem('HisPatients.addPatientGoalInfo', JSON.stringify({ patient: record.patient_id }))
      this.$router.push({ path: '/HisPatients/addPatientGoalInfo' })
    }
  }
}
</script>
