import request from '@/utils/request'

const apiUrls = {
    selectListFoodCaloryCategory: '/FoodCalories/selectListFoodCaloryCategory',
    listFoodCalory: '/FoodCalories/listFoodCalory',
    viewFoodCalory: '/FoodCalories/viewFoodCalory',
}

export function selectListFoodCaloryCategory (parameter) {
    return request({
        url: apiUrls.selectListFoodCaloryCategory,
        method: 'get',
        params: parameter
    })
}

export function listFoodCalory (parameter) {
    return request({
        url: apiUrls.listFoodCalory,
        method: 'get',
        params: parameter
    })
}

export function viewFoodCalory (parameter) {
    return request({
        url: apiUrls.viewFoodCalory,
        method: 'get',
        params: parameter
    })
}