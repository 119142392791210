<template>
  <div>
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="全部"
                      :options="'user.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入用户名/手机号搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.adminUserTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="adminUserTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="gender"
            slot-scope="text"
          >
            {{ 'ryx.gender' | showText(text.toString()) }}
          </span>

          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'user.status' | showText(text)"
            />
          </span>

          <span
            slot="user_tag"
            slot-scope="text,record"
          >
            <template>
              <a-tag
                color="orange"
                v-for="tag in record.user_tag"
                :key="tag.tag_id"
              >
                {{tag.tag_text}}
              </a-tag>
            </template>
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span>
                <a @click="clickDocView(record)">详情</a>
              </span><a-divider
                type="vertical"
                :hidden="['/HisPatients/setServiceRecord'] | isShowSeparator(['/HisPatients/setServiceRecord'])"
              />
              <span :hidden="'/HisPatients/setServiceRecord' | isShowButton">
                <a @click="clickDocAppointShow(record)">预约记录</a>
              </span>
            </template>
          </span>

          <a-table
            slot="expandedRowRender"
            slot-scope="item"
            rowKey="id"
            :columns="innerColumns"
            :data-source="item.manage_user"
            :pagination="false"
            :bordered="true"
            class="mr-10 my-5"
            size="middle"
          >
            <span
              slot="gender2"
              slot-scope="text"
            >
              {{ 'ryx.gender' | showText(text.toString()) }}
            </span>
            <span
              slot="action2"
              slot-scope="text, record"
            >
              <template>
                <span :hidden="'/HisPatients/setServiceRecord' | isShowButton">
                  <a @click="clickSetServiceRecord(record)">问诊记录</a>
                </span>
                <a-divider
                  type="vertical"
                  :hidden="['/HisPatients/setServiceRecord'] | isShowSeparator(['/HisPatients/setServiceRecord'])"
                />
                <span :hidden="'/HisPatients/setServiceRecord' | isShowButton">
                  <a @click="clickAppointShow(record,item.id)">预约记录</a>
                </span>
              </template>
            </span>
          </a-table>
        </s-table>
      </a-card>

      <!-- 客户问诊服务记录列表 -->
      <ServiceRecordDrawer ref="serviceRecordDrawer" />

      <!-- 查看营养师或者客户的预约记录 -->
      <ClientAppointmentDrawer ref="clientAppointmentDrawer" />
    </a-spin>
  </div>
</template>

<script>
import { listUser } from '@/api/dms/user'
import { ServiceRecordDrawer } from '@/components/ServiceRecord'
import { ClientAppointmentDrawer } from '@/components/Schedule'

const statusMap = {
  1: {
    status: 'default',
    text: '待激活'
  },
  2: {
    status: 'success',
    text: '正常'
  },
  3: {
    status: 'error',
    text: '注销'
  }
}

export default {
  name: 'ListDocUser',
  components: { ServiceRecordDrawer, ClientAppointmentDrawer },
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 60,
          dataIndex: 'id'
        },
        {
          title: '姓名',
          width: 120,
          dataIndex: 'nick_name',
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 60,
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 120,
          ellipsis: true
        },
        {
          title: '所属企业',
          dataIndex: 'enterprise_name',
          ellipsis: true
        },
        {
          title: '所属部门',
          dataIndex: 'department_name',
          width: 120,
          ellipsis: true
        },
        {
          title: '状态',
          width: 95,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '擅长',
          dataIndex: 'user_tag',
          scopedSlots: { customRender: 'user_tag' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: 'ID',
          width: 60,
          dataIndex: 'id'
        },
        {
          title: '客户姓名',
          // width: 80,
          dataIndex: 'name'
        },
        {
          title: '编号',
          // width: 120,
          dataIndex: 'patient_no'
        },
        {
          title: '性别',
          width: 60,
          dataIndex: 'gender',
          scopedSlots: { customRender: 'gender2' }
        },
        {
          title: '出生年月',
          // width: 120,
          dataIndex: 'birth_date'
        },
        {
          title: '联系电话',
          // width: 120,
          dataIndex: 'contact_phone'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 150,
          scopedSlots: { customRender: 'action2' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ user_type: 50, join: ['department', 'tags', 'relation', 'doctor'], main: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listUser(requestParameters)
          .then(res => {
            console.log(res)
            // if (res.data.length > 0) {
            //   that.tableScroll = { x: 1500 }
            // } else {
            //   that.tableScroll = {}
            // }
            return res
          })
      }
    }
  },
  filters: {
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {

  },
  methods: {
    clickDocAppointShow (record) {
      this.$refs.clientAppointmentDrawer.showDrawer({ service_user: record.id })
    },
    clickAppointShow (record, doc) {
      this.$refs.clientAppointmentDrawer.showDrawer({ patient: record.id, service_user: doc })
    },
    clickDocView (record) {
      sessionStorage.setItem('Enterprises.viewDocUser', JSON.stringify({ user: record.id }))
      this.$router.push({ path: '/Enterprises/viewDocUser' })
    },
    clickSetServiceRecord (record) {
      const cuRow = JSON.parse(JSON.stringify(record))
      // 不可编辑和新增，只能打印
      cuRow.notEdit = true
      // 可以评价
      cuRow.rate = true
      this.$refs.serviceRecordDrawer.showDrawer(cuRow)
    }
  }
}
</script>

<style lang="scss" scoped>
.adminUserForm {
  /deep/ .ant-form-item {
    margin-bottom: 10px;
  }
  /deep/ .ant-form-item:last-child {
    margin-bottom: 0;
  }
}
</style>
