<template>
  <a-drawer
    width="600"
    :maskClosable="true"
    placement="right"
    :closable="true"
    :visible="patFoodMenuDrawerVisibleCu"
    @close="closePatFoodMenuDrawer"
    id="drawer-body-div"
  >
    <div slot="title">
      <a-row>
        <a-col
          :span="12"
          class="title-text"
        >
          客户定制食谱列表
        </a-col>
        <a-col
          :span="12"
          class="text-right"
        >
          <a-button
            type="primary"
            icon="plus"
            size="small"
            class="mr-30"
            @click="clickNewOrUpdateRecord(false)"
          >新增定制食谱</a-button>
        </a-col>
      </a-row>
    </div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="patFoodMenuSpinning"
    >
      <div v-if="patFoodMenuInfoArr.length > 0">
        <div
          v-for="(rdv,idx) in patFoodMenuInfoArr"
          :key="idx"
        >
          <a-row>
            <a-col :span="18">
              <a-row>
                食谱名称： {{ rdv.menu_name }}
              </a-row>
              <a-row>
                <a-popover
                  title="菜谱介绍"
                  trigger="hover"
                  placement="left"
                >
                  <template slot="content">
                    <p class="mb-0">菜谱介绍： {{ rdv.menu_intro }}</p>
                    <p class="mb-0">不喜欢食物： {{ rdv.food_dislike }}</p>
                    <p class="mb-0">过敏食物： {{ rdv.allergies }}</p>
                    <p class="mb-0">不耐受食物： {{ rdv.food_intolerant }}</p>
                    <p class="mb-0">营养补充剂： {{ rdv.supplement }}</p>
                  </template>
                  菜谱摘要：{{ rdv.menu_intro !== null ? rdv.menu_intro.substr(0,14) : '' }}
                  <a-icon
                    type="small-dash"
                    theme="outlined"
                  />
                </a-popover>

              </a-row>
              <a-row>
                当前状态：
                <span
                  :class="rdv.status < 10 ? 'font-error' : 'font-success'"
                  class="font-weight"
                >
                  {{ 'service_records.step' | showText(rdv.status.toString()) }}
                </span>
              </a-row>
            </a-col>
            <a-col
              :span="6"
              class="text-right"
            >
              <a-button
                type="danger"
                icon="form"
                size="small"
                @click="clickNewOrUpdateRecord(rdv)"
              >编辑</a-button>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-row>
                食谱日期： {{ rdv.start_date }} ~ {{ rdv.end_date }}
              </a-row>
              <a-row>
                更新时间： {{ rdv.update_time }}
              </a-row>
            </a-col>
          </a-row>

          <div
            @click="clickLoadMore"
            v-if="idx >= patFoodMenuInfoArr.length - 1"
            class="more-div"
          >
            <a-divider>{{ page > lastPage ? '下面没有啦~~' : '点击加载更多'}}</a-divider>
          </div>
          <a-divider v-else />
        </div>
      </div>
      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
      />
    </a-spin>
  </a-drawer>
</template>

<script>
import { listPatientFoodMenu } from '@/api/dms/patientFoodMenu'

export default ({
  name: 'PatientFoodMenuListDrawer',
  props: {
    patFoodMenuDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    patFoodMenuDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.patFoodMenuDrawerVisibleCu = this.patFoodMenuDrawerVisible
        if (this.patFoodMenuDrawerVisible) {
          this.patFoodMenuSpinning = true
          this.patFoodMenuInfoArr = []
          this.page = 1
          this.lastPage = 1
          this.loadPatientFoodMenuData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      patFoodMenuDrawerVisibleCu: false,
      patFoodMenuSpinning: false,
      patFoodMenuInfoArr: [],
      pageSize: 2,
      page: 1,
      lastPage: 1,
    }
  },
  created () {
  },
  methods: {
    closePatFoodMenuDrawer () {
      this.patFoodMenuDrawerVisibleCu = false
      this.patFoodMenuInfoArr = []
      this.page = 1
      this.lastPage = 1
      this.$emit('patFoodMenuDrawerVisibleChange', false)
    },
    clickLoadMore () {
      if (this.page > this.lastPage) {
        this.$message.error('下面没有数据啦~~！')
        return false
      }
      this.loadPatientFoodMenuData()
    },
    loadPatientFoodMenuData () {
      this.patFoodMenuSpinning = true
      listPatientFoodMenu({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id, page: this.page, pageSize: this.pageSize }).then((res2) => {
        console.log(res2)
        if (res2.status !== undefined && res2.status === 1) {
          this.patFoodMenuInfoArr = this.patFoodMenuInfoArr.concat(res2.data)
          this.lastPage = res2.last_page
          this.page++
        }
        this.patFoodMenuSpinning = false
      })
    },
    clickNewOrUpdateRecord (rdv = false) {
      const cuPm = { patient: this.parentParams.patient }
      if (rdv !== undefined && rdv.id > 0) {
        cuPm.fm = rdv.id
      }
      sessionStorage.setItem('HealthPatientFoodMenus.setPatientFoodMenu', JSON.stringify(cuPm))
      this.$router.push({ path: '/HealthPatientFoodMenus/setPatientFoodMenu' })
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
#drawer-body-div {
  /deep/ .ant-drawer-close {
    height: 66px !important;
  }
  .title-text {
    line-height: 34px;
  }
}
</style>