import { saveSurveyResponseInfo } from '@/api/dms/surveyResponse'

let inputThatObj

// 根据设置的数组处理成form-create的rule数据，主要是处理组件联动情况中，handle的回调问题
export function setRuleByFormArr (formArr, ontime = false, fmObj = {}) {
  const ruleArr = []
  // const proPidArr = []
  let itemSort = 1 // 记录小题的序号，遇到大题的时候重置为1
  inputThatObj = fmObj
  formArr.map((task, tidx) => {
    const cuTask = JSON.parse(JSON.stringify(task))
    if (cuTask.type === 'h6' && cuTask.field === undefined) {
      itemSort = 1
    }
    // cuTask.title = cuTask.sort + '. ' + cuTask.title
    // cuTask.title = (tidx + 1) + '. ' + cuTask.title
    cuTask.title = itemSort + '. ' + cuTask.title
    if (cuTask.controlItemsArr !== undefined && cuTask.controlItemsArr.length > 0) {
      // 组装子级题目的数据
      cuTask.control = []
      cuTask.controlItemsArr.map(itv => {
        let handleObj = {}
        if (task.type === 'input') {
          const condStrArr = itv.cond !== undefined && itv.cond !== null && itv.cond !== '' ? itv.cond.split('##') : []
          handleObj = {
            handle (val) {
              let pmt = false
              condStrArr.map(cst => {
                if (val !== undefined && val !== null && val !== '' && val.indexOf(cst) !== -1) {
                  pmt = true
                }
              })
              return pmt
            }
          }
        } else {
          handleObj = {
            // value: itv.cond
            handle (val) {
              return val === itv.cond
            }
          }
        }
        const cuItv = JSON.parse(JSON.stringify(itv))
        cuItv.title = '附加题：' + cuItv.title
        if (ontime) {
          // 填写问卷的时候才需要实时保存答案
          if (cuItv.type === 'input') {
            cuItv.on = {
              blur:(e)=>saveAnswerByUser(e,cuItv)
            }
          } else {
            cuItv.on = {
              change:(e)=>saveAnswerByUser(e,cuItv)
            }
          }
        }
        cuTask.control.push(Object.assign({}, handleObj, { rule: [cuItv] }))
      })
    }
    delete cuTask.controlItemsArr
    delete cuTask.cond
    if (ontime) {
      // 填写问卷的时候才需要实时保存答案
      if (task.type === 'input') {
        cuTask.on = {
          blur:(e)=>saveAnswerByUser(e,cuTask)
        }
      } else {
        cuTask.on = {
          change:(e)=>saveAnswerByUser(e,cuTask)
        }
      }
    }
    
    ruleArr.push(cuTask)
    if (cuTask.type !== 'h6' && cuTask.field !== undefined) {
      itemSort++
    }
    // if (task.field !== undefined && task.field.indexOf('qaf_') >= 0) {
    //   // 处理上级题号的下拉数据
    //   proPidArr.push({ value: tidx, label: task.field.replace('qaf_', '大题 ').replace('_', ' 的第  ') + ' 题' })
    // }
  })
  // return { ruleArr: ruleArr, proPidArr: proPidArr }
  return { ruleArr: ruleArr }
}

export function saveAnswerByUser (obj,task) { 
  // console.log(obj, task, inputThatObj.cformInputApi.formData(), inputThatObj.parentParams);
  let cuval = obj.target !== undefined && obj.target.value !== undefined ? obj.target.value : obj
  let score = 0
  if (task.type === 'a-radio-group') {
      // 走children取分值
      task.children.map(chv => {
          if (chv.props.value === cuval) {
              score = chv.score
          }
      })
  } else {
      cuval = typeof cuval === 'object' ? cuval : [cuval]
      task.options.map(opv => {
          if (cuval.indexOf(opv.value) >= 0 && opv.score !== undefined) {
              score += parseFloat(opv.score)
          }
      })
  }
  
  const fmData = inputThatObj.cformInputApi.formData()
  let cuSaveParam = { patient_id: fmData.patient_id,service_record_id: fmData.service_record_id,survey_id: fmData.survey_id, source_type: fmData.source_type, contact_name: fmData.contact_name, contact_phone: fmData.contact_phone, question_id: task.field, question_type: task.type, answer: typeof cuval === 'object' ? cuval.join(',') : cuval, answer_score: score }
  if (inputThatObj.parentParams.response !== undefined && inputThatObj.parentParams.response > 0) {
    cuSaveParam.response = inputThatObj.parentParams.response
  }
  // 开始保存问卷数据 
  inputThatObj.surveyInputSpinning = true
  saveSurveyResponseInfo(cuSaveParam).then((res) => {
    if (res.status !== undefined && res.status === 1) {
      inputThatObj.parentParams.response = res.data.response
    } else {
      inputThatObj.$message.error(res.msg || '问卷信息实时保存失败！')
    }
    inputThatObj.surveyInputSpinning = false
  })
}