<template>

  <!-- Information Card 2 -->
  <a-card
    :bordered="false"
    class="card-info-2 h-full"
  >
    <div
      class="col-content h-full"
      style="background-image: url('/images/info-card-2.jpg')"
    >
      <div class="card-content">
        <h5>{{ timeFix }} {{ this.$store.state.user.name }}</h5>
        <p>今天也是元气满满的一天，加油！</p>
      </div>
      <div class="card-footer">
        <a-button
          ghost
          shape="round"
          size="small"
          @click="handleToSettings"
        >
          <span>个人中心</span>
          <a-icon
            class="ml-5"
            type="arrow-right"
          />
        </a-button>
      </div>
    </div>
  </a-card>
  <!-- / Information Card 2 -->

</template>

<script>
import { timeFix } from '@/utils/util'

export default ({
  data () {
    return {
      timeFix: timeFix(),
    }
  },
  methods: {
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
  }
})

</script>