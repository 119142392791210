<template>
  <a-spin
    tip="处理中..."
    size="small"
    :spinning="spinning"
  >
    <a-row :gutter="16">
      <a-col :span="18">
        <DailyFoodMenuViewTable :dailyFoodMenuDetail="dailyFoodMenuDetail" />
      </a-col>
      <a-col :span="6">
        <DailyFoodMenuViewChart :dailyFoodMenuDetail="dailyFoodMenuDetail" />
      </a-col>
    </a-row>

  </a-spin>
</template>

<script>
import { viewDailyFoodMenu } from '@/api/dms/dailyFoodMenu'
import DailyFoodMenuViewChart from './DailyFoodMenuViewChart'
import DailyFoodMenuViewTable from './DailyFoodMenuViewTable'

export default {
  name: 'DailyFoodMenuView',
  components: {
    DailyFoodMenuViewChart,
    DailyFoodMenuViewTable
  },
  data () {
    return {
      spinning: false,
      dailyFoodMenuDetail: {},
      // chart: null,
      // chartData: {},
      // height: 200
    }
  },
  props: {
    dailyFoodMenuId: {
      type: Number,
      default: null
    },
  },
  // filters: {
  //   statusTypeFilter (type) {
  //     if (type <= 0) {
  //       return 'error'
  //     } else if (type === 10) {
  //       return 'success'
  //     } else {
  //       return 'default'
  //     }
  //   }
  // },
  created () {
    this.spinning = true
    viewDailyFoodMenu({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, menu: this.dailyFoodMenuId, join: ['tags', 'recipe'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.dailyFoodMenuDetail = res.data
      }
      this.spinning = false
    })
  },
  // beforeDestroy: function () {
  //   this.chart.destroy()
  // },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
