<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="货品类型">
                  <a-tree-select
                    show-search
                    style="width: 100%"
                    v-model="queryParam.goods_type"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="请选择货品类型"
                    allow-clear
                    :filterTreeNode="filterTreeNode"
                    :tree-data="typeListData"
                    treeDataSimpleMode
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>

              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="当前状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="请选择当前状态"
                    :options="'enterprise_goods.status' | selectOptionsData()"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入货品名称、生产厂家搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="6"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.listEnterpriseGoodsTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                  <router-link
                    :to="'/EnterpriseGoods/addEnterpriseGoods'"
                    :hidden="'/EnterpriseGoods/addEnterpriseGoods' | isShowButton"
                  >
                    <a-button
                      type="primary"
                      icon="plus"
                    >新增货品</a-button>
                  </router-link>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="货品图片"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>

        <s-table
          ref="listEnterpriseGoodsTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'enterprise_goods.status' | showText(text)"
            />
          </span>

          <!-- <span
            slot="sale_type"
            slot-scope="text"
          >
            {{ 'enterprise_goods.sale_type' | showText(text) }}
          </span> -->

          <!-- <span
            slot="settlement_type"
            slot-scope="text"
          >
            {{ 'enterprise_goods.settlement_type' | showText(text) }}
          </span> -->

          <span
            slot="manufactor"
            slot-scope="text,record"
          >
            生产厂家：{{ record.manufactor }}
            <br />
            批文号：{{ text }}
            <span v-if="record.license_expire !== ''">
              <br />
              批文效期：{{ record.license_expire }}
            </span>
          </span>

          <span
            slot="unit_price"
            slot-scope="text,record"
          >
            含税价：{{ record.promotion_unit_price }} 元/ {{ record.unit }}
            <br>
            不含税价：{{ record.taxfree_unit_price }} 元/ {{ record.unit }}
            <br>
            税率：{{ record.tax_rate }} %，货币：{{ 'currency.type' | showText(record.currency) }}
          </span>

          <div
            slot="feature_image"
            slot-scope="text, record"
            style="max-width:65px;height:65px;cursor:pointer;"
          >
            <img
              v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.feature_image_show"
              @click="handlePreview(record.feature_image_show)"
            />
          </div>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EnterpriseGoods/editEnterpriseGoods' | isShowButton">
                <a @click="clickEdit(record)">编辑</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/EnterpriseGoods/changeEnterpriseGoods'] | isShowSeparator(['/EnterpriseGoods/editEnterpriseGoods'])"
              />
              <a-popconfirm
                :hidden="'/EnterpriseGoods/changeEnterpriseGoods' | isShowButton"
                title="确认修改该状态？"
                @confirm="clickChangeStatus(record)"
              >
                <a
                  class="font-success"
                  v-if="record.status !== 1"
                >启用</a>
                <a
                  class="font-error"
                  v-else
                >禁用</a>
              </a-popconfirm>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEnterpriseGood, changeStatus } from '@/api/dms/enterpriseGood'
import { selectListTypeByTt } from '@/api/dms/goodsType'

const statusMap = {
  '1': {
    status: 'success'
  },
  '-1': {
    status: 'error'
  },
  '0': {
    status: 'default'
  }
}

export default {
  name: 'EnterpriseGoodsList',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '货品名称',
          width: 150,
          dataIndex: 'goods_name',
          fixed: 'left'
        },
        {
          title: '货品类型',
          ellipsis: true,
          width: 100,
          dataIndex: 'type_name'
        },
        {
          title: '物料编码',
          ellipsis: true,
          width: 100,
          dataIndex: 'custom_code'
        },
        {
          title: '货品图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'feature_image' }
        },
        {
          title: '状态',
          ellipsis: true,
          width: 80,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '规格说明',
          ellipsis: true,
          dataIndex: 'spec_info'
        },
        {
          title: '厂家/批准文号',
          ellipsis: true,
          dataIndex: 'license_no',
          scopedSlots: { customRender: 'manufactor' }
        },
        // {
        //   title: '生产批号',
        //   ellipsis: true,
        //   dataIndex: 'manu_no'
        // },
        // {
        //   title: '销售类型',
        //   ellipsis: true,
        //   width: 100,
        //   dataIndex: 'sale_type',
        //   scopedSlots: { customRender: 'sale_type' }
        // },
        // {
        //   title: '结算类型',
        //   ellipsis: true,
        //   width: 100,
        //   dataIndex: 'settlement_type',
        //   scopedSlots: { customRender: 'settlement_type' }
        // },
        {
          title: '售价',
          ellipsis: true,
          dataIndex: 'unit_price',
          scopedSlots: { customRender: 'unit_price' }
        },
        {
          title: '更新时间',
          width: 160,
          ellipsis: true,
          dataIndex: 'update_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: { status: '1' },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listEnterpriseGood(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: true }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      },
      previewVisible: false,
      previewImg: '',
      typeListData: []
    }
  },
  filters: {
    statusTypeFilter (type) {
      return (statusMap[type] !== undefined) ? statusMap[type].status : 'default'
    }
  },
  created () {
    this.spinning = true
    selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise, addonParam: ['paramData'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.typeListData = res.data
      }
      this.spinning = false
      this.tip = '处理中...'
    })
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('EnterpriseGoods.editEnterpriseGoods', JSON.stringify({ goods: record.id }))
      this.$router.push({ path: '/EnterpriseGoods/editEnterpriseGoods' })
    },
    clickChangeStatus (record) {
      const cstatus = record.status !== 1 ? 1 : -1
      // 修改状态
      if ([-1, 1].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      this.spinning = true
      changeStatus({ goods: record.id, cstatus }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.$refs.listEnterpriseGoodsTable.refresh(true)
        this.spinning = false
      })
    },
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>
