import request from '@/utils/request'

const apiUrls = {
  listInvoices: '/Invoices/listInvoices',
  changeInvoiceStatus: '/Invoices/changeInvoiceStatus',
  getInvoiceDetailInfo: '/Invoices/getInvoiceDetailInfo',
  saveInvoice: '/Invoices/saveInvoice',
  getInvoiceTypesSelectList: '/Invoices/getInvoiceTypesSelectList',
  getInvoicedefList: '/Invoices/getInvoicedefList',
  saveInvoiceDef: '/Invoices/saveInvoiceDef',
  sureInvoiceSave: '/Invoices/sureInvoiceSave'
}

export function listInvoices (parameter) {
  return request({
    url: apiUrls.listInvoices,
    method: 'get',
    params: parameter
  })
}

export function changeInvoiceStatus (data) {
  return request({
    url: apiUrls.changeInvoiceStatus,
    method: 'post',
    data
  })
}

export function getInvoiceDetailInfo (parameter) {
  return request({
    url: apiUrls.getInvoiceDetailInfo,
    method: 'get',
    params: parameter
  })
}

export function saveInvoice (data) {
  return request({
    url: apiUrls.saveInvoice,
    method: 'post',
    data
  })
}

export function getInvoiceTypesSelectList (parameter) {
  return request({
    url: apiUrls.getInvoiceTypesSelectList,
    method: 'get',
    params: parameter
  })
}

export function getInvoicedefList (parameter) {
  return request({
    url: apiUrls.getInvoicedefList,
    method: 'get',
    params: parameter
  })
}

export function saveInvoiceDef (data) {
  return request({
    url: apiUrls.saveInvoiceDef,
    method: 'post',
    data
  })
}

export function sureInvoiceSave (data) {
  return request({
    url: apiUrls.sureInvoiceSave,
    method: 'post',
    data
  })
}
