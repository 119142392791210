<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveRoleRequest"
          ></form-create>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { saveRole } from '@/api/dms/adminRole'
import { selectListRelationEnterprise } from '@/api/dms/enterprise'
export default {
  name: 'AddPlatformRole',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'plattype',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'select',
          title: '所属企业',
          field: 'enterprise_id',
          props: { placeholder: '请选择所属企业' },
          options: [],
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 9 }
          },
          validate: [
            { required: true, message: '请选择所属企业' }
          ]
        },
        {
          type: 'input',
          title: '角色名称',
          field: 'name',
          props: { placeholder: '请填写角色名称' },
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 9 }
          },
          validate: [
            { required: true, message: '请确认输入角色名称', whitespace: true },
          ]
        },
        {
          type: 'input',
          title: '角色描述',
          field: 'remark',
          props: { placeholder: '请填写角色描述' },
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 9 }
          },
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal',
          labelCol: { span: 11 },
          wrapperCol: { span: 12 }
        },
        submitBtn: {
          innerText: '确认保存'
        },
        //表单提交事件
        // onSubmit: function (formData) {
        //   console.log(formData)
        //   this.saveRoleRequest(formData)
        // }
      }
    }
  },
  created () {
    this.spinning = true
    selectListRelationEnterprise({ main: this.$store.getters.userInfo.user.target_enterprise, type: 3 }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        const entArr = []
        res.data.map(ev => {
          entArr.push({ label: ev.relation_name, value: ev.relation_enterprise_id })
        })
        this.cformApi.updateRules({ // 更新规则
          'enterprise_id': { options: entArr }
        })
      }
      this.spinning = false
    })
  },
  methods: {
    saveRoleRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      saveRole(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '新增角色保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              formObj.resetFields()
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>