<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        id="detailBox"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.push({ path: '/EmallShops/listShops' })"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-descriptions title="店铺基础信息">
          <a-descriptions-item label="店铺名称">{{ originInfo.shop_name }}</a-descriptions-item>
          <a-descriptions-item
            label="店铺地址"
            :span="2"
          >{{ originInfo.id !== undefined ? originInfo.province_name+' '+originInfo.city_name+' '+originInfo.district_county_name+' '+originInfo.detail_address : '' }}</a-descriptions-item>

          <a-descriptions-item label="所属企业">{{ originInfo.enterprise_name }}</a-descriptions-item>
          <a-descriptions-item label="所属部门">{{ originInfo.dep_name }}</a-descriptions-item>
          <a-descriptions-item label="当前状态"><span :class="originInfo.status === '2' ? 'font-success' : 'font-error'">{{ 'emall_shop.status' | showText(originInfo.status) }}</span></a-descriptions-item>

          <a-descriptions-item
            label="店铺宣传语"
            :span="3"
          >{{ originInfo.sub_title }}</a-descriptions-item>

          <a-descriptions-item
            label="店铺介绍"
            :span="3"
          >{{ originInfo.introduction }}</a-descriptions-item>

          <a-descriptions-item
            label="店铺特色"
            :span="3"
          >{{ originInfo.feature }}</a-descriptions-item>

          <a-descriptions-item label="联系人">{{ originInfo.contact }}</a-descriptions-item>
          <a-descriptions-item label="联系电话">{{ originInfo.contact_phone }}</a-descriptions-item>
          <a-descriptions-item label="更新时间">{{ originInfo.update_date }}</a-descriptions-item>

          <a-descriptions-item label="店铺图片">
            <div
              style="max-width:80px;height:80px;cursor:pointer;"
              v-if="originInfo.feature_image_show !== undefined && originInfo.feature_image_show !== ''"
            >
              <img
                title="点击查看原图"
                style="width: 100%"
                :src="originInfo.feature_image_show"
                @click="handlePreview(originInfo.feature_image_show)"
              />
            </div>
          </a-descriptions-item>
        </a-descriptions>

        <a-divider />
        <div class="title">授权可售货品信息</div>
        <a-table
          :columns="goodsColumns"
          :dataSource="originInfo.emall_shop_good"
          :pagination="false"
          rowKey="id"
        >
          <span
            slot="goods_id"
            slot-scope="text,record,key"
          >
            {{ key+1 }}
          </span>
        </a-table>

        <a-row class="form-row text-center mt-20">
          <a-button
            type="default"
            icon="arrow-left"
            @click="$router.push({ path: '/EmallShops/listShops' })"
          >返回店铺管理列表</a-button>
        </a-row>
      </a-card>
    </a-spin>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="店铺图片"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>
  </div>
</template>

<script>
import { getEmallShopDetailInfo } from '@/api/dms/emallShop'

export default {
  data () {
    return {
      spinning: true,
      tip: '数据加载中...',
      originInfo: {},
      goodsColumns: [
        {
          title: '#',
          dataIndex: 'id',
          width: 70,
          ellipsis: true,
          scopedSlots: { customRender: 'goods_id' }
        },
        {
          title: '货品名称',
          dataIndex: 'goods_name',
          width: 170,
          ellipsis: true
        },
        {
          title: '货品类型',
          dataIndex: 'type_name',
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '展示标题',
          dataIndex: 'title',
          ellipsis: true
        },
        {
          title: '售价',
          width: 90,
          dataIndex: 'promotion_unit_price',
          ellipsis: true
        },
        {
          title: '开售时间',
          width: 150,
          dataIndex: 'start_time',
          ellipsis: true
        },
        {
          title: '停售时间',
          width: 150,
          dataIndex: 'end_time',
          ellipsis: true
        }
      ],
      previewVisible: false,
      previewImg: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallShops.viewEmallShop'))
    if (this.params === null || this.params.shop === undefined || this.params.shop < 1) {
      this.$message.error('请确认选择商城店铺！')
      return this.$router.go(-1)
    }
    // 获取数据
    getEmallShopDetailInfo({ shop: this.params.shop }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shop) {
        res.data.status = res.data.status.toString()
        this.originInfo = res.data
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
      this.spinning = false
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#detailBox {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
