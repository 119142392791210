<template>
  <div v-if="oneDayFoodShowArr.length > 0">
    <a-card
      :bordered="false"
      class="paddingDiv"
      :bodyStyle="{padding: 0}"
      v-for="(item, index) in oneDayFoodShowArr"
      :key="index"
    >
      <div
        class="nutritionPage"
        :ref="'alanberPage'+(4+index)"
      >
        <div class="nuContentPage">
          <div class="nuDivContentHeader">
            <div class="nuContentHeader">
              <div>
                <span>第 {{ index+1 }} 天菜谱</span>
                <br>
                <!-- <span>Results Analysis</span> -->
              </div>
              <div>
                <!-- <img
                  class="nuContentLogo"
                  src="/images/foodsense_logo.png"
                /> -->
              </div>
            </div>
          </div>

          <div class="nuDivContent">
            <div class="nuContentWithSider">
              <table class="analysisTable fontNuBase">
                <thead>
                  <tr class="thead_tr">
                    <!-- <th>
                      <div>天数</div>
                    </th> -->
                    <th class="thead_td1">
                      <div>三餐</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th class="thead_td1">
                      <div>序号</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th class="thead_td2">
                      <div>菜单名称</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th>
                      <div>主要原料</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th>
                      <div>辅助原料</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th class="thead_td2">
                      <div>热量</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th>
                      <div>调料</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                    <th>
                      <div>总摄入量</div>
                      <!-- <div class="fontNuBaseEng"></div> -->
                    </th>
                  </tr>
                </thead>
                <tbody :ref="'tbodyShow'+index">
                  <template v-if="item.menu_day !== undefined && item.menu_day !== null && item.menu_day.length >0">
                    <template v-for="(nuitem, nidx) in item.menu_day">
                      <tr
                        v-for="(recipe, ridx) in nuitem.recipes"
                        :key="nidx+'_'+ridx"
                      >
                        <!-- <td
                        v-if="nidx === 0"
                        :rowspan="nuitem.col_num"
                      >
                        <div
                          class="fontNuContentBase"
                        >第 {{ index + 1}} 天</div>
                      </td> -->
                        <!-- <td
                        v-else
                        style="display:none;"
                      ></td> -->
                        <td
                          v-if="ridx === 0"
                          :rowspan="nuitem.recipes.length"
                          class="thead_td1"
                        >
                          <div class="fontNuContentBase">{{ 'health.eating_type' | showText(nuitem.eating_type.toString()) }}</div>
                        </td>
                        <td class="thead_td1">
                          <div class="fontNuContentBase">{{ ridx + 1 }}</div>
                        </td>
                        <td class="thead_td2">
                          <div class="fontNuContentBase">{{ recipe.recipe_name }}</div>
                        </td>
                        <td>
                          <div class="fontNuContentBase">{{ recipe.major }}</div>
                        </td>
                        <td>
                          <div class="fontNuContentBase">{{ recipe.minor }}</div>
                        </td>
                        <td class="thead_td2">
                          <div class="fontNuContentBase">{{ recipe.calory }} {{ recipe.calory_unit }}</div>
                        </td>
                        <td>
                          <div class="fontNuContentBase">{{ recipe.seasoning }}</div>
                        </td>
                        <td
                          v-if="nidx === 0"
                          :rowspan="item.col_num"
                        >
                          <div class="fontNuContentBase">总热量：{{ item.calory }} {{ item.calory_unit }}，碳水化合物：{{ item.carbohydrate }} {{ item.carbohydrate_unit }}，蛋白质：{{ item.protein }} {{ item.protein_unit }}，脂肪：{{ item.fat }} {{ item.fat_unit }}</div>
                        </td>
                        <!-- <td>
                        <div
                          class="fontNuContentBase"
                          v-html="nuitem.alternative_food"
                        ></div>
                        <div
                          class="fontNuContentBaseEng"
                          v-html="nuitem.e_alternative_food"
                        ></div>
                      </td> -->
                      </tr>
                    </template>
                  </template>
                  <tr v-else>
                    <!-- <td></td> -->
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="nuContentRightSider">
              <div class="nuContentRightSiderText2">
                <div class="fontNuBase">一日食谱</div>
                <div class="fontNuS4">Daily Recipe</div>
              </div>
            </div>
          </div>

          <nutrition-footer
            :pageIndex="pageIndex + index"
            :pageTotal="pageTotal"
            :patient="patientInfo"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'FoodPage4',
  components: {
    NutritionFooter: () => import('./NutritionFooter')
  },
  props: {
    patientFoodOri: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 5
    },
    pageTotal: {
      type: Number,
      default: 1
    },
    patientInfo: {
      type: Object,
      default: null
    }
  },
  // watch: {
  //   patientFoodOri: {
  //     handler (newVal, oldVal) {
  //       this.$nextTick(function () {
  //         this.calcOneDayFood()
  //       })
  //     },
  //     deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
  //   }
  // },
  data () {
    return {
      oneDayFoodShowArr: [],
      oneDayFoodShowArrTmp: []
    }
  },
  created () {
    this.calcOneDayFood()
  },
  methods: {
    calcOneDayFood () {
      console.log('ss：', this.patientFoodOri)
      let allNum = 0
      let okNum = 0
      this.patientFoodOri.patient_food_menu_day.map((odv, odi) => {
        const tmpOdv = JSON.parse(JSON.stringify(odv))
        delete tmpOdv.patient_food_menu_day_recipe
        tmpOdv.menu_day = {}
        tmpOdv.col_num = 0
        this.oneDayFoodShowArrTmp[odv.day] = tmpOdv
        allNum += odv.patient_food_menu_day_recipe.length
        odv.patient_food_menu_day_recipe.map(omv => {
          if (this.oneDayFoodShowArrTmp[odv.day].menu_day[omv.eating_type] === undefined) {
            this.oneDayFoodShowArrTmp[odv.day].menu_day[omv.eating_type] = { eating_type: omv.eating_type, recipes: [] }
          }
          const recipe = omv.recipe_def_detail !== undefined && omv.recipe_def_detail !== null ? JSON.parse(omv.recipe_def_detail) : {}
          if (recipe.id !== undefined && recipe.id > 0) {
            let recipeShow = { recipe_name: recipe.recipe_name, calory: recipe.calory, calory_unit: recipe.calory_unit, major: [], minor: [], seasoning: [] }
            const major = recipe.major_materials !== undefined && recipe.major_materials !== null ? JSON.parse(recipe.major_materials) : []
            if (major.length > 0) {
              major.map(mj => {
                recipeShow.major.push(mj.food_name + ' (' + mj.weight + 'g)')
              })
            }
            const minor = recipe.minor_materials !== undefined && recipe.minor_materials !== null ? JSON.parse(recipe.minor_materials) : []
            if (minor.length > 0) {
              minor.map(mn => {
                recipeShow.minor.push(mn.food_name + ' (' + mn.weight + 'g)')
              })
            }
            const seasoning = recipe.seasoning !== undefined && recipe.seasoning !== null ? JSON.parse(recipe.seasoning) : []
            if (seasoning.length > 0) {
              seasoning.map(sa => {
                recipeShow.seasoning.push(sa.food_name + ' (' + sa.weight + 'g)')
              })
            }
            recipeShow.major = recipeShow.major.join('，')
            recipeShow.minor = recipeShow.minor.join('，')
            recipeShow.seasoning = recipeShow.seasoning.join('，')
            this.oneDayFoodShowArrTmp[odv.day].menu_day[omv.eating_type].recipes.push(recipeShow)
            this.oneDayFoodShowArrTmp[odv.day].col_num++
          }
          okNum++
        })
      })
      const that = this
      const timer = setInterval(() => {
        if (okNum >= allNum) {
          clearInterval(timer)
          that.oneDayFoodShowArr = Object.values(that.oneDayFoodShowArrTmp).map(odv => {
            odv.menu_day = Object.values(odv.menu_day)
            return odv
          })
          console.log('ss2：', that.oneDayFoodShowArr)
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nutritionPage {
  height: 1670px;
  width: 1175px;
  background-color: white;
  // border: 1px solid red;
  // margin-top: 10px;
  page-break-after: always;
}

.paddingDiv {
  height: 1670px;
  width: 1175px;
  margin-bottom: 10px;
}

.fontNuContentBase {
  font-size: 20px;
  line-height: 40px;
}

.fontNuContentBaseEng {
  font-size: 15px;
  line-height: 28px;
}

.nuContentRightSiderText2 {
  top: 650px;
}

.nuContentHeader {
  color: white;
}

.thead_td1 {
  width: 60px;
  padding: 0 !important;
  text-align: center;
}
.thead_td2 {
  width: 100px;
  padding: 0 !important;
  text-align: center;
}

// .analysisTable thead tr th:nth-of-type(1) {
//   width: 120px;
// }

// .analysisTable thead tr th:nth-of-type(2) {
//   width: 130px;
// }

// .analysisTable thead tr th:nth-of-type(3) {
//   width: 358px;
// }

// .analysisTable thead tr th:nth-of-type(4) {
//   width: 358px;
// }

// .analysisTable tbody tr:last-of-type {
//   height: 100%;
// }

// .analysisTable tbody tr td:nth-of-type(1) {
//   background-color: #fff4d1;
// }

// .analysisTable tbody tr:last-of-type td {
//   vertical-align: top;
// }
</style>
