import request from '@/utils/request'

const apiUrls = {
  getEmallGoodListByEnt: '/EmallGoods/getEmallGoodListByEnt',
  listEmallGood: '/EmallGoods/listEmallGood',
  addEmallGoodsAndSku: '/EmallGoods/addEmallGoodsAndSku',
  getEmallGoodDetailInfo: '/EmallGoods/getEmallGoodDetailInfo',
  changeStatus: '/EmallGoods/changeStatus',
  getEmallGoodAuthInfo: '/EmallGoods/getEmallGoodAuthInfo',
  editAuthShopSave: '/EmallGoods/editAuthShopSave',
  getEmallGoodInfo: '/EmallGoods/getEmallGoodInfo',
  editEmallGood: '/EmallGoods/editEmallGood',
  getEmallGoodGallaryList: '/EmallGoods/getEmallGoodGallaryList',
  updateEmallGoodGallary: '/EmallGoods/updateEmallGoodGallary',
  addEmallGoodShowcaseType: '/EmallGoods/addEmallGoodShowcaseType',
  getEmallGoodShowcaseTypesList: '/EmallGoods/getEmallGoodShowcaseTypesList',
  getEmallGoodShowcaseList: '/EmallGoods/getEmallGoodShowcaseList',
  changeShowcaseStatus: '/EmallGoods/changeShowcaseStatus'
}

export function getEmallGoodListByEnt (parameter) {
  return request({
    url: apiUrls.getEmallGoodListByEnt,
    method: 'get',
    params: parameter
  })
}

export function listEmallGood (parameter) {
  return request({
    url: apiUrls.listEmallGood,
    method: 'get',
    params: parameter
  })
}

export function addEmallGoodsAndSku (data) {
  return request({
    url: apiUrls.addEmallGoodsAndSku,
    method: 'post',
    data
  })
}

export function getEmallGoodDetailInfo (parameter) {
  return request({
    url: apiUrls.getEmallGoodDetailInfo,
    method: 'get',
    params: parameter
  })
}

export function getEmallGoodAuthInfo (parameter) {
  return request({
    url: apiUrls.getEmallGoodAuthInfo,
    method: 'get',
    params: parameter
  })
}

export function getEmallGoodInfo (parameter) {
  return request({
    url: apiUrls.getEmallGoodInfo,
    method: 'get',
    params: parameter
  })
}

export function editEmallGood (data) {
  return request({
    url: apiUrls.editEmallGood,
    method: 'post',
    data
  })
}

export function changeStatus (data) {
  return request({
    url: apiUrls.changeStatus,
    method: 'post',
    data
  })
}

export function editAuthShopSave (data) {
  return request({
    url: apiUrls.editAuthShopSave,
    method: 'post',
    data
  })
}

export function getEmallGoodGallaryList (data) {
  return request({
    url: apiUrls.getEmallGoodGallaryList,
    method: 'post',
    data
  })
}

export function updateEmallGoodGallary (data) {
  return request({
    url: apiUrls.updateEmallGoodGallary,
    method: 'post',
    data
  })
}

export function addEmallGoodShowcaseType (data) {
  return request({
    url: apiUrls.addEmallGoodShowcaseType,
    method: 'post',
    data
  })
}

export function getEmallGoodShowcaseTypesList (parameter) {
  return request({
    url: apiUrls.getEmallGoodShowcaseTypesList,
    method: 'get',
    params: parameter
  })
}

export function getEmallGoodShowcaseList (parameter) {
  return request({
    url: apiUrls.getEmallGoodShowcaseList,
    method: 'get',
    params: parameter
  })
}

export function changeShowcaseStatus (data) {
  return request({
    url: apiUrls.changeShowcaseStatus,
    method: 'post',
    data
  })
}
