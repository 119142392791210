<template>

  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
    :trigger="null"
    :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
    theme="light"
    :style="{ backgroundColor: 'transparent',}"
  >
    <div class="brand"><img
        :src="logoPng"
        alt=""
      > <span>{{ this.$store.state.app.mainIntro }}</span></div>
    <hr>

    <!-- Sidebar Navigation Menu -->
    <a-menu
      theme="light"
      mode="inline"
      :open-keys="openKeys"
      @openChange="onOpenChange"
      @click="onClickMenu"
    >
      <template v-for="(mitem, midx1) in menus">
        <template v-if="mitem.hidden !== true">
          <a-sub-menu
            v-if="mitem.children !== undefined && mitem.children.length !== undefined && mitem.children.length > 0"
            :key="midx1"
            style="padding: 0;"
          >
            <span slot="title">
              <span class="icon">
                <a-icon
                  :type="mitem.meta.icon"
                  theme="filled"
                  class="m-0"
                />
              </span>
              <span class="label">{{ mitem.meta.title || mitem.name }}</span>
            </span>
            <a-menu-item-group>
              <template v-for="(citem, cidx) in mitem.children">
                <template v-if="citem.hidden !== true">
                  <a-menu-item :key="midx1+'-'+cidx">
                    <router-link :to="citem.path">
                      <span class="label">{{ citem.meta.title || citem.name }}</span>
                    </router-link>
                  </a-menu-item>
                </template>
              </template>
            </a-menu-item-group>
          </a-sub-menu>

          <a-menu-item
            v-else
            :key="midx1"
            :class="(midx1 === cuclcik) ? 'ant-menu-item-selected':''"
          >
            <!-- <div class="level1-menue-item"> -->
            <router-link :to="mitem.path">
              <span class="icon">
                <a-icon
                  :type="mitem.meta.icon"
                  theme="outlined"
                  class="m-0"
                />
              </span>
              <span class="label">
                <span class="label">{{ mitem.meta.title || mitem.name }}</span>
              </span>
            </router-link>
            <!-- </div> -->
          </a-menu-item>
        </template>
      </template>

      <!-- demo: <a-menu-item class="menu-item-header">
        Pages
      </a-menu-item>
      <a-menu-item class="menu-item-header">
        <hr class="mt-5">
        Docs
      </a-menu-item>
      <a-menu-item>
        <a
          href="https://demos.creative-tim.com/muse-vue-ant-design-dashboard-pro/documentation/overview"
          target="_blank"
        >
          <span class="icon">
            <a-icon
              type="read"
              theme="filled"
              class="m-0"
            />
          </span>
          <span class="label">Getting Started</span>
        </a>
      </a-menu-item> -->
    </a-menu>
    <!-- / Sidebar Navigation Menu -->

  </a-layout-sider>
  <!-- / Main Sidebar -->

</template>

<script>
import { mapState } from 'vuex'
export default ({
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: 'primary'
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: 'light'
    }
  },
  data () {
    return {
      rootSubmenuKeys: [],
      openKeys: [0],
      logoPng: '/logo_ryx.png',
      menus: [],
      cuclcik: 0
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    })
  },
  created () {
    const routes = this.mainMenu.find(item => item.path === '/')
    this.menus = (routes && routes.children) || []
    this.rootSubmenuKeys = []
    this.menus.map((mv, mi) => {
      this.rootSubmenuKeys.push(mi)
      // 写入当前路由的地址
      if (mv.children !== undefined && mv.children.length > 0) {
        mv.children.map(cv => {
          if (cv.path === this.$route.path) {
            this.openKeys = [mi]
            this.cuclcik = mi
          }
        })
      }
    })
    this.logoPng = (localStorage.logoOnly !== undefined && localStorage.logoOnly !== null && localStorage.logoOnly !== '') ? localStorage.logoOnly.replace(/"/g, '') : ''
  },
  methods: {
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    onClickMenu (item) {
      this.cuclcik = item.key
      // 如果当前点击的菜单不再已打开的key里面则把openKey重置
      if (this.openKeys[0] === undefined || item.keyPath.indexOf(this.openKeys[0]) === -1) {
        this.openKeys = []
      }
    }
  }
})

</script>

<style lang="scss" scoped>
// .level1-menue-item {
//   padding: 10px 16px;
//   border-radius: 8px;
//   .router-link-active {
//     box-shadow: none !important;
//     background-color: transparent !important;
//   }
// }
// .ant-menu-item-selected {
//   .level1-menue-item {
//     background-color: #ffffff;
//     box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
//     font-weight: 600;
//     /deep/ .icon svg path {
//       fill: #ffffff !important;
//     }
//     .icon {
//       background-color: #1890ff !important;
//     }
//   }
// }
</style>