<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        :bodyStyle="{padding: 0, paddingTop: '16px'}"
      >
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="6">
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="所属分类">
                    <!-- <a-select
                      v-model="queryParam.category"
                      placeholder="全部"
                      :options="foodCaloryCategoryListData"
                      :allowClear="true"
                      :getPopupContainer="triggerNode=> { return triggerNode.parentNode || document.body ; }"
                    >
                    </a-select> -->
                    <a-tree-select
                      v-model="queryParam.category"
                      style="width: 100%"
                      :tree-data="foodCaloryCategoryListData"
                      tree-checkable
                      placeholder="全部"
                      :maxTagCount="2"
                      :allowClear="true"
                      :getPopupContainer="triggerNode=> { return triggerNode.parentNode || document.body }"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="食物名称"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.foodCaloryListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = {  })"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="foodCaloryListTable"
          :size="tableSize"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >

          <span
            slot="health_light"
            slot-scope="text"
          >
            <a-tag
              color="green"
              v-if="text === 1"
            >
              {{ 'food.health_light' | showText(text) }}
            </a-tag>
            <a-tag
              color="orange"
              v-if="text === 2"
            >
              {{ 'food.health_light' | showText(text) }}
            </a-tag>
            <a-tag
              color="red"
              v-if="text === 3"
            >
              {{ 'food.health_light' | showText(text) }}
            </a-tag>
          </span>

          <span
            slot="weight"
            slot-scope="text,record"
          >
            <a-input
              :value="text"
              size="small"
              addonAfter="克"
              @change="(e) => record.weight = !isNaN(e.target.value) ? Number(e.target.value) : 0"
            />
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a @click="clickView(record)">详情</a>

              <a-divider
                type="vertical"
                v-if="parentContainer === 'modalAddRecipe'"
              />

              <a
                v-if="parentContainer === 'modalAddRecipe'"
                @click="clickChoose(record)"
              >添加</a>

            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

    <a-modal
      v-model="modalVisible"
      title="食物详情（每100克/毫升营养物质含量）"
      :width="1200"
      :zIndex="3000"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button
          type="primary"
          @click="handleOk"
        >
          关闭
        </a-button>
      </template>

      <FoodCaloryView :foodCaloryId="foodDetail" />

    </a-modal>
  </div>
</template>

<script>
import { selectListFoodCaloryCategory, listFoodCalory } from '@/api/dms/foodCalory'
import FoodCaloryView from './FoodCaloryView'

export default {
  name: 'FoodCaloryList',
  components: {
    FoodCaloryView
  },
  data () {
    return {
      spinning: false,
      foodCaloryCategoryListData: [],
      columns: [],
      columnsCommon: [
        {
          title: 'ID',
          width: 55,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '分类名称',
          width: 160,
          dataIndex: 'category_name',
          ellipsis: true
        },
        {
          title: '食物名称',
          width: 300,
          dataIndex: 'food_name',
          ellipsis: false
        },
        {
          title: '热量',
          width: 125,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        // {
        //   title: '血糖指数',
        //   ellipsis: true,
        //   width: 200,
        //   dataIndex: 'glycemic_info',
        //   customRender: (text) => {
        //     let gInfo = JSON.parse(text)
        //     let outText = new Array()
        //     if (gInfo.gi !== undefined && gInfo.gi.value !== '') {
        //       outText.push(gInfo.gi.value + '(' + gInfo.gi.label + ')')
        //     }
        //     if (gInfo.gl !== undefined && gInfo.gl.value !== '') {
        //       outText.push(gInfo.gl.value + '(' + gInfo.gl.label + ')')
        //     }
        //     return outText.join(" / ")
        //   }
        // },
        {
          title: '健康等级',
          ellipsis: true,
          dataIndex: 'health_light',
          scopedSlots: { customRender: 'health_light' }
        },
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['category'] }, parameter, this.queryParam)
        console.log(requestParameters)
        return listFoodCalory(requestParameters)
          .then(res => {
            res.data.forEach(element => {
              if (element.cat_id === '9') {
                element.weight = 1
              } else if (element.cat_id === '7') {
                element.weight = 10
              } else {
                element.weight = 100
              }
            })
            console.log(res)
            return res
          })
      },
      modalVisible: false,
      foodDetail: null
    }
  },
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    tableSize: {
      type: String,
      default: 'default'
    },
    showChoose: {
      type: Boolean,
      default: false
    },
    parentContainer: {
      type: String,
      default: 'page'
    },
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {

  },
  mounted () {
    this.spinning = true
    selectListFoodCaloryCategory({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.foodCaloryCategoryListData = res.data
        console.log(res.data)
      }
      this.spinning = false
    })
    this.columns = this.columnsCommon
    if (this.parentContainer === 'modalAddRecipe') {
      this.columns.push({
        title: '重量',
        ellipsis: true,
        width: 100,
        dataIndex: 'weight',
        scopedSlots: { customRender: 'weight' }
      })
    }
    this.columns.push({
      title: '操作',
      ellipsis: true,
      width: this.parentContainer === 'modalAddRecipe' ? 100 : 50,
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
  },
  methods: {
    clickView (record) {
      console.log(record)
      this.foodDetail = record.id
      this.modalVisible = true
    },
    handleOk () {
      this.modalVisible = false
    },
    clickChoose (record) {
      this.$emit('closeModalCalory', false)
      this.$emit('chooseCalory', record)
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
