<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <div
        id="kanban"
        class="kanban"
      >
        <a-card :bordered="false">
          <form-create
            v-model="cformDefApi"
            :rule="cformDefRule"
            :option="cformOption"
          ></form-create>

          <a-divider />

          <draggable
            :list="boards"
            :animation="200"
            class="kanban-boards"
            ghost-class="ghost-card"
            group="boards"
            disabled
          >
            <a-spin
              v-for="(board) in boards"
              :key="board.id"
              :spinning="board.spinning"
            >
              <h6 class="font-semibold mx-0 mt-0 mb-30">
                {{ board.title }}
                <div
                  class="float-right"
                  v-if="editParam === undefined || editParam === null || editParam === '' || editParam.id === undefined || editParam.id < 1"
                >
                  <a-button
                    @click="loadCookieBtnClick"
                    type="danger"
                    size="small"
                    class="mr-10"
                  >获取最近缓存</a-button>
                  <a-button
                    @click="clearFormBtnClick"
                    type="danger"
                    size="small"
                  >清空表单</a-button>
                </div>
              </h6>
              <draggable
                :list="board.tasks"
                :animation="200"
                ghost-class="ghost-card"
                group="tasks"
              >
                <!-- 自定义的看板中项目内容 -->
                <div
                  v-for="(task,idx) in board.tasks"
                  :key="idx"
                >
                  <a-divider
                    v-if="idx > 0"
                    dashed
                  />
                  <template v-if="task.type === 'h6'">
                    大题标题：
                  </template>
                  <template v-else>
                    {{ (idx + 1) }} .
                  </template>
                  <!-- :class="task.type === 'h6' ? 'width85' : 'width75'" -->
                  <a-input
                    placeholder="请输入题目内容"
                    :style="task.type === 'h6' ? { width:'calc(100% - 140px)' } : { width:'calc(100% - 170px)' }"
                    :value="task.type === 'h6' ? task.children[0] : task.title"
                    @blur="(e)=>taskTitleChange(e,idx,task.type)"
                  />

                  <div class="inline-block">
                    <a-button
                      type="danger"
                      icon="close"
                      size="small"
                      class="mx-5"
                      title="删除该题"
                      @click="removeQuestion(idx)"
                    ></a-button>
                    <!-- <a-button
                      v-if="task.type !== 'h6'"
                      type="primary"
                      size="small"
                      title="给该题增加子级题目"
                      @click="submitBtnClick(idx)"
                    >加子题</a-button> -->
                    <a-dropdown
                      placement="bottomCenter"
                      v-if="task.type !== 'h6'"
                    >
                      <a-button
                        type="primary"
                        size="small"
                        title="给该题增加子级题目"
                      >加子题</a-button>
                      <a-menu slot="overlay">
                        <a-menu-item
                          v-for="(subItv,subIdx) in cformRule[0].options"
                          :key="subIdx"
                        >
                          <a
                            href="javascript:;"
                            @click="submitBtnClick(idx,subItv.value)"
                          >{{ subItv.label }}</a>
                        </a-menu-item>
                        <!-- <a-menu-item>
                          <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/"
                            >2nd menu item</a
                          >
                        </a-menu-item> -->
                      </a-menu>
                    </a-dropdown>
                  </div>

                  <template v-if="task.type === 'a-radio-group'">
                    <a-row>
                      <a-col
                        class="mt-10 item-col"
                        v-for="(argv,argi) in task.children"
                        :key="argi"
                        :span="10"
                        :offset="2"
                      >
                        {{ argv.children[0] }} .
                        <a-input
                          class="width55"
                          type="number"
                          :placeholder="'分值'"
                          size="small"
                          :value="argv.score"
                          @blur="(e)=>taskCheckGroupScoreChange(e,idx,argi)"
                        >
                        </a-input>
                      </a-col>
                    </a-row>
                  </template>
                  <template v-else-if="task.type === 'radio' || task.type === 'checkbox'">
                    <a-row>
                      <a-col
                        class="mt-10 item-col"
                        v-for="(rav,ri) in task.options"
                        :key="ri"
                        :span="12"
                      >
                        {{ rav.value }} .
                        <a-input
                          class="width55"
                          :placeholder="'请输入'+rav.value+'选项内容'"
                          size="small"
                          :value="rav.label"
                          @blur="(e)=>taskRadioChange(e,idx,ri)"
                        >
                        </a-input>
                        <a-input-search
                          class="width35"
                          type="number"
                          :placeholder="'分值'"
                          size="small"
                          :value="rav.score"
                          @blur="(e)=>taskRadioScoreChange(e,idx,ri)"
                          @search="removeRadioClick(idx,ri)"
                        >
                          <a-button
                            slot="enterButton"
                            type="danger"
                            icon="close"
                            title="删除该选项"
                          >
                          </a-button>
                        </a-input-search>
                      </a-col>
                    </a-row>
                    <a-row class="mt-10">
                      <a-col
                        :offset="10"
                        :span="12"
                      >
                        <a-button
                          @click="addRadioBtnClick(idx)"
                          type="primary"
                          icon="plus"
                          size="small"
                        >增加选项</a-button>
                      </a-col>
                    </a-row>
                  </template>

                  <!-- 子选项的渲染 -->
                  <template v-if="task.controlItemsArr !== undefined && task.controlItemsArr.length > 0">
                    <div
                      v-for="(citem,cidx) in task.controlItemsArr"
                      :key="cidx"
                    >
                      <a-divider dashed />
                      {{ (idx + 1) + '-' + (cidx+1) }} . 条件：
                      <!-- 上级是输入框的直接判断包含的关键字 -->
                      <template v-if="task.type === 'input'">
                        当上级题目输入
                        <a-input
                          placeholder="请输入显示子题目的关键字，多个用 ## 分隔"
                          :class="'width55'"
                          :value="citem.cond"
                          @blur="(e)=>taskCondChange(e,idx,task.type,cidx)"
                        /></template>
                      <!-- 选项的直接判断选择的值 -->
                      <template v-else>
                        当上级题目选择
                        <a-select
                          :value="citem.cond"
                          :options="showItemCondOpts(task)"
                          @change="(e)=>taskCondChange(e,idx,task.type,cidx)"
                          :class="'width55'"
                        >
                        </a-select>
                      </template> 时显示

                      <a-row>
                        子题目：
                        <a-input
                          placeholder="请输入题目内容"
                          class="width85 mt-10"
                          :value="citem.title"
                          @blur="(e)=>taskTitleChange(e,idx,citem.type,cidx)"
                        />
                      </a-row>
                      <template v-if="citem.type === 'a-radio-group'">
                        <a-row>
                          <a-col
                            class="mt-10 item-col"
                            v-for="(argv2,argi2) in citem.children"
                            :key="argi2"
                            :span="10"
                            :offset="2"
                          >
                            {{ argv2.children[0] }} .
                            <a-input
                              class="width55"
                              type="number"
                              :placeholder="'分值'"
                              size="small"
                              :value="argv2.score"
                              @blur="(e)=>taskCheckGroupScoreChange(e,idx,argi2,cidx)"
                            >
                            </a-input>
                          </a-col>
                        </a-row>
                      </template>
                      <template v-if="citem.type === 'radio' || citem.type === 'checkbox'">
                        <a-row>
                          <a-col
                            class="mt-10 item-col"
                            v-for="(rav2,ri2) in citem.options"
                            :key="ri2"
                            :span="12"
                          >
                            {{ rav2.value }} .
                            <a-input
                              class="width55"
                              :placeholder="'请输入'+rav2.value+'选项内容'"
                              size="small"
                              :value="rav2.label"
                              @blur="(e)=>taskRadioChange(e,idx,ri2,cidx)"
                            >
                            </a-input>
                            <a-input-search
                              class="width35"
                              type="number"
                              :placeholder="'分值'"
                              size="small"
                              :value="rav2.score"
                              @blur="(e)=>taskRadioScoreChange(e,idx,ri2,cidx)"
                              @search="removeRadioClick(idx,ri2,cidx)"
                            >
                              <a-button
                                slot="enterButton"
                                type="danger"
                                icon="close"
                                title="删除该选项"
                              >
                              </a-button>
                            </a-input-search>
                          </a-col>
                        </a-row>
                        <a-row class="mt-10">
                          <a-col
                            :offset="10"
                            :span="12"
                          >
                            <a-button
                              @click="addRadioBtnClick(idx,cidx)"
                              type="primary"
                              icon="plus"
                              size="small"
                            >增加选项</a-button>
                          </a-col>
                        </a-row>
                      </template>
                    </div>
                  </template>
                </div>
              </draggable>
            </a-spin>
          </draggable>
          <a-divider />
          <div>
            <form-create
              v-model="cformApi"
              :rule="cformRule"
              :option="cformOption"
            ></form-create>

            <a-row>
              <a-col
                :offset="6"
                :span="18"
              >
                <a-button
                  @click="submitBtnClick(false)"
                  type="primary"
                  icon="plus"
                  class="mr-10"
                >确认添加新题目</a-button>

                <a-button
                  @click="dividerAddClick()"
                  type="primary"
                  icon="plus"
                >添加大题分割线</a-button>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </div>

      <div class="right-preview">
        <a-card :bordered="false">
          <template v-if="cformRulePreview.length > 0">
            <form-create
              v-model="cformApiPreview"
              :rule="cformRulePreview"
              :option="cformOptionPreview"
            ></form-create>

            <a-row>
              <a-col
                :offset="11"
                :span="12"
              >
                <a-button
                  @click="saveBtnClick"
                  type="primary"
                >确认保存</a-button>
              </a-col>
            </a-row>
          </template>
          <a-empty
            class="my-50"
            v-else
            description="暂无相关数据"
          />
        </a-card>
      </div>
    </a-spin>
  </div>
</template>

<script>
import KanbanBoard from "@/components/Kanban/KanbanBoard"
import draggable from "vuedraggable"
import KanbanTask from "@/components/Kanban/KanbanTask"
import VueCookies from 'vue-cookies'
import { setRuleByFormArr } from '@/utils/formCreateRuleDeal'
import { saveSurveyDefInfo } from '@/api/dms/surveyDef'
import { getSelectListTag } from '@/api/manage'

export default {
  name: 'AddSurveyDef',
  components: {
    KanbanBoard,
    draggable,
    KanbanTask,
  },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      cformApiPreview: {},
      cformDefApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'select',
          title: '题目类型',
          field: 'problem_type',
          props: {
            placeholder: '请选择题目类型'
          },
          options: [
            { value: 1, label: '单选题' },
            { value: 2, label: '多选题' },
            { value: 3, label: '判断题' },
            { value: 4, label: '问答题' },
            { value: 5, label: '填空/简答题' },
          ],
          validate: [
            { required: true, message: '请选择题目类型' }
          ]
        }
      ],
      cformDefRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user_id',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'input',
          title: '问卷标题',
          field: 'title',
          props: { placeholder: '请填写问卷标题' },
          validate: [
            { required: true, message: '请确认输入问卷标题', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '是否共享',
          value: '0',
          field: 'is_open',
          props: { buttonStyle: 'solid', placeholder: '请选择是否共享' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '问卷标签',
          field: 'survey_tags',
          props: { mode: 'tags', placeholder: '请选择问卷标签', allowClear: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '备注' }
        }
      ],
      cformRulePreview: [],
      bigProSort: 0,
      previewRuleSort: 1,//只是作为field的唯一索引使用，不再作为排序号
      //组件参数配置
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      cformOptionPreview: {
        form: {
          labelAlign: 'left'
        },
        col: { pan: 24 },
        wrap: {
          labelCol: { span: 24 },
          wrapperCol: { offset: 1, span: 23 }
        },
        submitBtn: false
      },
      boards: [
        {
          id: "qaFormSet",
          title: "问卷表单设置",
          spinning: false,
          tasks: []
        }
      ],
      boardsCookieOri: [],//记录一次缓存中的表单数据，因为开始编辑表单之后，缓存就开始写新的缓存了
      proPidArr: [],
      aRadioBtnArr: [
        { value: 0, label: '✘' },
        { value: 1, label: '✔' }
      ],
      editParam: {}
    }
  },
  watch: {
    boards: {
      handler (newVal, oldVal) {
        // 重新组装预览form的数据
        this.cformRulePreview = []
        this.proPidArr = []
        const dealRule = setRuleByFormArr(this.boards[0].tasks)
        this.cformRulePreview = dealRule.ruleArr
        if (this.boards[0].tasks.length > 0) {
          // 设置当前数据到缓存中，避免手误关掉浏览器造成无法找回
          VueCookies.set('AJ-DMS-LAST-SURVEY', JSON.stringify({ boards: this.boards, previewRuleSort: this.previewRuleSort, bigProSort: this.bigProSort }))
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  created () {
    this.previewRuleSort = 1
    this.bigProSort = 0
    // 检测是否有历史问卷数据
    if (VueCookies.isKey('AJ-DMS-LAST-SURVEY')) {
      this.boardsCookieOri = VueCookies.get('AJ-DMS-LAST-SURVEY')
    }

    // 检测参数有id则是编辑
    this.editParam = JSON.parse(sessionStorage.getItem('MiscSurveyDefs.editSurveyDef'))
    console.log(this.editParam)
    // 删除参数避免一直存着，此页面是新增、编辑共用的
    sessionStorage.removeItem('MiscSurveyDefs.editSurveyDef')

    this.spinning = true
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformDefApi.updateRules({ // 更新规则
          'survey_tags': { options: res.data }
        })
      }
      // 是否有回显编辑数据
      if (this.editParam !== undefined && this.editParam !== null && this.editParam !== '' && this.editParam.id !== undefined) {
        const tagArr = []
        this.editParam.misc_survey_tag.map(tgv => {
          tagArr.push(tgv.tag_id.toString())
        })

        this.cformDefApi.setValue({ id: this.editParam.id, title: this.editParam.title, is_open: this.editParam.is_open.toString(), remarks: this.editParam.remarks, survey_tags: tagArr })
        const oriTaskArr = JSON.parse(this.editParam.form_rule)
        this.boards[0].tasks = oriTaskArr
        oriTaskArr.map(tkv => {
          if (tkv.field !== undefined && tkv.field !== null && tkv.field.indexOf('qaf_') >= 0) {
            const fieldExtArr = tkv.field.replace('qaf_', '').split('_')
            // 记录最大的索引号
            this.previewRuleSort = this.previewRuleSort < fieldExtArr[1] ? fieldExtArr[1] : this.previewRuleSort
            this.bigProSort = this.bigProSort < fieldExtArr[0] ? fieldExtArr[0] : this.bigProSort
          }
        })
      }
      this.spinning = false
    })
  },
  methods: {
    submitBtnClick (taskIdx, ctype = false) {
      // 判断是否是增加子级题目，
      const cuChooseType = ctype !== false ? ctype : this.cformApi.getValue('problem_type')
      if (taskIdx !== false) {
        // 增加子选项，父级题目必选
        if (this.boards[0].tasks[taskIdx] === undefined) {
          this.$message.error('请确认选择关联上级题目！')
          return false
        }
      }
      if (cuChooseType === undefined || cuChooseType === null || cuChooseType < 1) {
        this.$message.error('请确认选择题目类型！')
        return false
      }
      let cuNewTask = { title: '', field: 'qaf_' + (this.bigProSort < 1 ? 1 : this.bigProSort) + '_' + this.previewRuleSort, sort: this.previewRuleSort, controlItemsArr: [], cond: '' }
      if (cuChooseType >= 1 && cuChooseType <= 2) {
        // 单选多选
        cuNewTask.type = cuChooseType >= 2 ? 'checkbox' : 'radio'
        cuNewTask.options = [
          {
            value: 'A',
            label: ''
          },
          {
            value: 'B',
            label: ''
          },
          {
            value: 'C',
            label: ''
          },
          {
            value: 'D',
            label: ''
          }
        ]
      } else if (cuChooseType === 3) {
        // 判断题
        cuNewTask.type = 'a-radio-group'
        cuNewTask.props = { size: "small", buttonStyle: 'solid' }
        cuNewTask.children = [
          {
            type: 'a-radio-button',
            props: {
              value: '0'
            },
            children: ['✘']
          }, {
            type: 'a-radio-button',
            props: {
              value: '1'
            },
            children: ['✔']
          }
        ]
        cuNewTask.wrap = {
          labelCol: { span: 20 },
          wrapperCol: { span: 4 }
        }
      } else {
        // 简答或者填空
        cuNewTask.type = 'input'
        if (cuChooseType === 4) {
          cuNewTask.props = { type: 'textarea', autoSize: { minRows: 4 } }
        }
      }
      if (taskIdx !== false) {
        // 只增加子项目
        cuNewTask.field = this.boards[0].tasks[taskIdx].field + '_' + (this.boards[0].tasks[taskIdx].controlItemsArr.length + 1)
        this.boards[0].tasks[taskIdx].controlItemsArr.push(cuNewTask)
      } else {
        this.boards[0].tasks.push(cuNewTask)
        this.previewRuleSort++
      }
    },
    taskTitleChange (e, idx, type, cidx = false) {
      // 题目
      if (this.boards[0].tasks[idx] === undefined) {
        this.$message.error('请确认题目存在！')
        return false
      }
      if (cidx !== false) {
        if (this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined) {
          // 子选项的判断
          this.$message.error('请确认子项题目存在！')
          return false
        }
        this.boards[0].tasks[idx].controlItemsArr[cidx].title = e.target.value
      } else {
        if (type === 'h6') {
          this.boards[0].tasks[idx].children[0] = e.target.value
        } else {
          this.boards[0].tasks[idx].title = e.target.value
        }
      }

      // if (this.boards[0].tasks[idx].title.length > 35 && this.boards[0].tasks[idx].type === 'a-radio-group') {
      //   // 判断题的标题大于35字的时候要设置行高
      //   this.boards[0].tasks[idx].style = { lineHeight: 'normal' }
      // }
      this.boards = [...this.boards]
    },
    taskRadioChange (e, idx, ri, cidx = false) {
      // 选项值
      if (cidx !== false) {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx].options[ri] === undefined) {
          this.$message.error('请确认子题目选项存在！')
          return false
        }
        this.boards[0].tasks[idx].controlItemsArr[cidx].options[ri].label = e.target.value
      } else {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].options[ri] === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        this.boards[0].tasks[idx].options[ri].label = e.target.value
      }
      this.boards = [...this.boards]
    },
    taskRadioScoreChange (e, idx, ri, cidx = false) {
      // 选项分值
      if (cidx !== false) {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx].options[ri] === undefined) {
          this.$message.error('请确认子题目选项存在！')
          return false
        }
        this.boards[0].tasks[idx].controlItemsArr[cidx].options[ri].score = e.target.value
      } else {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].options[ri] === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        this.boards[0].tasks[idx].options[ri].score = e.target.value
      }
      this.boards = [...this.boards]
    },
    taskCheckGroupScoreChange (e, idx, ri, cidx = false) {
      // 选项分值
      if (cidx !== false) {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx].children[ri] === undefined) {
          this.$message.error('请确认子题目选项存在！')
          return false
        }
        this.boards[0].tasks[idx].controlItemsArr[cidx].children[ri].score = e.target.value
      } else {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].children[ri] === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        this.boards[0].tasks[idx].children[ri].score = e.target.value
      }
      this.boards = [...this.boards]
    },
    addRadioBtnClick (idx, cidx = false) {
      // 增加选项
      if (cidx !== false) {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx].options === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        const cuPlStr = String.fromCharCode((65 + this.boards[0].tasks[idx].controlItemsArr[cidx].options.length))
        this.boards[0].tasks[idx].controlItemsArr[cidx].options.push(
          {
            value: cuPlStr,
            label: ''
          }
        )
      } else {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].options === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        const cuPlStr = String.fromCharCode((65 + this.boards[0].tasks[idx].options.length))
        this.boards[0].tasks[idx].options.push(
          {
            value: cuPlStr,
            label: ''
          }
        )
      }
    },
    removeRadioClick (idx, ri, cidx = false) {
      // 删除选项
      if (cidx !== false) {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx].options[ri] === undefined) {
          this.$message.error('请确认子题目选项存在！')
          return false
        }
        this.boards[0].tasks[idx].controlItemsArr[cidx].options.splice(ri, 1)
      } else {
        if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].options[ri] === undefined) {
          this.$message.error('请确认选项存在！')
          return false
        }
        this.boards[0].tasks[idx].options.splice(ri, 1)
      }
    },
    dividerAddClick () {
      // 添加大题题目和分割线
      this.previewRuleSort = 1 // 小题排序重置1
      this.boards[0].tasks.push({
        type: 'h6',
        name: 'h6',
        class: 'mb-20',
        children: ['']
      })
      this.bigProSort++ //大题排序累加
    },
    taskCondChange (e, idx, type, cidx) {
      if (this.boards[0].tasks[idx] === undefined || this.boards[0].tasks[idx].controlItemsArr[cidx] === undefined) {
        this.$message.error('请确认子题目选项存在！')
        return false
      }
      if (type === 'input') {
        this.boards[0].tasks[idx].controlItemsArr[cidx].cond = e.target.value
      } else {
        // 选择对应值的情况
        this.boards[0].tasks[idx].controlItemsArr[cidx].cond = e
      }
    },
    showItemCondOpts (task) {
      if (task.type === 'radio' || task.type === 'checkbox') {
        const optArr = []
        task.options.map(opt => {
          optArr.push({ value: opt.value, label: opt.value + ' ' + opt.label })
        })
        return optArr
      } else {
        return this.aRadioBtnArr
      }
    },
    loadCookieBtnClick () {
      if (this.boardsCookieOri === undefined || this.boardsCookieOri.boards === undefined || this.boardsCookieOri.boards.length < 1) {
        this.$message.error('当前暂无缓存问卷表单数据！')
        return false
      }
      // 显示缓存
      const that = this
      if (this.boards[0].tasks.length > 0) {
        this.$confirm({
          title: '当前问卷表单已存在数据！是否覆盖？',
          content: false,
          cancelText: '取消',
          okText: '确认覆盖',
          icon: () => {
            return that.$createElement('a-icon', {
              props: { type: 'check-circle' },
              style: { color: '#52c41a' }
            })
          },
          onOk () {
            that.loadCookieDataDeal()
          },
          onCancel () { }
        })
      } else {
        this.loadCookieDataDeal()
      }
    },
    loadCookieDataDeal () {
      // 加载历史问卷数据
      const oriCkArr = JSON.parse(JSON.stringify(this.boardsCookieOri))
      this.boards = oriCkArr.boards
      this.previewRuleSort = oriCkArr.previewRuleSort
      this.bigProSort = oriCkArr.bigProSort
    },
    clearFormBtnClick () {
      const that = this
      this.$confirm({
        title: '确认清空当前问卷表单数据？',
        content: false,
        cancelText: '取消',
        okText: '确认清空',
        icon: () => {
          return that.$createElement('a-icon', {
            props: { type: 'check-circle' },
            style: { color: '#52c41a' }
          })
        },
        onOk () {
          that.previewRuleSort = 1
          that.bigProSort = 0
          that.proPidArr = []
          that.boards = [{
            id: "qaFormSet",
            title: "问卷表单设置",
            spinning: false,
            tasks: []
          }]
        },
        onCancel () { }
      })
    },
    saveBtnClick () {
      // 保存表单的json数据
      if (this.cformRulePreview.length < 1) {
        this.$message.error('请确认设置问卷表单！')
        return false
      }
      // console.log(JSON.stringify(this.boards[0].tasks))
      // return false
      const that = this
      this.cformDefApi.validate((valid2, fail2) => {
        if (valid2) {
          this.spinning = true
          // saveSurveyDefInfo(Object.assign({ form_rule: JSON.stringify(this.cformRulePreview) }, this.cformDefApi.formData())).then((res) => {
          saveSurveyDefInfo(Object.assign({ form_rule: JSON.stringify(this.boards[0].tasks) }, this.cformDefApi.formData())).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '继续新增问卷',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.cformDefApi.resetFields()
                  that.previewRuleSort = 1
                  that.bigProSort = 0
                  that.proPidArr = []
                  that.boards = [{
                    id: "qaFormSet",
                    title: "问卷表单设置",
                    spinning: false,
                    tasks: []
                  }]
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        } else {
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    removeQuestion (idx) {
      if (this.boards[0].tasks[idx] === undefined) {
        this.$message.error('请确认题目存在！')
        return false
      }
      this.boards[0].tasks.splice(idx, 1)
    }
  }
}

</script>

<style lang="scss" scoped>
#kanban {
  display: inline-block;
  width: 49%;
}
.right-preview {
  display: inline-block;
  width: 49%;
  margin-left: 2%;
  vertical-align: top;
}
.width85 {
  width: 85%;
}
.width75 {
  width: 75%;
}
.width55 {
  width: 55%;
}
.width35 {
  width: 35%;
}
.item-col {
  line-height: 34px;
}
/deep/ .ant-col-24.ant-form-item-label,
/deep/ .ant-col-xl-24.ant-form-item-label {
  padding: 0;
}
// /deep/.right-preview
//   .ant-col.ant-col-20.ant-form-item-label.ant-form-item-label-left {
//   white-space: normal;
//   line-height: normal;
// }
/deep/ .ant-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}
</style>