<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        id="detailBox"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.push({ path: '/EmallGoods/listShopGoods' })"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="editAuthShopForm"
        >
          <a-descriptions title="商品基础信息">
            <a-descriptions-item label="展示品名">{{ originInfo.goods_name }}</a-descriptions-item>
            <a-descriptions-item
              label="展示标题"
              :span="2"
            >{{ originInfo.title }}</a-descriptions-item>

            <a-descriptions-item label="商品类型">{{ originInfo.type_name }}</a-descriptions-item>
            <a-descriptions-item
              label="详细描述"
              :span="2"
            >{{ originInfo.sub_title }}</a-descriptions-item>

            <a-descriptions-item label="当前状态"><span :class="originInfo.online_status === 20 ? 'font-success' : 'font-error'">{{ 'emall_goods.status' | showText(originInfo.status) }}</span></a-descriptions-item>
            <a-descriptions-item label="是否上架"><span :class="originInfo.online_status === 1 ? 'font-success' : 'font-error'">{{ 'emall_goods.online_status' | showText(originInfo.online_status) }}</span></a-descriptions-item>
            <a-descriptions-item label="运费单价">{{ originInfo.freight > 0 ? originInfo.freight : 0 }} 元/ {{ 'emall_goods.freight_type' | showText(originInfo.freight_type) }}</a-descriptions-item>

            <a-descriptions-item label="成本价">{{ originInfo.cost_price }}</a-descriptions-item>
            <a-descriptions-item label="标价">{{ originInfo.unit_price }}</a-descriptions-item>
            <a-descriptions-item label="实际售价">{{ originInfo.promotion_unit_price }}</a-descriptions-item>

            <a-descriptions-item label="实体/虚拟商品">{{ 'emall_goods.is_virtual' | showText(originInfo.is_virtual) }}</a-descriptions-item>
            <a-descriptions-item
              label="库存管控"
              :span="originInfo.inventory_control > 0 ? 1 : 2"
            >{{ 'emall_goods.inventory_control' | showText(originInfo.inventory_control) }}</a-descriptions-item>
            <a-descriptions-item
              label="库存管控数量"
              v-if="originInfo.inventory_control > 0"
            >{{ originInfo.inventory }}</a-descriptions-item>

            <a-descriptions-item
              label="销售时间"
              :span="3"
            >{{ originInfo.start_time + ' ~ ' + originInfo.end_time }}</a-descriptions-item>
            <a-descriptions-item label="商品主图">
              <div
                style="max-width:80px;height:80px;cursor:pointer;"
                v-if="originInfo.feature_image_show !== undefined && originInfo.feature_image_show !== ''"
              >
                <img
                  title="点击查看原图"
                  style="width: 100%"
                  :src="originInfo.feature_image_show"
                  @click="handlePreview(originInfo.feature_image_show)"
                />
              </div>
            </a-descriptions-item>
          </a-descriptions>

          <a-divider />
          <div class="title">新增销售店铺信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="授权店铺"
                :labelCol="{ md: 2 }"
                :wrapperCol="{ md: 21 }"
              >
                <a-select
                  :allowClear="true"
                  show-search
                  :filter-option="gsFilterOption"
                  mode="multiple"
                  :maxTagCount="2"
                  placeholder="请选择授权店铺"
                  v-decorator="['shop_id']"
                >
                  <a-select-option
                    v-for="(g1,i1) in gsListData"
                    :key="i1"
                    :value="g1.value"
                  >
                    <span>{{ g1.label }}</span>
                    <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)"> {{ g1.label2 }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-divider />
          <div class="title">已授权销售店铺信息</div>
          <a-table
            :columns="goodsColumns"
            :dataSource="originInfo.emall_shop_good"
            :pagination="false"
            rowKey="id"
          >
            <span
              slot="action"
              slot-scope="text, record,key"
            >
              <a-popconfirm
                title="确认删除该授权销售店铺？"
                @confirm="clickDel(record,key)"
              >
                <a class="font-error">删除</a>
              </a-popconfirm>
            </span>
          </a-table>

          <a-row class="form-row text-center mt-20">
            <a-form-item :wrapperCol="{ span: 24 }">
              <a-button
                htmlType="submit"
                type="primary"
              >确认保存</a-button>
            </a-form-item>
          </a-row>
        </a-form>
      </a-card>
    </a-spin>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="店铺图片"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>
  </div>
</template>

<script>
import { getEmallGoodAuthInfo, editAuthShopSave } from '@/api/dms/emallGood'
import { getEmallShopSelectList } from '@/api/dms/emallShop'

export default {
  data () {
    return {
      spinning: true,
      tip: '数据加载中...',
      editAuthShopForm: this.$form.createForm(this),
      originInfo: {},
      gsListData: [],
      goodsColumns: [
        {
          title: '#',
          dataIndex: 'id',
          width: 70,
          ellipsis: true
        },
        {
          title: '店铺名称',
          dataIndex: 'shop_name',
          ellipsis: true
        },
        {
          title: '代理人',
          dataIndex: 'nick_name',
          ellipsis: true,
          width: 120,
          customRender: (text, row, index) => {
            return text === null ? '' : text
          }
        },
        {
          title: '联系人',
          dataIndex: 'contact',
          width: 120,
          ellipsis: true
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone',
          width: 120,
          ellipsis: true
        },
        {
          title: '店铺地址',
          dataIndex: 'detail_address',
          ellipsis: true,
          customRender: (text, row, index) => {
            const showTxt = []
            if (row.province_name !== null && row.province_name !== '') {
              showTxt.push(row.province_name)
            }
            if (row.city_name !== null && row.city_name !== '') {
              showTxt.push(row.city_name)
            }
            if (row.district_county_name !== null && row.district_county_name !== '') {
              showTxt.push(row.district_county_name)
            }
            showTxt.push(text)
            return showTxt.join(' ')
          }
        },
        {
          title: '操作',
          width: 80,
          dataIndex: 'action',
          ellipsis: true,
          scopedSlots: { customRender: 'action' }
        }
      ],
      previewVisible: false,
      previewImg: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoods.editAuthShops'))
    if (this.params === null || this.params.shopGood === undefined || this.params.shopGood < 1) {
      this.$message.error('请确认选择商城店铺！')
      return this.$router.go(-1)
    }
    this.editAuthShopForm.getFieldDecorator('id', { initialValue: this.params.shopGood, preserve: true, rules: [{ required: true }] })
    this.editAuthShopForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.loadData()
  },
  methods: {
    loadData () {
      // 获取店铺下拉数据
      getEmallShopSelectList({ shopGood: this.params.shopGood, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.gsListData = res.data
        }
      })
      // 获取数据
      getEmallGoodAuthInfo({ shopGood: this.params.shopGood }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shopGood) {
          res.data.status = res.data.status.toString()
          this.originInfo = res.data
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
        this.spinning = false
      }).catch(() => {
        return this.$router.go(-1)
      })
    },
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    clickDel (row, key) {
      const lab2 = [row.nick_name !== null ? '代理人：' + row.nick_name : '代理人：-', '联系人：' + row.contact, '联系电话：' + row.contact_phone]
      this.gsListData.push({ value: row.shop_id, label: row.shop_name, label2: lab2.join('，') })
      this.originInfo.emall_shop_good = this.originInfo.emall_shop_good.filter((v, k) => {
        return !(key === k)
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()

      this.editAuthShopForm.validateFields((err, values) => {
        if (!err) {
          this.spinning = true
          this.tip = '处理中...'
          values['goods'] = this.originInfo.emall_shop_good
          editAuthShopSave(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.spinning = true
                  that.editAuthShopForm.resetFields()
                  that.tip = '数据加载中...'
                  that.gsListData = []
                  that.originInfo = {}
                  that.loadData()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    gsFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].children !== undefined && option.componentOptions.children[0].children[0] !== undefined && option.componentOptions.children[0].children[0].text !== undefined) {
        return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#detailBox {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
