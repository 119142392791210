<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <!-- 打印模块的dom -->
      <print-report-dom
        :parentParams="printParams"
        @spinningChange="spinningChange"
      />
    </a-spin>
  </div>
</template>

<script>
import { PrintReportDom } from '@/components/Report'

export default {
  name: 'PrintPatientReportView',
  components: { PrintReportDom },
  data () {
    return {
      printParams: {},
      spinning: false
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.printPatientReportView'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.$nextTick(() => {
      this.printParams = this.params
      console.log(this.printParams)
    })
  },
  methods: {
    spinningChange (v) {
      this.spinning = v
    },
  }
}

</script>

<style lang="scss" scoped>
.labMainDivPage1 {
  width: 100%;
  height: 100%;
  position: relative;
}

.labmainTitle {
  // padding: 20px 50px 20px 50px;
  width: 100%;
  // height: 170px;
}

.labmainTitleText {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  vertical-align: top;
  // margin-top: 20px;
}

.labmainTitleBarno {
  // float: right;
  text-align: right;
  // width: 35%;
  font-size: 18px;
  vertical-align: top;
  font-weight: 700;
}

.divClientInfo {
  width: 100%;
  margin-top: 10px;
  // padding: 10px 50px 0 50px;
}

.fontlabBase {
  font-size: 18px;
}

.divLabFooter {
  width: 100%;
  // padding: 0 50px;
  // position: absolute;
  height: 300px;
  // bottom: 0px;
  margin-top: 20px;
}

.tableFooter {
  width: 100%;
}

.tableFooter td {
  padding: 8px 5px;
}

.tableFooter .tr1-1 {
  width: 35%;
}

.tableFooter .tr1-2 {
  width: 30%;
}

.tableFooter .tr1-3 {
  width: 35%;
}
.tableFooter .tr2-1 {
  width: 50%;
}

.tableFooter .tr2-2 {
  width: 35%;
}

.tableFooter .tr2-3 {
  width: 15%;
}

.tdFooterTopBorder {
  border-top: 1px solid #000000;
}
.tdFooterTitle {
  font-weight: bold;
  // margin-right: 10px;
}
.rightUserTd {
  text-align: right;
  // padding-right: 5.3% !important;
}
.barListDiv {
  text-align: right;
  display: inline-flex;
}
#printDiv {
  // width: 1240px;
  // height: 1754px;
  /deep/ .ant-descriptions .ant-descriptions-row > th,
  /deep/ .ant-descriptions .ant-descriptions-row > td {
    padding: 10px !important;
  }
  /deep/ .ant-descriptions .ant-descriptions-title {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}
</style>