<template>

  <!-- Main Sidebar -->
  <component
    :is="navbarFixed ? 'a-affix' : 'div'"
    :offset-top="top"
  >

    <!-- Layout Header -->
    <a-layout-header>
      <a-spin
        tip="正在切换..."
        size="small"
        :spinning="spinningEnt"
      >
        <a-row type="flex">
          <!-- Header Breadcrumbs & Title Column -->
          <a-col
            :span="4"
            :md="1"
            class="sidebar-toggler-col"
          >

            <a-button
              type="link"
              style="float: left;padding: 0 5px 0 0;height: 35px;"
              @click="$emit('minimizeSidebar') , resizeEventHandler()"
            >
              <a-icon
                v-if="sidebarMinimized"
                type="menu-unfold"
                theme="outlined"
                style="font-size:20px;"
              />
              <a-icon
                v-else
                type="menu-fold"
                theme="outlined"
                style="font-size:20px;"
              />
            </a-button>

          </a-col>

          <a-col
            :span="20"
            :md="6"
          >

            <!-- Header Breadcrumbs -->
            <!-- <a-breadcrumb>
						<template v-for="(item, key) in $route.meta.breadcrumbs">
							<a-breadcrumb-item v-if="key == $route.meta.breadcrumbs.length - 1" :key="key">{{ item }}</a-breadcrumb-item>
							<a-breadcrumb-item v-else :key="key"><router-link to="/">{{ item }}</router-link></a-breadcrumb-item>
						</template>
					</a-breadcrumb> -->
            <!-- / Header Breadcrumbs -->

            <!-- Header Page Title -->
            <div class="ant-page-header-heading">
              <span
                class="ant-page-header-heading-title"
                style="font-size: 20px;"
              >
                <a
                  v-if="this.$route.meta.type !== undefined && this.$route.meta.type === 2"
                  @click="$router.go(-1), resizeEventHandler()"
                >
                  返回
                </a>
                / {{ this.$route.meta.title || this.$route.name }}
              </span>

            </div>
            <!-- / Header Page Title -->
            <div style="color:darkgray;">
              {{ this.$route.meta.subtitle }}
            </div>

          </a-col>
          <!-- / Header Breadcrumbs & Title Column -->

          <!-- Header Control Column -->
          <a-col
            :span="24"
            :md="17"
            class="header-control"
          >

            <span class="btn-sign-in">
              <!-- <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
                  fill="#111827"
                />
              </svg> -->
              <img
                style="width:30px;height:30px;border-radius:50%;margin-right:4px;"
                :src="this.$store.getters.avatar !== '' ? this.$store.getters.avatar : defAvatar"
              />
              <a-dropdown
                overlayClassName="header-notifications-dropdown"
                placement="bottomRight"
                :getPopupContainer="() => wrapper"
              >
                <a
                  class="ant-dropdown-link"
                  @click="e => e.preventDefault()"
                >
                  {{ this.$store.state.user.name }}【{{ this.$store.state.user.enterprise_name }}】
                </a>

                <a-menu slot="overlay">
                  <a-sub-menu
                    v-if="menu"
                    key="switch"
                  >
                    <span
                      slot="title"
                      class="submenu-title-wrapper"
                    ><a-icon type="sync" />切换医院</span>
                    <a-menu-item
                      v-for="ent in allowsEnt"
                      :key="ent.id"
                      @click="handleToSwitch"
                    >
                      <span :class="ent.enterprise_name === $store.state.user.enterprise_name ? 'text-success' : ''">{{ ent.enterprise_name}}</span>
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-divider v-if="menu" />
                  <a-menu-item
                    v-if="menu"
                    key="settings"
                    @click="handleToSettings"
                  >
                    <a-icon type="setting" />个人中心
                  </a-menu-item>
                  <a-menu-divider v-if="menu" />
                  <a-menu-item
                    key="logout"
                    @click="handleLogout"
                  >
                    <a-icon type="logout" />退出登录
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

            </span>
            <!-- / Header Control Buttons -->

          </a-col>
          <!-- / Header Control Column -->

        </a-row>
      </a-spin>
    </a-layout-header>
    <!--  /Layout Header -->

  </component>
  <!-- / Main Sidebar -->

</template>

<script>

// const notificationsData = [
//   {
//     title: 'New message from Sophie',
//     img: 'images/face-1.jpg',
//     time: '13 minutes ago',
//   },
//   {
//     title: 'New album by Travis Scott',
//     svg: `<svg width="20" height="20" viewBox="0 0 107 107" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
// 					<title>logo-spotify</title>
// 					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
// 						<g id="logo-spotify" fill="#2EBD59" fill-rule="nonzero">
// 							<path d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z" id="Shape"></path>
// 						</g>
// 					</g>
// 				</svg>`,
//     time: '1 day ago',
//   },
//   {
//     title: 'Payment completed',
//     svg: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
// 					<path fill="#1890FF" d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"/>
// 					<path fill="#1890FF" fill-rule="evenodd" clip-rule="evenodd" d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"/>
// 				</svg>`,
//     time: '2 days ago',
//   },
// ]

import { Modal } from 'ant-design-vue'
import { getAllowsEntList, saveCurrentDep } from '@/api/dms/user'

export default ({
  props: {
    // Header fixed status.
    navbarFixed: {
      type: Boolean,
      default: false
    },
    sidebarMinimized: {
      type: Boolean,
      default: false
    },

    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false
    },

    menu: {
      type: Boolean,
      default: true
    }
    // Header notifications data.
    // notificationsData: {
    //   type: Array,
    //   default: () => notificationsData,
    // },
  },
  data () {
    return {
      // Fixed header/sidebar-footer ( Affix component ) top offset.
      top: 0,
      defAvatar: '/images/avatar-0.jpg',
      // defAvatar: process.env.NODE_ENV !== 'production' ? '/images/avatar-0.jpg' : '/webDms/images/avatar-0.jpg',

      // The wrapper element to attach dropdowns to.
      wrapper: document.body,

      // 可访问企业列表
      allowsEnt: [],

      spinningEnt: false
    }
  },
  methods: {
    resizeEventHandler () {
      this.top = this.top ? 0 : -0.01
      // To refresh the header if the window size changes.
      // Reason for the negative value is that it doesn't activate the affix unless
      // scroller is anywhere but the top of the page.
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout (e) {
      const that = this
      Modal.confirm({
        title: '账号注销',
        content: '确定要退出当前登录账号',
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return that.$store.dispatch('Logout').then(() => {
            that.$router.push({ path: '/user/login' })
          })
        },
        onCancel () { }
      })
    },
    handleToSwitch (e) {
      if (this.allowsEnt.length > 0 && this.allowsEnt.some((val) => { return val.id === e.key })) {
        this.spinningEnt = true
        saveCurrentDep({ platform: this.$store.getters.userInfo.platform.id, user: this.$store.getters.userInfo.user.id, enterprise: e.key }).then(res => {
          if (res.status !== undefined && res.status === 1) {
            let userInfo = { ...this.$store.getters.userInfo }
            userInfo.user = Object.assign(userInfo.user, res.data)
            this.$store.commit('SET_INFO', userInfo)
            this.$store.commit('SET_ENTERPRISE', res.data.enterprise_name)
            // 切换完成以后跳首页，不然后面每个页面都会面临切换刷新的问题
            this.$router.push({ path: '/dashboard' })
            // 强制刷新页面，但这种方法不会重新加载JavaScript文件，因为浏览器会从缓存中加载。主要是需要重新请求permissionInfo获取权限
            this.$router.go(0)
          } else {
            this.$message.error(res.msg || '切换失败，请稍后再试！')
          }
          this.spinningEnt = false
        })
      } else {
        this.$message.error('请确认账号具有所选医院的访问权限！')
        return false
      }

    }
  },
  mounted: function () {
    // Set the wrapper to the proper element, layout wrapper.
    this.wrapper = document.getElementById('layout-dashboard')
  },
  created () {
    // Registering window resize event listener to fix affix elements size
    // error while resizing.
    window.addEventListener("resize", this.resizeEventHandler)
    getAllowsEntList({ user: this.$store.getters.userInfo.user.id }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.allowsEnt = res.data
      }
    })
    console.log(this.$route)
  },
  destroyed () {
    // Removing window resize event listener.
    window.removeEventListener("resize", this.resizeEventHandler)
  },
})

</script>
