import request from '@/utils/request'

const apiUrls = {
  getClientAppointmentList: '/HealthClientAppointments/getClientAppointmentList',
  saveClientAppointmentDealInfo: '/HealthClientAppointments/saveClientAppointmentDealInfo',
  getClientAppointmentByUser: '/HealthClientAppointments/getClientAppointmentByUser',
  getNewestNotDealAppointmentInfo: '/HealthClientAppointments/getNewestNotDealAppointmentInfo',
  addPatientAppointInfo: '/HealthClientAppointments/addPatientAppointInfo',
  dashboardAppointSummary: '/HealthClientAppointments/dashboardAppointSummary',
  addDocDayAppoint: '/HealthClientAppointments/addDocDayAppoint'
}

export function getClientAppointmentList (parameter) {
  return request({
    url: apiUrls.getClientAppointmentList,
    method: 'get',
    params: parameter
  })
}

export function saveClientAppointmentDealInfo (data) {
  return request({
    url: apiUrls.saveClientAppointmentDealInfo,
    method: 'post',
    data
  })
}

export function getClientAppointmentByUser (parameter) {
  return request({
    url: apiUrls.getClientAppointmentByUser,
    method: 'get',
    params: parameter
  })
}

export function getNewestNotDealAppointmentInfo (parameter) {
  return request({
    url: apiUrls.getNewestNotDealAppointmentInfo,
    method: 'get',
    params: parameter
  })
}

export function addPatientAppointInfo (data) {
  return request({
    url: apiUrls.addPatientAppointInfo,
    method: 'post',
    data
  })
}

export function dashboardAppointSummary (data) {
  return request({
    url: apiUrls.dashboardAppointSummary,
    method: 'post',
    data
  })
}

export function addDocDayAppoint (data) {
  return request({
    url: apiUrls.addDocDayAppoint,
    method: 'post',
    data
  })
}

