var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in layout-sign-up-illustration"},[_c('a-spin',{attrs:{"tip":"处理中...","size":"large","spinning":_vm.spinning}},[_c('a-row',{staticClass:"row-main",attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form mr-auto",attrs:{"span":24,"md":{ span: 14, offset: 2 },"lg":10,"xl":6}},[_c('h4',{staticClass:"mb-15"},[_vm._v("账号注册")]),_c('p',{staticClass:"text-muted"},[_vm._v("仅需手机验证即可入驻平台")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.signUpForm,"layout":"horizontal","hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"医院名称","colon":false}},[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'enterprise_name',
                { rules: [{ required: true, message: '请输入医院名称！'}] } ]),expression:"[\n                'enterprise_name',\n                { rules: [{ required: true, message: '请输入医院名称！'}] },\n              ]"}],attrs:{"data-source":_vm.entDataSource,"optionLabelProp":"children","placeholder":"医院名称"},on:{"search":_vm.onSearchEnt}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"姓名","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nick_name',
              { rules: [{ required: true,min:2, message: '请输入您的姓名！', whitespace: true }] } ]),expression:"[\n              'nick_name',\n              { rules: [{ required: true,min:2, message: '请输入您的姓名！', whitespace: true }] },\n            ]"}],attrs:{"placeholder":"您的姓名"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"用户名","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'user_name',
              { rules: [{ required: true, message: '请输入账号登录用户名！', whitespace: true },{ validator: _vm.checkUserName }] } ]),expression:"[\n              'user_name',\n              { rules: [{ required: true, message: '请输入账号登录用户名！', whitespace: true },{ validator: checkUserName }] },\n            ]"}],attrs:{"placeholder":"账号登录用户名"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"密码","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'user_passwd',
              { rules: [{ required: true, min: 6, max: 12, message: '请输入6~12位登录密码', whitespace: true}] } ]),expression:"[\n              'user_passwd',\n              { rules: [{ required: true, min: 6, max: 12, message: '请输入6~12位登录密码', whitespace: true}] },\n            ]"}],attrs:{"type":"password","placeholder":"账号登录密码"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"密码确认","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'user_passwd2',
              { rules: [{ required: true, message: '请再次输入登录密码！', whitespace: true},{ validator: this.compareToPassword }] } ]),expression:"[\n              'user_passwd2',\n              { rules: [{ required: true, message: '请再次输入登录密码！', whitespace: true},{ validator: this.compareToPassword }] },\n            ]"}],attrs:{"type":"password","placeholder":"再次输入登录密码"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"手机号","colon":false}},[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              { rules: [{ required: true, message: '请输入手机号！', whitespace: true },{ validator: _vm.checkMobile }] } ]),expression:"[\n              'mobile',\n              { rules: [{ required: true, message: '请输入手机号！', whitespace: true },{ validator: checkMobile }] },\n            ]"}],attrs:{"enter-button":_vm.btnText,"disabled":_vm.btnMaxSec >= 0,"placeholder":"手机号"},on:{"search":_vm.onSendCode}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"短息验证码","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'captcha',
              { rules: [{ required: true, message: '请输入手机号收到的短息验证码！', whitespace: true }] } ]),expression:"[\n              'captcha',\n              { rules: [{ required: true, message: '请输入手机号收到的短息验证码！', whitespace: true }] },\n            ]"}],attrs:{"placeholder":"短息验证码"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remember',
            {
              valuePropName: 'checked',
              initialValue: true,
            } ]),expression:"[\n            'remember',\n            {\n              valuePropName: 'checked',\n              initialValue: true,\n            },\n          ]"}]},[_vm._v(" 阅读并同意《服务协议》和《个人信息保护政策》 ")])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" 注册 ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-right"},[_vm._v("已有账号？"),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/login"}},[_vm._v("点击登录")])],1)],1),_c('a-col',{staticClass:"col-img",staticStyle:{"padding":"0"},attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('div',[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"/images/info-rocket-ill.png","alt":"rocket"}})]),_c('h4',{staticClass:"text-white"},[_vm._v("Your journey starts here")]),_c('p',{staticClass:"text-white"},[_vm._v("Just as it takes a company to sustain a product, it takes a community to sustain a protocol.")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }