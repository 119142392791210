<template>
  <a-modal
    v-model="modalVisible1"
    :dialog-style="{ top: '20px' }"
    title="定制食谱详情"
    :width="1400"
    :destroyOnClose="true"
    :maskClosable="false"
  >
    <template slot="footer">
      <a-button
        type="primary"
        @click="handleModalOk1"
      >
        关闭
      </a-button>
    </template>
    <a-spin
      tip="处理中..."
      :spinning="spinningM1"
    >
      <a-row
        :gutter="24"
        type="flex"
        class="layout-dashboard"
        align="stretch"
      >
        <a-col
          :span="24"
          class="mb-10"
        >
          <a-card
            :bordered="false"
            class="card-categories header-solid h-full"
            :bodyStyle="{paddingTop: 0, paddingBottom: '12px' }"
          >
            <a-row
              type="flex"
              align="middle"
            >
              <a-col :span="2">
                <a-avatar
                  :size="100"
                  shape="square"
                  :src="foodmenuInfo.img_show !== '' ? foodmenuInfo.img_show : ''"
                />
              </a-col>
              <a-col :span="12">
                <h6 class="font-semibold m-0">{{ foodmenuInfo.menu_name}}</h6>
                <p class="font-semibold mb-0">{{ foodmenuInfo.menu_intro}}</p>
                <p class="font-semibold">食谱日期：{{ foodmenuInfo.start_date }} ~ {{ foodmenuInfo.end_date }}</p>
              </a-col>
              <a-col :span="10">
                <p class="mb-0">不喜欢食物： {{ foodmenuInfo.food_dislike }}</p>
                <p class="mb-0">过敏食物： {{ foodmenuInfo.allergies }}</p>
                <p class="mb-0">不耐受食物： {{ foodmenuInfo.food_intolerant }}</p>
                <p class="mb-0">营养补充剂： {{ foodmenuInfo.supplement }}</p>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-row
        :gutter="24"
        type="flex"
        class="layout-dashboard"
        align="stretch"
      >
        <a-col
          :span="24"
          class="mb-10"
        >
          <a-tabs>

            <a-tab-pane
              v-for="(dayData) in foodmenuDay"
              :key="dayData.day"
              :tab="'第'+dayData.day+'天'"
              force-render
            >
              <a-row :gutter="16">
                <a-col :span="18">
                  <a-row
                    :gutter="24"
                    type="flex"
                    class="layout-dashboard"
                    align="stretch"
                  >
                    <template v-for="(etype) in eatingType">
                      <a-col
                        :span="24"
                        class="mb-10"
                        v-if="dayData.dayRecipeArr[etype.value] !== undefined"
                        :key="etype.value"
                      >
                        <a-card
                          :bordered="false"
                          class="card-categories header-solid h-full"
                          :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
                        >
                          <template #title>
                            <h6 class="font-semibold m-0"> {{ etype.label }}</h6>
                          </template>
                          <a-row>

                            <a-table
                              :columns="columns"
                              :data-source="dayData.dayRecipeArr[etype.value]"
                              :pagination="false"
                              rowKey="id"
                              size="small"
                            >

                              <span
                                slot="action"
                                slot-scope="text, record"
                              >
                                <template>
                                  <a @click="clickViewRecipe(record)">详情</a>
                                </template>
                              </span>
                            </a-table>
                          </a-row>
                        </a-card>
                      </a-col>
                    </template>
                  </a-row>

                </a-col>
                <a-col :span="6">
                  <DailyFoodMenuViewChart
                    :dailyFoodMenuDetail="dayData"
                    :maxCalory="(dayData.calory_max !== undefined && dayData.calory_max !== null && dayData.calory_max !== '') ? parseFloat(dayData.calory_max) : 3200"
                  />
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>

      <!-- 详情弹窗组件 -->
      <FoodRecipeDetailModal
        :recipeDetail="recipeDetail"
        :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
        @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { viewPatientFoodMenu } from '@/api/dms/patientFoodMenu'
import DailyFoodMenuViewChart from '@/components/DailyFoodMenus/DailyFoodMenuViewChart'
import FoodRecipeDetailModal from '@/components/FoodRecipes/FoodRecipeDetailModal'

export default ({
  name: 'CardFoodmenuOneModal',
  components: {
    DailyFoodMenuViewChart,
    FoodRecipeDetailModal
  },
  data () {
    return {
      tip: '加载中...',
      loadingFoodmenu: false,
      foodmenuInfo: {},
      foodmenuDay: [],
      modalVisible1: false,
      spinningM1: false,
      eatingType: this.$options.filters.selectOptionsData('health.eating_type'),
      columns: [
        {
          title: '显示名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: false,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 80,
          dataIndex: 'eating_count',
          ellipsis: false,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '操作',
          ellipsis: true,
          width: 60,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      recipeDetail: {},
      foodRecipeDetailModalVisible: false,
    }
  },
  created () {

  },
  methods: {
    showModal (foodmenu) {
      this.modalVisible1 = true
      this.spinningM1 = true

      this.$nextTick(() => {
        viewPatientFoodMenu({ menu: foodmenu.id, user: this.$store.getters.userInfo.user.id, join: ['day', 'recipe'] }).then((res) => {
          if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
            const fmData = JSON.parse(JSON.stringify(res.data))
            delete fmData.patient_food_menu_day
            this.foodmenuInfo = fmData
            // console.log(res.data)
            res.data.patient_food_menu_day.forEach(element => {
              element.dayRecipeArr = []
              element.patient_food_menu_day_recipe.forEach(el => {
                if (element.dayRecipeArr[el.eating_type] == undefined) {
                  element.dayRecipeArr[el.eating_type] = []
                }
                element.dayRecipeArr[el.eating_type].push(JSON.parse(el.recipe_def_detail))
              })
            })
            this.foodmenuDay = res.data.patient_food_menu_day
            this.spinningM1 = false
          }
        })
      })
    },
    handleModalOk1 () {
      this.modalVisible1 = false
      this.foodmenuInfo = {}
      this.foodmenuDay = []
    },
    clickViewRecipe (record) {
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    foodRecipeDetailModalVisibleChange (v) {
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>