import request from '@/utils/request'

const apiUrls = {
  saveServiceRate: '/HealthServiceRecordRatings/saveServiceRate'
}

export function saveServiceRate (data) {
  return request({
    url: apiUrls.saveServiceRate,
    method: 'post',
    data
  })
}
