<template>
  <div id="settings">
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <div class="step-box">
        <div class="mb-20">
          <!-- 跳过第一步为true，开发完要改回false哦！！！！！！ -->
          <a-button
            class="float-right mr-20"
            icon="copy"
            size="small"
            type="danger"
            @click="loadStep1OriInfo(false)"
            v-if="params.record === undefined || params.record < 1"
          >回显最近报告数据</a-button>
          <a-descriptions
            title="当前客户"
            v-if="patientInfo.id !== undefined"
          >
            <a-descriptions-item label="客户姓名">{{ patientInfo.name }}</a-descriptions-item>
            <a-descriptions-item label="性别">{{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}</a-descriptions-item>
            <a-descriptions-item label="出生日期">{{ patientInfo.birth_date }}</a-descriptions-item>

            <a-descriptions-item label="编号">{{ patientInfo.patient_no }}</a-descriptions-item>
            <a-descriptions-item label="联系电话">{{ patientInfo.contact }}</a-descriptions-item>
            <a-descriptions-item label="身份证号">{{ patientInfo.id_card }}</a-descriptions-item>

            <a-descriptions-item
              label="关注问题"
              :span="3"
            >
              <a-tag
                color="orange"
                v-for="tag in patientInfo.his_patient_tag"
                :key="tag.tag_id"
              >
                {{tag.tag_text}}
              </a-tag>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <a-steps
          :current="currentStep"
          @change="onStepChange"
        >
          <a-step
            title="客户基本信息"
            description="录入客户体格指标、期望目标等基本信息。"
          />
          <a-step
            title="问诊信息"
            description="录入客户相关问诊内容和营养方案等信息"
          />
          <a-step
            title="随访与监测"
            description="与客户定制随访计划以及相关监测指标"
          />
        </a-steps>
      </div>

      <a-row
        type="flex"
        :gutter="[24,24]"
      >
        <!-- 左侧目录框 -->
        <a-col
          :span="24"
          :lg="5"
        >
          <a-affix :offset-top=" navbarFixed ? 100 : 10 ">
            <a-card
              :bordered="false"
              class="header-solid mb-24"
            >
              <a-anchor
                :targetOffset=" navbarFixed ? 100 : 10 "
                :affix="false"
              >
                <a-anchor-link
                  v-for="(aav, aaidx) in anchorArr"
                  :key="aaidx"
                  :href="aav.hrefId"
                >
                  <div
                    slot="title"
                    class="ant-list-item-meta"
                  >
                    <a-icon
                      :type="aav.icon"
                      class="text-gray-6 text-lg"
                    />
                    <h4 class="ant-list-item-meta-title">
                      <span class="font-regular">{{ aav.title }}</span>
                    </h4>
                  </div>
                </a-anchor-link>

              </a-anchor>
            </a-card>
          </a-affix>
        </a-col>

        <a-col
          :span="24"
          :lg="19"
        >
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <h6
              id="body-info"
              class="mb-20"
              v-if="!showStep1"
            >体格指标信息
              <a-button
                class="float-right mr-40"
                icon="user"
                size="small"
                type="danger"
                @click="loadStep1NewestBodyInfo"
              >获取最新体格指标数据</a-button>
            </h6>
            <form-create
              v-model="cformBodyApi"
              :rule="cformBodyRule"
              :option="cformOption"
            ></form-create>
            <a-divider
              v-if="!showStep1"
              style="margin-top: 16px;"
            />

            <h6
              id="appoint-info"
              class="mb-20"
              v-if="!showStep1"
            >预约目标及期望</h6>
            <form-create
              v-model="cformAppointApi"
              :rule="cformAppointRule"
              :option="cformAppointOption"
            >
            </form-create>

            <!-- 第二步相关样式 -->
            <form-create
              id="medical-info"
              v-model="cformMedicalApi"
              :rule="cformMedicalRule"
              :option="cformAppointOption"
            >
            </form-create>
            <form-create
              id="dietary-info"
              v-model="cformDietaryApi"
              :rule="cformDietaryRule"
              :option="cformOption"
            >
            </form-create>
            <form-create
              id="nutrition-info"
              v-model="cformNutritionApi"
              :rule="cformNutritionRule"
              :option="cformOption"
            >
            </form-create>

            <!-- 第三步相关样式 -->
            <form-create
              id="followup-info"
              v-model="cformFollowupApi"
              :rule="cformFollowupRule"
              :option="cformOption"
            >
            </form-create>
            <form-create
              id="monitor-info"
              v-model="cformMonitorApi"
              :rule="cformMonitorRule"
              :option="cformAppointOption"
            >
            </form-create>

            <!-- 控制底部按钮显示 -->
            <a-row>
              <a-col
                :offset="10"
                :span="12"
                v-if="!showStep1"
              >
                <a-button
                  icon="check"
                  @click="clickStep1Save"
                  type="primary"
                >确认保存</a-button>

                <a-button
                  icon="arrow-right"
                  @click="onStepChange(false)"
                  type="primary"
                  class="ml-10"
                >下一步</a-button>
              </a-col>

              <a-col
                :offset="7"
                :span="12"
                v-if="!showStep2"
              >
                <a-button
                  icon="plus"
                  type="danger"
                  ghost
                  @click="clickAddSport"
                >
                  继续添加运动内容
                </a-button>

                <a-button
                  icon="check"
                  @click="clickStep2Save"
                  type="primary"
                  class="mx-10"
                >确认保存</a-button>

                <a-button
                  icon="arrow-right"
                  @click="onStepChange(false)"
                  type="primary"
                >下一步</a-button>
              </a-col>

              <a-col
                :offset="10"
                :span="12"
                v-if="!showStep3"
              >
                <a-button
                  icon="check"
                  @click="clickStep3Save"
                  type="primary"
                >确认保存</a-button>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <!-- 饮食模式的抽屉窗口 -->
      <dietary-pattern-detail
        :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
        :parentParams="dietaryPatternParams"
        @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
      />

    </a-spin>
  </div>
</template>

<script>
import { getPatientInfo, getPatientBodyInfoByServiceRecord, getPatientNewestBodyInfo, savePatientMedicalInfo, savePatientNutritionInfo, getPatientMedicalInfo, getPatientNutritionInfo, removePatientPrescriptionSport, getPatientAppointInfo, savePatientDietaryInfo, getPatientDietaryInfo } from '@/api/dms/patient'
import { savePatientServiceRecord, getServiceRecordInfo } from '@/api/dms/serviceRecord'
import { getNewestNotDealAppointmentInfo } from '@/api/dms/clientAppointment'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { savePatientFollowPlanInfo, getPatientFollowPlanInfo } from '@/api/dms/followup'
import { savePatientMonitorRecordInfo, getPatientMonitorRecordInfo } from '@/api/dms/monitorRecord'
import { getAndRenderBodyItemFormData } from '@/utils/bodyItemCalc'
import { DietaryPatternDetail } from '@/components/DietaryPattern'

export default {
  name: 'SetServiceRecord',
  props: ['navbarFixed'],
  components: { DietaryPatternDetail },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformBodyApi: {},
      cformAppointApi: {},
      cformMedicalApi: {},
      cformNutritionApi: {},
      cformFollowupApi: {},
      cformMonitorApi: {},
      cformDietaryApi: {},
      //表单生成规则
      cformBodyRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'patient_id'
        }
      ],
      cformAppointRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'client_appoint_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'input',
          title: '预约原因',
          field: 'reason',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写预约原因' },
          validate: [
            { required: true, message: '请填写预约原因', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '期望达到效果',
          field: 'expectation',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写期望达到效果' },
          validate: [
            { required: true, message: '请填写期望达到效果', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '目标',
          field: 'goal',
          props: { placeholder: '请选择目标' },
          options: [],
          validate: [
            { required: true, message: '请选择目标' }
          ]
        },
        {
          type: 'input',
          title: '目标备注',
          field: 'goal_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写目标备注' }
        },
        {
          type: 'input',
          title: '其他说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他说明' }
        },
      ],
      cformMedicalRule: [
        {
          type: 'h6',
          name: 'h6',
          class: 'mb-20',
          children: ['病史及用药信息']
        },
        {
          type: 'hidden',
          field: 'id'
        },
        // {
        //   type: 'hidden',
        //   field: 'patient_id'
        // },
        // {
        //   type: 'hidden',
        //   field: 'enterprise_id',
        //   value: this.$store.getters.userInfo.user.target_enterprise
        // },
        // {
        //   type: 'hidden',
        //   field: 'create_user',
        //   value: this.$store.getters.userInfo.user.id
        // },
        {
          type: 'select',
          title: '疾病信息',
          field: 'disease',
          props: { mode: 'multiple', placeholder: '请选择疾病信息', maxTagCount: 5, maxTagTextLength: 10, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择疾病信息' }
          ]
        },
        {
          type: 'input',
          title: '其他疾病及备注',
          field: 'disease_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他疾病信息及备注' }
        },
        {
          type: 'input',
          title: '现病史及服药信息',
          field: 'medication',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写现病史及服药信息' }
        },
        {
          type: 'input',
          title: '既往病史',
          field: 'personal_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写既往病史' }
        },
        {
          type: 'input',
          title: '家族病史',
          field: 'family_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写家族病史' }
        },
        {
          type: 'input',
          title: '其他备注说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注说明' }
        }
      ],
      cformDietaryRule: [
        {
          type: 'h6',
          name: 'h6',
          class: 'mb-20',
          children: ['个人饮食习惯']
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'select',
          title: '饮食类型',
          field: 'diet_type',
          props: { mode: 'multiple', placeholder: '请选择饮食类型', maxTagCount: 2, maxTagTextLength: 10, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择饮食类型' }
          ]
        },
        {
          type: 'input',
          title: '其他饮食类型',
          field: 'diet_type_remarks',
          props: { placeholder: '请填写其他饮食类型及备注' }
        },
        {
          type: 'input',
          field: 'food_favorite',
          title: '喜欢的食物',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写喜欢的食物' }
        },
        {
          type: 'input',
          field: 'food_dislike',
          title: '不喜欢的食物',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不喜欢的食物' }
        },
        {
          type: 'input',
          title: '过敏食物',
          field: 'allergies_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写过敏食物' }
        },
        {
          type: 'input',
          title: '不耐受食物',
          field: 'food_intolerant_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不耐受食物' }
        },
        {
          type: 'input',
          title: '缺乏营养',
          field: 'nutrition_deficiency_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写缺乏营养' }
        },
        {
          type: 'input',
          title: '日饮水量',
          field: 'water_intake', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写日饮水量', min: 0, step: 1, max: 127, addonAfter: '升' },
        },
        {
          type: 'input',
          title: '其他备注信息',
          field: 'other_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注信息' }
        },
      ],
      cformNutritionRule: [
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          class: 'mb-20',
          children: ['营养处方']
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'life_id'
        },
        {
          type: 'hidden',
          field: 'recipe_id'
        },
        {
          type: 'input',
          title: '营养评定',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养评定' },
          validate: [
            { required: true, message: '请填写营养评定', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '干预方法',
          field: 'intervention_method',
          props: { placeholder: '请选择干预方法' },
          options: [],
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          validate: [
            { required: true, message: '请选择干预方法' }
          ]
        },
        {
          type: 'select',
          title: '饮食模式推荐',
          field: 'dietary_pattern_id',
          props: { placeholder: '请选饮食模式推荐' },
          options: [],
          col: {
            span: 18
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 19 }
          }
        },
        {
          type: 'a-button',
          class: 'ant-col-5 mt-5',
          name: 'a-button',
          props: { icon: 'file-protect', type: 'primary' },
          children: ['查看饮食模式详情'],
          on: {
            click: () => this.clickshowPattern()
          }
        },
        {
          type: 'input',
          title: '推荐方案',
          field: 'recommendation',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写推荐方案' }
        },
        {
          type: 'select',
          title: '一周运动量',
          field: 'calorie_calc',
          props: { placeholder: '请选一周运动量' },
          options: [],
          on: {
            change: (v) => this.calorieCalcChange(v)
          }
        },
        {
          type: 'input',
          title: '热量摄入建议',
          field: 'calorie', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写热量摄入建议', min: 0, step: 1, max: 1000000, addonAfter: 'Kcal' }
        },
        {
          type: 'slider',
          title: '碳水摄入建议',
          field: 'carbohydrates',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },//tooltipVisible: true,
          props: { placeholder: '请选择碳水摄入建议', min: 0, step: 1, max: 100, marks: { 55: '55%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'carbohydrates')
          }
        },
        {
          type: 'slider',
          title: '蛋白质摄入',
          field: 'protein',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },
          props: { placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, marks: { 15: '15%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'protein')
          }
        },
        {
          type: 'slider',
          title: '脂肪摄入建议',
          field: 'fat',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },
          props: { placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, marks: { 30: '30%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'fat')
          }
        },
        // {55%的碳水化合物、15%的蛋白质和30%的脂肪。
        //   type: 'input',
        //   title: '碳水摄入建议',
        //   field: 'carbohydrates', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写碳水摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        // {
        //   type: 'input',
        //   title: '蛋白质摄入',
        //   field: 'protein', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        // {
        //   type: 'input',
        //   title: '脂肪摄入建议',
        //   field: 'fat', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['一日食谱制定']
        },
        {
          type: 'input',
          title: '早餐',
          field: 'breakfast',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早餐' }
        },
        {
          type: 'input',
          title: '早加餐',
          field: 'snack_morning',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早加餐' }
        },
        {
          type: 'input',
          title: '午餐',
          field: 'lunch',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午餐' }
        },
        {
          type: 'input',
          title: '午加餐',
          field: 'snack_afternoon',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午加餐' }
        },
        {
          type: 'input',
          title: '晚餐',
          field: 'supper',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'supper', autoSize: { minRows: 2 }, placeholder: '请填写晚餐' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['生活干预、运动计划']
        },
        {
          type: 'input',
          title: '生活干预',
          field: 'life_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写生活干预' }
        },
        {
          type: 'input',
          title: '运动计划',
          field: 'sport_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动计划' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['运动内容推荐']
        },

      ],
      cformFollowupRule: [
        {
          type: 'h6',
          name: 'h6',
          class: 'mb-20',
          children: ['随访计划']
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '随访方式',
          field: 'method',
          props: { placeholder: '请选择随访方式' },
          options: [],
          validate: [
            { required: true, message: '请选择随访方式' }
          ]
        },
        {
          type: 'input',
          title: '总次数',
          field: 'all_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写随访总次数', min: 0, step: 1, max: 1000, addonAfter: '次' },
          validate: [
            { required: true, message: '请填写随访总次数' }
          ]
        },
        {
          type: 'input',
          title: '随访周期',
          field: 'cycle',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写随访周期' },
          validate: [
            { required: true, message: '请填写随访周期', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写备注' }
        }
      ],
      cformMonitorRule: [
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          class: 'mb-20',
          children: ['指标检测计划']
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '发现的问题',
          field: 'problem',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写发现的问题' }
        },
        {
          type: 'input',
          title: '解决方案',
          field: 'solution',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写解决方案' }
        },
        {
          type: 'a-radio-group',
          title: '是否有不良反应',
          value: '0',
          field: 'is_reaction',
          props: {
            size: "small", buttonStyle: 'solid', placeholder: '请选择是否有不良反应'
          },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['无']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['有']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '执行程度及调整',
          field: 'content',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写执行程度及调整' }
        }
      ],
      sportFormRuleNum: 1,
      sportFormRuleNumArr: [],
      sportFormRuleLastField: '',
      sportFormRuleTmpArr: [
        {
          type: 'input',
          title: '运动方案',
          field: 'plan_content',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动方案' }
        },
        {
          type: 'input',
          title: '运动举例',
          field: 'example',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动举例' }
        },
        {
          type: 'input',
          title: '运动频率',
          field: 'frequency',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动频率' }
        },
        {
          type: 'input',
          title: '运动时间',
          field: 'duration',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动时间' }
        },
        {
          type: 'select',
          title: '运动方式',
          field: 'sport_type',
          props: { placeholder: '请选择运动方式' },
          options: []
        },
        {
          type: 'a-button',
          hidden: true,
          class: 'ant-col-2 mt-5',
          name: 'a-button',
          props: { icon: 'close', type: 'danger' },
          children: ['删除']
        },
        {
          type: 'hidden',
          title: '运动计划id',
          field: 'id'
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        }
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      cformAppointOption: { // 一列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 20 }
        },
        submitBtn: false
      },
      showStep1: false,
      showStep2: false,
      showStep3: false,
      currentStep: 0,
      patientInfo: {},
      anchorArr: [],
      anchorArrStep1: [
        { title: '体格指标信息', hrefId: '#body-info', icon: 'user' },
        { title: '预约目标及期望', hrefId: '#appoint-info', icon: 'solution' },
      ],
      anchorArrStep2: [
        { title: '病史及用药信息', hrefId: '#medical-info', icon: 'fork' },
        { title: '个人饮食习惯', hrefId: '#dietary-info', icon: 'book' },
        { title: '营养处方', hrefId: '#nutrition-info', icon: 'form' },
      ],
      anchorArrStep3: [
        { title: '随访计划', hrefId: '#followup-info', icon: 'solution' },
        { title: '指标检测计划', hrefId: '#monitor-info', icon: 'heart' },
      ],
      recordOri: {},
      nutritionOri: {},
      nutritionSportIdx: -1,
      setValMark: false,
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
      cpfOri: { carbohydrates: 55, protein: 15, fat: 30 }
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.setServiceRecord'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.anchorArr = this.anchorArrStep1
    this.spinning = true
    // 获取客户基本信息数据
    this.loadPatientInfo()
    // 获取渲染客户体格指标字段
    getAndRenderBodyItemFormData(this)
    // 获取客户的期望数据
    this.loadStep1AppointInfo()
    // this.loadStep1OriInfo(true) // 测试回显数据使用
    if (this.params.record !== undefined && this.params.record > 0) {
      // 有record参数则是编辑情况，需要回显id，
      this.loadStep1OriInfo(true)
      const timer = setInterval(() => {
        // 等到record主记录获取完成再加载后面的数据
        if (this.recordOri.id !== undefined && this.recordOri.id > 0) {
          this.spinning = true
          this.cformAppointApi.setValue({ service_record_id: this.params.record })
          this.loadStep2MedicalAndNutritionInfo(true)
          clearInterval(timer)
        }
      }, 100)
    }
  },
  watch: {
    nutritionSportIdx: {
      handler (newVal, oldVal) {
        if (this.nutritionSportIdx < 0 || this.nutritionOri.prescription_sport === undefined || this.nutritionOri.prescription_sport[this.nutritionSportIdx] === undefined) {
          this.spinning = false
          this.nutritionSportIdx = -1
          return false
        }
        // 回显多个运动计划
        let spfNum = this.sportFormRuleNum
        let spfNumMark = true
        if (this.sportFormRuleNumArr[this.nutritionSportIdx] !== undefined) { // 已有form-create组件，只需要回显字段值
          spfNum = this.sportFormRuleNumArr[this.nutritionSportIdx]
          spfNumMark = false
        }
        let setSportArr = {}
        let arrNum = 0
        const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
        cuTmpArr.map(spv => {
          let oriVal = this.nutritionOri.prescription_sport[this.nutritionSportIdx][spv.field]
          // 从第2个开始加删除按钮
          if (this.sportFormRuleNum > 1) {
            if (spv.field === 'sport_type') {
              spv.col = {
                span: 10
              }
              spv.wrap = {
                labelCol: { span: 6 },
                wrapperCol: { span: 16 }
              }
            }
            if (spv.type === 'a-button' && spv.name === 'a-button') {
              spv.hidden = false
              spv.on = {
                click: () => this.clickRemoveSport(spfNum)
              }
            }
          } else {
            if (spv.type === 'a-divider' && spv.name === 'a-divider') {
              spv.hidden = true
            }
          }
          if (spv.title !== undefined && spv.field !== undefined) {
            if (spv.field === 'sport_type') {
              spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
              oriVal = oriVal.toString()
            }
            spv.title = spv.title + '#' + spfNum
            spv.field = spv.field + '#' + spfNum
            if (this.setValMark || (!this.setValMark && spv.field !== 'id')) {
              // 按钮点击的回显组装运动计划的时候不回显id
              setSportArr[spv.field] = oriVal
            }
          }
          if (spfNumMark) {
            this.cformNutritionApi.append(spv, 'patient_id')
          }
          arrNum++
        })
        const timer = setInterval(() => {
          if (arrNum >= this.sportFormRuleTmpArr.length) {
            this.cformNutritionApi.setValue(setSportArr)
            if (spfNumMark) {
              this.sportFormRuleNumArr.push(this.sportFormRuleNum)
              this.sportFormRuleNum++
            }
            this.nutritionSportIdx++
            clearInterval(timer)
          }
        }, 100)
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  methods: {
    loadPatientInfo () {
      console.log(this.params)
      if (this.params.appoint !== undefined && this.params.appoint > 0) {
        // 比如首页会传入预约记录ID则直接赋值
        this.$nextTick(() => {
          this.cformAppointApi.setValue({ client_appoint_id: this.params.appoint })
        })
      } else {
        // 客户是否有预约信息最新的未处理预约记录
        getNewestNotDealAppointmentInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, dep: this.$store.getters.userInfo.user.department_id, user: this.$store.getters.userInfo.user.id }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.cformAppointApi.setValue({ client_appoint_id: res.data.id })
          }
        })
      }
      // 获取饮食模式下拉数据
      selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.cformNutritionApi.updateRules({ // 更新规则
            'dietary_pattern_id': { options: res.data }
          })
          this.renderSportFormData()
        }
      })
      // 客户基础信息
      getPatientInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        this.cformAppointApi.updateRules({ // 更新规则
          'goal': { options: this.$options.filters.selectOptionsData('patient.appoint_goal') }
        })
        this.cformMedicalApi.updateRules({ // 更新规则
          'disease': { options: this.$options.filters.selectOptionsData('patient.disease') }
        })
        this.cformDietaryApi.updateRules({ // 更新规则
          'diet_type': { options: this.$options.filters.selectOptionsData('patient.diet_type') }
        })
        this.cformNutritionApi.setValue(this.cpfOri)
        this.cformNutritionApi.updateRules({ // 更新规则
          'intervention_method': { options: this.$options.filters.selectOptionsData('prescription_nutrition.intervention_method') },
          'calorie_calc': { options: this.$options.filters.selectOptionsData('calorie.calc') },
        })
        this.cformFollowupApi.updateRules({ // 更新规则
          'method': { options: this.$options.filters.selectOptionsData('followup.method') },
        })
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient) {
          this.patientInfo = res.data
          this.spinning = false
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
        this.loadShowStep()
      }).catch((e) => {
        console.log(e)
        return this.$router.go(-1)
      })
    },
    loadShowStep () {
      if (this.currentStep === 0) {
        this.showStep1 = false
        this.showStep2 = true
        this.showStep3 = true
      }
      if (this.currentStep === 1) {
        this.showStep1 = true
        this.showStep2 = false
        this.showStep3 = true
      }
      if (this.currentStep === 2) {
        this.showStep1 = true
        this.showStep2 = true
        this.showStep3 = false
      }

      this.cformBodyApi.hideForm(this.showStep1)
      this.cformAppointApi.hideForm(this.showStep1)
      this.cformMedicalApi.hideForm(this.showStep2)
      this.cformDietaryApi.hideForm(this.showStep2)
      this.cformNutritionApi.hideForm(this.showStep2)
      this.cformFollowupApi.hideForm(this.showStep3)
      this.cformMonitorApi.hideForm(this.showStep3)
    },
    loadStep1OriInfo (issetVal = false) {
      this.spinning = true
      this.setValMark = issetVal
      // 获取主记录数据  回显按钮触发
      const getRecParam = { patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }
      if (this.params.record !== undefined && this.params.record > 0) {
        getRecParam.id = this.params.record
      }
      getServiceRecordInfo(getRecParam).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.recordOri = res.data
          if (!this.setValMark) {
            // 点击按钮回显的时候不能给recordOri写id值，否则会下一步按钮会跳转
            delete this.recordOri.id
            // 点回显按钮才获取第二步的数据
            this.loadStep2MedicalAndNutritionInfo(true)
          }
          // this.loadStep2MedicalAndNutritionInfo(true) // 测试回显数据使用
          // 数据获取成功则加载相关的体格数据
          this.loadStep1MoreInfo()
        } else {
          this.$message.error(res.msg || '获取原始信息失败！请刷新后重试！')
        }
      })
    },
    loadStep1MoreInfo () {
      // 根据主记录id获取体格信息的时候走历史表，手动获取最新体格数据的时候走检测表
      getPatientBodyInfoByServiceRecord({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1) {
          const bodyFormField = {}
          res2.data.map(rv => {
            const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.item_def_id + '@@' + rv.item_unit_name
            bodyFormField[fieldName] = rv.result_val
          })
          this.cformBodyApi.setValue(bodyFormField)
        }
        this.spinning = false
      })
    },
    loadStep1NewestBodyInfo () {
      this.spinning = true
      // 手动获取最新体格数据的时候走检测表
      getPatientNewestBodyInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1) {
          const bodyFormField = {}
          res2.data.map(rv => {
            const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.item_def_id + '@@' + rv.item_unit_name
            bodyFormField[fieldName] = rv.result_val
          })
          this.cformBodyApi.setValue(bodyFormField)
        }
        this.spinning = false
      })
    },
    loadStep1AppointInfo () {
      // 加载目标期望数据
      getPatientAppointInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformAppointApi.setValue({ id: res.data.id, reason: res.data.reason, expectation: res.data.expectation, goal: res.data.goal.toString(), goal_remarks: res.data.goal_remarks, other_remarks: res.data.other_remarks })
        }
      })
    },
    loadStep2MedicalAndNutritionInfo (loadNext = false) {
      // 加载既往病史数据
      getPatientMedicalInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          if (!this.setValMark) {
            // 点击按钮回显的时候不能写id值，否则会下一步按钮会跳转
            delete res.data.id
          }
          res.data.disease = JSON.parse(res.data.disease)
          this.cformMedicalApi.setValue(res.data)
        }
      })
      // 加载个人饮食习惯数据
      getPatientDietaryInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          if (!this.setValMark) {
            // 点击按钮回显的时候不能写id值，否则会下一步按钮会跳转
            delete res.data.id
          }
          res.data.diet_type = JSON.parse(res.data.diet_type)
          this.cformDietaryApi.setValue(res.data)
        }
      })
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.nutritionOri = resNut.data
          if (!this.setValMark) {
            // 点击按钮回显的时候不能写id值，否则会下一步按钮会跳转
            delete this.nutritionOri.id
          }
          let setNutArr = JSON.parse(JSON.stringify(resNut.data))
          delete setNutArr.prescription_sport
          setNutArr.intervention_method = setNutArr.intervention_method.toString()
          this.cformNutritionApi.setValue(setNutArr)
          // 处理碳水蛋白脂肪
          setNutArr.carbohydrates = setNutArr.carbohydrates <= 0 ? 0 : setNutArr.carbohydrates
          setNutArr.protein = setNutArr.protein <= 0 ? 0 : setNutArr.protein
          setNutArr.fat = setNutArr.fat <= 0 ? 0 : setNutArr.fat
          this.cformNutritionApi.updateRules({
            carbohydrates: { props: { marks: { [setNutArr.carbohydrates]: setNutArr.carbohydrates + '%' } } },
            protein: { props: { marks: { [setNutArr.protein]: setNutArr.protein + '%' } } },
            fat: { props: { marks: { [setNutArr.fat]: setNutArr.fat + '%' } } }
          })
          // watch触发开始自动增加回显数据
          this.nutritionSportIdx = 0
          if (loadNext) {
            // 按钮回显的时候才会触发加载第三步的回显数据
            this.loadStep3FollowupAndMonitorInfo()
          }
        }
        this.spinning = false
      })
    },
    loadStep3FollowupAndMonitorInfo () {
      // 加载随访计划数据
      getPatientFollowPlanInfo({ patient: this.params.patient, prescription_no: this.nutritionOri.prescription_no }).then((res) => {
        console.log('ff:', res)
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          if (!this.setValMark) {
            // 点击按钮回显的时候不能写id值，否则会下一步按钮会跳转
            delete res.data.id
          }
          res.data.method = res.data.method.toString()
          this.cformFollowupApi.setValue(res.data)
        }
      })
      // 加载指标监测计划数据
      getPatientMonitorRecordInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res2) => {
        console.log('mm:', res2)
        if (res2.status !== undefined && res2.status === 1 && res2.data.id !== undefined && res2.data.id > 0) {
          if (!this.setValMark) {
            // 点击按钮回显的时候不能写id值，否则会下一步按钮会跳转
            delete res2.data.id
          }
          res2.data.is_reaction = res2.data.is_reaction.toString()
          this.cformMonitorApi.setValue(res2.data)
        }

        this.spinning = false
      })
    },
    onStepChange (current = false) {
      const that = this
      // 传入current则是上面步骤条的点击change事件触发的，直接设置对应值，没有传入的时候则是下面下一步按钮触发的直接+1
      if (this.currentStep === 0) {
        // 第一跳第二检测是否有主记录id
        if (this.recordOri.id === undefined || this.recordOri.id < 1) {
          this.$message.error('请先确认保存客户基本信息！')
          return false
        }
        if (current === false || current === 1) {
          this.anchorArr = this.anchorArrStep2
        }
        // 第一到第三步
        if (current === 2) {
          // 跳第三检测是否有处方号
          if (this.nutritionOri.id === undefined || this.nutritionOri.id < 1 || this.nutritionOri.prescription_no === undefined) {
            this.$message.error('请先确认保存客户问诊相关信息！')
            return false
          }
          this.anchorArr = this.anchorArrStep3
        }
      }
      if (this.currentStep === 1) {
        // 第二回第一步
        if (current === 0) {
          this.anchorArr = this.anchorArrStep1
        }
        // 第二到第三步
        if (current === false || current === 2) {
          // 跳第三检测是否有处方号
          if (this.nutritionOri.id === undefined || this.nutritionOri.id < 1 || this.nutritionOri.prescription_no === undefined) {
            this.$message.error('请先确认保存客户问诊相关信息！')
            return false
          }
          this.anchorArr = this.anchorArrStep3
        }
      }
      if (this.currentStep === 2) {
        // 第三回第一步
        if (current === 0) {
          this.anchorArr = this.anchorArrStep1
        }
        // 第三到第二步
        if (current === 1) {
          this.anchorArr = this.anchorArrStep2
        }
      }
      this.currentStep = current === false ? this.currentStep + 1 : current
      this.loadShowStep()
    },
    clickStep1Save () {
      this.cformBodyApi.validate((valid, fail) => {
        if (valid) {
          this.cformAppointApi.validate((valid2, fail2) => {
            if (valid2) {
              this.spinning = true
              this.tip = '处理中...'
              savePatientServiceRecord(Object.assign({}, this.cformBodyApi.formData(), this.cformAppointApi.formData())).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  this.cformAppointApi.setValue({ service_record_id: res.data.record })
                  this.$message.success('保存成功！')
                  this.tip = '加载中...'
                  this.loadStep1OriInfo(true)
                } else {
                  this.$message.error(res.msg || '保存失败！请刷新后重试！')
                  this.spinning = false
                }
              })
            } else {
              this.$message.error('请确认必填项填写完成！')
            }
          })
        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    },
    clickStep2Save () {
      this.cformMedicalApi.validate((valid, fail) => {
        if (valid) {
          this.cformDietaryApi.validate((valid, fail) => {
            if (valid) {
              this.cformNutritionApi.validate((valid2, fail2) => {
                if (valid2) {
                  this.spinning = true
                  this.tip = '处理中...'
                  const sameUserArr = { service_record_id: this.recordOri.id, patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id }
                  let reqOkNum = 0
                  savePatientMedicalInfo(Object.assign({}, sameUserArr, this.cformMedicalApi.formData())).then((res) => {
                    if (res.status !== undefined && res.status === 1) {
                      reqOkNum++
                    } else {
                      this.$message.error(res.msg || '保存失败！请刷新后重试！')
                      this.spinning = false
                    }
                  })
                  savePatientDietaryInfo(Object.assign({}, sameUserArr, this.cformDietaryApi.formData())).then((res) => {
                    if (res.status !== undefined && res.status === 1) {
                      reqOkNum++
                    } else {
                      this.$message.error(res.msg || '保存失败！请刷新后重试！')
                      this.spinning = false
                    }
                  })
                  savePatientNutritionInfo(Object.assign({}, sameUserArr, this.cformNutritionApi.formData())).then((res) => {
                    if (res.status !== undefined && res.status === 1) {
                      reqOkNum++
                    } else {
                      this.$message.error(res.msg || '保存失败！请刷新后重试！')
                      this.spinning = false
                    }
                  })
                  const that = this
                  const timer = setInterval(() => {
                    if (reqOkNum >= 3) {
                      that.$message.success('保存成功！')
                      that.tip = '加载中...'
                      that.setValMark = true  // 设置要回显id的标识
                      that.loadStep2MedicalAndNutritionInfo()
                      clearInterval(timer)
                    }
                  }, 100)
                } else {
                  this.$message.error('请确认营养方案必填项填写完成!！')
                }
              })
            } else {
              this.$message.error('请确认个人饮食习惯必填项填写完成！~')
            }
          })
        } else {
          this.$message.error('请确认病史及用药信息必填项填写完成！')
        }
      })
    },
    clickStep3Save () {
      this.cformFollowupApi.validate((valid, fail) => {
        if (valid) {
          this.cformMonitorApi.validate((valid2, fail2) => {
            if (valid2) {
              this.spinning = true
              this.tip = '处理中...'
              let reqOkNum = 0
              savePatientFollowPlanInfo(Object.assign({ prescription_no: this.nutritionOri.prescription_no, patient_id: this.params.patient }, this.cformFollowupApi.formData())).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  reqOkNum++
                } else {
                  this.$message.error(res.msg || '保存失败！请刷新后重试！')
                  this.spinning = false
                }
              })
              savePatientMonitorRecordInfo(Object.assign({ service_record_id: this.recordOri.id, patient_id: this.params.patient }, this.cformMonitorApi.formData())).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  reqOkNum++
                } else {
                  this.$message.error(res.msg || '保存失败！请刷新后重试！')
                  this.spinning = false
                }
              })
              const that = this
              const timer = setInterval(() => {
                if (reqOkNum >= 2) {
                  that.$message.success('保存成功！')
                  that.tip = '加载中...'
                  that.setValMark = true  // 设置要回显id的标识
                  that.loadStep3FollowupAndMonitorInfo()
                  clearInterval(timer)
                }
              }, 100)
            } else {
              this.$message.error('请确认必填项填写完成!！')
            }
          })
        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    },
    clickAddSport () {
      this.renderSportFormData()
    },
    renderSportFormData () {
      this.spinning = true
      let arrNum = 0
      const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
      cuTmpArr.map(spv => {
        // 从第二个运动计划开始就要显示删除按钮
        if (this.sportFormRuleNum > 1) {
          if (spv.field === 'sport_type') {
            spv.col = {
              span: 10
            }
            spv.wrap = {
              labelCol: { span: 6 },
              wrapperCol: { span: 16 }
            }
          }
          if (spv.type === 'a-button' && spv.name === 'a-button') {
            spv.hidden = false
            const cuspnum = parseInt(this.sportFormRuleNum)
            spv.on = {
              click: () => this.clickRemoveSport(cuspnum)
            }
          }
        } else {
          if (spv.type === 'a-divider' && spv.name === 'a-divider') {
            spv.hidden = true
          }
        }
        if (spv.title !== undefined && spv.field !== undefined) {
          if (spv.field === 'sport_type') {
            spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
          }
          spv.title = spv.title + '#' + this.sportFormRuleNum
          spv.field = spv.field + '#' + this.sportFormRuleNum
        }
        this.cformNutritionApi.append(spv, 'patient_id')
        arrNum++
      })
      const timer = setInterval(() => {
        if (arrNum >= this.sportFormRuleTmpArr.length) {
          this.sportFormRuleNumArr.push(this.sportFormRuleNum)
          // this.sportFormRuleLastField = this.sportFormRuleTmpArr[this.sportFormRuleTmpArr.length - 1].field
          this.sportFormRuleNum++
          // this.cformNutritionApi.refresh()
          clearInterval(timer)
          this.spinning = false
        }
      }, 100)
    },
    clickRemoveSport (cuext) {
      const spArrIdx = this.sportFormRuleNumArr.indexOf(cuext)
      if (spArrIdx < 0) {
        this.$message.error('请正确选择要删除的运动计划！')
        return false
      }
      // 找到id#字段，从前一个分割线字段开始往后找到plan_content#的结束位置，都删除
      let startIdx = false
      let endIdx = false
      const cuRulesArr = JSON.parse(JSON.stringify(this.cformNutritionApi.rule))
      const rmArr = []
      cuRulesArr.map((nrv, nridx) => {
        if (nrv.field !== undefined && nrv.field === 'id#' + cuext) {
          startIdx = nridx
        }
        if (nrv.field !== undefined && nrv.field === 'plan_content#' + cuext) {
          endIdx = nridx
        }
        if (nrv.field !== undefined) {
          const farr = nrv.field.split('#')
          if (farr.length === 2 && parseInt(farr[1]) === parseInt(cuext)) {
            rmArr.push(nrv.field)
          }
        }
      })
      if (startIdx === false || endIdx === false || startIdx > endIdx) {
        this.$message.error('请确认选择要删除的运动计划存在！')
        return false
      }
      const cuSpId = this.cformNutritionApi.getValue('id#' + cuext)
      this.spinning = true
      if (cuSpId !== undefined && cuSpId > 0) {
        removePatientPrescriptionSport({ id: cuSpId, service_record: this.recordOri.id, patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, prescription_no: this.nutritionOri.prescription_no }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
            this.spinning = false
          }
        })
      } else {
        this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
      }
    },
    rmSportFormField (cuRulesArr, rmArr, startIdx, endIdx, spArrIdx) {
      // 先设置没有field字段的数据为hidden隐藏，再删除有field的字段，因为rule在删除字段后索引有变化，同时进行会有问题
      let ridx = (startIdx - 1)
      for (ridx; ridx <= endIdx; ridx++) {
        if (cuRulesArr[ridx].field === undefined) {
          this.cformNutritionApi.rule[ridx].hidden = true
        }
      }
      const timer = setInterval(() => {
        if (ridx > endIdx) {
          rmArr.map(rmv => {
            this.cformNutritionApi.removeField(rmv)
          })
          clearInterval(timer)
        }
      }, 100)
      delete this.sportFormRuleNumArr[spArrIdx]
      this.sportFormRuleNumArr.splice(spArrIdx, 1)
      this.$message.success('删除成功！')
      this.spinning = false
    },
    clickshowPattern () {
      const cuPattern = this.cformNutritionApi.getValue('dietary_pattern_id')
      if (cuPattern === undefined || cuPattern < 0) {
        this.$message.error('请先选择左侧饮食模式推荐！')
        return false
      }
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
      console.log(this.dietaryPatternParams)
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    calorieCalcChange (v) {
      const bodyFormData = this.cformBodyApi.formData()
      // 写个中间默认值
      let cuBmr = 1600
      for (let bfn in bodyFormData) {
        if (bfn.indexOf('bmr') >= 0) {
          cuBmr = bodyFormData[bfn]
        }
      }
      // 根据选择的运动量和bmr计算推荐热量
      this.cformNutritionApi.setValue({ calorie: parseInt(cuBmr * v) })
    },
    cpfChange (v, field) {
      console.log(v, field)
      // 另外两个字段同步自动计算
      if (this.cpfOri[field] === undefined) {
        return false
      }
      const upNuRulesArr = {}
      const upNuValuesArr = {}
      upNuRulesArr[field] = { props: { marks: { [v]: v + '%' } } }
      upNuValuesArr[field] = v
      // 用差值先计算小值，再用100%计算剩下的值
      const diff = v - this.cpfOri[field]
      // 当前字段增加变大，另外两个字段就这个差值的平均后减小
      // const diff1 = Math.ceil((v - this.cpfOri[field]) / 2)
      // const diff2 = Math.floor((v - this.cpfOri[field]) / 2)
      // const diffMin = Math.abs(diff1) <= Math.abs(diff2) ? diff1 : diff2
      // const diffMax = Math.abs(diff1) >= Math.abs(diff2) ? diff1 : diff2
      const otherCpfArr = JSON.parse(JSON.stringify(this.cpfOri))
      delete otherCpfArr[field]
      const valArr = Object.values(otherCpfArr)
      const keyArr = Object.keys(otherCpfArr)
      // 比较剩余哪个字段的默认值大，对应减小diffMax
      // 第一个大为默认
      // let key0Val = valArr[0] - diffMax
      // let key1Val = valArr[1] - diffMin
      let key0Val = 1
      let key1Val = 1
      // 按剩余2个字段的百分比计算小值
      if (valArr[0] <= valArr[1]) {
        const minNum = Math.floor(valArr[0] / (valArr[0] + valArr[1]) * diff)
        key0Val = valArr[0] - minNum
        key1Val = 100 - v - key0Val
      } else {
        const minNum = Math.floor(valArr[1] / (valArr[0] + valArr[1]) * diff)
        key1Val = valArr[1] - minNum
        key0Val = 100 - v - key1Val
      }
      key0Val = key0Val < 0 ? 0 : key0Val
      key0Val = key0Val > 100 ? 100 : key0Val
      key1Val = key1Val < 0 ? 0 : key1Val
      key1Val = key1Val > 100 ? 100 : key1Val

      upNuRulesArr[keyArr[0]] = { props: { marks: { [key0Val]: key0Val + '%' } } }
      upNuRulesArr[keyArr[1]] = { props: { marks: { [key1Val]: key1Val + '%' } } }
      this.cformNutritionApi.updateRules(upNuRulesArr)
      this.cformNutritionApi.setValue({ [keyArr[0]]: key0Val, [keyArr[1]]: key1Val, field: v })
      console.log(upNuRulesArr, { [keyArr[0]]: key0Val, [keyArr[1]]: key1Val })
    }
  }
};
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
  .step-box {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
}
</style>