<template>
  <div>
    <a-card :bordered="false">
      <div class="mx-25">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入问卷标题、填写人、填写人联系电话搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="7"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.listSurveyResponseTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>

      <s-table
        ref="listSurveyResponseTable"
        size="default"
        rowKey="id"
        showPagination="auto"
        :columns="columns"
        :data="loadData"
        :pageSize="pageSize"
        :scroll="tableScroll"
      >
        <span
          slot="source_type"
          slot-scope="text"
        >
          {{ 'survey_response.source_type' | showText(text.toString()) }}
        </span>
        <span
          slot="is_finished"
          slot-scope="text"
          :class="text >= 1 ? 'font-success' : 'font-error'"
        >
          {{ text >= 1 ? '是' : '否' }}
        </span>
        <span
          slot="status"
          slot-scope="text"
        >
          <a-badge
            :status="text | statusTypeFilter"
            :text="'survey_response.status' | showText(text)"
          />
        </span>
        <span
          slot="action"
          slot-scope="text, record"
        >
          <template>
            <span>
              <a @click="clickView(record)">详情</a>
            </span>
          </template>
        </span>
      </s-table>

      <!-- 问卷填写查看的抽屉窗口 -->
      <survey-response-view ref="surveyResponseViewDrawer" />
    </a-card>
  </div>

</template>

<script>
import { listSurveyResponse } from '@/api/dms/surveyResponse'
import { SurveyResponseView } from '@/components/Surveys'

export default ({
  name: 'CardPersonalSurvey',
  components: { SurveyResponseView },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      tip: '加载中...',
      columns: [
        {
          title: 'ID',
          width: 65,
          dataIndex: 'id'
        },
        {
          title: '问卷标题',
          // width: 180,
          ellipsis: true,
          dataIndex: 'title'
        },
        {
          title: '绑定客户姓名-编号',
          // width: 180,
          ellipsis: true,
          dataIndex: 'patient_name',
          customRender: (text, row) => {
            return (text === null ? '' : text) + ' - ' + (row.patient_no === null ? '' : row.patient_no)
          }
        },
        {
          title: '问卷渠道',
          width: 70,
          ellipsis: true,
          dataIndex: 'source_type',
          scopedSlots: { customRender: 'source_type' }
        },
        {
          title: '问卷填写人',
          ellipsis: true,
          width: 80,
          dataIndex: 'contact_name'
        },
        {
          title: '问卷填写人电话',
          ellipsis: true,
          width: 110,
          dataIndex: 'contact_phone'
        },
        {
          title: '问卷填写人IP',
          ellipsis: true,
          width: 100,
          dataIndex: 'source_ip'
        },
        {
          title: '问卷填写开始-结束时间',
          // width: 150,
          ellipsis: true,
          dataIndex: 'end_time',
          customRender: (text, row) => {
            return (row.start_time === null ? '' : row.start_time) + ' - ' + (text === null ? '' : text)
          }
        },
        {
          title: '是否完成',
          width: 70,
          align: 'center',
          dataIndex: 'is_finished',
          scopedSlots: { customRender: 'is_finished' }
        },
        {
          title: '当前状态',
          width: 75,
          ellipsis: true,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      extraParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, this.extraParam, parameter, this.queryParam)
        return listSurveyResponse(requestParameters)
          .then(res => {
            console.log(res)
            return res
          })
      }
    }
  },
  created () {
    console.log(this.parentParams)
    this.queryParam.patient = this.parentParams.patient
  },
  filters: {
    statusTypeFilter (status) {
      return status.toString() === '10' ? 'success' : 'error'
    }
  },
  methods: {
    clickView (record) {
      this.$refs.surveyResponseViewDrawer.showDrawer({ id: record.id })
    },
  }
})

</script>