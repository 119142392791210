<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <!-- 食谱的基础信息部分 -->
          <h5>食谱定制相关信息</h5>
          <form-create
            v-model="cformFoodApi"
            :rule="cformFoodRule"
            :option="cformOption"
          >
          </form-create>
        </div>
      </a-card>

      <a-card
        :bordered="false"
        class="mt-20"
      >
        <div class="mx-25">
          <h5>一日食谱配置
            <div class="float-right mr-15 mt--5">
              <a-button
                icon="plus"
                type="primary"
                @click="addOneDay"
              >增加一天</a-button>
            </div>
          </h5>
          <div v-if="chooseFoodArr.length > 0">
            <a-collapse
              :bordered="false"
              accordion
            >
              <a-collapse-panel
                v-for="(fdv,fdi) in chooseFoodArr"
                :key="fdi"
              >
                <span
                  slot="header"
                  class="font-bold"
                >{{ fdv.title }}</span>
                <div
                  slot="extra"
                  class="mt--5"
                >
                  <a-button
                    icon="plus"
                    size="small"
                    @click="(e)=>addOneDayMenu(e,fdi)"
                  >选择一日食谱</a-button>
                </div>
                <div
                  v-for="(etype,ti) in eatingTypeArr"
                  :key="fdi+'-'+ti"
                >
                  <h6>
                    {{ etype.label }}
                    <div
                      class="float-right right-icon-text"
                      @click="clickShowMenu('et_'+fdi+'_'+etype.value)"
                    >
                      <a-icon
                        v-if="cuShowMenuTable === 'et_'+fdi+'_'+etype.value"
                        type="minus-circle"
                      />
                      <a-icon
                        v-else
                        type="plus-circle"
                      />
                      菜单选择
                    </div>
                  </h6>
                  <div>
                    <div>
                      <div v-if="fdv['et_'+fdi+'_'+etype.value] !== undefined && fdv['et_'+fdi+'_'+etype.value].length > 0">
                        <a-table
                          :columns="showRecipeColumns"
                          :data-source="fdv['et_'+fdi+'_'+etype.value]"
                          size="middle"
                          :pagination="false"
                          rowKey="id"
                          class="mb-20"
                        >
                          <span
                            slot="action"
                            slot-scope="text, record,idx"
                          >
                            <template>
                              <a @click="clickViewRecipe(record)">详情</a>
                              <a-divider type="vertical" />
                              <a
                                class="font-error"
                                @click="clickRemoveRecipe(fdi,'et_'+fdi+'_'+etype.value,idx)"
                              >删除</a>
                            </template>
                          </span>
                        </a-table>
                      </div>
                      <p
                        v-else
                        class="font-error"
                      >暂无数据... 请在可选菜单列表中选择菜单食谱</p>
                    </div>
                    <div v-if="cuShowMenuTable === 'et_'+fdi+'_'+etype.value">
                      <a-collapse
                        class="inner-collapse"
                        :activeKey="fdi+etype.value"
                      >
                        <a-collapse-panel
                          :key="fdi+etype.value"
                          header="可选菜单列表"
                        >
                          <food-recipe-list
                            :sourceMark="'choose'"
                            :arrIdx="{idx:fdi,type:'et_'+fdi+'_'+etype.value}"
                            @sureChooseRecipe="sureChooseRecipe"
                          />
                        </a-collapse-panel>
                      </a-collapse>
                    </div>
                  </div>
                  <a-divider class="mb-15" />
                </div>
              </a-collapse-panel>
            </a-collapse>

            <a-row class="mt-20">
              <a-col
                :offset="11"
                :span="12"
              >
                <a-button
                  icon="check"
                  @click="clickPatMenuSave"
                  type="primary"
                >确认保存</a-button>
              </a-col>
            </a-row>
          </div>

          <a-empty
            v-else
            class="mb-30"
            description="暂无已选择食谱数据"
          />
        </div>
      </a-card>
      <!-- 食谱详情弹窗组件 -->
      <food-recipe-detail-modal
        :recipeDetail="recipeDetail"
        :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
        @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
      />

      <!-- 一日食谱的弹窗列表 -->
      <a-modal
        :visible="dailyFoodVisible"
        title="一日食谱列表"
        :width="1280"
        :maskClosable="false"
        cancelText="关闭"
        okText=""
        @cancel="dailyFoodCancel"
      >
        <daily-food-menu-list
          :sourceMark="'choose'"
          @sureChooseOneDayFood="sureChooseOneDayFood"
        />
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { getPatientDietaryInfo } from '@/api/dms/patient'
import { savePatientMenuFoodInfo } from '@/api/dms/patientFoodMenu'
import { FoodRecipeList, FoodRecipeDetailModal } from '@/components/FoodRecipes'
import { DailyFoodMenuList } from '@/components/DailyFoodMenus'
import { rmFile } from '@/api/manage'
import moment from 'moment'

export default {
  name: 'SetPatientFoodMenu',
  components: {
    FoodRecipeList,
    FoodRecipeDetailModal,
    DailyFoodMenuList
  },
  data () {
    return {
      spinning: false,
      showRecipeColumns: [
        {
          title: '序号',
          dataIndex: 'id',
          width: 50,
          customRender: (text, row, idx) => {
            return idx + 1
          }
        }, {
          title: '名称',
          width: 160,
          dataIndex: 'recipe_name',
          ellipsis: true
        },
        {
          title: '显示名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: false,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 50,
          dataIndex: 'eating_count',
          ellipsis: false,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '适合人数',
          width: 70,
          dataIndex: 'person',
          ellipsis: false,
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '操作',
          ellipsis: true,
          width: 90,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      cformFoodApi: {},
      cformFoodRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '食谱名称',
          field: 'menu_name',
          props: { placeholder: '请填写食谱名称' },
          validate: [
            { required: true, message: '请填写食谱名称', whitespace: true }
          ]
        },
        {
          type: 'RangePicker',
          title: '开始结束日期',
          field: 'start_date', style: { width: '100%' },
          value: [moment().format('YYYY-MM-DD'), moment().add(2, 'days').format('YYYY-MM-DD')],
          props: { ranges: { '最近三天': [moment(), moment().add(2, 'days')], '最近一周': [moment(), moment().add(6, 'days')], '最近一月': [moment(), moment().add(1, 'months').add(-1, 'days')] }, valueFormat: 'YYYY-MM-DD' },
          validate: [
            { required: true, message: '请确认选择开始结束日期' }
          ]
        },
        {
          type: 'input',
          title: '食谱介绍',
          field: 'menu_intro',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写食谱介绍' }
        },
        {
          type: 'upload',
          field: 'img_url',
          title: '封面图',
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/food_menu/patient' },
            limit: 1,
            listType: 'picture-card'
          },
        },
        {
          type: 'input',
          title: '不喜欢的食物',
          field: 'food_dislike',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不喜欢的食物' }
        },
        {
          type: 'input',
          title: '过敏信息',
          field: 'allergies',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写过敏信息' }
        },
        {
          type: 'input',
          title: '不耐受的食物',
          field: 'food_intolerant',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写不耐受的食物' }
        },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'input',
          title: '其他备注',
          field: 'remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注' }
        },
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      chooseFoodArr: [],
      recipeDetail: {},
      foodRecipeDetailModalVisible: false,
      dailyFoodVisible: false,
      cuChooseIdx: -1,
      cuShowMenuTable: null,
      eatingTypeArr: [],
      fmenu_img: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthPatientFoodMenus.setPatientFoodMenu'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 饮食习惯  回显过敏食物相关信息
    getPatientDietaryInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.cformFoodApi.setValue({ food_dislike: res.data.food_dislike, allergies: res.data.allergies_remarks, food_intolerant: res.data.food_intolerant_remarks })
      }
      this.cformFoodApi.setValue({ patient_id: this.params.patient })
      this.eatingTypeArr = this.$options.filters.selectOptionsData('health.eating_type')
      this.spinning = false
    })
  },
  methods: {
    moment,
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.fmenu_img = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.fmenu_img }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.cformFoodApi.setValue({ img_url: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    addOneDay () {
      // 增加一天
      this.chooseFoodArr.push({ title: '第 ' + (this.chooseFoodArr.length + 1) + ' 天', dayNum: this.chooseFoodArr.length + 1 })
    },
    addOneDayMenu (e, fdi) {
      e.stopPropagation()
      // 点击增选择一日食谱按钮
      this.cuChooseIdx = fdi
      if (this.chooseFoodArr[fdi] === undefined) {
        this.$message.error('请确认当前日期！')
        return false
      }
      this.dailyFoodVisible = true
    },
    sureChooseRecipe (recipeInfo) {
      // 组件确认加入一个食谱
      if (recipeInfo.arrIdx !== undefined && recipeInfo.recipe !== undefined && recipeInfo.recipe.id !== undefined && recipeInfo.arrIdx.idx !== undefined && recipeInfo.arrIdx.type !== undefined && this.chooseFoodArr[recipeInfo.arrIdx.idx] !== undefined) {
        if (this.chooseFoodArr[recipeInfo.arrIdx.idx][recipeInfo.arrIdx.type] === undefined) {
          this.chooseFoodArr[recipeInfo.arrIdx.idx][recipeInfo.arrIdx.type] = []
        }
        const isCheck = this.chooseFoodArr[recipeInfo.arrIdx.idx][recipeInfo.arrIdx.type].filter((cup) => cup.id === recipeInfo.recipe.id)
        if (isCheck.length > 0) {
          this.$message.error('该菜单菜谱已选择！')
          return false
        } else {
          this.chooseFoodArr[recipeInfo.arrIdx.idx][recipeInfo.arrIdx.type].push(recipeInfo.recipe)
          this.chooseFoodArr = [...this.chooseFoodArr]
        }
      } else {
        this.$message.error('请确认所选菜单菜谱存在可操作！')
        return false
      }
    },
    clickRemoveRecipe (fdi, field, idx) {
      // 删除指定已选的菜谱
      if (this.chooseFoodArr[fdi][field][idx] === undefined) {
        this.$message.error('请确认所选菜谱存在可操作！')
        return false
      }
      this.chooseFoodArr[fdi][field].splice(idx, 1)
    },
    clickViewRecipe (record) {
      // 查看已选食谱详情弹窗
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    foodRecipeDetailModalVisibleChange (v) {
      // 食谱详情弹窗事件监听
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    },
    clickPatMenuSave () {
      this.cformFoodApi.validate((valid2, fail2) => {
        if (valid2) {
          this.spinning = true
          const that = this
          let fmData = this.cformFoodApi.formData()
          fmData.img_url = this.fmenu_img
          fmData.choose_foods = this.chooseFoodArr
          savePatientMenuFoodInfo(fmData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '客户定制食谱保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.fmenu_img = ''
                  that.chooseFoodArr = []
                  that.cformFoodApi.resetFields()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    dailyFoodCancel () {
      this.dailyFoodVisible = false
    },
    sureChooseOneDayFood (v) {
      if (this.chooseFoodArr[this.cuChooseIdx] === undefined) {
        this.$message.error('请确认选择当前日期！')
        return false
      }
      if (v.daily_food_menu_recipe !== undefined && v.daily_food_menu_recipe.length > 0) {
        v.daily_food_menu_recipe.map(fmv => {
          const cuMenu = JSON.parse(JSON.stringify(fmv))
          cuMenu.id = cuMenu.recipe_def_id
          const cuField = 'et_' + this.cuChooseIdx + '_' + fmv.eating_type
          if (this.chooseFoodArr[this.cuChooseIdx][cuField] === undefined) {
            this.chooseFoodArr[this.cuChooseIdx][cuField] = []
          }
          const isCheck = this.chooseFoodArr[this.cuChooseIdx][cuField].filter((cup) => cup.id === cuMenu.id)
          if (isCheck.length < 1) {
            this.chooseFoodArr[this.cuChooseIdx][cuField].push(cuMenu)
          }
        })
        this.chooseFoodArr = [...this.chooseFoodArr]
        this.dailyFoodVisible = false
      } else {
        this.$message.error('该菜谱暂无菜单信息！')
        return false
      }
    },
    clickShowMenu (field) {
      if (this.cuShowMenuTable === field) {
        this.cuShowMenuTable = null
      } else {
        this.cuShowMenuTable = field
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -5px;
}
/deep/ .inner-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.right-icon-text {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
</style>