import request from '@/utils/request'

const apiUrls = {
  savePatientMonitorRecordInfo: '/HealthMonitorRecords/savePatientMonitorRecordInfo',
  getPatientMonitorRecordInfo: '/HealthMonitorRecords/getPatientMonitorRecordInfo'
}

export function savePatientMonitorRecordInfo (data) {
  return request({
    url: apiUrls.savePatientMonitorRecordInfo,
    method: 'post',
    data
  })
}

export function getPatientMonitorRecordInfo (parameter) {
  return request({
      url: apiUrls.getPatientMonitorRecordInfo,
      method: 'get',
      params: parameter
  })
}
