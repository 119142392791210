import request from '@/utils/request'

const apiUrls = {
  listLogs: '/Logs/listLogs',
  listApiRequestLogs: '/ApiRequestLogs/listApiRequestLogs'
}

export function listLogs (parameter) {
  return request({
    url: apiUrls.listLogs,
    method: 'get',
    params: parameter
  })
}

export function listApiRequestLogs (parameter) {
  return request({
    url: apiUrls.listApiRequestLogs,
    method: 'get',
    params: parameter
  })
}
