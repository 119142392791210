<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div class="pageNavBox">
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
            <div class="pageNavRight">
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存排班</a-button>
            </div>
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveScheduleRequest"
          ></form-create>

          <div class="calendarBox">
            <a-divider style="margin-top: 16px;" />
            <div class="title-font mb-25">
              排班日期列表
              <a-button
                v-if="clickSetScheduleTimes > 0"
                class="float-right"
                @click="addBtnClick"
                type="primary"
              >新增排班日期</a-button>
            </div>
            <a-table
              ref="ScheduleListTable"
              bordered
              :columns="columns"
              :dataSource="schListData"
              :scroll="tableScroll"
            >
              <span
                slot="action"
                slot-scope="text, record,idx"
              >
                <template>
                  <a @click="clickEdit(record,idx)">编辑</a>
                  <a-divider type="vertical" />
                  <a
                    @click="clickRemove(idx)"
                    class="font-error"
                  >删除</a>
                </template>
              </span>
            </a-table>
          </div>

          <div class="calendarBox">
            <a-divider style="margin-top: 16px;" />
            <div class="title-font mb-25">排班日历预览</div>
            <FullCalendar
              ref="calenderObj"
              :options="calendarOptions"
            />
          </div>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存排班</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>

    <a-modal
      v-model="modalVisible1"
      title="排班编辑"
      okText="确认保存"
      :maskClosable="false"
      :okButtonProps="okButtonProps1"
      @ok="handleModalOk1"
    >
      <a-spin
        tip="处理中..."
        :spinning="spinningM1"
      >
        <form-create
          v-model="cformApiM1"
          :rule="cformRuleM1"
          :option="cformOptionM1"
        ></form-create>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { getScheduleDefInfo } from '@/api/dms/scheduleDef'
import { addSchedules } from '@/api/dms/schedule'
// FullCalendar 相关组件
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'

export default {
  name: 'SetSchedules',
  components: {
    FullCalendar
  },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      cformApiM1: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'enterprise_id'
        },
        {
          type: 'hidden',
          field: 'dep_id'
        },
        {
          type: 'hidden',
          field: 'service_user_id'
        },
        {
          type: 'RangePicker',
          title: '排班日期范围',
          field: 'datetime_range', style: { width: '100%' },
          value: [moment().format('YYYY-MM-DD'), moment().add(1, 'months').format('YYYY-MM-DD')],
          props: { ranges: { '最近一月': [moment(), moment().add(1, 'months')], '最近三月': [moment(), moment().add(3, 'months')], '本年': [moment(), moment().endOf('year')] }, valueFormat: 'YYYY-MM-DD' },
          validate: [
            { required: true, message: '请确认选择排班日期范围' }
          ]
        },
        // {
        //   type: 'input',
        //   title: '可预约数量',
        //   field: 'available_count', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写可预约数量', min: 0, step: 1, max: 127, addonAfter: '个' },
        //   validate: [
        //     { required: true, message: '请填写可预约数量', whitespace: true }
        //   ]
        // },
        {
          type: 'input',
          title: '加号数量',
          field: 'add_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写加号数量', min: 0, step: 1, max: 127, addonAfter: '个' },
        },
        // {
        //   type: 'select',
        //   title: '排班年份',
        //   field: 'year',
        //   props: { placeholder: '请选择排班年份' },
        //   options: [],
        //   validate: [
        //     { required: true, message: '请确认选择排班年份' }
        //   ]
        // },
        {
          type: 'a-button',
          props: {
            type: 'danger',
            icon: 'ordered-list'
          },
          class: 'setBtn',
          children: ['生成排班列表'],
          on: {
            click: () => this.clickSetSchedules()
          }
        }
      ],
      cformRuleM1: [
        {
          type: 'hidden',
          field: 'key'
        },
        {
          type: 'DatePicker',
          title: '排班日期',
          field: 'service_date', style: { width: '100%' },
          props: { placeholder: '请选择排班日期', type: 'date', allowClear: true },
          validate: [
            { required: true, message: '请选择排班日期' }
          ]
        },
        {
          type: 'input',
          title: '可预约数量',
          field: 'available_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写可预约数量', min: 0, step: 1, max: 127, addonAfter: '个' },
          validate: [
            { required: true, message: '请填写可预约数量', whitespace: true }
          ]
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      cformOptionM1: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      scheduleDef: {},
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],//要使用的组件需要先引入
        locale: 'zh-cn',
        allDayText: '全天',
        contentHeight: 'auto',
        initialView: 'dayGridMonth',
        selectable: true,
        initialDate: moment().format('YYYY-MM-DD'),
        // editable: true,
        headerToolbar: {//设置头部工具栏显示的按钮
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        },
        buttonText: {//设置头部工具栏的按钮文本
          today: '今天',
          dayGridMonth: '月',
          timeGridWeek: '周',
          timeGridDay: '天',
          list: '日程'
        },
        dayMaxEvents: 2,//设置日期视图中显示的事件个数，
        events: {
          // 统一设置背景色，边框，字体，在下面还可以单独设置
          backgroundColor: '#ffffff1a',
          textColor: '#141414',
          borderColor: '#ff0000',
          events: []
        }
      },
      calendarApi: null,
      schListData: [],
      columns: [
        {
          title: '序号',
          width: 100,
          align: 'center',
          dataIndex: 'key',
          customRender: (text, row, idx) => {
            return idx + 1
          }
        },
        {
          title: '排班日期',
          // width: 100,
          dataIndex: 'service_date',
          ellipsis: true
        },
        {
          title: '可约数量',
          // width: 100,
          dataIndex: 'available_count',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          // width: 95,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      spinningM1: false,
      modalVisible1: false,
      okButtonProps1: {},
      cuChooseIdx: 0,
      schListLastKey: 0,
      clickSetScheduleTimes: 0
    }
  },
  mounted () {
    this.calendarApi = this.$refs.calenderObj.getApi()
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthServiceScheduleDefs.setSchedules'))
    if (this.params === null || this.params.def === undefined || this.params.def < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getScheduleDefInfo({ schedule: this.params.def }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.def) {
        const cuYear = moment().format('YYYY')
        const nextYear = moment().add(1, 'years').format('YYYY')
        const yearArr = [{ value: cuYear, label: cuYear + '年' }, { value: nextYear, label: nextYear + '年' }]
        this.cformApi.setValue({ enterprise_id: res.data.enterprise_id, dep_id: res.data.dep_id, service_user_id: res.data.service_user_id })
        this.cformApi.updateRules({ // 更新规则
          'year': { options: yearArr }
        })
        this.scheduleDef = res.data
        this.spinning = false
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveScheduleRequest (data, formObj) {
      if (this.calendarOptions.events.events.length < 1) {
        this.$message.error('请确认生成排班列表且存在排班数据！')
        return false
      }
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      data.dateArr = this.schListData
      addSchedules(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '排班数据保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    clickSetSchedules () {
      const cuFormData = this.cformApi.formData()
      if (cuFormData.datetime_range === undefined || cuFormData.datetime_range.length !== 2) {
        this.$message.error('请确认先择排班日期范围！')
        return false
      }
      const scheduleRule = JSON.parse(this.scheduleDef.schedule_rule)
      let allowArr = []
      if (this.scheduleDef.rule_type === 1) {
        // 周
        if (scheduleRule.week !== undefined && scheduleRule.week.length !== undefined && scheduleRule.week.length > 0) {
          allowArr = scheduleRule.week
        }
      } else if (this.scheduleDef.rule_type === 2) {
        // 月
        if (scheduleRule.month !== undefined && scheduleRule.month.length !== undefined && scheduleRule.month.length > 0) {
          allowArr = scheduleRule.month
        }
      } else {
        // 自定义时间
        if (scheduleRule.customDay !== undefined && scheduleRule.customDay.length !== undefined && scheduleRule.customDay.length > 0) {
          allowArr = scheduleRule.customDay
        }
      }
      this.clickSetScheduleTimes++
      this.calcDateShow(cuFormData.year, cuFormData.available_count, this.scheduleDef.rule_type, allowArr)
    },
    calcDateShow (startYear, availableCount, type, allowArr) {
      // 当前年份从当天开始计算，下一年份从1开始
      // let startDate = startYear === moment().format('YYYY') ? Math.ceil((new Date() - new Date(new Date().getFullYear().toString())) / (24 * 60 * 60 * 1000)) + 1 : 1
      let startDate = startYear === moment().format('YYYY') ? moment().format('YYYY-MM-DD') : startYear + '-01-01'
      let whileMark = true
      let addDayNum = 0
      const calendarDateArr = []
      this.schListData = []
      this.schListLastKey = 0
      if (allowArr.length < 1) {
        return false
      }
      while (whileMark) {
        const dateStr = moment(startDate).add(addDayNum, 'days').format('YYYY-MM-DD')
        const dateArr = dateStr.split('-')
        if (dateArr[0] > startYear) {
          // 大于选择年份则跳出循环
          whileMark = false
          break
        }
        let cuCond = dateStr
        if (type === 1) { // 按周，获取时间是星期几 1-7
          cuCond = moment(dateStr).isoWeekday()
        }
        if (type === 2) {// 按月是获取当前号数
          cuCond = parseInt(dateArr[2])
        }
        if (allowArr.indexOf(cuCond) >= 0) {
          this.schListLastKey += 1
          calendarDateArr.push({
            id: this.schListLastKey,
            title: '可约：' + availableCount,
            start: dateStr,
            end: dateStr,
            display: 'list-item'
          })
          this.schListData.push({
            key: this.schListLastKey,
            available_count: availableCount,
            service_date: dateStr
          })
        }

        addDayNum++
      }
      this.calendarOptions.events.events = calendarDateArr
      this.calendarApi.gotoDate(startDate)
    },
    clickEdit (row, idx) {
      this.modalVisible1 = true
      this.$nextTick(() => {
        this.cuChooseIdx = idx
        this.cformApiM1.setValue(row)
      })
    },
    handleModalOk1 () {
      // if (this.schListData[this.cuChooseIdx] === undefined) {
      //   this.$message.error('请确认排班日期存在！')
      //   return false
      // }
      // 检测时间是否重复
      const cuFormData = this.cformApiM1.formData()
      const schListDataFilter = this.schListData.filter((lv) => {
        return lv.key !== cuFormData.key && lv.service_date === cuFormData.service_date
      })
      if (schListDataFilter.length > 0) {
        this.$message.error('排班日期：' + cuFormData.service_date + ' 已存在！')
        return false
      } else {
        this.calendarOptions.events.events[this.cuChooseIdx] = {
          title: '可约：' + cuFormData.available_count,
          start: cuFormData.service_date,
          end: cuFormData.service_date,
          display: 'list-item'
        }
        this.calendarOptions.events.events = [...this.calendarOptions.events.events]
        // if (this.calendarOptions.events.events[this.cuChooseIdx] === undefined) {
        //   this.calendarApi.addEvent({
        //     id: cuFormData.key,
        //     title: '可约：' + cuFormData.available_count,
        //     start: cuFormData.service_date,
        //     end: cuFormData.service_date,
        //     display: 'list-item'
        //   })
        // } else {
        //   const cuEvent = this.calendarApi.getEventById(cuFormData.key)
        //   cuEvent.setProp('title', '可约：' + cuFormData.available_count)
        //   // cuEvent.setStart(cuFormData.service_date)
        //   // cuEvent.setEnd(cuFormData.service_date)
        // }
        this.schListData[this.cuChooseIdx] = cuFormData
        this.schListData = [...this.schListData]
        this.cformApiM1.resetFields()
        this.modalVisible1 = false
        this.$message.success('排班日期：' + cuFormData.service_date + ' 操作成功！')
        this.sortListData()
        return true
      }
    },
    addBtnClick () {
      const cuFormData = this.cformApi.formData()
      if (cuFormData.year === undefined || cuFormData.available_count === undefined) {
        this.$message.error('请确认先填写可预约数量和选择排班年份！')
        return false
      }
      this.modalVisible1 = true
      this.$nextTick(() => {
        this.schListLastKey += 1
        this.cuChooseIdx = this.schListData.length
        this.cformApiM1.setValue({
          key: this.schListLastKey,
          available_count: cuFormData.available_count,
          service_date: undefined
        })
      })
    },
    sortListData () {
      // 按时间排序，a-b小于0，a在前b在后
      this.schListData.sort((a, b) => {
        return a.service_date > b.service_date ? 1 : (a.service_date === b.service_date ? 0 : -1)
      })
    },
    clickRemove (idx) {
      this.schListData.splice(idx, 1)
      this.calendarOptions.events.events.splice(idx, 1)
    }
  }
}

</script>

<style lang="scss" scoped>
/deep/ .setBtn {
  margin-left: 12.5%;
  margin-top: 5px;
}
.calendarBox {
  padding: 0 25px;
}
.pageNavBox {
  position: relative;
}
.pageNavRight {
  position: absolute;
  right: 0;
  top: -10px;
}
</style>