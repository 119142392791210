<template>
  <div id="settings">
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <div class="step-box">
        <div class="mb-20">
          <!-- 跳过第一步为true，开发完要改回false哦！！！！！！ -->
          <a-button
            class="float-right mr-20"
            icon="copy"
            size="small"
            type="danger"
            @click="loadStep1OriInfo(true)"
          >回显最近报告数据</a-button>
          <a-descriptions
            title="当前客户"
            v-if="patientInfo.id !== undefined"
          >
            <a-descriptions-item label="客户姓名">{{ patientInfo.name }}</a-descriptions-item>
            <a-descriptions-item label="性别">{{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}</a-descriptions-item>
            <a-descriptions-item label="出生日期">{{ patientInfo.birth_date }}</a-descriptions-item>

            <a-descriptions-item label="编号">{{ patientInfo.patient_no }}</a-descriptions-item>
            <a-descriptions-item label="联系电话">{{ patientInfo.contact }}</a-descriptions-item>
            <a-descriptions-item label="身份证号">{{ patientInfo.id_card }}</a-descriptions-item>

            <a-descriptions-item
              label="关注问题"
              :span="3"
            >
              <a-tag
                color="orange"
                v-for="tag in patientInfo.his_patient_tag"
                :key="tag.tag_id"
              >
                {{tag.tag_text}}
              </a-tag>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <a-steps
          :current="currentStep"
          @change="onStepChange"
        >
          <a-step
            title="客户基本信息"
            description="录入客户体格指标、期望目标等基本信息。"
          />
          <a-step
            title="问诊信息"
            description="录入客户相关问诊内容和营养方案等信息"
          />
          <a-step
            title="随访与监测"
            description="与客户定制随访计划以及相关监测指标"
          />
        </a-steps>
      </div>

      <a-row
        type="flex"
        :gutter="[24,24]"
      >
        <!-- 左侧目录框 -->
        <a-col
          :span="24"
          :lg="5"
        >
          <a-affix :offset-top=" navbarFixed ? 100 : 10 ">
            <a-card
              :bordered="false"
              class="header-solid mb-24"
            >
              <a-anchor
                :targetOffset=" navbarFixed ? 100 : 10 "
                :affix="false"
              >
                <a-anchor-link
                  v-for="(aav, aaidx) in anchorArr"
                  :key="aaidx"
                  :href="aav.hrefId"
                >
                  <div
                    slot="title"
                    class="ant-list-item-meta"
                  >
                    <a-icon
                      :type="aav.icon"
                      class="text-gray-6 text-lg"
                    />
                    <h4 class="ant-list-item-meta-title">
                      <span class="font-regular">{{ aav.title }}</span>
                    </h4>
                  </div>
                </a-anchor-link>

              </a-anchor>
            </a-card>
          </a-affix>
        </a-col>

        <a-col
          :span="24"
          :lg="19"
          v-if="showStep1"
        >
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <h6
              id="body-info"
              class="font-semibold mb-20"
            >体格指标信息
              <a-button
                class="float-right mr-40"
                icon="user"
                size="small"
                type="danger"
                @click="loadStep1NewestBodyInfo"
              >获取最新体格指标数据</a-button>
            </h6>
            <div>
              <form-create
                id="bydid"
                v-model="cformBodyApi"
                :rule="cformBodyRule"
                :option="cformOption"
              ></form-create>
              <a-divider style="margin-top: 16px;" />
            </div>

            <h6
              id="appoint-info"
              class="font-semibold"
            >预约目标及期望</h6>
            <div>
              <form-create
                v-model="cformAppointApi"
                :rule="cformAppointRule"
                :option="cformAppointOption"
              >
              </form-create>

              <a-row>
                <a-col
                  :offset="10"
                  :span="12"
                >
                  <a-button
                    icon="check"
                    @click="clickStep1Save"
                    type="primary"
                  >确认保存</a-button>

                  <a-button
                    icon="arrow-right"
                    @click="onStepChange(false)"
                    type="primary"
                    class="ml-10"
                  >下一步</a-button>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>

        <a-col
          :span="24"
          :lg="19"
          v-if="showStep2"
        >
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <h6
              id="medical-info"
              class="font-semibold mb-20"
            >病史及用药信息
            </h6>
            <div>
              <form-create
                v-model="cformMedicalApi"
                :rule="cformMedicalRule"
                :option="cformAppointOption"
              >
              </form-create>
              <a-divider style="margin-top: 16px;" />
            </div>

            <h6
              id="nutrition-info"
              class="font-semibold"
            >营养处方</h6>
            <div>
              <form-create
                v-model="cformNutritionApi"
                :rule="cformNutritionRule"
                :option="cformOption"
              >
              </form-create>

              <a-row>
                <a-col
                  :offset="7"
                  :span="12"
                >
                  <a-button
                    icon="plus"
                    type="danger"
                    ghost
                    @click="clickAddSport"
                  >
                    继续添加运动内容
                  </a-button>

                  <a-button
                    icon="check"
                    @click="clickStep2Save"
                    type="primary"
                    class="mx-10"
                  >确认保存</a-button>

                  <a-button
                    icon="arrow-right"
                    @click="onStepChange(false)"
                    type="primary"
                  >下一步</a-button>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>

        <a-col
          :span="24"
          :lg="19"
          v-if="showStep3"
        >
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <h6
              id="followup-info"
              class="font-semibold mb-20"
            >随访计划
            </h6>
            <div>
              <form-create
                v-model="cformFollowupApi"
                :rule="cformFollowupRule"
                :option="cformOption"
              >
              </form-create>
              <a-divider style="margin-top: 16px;" />
            </div>

            <h6
              id="monitor-info"
              class="font-semibold"
            >指标检测计划</h6>
            <div>
              <form-create
                v-model="cformMonitorApi"
                :rule="cformMonitorRule"
                :option="cformAppointOption"
              >
              </form-create>

              <a-row>
                <a-col
                  :offset="10"
                  :span="12"
                >
                  <a-button
                    icon="check"
                    @click="clickStep3Save"
                    type="primary"
                  >确认保存</a-button>

                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getPatientInfo, getPatientBodyInfoByServiceRecord, getPatientNewestBodyInfo, savePatientMedicalInfo, savePatientNutritionInfo } from '@/api/dms/patient'
import { savePatientServiceRecord, getServiceRecordInfo } from '@/api/dms/serviceRecord'
import { getNewestNotDealAppointmentInfo } from '@/api/dms/clientAppointment'
import { getPatientAppointInfo } from '@/api/dms/patient'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { getAndRenderBodyItemFormData } from '@/utils/bodyItemCalc'

export default {
  name: 'SetServiceRecord',
  props: ['navbarFixed'],
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformBodyApi: {},
      cformAppointApi: {},
      cformMedicalApi: {},
      cformNutritionApi: {},
      cformFollowupApi: {},
      cformMonitorApi: {},
      //表单生成规则
      cformBodyRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'patient_id'
        }
      ],
      cformAppointRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'client_appoint_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'input',
          title: '预约原因',
          field: 'reason',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写预约原因' },
          validate: [
            { required: true, message: '请填写预约原因', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '期望达到效果',
          field: 'expectation',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写期望达到效果' },
          validate: [
            { required: true, message: '请填写期望达到效果', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '目标',
          field: 'goal',
          props: { placeholder: '请选择目标' },
          options: [],
          validate: [
            { required: true, message: '请选择目标' }
          ]
        },
        {
          type: 'input',
          title: '目标备注',
          field: 'goal_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写目标备注' }
        },
        {
          type: 'input',
          title: '其他说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他说明' }
        },
      ],
      cformMedicalRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        // {
        //   type: 'hidden',
        //   field: 'patient_id'
        // },
        // {
        //   type: 'hidden',
        //   field: 'enterprise_id',
        //   value: this.$store.getters.userInfo.user.target_enterprise
        // },
        // {
        //   type: 'hidden',
        //   field: 'create_user',
        //   value: this.$store.getters.userInfo.user.id
        // },
        {
          type: 'select',
          title: '疾病信息',
          field: 'disease',
          props: { mode: 'multiple', placeholder: '请选择疾病信息', maxTagCount: 5, maxTagTextLength: 10, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择疾病信息' }
          ]
        },
        {
          type: 'input',
          title: '其他疾病及备注',
          field: 'disease_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他疾病信息及备注' }
        },
        {
          type: 'input',
          title: '现病史及服药信息',
          field: 'medication',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写现病史及服药信息' }
        },
        {
          type: 'input',
          title: '既往病史',
          field: 'personal_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写既往病史' }
        },
        {
          type: 'input',
          title: '家族病史',
          field: 'family_history',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写家族病史' }
        },
        {
          type: 'input',
          title: '其他备注说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注说明' }
        }
      ],
      cformNutritionRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '营养评定',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养评定' },
          validate: [
            { required: true, message: '请填写营养评定', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '干预方法',
          field: 'intervention_method',
          props: { placeholder: '请选择干预方法' },
          options: [],
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          validate: [
            { required: true, message: '请选择干预方法' }
          ]
        },
        {
          type: 'select',
          title: '饮食模式推荐',
          field: 'dietary_pattern_id',
          props: { placeholder: '请选饮食模式推荐' },
          options: [],
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          }
        },
        {
          type: 'input',
          title: '推荐方案',
          field: 'recommendation',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写推荐方案' }
        },
        {
          type: 'input',
          title: '热量摄入建议',
          field: 'calorie', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写热量摄入建议', min: 0, step: 1, max: 1000000, addonAfter: 'Kcal' }
        },
        {
          type: 'input',
          title: '碳水摄入建议',
          field: 'carbohydrates', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写碳水摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '蛋白质摄入',
          field: 'protein', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '脂肪摄入建议',
          field: 'fat', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['一日食谱制定']
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'input',
          title: '早餐',
          field: 'breakfast',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早餐' }
        },
        {
          type: 'input',
          title: '早加餐',
          field: 'snack_morning',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早加餐' }
        },
        {
          type: 'input',
          title: '午餐',
          field: 'lunch',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午餐' }
        },
        {
          type: 'input',
          title: '午加餐',
          field: 'snack_afternoon',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午加餐' }
        },
        {
          type: 'input',
          title: '晚餐',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'supper', autoSize: { minRows: 2 }, placeholder: '请填写晚餐' }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['生活干预、运动计划']
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'input',
          title: '生活干预',
          field: 'life_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写生活干预' }
        },
        {
          type: 'input',
          title: '运动计划',
          field: 'sport_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动计划' }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['运动内容推荐']
        },

      ],
      cformFollowupRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        // {
        //   type: 'hidden',
        //   field: 'patient_id'
        // },
        // {
        //   type: 'hidden',
        //   field: 'prescription_no'
        // },
        {
          type: 'select',
          title: '随访方式',
          field: 'method',
          props: { placeholder: '请选择随访方式' },
          options: [],
          validate: [
            { required: true, message: '请选择随访方式' }
          ]
        },
        {
          type: 'input',
          title: '总次数',
          field: 'all_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写随访总次数', min: 0, step: 1, max: 1000, addonAfter: '次' },
          validate: [
            { required: true, message: '请填写随访总次数' }
          ]
        },
        {
          type: 'input',
          title: '随访周期',
          field: 'cycle',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写随访周期' },
          validate: [
            { required: true, message: '请填写随访周期', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写备注' }
        }
      ],
      cformMonitorRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '发现的问题',
          field: 'problem',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写发现的问题' }
        },
        {
          type: 'input',
          title: '解决方案',
          field: 'solution',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写解决方案' }
        },
        {
          type: 'a-radio-group',
          title: '是否有不良反应',
          value: '0',
          field: 'is_reaction',
          props: {
            size: "small", buttonStyle: 'solid', placeholder: '请选择是否有不良反应'
          },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['无']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['有']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '执行程度及调整',
          field: 'content',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写执行程度及调整' }
        }
      ],
      sportFormRuleNum: 1,
      sportFormRuleLastField: '',
      sportFormRuleTmpArr: [
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'select',
          title: '运动方式',
          field: 'sport_type',
          props: { placeholder: '请选择运动方式' },
          options: []
        },
        {
          type: 'input',
          title: '运动时间',
          field: 'duration',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动时间' }
        },
        {
          type: 'input',
          title: '运动频率',
          field: 'frequency',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动频率' }
        },
        {
          type: 'input',
          title: '运动举例',
          field: 'example',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动举例' }
        },
        {
          type: 'input',
          title: '运动方案',
          field: 'plan_content',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动方案' }
        },
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      cformAppointOption: { // 一列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 20 }
        },
        submitBtn: false
      },
      showStep1: true,
      showStep2: true,
      showStep3: true,
      currentStep: 0,
      patientInfo: {},
      anchorArr: [],
      anchorArrStep1: [
        { title: '体格指标信息', hrefId: '#body-info', icon: 'user' },
        { title: '预约目标及期望', hrefId: '#appoint-info', icon: 'solution' },
      ],
      anchorArrStep2: [
        { title: '病史及用药信息', hrefId: '#medical-info', icon: 'fork' },
        { title: '营养处方', hrefId: '#nutrition-info', icon: 'form' },
      ],
      anchorArrStep3: [
        { title: '随访计划', hrefId: '#followup-info', icon: 'solution' },
        { title: '指标检测计划', hrefId: '#monitor-info', icon: 'heart' },
      ],
      recordOri: {}
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.setServiceRecord'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.anchorArr = this.anchorArrStep1
    this.spinning = true
    // 获取客户基本信息数据
    this.loadPatientInfo()
    // 获取渲染客户体格指标字段
    getAndRenderBodyItemFormData(this)
    // 获取是否有客户的期望目标
    this.loadStep1AppointInfo()
    // this.loadStep1OriInfo()
  },
  methods: {
    loadPatientInfo () {
      // 客户是否有预约信息最新的未处理预约记录
      getNewestNotDealAppointmentInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, dep: this.$store.getters.userInfo.user.department_id, user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.cformAppointApi.setValue({ client_appoint_id: res.data.id })
        }
      })
      // 获取饮食模式下拉数据
      selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.cformNutritionApi.updateRules({ // 更新规则
            'dietary_pattern_id': { options: res.data }
          })
          this.renderSportFormData()
        }
      })
      // 客户基础信息
      getPatientInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        this.cformAppointApi.updateRules({ // 更新规则
          'goal': { options: this.$options.filters.selectOptionsData('patient.appoint_goal') }
        })
        // this.cformMedicalApi.setValue({ patient_id: this.params.patient })
        this.cformMedicalApi.updateRules({ // 更新规则
          'disease': { options: this.$options.filters.selectOptionsData('patient.disease') }
        })
        this.cformNutritionApi.updateRules({ // 更新规则
          'intervention_method': { options: this.$options.filters.selectOptionsData('prescription_nutrition.intervention_method') },
          // 'sport_type': { options: this.$options.filters.selectOptionsData('patient.activity_type') }
        })
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient) {
          this.patientInfo = res.data
          this.spinning = false
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
        this.loadShowStep()
      }).catch((e) => {
        console.log(e)
        return this.$router.go(-1)
      })
    },
    loadShowStep () {
      // if (this.currentStep === 0) {
      //   this.showStep1 = true
      //   this.showStep2 = false
      //   this.showStep3 = false
      // }
      // if (this.currentStep === 1) {
      //   this.showStep1 = false
      //   this.showStep2 = true
      //   this.showStep3 = false
      // }
      // if (this.currentStep === 2) {
      //   this.showStep1 = false
      //   this.showStep2 = false
      //   this.showStep3 = true
      // }
    },
    loadStep1OriInfo (setValMark = false) {
      this.spinning = true
      // 获取主记录数据  回显按钮触发
      getServiceRecordInfo({ patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.recordOri = res.data
          if (setValMark) {
            // 点击按钮回显的时候不需要设置service_record_id值，保存成功后的加载才设置service_record_id
            this.cformAppointApi.setValue({ service_record_id: res.data.id })
            // todo第二步的
          }
          // 数据获取成功则加载相关的体格数据
          this.loadStep1MoreInfo()
        } else {
          this.$message.error(res.msg || '获取原始信息失败！请刷新后重试！')
        }
      })
    },
    loadStep1MoreInfo () {
      // 根据主记录id获取体格信息的时候走历史表，手动获取最新体格数据的时候走检测表
      getPatientBodyInfoByServiceRecord({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1) {
          const bodyFormField = {}
          res2.data.map(rv => {
            const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.item_def_id + '@@' + rv.item_unit_name
            bodyFormField[fieldName] = rv.result_val
          })
          this.cformBodyApi.setValue(bodyFormField)
        }
        this.spinning = false
      })
    },
    loadStep1NewestBodyInfo () {
      this.spinning = true
      // 根据主记录id获取体格信息的时候走历史表，手动获取最新体格数据的时候走检测表
      getPatientNewestBodyInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1) {
          const bodyFormField = {}
          res2.data.map(rv => {
            const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.item_def_id + '@@' + rv.item_unit_name
            bodyFormField[fieldName] = rv.result_val
          })
          this.cformBodyApi.setValue(bodyFormField)
        }
        this.spinning = false
      })
    },
    loadStep1AppointInfo () {
      // 加载目标期望数据
      getPatientAppointInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformAppointApi.setValue({ id: res.data.id, reason: res.data.reason, expectation: res.data.expectation, goal: res.data.goal.toString(), goal_remarks: res.data.goal_remarks, other_remarks: res.data.other_remarks })
        }
      })
    },
    onStepChange (current = false) {
      const that = this
      // 传入current则是上面步骤条的点击change事件触发的，直接设置对应值，没有传入的时候则是下面下一步按钮触发的直接+1
      if (this.currentStep === 0) {
        // 第一跳第二检测是否有主记录id
        if (this.recordOri.id === undefined || this.recordOri.id < 1) {
          this.$message.error('请先确认保存客户基本信息！')
          return false
        }
        if (current === false || current === 1) {
          this.anchorArr = this.anchorArrStep2
        }
        // 第二到第三步
        if (current === 2) {
          this.anchorArr = this.anchorArrStep3
        }
      }
      if (this.currentStep === 1) {
        // 第二回第一步
        if (current === 0) {
          this.anchorArr = this.anchorArrStep1
        }
        // 第二到第三步
        if (current === false || current === 2) {
          this.anchorArr = this.anchorArrStep3
        }
      }
      if (this.currentStep === 2) {
        // 第三回第一步
        if (current === 0) {
          this.anchorArr = this.anchorArrStep1
        }
        // 第三到第二步
        if (current === 1) {
          this.anchorArr = this.anchorArrStep2
        }
      }
      this.currentStep = current === false ? this.currentStep + 1 : current
      this.loadShowStep()
    },
    clickStep1Save () {
      this.cformBodyApi.validate((valid, fail) => {
        if (valid) {
          this.cformAppointApi.validate((valid2, fail2) => {
            if (valid2) {
              this.spinning = true
              this.tip = '处理中...'
              savePatientServiceRecord(Object.assign({}, this.cformBodyApi.formData(), this.cformAppointApi.formData())).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  this.$message.success('保存成功！')
                  this.tip = '加载中...'
                  this.loadStep1OriInfo(true)
                  this.loadStep1AppointInfo()
                } else {
                  this.$message.error(res.msg || '保存失败！请刷新后重试！')
                  this.spinning = false
                }
              })
            }
          })
        }
      })
    },
    clickStep2Save () {
      console.log(this.cformMedicalApi.formData())
      this.cformMedicalApi.validate((valid, fail) => {
        if (valid) {
          // this.cformNutritionApi.validate((valid2, fail2) => {
          //   if (valid2) {
          //     this.spinning = true
          //     this.tip = '处理中...'
          //     // savePatientServiceRecord(Object.assign({ service_record_id: this.recordOri.id }, this.cformMedicalApi.formData(), this.cformNutritionApi.formData())).then((res) => {
          //     const sameUserArr = { service_record_id: this.recordOri.id, patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id }
          //     savePatientMedicalInfo(Object.assign(sameUserArr, )).then((res) => {
          //       if (res.status !== undefined && res.status === 1) {
          //         // this.$message.success('保存成功！')
          //         // this.tip = '加载中...'
          //         // this.loadStep1OriInfo(true)
          //         // this.loadStep1AppointInfo()
          //       } else {
          //         this.$message.error(res.msg || '保存失败！请刷新后重试！')
          //         this.spinning = false
          //       }
          //     })
          //     // savePatientNutritionInfo(Object.assign(sameUserArr, this.cformNutritionApi.formData())).then((res) => {
          //     //   if (res.status !== undefined && res.status === 1) {
          //     //     // this.$message.success('保存成功！')
          //     //     // this.tip = '加载中...'
          //     //     // this.loadStep1OriInfo(true)
          //     //     // this.loadStep1AppointInfo()
          //     //   } else {
          //     //     this.$message.error(res.msg || '保存失败！请刷新后重试！')
          //     //     this.spinning = false
          //     //   }
          //     // })
          //   }
          // })
        }
      })
    },
    clickStep3Save () {
      console.log(this.cformFollowupApi.formData(), this.cformMonitorApi.formData())
    },
    clickAddSport () {
      // this.renderSportFormData()
    },
    renderSportFormData () {
      this.spinning = true
      let arrNum = 0
      const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
      cuTmpArr.map(spv => {
        if (spv.title !== undefined && spv.field !== undefined) {
          spv.title = spv.title + '#' + this.sportFormRuleNum
          spv.field = spv.field + '#' + this.sportFormRuleNum
        }
        this.cformNutritionApi.append(spv, 'patient_id')
        arrNum++
      })
      const timer = setInterval(() => {
        if (arrNum >= this.sportFormRuleTmpArr.length) {
          // this.sportFormRuleLastField = this.sportFormRuleTmpArr[this.sportFormRuleTmpArr.length - 1].field
          this.sportFormRuleNum++
          // this.cformNutritionApi.refresh()
          clearInterval(timer)
          this.spinning = false
        }
      }, 100)
    }
  }
};
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
  .step-box {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
}
</style>