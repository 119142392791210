<template>
  <a-card
    :bordered="false"
    id="prescription"
    class="header-solid mb-24"
  >
    <template #title>
      <h5 class="mb-0 font-semibold">营养处方</h5>
    </template>

    <a-button
      slot="extra"
      type="primary"
      icon="file-search"
      class="px-10 py-5 "
      @click="clickPrescriptionBtn"
    >查看历史处方</a-button>

    <form-create
      v-model="cformNutritionApi"
      :rule="cformNutritionRule"
      :option="cformOption"
      @submit="saveNutritionRequest"
    >
    </form-create>

    <a-row>
      <a-col
        :offset="11"
        :span="12"
      >
        <a-button
          icon="plus"
          type="danger"
          ghost
          @click="clickAddSport"
        >
          继续添加运动内容
        </a-button>
        <a-button
          @click="cformNutritionApi.submit()"
          type="primary"
          class="ml-10"
        >确认保存</a-button>
      </a-col>
    </a-row>

    <!-- 饮食模式的抽屉窗口 -->
    <dietary-pattern-detail
      :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
      :parentParams="dietaryPatternParams"
      @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
    />
  </a-card>
</template>

<script>
import { savePatientNutritionInfo, getPatientNutritionInfo, removePatientPrescriptionSport } from '@/api/dms/patient'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { DietaryPatternDetail } from '@/components/DietaryPattern'

export default ({
  // 客户营养处方信息card
  name: 'Prescription',
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    cformOption: {
      type: Object,
      default: null
    }
  },
  components: { DietaryPatternDetail },
  data () {
    return {
      cformNutritionApi: {},
      cformNutritionRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'input',
          title: '营养评定',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养评定' },
          validate: [
            { required: true, message: '请填写营养评定', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '干预方法',
          field: 'intervention_method',
          props: { placeholder: '请选择干预方法' },
          options: [],
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          validate: [
            { required: true, message: '请选择干预方法' }
          ]
        },
        {
          type: 'select',
          title: '饮食模式推荐',
          field: 'dietary_pattern_id',
          props: { placeholder: '请选饮食模式推荐' },
          options: [],
          col: {
            span: 18
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 19 }
          }
        },
        {
          type: 'a-button',
          class: 'ant-col-5 mt-5',
          name: 'a-button',
          props: { icon: 'file-protect', type: 'primary' },
          children: ['查看饮食模式详情'],
          on: {
            click: () => this.clickshowPattern()
          }
        },
        {
          type: 'input',
          title: '推荐方案',
          field: 'recommendation',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写推荐方案' }
        },
        {
          type: 'input',
          title: '热量摄入建议',
          field: 'calorie', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写热量摄入建议', min: 0, step: 1, max: 1000000, addonAfter: 'Kcal' }
        },
        {
          type: 'input',
          title: '碳水摄入建议',
          field: 'carbohydrates', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写碳水摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '蛋白质摄入',
          field: 'protein', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '脂肪摄入建议',
          field: 'fat', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['一日食谱制定']
        },
        {
          type: 'input',
          title: '早餐',
          field: 'breakfast',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早餐' }
        },
        {
          type: 'input',
          title: '早加餐',
          field: 'snack_morning',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早加餐' }
        },
        {
          type: 'input',
          title: '午餐',
          field: 'lunch',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午餐' }
        },
        {
          type: 'input',
          title: '午加餐',
          field: 'snack_afternoon',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午加餐' }
        },
        {
          type: 'input',
          title: '晚餐',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'supper', autoSize: { minRows: 2 }, placeholder: '请填写晚餐' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['生活干预、运动计划']
        },
        {
          type: 'input',
          title: '生活干预',
          field: 'life_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写生活干预' }
        },
        {
          type: 'input',
          title: '运动计划',
          field: 'sport_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动计划' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['运动内容推荐']
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        // {
        //   type: 'select',
        //   title: '运动方式',
        //   field: 'sport_type',
        //   props: { placeholder: '请选择运动方式' },
        //   options: []
        // },
        // {
        //   type: 'input',
        //   title: '运动时间',
        //   field: 'duration',
        //   props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动时间' }
        // },
        // {
        //   type: 'input',
        //   title: '运动频率',
        //   field: 'frequency',
        //   props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动频率' }
        // },
        // {
        //   type: 'input',
        //   title: '运动举例',
        //   field: 'example',
        //   props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动举例' }
        // },
        // {
        //   type: 'input',
        //   title: '运动方案',
        //   field: 'plan_content',
        //   col: {
        //     span: 24
        //   },
        //   wrap: {
        //     labelCol: { span: 3 },
        //     wrapperCol: { span: 20 }
        //   },
        //   props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动方案' }
        // },
      ],
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
      sportFormRuleNum: 1,
      sportFormRuleNumArr: [],
      sportFormRuleLastField: '',
      sportFormRuleTmpArr: [
        {
          type: 'input',
          title: '运动方案',
          field: 'plan_content',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动方案' }
        },
        {
          type: 'input',
          title: '运动举例',
          field: 'example',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动举例' }
        },
        {
          type: 'input',
          title: '运动频率',
          field: 'frequency',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动频率' }
        },
        {
          type: 'input',
          title: '运动时间',
          field: 'duration',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动时间' }
        },
        {
          type: 'select',
          title: '运动方式',
          field: 'sport_type',
          props: { placeholder: '请选择运动方式' },
          options: []
        },
        {
          type: 'a-button',
          hidden: true,
          class: 'ant-col-2 mt-5',
          name: 'a-button',
          props: { icon: 'close', type: 'danger' },
          children: ['删除']
        },
        {
          type: 'hidden',
          title: '运动计划id',
          field: 'id'
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        }
      ],
      nutritionSportIdx: -1,
      nutritionOri: {}
    }
  },
  watch: {
    nutritionSportIdx: {
      handler (newVal, oldVal) {
        if (this.nutritionSportIdx < 0 || this.nutritionOri.prescription_sport === undefined || this.nutritionOri.prescription_sport[this.nutritionSportIdx] === undefined) {
          this.$emit('spinningChange', false)
          this.nutritionSportIdx = -1
          return false
        }
        // 回显多个运动计划
        let spfNum = this.sportFormRuleNum
        let spfNumMark = true
        if (this.sportFormRuleNumArr[this.nutritionSportIdx] !== undefined) { // 已有form-create组件，只需要回显字段值
          spfNum = this.sportFormRuleNumArr[this.nutritionSportIdx]
          spfNumMark = false
        }
        let setSportArr = {}
        let arrNum = 0
        const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
        cuTmpArr.map(spv => {
          let oriVal = this.nutritionOri.prescription_sport[this.nutritionSportIdx][spv.field]
          // 从第2个开始加删除按钮
          if (this.sportFormRuleNum > 1) {
            if (spv.field === 'sport_type') {
              spv.col = {
                span: 10
              }
              spv.wrap = {
                labelCol: { span: 6 },
                wrapperCol: { span: 16 }
              }
            }
            if (spv.type === 'a-button' && spv.name === 'a-button') {
              spv.hidden = false
              spv.on = {
                click: () => this.clickRemoveSport(spfNum)
              }
            }
          } else {
            if (spv.type === 'a-divider' && spv.name === 'a-divider') {
              spv.hidden = true
            }
          }
          if (spv.title !== undefined && spv.field !== undefined) {
            if (spv.field === 'sport_type') {
              spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
              oriVal = oriVal.toString()
            }
            spv.title = spv.title + '#' + spfNum
            spv.field = spv.field + '#' + spfNum
            setSportArr[spv.field] = oriVal
          }
          if (spfNumMark) {
            this.cformNutritionApi.append(spv, 'patient_id2')
          }
          arrNum++
        })
        const timer = setInterval(() => {
          if (arrNum >= this.sportFormRuleTmpArr.length) {
            this.cformNutritionApi.setValue(setSportArr)
            if (spfNumMark) {
              this.sportFormRuleNumArr.push(this.sportFormRuleNum)
              this.sportFormRuleNum++
            }
            this.nutritionSportIdx++
            clearInterval(timer)
          }
        }, 100)
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  created () {
    this.$nextTick(() => {
      this.cformNutritionApi.setValue({ patient_id: this.parentParams.patient })
      this.cformNutritionApi.updateRules({ // 更新规则
        'intervention_method': { options: this.$options.filters.selectOptionsData('prescription_nutrition.intervention_method') },
        'sport_type': { options: this.$options.filters.selectOptionsData('patient.activity_type') }
      })
    })
    // 获取饮食模式下拉数据
    selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1) {
        this.cformNutritionApi.updateRules({ // 更新规则
          'dietary_pattern_id': { options: res.data }
        })
      }
      this.renderSportFormData()
    })
  },
  methods: {
    saveNutritionRequest (data, formObj) {
      if (this.parentParams.serviceRecord < 1) {
        this.$message.error('请先保存客户预约目的及期望相关信息！')
        return false
      }
      this.$emit('spinningChange', true)
      const that = this
      savePatientNutritionInfo(Object.assign({ service_record_id: this.parentParams.serviceRecord }, data)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '营养处方保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              that.$emit('spinningChange', false)
              return that.$router.go(-1)
            },
            onCancel () {
              // formObj.resetFields()
              // that.cformNutritionApi.setValue({ patient_id: that.parentParams.patient })
              that.loadPatientNutritionOriInfo()
              return false
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
          this.$emit('spinningChange', false)
        }
      })
    },
    loadPatientNutritionOriInfo () {
      // 加载营养方案数据
      getPatientNutritionInfo({
        patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.serviceRecord
      }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.nutritionOri = resNut.data

          let setNutArr = JSON.parse(JSON.stringify(resNut.data))
          delete setNutArr.prescription_sport
          setNutArr.intervention_method = setNutArr.intervention_method.toString()
          this.cformNutritionApi.setValue(setNutArr)
          // watch触发开始自动增加回显数据
          this.nutritionSportIdx = 0
        }
      })
    },
    clickPrescriptionBtn () {
      // 显示历史处方数据
      this.$emit('prescriptionDrawerVisibleChange', true)
    },
    clickshowPattern () {
      const cuPattern = this.cformNutritionApi.getValue('dietary_pattern_id')
      if (cuPattern === undefined || cuPattern < 0) {
        this.$message.error('请先选择左侧饮食模式推荐！')
        return false
      }
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    clickAddSport () {
      this.renderSportFormData()
    },
    renderSportFormData () {
      this.$emit('spinningChange', true)
      let arrNum = 0
      const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
      cuTmpArr.map(spv => {
        // 从第二个运动计划开始就要显示删除按钮
        if (this.sportFormRuleNum > 1) {
          if (spv.field === 'sport_type') {
            spv.col = {
              span: 10
            }
            spv.wrap = {
              labelCol: { span: 6 },
              wrapperCol: { span: 16 }
            }
          }
          if (spv.type === 'a-button' && spv.name === 'a-button') {
            spv.hidden = false
            const cuspnum = parseInt(this.sportFormRuleNum)
            spv.on = {
              click: () => this.clickRemoveSport(cuspnum)
            }
          }
        } else {
          if (spv.type === 'a-divider' && spv.name === 'a-divider') {
            spv.hidden = true
          }
        }
        if (spv.title !== undefined && spv.field !== undefined) {
          if (spv.field === 'sport_type') {
            spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
          }
          spv.title = spv.title + '#' + this.sportFormRuleNum
          spv.field = spv.field + '#' + this.sportFormRuleNum
        }
        this.cformNutritionApi.append(spv, 'patient_id2')
        arrNum++
      })
      const timer = setInterval(() => {
        if (arrNum >= this.sportFormRuleTmpArr.length) {
          this.sportFormRuleNumArr.push(this.sportFormRuleNum)
          this.sportFormRuleNum++
          clearInterval(timer)
          this.$emit('spinningChange', false)
        }
      }, 100)
    },
    clickRemoveSport (cuext) {
      const spArrIdx = this.sportFormRuleNumArr.indexOf(cuext)
      if (spArrIdx < 0) {
        this.$message.error('请正确选择要删除的运动计划！')
        return false
      }
      // 找到id#字段，从前一个分割线字段开始往后找到plan_content#的结束位置，都删除
      let startIdx = false
      let endIdx = false
      const cuRulesArr = JSON.parse(JSON.stringify(this.cformNutritionApi.rule))
      const rmArr = []
      cuRulesArr.map((nrv, nridx) => {
        if (nrv.field !== undefined && nrv.field === 'id#' + cuext) {
          startIdx = nridx
        }
        if (nrv.field !== undefined && nrv.field === 'plan_content#' + cuext) {
          endIdx = nridx
        }
        if (nrv.field !== undefined) {
          const farr = nrv.field.split('#')
          if (farr.length === 2 && parseInt(farr[1]) === parseInt(cuext)) {
            rmArr.push(nrv.field)
          }
        }
      })
      if (startIdx === false || endIdx === false || startIdx > endIdx) {
        this.$message.error('请确认选择要删除的运动计划存在！')
        return false
      }
      const cuSpId = this.cformNutritionApi.getValue('id#' + cuext)
      this.$emit('spinningChange', true)
      if (cuSpId !== undefined && cuSpId > 0) {
        removePatientPrescriptionSport({ id: cuSpId, service_record: this.parentParams.serviceRecord, patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, prescription_no: this.nutritionOri.prescription_no }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
            this.$emit('spinningChange', false)
          }
        })
      } else {
        this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
      }
    },
    rmSportFormField (cuRulesArr, rmArr, startIdx, endIdx, spArrIdx) {
      // 先设置没有field字段的数据为hidden隐藏，再删除有field的字段，因为rule在删除字段后索引有变化，同时进行会有问题
      let ridx = (startIdx - 1)
      for (ridx; ridx <= endIdx; ridx++) {
        if (cuRulesArr[ridx].field === undefined) {
          this.cformNutritionApi.rule[ridx].hidden = true
        }
      }
      const timer = setInterval(() => {
        if (ridx > endIdx) {
          rmArr.map(rmv => {
            this.cformNutritionApi.removeField(rmv)
          })
          clearInterval(timer)
        }
      }, 100)
      delete this.sportFormRuleNumArr[spArrIdx]
      this.sportFormRuleNumArr.splice(spArrIdx, 1)
      this.$message.success('删除成功！')
      this.$emit('spinningChange', false)
    },
  },
})

</script>