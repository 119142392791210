<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="editGoodsSpecDefForm"
        >
          <div class="title">规格参数信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="规格参数名称"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写规格参数名称"
                  v-decorator="[
                    'spec_name',
                    { rules: [{ required: true, message: '请填写规格参数名称', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="商品类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择商品类型' }] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择商品类型"
                  allow-clear
                  :treeDefaultExpandedKeys="treeDefaultExpandedKeys"
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  :options="'emall_goods_spec.disp_type' | selectOptionsData()"
                  show-search
                  optionFilterProp="children"
                  placeholder="请选择展示类型"
                  v-decorator="[
                    'disp_type',
                    { rules: [{ required: true, message: '请选择展示类型' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="排序号"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写排序号"
                  :min="1"
                  :step="1"
                  style="width:100%;"
                  v-decorator="[
                    'sort',
                    { rules: [{ required: true, message: '请填写排序号' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-icon
            slot="indicator"
            type="warning"
          />
          <div class="title">规格值信息</div>

          <a-table
            :columns="columns"
            :dataSource="data"
            :pagination="false"
            rowKey="key"
          >
            <template
              slot="orid"
              slot-scope="text"
            >
              {{ text > 0 ? text : '新' }}
            </template>
            <template
              slot="spec_val"
              slot-scope="text, record"
            >
              <a-input
                style="margin: -5px 0;width:100%;"
                :value="record.spec_val"
                :disabled="(record.rm_mark === undefined || record.rm_mark < 1) ? false : true"
                placeholder="规格值"
                @change="(v) => selectColData(v.target.value, record.key,'spec_val')"
              />
            </template>
            <template
              slot="sort"
              slot-scope="text, record"
            >
              <a-input-number
                style="margin: -5px 0;width:100%;"
                placeholder="排序号"
                :value="record.sort"
                :disabled="(record.rm_mark === undefined || record.rm_mark < 1) ? false : true"
                :min="1"
                :step="1"
                @change="(v) => selectColData(v, record.key,'sort')"
              />
            </template>
            <template
              slot="operation"
              slot-scope="text, record"
            >
              <div v-if="record.rm_mark === undefined || record.rm_mark < 1">
                <a-popconfirm
                  v-if="data.length > 0"
                  title="是否要删除此行？"
                  @confirm="removeMember(record.key)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </div>
              <div
                v-else
                class="font-error"
              >
                已关联商品
              </div>
            </template>
          </a-table>
          <a-button
            style="width: 100%; margin-top: 16px; margin-bottom: 8px"
            type="dashed"
            icon="plus"
            @click="newMember"
          >
            继续添加规格值
          </a-button>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
            class="margin-top-20"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListTypeByTt } from '@/api/dms/goodsType'
import { getEmallGoodsSpecDefInfo, editEmallGoodsSpecDef } from '@/api/dms/emallGoodSpec'

export default {
  name: 'EditGoodsSpecDefForm',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      editGoodsSpecDefForm: this.$form.createForm(this),
      typeListData: [],
      columns: [
        {
          title: '编号',
          dataIndex: 'orid',
          width: '10%',
          ellipsis: true,
          scopedSlots: { customRender: 'orid' }
        },
        {
          title: '规格值',
          dataIndex: 'spec_val',
          width: '50%',
          ellipsis: true,
          scopedSlots: { customRender: 'spec_val' }
        },
        {
          title: '排序号',
          dataIndex: 'sort',
          width: '30%',
          ellipsis: true,
          scopedSlots: { customRender: 'sort' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      data: [],
      treeDefaultExpandedKeys: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoods.editGoodsSpecDefs'))
    if (this.params === null || this.params.def === undefined || this.params.def < 1) {
      this.$message.error('请确认选择规格参数！')
      return this.$router.go(-1)
    }
    this.spinning = true
    this.editGoodsSpecDefForm.getFieldDecorator('id', { initialValue: this.params.def, preserve: true, rules: [{ required: true }] })
    this.loadSelectData()
  },
  methods: {
    loadSelectData () {
      this.tip = '加载中...'
      getEmallGoodsSpecDefInfo({ def: this.params.def }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.treeDefaultExpandedKeys = [res.data.goods_type]
          this.editGoodsSpecDefForm.setFieldsValue({ spec_name: res.data.spec_name, goods_type: res.data.goods_type, disp_type: res.data.disp_type, sort: res.data.sort })
          if (res.data.emall_goods_spec_val !== undefined && res.data.emall_goods_spec_val.length > 0) {
            res.data.emall_goods_spec_val.map((gv, gk) => {
              this.data.push({
                key: (gk + 1).toString(),
                orid: gv.id,
                spec_val: gv.spec_val,
                sort: gv.sort,
                rm_mark: (gv.emall_goods_sku_spec !== undefined && gv.emall_goods_sku_spec[0] !== undefined && gv.emall_goods_sku_spec[0].sku_nums > 0) ? 1 : 0
              })
            })
          }
        }
      })
      selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.typeListData = res.data
        }
        this.spinning = false
        this.tip = '处理中...'
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      if (this.data.length > 0) {
        let checkAuth = true
        for (const di in this.data) {
          if (this.data[di].spec_val === undefined || this.data[di].spec_val === null || this.data[di].spec_val === '') {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的规格值！'
            })
            return false
          }
          if (this.data[di].sort === undefined || this.data[di].sort === null || this.data[di].sort === '') {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的排序号！'
            })
            return false
          }
        }
        if (!checkAuth) {
          return false
        }
      }

      this.editGoodsSpecDefForm.validateFields((err, values) => {
        if (!err) {
          this.spinning = true
          values['values'] = this.data
          editEmallGoodsSpecDef(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.data = []
                  that.loadSelectData()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    newMember () {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        orid: 0,
        spec_val: '',
        sort: ''
      })
    },
    removeMember (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    selectColData (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
