<template>
  <div class="nuMainDivPage1">
    <div class="numainPageLogo">
      <!-- <img
        class="numainLogo"
        src="/images/nutrition_logo.png"
      />
      <img
        class="numainLogo1"
        src="/images/nutrition_logo1.png"
      /> -->
    </div>

    <div class="numainTitle">
      <div>健康管理 - {{ showTitle.text }}</div>
      <div>Health Management - {{ showTitle.textEn }}</div>
    </div>

    <div class="nuSecondBox">
      <div class="inline-block nuSecondLeft">
        建议在实施计划前再次和您的专属营养师讨论，以确保饮食计划符合个人的偏好，特殊需求和健康状况。
      </div>
      <div class="nuSecondTitle inline-block">
        <!-- <div class="nuSecondTitle1">
          L I V I N G
          <br>
          预 知 风 险
        </div>
        <div class="nuSecondTitle2">
          B E T T E R
          <br>
          健 康 生 活
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NutritionPage1',
  props: {
    reportInfo: {
      type: Object,
      default: null
    },
    showTitle: {
      type: Object,
      default: () => { return { text: '营养方案', textEn: 'Nutrition Report' } }
    }
  }

}
</script>

<style lang="scss" scoped>
.nuMainDivPage1 {
  width: 100%;
  height: 100%;
  background-image: url("/images/nutrition_bg_1.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.numainPageLogo {
  // display: flex;
  // position: relative;
  // align-items: flex-start;
  // align-self: center;
  // flex-direction: row;
  // justify-content: space-between;
  padding: 50px 50px;
  width: 100%;
  height: 140px;
}

.numainLogo {
  display: inline-block;
  height: 100px;
}

.numainLogo1 {
  display: inline-block;
  height: 115px;
  margin-left: 580px;
}

.numainTitle {
  width: 100%;
  position: relative;
  top: 1130px;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // color: #4863a7;
  color: #59aacc;
  font-style: normal;
}

.numainTitle div:first-child {
  font-size: 38px;
  white-space: nowrap;
}

.numainTitle div:last-child {
  font-size: 20px;
  // font-family: 'Proxima Nova Rg' !important;
  // font-size: 30px;
}

.nuSecondBox {
  width: 100%;
  position: relative;
  top: 1320px;
  padding: 0 80px;
}

.nuSecondLeft {
  width: calc(100% - 280px);
  line-height: 60px;
  vertical-align: top;
}

.nuSecondTitle {
  // width: 35%;
  // padding-left: 830px;
  font-style: normal;
  color: #000000;
  font-size: 20px;
  text-align: right;
}

.nuSecondTitle1 {
  width: 115px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}

.nuSecondTitle2 {
  width: 125px;
  margin-left: 35px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
</style>
