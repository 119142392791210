<!-- 
	错误提示页面
 -->

<template>
  <a-result
    :status="resStatus"
    :title="showTitle"
    :sub-title="subTitle"
  >
    <!-- <template #extra>
      <router-link
        class="ant-btn ant-btn-primary"
        to="/"
      >返回首页</router-link>
    </template> -->
  </a-result>
</template>

<script>
export default ({
  name: 'SurveyInputError',
  data () {
    return {
      showTitle: '',
      subTitle: '',
      resStatus: '404'
    }
  },
  created () {
    if (this.$route.query !== undefined && this.$route.query.sub !== undefined) {
      this.subTitle = this.$route.query.sub
    }
    if (this.$route.query !== undefined && this.$route.query.title !== undefined) {
      this.showTitle = this.$route.query.title
    }
    if (this.$route.query !== undefined && this.$route.query.status !== undefined) {
      this.resStatus = this.$route.query.status
    }
  }
})

</script>