<template>
  <div>
    <a-card :bordered="false">
      <div class="mx-25">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="5"
                :sm="24"
              >
                <a-form-item label="当前状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="全部"
                    :options="'schedule.status' | selectOptionsData()"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="服务时间">
                  <a-range-picker
                    inputReadOnly
                    :allowClear="false"
                    style="width:100%;"
                    :ranges="{ '今天': [moment(), moment()], '本周': [moment().startOf('week'), moment().endOf('week')], '本月': [moment().startOf('month'), moment().endOf('month')], '最近三月': [moment().add(-3, 'months'), moment()], '本年': [moment().startOf('year'), moment().endOf('year')] }"
                    valueFormat="YYYY-MM-DD"
                    v-model="queryParam.date"
                  >
                    <a-icon
                      slot="suffixIcon"
                      type="calendar"
                    />
                  </a-range-picker>
                </a-form-item>
              </a-col>

              <a-col
                :md="7"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.scheduleListTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {date: [moment().startOf('week'), moment().endOf('week')]})"
                  >重置</a-button>
                  <a-button
                    :hidden="'/HealthServiceSchedules/addSchedule' | isShowButton"
                    type="primary"
                    icon="plus"
                    @click="addScheduleClick"
                  >新增临时排班</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>

      <s-table
        ref="scheduleListTable"
        size="default"
        rowKey="id"
        showPagination="auto"
        :columns="columns"
        :data="loadData"
        :pageSize="pageSize"
        :scroll="tableScroll"
      >
        <span
          slot="status"
          slot-scope="text"
        >
          <a-badge
            :status="text | statusTypeFilter"
            :text="'schedule.status' | showText(text)"
          />
        </span>
        <span
          slot="action"
          slot-scope="text, record"
        >
          <template>
            <a
              :hidden="'/HealthServiceSchedules/editSchedule' | isShowButton"
              @click="clickEdit(record)"
            >编辑</a>
            <a-divider
              v-if="record.status === 10"
              type="vertical"
              :hidden="'/HisPatients/addPatientAppointment' | isShowSeparator(['/HealthServiceSchedules/editSchedule'])"
            />
            <span
              v-if="record.status === 10"
              :hidden="'/HisPatients/addPatientAppointment' | isShowButton"
            >
              <a @click="clickSetAppoint(record)">预约</a>
            </span>
          </template>
        </span>
      </s-table>

      <!-- 手动新增客户预约抽屉窗口 -->
      <schedule-appointment
        :parentParams="patAppointParam"
        :patAppointVisible="patAppointVisible"
        @patAppointVisibleChange="patAppointVisibleChange"
      />

      <!-- 排班新增、编辑抽屉窗口 -->
      <a-drawer
        width="350"
        :title="drawerTitle"
        :maskClosable="false"
        placement="right"
        :closable="true"
        :visible="editDrawerVisible"
        @close="closeEditDrawer"
        id="drawer-body-div"
      >
        <a-spin
          tip="处理中..."
          size="large"
          :spinning="editDrawerSpinning"
        >
          <div>
            <form-create
              v-model="cformEditApi"
              :rule="cformEditRule"
              :option="cformEditOption"
            ></form-create>

            <a-row>
              <a-col
                :offset="8"
                :span="16"
              >
                <a-button
                  @click="closeEditDrawer"
                  type="default"
                  class="mr-5"
                >关闭</a-button>
                <a-button
                  @click="submitEditBtnClick"
                  type="primary"
                >确认保存</a-button>
              </a-col>
            </a-row>
          </div>
        </a-spin>
      </a-drawer>
    </a-card>
  </div>
</template>

<script>
import { getScheduleList, saveSchedule } from '@/api/dms/schedule'
import { selectListDepartment } from '@/api/dms/department'
import { ScheduleAppointment } from '@/components/Schedule'
import moment from 'moment'

export default {
  name: 'ListSchedule',
  components: { ScheduleAppointment },
  data () {
    return {
      columns: [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id'
        },
        // {
        //   title: '姓名',
        //   width: 100,
        //   dataIndex: 'nick_name',
        //   ellipsis: true
        // },
        {
          title: '所属部门',
          width: 100,
          dataIndex: 'department_name',
          ellipsis: true
        },
        {
          title: '服务时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'service_date',
          customRender: (text) => {
            return text !== undefined && text !== null && text !== '' ? text.substr(0, 16) : ''
          }
        },
        {
          title: '可预约数量',
          width: 80,
          align: 'center',
          dataIndex: 'available_count',
          ellipsis: true
        },
        {
          title: '加号数量',
          width: 80,
          align: 'center',
          dataIndex: 'add_count',
          ellipsis: true
        },
        {
          title: '已约数量',
          width: 80,
          align: 'center',
          dataIndex: 'appoint_count',
          ellipsis: true
        },
        {
          title: '当前状态',
          width: 80,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '备注',
          ellipsis: true,
          dataIndex: 'remarks',
          ellipsis: true
        },
        {
          title: '更新时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'update_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 95,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: { date: [moment().startOf('week'), moment().endOf('week')] },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }, parameter, this.queryParam)
        return getScheduleList(requestParameters)
          .then(res => {
            return res
          })
      },
      depList: [],
      drawerTitle: '排班',
      editDrawerVisible: false,
      editDrawerSpinning: false,
      patAppointVisible: false,
      patAppointParam: {},
      //实例对象
      cformEditApi: {},
      //表单生成规则
      cformEditRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'service_user_id',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'dep_id',
          value: this.$store.getters.userInfo.user.department_id
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'DatePicker',
          title: '服务时间',
          field: 'service_date', style: { width: '100%' },
          props: { placeholder: '请选择服务时间', type: 'date', showTime: true, format: 'YYYY-MM-DD HH:mm' },
          // col: {
          //   span: 24
          // },
          // wrap: {
          //   labelCol: { span: 4 },
          //   wrapperCol: { span: 19 }
          // },
          validate: [
            { required: true, message: '请确认选择服务时间' }
          ]
        },
        {
          type: 'select',
          title: '当前状态',
          field: 'status',
          props: { placeholder: '当前状态' },
          options: [],
          validate: [
            { required: true, message: '请选择当前状态' }
          ]
        },
        {
          type: 'input',
          title: '可预约数量',
          field: 'available_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '可预约数量', min: 0, step: 1, max: 127, addonAfter: '人' },
          validate: [
            { required: true, message: '请填写可预约数量' }
          ]
        },
        {
          type: 'input',
          title: '加号数量',
          field: 'add_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '加号数量', min: 0, step: 1, max: 127, addonAfter: '人' },
          validate: [
            { required: true, message: '请填写加号数量' }
          ]
        },
        {
          type: 'input',
          title: '已约数量',
          field: 'appoint_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '已约数量', min: 0, step: 1, max: 127, addonAfter: '人' },
          validate: [
            { required: true, message: '请填写已约数量' }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          // col: {
          //   span: 24
          // },
          // wrap: {
          //   labelCol: { span: 4 },
          //   wrapperCol: { span: 19 }
          // },
          props: { placeholder: '请填写备注' }
        }
      ],
      //组件参数配置
      cformEditOption: {
        // form: {
        //   layout: 'horizontal'
        // },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 8 },
          wrapperCol: { span: 15 }
        },
        submitBtn: false
      },
    }
  },
  created () {
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.depList = res.data
      }
    })
  },
  filters: {
    statusTypeFilter (type) {
      if (type < 0) {
        return 'error'
      } else if (type >= 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  methods: {
    moment,
    clickEdit (record) {
      // 编辑
      this.drawerTitle = '编辑排班信息'
      this.editDrawerVisible = true
      this.$nextTick(() => {
        this.cformEditApi.updateRules({ 'status': { options: this.$options.filters.selectOptionsData('schedule.status') } })
        const curecord = JSON.parse(JSON.stringify(record))
        curecord.status = curecord.status.toString()
        this.cformEditApi.setValue(curecord)
      })
    },
    addScheduleClick () {
      this.drawerTitle = '新增临时排班'
      this.editDrawerVisible = true
      this.$nextTick(() => {
        this.cformEditApi.updateRules({ 'status': { options: this.$options.filters.selectOptionsData('schedule.status') } })
        this.cformEditApi.setValue({ add_count: 0, appoint_count: 0 })
      })
    },
    clickSetAppoint (record) {
      this.patAppointVisible = true
      this.patAppointParam = { incharge_doc: record.service_user_id, schedule_id: record.id }
    },
    patAppointVisibleChange (v) {
      this.patAppointVisible = v
      this.$refs.scheduleListTable.refresh(true)
    },
    closeEditDrawer () {
      this.editDrawerVisible = false
      this.$refs.scheduleListTable.refresh(true)
    },
    submitEditBtnClick () {
      this.cformEditApi.validate((valid2, fail2) => {
        if (valid2) {
          this.editDrawerSpinning = true
          let cuFormData = this.cformEditApi.formData()
          saveSchedule(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformEditApi.resetFields()
              this.closeEditDrawer()
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.editDrawerSpinning = false
          })
        } else {
          console.log(fail2)
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
  }
}

</script>

<style lang="scss" scoped>
</style>