<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>营养处方</span> -->
          <br>
          <!-- <span>Nutrition Prescription</span> -->
        </div>
        <div>
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div class="nuContentWithSider">
        <table class="analysisTable fontNuBase">
          <thead>
            <tr class="thead_tr">
              <th colspan="2">
                一日食谱建议
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="analysisTabletd1">
                早餐
              </td>
              <td>{{ nutritionInfo.breakfast }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1">
                早加餐
              </td>
              <td>{{ nutritionInfo.snack_morning }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1">
                午餐
              </td>
              <td>{{ nutritionInfo.lunch }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1">
                午加餐
              </td>
              <td>{{ nutritionInfo.snack_afternoon }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1">
                晚餐
              </td>
              <td>{{ nutritionInfo.supper }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="nuContentRightSider">
        <div class="nuContentRightSiderText2">
          <div class="fontNuBase">营养处方</div>
          <div class="fontNuS4">Nutrition Prescription</div>
        </div>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import NutritionFooter from './NutritionFooter'

export default {
  name: 'NutritionPage5',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    nutritionInfo: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nuContentRightSiderText2 {
  top: 650px;
}
</style>
