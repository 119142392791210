<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          id="editShopForm"
          @submit="handleSubmit"
          :form="editShopForm"
        >
          <div class="title">店铺基础信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="所属企业"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择所属企业"
                  :options="entListArr"
                  :allowClear="true"
                  @change="enterpriseChange"
                  v-decorator="[
                    'enterprise_id',
                    { rules: [{ required: true, message: '请选择所属企业' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="所属部门"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择所属部门"
                  :options="cuDepArrData"
                  :allowClear="true"
                  v-decorator="[
                    'dep_id',
                    { rules: [{ required: true, message: '请选择所属部门' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="店铺名称"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写店铺名称"
                  v-decorator="[
                    'shop_name',
                    { rules: [{ required: true, message: '请填写店铺名称', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="宣传标语"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写宣传标语"
                  v-decorator="['sub_title']"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="店铺地址"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-cascader
                  :options="proOptions"
                  :loadData="proLoadData"
                  :changeOnSelect="true"
                  notFoundContent="暂无可选"
                  placeholder="请选择店铺地址"
                  v-decorator="[
                    'province',
                    { rules: [{ required: true, message: '请选择店铺地址' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="地址详细"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="例：人民南路1段1号xxx大厦A座1201号"
                  v-decorator="[
                    'detail_address',
                    { rules: [{ required: true, message: '请填写地址详细', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="联系人姓名"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写联系人姓名"
                  v-decorator="[
                    'contact',
                    { rules: [{ required: true,min:2,max:50, message: '请填写联系人姓名', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="联系电话"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写联系电话"
                  v-decorator="[
                    'contact_phone',
                    { rules: [{ required: true, message: '请填写联系电话', whitespace: true },{validator: checkMobile}] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="店铺介绍"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-textarea
                  placeholder="请填写店铺介绍"
                  :auto-size="{ minRows: 3, maxRows: 10 }"
                  v-decorator="['introduction']"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="店铺特色"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-textarea
                  placeholder="请填写店铺特色"
                  :auto-size="{ minRows: 3, maxRows: 10 }"
                  v-decorator="['feature']"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="店铺展示图"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  accept="image/*"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="beforeUpload"
                  :file-list="fileList"
                  :remove="rmUpImgFile"
                  @preview="upImgPreview"
                  @change="upImgChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getCascaderList } from '@/api/manage'
import { editEmallShop, getEmallShopInfo } from '@/api/dms/emallShop'
import { selectListRelationEnterprise } from '@/api/dms/enterprise'
import { selectListDepartment } from '@/api/dms/department'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'
import { checkMobile } from '@/utils/customValidate'

export default {
  name: 'EditShopForm',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      editShopForm: this.$form.createForm(this),
      proOptions: [],
      cuDepArrData: [],
      entListArr: [],
      fileList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallShops.editShops'))
    if (this.params === null || this.params.shop === undefined || this.params.shop < 1) {
      this.$message.error('请确认选择商城店铺！')
      return this.$router.go(-1)
    }
    // 注册id参数到form
    this.spinning = true
    this.editShopForm.getFieldDecorator('feature_image', { preserve: true })
    this.editShopForm.getFieldDecorator('id', { initialValue: this.params.shop, preserve: true, rules: [{ required: true }] })
    // this.editShopForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.entListArr.push({ label: this.$store.getters.userInfo.user.enterprise_name, value: this.$store.getters.userInfo.user.target_enterprise })
    selectListRelationEnterprise({ main: this.$store.getters.userInfo.user.target_enterprise, type: 3 }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        res.data.map(ev => {
          this.entListArr.push({ label: ev.relation_name, value: ev.relation_enterprise_id })
        })
      }
    }).finally(() => {
      getEmallShopInfo({ shop: this.params.shop }).then(res => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shop) {
          if (res.data.feature_image_show !== undefined && res.data.feature_image_show !== null && res.data.feature_image_show !== '') {
            this.fileList = [
              {
                uid: '-1',
                name: '店铺展示图',
                status: 'done',
                url: res.data.feature_image_show
              }
            ]
          }
          const proData = []
          if (res.data.province !== null && res.data.province !== '') {
            proData.push(res.data.province)
          }
          if (res.data.city !== null && res.data.city !== '') {
            proData.push(res.data.city)
          }
          if (res.data.district_county !== null && res.data.district_county !== '') {
            proData.push(res.data.district_county)
          }
          res.data.province = proData
          getCascaderList({ pid: proData.concat([0]), lastLevel: 3 }).then(res3 => {
            if (res3.data !== undefined && res3.data.length > 0) {
              this.proOptions = res3.data
            } else {
              this.proOptions = []
              this.$message.error(res3.msg || '获取地区信息失败！请刷新后重试！')
            }
            this.spinning = false
            this.tip = '数据处理中...'
          })
          delete res.data.feature_image_show
          delete res.data.city
          delete res.data.district_county
          res.data.dep_id = res.data.dep_id.toString()
          this.editShopForm.setFieldsValue(res.data)
          this.enterpriseChange(res.data.enterprise_id)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
      })
    })
  },
  methods: {
    checkMobile,
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      this.editShopForm.validateFields((err, values) => {
        if (!err) {
          if (values.province === undefined || values.province.length !== 3) {
            that.$message.error('请确认收货地址省市区选择完整！')
            return
          }
          that.spinning = true
          editEmallShop(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '编辑店铺保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回店铺管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () { }
              })
            } else {
              that.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            that.spinning = false
          })
        }
      })
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 10
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过10MB！')
        return false
      }
    },
    upImgChange (info) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          this.editShopForm.setFieldsValue({ feature_image: upRes.data })
        }
      }

      if (info.file.status !== undefined) {
        this.fileList = info.fileList
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.editShopForm.getFieldValue('feature_image') }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.editShopForm.setFieldsValue({ feature_image: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    proLoadData (selectedOptions) {
      // 接口获取级联的下拉数据
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // 开始获取级联数据
      getCascaderList({ pid: targetOption.value, lastLevel: 3 }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          if (res.data !== undefined && res.data.length > 0) {
            targetOption.children = res.data
          } else {
            targetOption.children = []
          }
        } else {
          this.$message.error(res.msg || '获取地区信息失败！请刷新后重试！')
        }
        targetOption.loading = false
        this.proOptions = [...this.proOptions]
      })
    },
    enterpriseChange (v) {
      this.spinning = true
      selectListDepartment({ enterprise: v }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cuDepArrData = res.data
        }
        this.spinning = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#editShopForm {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
