<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveRequest"
          >
          </form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { savePatientActivityInfo } from '@/api/dms/patient'
import moment from 'moment'

export default {
  name: 'AddPatientActiveInfo',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'select',
          title: '活动类型',
          field: 'activity_type',
          props: { placeholder: '请选择活动类型' },
          options: [],
          validate: [
            { required: true, message: '请选择活动类型' }
          ]
        },
        {
          type: 'DatePicker',
          title: '记录时间',
          field: 'activity_time', style: { width: '100%' },
          props: { placeholder: '请填写记录时间', type: 'date', format: 'YYYY-MM-DD HH:mm:00', showTime: true },
          validate: [
            { required: true, message: '请填写记录时间' }
          ]
        },
        {
          type: 'input',
          title: '运动时长',
          field: 'duration_time', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写运动时长', min: 0, step: 1, max: 1440, addonAfter: '分钟' },
          validate: [
            { required: true, message: '请填写记录时间' }
          ]
        },
        {
          type: 'input',
          title: '消耗热量',
          field: 'calories', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写消耗热量', min: 0, step: 1, max: 1000000, addonAfter: '卡路里' }
        },
        {
          type: 'input',
          title: '其他备注',
          field: 'remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注' }
        },
      ],
      //组件参数配置
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      optionsArr: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.addPatientActiveInfo'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据 his.result_type
    const that = this
    const timer = setTimeout(() => {
      that.cformApi.updateRules({ // 更新规则
        'activity_type': { options: that.$options.filters.selectOptionsData('patient.activity_type') }
      })
      that.cformApi.setValue({ patient_id: that.params.patient, activity_time: moment().format('YYYY-MM-DD HH:mm:00') })
      that.spinning = false
      clearTimeout(timer)
    }, 1000)
  },
  methods: {
    moment,
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      savePatientActivityInfo(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '活动日志数据保存成功！',
            content: false,
            cancelText: '继续录入',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              formObj.resetFields()
              return that.cformApi.setValue({ patient_id: that.params.patient, activity_time: moment().format('YYYY-MM-DD HH:mm:00') })
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.tab-div-box {
  width: calc(100% - 40px);
}
.page-icon-left {
  line-height: 40px;
  vertical-align: top;
}
</style>