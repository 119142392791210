<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入客户姓名/客户编号搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="用户关注点">
                    <a-select
                      showSearch
                      v-model="queryParam.tag"
                      mode="multiple"
                      :maxTagCount="1"
                      placeholder="无"
                      :options="patientTag"
                      :filter-option="patientFilterOption"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.hisPatientTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/HisPatients/addPatient'"
                      :hidden="'/HisPatients/addPatient' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增客户</a-button>
                    </router-link>
                    <!-- <router-link
                      class="mx-10"
                      :to="'/HisPatients/addPatientAppointment'"
                      :hidden="'/HisPatients/addPatientAppointment' | isShowButton"
                    > -->
                    <a-button
                      class="mx-10"
                      :hidden="'/HisPatients/addPatientAppointment' | isShowButton"
                      type="primary"
                      icon="carry-out"
                      @click="clickSetAppoint"
                    >手动新增客户预约</a-button>
                    <!-- </router-link> -->
                    <!-- <a-button
                      type="primary"
                      icon="right"
                      @click="showSurveyList"
                    >测试</a-button> -->
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="hisPatientTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="gender"
            slot-scope="text"
          >
            {{ 'ryx.gender' | showText(text.toString()) }}
          </span>

          <span
            slot="disease_tag"
            slot-scope="text,record"
          >
            <template>
              <a-tag
                color="orange"
                v-for="tag in record.his_patient_tag"
                :key="tag.tag_id"
              >
                {{tag.tag_text}}
              </a-tag>
            </template>
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/HisPatients/viewPatient' | isShowButton">
                <a @click="clickView(record)">详情</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/HisPatients/editPatient','/HisPatients/setServiceRecord'] | isShowSeparator(['/HisPatients/viewPatient'])"
              />
              <span :hidden="'/HisPatients/setServiceRecord' | isShowButton">
                <a @click="clickSetRecord(record)">问诊</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/HisPatients/editPatient'] | isShowSeparator(['/HisPatients/setServiceRecord'])"
              />

              <a-dropdown :hidden="['/HisPatients/editPatient'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/HisPatients/editPatient' | isShowButton"
                    key="0"
                  >
                    <a @click="clickEdit(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/editPatient' | isShowButton"
                    key="10"
                  >
                    <a @click="clickManage(record)">档案管理</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/HealthPatientFoodMenus/setPatientFoodMenu' | isShowButton"
                    key="11"
                  >
                    <a @click="clickRecipe(record)">菜谱定制</a>
                  </a-menu-item>
                  <!-- <a-menu-item
                      :hidden="'/HisPatients/printPatientReportView' | isShowButton"
                      key="8"
                    >
                      <a @click="clickPrint(record)">打印最新报告</a>
                    </a-menu-item> -->
                  <a-menu-divider :hidden="['/HisPatients/addPatientDietaryInfo','/HisPatients/addPatientMeasureInfo','/HisPatients/addPatientBodyInfo','/HisPatients/followupPlanInfo'] | isShowSeparator(['/HisPatients/editPatient','/HisPatients/printPatientReportView','/HealthPatientFoodMenus/setPatientFoodMenu'])" />
                  <!-- <a-menu-item
                      :hidden="'/HisPatients/addPatientDietaryInfo' | isShowButton"
                      @click="clickDietary(record)"
                      key="1"
                    >服务信息录入</a-menu-item> -->
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientMeasureInfo' | isShowButton"
                    @click="clickMeasure(record)"
                    key="2"
                  >检测结果录入</a-menu-item>
                  <!-- <a-menu-item
                    :hidden="'/HisPatients/addPatientBodyInfo' | isShowButton"
                    @click="clickBody(record)"
                    key="6"
                  >体格指标录入</a-menu-item> -->
                  <a-menu-divider :hidden="['/HisPatients/inputSurveyByUser','/HisPatients/addPatientAppointment'] | isShowSeparator(['/HisPatients/addPatientDietaryInfo','/HisPatients/addPatientMeasureInfo','/HisPatients/addPatientBodyInfo'])" />
                  <!-- <a-menu-item
                    :hidden="'/HisPatients/inputSurveyByUser' | isShowButton"
                    @click="showSurveyList(record)"
                    key="8"
                  >问卷调查</a-menu-item> -->
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientAppointment' | isShowButton"
                    @click="clickSetAppoint(record)"
                    key="9"
                  >新增预约</a-menu-item>
                  <!-- <a-menu-item
                      :hidden="'/HisPatients/followupPlanInfo' | isShowButton"
                      @click="clickFollowup(record)"
                      key="7"
                    >随访计划</a-menu-item>
                    <a-menu-divider :hidden="['/HisPatients/addPatientFoodDaryInfo','/HisPatients/addPatientActiveInfo','/HisPatients/addPatientGoalInfo'] | isShowSeparator(['/HisPatients/editPatient','/HisPatients/addPatientDietaryInfo','/HisPatients/addPatientMeasureInfo','/HisPatients/addPatientBodyInfo','/HisPatients/followupPlanInfo'])" />
                    <a-menu-item
                      :hidden="'/HisPatients/addPatientFoodDaryInfo' | isShowButton"
                      @click="clickFoodDary(record)"
                      key="3"
                    >日常餐饮录入</a-menu-item>
                    <a-menu-item
                      :hidden="'/HisPatients/addPatientActiveInfo' | isShowButton"
                      @click="clickActive(record)"
                      key="4"
                    >活动日志录入</a-menu-item>
                    <a-menu-item
                      :hidden="'/HisPatients/addPatientGoalInfo' | isShowButton"
                      @click="clickGoal(record)"
                      key="5"
                    >目标日志录入</a-menu-item> -->
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>

        <!-- 打印模块的dom -->
        <!-- <print-report-dom
          :parentParams="printParams"
          @spinningChange="spinningChange"
        /> -->
      </a-card>

      <!-- 问卷列表的抽屉窗口 -->
      <!-- <survey-list
        :parentParams="surveyDrawerParams"
        :surveyDrawerVisible="surveyDrawerVisible"
        @surveyDrawerVisibleChange="surveyDrawerVisibleChange"
      /> -->

      <!-- 手动新增客户预约抽屉窗口 -->
      <schedule-appointment
        :parentParams="patAppointParam"
        :patAppointVisible="patAppointVisible"
        @patAppointVisibleChange="patAppointVisibleChange"
      />

      <!-- 档案管理抽屉窗口 -->
      <patient-archive-manage
        :parentParams="patArchiveParam"
        :archiveDrawerVisible="archiveDrawerVisible"
        @archiveDrawerVisibleChange="archiveDrawerVisibleChange"
      />

      <!-- 客户定制食谱列表抽屉窗口 -->
      <patient-food-menu-list-drawer
        :parentParams="patFoodMenuParam"
        :patFoodMenuDrawerVisible="patFoodMenuDrawerVisible"
        @patFoodMenuDrawerVisibleChange="patFoodMenuDrawerVisibleChange"
      />

      <!-- 客户问诊服务记录列表 -->
      <ServiceRecordDrawer ref="serviceRecordDrawer" />
    </a-spin>
  </div>
</template>

<script>
import { listHisPatient } from '@/api/dms/patient'
// import { PrintReportDom } from '@/components/Report'PrintReportDom, 
// import { SurveyList } from '@/components/Surveys'SurveyList, 
import { ScheduleAppointment } from '@/components/Schedule'
import { PatientArchiveManage } from '@/components/PatientCards'
import { PatientFoodMenuListDrawer } from '@/components/PatientFoodMenu'
import { ServiceRecordDrawer } from '@/components/ServiceRecord'
import { getSelectListTag } from '@/api/manage'

export default {
  name: 'HisPatientList',
  components: { ScheduleAppointment, PatientArchiveManage, PatientFoodMenuListDrawer, ServiceRecordDrawer },
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 70,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '客户姓名',
          dataIndex: 'name',
          width: 170,
          ellipsis: true,
          // customRender: (text, row) => {
          //   return text + ' / ' + row.name_py
          // }
        },
        {
          title: '客户来源',
          dataIndex: 'enterprise_name',
          width: 160,
          ellipsis: true
        },
        {
          title: '客户编号',
          dataIndex: 'patient_no',
          width: 190,
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 50,
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '出生年月',
          dataIndex: 'birth_date',
          width: 95,
          ellipsis: true
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone',
          width: 110,
          ellipsis: true
        },
        // {
        //   title: '血型ABO',
        //   dataIndex: 'blood_abo',
        //   width: 95,
        //   ellipsis: true
        // },
        // {
        //   title: '血型RHD',
        //   dataIndex: 'blood_rhd',
        //   width: 95,
        //   ellipsis: true
        // },
        // {
        //   title: '身高(Kg)',
        //   dataIndex: 'height',
        //   width: 100,
        //   ellipsis: true
        // },
        // {
        //   title: '体重(Cm)',
        //   dataIndex: 'weight',
        //   width: 100,
        //   ellipsis: true
        // },
        // {
        //   title: 'BMI',
        //   dataIndex: 'bmi',
        //   width: 90,
        //   ellipsis: true
        // },
        {
          title: '客户画像',
          // width: 350,
          ellipsis: true,
          scopedSlots: { customRender: 'disease_tag' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, join: ['diseasetag', 'whereTag'] }, parameter, this.queryParam)
        // const that = this
        return listHisPatient(requestParameters)
          .then(res => {
            console.log(res)
            // if (res.data.length > 0) {
            //   that.tableScroll = { x: 1500 }
            // } else {
            //   that.tableScroll = {}
            // }
            return res
          })
      },
      surveyDrawerVisible: false,
      surveyDrawerParams: {},
      patAppointVisible: false,
      patAppointParam: {},
      archiveDrawerVisible: false,
      patArchiveParam: {},
      patFoodMenuParam: {},
      patFoodMenuDrawerVisible: false,
      patientTag: []
    }
  },
  created () {
    this.spinning = true

    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.patientTag = res.data
      }

      this.spinning = false
    })
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('HisPatients.editPatient', JSON.stringify({ patient: record.patient_uid }))
      this.$router.push({ path: '/HisPatients/editPatient' })
    },
    clickView (record) {
      sessionStorage.setItem('HisPatients.viewPatient', JSON.stringify({ patient: record.id, patient_uid: record.patient_uid }))
      this.$router.push({ path: '/HisPatients/viewPatient' })
    },
    clickDietary (record) {
      sessionStorage.setItem('HisPatients.addPatientDietaryInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientDietaryInfo' })
    },
    clickMeasure (record) {
      sessionStorage.setItem('HisPatients.addPatientMeasureInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientMeasureInfo' })
    },
    clickBody (record) {
      sessionStorage.setItem('HisPatients.addPatientBodyInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientBodyInfo' })
    },
    clickFollowup (record) {
      sessionStorage.setItem('HisPatients.followupPlanInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/followupPlanInfo' })
    },
    clickFoodDary (record) {
      sessionStorage.setItem('HisPatients.addPatientFoodDaryInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientFoodDaryInfo' })
    },
    clickActive (record) {
      sessionStorage.setItem('HisPatients.addPatientActiveInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientActiveInfo' })
    },
    clickGoal (record) {
      sessionStorage.setItem('HisPatients.addPatientGoalInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientGoalInfo' })
    },
    clickSetRecord (record) {
      this.$refs.serviceRecordDrawer.showDrawer(record)
    },
    // surveyDrawerVisibleChange (v) {
    //   this.surveyDrawerVisible = v
    // },
    // showSurveyList (row) {
    //   this.surveyDrawerParams = { name: row.name, phone: row.contact_phone, patient: row.id }
    //   // 打开问卷调查列表
    //   this.surveyDrawerVisible = true
    // },
    clickSetAppoint (record = false) {
      let cuPatApParm = { incharge_doc: this.$store.getters.userInfo.user.id }
      if (record !== false && record.id !== undefined) {
        cuPatApParm.choose_patient = record.id
      }
      this.patAppointVisible = true
      this.patAppointParam = JSON.parse(JSON.stringify(cuPatApParm))
    },
    patAppointVisibleChange (v) {
      this.patAppointVisible = v
    },
    archiveDrawerVisibleChange (v) {
      this.archiveDrawerVisible = v
    },
    clickManage (record) {
      // 档案管理弹窗
      this.patArchiveParam = { patient: record.id }
      this.archiveDrawerVisible = true
    },
    patFoodMenuDrawerVisibleChange (v) {
      this.patFoodMenuDrawerVisible = v
    },
    clickRecipe (record) {
      // 菜谱定制页面
      this.patFoodMenuParam = { patient: record.id }
      this.patFoodMenuDrawerVisible = true
    },
    patientFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].text !== undefined) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
