<!-- 
	This is the illustration sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in layout-sign-up-illustration">

    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-row
        type="flex"
        :gutter="[24,24]"
        justify="space-around"
        align="middle"
        class="row-main"
      >

        <!-- Sign Up Form Column -->
        <a-col
          :span="24"
          :md="{ span: 14, offset: 2 }"
          :lg="10"
          :xl="6"
          class="col-form mr-auto"
        >
          <h4 class="mb-15">账号注册</h4>
          <p class="text-muted">仅需手机验证即可入驻平台</p>

          <!-- Sign Up Form -->
          <a-form
            id="components-form-demo-normal-login"
            :form="signUpForm"
            class="login-form"
            @submit="handleSubmit"
            layout="horizontal"
            :hideRequiredMark="true"
          >
            <a-form-item
              class="mb-5"
              label="医院名称"
              :colon="false"
            >
              <a-auto-complete
                v-decorator="[
                  'enterprise_name',
                  { rules: [{ required: true, message: '请输入医院名称！'}] },
                ]"
                :data-source="entDataSource"
                optionLabelProp="children"
                placeholder="医院名称"
                @search="onSearchEnt"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="姓名"
              :colon="false"
            >
              <a-input
                v-decorator="[
                'nick_name',
                { rules: [{ required: true,min:2, message: '请输入您的姓名！', whitespace: true }] },
              ]"
                placeholder="您的姓名"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="用户名"
              :colon="false"
            >
              <a-input
                v-decorator="[
                'user_name',
                { rules: [{ required: true, message: '请输入账号登录用户名！', whitespace: true },{ validator: checkUserName }] },
              ]"
                placeholder="账号登录用户名"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="密码"
              :colon="false"
            >
              <a-input
                v-decorator="[
                'user_passwd',
                { rules: [{ required: true, min: 6, max: 12, message: '请输入6~12位登录密码', whitespace: true}] },
              ]"
                type="password"
                placeholder="账号登录密码"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="密码确认"
              :colon="false"
            >
              <a-input
                v-decorator="[
                'user_passwd2',
                { rules: [{ required: true, message: '请再次输入登录密码！', whitespace: true},{ validator: this.compareToPassword }] },
              ]"
                type="password"
                placeholder="再次输入登录密码"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="手机号"
              :colon="false"
            >
              <a-input-search
                :enter-button="btnText"
                :disabled="btnMaxSec >= 0"
                @search="onSendCode"
                v-decorator="[
                'mobile',
                { rules: [{ required: true, message: '请输入手机号！', whitespace: true },{ validator: checkMobile }] },
              ]"
                placeholder="手机号"
              />
            </a-form-item>
            <a-form-item
              class="mb-5"
              label="短息验证码"
              :colon="false"
            >
              <a-input
                v-decorator="[
                'captcha',
                { rules: [{ required: true, message: '请输入手机号收到的短息验证码！', whitespace: true }] },
              ]"
                placeholder="短息验证码"
              />
            </a-form-item>
            <a-form-item class="mb-5">
              <a-checkbox v-decorator="[
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true,
              },
            ]">
                阅读并同意《服务协议》和《个人信息保护政策》
              </a-checkbox>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                block
                html-type="submit"
                class="login-form-button"
              >
                注册
              </a-button>
            </a-form-item>
          </a-form>
          <!-- / Sign Up Form -->

          <p class="font-semibold text-muted text-right">已有账号？<router-link
              to="/login"
              class="font-bold text-dark"
            >点击登录</router-link></p>
        </a-col>
        <!-- / Sign Up Form Column -->

        <!-- Sign Up Image Column -->
        <a-col
          :span="24"
          :md="12"
          :lg="12"
          :xl="12"
          class="col-img"
          style="padding: 0;"
        >
          <div>
            <div class="img">
              <img
                src="/images/info-rocket-ill.png"
                alt="rocket"
              >
            </div>
            <h4 class="text-white">Your journey starts here</h4>
            <p class="text-white">Just as it takes a company to sustain a product, it takes a community to sustain a protocol.</p>
          </div>
        </a-col>
        <!-- / Sign Up Image Column -->

      </a-row>

    </a-spin>
  </div>
</template>

<script>
import { checkMobile, checkUserName } from '@/utils/customValidate'
import { getSmsCaptcha, registerDmsUser, selectListEnterprise } from '@/api/login'

export default ({
  data () {
    return {
      // Sign up form object.
      signUpForm: this.$form.createForm(this, { name: 'signup_illustration' }),
      btnText: '发送短信',
      btnMaxSec: -1,
      spinning: false,
      entDataSource: []
    }
  },
  methods: {
    checkMobile, checkUserName,
    // Handles input validation after submission.
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      this.signUpForm.validateFields((err, values) => {
        if (!err) {
          if (values.remember !== true) {
            this.$message.error('请确认阅读并同意《服务协议》和《个人信息保护政策》！')
            return false
          }
          if (isNaN(values.enterprise_name) || values.enterprise_name < 1) {
            this.$message.error('请输入医院名称并根据搜索结果选择相关医院！')
            return false
          }
          this.spinning = true
          values.platform = 'dms'
          registerDmsUser(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$success({
                title: '注册成功！',
                content: '请耐心等待管理员审核授权~~',
                okText: '好的，知道了！',
                onOk () {
                  return that.$router.push({ path: '/login' })
                }
              })
            } else {
              this.$message.error(res.msg || '注册失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    compareToPassword (rule, value, callback) {
      let ct
      if (value && value !== this.signUpForm.getFieldValue('user_passwd')) {
        ct = '两次输入密码不一致！'
      }
      return callback(ct)
    },
    onSendCode () {
      this.signUpForm.validateFields(['mobile'], (err, values) => {
        if (!err) {
          // 校验手机号通过才去发短信
          this.btnMaxSec = 180
          const timer = setInterval(() => {
            if (this.btnMaxSec > 0) {
              this.btnText = this.btnMaxSec + '秒后重试'
            } else {
              this.btnText = '发送短信'
              clearInterval(timer)
            }
            this.btnMaxSec--
          }, 1000)
          getSmsCaptcha({ phone: values.mobile }).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('短信已发送！请注意查收！')
            } else {
              this.$message.error(res.msg || '发送失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    onSearchEnt (searchText) {
      this.entDataSource = []
      if (searchText.length < 2) {
        return false
      }
      selectListEnterprise({ search: searchText, resFields: ['value', 'text'] }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.entDataSource = res.data
        }
      })
    }
  }
})

</script>

<style lang="scss">
</style>