<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="当前状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="请选择当前状态"
                    :options="'emall_goods.status' | selectOptionsData()"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入展示货名、标题搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="4"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.listEmallGoodsTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = { })"
                  >重置</a-button>
                  <router-link
                    :to="'/EmallGoods/addShopGoods'"
                    :hidden="'/EmallGoods/addShopGoods' | isShowButton"
                  >
                    <a-button
                      type="primary"
                      icon="plus"
                    >新增商城货品</a-button>
                  </router-link>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <s-table
          ref="listEmallGoodsTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'emall_goods.status' | showText(text)"
            />
          </span>

          <span
            slot="online_status"
            slot-scope="text"
          >
            <span :class="text === 1 ? 'font-success' : 'font-error'">{{ 'emall_goods.online_status' | showText(text) }}</span>
          </span>

          <span
            slot="is_virtual"
            slot-scope="text"
          >
            {{ 'emall_goods.is_virtual' | showText(text) }}
          </span>

          <span
            slot="inventory_control"
            slot-scope="text"
          >
            {{ 'emall_goods.inventory_control' | showText(text) }}
          </span>

          <span
            slot="freight"
            slot-scope="text, record"
          >
            <span v-if="text !== undefined && text !== null && text > 0 && record.freight_type !== undefined">{{ parseFloat(text) }}元/{{ 'emall_goods.freight_type' | showText(record.freight_type) }}</span>
          </span>

          <div
            slot="feature_image"
            slot-scope="text, record"
            style="max-width:25px;height:25px;cursor:pointer;"
          >
            <img
              v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.feature_image_show"
              @click="handlePreview(record.feature_image_show)"
            />
          </div>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EmallGoods/viewShopGoods' | isShowButton">
                <a @click="clickView(record)">详情</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/EmallGoods/changeShopGoodsStatus','/EmallGoodsShowcases/showManage','/EmallGoods/editAuthShops','/EmallGoods/emallShopGoodSku','/EmallGoods/editShopGoods'] | isShowSeparator(['/EmallGoods/viewShopGoods'])"
              />
              <a-dropdown :hidden="['/EmallGoods/changeShopGoodsStatus','/EmallGoodsShowcases/showManage','/EmallGoods/editAuthShops','/EmallGoods/emallShopGoodSku','/EmallGoods/editShopGoods'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/EmallGoods/editShopGoods' | isShowButton"
                    key="0"
                  >
                    <a @click="clickEdit(record)">信息编辑</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/EmallGoodsShowcases/showManage' | isShowButton"
                    key="1"
                  >
                    <a @click="clickShow(record)">展示内容管理</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/EmallGoods/emallShopGoodSku' | isShowButton"
                    key="2"
                  >
                    <a @click="clickSku(record)">商品SKU管理</a>
                  </a-menu-item>

                  <a-menu-divider :hidden="['/EmallGoods/editAuthShops','/EmallGoods/changeShopGoodsStatus'] | isShowSeparator(['/EmallGoods/editShopGoods','/EmallGoodsShowcases/showManage','/EmallGoods/emallShopGoodSku'])" />
                  <a-menu-item
                    :hidden="'/EmallGoods/editAuthShops' | isShowButton"
                    key="3"
                  >
                    <a @click="clickShop(record)">授权销售店铺</a>
                  </a-menu-item>

                  <a-menu-divider :hidden="['/EmallGoods/changeShopGoodsStatus'] | isShowSeparator(['/EmallGoods/editAuthShops'])" />
                  <a-menu-item
                    v-if="record.status <= -1"
                    :hidden="'/EmallGoods/changeShopGoodsStatus' | isShowButton"
                    key="4"
                  >
                    <a
                      @click="clickChangeStatus(record,20)"
                      class="font-success"
                    >正常可售</a>
                  </a-menu-item>
                  <a-menu-item
                    v-if="record.status <= -1"
                    :hidden="'/EmallGoods/changeShopGoodsStatus' | isShowButton"
                    key="5"
                  >
                    <a
                      @click="clickChangeStatus(record,-10)"
                      class="font-error"
                    >删除</a>
                  </a-menu-item>
                  <a-menu-item
                    v-if="record.status > -1"
                    :hidden="'/EmallGoods/changeShopGoodsStatus' | isShowButton"
                    key="6"
                  >
                    <a
                      v-if="record.online_status === 2"
                      @click="clickChangeStatus(record,1)"
                      class="font-success"
                    >上架</a>
                    <a
                      v-else
                      @click="clickChangeStatus(record,2)"
                      class="font-error"
                    >下架</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="货品主图"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEmallGood, changeStatus } from '@/api/dms/emallGood'

export default {
  name: 'EmallGoodsList',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      typeListData: [],
      previewVisible: false,
      previewImg: '',
      columns: [
        {
          title: '#',
          width: 80,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '展示品名',
          width: 150,
          dataIndex: 'goods_name',
          fixed: 'left'
        },
        {
          title: '展示标题',
          ellipsis: true,
          dataIndex: 'title'
        },
        {
          title: '货品类型',
          ellipsis: true,
          width: 95,
          dataIndex: 'goods_type_name'
        },
        {
          title: '货品主图',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'feature_image' }
        },
        {
          title: '当前状态',
          ellipsis: true,
          width: 100,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '是否上架',
          ellipsis: true,
          width: 95,
          dataIndex: 'online_status',
          scopedSlots: { customRender: 'online_status' }
        },
        {
          title: '虚拟/实体',
          ellipsis: true,
          width: 95,
          dataIndex: 'is_virtual',
          scopedSlots: { customRender: 'is_virtual' }
        },
        {
          title: '库存管控',
          ellipsis: true,
          width: 95,
          dataIndex: 'inventory_control',
          scopedSlots: { customRender: 'inventory_control' }
        },
        {
          title: '上架库存数',
          ellipsis: true,
          width: 105,
          dataIndex: 'inventory'
        },
        {
          title: '成本价',
          ellipsis: true,
          width: 95,
          dataIndex: 'cost_price'
        },
        {
          title: '销售标价',
          ellipsis: true,
          width: 95,
          dataIndex: 'unit_price'
        },
        {
          title: '实际售价',
          ellipsis: true,
          width: 95,
          dataIndex: 'promotion_unit_price'
        },
        {
          title: '运费',
          ellipsis: true,
          width: 95,
          dataIndex: 'freight',
          scopedSlots: { customRender: 'freight' }
        },
        {
          title: '销售时间',
          width: 320,
          ellipsis: true,
          dataIndex: 'start_time',
          customRender: (text, row, index) => {
            return text + ' ~ ' + row.end_time
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listEmallGood(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: 1900 }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      }
    }
  },
  created () {

  },
  filters: {
    statusTypeFilter (type) {
      if (type === 20) {
        return 'success'
      }
      if (type === 10) {
        return 'error'
      }
      return 'warning'
    }
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('EmallGoods.editShopGoods', JSON.stringify({ shopGood: record.id }))
      this.$router.push({ path: '/EmallGoods/editShopGoods' })
    },
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    clickView (record) {
      // 详情
      sessionStorage.setItem('EmallGoods.viewShopGoods', JSON.stringify({ shopGood: record.id }))
      this.$router.push({ path: '/EmallGoods/viewShopGoods' })
    },
    clickShow (record) {
      sessionStorage.setItem('EmallGoodsShowcases.showManage', JSON.stringify({ shopGood: record.id }))
      this.$router.push({ path: '/EmallGoodsShowcases/showManage' })
    },
    clickSku (record) {
      const Base64 = require('js-base64').Base64
      this.$router.push({ path: '/EmallGoods/listShopGoodsSku', query: { keyword: Base64.encode(record.goods_name + '(=)' + record.id) } })
    },
    clickShop (record) {
      // 授权店铺
      sessionStorage.setItem('EmallGoods.editAuthShops', JSON.stringify({ shopGood: record.id }))
      this.$router.push({ path: '/EmallGoods/editAuthShops' })
    },
    clickChangeStatus (record, cstatus) {
      // 修改状态
      if ([1, 2, -10, 20].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      const that = this
      this.$confirm({
        title: '确认修改该商城货品的状态？',
        content: false,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          that.tip = '处理中...'
          that.spinning = true
          changeStatus({ shopGood: record.id, cstatus, enterprise_id: that.$store.getters.userInfo.user.target_enterprise }).then(res => {
            if (res.status !== undefined && res.status === 1) {
              that.$message.success('操作成功！')
            } else {
              that.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            that.$refs.listEmallGoodsTable.refresh(true)
            that.spinning = false
            that.tip = '加载中...'
          })
        },
        onCancel () { }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-badge-status-text {
  margin-left: 3px !important;
}
</style>
