import axios from 'axios'
import store from '@/store'
import { message } from 'ant-design-vue'
import { VueAxios } from './axios'
import { verifySign, aesDecrypt, aesEncrypt } from '@/utils/enAndDecryption'
import VueCookies from 'vue-cookies'
// import router from '../router'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000 // 请求超时时间
})
// 是否开启rsa验签
const isRsa = (process.env.VUE_APP_OPEN_RSA !== undefined) ? process.env.VUE_APP_OPEN_RSA : false
// 是否开启aes加解密
const isAes = (process.env.VUE_APP_OPEN_AES !== undefined) ? process.env.VUE_APP_OPEN_AES : false

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    let errorShowMsg = data.msg || '操作失败！请刷新后重试！'
    // 检测是否需要rsa验签！
    if (isRsa === 'true' && data.sign !== undefined && data.result !== undefined) {
      // false验证失败！true验证通过！
      const checkSign = verifySign(data.result, data.sign)
      if (checkSign === false) {
        errorShowMsg = '数据验证失败！'
      }
    }
    // 检测是否aes解密
    if (isAes === 'true' && data.result !== undefined) {
      // false解密失败
      const decoding = aesDecrypt(data.result)
      errorShowMsg = decoding.msg || '数据解析失败！'
    }
    // 401是登录信息有问题，自动注销当前账号
    if (error.response.status === 401) {
      if (VueCookies.isKey('AJ-DMS')) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
    message.error(errorShowMsg)
  }
  return Promise.reject(error)
}

// 发起请求request interceptor
request.interceptors.request.use(config => {
  // auth开头的接口不用统一的平台前缀 否则 其他接口需要传token
  config.headers['Authorization'] = 'Bearer ' + store.state.user.token
  if (config.url.indexOf('/auth/') >= 0 || config.url.indexOf('/api/') >= 0) {
    // 开发时
    if (process.env.NODE_ENV !== 'production') {
      config.baseURL = ''
    } else {
      // 生产时将dms替换掉  匹配最后出现的位置
      config.baseURL = config.baseURL.replace(/dms$/, '')
    }
  }
  // 因为upload组件和avatar的上传处理方式问题，所以upload方法不需要加密！//todo 丢defaultSettings去
  if (config.url.indexOf('/Users/changeAvatar') >= 0 || config.url.indexOf('/UploadFiles/uploadBase64File') >= 0) {
    return config
  }
  // 检测是否aes解密
  if (isAes === 'true') {
    // post和get获取参数的方式不同所以需要分开处理。。。
    if (config.method === 'get') {
      // 写给参数
      config.params = aesEncrypt(config.params)
    } else {
      config.data = aesEncrypt(config.data)
    }
  }
  return config
}, errorHandler)

// 请求响应结果response interceptor
request.interceptors.response.use((response) => {
  // 检测是否需要rsa验签！
  if (isRsa === 'true') {
    // false验证失败！true验证通过！
    let checkSign = false
    if (response.data.result !== undefined && response.data.sign !== undefined) {
      checkSign = verifySign(response.data.result, response.data.sign)
    }
    if (checkSign === false) {
      message.error('数据验证失败！')
    } else {
      // 如果只开启了rsa签名没有开启aes加解密的话则需要处理返回结果，因为后端生成签名时会将结果处理成字符串
      if (isAes !== 'true') {
        return JSON.parse(response.data.result)
      }
    }
  }
  // 检测是否aes解密
  if (isAes === 'true') {
    // false解密失败
    let decoding = false
    if (response.data.result !== undefined) {
      decoding = aesDecrypt(response.data.result)
    }
    if (decoding === false) {
      message.error('数据解析失败！')
    }
    return decoding
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
