<template>
  <a-drawer
    width="500"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="medicalDrawerVisibleCu"
    title="病史及用药信息修改"
    @close="closeMedicalDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <form-create
        id="medical-info"
        v-model="cformMedicalApi"
        :rule="cformMedicalRule"
        :option="cformOption"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="8"
          :span="12"
        >
          <a-button
            icon="close"
            @click="closeMedicalDrawer"
            type="default"
            class="mr-5"
          >关闭</a-button>
          <a-button
            icon="check"
            @click="clickSave"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { savePatientMedicalInfo } from '@/api/dms/patient'
import { getSelectListTag } from '@/api/manage'

export default ({
  name: 'MedicalInfoDrawer',
  props: {
    medicalDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    medicalDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.medicalDrawerVisibleCu = this.medicalDrawerVisible
        if (this.medicalDrawerVisible) {
          this.spinning = true

          // 获取已有信息
          this.loadPatientOriData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      medicalDrawerVisibleCu: false,
      cformMedicalApi: {},
      cformMedicalRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'select',
          title: '疾病信息',
          field: 'disease',
          props: { mode: 'tags', placeholder: '请选择疾病信息', allowClear: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择疾病信息' }
          ]
        },
        {
          type: 'input',
          title: '其他疾病及备注',
          field: 'disease_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他疾病信息及备注' }
        },
        {
          type: 'input',
          title: '现病史及服药信息',
          field: 'medication',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写现病史及服药信息' }
        },
        {
          type: 'input',
          title: '既往病史',
          field: 'personal_history',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写既往病史' }
        },
        {
          type: 'input',
          title: '家族病史',
          field: 'family_history',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写家族病史' }
        },
        {
          type: 'input',
          title: '其他备注说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注说明' }
        }
      ],
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      params: {}
    }
  },
  created () {

  },
  methods: {
    loadPatientOriData () {
      getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['1'] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cformMedicalApi.updateRules({ // 更新规则
            'disease': { options: res.data }
          })
          // 有传入原数据才需要回显
          if (this.parentParams.oriInfo.id !== undefined && this.parentParams.oriInfo.id > 0) {
            this.cformMedicalApi.setValue(this.parentParams.oriInfo)
          } else {
            this.cformMedicalApi.setValue({ patient_id: this.parentParams.patient })
          }
        }
        this.spinning = false
      })
      // this.$nextTick(() => {
      //   this.cformMedicalApi.updateRules({ // 更新规则
      //     'disease': { options: this.$options.filters.selectOptionsData('patient.disease') }
      //   })
      //   // 有传入原数据才需要回显
      //   if (this.parentParams.oriInfo.id !== undefined && this.parentParams.oriInfo.id > 0) {
      //     this.cformMedicalApi.setValue(this.parentParams.oriInfo)
      //   } else {
      //     this.cformMedicalApi.setValue({ patient_id: this.parentParams.patient })
      //   }
      //   this.spinning = false
      // })
    },
    closeMedicalDrawer () {
      this.medicalDrawerVisibleCu = false
      this.$emit('medicalDrawerVisibleChange', false)
    },
    clickSave () {
      this.cformMedicalApi.validate((valid, fail) => {
        if (valid) {
          this.spinning = true
          savePatientMedicalInfo(Object.assign({}, this.cformMedicalApi.formData())).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.cformMedicalApi.resetFields()
              this.closeMedicalDrawer()
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })

        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
</style>