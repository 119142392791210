<template>
  <food-composition-list>
  </food-composition-list>
</template>

<script>
import { FoodCompositionList } from '@/components/FoodComposition'

export default {
  name: 'ListFoodComposition',
  components: {
    FoodCompositionList,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {

  },
  methods: {

  }
}
</script>
