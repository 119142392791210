<template>

  <!-- Layout Footer -->
  <a-layout-footer>
    <a-row>
      <a-col :span="24">

        <!-- Footer Copyright Notice -->
        <p class="text-center">
          2022 - <span>{{ this.$store.state.app.year }}</span> &copy; 版权所有 | <span v-text="this.$store.state.app.company"></span> <span>{{ this.$store.state.app.companyEng }}</span>
        </p>
        <!-- / Footer Copyright Notice -->

      </a-col>
    </a-row>
  </a-layout-footer>
  <!-- / Layout Footer -->

</template>

<script>
export default {
  name: 'BasicFooter',
  data () {
    return {
      // year: '',
      // company: '',
      // companyEng: ''
    }
  },
  computed: {
    // year () {
    //   return localStorage.year !== undefined ? localStorage.year.replace(/"/g, '') : ''
    // },
    // company () {
    //   return localStorage.company !== undefined ? localStorage.company.replace(/"/g, '') : ''
    // },
    // companyEng () {
    //   return localStorage.companyEng !== undefined ? localStorage.companyEng.replace(/"/g, '') : ''
    // }
  },
  created () {
    // this.year = localStorage.year !== undefined ? localStorage.year.replace(/"/g, '') : ''
    // this.company = localStorage.company !== undefined ? localStorage.company.replace(/"/g, '') : ''
    // this.companyEng = localStorage.companyEng !== undefined ? localStorage.companyEng.replace(/"/g, '') : ''
  }
}
</script>
