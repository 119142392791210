<template>
  <!-- 个人信息 Card -->
  <a-card
    class="header-solid h-full card-profile-information"
    :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
    :headStyle="{paddingRight: 0,}"
  >
    <template #title>
      <h6 class="font-semibold m-0">个人信息</h6>
    </template>
    <template slot="extra">
      <div>
        <a-button
          type="link"
          :disabled="loadingPersonal"
          @click="refreshPersonalOriInfo()"
        >
          刷新
        </a-button>
        <a-button
          type="link"
          class="ml-10 mr-10"
          :disabled="loadingPersonal"
          @click="clickEditPersonal()"
          v-if="Object.keys(personalInfo).length > 0"
        >
          编辑
        </a-button>
        <a-button
          type="link"
          class="ml-10 mr-10"
          :disabled="loadingPersonal"
          @click="clickEditPersonal()"
          v-else
        >
          添加
        </a-button>
      </div>
    </template>

    <a-spin
      :tip="tip"
      :spinning="loadingPersonal"
    >
      <div v-if="Object.keys(personalInfo).length > 0"> <a-descriptions
          title=""
          :column="1"
        >
          <a-descriptions-item label="最近状况">{{ personalInfo.recent_status }}</a-descriptions-item>

          <a-descriptions-item label="诊疗原因">{{ personalInfo.reason }}</a-descriptions-item>

          <a-descriptions-item label="期望">{{ personalInfo.expectation }}</a-descriptions-item>

          <a-descriptions-item label="目标">
            <a-tag
              color="green"
              v-for="tag in personalInfo.goalArray"
              :key="tag.id"
            >
              {{tag.tag_text}}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="备注">{{ personalInfo.goal_remarks }}</a-descriptions-item>
        </a-descriptions>

        <hr>

        <a-descriptions
          title=""
          :column="1"
          v-if="Object.keys(personalInfo).length > 0"
        >
          <a-descriptions-item label="婚姻情况">{{ 'patient.marriage' | showText(personalInfo.marriage.toString()) }}
            <span class="ml-10"> {{ personalInfo.marriage_remarks!== null ? "("+ personalInfo.marriage_remarks + ")" : ""}} </span>
          </a-descriptions-item>

          <a-descriptions-item label="睡觉时间">
            {{ personalInfo.sleep_start_time !== undefined && personalInfo.sleep_start_time !== null ? personalInfo.sleep_start_time.substr(0,5) : '' }}
            ~
            {{ personalInfo.sleep_end_time !== undefined && personalInfo.sleep_end_time !== null ? personalInfo.sleep_end_time.substr(0,5) : ''}}
          </a-descriptions-item>
          <a-descriptions-item label="睡眠质量">{{ 'patient.sleep' | showText(personalInfo.sleep.toString()) }}
            <span class="ml-10"> {{ personalInfo.sleep_remarks!== null ? "("+ personalInfo.sleep_remarks + ")" : ""}} </span>
          </a-descriptions-item>

          <a-descriptions-item label="抽烟情况">{{ 'patient.smoker' | showText(personalInfo.smoker.toString()) }}
            <span class="ml-10"> {{ personalInfo.smoker_remarks!== null ? "("+ personalInfo.smoker_remarks + ")" : ""}} </span>
          </a-descriptions-item>

          <a-descriptions-item label="喝酒情况">{{ 'patient.alcohol' | showText(personalInfo.alcohol.toString()) }}
            <span class="ml-10"> {{ personalInfo.alcohol_remarks!== null ? "("+ personalInfo.alcohol_remarks + ")" : ""}} </span>
          </a-descriptions-item>

          <a-descriptions-item label="排便习惯">{{ 'patient.defecation' | showText(personalInfo.defecation.toString()) }}
            <span class="ml-10"> {{ personalInfo.defecation_remarks!== null ? "("+ personalInfo.defecation_remarks + ")" : ""}} </span>
          </a-descriptions-item>

          <a-descriptions-item label="身体状况">{{ personalInfo.physical_remarks }}</a-descriptions-item>
          <a-descriptions-item label="其他生活习惯">{{ personalInfo.other_remarks }}</a-descriptions-item>
        </a-descriptions>
      </div>

      <a-empty v-else>
      </a-empty>
    </a-spin>

    <!-- 生活习惯修改抽屉 -->
    <personal-info-drawer
      :parentParams="patPersonalParam"
      :personalDrawerVisible="personalDrawerVisible"
      @personalDrawerVisibleChange="personalDrawerVisibleChange"
    />
  </a-card>
  <!-- / 个人信息 Card -->

</template>

<script>
import { getPatientPersonalInfo } from '@/api/dms/patient'
import PersonalInfoDrawer from './PersonalInfoDrawer'

export default ({
  name: 'CardPersonalInfo',
  components: { PersonalInfoDrawer },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      tip: '加载中...',
      loadingPersonal: false,
      personalInfo: {},
      personalDrawerVisible: false,
      patPersonalParam: {}
    }
  },
  created () {
    this.refreshPersonalOriInfo()
  },
  methods: {
    refreshPersonalOriInfo () {
      this.loadingPersonal = true
      // 加载个人生活习惯数据
      getPatientPersonalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['goal'] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.personalInfo = res.data
          this.personalInfo.marriage = this.personalInfo.marriage !== null ? this.personalInfo.marriage.toString() : ''
          this.personalInfo.defecation = this.personalInfo.defecation !== null ? this.personalInfo.defecation.toString() : ''
          this.personalInfo.sleep = this.personalInfo.sleep !== null ? this.personalInfo.sleep.toString() : ''
          this.personalInfo.smoker = this.personalInfo.smoker !== null ? this.personalInfo.smoker.toString() : ''
          this.personalInfo.alcohol = this.personalInfo.alcohol !== null ? this.personalInfo.alcohol.toString() : ''
        }
        this.loadingPersonal = false
      })
    },
    clickEditPersonal () {
      this.patPersonalParam = { patient: this.parentParams.patient, oriInfo: { ...this.personalInfo } }
      this.personalDrawerVisible = true
    },
    personalDrawerVisibleChange (v) {
      this.personalDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.refreshPersonalOriInfo()
      }
    },
  }
})

</script>