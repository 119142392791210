<template>
  <a-spin
    tip="处理中..."
    size="small"
    :spinning="spinning"
  >
    <a-row
      :gutter="24"
      type="flex"
      class="layout-dashboard"
      align="stretch"
    >
      <a-col
        :span="24"
        class="mb-10"
      >
        <a-card
          :bordered="false"
          class="header-solid card-profile-information"
          :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
          :headStyle="{paddingRight: 0,}"
        >
          <a-row
            type="flex"
            align="middle"
            justify="space-around"
          >
            <a-col
              :span="24"
              type="flex"
            >
              <a-row
                type="flex"
                justify="space-between"
              >
                <!-- <strong>总热量</strong> -->
                <strong>总热量<span
                    v-if="this.dailyFoodMenuDetail.calory !== undefined && parseFloat(this.dailyFoodMenuDetail.calory) - parseFloat(maxCaloryNum) > 0"
                    class="font-error"
                  >(超过建议上限)</span></strong>
                <span> {{ this.dailyFoodMenuDetail.calory !== undefined ? this.dailyFoodMenuDetail.calory : ''}} / {{maxCaloryNum}}(千卡)</span>
              </a-row>
              <a-progress
                size="small"
                :percent="percentCalory"
                :show-info="false"
                :format="() => dailyFoodMenuDetail.calory"
                :status="statusCalory"
                class="mb-10"
              />
            </a-col>
            <a-col class="text-center"><strong>碳水</strong><br> {{ dailyFoodMenuDetail.carbohydrate === undefined ? '' : dailyFoodMenuDetail.carbohydrate + ' g' }} </a-col>
            <a-col>
              <div style="width: 2px; height: 20px; border-left: 1px solid #e8e8e8 ;"></div>
            </a-col>
            <a-col class="text-center"><strong>蛋白质</strong><br> {{ dailyFoodMenuDetail.protein === undefined ? '' : dailyFoodMenuDetail.protein + ' g' }} </a-col>
            <a-col>
              <div style="width: 2px; height: 20px; border-left: 1px solid #e8e8e8 ;"></div>
            </a-col>
            <a-col class="text-center"><strong>脂肪</strong><br> {{dailyFoodMenuDetail.fat === undefined ? '' : dailyFoodMenuDetail.fat + ' g' }} </a-col>
          </a-row>
          <a-row>
            <canvas
              ref="dailyFoodMenuViewChartCanvas"
              class="chart-doughnut"
              :style="{'height': height + 'px'}"
            ></canvas>
          </a-row>
          <!-- <hr class="my-5"> -->
        </a-card>
      </a-col>

      <a-col
        :span="24"
        class="mb-10"
      >
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
        >
          <strong>微量元素</strong>
          <a-table
            :columns="columns"
            :showHeader="false"
            :data-source="nutrientList"
            :pagination="false"
            rowKey="name"
            size="small"
          >
            <div
              slot="count"
              slot-scope="text, record"
              class="text-right"
            >
              {{ text }} {{ record.unit }}
            </div>
          </a-table>
        </a-card>
      </a-col>

    </a-row>
  </a-spin>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

// const nutrientInfo = [
//   {
//     index: 1,
//     type: 1,
//     text: '重量',
//     column: 'weight',
//     count: 0,
//     unit: '克',
//     display: ''
//   },
//   {
//     type: 1,
//     text: '热量',
//     column: 'calory',
//     count: 0,
//     unit: '千卡',
//     display: ''
//   },
//   {
//     type: 1,
//     text: '热量',
//     column: 'joule',
//     count: 0,
//     unit: '千焦',
//     display: ''
//   },
//   {
//     type: 1,
//     text: '蛋白质',
//     column: 'protein',
//     count: 0,
//     unit: '克',
//     display: ''
//   },
//   {
//     type: 1,
//     text: '脂肪',
//     column: 'fat',
//     count: 0,
//     unit: '克',
//     display: ''
//   },
//   {
//     type: 1,
//     text: '碳水化合物',
//     column: 'carbohydrate',
//     count: 0,
//     unit: '克',
//     display: ''
//   },
//   {
//     type: 2,
//     text: '膳食纤维',
//     column: 'fiber_dietary',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '糖',
//     column: 'sugar',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '饱和脂肪',
//     column: 'saturated_fat',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '反式脂肪',
//     column: 'fatty_acid',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '单不饱和脂肪',
//     column: 'mufa',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '多不饱和脂肪',
//     column: 'pufa',
//     count: 0,
//     unit: '克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '胆固醇',
//     column: 'cholesterol',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '酒精度',
//     column: 'alcohol',
//     count: 0,
//     unit: '%vol',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素A',
//     column: 'vitamin_a',
//     count: 0,
//     unit: '微克RAE',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '胡萝卜素',
//     column: 'carotene',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素B1',
//     column: 'thiamine',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素B2',
//     column: 'lactoflavin',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素B6',
//     column: 'vitamin_b6',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素B12',
//     column: 'vitamin_b12',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素C',
//     column: 'vitamin_c',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素D',
//     column: 'vitamin_d',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素E',
//     column: 'vitamin_e',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '维生素K',
//     column: 'vitamin_k',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '烟酸',
//     column: 'niacin',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '叶酸',
//     column: 'folacin',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '泛酸',
//     column: 'pantothenic',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '生物素',
//     column: 'biotin',
//     count: 0,
//     unit: '微克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '胆碱',
//     column: 'choline',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '钠',
//     column: 'natrium',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '磷',
//     column: 'phosphor',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '钾',
//     column: 'kalium',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '镁',
//     column: 'magnesium',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '钙',
//     column: 'calcium',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '铁',
//     column: 'iron',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '锌',
//     column: 'zinc',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '碘',
//     column: 'iodine',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '硒',
//     column: 'selenium',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '铜',
//     column: 'copper',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '氟',
//     column: 'fluorine',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
//   {
//     type: 2,
//     text: '锰',
//     column: 'manganese',
//     count: 0,
//     unit: '毫克',
//     display: 'none'
//   },
// ]

export default {
  name: 'DailyFoodMenuViewChart',
  data () {
    return {
      spinning: false,
      chart: null,
      chartData: {},
      height: 200,
      percentCalory: 180,
      maxCaloryNum: 3200,
      // statusCaloryArr: ['success', 'normal', 'active', 'exception'],
      statusCalory: 'success',
      nutrientList: [],
      columns: [
        {
          title: '营养元素',
          width: 55,
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '摄入量',
          width: 55,
          dataIndex: 'count',
          scopedSlots: { customRender: 'count' }
        },
      ]
    }
  },
  props: {
    dailyFoodMenuDetail: {
      type: Object,
      default: null
    },
    maxCalory: {
      type: Number,
      default: 3200
    }
  },
  watch: {
    dailyFoodMenuDetail: {
      handler (newVal, oldVal) {
        this.refreshChart()
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  mounted () {
    this.refreshChart()

  },
  unmounted: function () {
    this.chart.destroy()
  },
  methods: {
    refreshChart () {
      this.spinning = true
      if (this.chart !== null) {
        this.chart.destroy()
      }
      this.maxCaloryNum = (this.maxCalory === undefined || this.maxCalory === null || this.maxCalory === '') ? 3200 : this.maxCalory
      // 总热量进度条先设置个上限，后面看情况，根据客户的信息来改
      if (this.dailyFoodMenuDetail.calory !== undefined) {
        this.percentCalory = Math.round(this.dailyFoodMenuDetail.calory / this.maxCaloryNum * 100)
      }
      if (this.percentCalory >= 100) {
        this.statusCalory = 'exception'
      } else {
        this.statusCalory = 'success'
      }

      // 三大营养素
      Object.assign(this.chartData,
        {
          '碳水': this.dailyFoodMenuDetail.carbohydrate,
          '蛋白质': this.dailyFoodMenuDetail.protein,
          '脂肪': this.dailyFoodMenuDetail.fat,
        })

      let ctx = this.$refs.dailyFoodMenuViewChartCanvas.getContext("2d")
      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: Object.keys(this.chartData),
          datasets: [{
            label: "Projects",
            weight: 9,
            cutout: 40,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            backgroundColor: ['#FADB14', '#1890FF', '#F5222D'],
            data: Object.values(this.chartData),
            fill: false
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            }
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              }
            },
          },
        },
      })

      // 微量元素列表
      this.nutrientList = []
      if (this.dailyFoodMenuDetail.other_nutrition_info !== undefined) {
        // nutrientInfo.forEach((nu) => {
        // if (nu.type === 2) {
        const nutrientMapAll = this.$options.filters.getDicTextArrByKey('nutrient.map', 'type', [1])
        nutrientMapAll.map(nu => {
          this.nutrientList.push({
            column: nu.column,
            name: nu.text,
            count: '0.0',
            unit: nu.unit
          })
        })

        //   }
        // })

        let nutrientData = typeof this.dailyFoodMenuDetail.other_nutrition_info === 'object' ? JSON.parse(JSON.stringify(this.dailyFoodMenuDetail.other_nutrition_info)) : JSON.parse(this.dailyFoodMenuDetail.other_nutrition_info)
        let that = this
        Object.keys(nutrientData).forEach(function (key) {
          if (!key.includes('_unit')) {
            that.nutrientList.forEach((nu) => {
              if (nu.column === key) {
                nu.count = nutrientData[key] !== '' ? nutrientData[key] : '0.0'
              }
            })
          }
        })
      }


      this.spinning = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
