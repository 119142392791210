<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="当前状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="请选择当前状态"
                    :options="'emall_goods_sku.status' | selectOptionsData()"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入展示货名、实际货名搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="4"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.listEmallGoodsSkuTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = { })"
                  >重置</a-button>
                  <router-link
                    :to="{path:'/EmallGoods/addShopGoodsSku',query:$route.query}"
                    :hidden="'/EmallGoods/addShopGoodsSku' | isShowButton"
                  >
                    <a-button
                      type="primary"
                      icon="plus"
                    >新增商品SKU</a-button>
                  </router-link>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <s-table
          ref="listEmallGoodsSkuTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'emall_goods.status' | showText(text)"
            />
          </span>

          <span
            slot="emall_goods_sku_spec"
            slot-scope="text"
            :title="showValTitle(text)"
          >
            <span v-if="text !== undefined && text.length !== undefined && text.length > 0">
              <a-badge
                style="margin-right:5px;"
                v-for="(tv,i1) in text"
                :key="i1"
                status="success"
                :text="'【'+tv.spec_name+'】'+tv.spec_val"
              />
            </span>
          </span>

          <div
            slot="feature_image"
            slot-scope="text, record"
            style="max-width:25px;height:25px;cursor:pointer;"
          >
            <img
              v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.feature_image_show"
              @click="handlePreview(record.feature_image_show)"
            />
          </div>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EmallGoods/editShopGoodsSku' | isShowButton">
                <a @click="clickEdit(record)">编辑</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/EmallGoods/changeShopGoodsSku'] | isShowSeparator(['/EmallGoods/editShopGoodsSku'])"
              />
              <a-popconfirm
                :hidden="'/EmallGoods/changeShopGoodsSku' | isShowButton"
                title="确认修改该状态？"
                @confirm="clickChangeStatus(record)"
              >
                <a
                  class="font-success"
                  v-if="record.status !== 20"
                >可售</a>
                <a
                  class="font-error"
                  v-else
                >售罄</a>
              </a-popconfirm>
            </template>
          </span>
        </s-table>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="货品主图"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEmallGoodSku, changeShopGoodsSku } from '@/api/dms/emallGoodSku'

export default {
  name: 'EmallGoodsSkuList',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      typeListData: [],
      previewVisible: false,
      previewImg: '',
      columns: [
        {
          title: '#',
          width: 80,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '展示货名',
          width: 160,
          dataIndex: 'em_goods_name',
          fixed: 'left'
        },
        {
          title: '货品类型',
          ellipsis: true,
          width: 95,
          dataIndex: 'goods_type_name'
        },
        {
          title: '当前状态',
          ellipsis: true,
          width: 100,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '实际货名',
          width: 160,
          ellipsis: true,
          dataIndex: 'goods_name'
        },
        {
          title: '规格值',
          ellipsis: true,
          dataIndex: 'emall_goods_sku_spec',
          scopedSlots: { customRender: 'emall_goods_sku_spec' }
        },
        {
          title: '货品图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'feature_image' }
        },
        {
          title: '上架库存数',
          ellipsis: true,
          width: 105,
          dataIndex: 'inventory'
        },
        {
          title: '销量',
          ellipsis: true,
          width: 95,
          dataIndex: 'sell_count'
        },
        {
          title: '成本价',
          ellipsis: true,
          width: 95,
          dataIndex: 'cost_price'
        },
        {
          title: '销售标价',
          ellipsis: true,
          width: 95,
          dataIndex: 'unit_price'
        },
        {
          title: '实际售价',
          ellipsis: true,
          width: 95,
          dataIndex: 'promotion_unit_price'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listEmallGoodSku(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: 1600 }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      }
    }
  },
  created () {
    if (this.$route.query.keyword !== undefined && this.$route.query.keyword !== '') {
      const Base64 = require('js-base64').Base64
      let keyword = Base64.decode(this.$route.query.keyword)
      keyword = keyword.split('(=)')
      if (keyword[0] !== undefined && keyword[0] !== '') {
        this.queryParam.search = keyword[0]
      }
    }
  },
  filters: {
    statusTypeFilter (type) {
      if (type === 20) {
        return 'success'
      }
      if (type === 10) {
        return 'error'
      }
      return 'warning'
    }
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('EmallGoods.editShopGoodsSku', JSON.stringify({ gsku: record.id }))
      this.$router.push({ path: '/EmallGoods/editShopGoodsSku' })
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    clickChangeStatus (record) {
      const cstatus = record.status !== 20 ? 20 : 10
      // 修改状态
      if ([10, 20].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      this.tip = '处理中...'
      this.spinning = true
      changeShopGoodsSku({ gsku: record.id, cstatus, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.tip = '加载中...'
        this.$refs.listEmallGoodsSkuTable.refresh(true)
        this.spinning = false
      })
    },
    showValTitle (vals) {
      const showVal = []
      if (vals.length !== undefined && vals.length > 0) {
        vals.forEach(vv => {
          showVal.push('【' + vv.spec_name + '】' + vv.spec_val)
        })
      }
      return showVal.join(',')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-badge-status-text {
  margin-left: 3px !important;
}
</style>
