<template>
  <div id="settings">
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-row
        type="flex"
        :gutter="[24,24]"
      >

        <a-col :span="24">
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <!-- 基本信息 card -->
            <h5 class=" font-semibold">客户基础信息</h5>

            <div class="ml-20 mb-10">
              <a-descriptions
                :title="null"
                v-if="patientInfo.id !== undefined"
                :column="6"
              >
                <a-descriptions-item label="客户姓名">{{ patientInfo.name }}</a-descriptions-item>
                <a-descriptions-item label="性别">{{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}</a-descriptions-item>
                <a-descriptions-item label="编号">{{ patientInfo.patient_no }}</a-descriptions-item>

                <a-descriptions-item label="出生日期">{{ patientInfo.birth_date }}</a-descriptions-item>
                <a-descriptions-item label="联系电话">{{ patientInfo.contact }}</a-descriptions-item>
                <a-descriptions-item label="身份证号">{{ patientInfo.id_card }}</a-descriptions-item>
                <a-descriptions-item
                  label="关注问题"
                  :span="6"
                >
                  <a-tag
                    color="orange"
                    v-for="tag in patientInfo.his_patient_tag"
                    :key="tag.tag_id"
                  >
                    {{tag.tag_text}}
                  </a-tag>
                </a-descriptions-item>
              </a-descriptions>
            </div>

            <a-collapse :bordered="false">
              <a-collapse-panel
                key="1"
                v-if="bodyInfo.length > 0"
              >
                <span
                  slot="header"
                  class="font-bold"
                >最新体格指标</span>
                <a-descriptions
                  title=""
                  :column="6"
                >
                  <template v-for="(bv,bi) in bodyInfo">
                    <a-descriptions-item
                      :key="bi"
                      :label="bv.item_name"
                    >{{ bv.result_val + ' ' + bv.unit_name }}</a-descriptions-item>
                  </template>
                </a-descriptions>
              </a-collapse-panel>

              <a-collapse-panel
                key="2"
                v-if="medicalInfo.id !== undefined"
              >
                <span
                  slot="header"
                  class="font-bold"
                >病史及用药信息</span>
                <a-descriptions
                  title=""
                  :column="2"
                >
                  <a-descriptions-item label="疾病信息">
                    <a-tag
                      color="orange"
                      v-for="(mdv,mdi) in medicalInfo.disease"
                      :key="mdi"
                    >
                      {{ 'patient.disease' | showText(mdv.toString()) }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="疾病信息备注">{{ medicalInfo.disease_remarks }}</a-descriptions-item>

                  <a-descriptions-item label="现病史及用药信息">{{ medicalInfo.medication }}</a-descriptions-item>
                  <a-descriptions-item label="既往病史">{{ medicalInfo.personal_history }}</a-descriptions-item>

                  <a-descriptions-item label="家族病史">{{ medicalInfo.family_history }}</a-descriptions-item>
                  <a-descriptions-item label="其他备注">{{ medicalInfo.other_remarks }}</a-descriptions-item>
                </a-descriptions>
              </a-collapse-panel>

              <a-collapse-panel
                key="3"
                v-if="dietaryInfo.id !== undefined"
              >
                <span
                  slot="header"
                  class="font-bold"
                >饮食习惯</span>
                <a-descriptions
                  title=""
                  :column="4"
                >
                  <a-descriptions-item
                    label="饮食类型"
                    :span="2"
                  >
                    <a-tag
                      color="blue"
                      v-for="(mdv,mdi) in dietaryInfo.diet_type"
                      :key="mdi"
                    >
                      {{ 'patient.diet_type' | showText(mdv.toString()) }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="其他饮食类型">{{ dietaryInfo.diet_type_remarks }}</a-descriptions-item>
                  <a-descriptions-item label="日饮水量">{{ dietaryInfo.water_intake > 0 ? dietaryInfo.water_intake +' 升' : '' }} </a-descriptions-item>

                  <a-descriptions-item
                    label="喜欢的食物"
                    :span="2"
                  >{{ dietaryInfo.food_favorite }}</a-descriptions-item>
                  <a-descriptions-item
                    label="不喜欢的食物"
                    :span="2"
                  >{{ dietaryInfo.food_dislike }}</a-descriptions-item>

                  <a-descriptions-item
                    label="过敏食物"
                    :span="2"
                  >{{ dietaryInfo.allergies_remarks }}</a-descriptions-item>
                  <a-descriptions-item
                    label="不耐受食物"
                    :span="2"
                  >{{ dietaryInfo.food_intolerant_remarks }}</a-descriptions-item>

                  <a-descriptions-item
                    label="缺乏营养"
                    :span="2"
                  >{{ dietaryInfo.nutrition_deficiency_remarks }}</a-descriptions-item>
                  <a-descriptions-item
                    label="其他饮食习惯备注"
                    :span="2"
                  >{{ dietaryInfo.other_remarks }}</a-descriptions-item>
                </a-descriptions>
              </a-collapse-panel>

              <a-collapse-panel
                key="4"
                v-if="personalInfo.id !== undefined"
              >
                <span
                  slot="header"
                  class="font-bold"
                >生活习惯</span>
                <a-descriptions
                  title=""
                  :column="9"
                >
                  <a-descriptions-item label="婚姻情况">{{ 'patient.marriage' | showText(personalInfo.marriage.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    label="婚姻备注"
                    :span="2"
                  >{{ personalInfo.marriage_remarks }}</a-descriptions-item>
                  <a-descriptions-item label="睡眠质量">{{ 'patient.sleep' | showText(personalInfo.sleep.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    label="睡觉时间"
                    :span="2"
                  >{{ personalInfo.sleep_start_time +' ~ '+ personalInfo.sleep_end_time }}</a-descriptions-item>
                  <a-descriptions-item
                    label="睡眠质量备注"
                    :span="3"
                  >{{ personalInfo.sleep_remarks }}</a-descriptions-item>

                  <a-descriptions-item label="抽烟情况">{{ 'patient.smoker' | showText(personalInfo.smoker.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    label="抽烟备注"
                    :span="2"
                  >{{ personalInfo.smoker_remarks }}</a-descriptions-item>
                  <a-descriptions-item label="喝酒情况">{{ 'patient.alcohol' | showText(personalInfo.alcohol.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    label="喝酒备注"
                    :span="2"
                  >{{ personalInfo.alcohol_remarks }}</a-descriptions-item>
                  <a-descriptions-item label="排便习惯">{{ 'patient.defecation' | showText(personalInfo.defecation.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    label="排便习惯备注"
                    :span="2"
                  >{{ personalInfo.defecation_remarks }}</a-descriptions-item>

                  <a-descriptions-item
                    label="身体状况"
                    :span="4"
                  >{{ personalInfo.physical_remarks }}</a-descriptions-item>
                  <a-descriptions-item
                    label="其他生活习惯备注"
                    :span="5"
                  >{{ personalInfo.other_remarks }}</a-descriptions-item>
                </a-descriptions>
              </a-collapse-panel>
            </a-collapse>
            <!-- / 基本信息 -->

            <h5 class="mt-20 font-semibold">问诊信息录入</h5>
            <a-collapse
              accordion
              class="mt-20"
              @change="collapseChange"
            >
              <a-collapse-panel key="1">
                <span
                  slot="header"
                  class="font-bold"
                >预约目的及期望</span>
                <form-create
                  v-model="cformAppointApi"
                  :rule="cformAppointRule"
                  :option="cformOption"
                >
                </form-create>
                <a-row>
                  <a-col
                    :offset="11"
                    :span="12"
                  >
                    <a-button
                      icon="check"
                      @click="clickAppointSave"
                      type="primary"
                    >确认保存</a-button>
                  </a-col>
                </a-row>
              </a-collapse-panel>

              <a-collapse-panel key="2">
                <span
                  slot="header"
                  class="font-bold"
                >营养处方</span>
                <form-create
                  id="nutrition-info"
                  v-model="cformNutritionApi"
                  :rule="cformNutritionRule"
                  :option="cformOption"
                >
                </form-create>
                <a-row>
                  <a-col
                    :offset="9"
                    :span="12"
                  >
                    <a-button
                      icon="plus"
                      type="danger"
                      ghost
                      @click="clickAddSport"
                    >
                      继续添加运动内容
                    </a-button>

                    <a-button
                      icon="check"
                      @click="clickNutritionSave"
                      type="primary"
                      class="ml-10"
                    >确认保存</a-button>
                  </a-col>
                </a-row>
              </a-collapse-panel>

              <a-collapse-panel key="3">
                <span
                  slot="header"
                  class="font-bold"
                >随访计划</span>
                <form-create
                  id="followup-info"
                  v-model="cformFollowupApi"
                  :rule="cformFollowupRule"
                  :option="cformOption"
                >
                </form-create>
                <a-row>
                  <a-col
                    :offset="11"
                    :span="12"
                  >
                    <a-button
                      icon="check"
                      @click="clickFollowSave"
                      type="primary"
                    >确认保存</a-button>
                  </a-col>
                </a-row>
              </a-collapse-panel>

              <a-collapse-panel key="4">
                <span
                  slot="header"
                  class="font-bold"
                >指标检测计划</span>
                <form-create
                  id="monitor-info"
                  v-model="cformMonitorApi"
                  :rule="cformMonitorRule"
                  :option="cformOption"
                >
                </form-create>
                <a-row>
                  <a-col
                    :offset="11"
                    :span="12"
                  >
                    <a-button
                      icon="check"
                      @click="clickMonitorSave"
                      type="primary"
                    >确认保存</a-button>
                  </a-col>
                </a-row>
              </a-collapse-panel>
            </a-collapse>
          </a-card>
        </a-col>
      </a-row>

      <!-- 饮食模式的抽屉窗口 -->
      <dietary-pattern-detail
        :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
        :parentParams="dietaryPatternParams"
        @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
      />
    </a-spin>
  </div>
</template>

<script>
import { getPatientNewestBodyInfo, getPatientInfo, getPatientMedicalInfo, getPatientDietaryInfo, getPatientPersonalInfo, savePatientAppointInfo, getPatientAppointInfo, savePatientNutritionInfo, getPatientNutritionInfo } from '@/api/dms/patient'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { savePatientServiceRecordOnly, getServiceRecordInfo } from '@/api/dms/serviceRecord'
import { getNewestNotDealAppointmentInfo } from '@/api/dms/clientAppointment'
import { savePatientFollowPlanInfo, getPatientFollowPlanInfo } from '@/api/dms/followup'
import { savePatientMonitorRecordInfo, getPatientMonitorRecordInfo } from '@/api/dms/monitorRecord'
import { DietaryPatternDetail } from '@/components/DietaryPattern'

export default {
  props: ['navbarFixed'],
  components: {
    DietaryPatternDetail
  },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      patientInfo: {},
      cformAppointApi: {},
      cformNutritionApi: {},
      cformFollowupApi: {},
      cformMonitorApi: {},
      //表单生成规则
      cformAppointRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'service_user_id',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'input',
          title: '预约原因',
          field: 'reason',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写预约原因' },
          validate: [
            { required: true, message: '请填写预约原因', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '期望达到效果',
          field: 'expectation',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写期望达到效果' },
          validate: [
            { required: true, message: '请填写期望达到效果', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '目标',
          field: 'goal',
          props: { placeholder: '请选择目标' },
          options: [],
          validate: [
            { required: true, message: '请选择目标' }
          ]
        },
        {
          type: 'input',
          title: '目标备注',
          field: 'goal_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写目标备注' }
        },
        {
          type: 'input',
          title: '其他说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他说明' }
        },
      ],
      cformNutritionRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'life_id'
        },
        {
          type: 'hidden',
          field: 'recipe_id'
        },
        {
          type: 'input',
          title: '营养评定',
          field: 'assessment',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养评定' },
          validate: [
            { required: true, message: '请填写营养评定', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '干预方法',
          field: 'intervention_method',
          props: { mode: 'multiple', placeholder: '请选择干预方法', maxTagCount: 1, maxTagTextLength: 7, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          // col: {
          //   span: 24
          // },
          // wrap: {
          //   labelCol: { span: 3 },
          //   wrapperCol: { span: 20 }
          // },
          validate: [
            { required: true, message: '请选择干预方法' }
          ],
          on: {
            change: (e) => this.interventionMethodChange(e)
          }
        },
        {
          type: 'select',
          title: '饮食模式推荐',
          hidden: true,
          field: 'dietary_pattern_id',
          props: { mode: 'multiple', placeholder: '请选饮食模式推荐', maxTagCount: 1, maxTagTextLength: 7, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 15 }
          }
        },
        {
          type: 'a-button',
          class: 'ant-col-3 mt-5',
          name: 'a-button',
          hidden: true,
          props: { icon: 'file-protect', type: 'primary' },
          children: ['查看饮食模式详情'],
          on: {
            click: () => this.clickshowPattern()
          }
        },
        {
          type: 'input',
          title: '推荐方案',
          field: 'recommendation',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写推荐方案' }
        },
        {
          type: 'select',
          title: '一周运动量',
          field: 'calorie_calc',
          props: { placeholder: '请选一周运动量' },
          options: [],
          on: {
            change: (v) => this.calorieCalcChange(v)
          }
        },
        {
          type: 'input',
          title: '热量摄入建议',
          field: 'calorie', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写热量摄入建议', min: 0, step: 1, max: 1000000, addonAfter: 'Kcal' }
        },
        {
          type: 'slider',
          title: '碳水摄入建议',
          field: 'carbohydrates',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },//tooltipVisible: true,
          props: { placeholder: '请选择碳水摄入建议', min: 0, step: 1, max: 100, marks: { 55: '55%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'carbohydrates')
          }
        },
        {
          type: 'slider',
          title: '蛋白质摄入',
          field: 'protein',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },
          props: { placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, marks: { 15: '15%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'protein')
          }
        },
        {
          type: 'slider',
          title: '脂肪摄入建议',
          field: 'fat',
          col: {
            span: 8
          },
          wrap: {
            labelCol: { span: 9 },
            wrapperCol: { span: 12 }
          },
          props: { placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, marks: { 30: '30%' }, tipFormatter: (v) => { return `${v}%` } },
          on: {
            afterChange: (v) => this.cpfChange(v, 'fat')
          }
        },
        // {55%的碳水化合物、15%的蛋白质和30%的脂肪。
        //   type: 'input',
        //   title: '碳水摄入建议',
        //   field: 'carbohydrates', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写碳水摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        // {
        //   type: 'input',
        //   title: '蛋白质摄入',
        //   field: 'protein', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写蛋白质摄入', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        // {
        //   type: 'input',
        //   title: '脂肪摄入建议',
        //   field: 'fat', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写脂肪摄入建议', min: 0, step: 1, max: 100, addonAfter: '%' }
        // },
        {
          type: 'input',
          title: '营养补充剂',
          field: 'supplement',
          col: {
            span: 24
          },
          hidden: true,
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写营养补充剂' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['一日食谱制定']
        },
        {
          type: 'input',
          title: '早餐',
          field: 'breakfast',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早餐' }
        },
        {
          type: 'input',
          title: '早加餐',
          field: 'snack_morning',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写早加餐' }
        },
        {
          type: 'input',
          title: '午餐',
          field: 'lunch',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午餐' }
        },
        {
          type: 'input',
          title: '午加餐',
          field: 'snack_afternoon',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写午加餐' }
        },
        {
          type: 'input',
          title: '晚餐',
          field: 'supper',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'supper', autoSize: { minRows: 2 }, placeholder: '请填写晚餐' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['生活干预、运动计划']
        },
        {
          type: 'input',
          title: '生活干预',
          field: 'life_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写生活干预' }
        },
        {
          type: 'input',
          title: '运动计划',
          field: 'sport_plan',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动计划' }
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['运动内容推荐']
        },

      ],
      cformFollowupRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '随访方式',
          field: 'method',
          props: { placeholder: '请选择随访方式' },
          options: [],
          validate: [
            { required: true, message: '请选择随访方式' }
          ]
        },
        {
          type: 'input',
          title: '总次数',
          field: 'all_count', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写随访总次数', min: 0, step: 1, max: 1000, addonAfter: '次' },
          validate: [
            { required: true, message: '请填写随访总次数' }
          ]
        },
        {
          type: 'input',
          title: '随访周期',
          field: 'cycle',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写随访周期' },
          validate: [
            { required: true, message: '请填写随访周期', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写备注' }
        }
      ],
      cformMonitorRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '发现的问题',
          field: 'problem',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写发现的问题' }
        },
        {
          type: 'input',
          title: '解决方案',
          field: 'solution',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写解决方案' }
        },
        {
          type: 'a-radio-group',
          title: '是否有不良反应',
          value: '0',
          field: 'is_reaction',
          props: {
            size: "small", buttonStyle: 'solid', placeholder: '请选择是否有不良反应'
          },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['无']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['有']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '执行程度及调整',
          field: 'content',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写执行程度及调整' }
        }
      ],
      nutritionSportIdx: -1,
      sportFormRuleNum: 1,
      sportFormRuleNumArr: [],
      sportFormRuleLastField: '',
      sportFormRuleTmpArr: [
        {
          type: 'input',
          title: '运动方案',
          field: 'plan_content',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动方案' }
        },
        {
          type: 'input',
          title: '运动举例',
          field: 'example',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动举例' }
        },
        {
          type: 'input',
          title: '运动频率',
          field: 'frequency',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动频率' }
        },
        {
          type: 'input',
          title: '运动时间',
          field: 'duration',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写运动时间' }
        },
        {
          type: 'select',
          title: '运动方式',
          field: 'sport_type',
          props: { placeholder: '请选择运动方式' },
          options: []
        },
        {
          type: 'a-button',
          hidden: true,
          class: 'ant-col-2 mt-5',
          name: 'a-button',
          props: { icon: 'close', type: 'danger' },
          children: ['删除']
        },
        {
          type: 'hidden',
          title: '运动计划id',
          field: 'id'
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        }
      ],
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      cformAppointOption: { // 一列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
      bodyInfo: [],
      medicalInfo: {},
      dietaryInfo: {},
      personalInfo: {},
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
      cpfOri: { carbohydrates: 55, protein: 15, fat: 30 },
      dietaryPatternSelectArr: [],
      recordOri: { appoint_id: 0 },
      appointOri: {},
      nutritionOri: {},
      followupOri: {},
      monitorOri: {}
    }
  },
  watch: {
    nutritionSportIdx: {
      handler (newVal, oldVal) {
        if (this.nutritionSportIdx < 0 || this.nutritionOri.prescription_sport === undefined || this.nutritionOri.prescription_sport[this.nutritionSportIdx] === undefined) {
          this.spinning = false
          this.nutritionSportIdx = -1
          return false
        }
        // 回显多个运动计划
        let spfNum = this.sportFormRuleNum
        let spfNumMark = true
        if (this.sportFormRuleNumArr[this.nutritionSportIdx] !== undefined) { // 已有form-create组件，只需要回显字段值
          spfNum = this.sportFormRuleNumArr[this.nutritionSportIdx]
          spfNumMark = false
        }
        let setSportArr = {}
        let arrNum = 0
        const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
        cuTmpArr.map(spv => {
          let oriVal = this.nutritionOri.prescription_sport[this.nutritionSportIdx][spv.field]
          // 从第2个开始加删除按钮
          if (this.sportFormRuleNum > 1) {
            if (spv.field === 'sport_type') {
              spv.col = {
                span: 10
              }
              spv.wrap = {
                labelCol: { span: 6 },
                wrapperCol: { span: 16 }
              }
            }
            if (spv.type === 'a-button' && spv.name === 'a-button') {
              spv.hidden = false
              spv.on = {
                click: () => this.clickRemoveSport(spfNum)
              }
            }
          } else {
            if (spv.type === 'a-divider' && spv.name === 'a-divider') {
              spv.hidden = true
            }
          }
          if (spv.title !== undefined && spv.field !== undefined) {
            if (spv.field === 'sport_type') {
              spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
              oriVal = oriVal.toString()
            }
            spv.title = spv.title + '#' + spfNum
            spv.field = spv.field + '#' + spfNum
            if (this.setValMark || (!this.setValMark && spv.field !== 'id')) {
              // 按钮点击的回显组装运动计划的时候不回显id
              setSportArr[spv.field] = oriVal
            }
          }
          if (spfNumMark) {
            this.cformNutritionApi.append(spv, 'patient_id')
          }
          arrNum++
        })
        const timer = setInterval(() => {
          if (arrNum >= this.sportFormRuleTmpArr.length) {
            this.cformNutritionApi.setValue(setSportArr)
            if (spfNumMark) {
              this.sportFormRuleNumArr.push(this.sportFormRuleNum)
              this.sportFormRuleNum++
            }
            this.nutritionSportIdx++
            clearInterval(timer)
          }
        }, 100)
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.setServiceRecord'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.params.serviceRecord = 0
    this.spinning = true
    // 获取体格信息
    getPatientNewestBodyInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
      if (res2.status !== undefined && res2.status === 1) {
        this.bodyInfo = res2.data
      }
    })
    // 获取病史及用药信息
    getPatientMedicalInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.medicalInfo = res.data
        if (this.medicalInfo.disease !== undefined && this.medicalInfo.disease !== '' && this.medicalInfo.disease !== null) {
          this.medicalInfo.disease = JSON.parse(this.medicalInfo.disease)
        }
      }
    })
    // 饮食习惯
    getPatientDietaryInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.dietaryInfo = res.data
        this.dietaryInfo.diet_type = JSON.parse(this.dietaryInfo.diet_type)
      }
    })
    // 生活习惯
    getPatientPersonalInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.personalInfo = res.data
      }
    })
    // 获取饮食模式下拉数据
    selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1) {
        this.dietaryPatternSelectArr = res.data
      }
    })
    // 获取客户基础信息
    getPatientInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient) {
        this.patientInfo = res.data
        // 获取目标期望数据
        this.loadOriAppointInfo()
        // 判断是否需要获取服务数据
        this.loadOriServiceInfo()
      } else {
        this.spinning = false
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch((e) => {
      console.log(e)
      return this.$router.go(-1)
    })
  },
  methods: {
    loadOriServiceInfo () {
      // 检测是否有原记录id，没有原记录才需要去判断预约记录id
      if (this.params.record !== undefined && this.params.record > 0) {
        // 获取主记录数据 
        const getRecParam = { patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id, id: this.params.record }
        getServiceRecordInfo(getRecParam).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.recordOri = res.data
            // 数据获取成功则加载相关的营养方案，加载完了才能加载后面的随访计划
            this.loadOriNutritionInfo(false, true)
          } else {
            this.$message.error(res.msg || '获取原始信息失败！请刷新后重试！')
          }
        })
      } else {
        // 检测是否有预约记录id
        if (this.params.appoint !== undefined && this.params.appoint > 0) {
          // 比如首页会传入预约记录ID则直接赋值
          this.$nextTick(() => {
            this.recordOri.appoint_id = this.params.appoint
            this.spinning = false
          })
        } else {
          // 客户是否有预约信息最新的未处理预约记录
          getNewestNotDealAppointmentInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, dep: this.$store.getters.userInfo.user.department_id, user: this.$store.getters.userInfo.user.id }).then((res) => {
            this.spinning = false
            if (res.status !== undefined && res.status === 1) {
              this.recordOri.appoint_id = res.data.id
            }
          })
        }
      }
    },
    loadOriMoreInfo () {
      // 加载随访计划数据
      getPatientFollowPlanInfo({ patient: this.params.patient, prescription_no: this.nutritionOri.prescription_no }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          res.data.method = res.data.method.toString()
          this.followupOri = res.data
        }
      })
      // 加载指标监测计划数据
      getPatientMonitorRecordInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1 && res2.data.id !== undefined && res2.data.id > 0) {
          res2.data.is_reaction = res2.data.is_reaction.toString()
          this.monitorOri = res2.data
        }

        this.spinning = false
      })
    },
    clickAddSport () {
      this.renderSportFormData()
    },
    renderSportFormData () {
      this.spinning = true
      let arrNum = 0
      const cuTmpArr = JSON.parse(JSON.stringify(this.sportFormRuleTmpArr))
      cuTmpArr.map(spv => {
        // 从第二个运动计划开始就要显示删除按钮
        if (this.sportFormRuleNum > 1) {
          if (spv.field === 'sport_type') {
            spv.col = {
              span: 10
            }
            spv.wrap = {
              labelCol: { span: 6 },
              wrapperCol: { span: 16 }
            }
          }
          if (spv.type === 'a-button' && spv.name === 'a-button') {
            spv.hidden = false
            const cuspnum = parseInt(this.sportFormRuleNum)
            spv.on = {
              click: () => this.clickRemoveSport(cuspnum)
            }
          }
        } else {
          if (spv.type === 'a-divider' && spv.name === 'a-divider') {
            spv.hidden = true
          }
        }
        if (spv.title !== undefined && spv.field !== undefined) {
          if (spv.field === 'sport_type') {
            spv.options = this.$options.filters.selectOptionsData('patient.activity_type')
          }
          spv.title = spv.title + '#' + this.sportFormRuleNum
          spv.field = spv.field + '#' + this.sportFormRuleNum
        }
        this.cformNutritionApi.append(spv, 'patient_id')
        arrNum++
      })
      const timer = setInterval(() => {
        if (arrNum >= this.sportFormRuleTmpArr.length) {
          this.sportFormRuleNumArr.push(this.sportFormRuleNum)
          // this.sportFormRuleLastField = this.sportFormRuleTmpArr[this.sportFormRuleTmpArr.length - 1].field
          this.sportFormRuleNum++
          // this.cformNutritionApi.refresh()
          clearInterval(timer)
          this.spinning = false
        }
      }, 100)
    },
    clickRemoveSport (cuext) {
      const spArrIdx = this.sportFormRuleNumArr.indexOf(cuext)
      if (spArrIdx < 0) {
        this.$message.error('请正确选择要删除的运动计划！')
        return false
      }
      // 找到id#字段，从前一个分割线字段开始往后找到plan_content#的结束位置，都删除
      let startIdx = false
      let endIdx = false
      const cuRulesArr = JSON.parse(JSON.stringify(this.cformNutritionApi.rule))
      const rmArr = []
      cuRulesArr.map((nrv, nridx) => {
        if (nrv.field !== undefined && nrv.field === 'id#' + cuext) {
          startIdx = nridx
        }
        if (nrv.field !== undefined && nrv.field === 'plan_content#' + cuext) {
          endIdx = nridx
        }
        if (nrv.field !== undefined) {
          const farr = nrv.field.split('#')
          if (farr.length === 2 && parseInt(farr[1]) === parseInt(cuext)) {
            rmArr.push(nrv.field)
          }
        }
      })
      if (startIdx === false || endIdx === false || startIdx > endIdx) {
        this.$message.error('请确认选择要删除的运动计划存在！')
        return false
      }
      const cuSpId = this.cformNutritionApi.getValue('id#' + cuext)
      this.spinning = true
      if (cuSpId !== undefined && cuSpId > 0) {
        removePatientPrescriptionSport({ id: cuSpId, service_record: this.recordOri.id, patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, prescription_no: this.nutritionOri.prescription_no }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
            this.spinning = false
          }
        })
      } else {
        this.rmSportFormField(cuRulesArr, rmArr, startIdx, endIdx, spArrIdx)
      }
    },
    rmSportFormField (cuRulesArr, rmArr, startIdx, endIdx, spArrIdx) {
      // 先设置没有field字段的数据为hidden隐藏，再删除有field的字段，因为rule在删除字段后索引有变化，同时进行会有问题
      let ridx = (startIdx - 1)
      for (ridx; ridx <= endIdx; ridx++) {
        if (cuRulesArr[ridx].field === undefined) {
          this.cformNutritionApi.rule[ridx].hidden = true
        }
      }
      const timer = setInterval(() => {
        if (ridx > endIdx) {
          rmArr.map(rmv => {
            this.cformNutritionApi.removeField(rmv)
          })
          clearInterval(timer)
        }
      }, 100)
      delete this.sportFormRuleNumArr[spArrIdx]
      this.sportFormRuleNumArr.splice(spArrIdx, 1)
      this.$message.success('删除成功！')
      this.spinning = false
    },
    clickshowPattern () {
      const cuPattern = this.cformNutritionApi.getValue('dietary_pattern_id')
      if (cuPattern === undefined || cuPattern < 0) {
        this.$message.error('请先选择左侧饮食模式推荐！')
        return false
      }
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    calorieCalcChange (v) {
      console.log(this.bodyInfo)
      // 写个中间默认值
      let cuBmr = 1600
      for (let bfn in this.bodyInfo) {
        if (bfn.indexOf('bmr') >= 0) {
          cuBmr = bodyFormData[bfn]
        }
      }
      // 根据选择的运动量和bmr计算推荐热量
      this.cformNutritionApi.setValue({ calorie: parseInt(cuBmr * v) })
    },
    cpfChange (v, field) {
      // 另外两个字段同步自动计算
      if (this.cpfOri[field] === undefined) {
        return false
      }
      const upNuRulesArr = {}
      const upNuValuesArr = {}
      upNuRulesArr[field] = { props: { marks: { [v]: v + '%' } } }
      upNuValuesArr[field] = v
      // 用差值先计算小值，再用100%计算剩下的值
      const diff = v - this.cpfOri[field]
      // 当前字段增加变大，另外两个字段就这个差值的平均后减小
      // const diff1 = Math.ceil((v - this.cpfOri[field]) / 2)
      // const diff2 = Math.floor((v - this.cpfOri[field]) / 2)
      // const diffMin = Math.abs(diff1) <= Math.abs(diff2) ? diff1 : diff2
      // const diffMax = Math.abs(diff1) >= Math.abs(diff2) ? diff1 : diff2
      const otherCpfArr = JSON.parse(JSON.stringify(this.cpfOri))
      delete otherCpfArr[field]
      const valArr = Object.values(otherCpfArr)
      const keyArr = Object.keys(otherCpfArr)
      // 比较剩余哪个字段的默认值大，对应减小diffMax
      // 第一个大为默认
      // let key0Val = valArr[0] - diffMax
      // let key1Val = valArr[1] - diffMin
      let key0Val = 1
      let key1Val = 1
      // 按剩余2个字段的百分比计算小值
      if (valArr[0] <= valArr[1]) {
        const minNum = Math.floor(valArr[0] / (valArr[0] + valArr[1]) * diff)
        key0Val = valArr[0] - minNum
        key1Val = 100 - v - key0Val
      } else {
        const minNum = Math.floor(valArr[1] / (valArr[0] + valArr[1]) * diff)
        key1Val = valArr[1] - minNum
        key0Val = 100 - v - key1Val
      }
      key0Val = key0Val < 0 ? 0 : key0Val
      key0Val = key0Val > 100 ? 100 : key0Val
      key1Val = key1Val < 0 ? 0 : key1Val
      key1Val = key1Val > 100 ? 100 : key1Val

      upNuRulesArr[keyArr[0]] = { props: { marks: { [key0Val]: key0Val + '%' } } }
      upNuRulesArr[keyArr[1]] = { props: { marks: { [key1Val]: key1Val + '%' } } }
      this.cformNutritionApi.updateRules(upNuRulesArr)
      this.cformNutritionApi.setValue({ [keyArr[0]]: key0Val, [keyArr[1]]: key1Val, field: v })
    },
    collapseChange (key) {
      this.$nextTick(() => {
        if (key === '1') {
          this.cformAppointApi.updateRules({ // 更新规则
            'goal': { options: this.$options.filters.selectOptionsData('patient.appoint_goal') }
          })
          // 有原数据回显
          if (this.appointOri.id !== undefined && this.appointOri.id > 0) {
            this.cformAppointApi.setValue({ id: this.appointOri.id, reason: this.appointOri.reason, expectation: this.appointOri.expectation, goal: this.appointOri.goal.toString(), goal_remarks: this.appointOri.goal_remarks, other_remarks: this.appointOri.other_remarks })
          }
        }
        if (key === '2') {
          this.cformNutritionApi.setValue(this.cpfOri)
          this.cformNutritionApi.updateRules({ // 更新规则
            'intervention_method': { options: this.$options.filters.selectOptionsData('prescription_nutrition.intervention_method') },
            'calorie_calc': { options: this.$options.filters.selectOptionsData('calorie.calc') },
            'dietary_pattern_id': { options: this.dietaryPatternSelectArr }
          })
          if (this.sportFormRuleNum === 1) {
            this.renderSportFormData()
          }
          this.resetNutritionFormData()
        }
        if (key === '3') {
          this.cformFollowupApi.updateRules({ // 更新规则
            'method': { options: this.$options.filters.selectOptionsData('followup.method') },
          })
          // 有原数据回显
          if (this.followupOri.id !== undefined && this.followupOri.id > 0) {
            this.cformFollowupApi.setValue(this.followupOri)
          }
        }
        if (key === '4') {
          // 有原数据回显
          if (this.monitorOri.id !== undefined && this.monitorOri.id > 0) {
            this.cformMonitorApi.setValue(this.monitorOri)
          }
        }
      })
    },
    loadOriAppointInfo () {
      // 加载目标期望数据
      getPatientAppointInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.appointOri = res.data
        }
      })
    },
    resetNutritionFormData () {
      // 回显
      if (this.nutritionOri.id !== undefined && this.nutritionOri.id > 0) {
        let setNutArr = JSON.parse(JSON.stringify(this.nutritionOri))
        // todo字段改后处理饮食模式回显
        setNutArr.dietary_pattern_id = JSON.parse(setNutArr.dietary_pattern_id)
        delete setNutArr.prescription_sport
        // setNutArr.intervention_method = setNutArr.intervention_method.toString()
        setNutArr.intervention_method = (setNutArr.intervention_method !== null && setNutArr.intervention_method !== '') ? setNutArr.intervention_method.split(',') : []
        // 触发干预方法的修改事件
        this.interventionMethodChange(setNutArr.intervention_method)
        this.cformNutritionApi.setValue(setNutArr)
        // 处理碳水蛋白脂肪
        setNutArr.carbohydrates = setNutArr.carbohydrates <= 0 ? 0 : setNutArr.carbohydrates
        setNutArr.protein = setNutArr.protein <= 0 ? 0 : setNutArr.protein
        setNutArr.fat = setNutArr.fat <= 0 ? 0 : setNutArr.fat
        this.cformNutritionApi.updateRules({
          carbohydrates: { props: { marks: { [setNutArr.carbohydrates]: setNutArr.carbohydrates + '%' } } },
          protein: { props: { marks: { [setNutArr.protein]: setNutArr.protein + '%' } } },
          fat: { props: { marks: { [setNutArr.fat]: setNutArr.fat + '%' } } }
        })
        // watch触发开始自动增加回显数据
        this.nutritionSportIdx = 0
      }
    },
    loadOriNutritionInfo (setValMark = false, loadNext = false) {
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.nutritionOri = resNut.data
          if (setValMark) {
            // 保存后需要重置回显数据
            this.resetNutritionFormData()
            this.spinning = false
          }
          if (loadNext) {
            this.loadOriMoreInfo()
          }
        } else {
          this.spinning = false
        }
      })
    },
    clickAppointSave () {
      this.cformAppointApi.validate((valid2, fail2) => {
        if (valid2) {
          // 没有服务记录id的则需要先保存服务再写当前保存数据
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.addNewServiceRecord(1)
          } else {
            this.spinning = true
            this.tip = '处理中...'
            const cuAptData = this.cformAppointApi.formData()
            savePatientAppointInfo(Object.assign({ patient_id: this.params.patient }, cuAptData)).then((res) => {
              if (res.status !== undefined && res.status === 1) {
                this.$message.success('保存成功！')
                Object.assign(this.appointOri, cuAptData)
                this.cformAppointApi.setValue({ id: res.data.id })
              } else {
                this.$message.error(res.msg || '服务信息保存失败！请刷新后重试！')
              }
              this.spinning = false
            })
          }
        } else {
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    clickNutritionSave () {
      this.cformNutritionApi.validate((valid2, fail2) => {
        if (valid2) {
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.addNewServiceRecord(2)
          } else {
            this.spinning = true
            this.tip = '处理中...'
            const sameUserArr = { service_record_id: this.recordOri.id, patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id }
            const cuNurData = this.cformNutritionApi.formData()
            savePatientNutritionInfo(Object.assign({}, sameUserArr, cuNurData)).then((res) => {
              if (res.status !== undefined && res.status === 1) {
                this.$message.success('保存成功！')
                Object.assign(this.nutritionOri, cuNurData, res.data)
                this.loadOriNutritionInfo(true)
              } else {
                this.$message.error(res.msg || '保存失败！请刷新后重试！')
              }
              this.spinning = false
            })
          }
        } else {
          this.$message.error('请确认营养方案必填项填写完成!！')
        }
      })
    },
    clickFollowSave () {
      if (this.nutritionOri.prescription_no === undefined || this.nutritionOri.prescription_no === null || this.nutritionOri.prescription_no === '') {
        this.$message.error('请确认先保存营养处方信息！')
        return false
      }
      this.cformFollowupApi.validate((valid, fail) => {
        if (valid) {// 没有服务记录id的则需要先保存服务再写当前保存数据
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.addNewServiceRecord(3)
          } else {
            this.spinning = true
            this.tip = '处理中...'
            const cuFolData = this.cformFollowupApi.formData()
            savePatientFollowPlanInfo(Object.assign({ prescription_no: this.nutritionOri.prescription_no, patient_id: this.params.patient }, cuFolData)).then((res) => {
              if (res.status !== undefined && res.status === 1) {
                this.$message.success('保存成功！')
                Object.assign(this.followupOri, cuFolData)
                this.cformFollowupApi.setValue({ id: res.data.id })
              } else {
                this.$message.error(res.msg || '保存失败！请刷新后重试！')
              }
              this.spinning = false
            })
          }
        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    },
    clickMonitorSave () {
      this.cformMonitorApi.validate((valid2, fail2) => {
        if (valid2) {
          // 没有服务记录id的则需要先保存服务再写当前保存数据
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.addNewServiceRecord(4)
          } else {
            this.spinning = true
            this.tip = '处理中...'
            const cuMonData = this.cformMonitorApi.formData()
            savePatientMonitorRecordInfo(Object.assign({ service_record_id: this.recordOri.id, patient_id: this.params.patient }, cuMonData)).then((res) => {
              if (res.status !== undefined && res.status === 1) {
                this.$message.success('保存成功！')
                Object.assign(this.monitorOri, cuMonData)
                this.cformMonitorApi.setValue({ id: res.data.id })
              } else {
                this.$message.error(res.msg || '保存失败！请刷新后重试！')
              }
              this.spinning = false
            })
          }
        } else {
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    addNewServiceRecord (nextMark = 0) {
      nextMark = parseInt(nextMark)
      this.spinning = true
      this.tip = '处理中...'
      savePatientServiceRecordOnly({ patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id, client_appoint_id: this.recordOri.appoint_id }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.recordOri.id = res.data.record
          if (nextMark === 1) {
            this.clickAppointSave()
          }
          if (nextMark === 2) {
            this.clickNutritionSave()
          }
          if (nextMark === 3) {
            this.clickFollowSave()
          }
          if (nextMark === 4) {
            this.clickMonitorSave()
          }
        } else {
          this.$message.error(res.msg || '服务信息保存失败！请刷新后重试！')
        }
      })
    },
    interventionMethodChange (e) {
      let supplementHide = true
      let dietaryHide = true
      this.cformNutritionApi.rule[6].hidden = true
      if (e.indexOf('1') >= 0) {
        dietaryHide = false
        this.cformNutritionApi.rule[6].hidden = false
      }
      if (e.indexOf('2') >= 0) {
        supplementHide = false
      }
      this.cformNutritionApi.updateRules({ // 更新规则
        'supplement': { hidden: supplementHide },
        'dietary_pattern_id': { hidden: dietaryHide }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
}
</style>