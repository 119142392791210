<template>
  <div id="settings">
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="spinning"
    >
      <div
        class="profile-nav-bg"
        style="background-image: url('/images/bg-patient.jpg')"
      ></div>

      <a-card
        :bordered="false"
        class="card-profile-head"
        :bodyStyle="{padding: 0,}"
      >
        <template #title>
          <a-row
            type="flex"
            align="middle"
          >
            <a-col
              :span="24"
              :md="10"
              class="col-info"
            >
              <a-avatar
                :size="74"
                shape="square"
                :src="'/images/avatar-' +  patientInfo.gender  + '.jpg'"
              />
              <div class="avatar-info">
                <h4 class="font-semibold m-0">{{ patientInfo.name }}</h4>
                <p>{{ 'ryx.gender' | showText(patientInfo.gender) }} / 出生日期：{{ patientInfo.birth_date }} / 联系电话：{{ patientInfo.contact_phone }}</p>
              </div>
            </a-col>
            <a-col
              :span="24"
              :md="6"
              class="col-info"
            >
              <div class="avatar-info">
                <p class="mt-15">客户编号：{{ patientInfo.patient_no }}</p>
                <p>所属机构：{{ patientInfo.enterprise_name }}</p>
              </div>
            </a-col>
            <a-col
              :span="24"
              :md="8"
            >
              <div class="avatar-info">
                <p>客户画像：
                  <a-tag
                    color="orange"
                    v-for="tag in patientInfo.his_patient_tag"
                    :key="tag.tag_id"
                  >
                    {{tag.tag_text}}
                  </a-tag>
                </p>
              </div>
            </a-col>
          </a-row>
        </template>
      </a-card>

      <div class="text-right mb-10 mt--10">
        <a-button
          v-if="params.service_type >= 10 && params.fm > 0"
          type="primary"
          icon="file-text"
          @click="clickFoodDetail"
        >查看食谱详情</a-button>

        <!-- 食谱详情的弹窗 -->
        <CardFoodmenuOneModal ref="cardFoodmenuOneModal" />
      </div>

      <div class="kanban-page">
        <card-service-record-one :currentServiceRecord="currentServiceRecord"></card-service-record-one>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getPatientInfo } from '@/api/dms/patient'
import { CardServiceRecordOne, CardFoodmenuOneModal } from "@/components/PatientCards"

export default {
  name: 'ViewServiceRecord',
  components: {
    CardServiceRecordOne,
    CardFoodmenuOneModal
  },
  data () {
    return {
      cuTabKey: 'serviceRecordTab',
      patientInfo: {},
      currentServiceRecord: {},
      spinning: false
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthServiceRecords.viewServiceRecord'))
    if (this.params === null || this.params.id === undefined || this.params.id < 1) {
      this.$message.error('请确认选择诊疗记录！')
      return this.$router.go(-1)
    }
    console.log(this.params)
    // 获取数据
    this.loadPatientInfo()
  },
  methods: {
    loadPatientInfo () {
      this.spinning = true
      getPatientInfo({ patient: this.params.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient_id) {
          this.currentServiceRecord = this.params
          // console.log(res.data)
          this.patientInfo = res.data
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
      }).catch((e) => {
        return this.$router.go(-1)
      })
    },
    clickFoodDetail () {
      this.$refs.cardFoodmenuOneModal.showModal({ id: this.params.fm })
    }
  }
}
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
  .step-box {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ghost-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 1px solid #4299e1;
  }
}
.mt--10 {
  margin-top: -10px;
}
</style>