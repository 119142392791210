<template>
  <a-drawer
    width="600"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="archiveDrawerVisibleCu"
    title="客户档案查看/管理"
    @close="closeArchiveDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="archiveSpinning"
    >
      <div>
        <h6>最新体格指标
          <a-button
            type="primary"
            size="small"
            class="flot-right"
            icon="form"
            @click="clickEditbody()"
          >修改</a-button>
        </h6>
        <div v-if="bodyInfo.length > 0">
          <a-descriptions
            title=""
            :column="3"
          >
            <template v-for="(bv,bi) in bodyInfo">
              <a-descriptions-item
                :key="bi"
                :label="bv.item_name"
              >{{ bv.result_val + ' ' + bv.unit_name }}</a-descriptions-item>
            </template>
          </a-descriptions>
        </div>

        <a-empty
          v-else
          description="暂无体格指标相关数据"
        />
        <a-divider class="my-15" />
      </div>

      <div>
        <h6>病史及用药信息
          <a-button
            type="primary"
            size="small"
            class="flot-right"
            icon="form"
            @click="clickEditMedical()"
          >修改</a-button>
        </h6>
        <div v-if="medicalInfo.id !== undefined && medicalInfo.id > 0">
          <a-descriptions
            title=""
            :column="1"
          >
            <a-descriptions-item label="疾病信息">
              <template v-for="(mdv,mdi) in medicalInfo.disease">
                <a-tag
                  color="orange"
                  :key="mdi"
                  v-if="tagArr[mdv.toString()] !== undefined"
                >
                  {{ tagArr[mdv.toString()] }}
                </a-tag>
              </template>
            </a-descriptions-item>
            <a-descriptions-item label="疾病信息备注">{{ medicalInfo.disease_remarks }}</a-descriptions-item>

            <a-descriptions-item label="现病史及用药信息">{{ medicalInfo.medication }}</a-descriptions-item>
            <a-descriptions-item label="既往病史">{{ medicalInfo.personal_history }}</a-descriptions-item>

            <a-descriptions-item label="家族病史">{{ medicalInfo.family_history }}</a-descriptions-item>
            <a-descriptions-item label="其他备注">{{ medicalInfo.other_remarks }}</a-descriptions-item>
          </a-descriptions>
        </div>

        <a-empty
          v-else
          description="暂无病史及用药信息相关数据"
        />
        <a-divider class="my-15" />
      </div>

      <div>
        <h6>饮食习惯
          <a-button
            type="primary"
            size="small"
            class="flot-right"
            icon="form"
            @click="clickEditDietary()"
          >修改</a-button>
        </h6>
        <div v-if="dietaryInfo.id !== undefined && dietaryInfo.id > 0">
          <a-descriptions
            title=""
            :column="2"
          >
            <a-descriptions-item
              label="喜好饮食类型"
              :span="2"
            >
              <a-tag
                color="blue"
                v-for="(mdv,mdi) in dietaryInfo.favorite_diet_type"
                :key="mdi"
              >
                {{ 'patient.favorite_diet_type' | showText(mdv.toString()) }}
              </a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="喜好饮食类型备注">{{ dietaryInfo.favorite_diet_type_remarks }}</a-descriptions-item>
            <a-descriptions-item label="日饮水量">{{ dietaryInfo.water_intake > 0 ? dietaryInfo.water_intake +' 升' : '' }} </a-descriptions-item>

            <a-descriptions-item label="喜欢的食物">{{ dietaryInfo.food_favorite }}</a-descriptions-item>
            <a-descriptions-item label="不喜欢的食物">{{ dietaryInfo.food_dislike }}</a-descriptions-item>

            <a-descriptions-item label="过敏食物">{{ dietaryInfo.allergies_remarks }}</a-descriptions-item>
            <a-descriptions-item label="不耐受食物">{{ dietaryInfo.food_intolerant_remarks }}</a-descriptions-item>

            <a-descriptions-item label="缺乏营养">{{ dietaryInfo.nutrition_deficiency_remarks }}</a-descriptions-item>
            <a-descriptions-item label="其他饮食习惯备注">{{ dietaryInfo.other_remarks }}</a-descriptions-item>
          </a-descriptions>
        </div>

        <a-empty
          v-else
          description="暂无饮食习惯相关数据"
        />
        <a-divider class="my-15" />
      </div>

      <div>
        <h6>生活习惯
          <a-button
            type="primary"
            size="small"
            class="flot-right"
            icon="form"
            @click="clickEditPersonal()"
          >修改</a-button>
        </h6>
        <div v-if="personalInfo.id !== undefined && personalInfo.id > 0">
          <a-descriptions
            title=""
            :column="3"
          >
            <a-descriptions-item label="婚姻情况">{{ 'patient.marriage' | showText(personalInfo.marriage.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              label="婚姻备注"
              :span="2"
            >{{ personalInfo.marriage_remarks }}</a-descriptions-item>

            <a-descriptions-item label="睡眠质量">{{ 'patient.sleep' | showText(personalInfo.sleep.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              label="睡觉时间"
              :span="2"
            >{{ personalInfo.sleep_start_time === null ? '' : personalInfo.sleep_start_time +' ~ '+ personalInfo.sleep_end_time }}</a-descriptions-item>
            <a-descriptions-item
              label="睡眠质量备注"
              :span="3"
            >{{ personalInfo.sleep_remarks }}</a-descriptions-item>

            <a-descriptions-item label="抽烟情况">{{ 'patient.smoker' | showText(personalInfo.smoker.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              label="抽烟备注"
              :span="2"
            >{{ personalInfo.smoker_remarks }}</a-descriptions-item>

            <a-descriptions-item label="喝酒情况">{{ 'patient.alcohol' | showText(personalInfo.alcohol.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              label="喝酒备注"
              :span="2"
            >{{ personalInfo.alcohol_remarks }}</a-descriptions-item>

            <a-descriptions-item label="排便习惯">{{ 'patient.defecation' | showText(personalInfo.defecation.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              label="排便习惯备注"
              :span="2"
            >{{ personalInfo.defecation_remarks }}</a-descriptions-item>

            <a-descriptions-item
              label="身体状况"
              :span="3"
            >{{ personalInfo.physical_remarks }}</a-descriptions-item>
            <a-descriptions-item
              label="其他生活习惯备注"
              :span="3"
            >{{ personalInfo.other_remarks }}</a-descriptions-item>
          </a-descriptions>
        </div>

        <a-empty
          v-else
          description="暂无生活相关数据"
        />
      </div>

      <a-row class="mt-20">
        <a-col
          :offset="11"
          :span="12"
        >
          <a-button
            icon="close"
            @click="closeArchiveDrawer"
            type="default"
            class="mr-5"
          >关闭</a-button>
        </a-col>
      </a-row>

      <!-- 体格指标修改抽屉 -->
      <body-info-drawer
        :parentParams="patBodyParam"
        :bodyDrawerVisible="bodyDrawerVisible"
        @bodyDrawerVisibleChange="bodyDrawerVisibleChange"
      />

      <!-- 病史及用药信息修改抽屉 -->
      <medical-info-drawer
        :parentParams="patMedicalParam"
        :medicalDrawerVisible="medicalDrawerVisible"
        @medicalDrawerVisibleChange="medicalDrawerVisibleChange"
      />

      <!-- 饮食习惯修改抽屉 -->
      <dietary-info-drawer
        :parentParams="patDietaryParam"
        :dietaryDrawerVisible="dietaryDrawerVisible"
        @dietaryDrawerVisibleChange="dietaryDrawerVisibleChange"
      />

      <!-- 生活习惯修改抽屉 -->
      <personal-info-drawer
        :parentParams="patPersonalParam"
        :personalDrawerVisible="personalDrawerVisible"
        @personalDrawerVisibleChange="personalDrawerVisibleChange"
      />
    </a-spin>
  </a-drawer>
</template>

<script>
import { getPatientNewestBodyInfo, getPatientMedicalInfo, getPatientDietaryInfo, getPatientPersonalInfo } from '@/api/dms/patient'
import BodyInfoDrawer from './BodyInfoDrawer'
import MedicalInfoDrawer from './MedicalInfoDrawer'
import DietaryInfoDrawer from './DietaryInfoDrawer'
import PersonalInfoDrawer from './PersonalInfoDrawer'
import { getSelectListTag } from '@/api/manage'

export default ({
  name: 'PatientArchiveManage',
  components: { BodyInfoDrawer, MedicalInfoDrawer, DietaryInfoDrawer, PersonalInfoDrawer },
  props: {
    archiveDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    archiveDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.archiveDrawerVisibleCu = this.archiveDrawerVisible
        if (this.archiveDrawerVisible) {
          this.archiveSpinning = true
          this.bodyInfo = []
          this.medicalInfo = {}
          this.dietaryInfo = {}
          this.personalInfo = {}
          // 获取已有信息
          this.loadPatientOriData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      archiveSpinning: false,
      archiveDrawerVisibleCu: false,
      tagArr: {},
      bodyInfo: [],
      medicalInfo: {},
      dietaryInfo: {},
      personalInfo: {},
      bodyDrawerVisible: false,
      patBodyParam: {},
      patMedicalParam: {},
      medicalDrawerVisible: false,
      dietaryDrawerVisible: false,
      patDietaryParam: {},
      personalDrawerVisible: false,
      patPersonalParam: {}
    }
  },
  created () {

  },
  methods: {
    closeArchiveDrawer () {
      this.archiveDrawerVisibleCu = false
      this.$emit('archiveDrawerVisibleChange', false)
    },
    loadPatientOriData () {
      this.loadPersonalOriInfo()
      this.loadMedicalOriInfo()
      this.loadDietaryOriInfo()
      this.loadBodyOriInfo()
    },
    loadBodyOriInfo () {
      // 获取最新体格数据的时候走检测表
      getPatientNewestBodyInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1) {
          this.bodyInfo = res2.data
        }
        this.archiveSpinning = false
      })
    },
    loadMedicalOriInfo () {
      getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['1'] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          res.data.map(tv => {
            this.tagArr[tv.value] = tv.label
          })
        }
      })
      // 加载既往病史数据
      getPatientMedicalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.medicalInfo = res.data
          this.medicalInfo.disease = JSON.parse(this.medicalInfo.disease)
        }
        this.archiveSpinning = false
      })
    },
    loadDietaryOriInfo () {
      // 加载个人饮食习惯数据
      getPatientDietaryInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.dietaryInfo = res.data
          this.dietaryInfo.favorite_diet_type = JSON.parse(this.dietaryInfo.favorite_diet_type)
        }
        this.archiveSpinning = false
      })
    },
    loadPersonalOriInfo () {
      // 加载个人生活习惯数据
      getPatientPersonalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.personalInfo = res.data
          this.personalInfo.marriage = this.personalInfo.marriage.toString()
          this.personalInfo.defecation = this.personalInfo.defecation.toString()
          this.personalInfo.sleep = this.personalInfo.sleep.toString()
          this.personalInfo.smoker = this.personalInfo.smoker.toString()
          this.personalInfo.alcohol = this.personalInfo.alcohol.toString()
        }
        this.archiveSpinning = false
      })
    },
    bodyDrawerVisibleChange (v) {
      this.bodyDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.archiveSpinning = true
        this.loadBodyOriInfo()
      }
    },
    medicalDrawerVisibleChange (v) {
      this.medicalDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.archiveSpinning = true
        this.loadMedicalOriInfo()
      }
    },
    dietaryDrawerVisibleChange (v) {
      this.dietaryDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.archiveSpinning = true
        this.loadDietaryOriInfo()
      }
    },
    personalDrawerVisibleChange (v) {
      this.personalDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.archiveSpinning = true
        this.loadPersonalOriInfo()
      }
    },
    clickEditbody () {
      this.patBodyParam = { patient: this.parentParams.patient, bodyInfo: this.bodyInfo }
      if (this.parentParams.recordOri !== undefined && this.parentParams.recordOri > 0) {
        this.patBodyParam.recordOri = this.parentParams.recordOri
      }
      this.bodyDrawerVisible = true
    },
    clickEditMedical () {
      this.patMedicalParam = { patient: this.parentParams.patient, oriInfo: this.medicalInfo }
      this.medicalDrawerVisible = true
    },
    clickEditDietary () {
      this.patDietaryParam = { patient: this.parentParams.patient, oriInfo: this.dietaryInfo }
      this.dietaryDrawerVisible = true
    },
    clickEditPersonal () {
      this.patPersonalParam = { patient: this.parentParams.patient, oriInfo: this.personalInfo }
      this.personalDrawerVisible = true
    },
  }
})

</script>

<style lang="scss" scoped>
.flot-right {
  float: right;
}
</style>