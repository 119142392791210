<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="savePatientRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { updatePatientInfo, getPatientInfo } from '@/api/dms/patient'
import { checkMobile } from '@/utils/customValidate'
import { selectListUserByDep } from '@/api/dms/user'
import { getSelectListTag } from '@/api/manage'

export default {
  name: 'EditPatient',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_uid'
        },
        // {
        //   type: 'hidden',
        //   field: 'enterprise',
        //   value: this.$store.getters.userInfo.user.target_enterprise
        // },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'input',
          title: '客户姓名',
          field: 'name',
          props: {
            size: "small", placeholder: '请填写客户姓名'
          },
          validate: [
            { required: true, message: '请确认输入客户姓名', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '身份证号',
          field: 'id_card',
          props: {
            size: "small", placeholder: '请填写身份证号'
          },
          validate: [
            { required: true, message: '请确认输入身份证号', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '性别',
          value: '0',
          field: 'gender',
          props: {
            size: "small", buttonStyle: 'solid', placeholder: '请选择性别'
          },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['保密']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['男']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['女']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },

        {
          type: 'DatePicker',
          title: '出生年月',
          field: 'birth_date', style: { width: '100%' },
          props: {
            size: "small", placeholder: '请选择出生年月', type: 'date', allowClear: true
          },          // validate: [
          //   { required: true, message: '请填写出生年月' }
          // ]
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'contact_phone',
          props: {
            size: "small", placeholder: '请填写联系电话'
          },
          // validate: [
          //   { required: true, message: '请填写联系电话', whitespace: true },
          //   { validator: checkMobile }
          // ]
        },
        {
          type: 'input',
          title: '客户编号',
          field: 'patient_no',
          props: {
            size: "small", placeholder: '请填写客户编号'
          },
          validate: [
            { required: true, message: '请确认填写客户编号', whitespace: true }
          ]
        },
        // {
        //   type: 'input',
        //   title: 'BMI',
        //   field: 'bmi', style: { width: '100%' },
        //   props: { placeholder: '请填写身高、体重后自动计算，且保留两位小数。', disabled: true, addonAfter: 'Kg/㎡' }
        // },
        // {
        //   type: 'input',
        //   title: '身高',
        //   field: 'height', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写身高', min: 0, step: 0.01, max: 10000, addonAfter: 'Cm' },
        //   on: {
        //     change: () => this.whChange()
        //   }
        // },
        // {
        //   type: 'input',
        //   title: '体重',
        //   field: 'weight', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写体重', min: 0, step: 0.01, max: 10000, addonAfter: 'Kg' },
        //   on: {
        //     change: () => this.whChange()
        //   }
        // },
        {
          type: 'input',
          title: '客户来源',
          field: 'enterprise_name',
          props: {
            size: "small", disabled: true
          },
        },
        {
          type: 'select',
          title: '当前负责医生',
          field: 'incharge_doc',
          col: {
            span: 16
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: {
            size: "small", mode: 'multiple', placeholder: '请选择当前负责医生', allowClear: true, showSearch: true, optionFilterProp: 'children'
          },
          options: [],
          validate: [
            { required: true, message: '请选择当前负责医生' }
          ]
        },
        // {
        //   type: 'input',
        //   title: '血型ABO',
        //   field: 'blood_abo',
        //   props: { placeholder: '请填写血型ABO' }
        // },
        // {
        //   type: 'input',
        //   title: '血型RHD',
        //   field: 'blood_rhd',
        //   props: { placeholder: '请填写血型RHD' }
        // },
        {
          type: 'select',
          title: '客户画像',
          field: 'patient_tags',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 2 },
            wrapperCol: { span: 21 }
          },
          props: { size: "small", mode: 'tags', placeholder: '请选择标签', allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
      ],
      //组件参数配置
      cformOption: {
        form: {
          // layout: 'vertical'
        },
        col: {
          span: 8
        },
        // row: {
        //   gutter: 16
        // },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.editPatient'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    selectListUserByDep({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, user_type: 50 }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformApi.updateRules({ // 更新规则
          'incharge_doc': { options: res.data }
        })
      }
    })
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['0', '10', '20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformApi.updateRules({ // 更新规则
          'patient_tags': { options: res.data }
        })
      }
      this.spinning = false
    })
    // 获取数据
    getPatientInfo({ patient_uid: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag', 'doctor'] }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.patient_uid === this.params.patient) {
        console.log(res.data)
        res.data.gender = res.data.gender.toString()
        res.data.incharge_doc = []
        if (res.data.his_patient_doctor !== undefined && res.data.his_patient_doctor !== null) {
          res.data.his_patient_doctor.forEach(element => {
            res.data.incharge_doc.push(element.doctor_id.toString())
          })
        }
        res.data.patient_tags = []
        if (res.data.his_patient_tag !== undefined && res.data.his_patient_tag !== null) {
          res.data.his_patient_tag.forEach(element => {
            res.data.patient_tags.push(element.tag_id)
          })
        }
        this.cformApi.setValue(res.data)
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
      this.spinning = false
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    savePatientRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      updatePatientInfo(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '编写客户保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    // whChange () {
    //   let wh = this.cformApi.formData()
    //   if (wh.weight === undefined || wh.height === undefined || wh.weight === null || wh.height === null) {
    //     this.cformApi.setValue({ bmi: undefined })
    //     return false
    //   }
    //   wh.weight = wh.weight <= 0 ? 0 : parseFloat(wh.weight).toFixed(2)
    //   wh.height = wh.height <= 0 ? 0 : parseFloat(wh.height).toFixed(2)
    //   if (wh.weight <= 0 || wh.height <= 0) {
    //     this.cformApi.setValue({ bmi: 0, weight: wh.weight, height: wh.height })
    //     return false
    //   }
    //   const cuBmi = wh.weight / Math.pow((wh.height / 100), 2)
    //   this.cformApi.setValue({ bmi: cuBmi.toFixed(2), weight: wh.weight, height: wh.height })
    // }
  }
}

</script>

<style lang="scss" scoped>
</style>