<template>
  <EditFoodRecipe :recipe="params.recipe" />
</template>

<script>
import { EditFoodRecipe } from '@/components/FoodRecipes'

export default {
  name: 'EditFoodRecipePage',
  components: {
    EditFoodRecipe,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('FoodRecipes.editFoodRecipe'))
    if (this.params === null || this.params.recipe === undefined) {
      this.$message.error('请确认选择食谱！')
      return this.$router.go(-1)
    }
  },
  methods: {

  }
}
</script>
