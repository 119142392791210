<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>营养处方</span> -->
          <br>
          <!-- <span>Nutrition Prescription</span> -->
        </div>
        <div>
          <!-- <img
            class="nuContentLogo"
            src="/images/foodsense_logo.png"
          /> -->
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div class="nuContentWithSider">
        <div class="nuDivSectionTitle">
          <span>营养处方 <small>Nutrition Prescription</small></span>
        </div>

        <table class="analysisTable fontNuBase">
          <tbody v-if="nutritionInfo.id !== undefined">
            <tr>
              <td class="analysisTabletd1">
                营养评估
                <!-- <div
                  class="fontNuContentBaseEng"
                ></div> -->
              </td>
              <td colspan="5">{{ nutritionInfo.assessment }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1 height100">
                干预方法
              </td>
              <td
                colspan="5"
                class="height100"
              >{{ this.$options.filters.showText('prescription_nutrition.intervention_method',nutritionInfo.intervention_method) }}</td>
            </tr>
            <tr v-if="nutritionInfo.intervention_method.indexOf('1') >= 0">
              <td class="analysisTabletd1 height100">
                推荐饮食模式
              </td>
              <td
                colspan="5"
                class="height100"
              >{{ dietaryPatternName.join('、') }}</td>
            </tr>
            <tr v-if="nutritionInfo.intervention_method.indexOf('2') >= 0">
              <td class="analysisTabletd1">
                营养补充剂建议
              </td>
              <td colspan="5">{{ nutritionInfo.supplement }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1">
                推荐方案
              </td>
              <td colspan="5">{{ nutritionInfo.recommendation }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1 height100">
                热量摄入建议
              </td>
              <td
                colspan="2"
                class="height100"
              >{{ (nutritionInfo.calorie !== null && nutritionInfo.calorie !== '') ? nutritionInfo.calorie+' Kcal' : '' }}</td>
              <td class="analysisTabletd1 height100">
                建议日饮水量
              </td>
              <td
                colspan="2"
                class="height100"
              >{{ (nutritionInfo.advise_water_intake !== null && nutritionInfo.advise_water_intake !== '') ? nutritionInfo.advise_water_intake+' mL' : '' }}</td>
            </tr>
            <tr>
              <td class="analysisTabletd1 height100">
                碳水化合物摄入
              </td>
              <td class="height100">{{ (nutritionInfo.carbohydrates !== null && nutritionInfo.carbohydrates !== '') ? nutritionInfo.carbohydrates+' %' : '' }} </td>
              <td class="analysisTabletd1 height100">
                蛋白质摄入
              </td>
              <td class="height100">{{ (nutritionInfo.protein !== null && nutritionInfo.protein !== '') ? nutritionInfo.protein+' %' : '' }}</td>
              <td class="analysisTabletd1 height100">
                脂肪摄入
              </td>
              <td class="height100">{{ (nutritionInfo.fat !== null && nutritionInfo.fat !== '') ? nutritionInfo.fat+' %' : '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="nuContentRightSider">
        <div class="nuContentRightSiderText2">
          <div class="fontNuBase">营养处方</div>
          <div class="fontNuS4">Nutrition Prescription</div>
        </div>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import NutritionFooter from './NutritionFooter'

export default {
  name: 'NutritionPage4',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    nutritionInfo: {
      type: Object,
      default: null
    },
    dietaryPatternName: {
      type: Array,
      default: []
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {

    }
  },
  created () {
    // console.log(this.nutritionInfo)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nuContentRightSiderText2 {
  top: 650px;
}
</style>
