var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.tip,"size":"large","spinning":_vm.spinning}},[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('div',[_c('a-icon',{staticClass:"back-page-icon",attrs:{"title":"返回列表页","type":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('a-divider',{staticStyle:{"margin-top":"16px"}}),_c('a-form',{attrs:{"form":_vm.editShopGoodsSkuForm},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"title"},[_vm._v("商品SKU信息")]),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"商品展示名","labelCol":{ md: 6 },"wrapperCol":{ md: 16 },"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'goods_name',
                  { initialValue: _vm.skuInfo.goods_name }
                ]),expression:"[\n                  'goods_name',\n                  { initialValue: skuInfo.goods_name }\n                ]"}],attrs:{"placeholder":"商品展示名","disabled":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"展示标题","labelCol":{ md: 6 },"wrapperCol":{ md: 16 },"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'title',
                  { initialValue: _vm.skuInfo.title }
                ]),expression:"[\n                  'title',\n                  { initialValue: skuInfo.title }\n                ]"}],attrs:{"placeholder":"展示标题","disabled":""}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"商品SKU规格","labelCol":{ md: 3 },"wrapperCol":{ md: 20 },"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'specs',
                  { initialValue: _vm.skuInfo.showSpec }
                ]),expression:"[\n                  'specs',\n                  { initialValue: skuInfo.showSpec }\n                ]"}],attrs:{"placeholder":"商品SKU规格","disabled":""}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"SKU商品","labelCol":{ md: 3 },"wrapperCol":{ md: 20 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'goods_id',
                  { initialValue: _vm.skuInfo.goods_id+'-'+_vm.skuInfo.pid,rules: [{ required: true, message: '请选择SKU商品' }] } ]),expression:"[\n                  'goods_id',\n                  { initialValue: skuInfo.goods_id+'-'+skuInfo.pid,rules: [{ required: true, message: '请选择SKU商品' }] },\n                ]"}],staticStyle:{"margin":"-5px 0","width":"100%"},attrs:{"placeholder":"请选择SKU商品","show-search":"","optionFilterProp":"children","filter-option":_vm.goodsFilterOption}},[_c('a-select-opt-group',[(_vm.goodsList1.length > 0)?_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('a-icon',{attrs:{"type":"home"}}),_vm._v(" 自有货品 ")],1):_vm._e(),_vm._l((_vm.goodsList1),function(g1,i1){return _c('a-select-option',{key:i1,attrs:{"value":g1.value,"disabled":g1.disabled}},[_c('span',[_vm._v(_vm._s(g1.label))]),_c('span',{staticStyle:{"font-size":"10px","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(_vm._s(g1.label2))])])})],2),_c('a-select-opt-group',[(_vm.goodsList2.length > 0)?_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('a-icon',{attrs:{"type":"branches"}}),_vm._v(" 代理货品 ")],1):_vm._e(),_vm._l((_vm.goodsList2),function(g2,i2){return _c('a-select-option',{key:i2,attrs:{"value":g2.value,"disabled":g2.disabled}},[_c('span',[_vm._v(_vm._s(g2.label))]),_c('span',{staticStyle:{"font-size":"10px","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(_vm._s(g2.label2))])])})],2)],1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"成本价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'cost_price',
                  { initialValue: _vm.skuInfo.cost_price,rules: [{ required: true, message: '请填写成本价' }] } ]),expression:"[\n                  'cost_price',\n                  { initialValue: skuInfo.cost_price,rules: [{ required: true, message: '请填写成本价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写成本价","min":0,"step":0.01}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"销售标价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'unit_price',
                  { initialValue: _vm.skuInfo.unit_price,rules: [{ required: true, message: '请填写销售标价' }] } ]),expression:"[\n                  'unit_price',\n                  { initialValue: skuInfo.unit_price,rules: [{ required: true, message: '请填写销售标价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写销售标价","min":0,"step":0.01}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"实际售价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'promotion_unit_price',
                  { initialValue: _vm.skuInfo.promotion_unit_price,rules: [{ required: true, message: '请填写实际售价' }] } ]),expression:"[\n                  'promotion_unit_price',\n                  { initialValue: skuInfo.promotion_unit_price,rules: [{ required: true, message: '请填写实际售价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写实际售价","min":0,"step":0.01}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"上架库存数","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'inventory',
                  { initialValue: _vm.skuInfo.inventory,rules: [{ required: true, message: '请填写上架库存数' }] } ]),expression:"[\n                  'inventory',\n                  { initialValue: skuInfo.inventory,rules: [{ required: true, message: '请填写上架库存数' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写上架库存数","min":0,"step":1}})],1)],1)],1),_c('a-form-item',{staticClass:"mt-20",staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("确认保存")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }