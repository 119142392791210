<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveScheduleRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListDepartment } from '@/api/dms/department'
import { getScheduleDefInfo, updateScheduleDef } from '@/api/dms/scheduleDef'
import { selectListUser } from '@/api/dms/user'

export default {
  name: 'EditScheduleDef',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'select',
          title: '所属部门',
          field: 'dep_id',
          props: { placeholder: '请选择所属部门', disabled: true },
          options: [],
          validate: [
            { required: true, message: '请确认选择所属部门', whitespace: true }
          ],
          on: {
            change: (e) => { this.depChange(e) }
          }
        },
        {
          type: 'select',
          title: '医生',
          field: 'service_user_id',
          props: { placeholder: '请先选择部门再选择医生', disabled: true },
          options: [],
          validate: [
            { required: true, message: '请确认选择医生', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '是否默认',
          value: '0',
          field: 'is_default',
          props: { buttonStyle: 'solid', placeholder: '请选择是否默认' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否默认', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '当前状态',
          field: 'status',
          props: { placeholder: '请选择当前状态' },
          options: [],
          validate: [
            { required: true, message: '请确认选择当前状态', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '规则类型',
          field: 'rule_type',
          props: { placeholder: '请选择规则类型' },
          options: [],
          validate: [
            { required: true, message: '请确认选择规则类型', whitespace: true }
          ],
          on: {
            change: (e) => { this.typeChange(e) }
          }
        },
        {
          type: 'select',
          title: '每周时间',
          hidden: true,
          field: 'week',
          props: { mode: 'multiple', placeholder: '请选择每周开放时间', maxTagCount: 3, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [
            { value: 1, label: '星期一' },
            { value: 2, label: '星期二' },
            { value: 3, label: '星期三' },
            { value: 4, label: '星期四' },
            { value: 5, label: '星期五' },
            { value: 6, label: '星期六' },
            { value: 7, label: '星期七' },
          ],
          validate: [
            { required: true, message: '请确认选择每周开放时间' }
          ]
        },
        {
          type: 'select',
          title: '每月时间',
          field: 'month',
          hidden: true,
          props: { mode: 'multiple', placeholder: '请选择每月开放时间', maxTagCount: 3, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请确认选择每月开放时间' }
          ]
        },
        {
          type: 'input',
          title: '自定义日期',
          field: 'customDay',
          hidden: true,
          props: { placeholder: '请输入自定义日期，例：2023-01-01,2023-01-31' },
          validate: [
            { required: true, message: '请确认输入自定义日期', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        },
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      departmentListData: [],
      docListData: [],
      monthList: []
    }
  },
  created () {
    for (let mi = 1; mi <= 31; mi++) {
      this.monthList.push({ value: mi, label: mi + '号' })
    }
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthServiceScheduleDefs.editScheduleDef'))
    if (this.params === null || this.params.def === undefined || this.params.def < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getScheduleDefInfo({ schedule: this.params.def }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.def) {
        console.log(res.data)
        res.data.rule_type = res.data.rule_type.toString()
        res.data.is_default = res.data.is_default.toString()
        res.data.dep_id = res.data.dep_id.toString()
        res.data.service_user_id = res.data.service_user_id.toString()
        res.data.status = res.data.status.toString()
        this.depChange(res.data.dep_id)
        this.typeChange(res.data.rule_type)
        res.data = Object.assign({}, res.data, JSON.parse(res.data.schedule_rule))
        this.cformApi.setValue(res.data)
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch(() => {
      return this.$router.go(-1)
    })
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.departmentListData = res.data
        this.cformApi.updateRules({ // 更新规则
          'dep_id': { options: this.departmentListData },
          'month': { options: this.monthList },
          'rule_type': { options: this.$options.filters.selectOptionsData('schedule.rule_type') },
          'status': { options: this.$options.filters.selectOptionsData('schedule.status') }
        })
      }
      this.spinning = false
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveScheduleRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      data.ruleDataArr = this.ruleDataArr
      updateScheduleDef(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '编辑排班定义保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    depChange (v) {
      this.spinning = true
      selectListUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user_type: 50, dep: v }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.docListData = res.data
        }
        this.cformApi.updateRules({ // 更新规则
          'service_user_id': { options: this.docListData }
        })
        this.spinning = false
      })
    },
    typeChange (v) {
      // 0自定义，1按周，2按月
      if (v === '1') {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: false },
          'month': { hidden: true },
          'customDay': { hidden: true },
        })
      } else if (v === '2') {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: true },
          'month': { hidden: false },
          'customDay': { hidden: true },
        })
      } else {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: true },
          'month': { hidden: true },
          'customDay': { hidden: false },
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>