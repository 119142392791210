import request from '@/utils/request'

const apiUrls = {
  listUser: '/Users/listUser',
  addAdminUser: '/Users/addAdminUser',
  updateAdminUser: '/Users/updateAdminUser',
  getAdminUserInfo: '/Users/getAdminUserInfo',
  resetUserPwd: '/Users/resetUserPwd',
  changeStatus: '/Users/changeStatus',
  changeRoles: '/Users/changeRoles',
  selectListUser: '/Users/selectListUser',
  selectListUserByDep: '/Users/selectListUserByDep',
  selectListUserAndTags: '/Users/selectListUserAndTags',
  getAllowsEntList: '/Users/getAllowsEntList',
  saveCurrentDep: '/Users/saveCurrentDep',
  listUserProfile: '/Users/listUserProfile',
  saveUserProfile: '/Users/saveUserProfile',
  removeEntUser: '/Users/removeEntUser'
}

export function listUser (parameter) {
  return request({
    url: apiUrls.listUser,
    method: 'get',
    params: parameter
  })
}

export function listEmallUser (parameter) {
  return request({
    url: apiUrls.listEmallUser,
    method: 'get',
    params: parameter
  })
}

export function addAdminUser (data) {
  return request({
    url: apiUrls.addAdminUser,
    method: 'post',
    data
  })
}

export function getAdminUserInfo (data) {
  return request({
    url: apiUrls.getAdminUserInfo,
    method: 'post',
    data
  })
}

export function updateAdminUser (data) {
  return request({
    url: apiUrls.updateAdminUser,
    method: 'post',
    data
  })
}

export function resetUserPwd (data) {
  return request({
    url: apiUrls.resetUserPwd,
    method: 'post',
    data
  })
}

export function changeStatus (data) {
  return request({
    url: apiUrls.changeStatus,
    method: 'post',
    data
  })
}

export function changeRoles (data) {
  return request({
    url: apiUrls.changeRoles,
    method: 'post',
    data
  })
}

export function selectListUser (parameter) {
  return request({
    url: apiUrls.selectListUser,
    method: 'get',
    params: parameter
  })
}

export function selectListUserByDep (parameter) {
  return request({
    url: apiUrls.selectListUserByDep,
    method: 'get',
    params: parameter
  })
}

export function selectListUserAndTags (parameter) {
  return request({
    url: apiUrls.selectListUserAndTags,
    method: 'get',
    params: parameter
  })
}

export function getAllowsEntList (parameter) {
  return request({
    url: apiUrls.getAllowsEntList,
    method: 'get',
    params: parameter
  })
}

export function listUserProfile (parameter) {
  return request({
    url: apiUrls.listUserProfile,
    method: 'get',
    params: parameter
  })
}

export function saveCurrentDep (data) {
  return request({
    url: apiUrls.saveCurrentDep,
    method: 'post',
    data
  })
}

export function saveUserProfile (data) {
  return request({
    url: apiUrls.saveUserProfile,
    method: 'post',
    data
  })
}

export function removeEntUser (data) {
  return request({
    url: apiUrls.removeEntUser,
    method: 'post',
    data
  })
}
