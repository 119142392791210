<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          id="addGoodsSpecDefForm"
          @submit="handleSubmit"
          :form="addGoodsSpecDefForm"
        >
          <div class="title">规格参数信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="规格参数名称"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写规格参数名称"
                  v-decorator="[
                    'spec_name',
                    { rules: [{ required: true, message: '请填写规格参数名称', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="商品类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择商品类型' }] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择商品类型"
                  allow-clear
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  :options="'emall_goods_spec.disp_type' | selectOptionsData()"
                  show-search
                  optionFilterProp="children"
                  placeholder="请选择展示类型"
                  v-decorator="[
                    'disp_type',
                    { rules: [{ required: true, message: '请选择展示类型' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="排序号"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写排序号"
                  :min="1"
                  :step="1"
                  style="width:100%;"
                  v-decorator="[
                    'sort',
                    { rules: [{ required: true, message: '请填写排序号' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="规格值"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  placeholder="请填写规格值（多个值请用英文逗号 “ , ” 分割）"
                  v-decorator="[
                    'spec_val',
                    { rules: [{ required: true, message: '请填写规格值', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListTypeByTt } from '@/api/dms/goodsType'
import { addGoodsSpecDefs } from '@/api/dms/emallGoodSpec'

export default {
  name: 'AddGoodsSpecDefForm',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      addGoodsSpecDefForm: this.$form.createForm(this),
      typeListData: []
    }
  },
  created () {
    this.spinning = true
    this.addGoodsSpecDefForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.spinning = true
    selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.typeListData = res.data
      }
      this.spinning = false
      this.tip = '处理中...'
    })
  },
  methods: {
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      this.addGoodsSpecDefForm.validateFields((err, values) => {
        if (!err) {
          that.spinning = true
          addGoodsSpecDefs(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '新增规格参数成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回规格参数管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.addGoodsSpecDefForm.resetFields()
                }
              })
            } else {
              that.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            that.spinning = false
          })
        }
      })
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#addGoodsSpecDefForm {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
