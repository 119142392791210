import request from '@/utils/request'

const apiUrls = {
  selectListDepartment: '/Departments/selectListDepartment',
  selectListVirtualDepartment: '/Departments/selectListVirtualDepartment',
  listDepartment: '/Departments/listDepartment',
  addDepartment: '/Departments/addDepartment',
  getDepartmentInfo: '/Departments/getDepartmentInfo',
  updateDepartment: '/Departments/updateDepartment'
}

export function selectListDepartment (parameter) {
  return request({
    url: apiUrls.selectListDepartment,
    method: 'get',
    params: parameter
  })
}

export function selectListVirtualDepartment (parameter) {
  return request({
    url: apiUrls.selectListVirtualDepartment,
    method: 'get',
    params: parameter
  })
}

export function listDepartment (parameter) {
  return request({
    url: apiUrls.listDepartment,
    method: 'get',
    params: parameter
  })
}

export function addDepartment (data) {
  return request({
    url: apiUrls.addDepartment,
    method: 'post',
    data
  })
}

export function getDepartmentInfo (data) {
  return request({
    url: apiUrls.getDepartmentInfo,
    method: 'post',
    data
  })
}

export function updateDepartment (data) {
  return request({
    url: apiUrls.updateDepartment,
    method: 'post',
    data
  })
}
