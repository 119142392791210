<template>
  <a-affix :offset-top="top">
    <div
      id="kanban"
      class="kanban"
      :style="{height: scrollHeight+'px'}"
    >

      <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
      <draggable
        :list="boards"
        :animation="200"
        :draggable="false"
        class="kanban-boards"
        ghost-class="ghost-card"
        group="boards"
      >
        <KanbanBoard
          v-for="(board) in boards"
          :key="board.id"
          :board="board"
          :style="{'display': board.display}"
        >
          <a-spin :spinning="board.spinning">
            <!-- Draggable component from vuedraggable. -->
            <draggable
              :list="board.tasks"
              :draggable="false"
              :animation="200"
              ghost-class="ghost-card"
              group="tasks"
            >

              <!-- Kanban Task -->
              <KanbanTask
                v-for="(task) in board.tasks"
                :key="task.id"
                :task="task"
                :boardId="board.id"
              ></KanbanTask>
              <!-- / Kanban Task -->

            </draggable>
            <!-- / Draggable component from vuedraggable. -->
          </a-spin>
        </KanbanBoard>
      </draggable>
    </div>
  </a-affix>
</template>

<script>
import { getPatientBodyInfoByServiceRecord, getPatientAppointInfo, getPatientNutritionInfo, getPatientFollowupRecord } from '@/api/dms/patient'
import { getPreConsultationInfo } from '@/api/dms/consultation'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { getSelectListTag } from '@/api/manage'
import KanbanBoard from "@/components/Kanban/KanbanBoard"
import draggable from "vuedraggable"
import KanbanTask from "@/components/Kanban/KanbanTask"

export default ({
  name: 'CardServiceRecordOne',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    currentServiceRecord: {
      type: Object,
      default: null
    }
  },
  components: {
    KanbanBoard,
    draggable,
    KanbanTask,
  },
  data () {
    return {
      tip: '刷新中...',
      dietaryPattern: [],
      tagArr: [],

      boards: [
        {
          id: "_surveyInfo",
          title: "问卷调查",
          spinning: true,
          display: 'none',
          tasks: []
        },
        {
          id: "_serviceInfo",
          title: "问诊信息",
          spinning: true,
          display: 'block',
          tasks: []
        },
        {
          id: "_bodyInfo",
          title: "检查指标",
          spinning: true,
          display: 'block',
          tasks: []
        },
        // {
        //   id: "_personal&medication",
        //   title: "生活习惯及病史",
        //   spinning: true,
        //   display: 'block',
        //   tasks: []
        // },
        {
          id: "_nutritionInfo",
          title: "营养评定",
          spinning: true,
          display: 'block',
          tasks: []
        },
        {
          id: "_dietaryInfo",
          title: "饮食情况调查",
          spinning: true,
          display: 'none',
          tasks: []
        },
        {
          id: "_activity",
          title: "生活干预/运动计划",
          spinning: false,
          display: 'none',
          tasks: []
        },
        {
          id: "_followup",
          title: "随访信息",
          spinning: false,
          display: 'none',
          tasks: []
        }
      ],
      windowHeight: 0,
      scrollHeight: 0,
      top: 10,
    }
  },
  created () {
    // 获取饮食模式下拉数据
    selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1) {
        this.dietaryPattern = res.data
      }
    })
    // 获得问诊目标
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['1', '20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.tagArr = res.data
      }
    })
  },
  mounted () {
    this.windowHeight = document.body.clientHeight
    this.scrollHeight = this.windowHeight - 20
    window.onresize = () => {
      return (() => {
        // 窗口缩放自动获取页面宽高
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight // 高
        this.scrollHeight = this.windowHeight - 20
      })()
    }
  },
  watch: {
    currentServiceRecord: {
      handler (newRecord, oldRecord) {
        this.refreshServiceAppInfo()
        this.refreshBodyInfo()
        this.refreshNutritionInfo()
        // this.refreshPersonalInfo()
        // this.refreshDietaryInfo()
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  methods: {
    refreshServiceAppInfo () {
      let curBoard = this.boards.filter(item => item.id === '_serviceInfo')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []
      // 加载问诊填写的内容
      getPreConsultationInfo({ service_record: this.currentServiceRecord.id, patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((resCon) => {
        if (resCon.status !== undefined && resCon.status === 1 && Object.keys(resCon.data).length > 0) {
          curBoard[0].tasks.push({
            id: '_recent_status',
            title: '最近状况',
            description: resCon.data.recent_status,
          })
          curBoard[0].tasks.push({
            id: '_reason',
            title: '诊疗原因',
            description: resCon.data.reason,
          })
          curBoard[0].tasks.push({
            id: '_expectation',
            title: '客户期望',
            description: resCon.data.expectation,
          })

          let tagIdArr = JSON.parse(resCon.data.goal)
          let tagArr = this.tagArr.filter(gl => tagIdArr.includes(gl.value))
          let tagDesc = ''
          tagArr.forEach(tag => {
            tagDesc += tag.label + '，'
          })
          tagDesc += resCon.data.goal_remarks !== null ? resCon.data.goal_remarks : ''

          curBoard[0].tasks.push({
            id: '_goal',
            title: '管理目标',
            description: tagDesc,
          })

          tagIdArr = JSON.parse(resCon.data.disease)
          tagArr = this.tagArr.filter(gl => tagIdArr.includes(gl.value))
          tagDesc = ''
          tagArr.forEach(tag => {
            tagDesc += tag.label + '，'
          })
          tagDesc += resCon.data.disease_remarks !== null ? resCon.data.disease_remarks : ''

          curBoard[0].tasks.push({
            id: '_disease',
            title: '疾病信息',
            description: tagDesc,
          })

          curBoard[0].tasks.push({
            id: '_medication',
            title: '服药及营养补充剂信息',
            description: resCon.data.medication,
          })

          //   加载预约数据
          getPatientAppointInfo({ appoint_id: this.currentServiceRecord.appoint_id, patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((resApp) => {
            if (resApp.status !== undefined && resApp.status === 1 && Object.keys(resApp.data).length > 0) {
              curBoard[0].tasks.push({
                id: '_app_reason',
                title: '来由',
                badge: {
                  class: 'bg-success text-white',
                  text: '预约单自述',
                },
                description: resApp.data.reason,
              })
              curBoard[0].tasks.push({
                id: '_app_expectation',
                title: '期望',
                badge: {
                  class: 'bg-success text-white',
                  text: '预约单自述',
                },
                description: resApp.data.expectation,
              })
              curBoard[0].tasks.push({
                id: '_app_goal_remarks',
                title: '目标',
                badge: {
                  class: 'bg-success text-white',
                  text: '预约单自述',
                },
                description: resApp.data.goal_remarks,
              })
            } else {
              if (resApp.status !== 1) {
                this.$message.error(resApp.msg || '获取原始信息失败！请刷新后重试！')
              }
            }
            if (curBoard[0].tasks.length === 0) {
              curBoard[0].display = 'none'
            }
            curBoard[0].spinning = false
          })
        } else {
          if (resCon.status !== 1) {
            this.$message.error(resCon.msg || '获取原始信息失败！请刷新后重试！')
          }
          curBoard[0].display = 'none'
          curBoard[0].spinning = false
        }
      })



    },
    refreshBodyInfo () {
      let curBoard = this.boards.filter(item => item.id === '_bodyInfo')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []

      //   加载体检数据
      getPatientBodyInfoByServiceRecord({ service_record: this.currentServiceRecord.id, patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.length > 0) {
          let bodyinfo = {}
          res.data.forEach(element => {
            let unitName = element.item_unit_name !== null ? '（' + element.item_unit_name + '）' : ''


            bodyinfo = {
              id: element.id,
              title: element.item_name + "：" + element.result_val + unitName
            }
            if (element.result_type === 2) {
              bodyinfo.badge = {
                class: 'bg-warning',
                text: '偏低',
              }
            }
            if (element.result_type === 4) {
              bodyinfo.badge = {
                class: 'bg-warning',
                text: '偏高',
              }
            }
            // BMI 单独标记计算公式
            if (element.rptcode !== undefined && element.rptcode === 'bmr') {
              let bmr = JSON.parse(element.remarks)
              if (bmr.label !== undefined && bmr.label !== '') {
                bodyinfo.description = '计算公式：' + bmr.label.replace(/ /g, '').replace(/，/g, '')
              }
            }
            curBoard[0].tasks.push(bodyinfo)
          })
        } else {
          if (res.status !== 1) {
            this.$message.error(res.msg || '获取原始信息失败！请刷新后重试！')
          }
        }
        if (curBoard[0].tasks.length === 0) {
          curBoard[0].display = 'none'
        }
        curBoard[0].spinning = false
      })
    },
    // refreshPersonalInfo () {
    //   let curBoard = this.boards.filter(item => item.id === '_personal&medication')
    //   curBoard[0].spinning = true
    //   curBoard[0].display = 'block'
    //   curBoard[0].tasks = []
    //   // 加载个人生活习惯数据
    //   getPatientPersonalInfo({ patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.currentServiceRecord.id }).then((resPer) => {
    //     if (resPer.status !== undefined && resPer.status === 1 && Object.keys(resPer.data).length > 0) {
    //       let desc = this.$options.filters.showText('patient.marriage', resPer.data.marriage)
    //       desc += resPer.data.marriage > 0 && resPer.data.marriage_remarks !== null && resPer.data.marriage_remarks !== '' ? '，' + resPer.data.marriage_remarks : ''
    //       curBoard[0].tasks.push({
    //         id: '_marriage',
    //         title: '婚姻状况',
    //         description: desc,
    //       })

    //       desc = this.$options.filters.showText('patient.smoker', resPer.data.smoker)
    //       desc += resPer.data.smoker > 0 && resPer.data.smoker_remarks !== null && resPer.data.smoker_remarks !== '' ? '，' + resPer.data.smoker_remarks : ''
    //       curBoard[0].tasks.push({
    //         id: '_smoke',
    //         title: '是否抽烟',
    //         description: desc,
    //       })

    //       desc = this.$options.filters.showText('patient.alcohol', resPer.data.alcohol)
    //       desc += resPer.data.alcohol > 0 && resPer.data.alcohol_remarks !== null && resPer.data.alcohol_remarks !== '' ? '，' + resPer.data.alcohol_remarks : ''
    //       curBoard[0].tasks.push({
    //         id: '_alcohol',
    //         title: '是否喝酒',
    //         description: desc,
    //       })

    //       desc = this.$options.filters.showText('patient.sleep', resPer.data.sleep)
    //       desc += resPer.data.sleep > 0 && resPer.data.sleep_remarks !== null && resPer.data.sleep_remarks !== '' ? '，' + resPer.data.sleep_remarks : ''
    //       curBoard[0].tasks.push({
    //         id: '_sleep',
    //         title: '睡眠质量',
    //         description: desc,
    //       })

    //       // 加载既往病史数据
    //       getPatientMedicalInfo({ patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((resMed) => {
    //         if (resMed.status !== undefined && resMed.status === 1 && Object.keys(resMed.data).length > 0) {
    //           desc = this.$options.filters.showText('patient.disease', resMed.data.disease)
    //           desc += resPer.data.disease > 0 && resPer.data.disease_remarks !== null && resPer.data.disease_remarks !== '' ? '，' + resPer.data.disease_remarks : ''
    //           curBoard[0].tasks.push({
    //             id: '_disease',
    //             title: '疾病情况',
    //             description: desc,
    //           })
    //           curBoard[0].tasks.push({
    //             id: '_medication',
    //             title: '服药情况',
    //             description: resMed.data.medication,
    //           })
    //           curBoard[0].tasks.push({
    //             id: '_personal_history',
    //             title: '个人病史',
    //             description: resMed.data.personal_history,
    //           })
    //           curBoard[0].tasks.push({
    //             id: '_family_history',
    //             title: '家族病史',
    //             description: resMed.data.family_history,
    //           })
    //           curBoard[0].tasks.push({
    //             id: '_other_remarks',
    //             title: '其他信息',
    //             description: resMed.data.other_remarks,
    //           })
    //         } else {
    //           if (resMed.status !== 1) {
    //             this.$message.error(resPer.msg || '获取原始信息失败！请刷新后重试！')
    //           }
    //         }

    //         if (curBoard[0].tasks.length === 0) {
    //           curBoard[0].display = 'none'
    //         }
    //         curBoard[0].spinning = false
    //       })
    //     } else {
    //       if (resPer.status !== 1) {
    //         this.$message.error(resPer.msg || '获取原始信息失败！请刷新后重试！')
    //       }
    //       if (curBoard[0].tasks.length === 0) {
    //         curBoard[0].display = 'none'
    //       }
    //       curBoard[0].spinning = false
    //     }
    //   })
    // },
    refreshNutritionInfo () {
      let curBoard = this.boards.filter(item => item.id === '_nutritionInfo')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.currentServiceRecord.id }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && Object.keys(resNut.data).length > 0) {
          curBoard[0].tasks.push({
            id: '_assessment',
            title: '营养评定',
            description: resNut.data.assessment,
          })
          curBoard[0].tasks.push({
            id: '_intervention_method',
            title: '干预方法',
            description: this.$options.filters.showText('prescription_nutrition.intervention_method', resNut.data.intervention_method),
          })

          if (resNut.data.activity_level !== null) {
            curBoard[0].tasks.push({
              id: '_activity_level',
              title: '一周运动情况',
              description: this.$options.filters.showText('calorie.calc', resNut.data.activity_level),
            })
          }
          if (resNut.data.advise_activity_level !== null) {
            curBoard[0].tasks.push({
              id: '_advise_activity_level',
              title: '建议运动量',
              description: this.$options.filters.showText('calorie.calc', resNut.data.advise_activity_level),
            })
          }
          if (resNut.data.calorie !== null) {
            curBoard[0].tasks.push({
              id: '_calorie',
              title: '热量摄入建议：' + resNut.data.calorie + '（Kcal）',
            })
          }

          let water_intake = resNut.data.water_intake + '毫升'
          water_intake += resNut.data.advise_water_intake !== null && resNut.data.advise_water_intake !== '' ? '，建议饮水' + resNut.data.advise_water_intake + '毫升' : ''
          curBoard[0].tasks.push({
            id: '_water_intake',
            title: '每日饮水量',
            description: water_intake,
          })

          curBoard[0].tasks.push({
            id: '_supplement',
            title: '营养补充剂',
            description: resNut.data.supplement,
          })
          curBoard[0].tasks.push({
            id: '_chartDoughnut',
            title: '营养摄入比例',
            type: 'chartDoughnut',
            chartData: {
              carbohydrates: resNut.data.carbohydrates,
              protein: resNut.data.protein,
              fat: resNut.data.fat,
            },
          })
          if (resNut.data.dietary_pattern_id !== null) {
            let ptIdArr = JSON.parse(resNut.data.dietary_pattern_id)
            let pt = this.dietaryPattern.filter(pt => ptIdArr.includes(pt.value))
            let ptLabel = ''
            pt.forEach(pt => {
              ptLabel += '[' + pt.label + ']'
            })
            curBoard[0].tasks.push({
              id: '_dietary_pattern_id',
              title: '推荐饮食方案',
              type: 'dietary_pattern',
              recommendation: resNut.data.recommendation,
              val: ptIdArr,
              label: ptLabel,
            })
          }

        } else {
          // this.$message.error(resNut.msg || '获取原始信息失败！请刷新后重试！')
        }

        // 显示问诊当时的饮食调查信息
        this.refreshDietaryInfo(resNut)

        // 取营养方案的时候，同时取了life、sport、followup表数据
        this.refreshLifeSportInfo(resNut)
        this.refreshFollowupInfo(resNut)

        if (curBoard[0].tasks.length === 0) {
          curBoard[0].display = 'none'
        }
        curBoard[0].spinning = false
      })
    },
    refreshDietaryInfo (resDty) {
      let curBoard = this.boards.filter(item => item.id === '_dietaryInfo')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []

      if (resDty.status !== undefined && resDty.status === 1 && Object.keys(resDty.data).length > 0) {
        let desc = resDty.data.favorite_diet_type !== null ? this.$options.filters.showText('patient.favorite_diet_type', resDty.data.favorite_diet_type) : ''
        desc += resDty.data.favorite_diet_type_remarks !== null && resDty.data.favorite_diet_type_remarks !== '' ? '，' + resDty.data.favorite_diet_type_remarks : ''

        curBoard[0].tasks.push({
          id: '_favorite_diet_type',
          title: '喜欢食物类型',
          description: desc,
        })
        curBoard[0].tasks.push({
          id: '_food_favorite',
          title: '喜欢的食物',
          description: resDty.data.food_favorite,
        })
        curBoard[0].tasks.push({
          id: '_food_dislike',
          title: '不喜欢的食物',
          description: resDty.data.food_dislike,
        })
        curBoard[0].tasks.push({
          id: '_allergies',
          title: '过敏信息',
          description: resDty.data.allergies_remarks,
        })
        curBoard[0].tasks.push({
          id: '_food_intolerant',
          title: '食物不耐受信息',
          description: resDty.data.food_intolerant_remarks,
        })
        curBoard[0].tasks.push({
          id: '_nutrition_deficiency_remarks',
          title: '营养缺乏',
          description: resDty.data.nutrition_deficiency_remarks,
        })
      }
      if (curBoard[0].tasks.length === 0) {
        curBoard[0].display = 'none'
      }
      curBoard[0].spinning = false
    },
    refreshLifeSportInfo (resLife) {
      let curBoard = this.boards.filter(item => item.id === '_activity')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []

      if (resLife.status !== undefined && resLife.status === 1 && Object.keys(resLife.data).length > 0) {
        curBoard[0].tasks.push({
          id: '_life_plan',
          title: '生活方式',
          description: resLife.data.life_plan,
        })
        curBoard[0].tasks.push({
          id: '_sport_plan',
          title: '运动方式',
          description: resLife.data.sport_plan,
        })

        resLife.data.prescription_sport.forEach((sport, index) => {
          index += 1
          let desc = '运动方式：' + this.$options.filters.showText('patient.activity_type', sport.sport_type) + '<br>'
          if (sport.duration !== null) {
            desc += '运动时间：' + sport.duration + '<br>'
          }
          if (sport.frequency !== null) {
            desc += '运动频率：' + sport.frequency + '<br>'
          }
          if (sport.example !== null) {
            desc += '运动举例：' + sport.example + '<br>'
          }
          if (sport.plan_content !== null) {
            desc += '运动方案：' + sport.plan_content + '<br>'
          }
          curBoard[0].tasks.push({
            id: '_sport' + index,
            title: '推荐运动方式' + index,
            description_multiple: desc,
          })
        })

      }
      if (curBoard[0].tasks.length === 0) {
        curBoard[0].display = 'none'
      }
      curBoard[0].spinning = false
    },
    refreshFollowupInfo (resFollowUp) {
      let curBoard = this.boards.filter(item => item.id === '_followup')
      curBoard[0].spinning = true
      curBoard[0].display = 'block'
      curBoard[0].tasks = []
      if (resFollowUp.status !== undefined && resFollowUp.status === 1 && resFollowUp.data.followup_id !== null) {
        let desc = '随访周期：' + resFollowUp.data.followup_cycle + '<br>'
        desc += '回访方式：' + this.$options.filters.showText('followup.method', resFollowUp.data.followup_method) + '<br>'
        desc += '计划回访次数：' + resFollowUp.data.followup_all_count + '<br>'
        if (resFollowUp.data.followup_remarks !== null) {
          desc += '计划备注：' + resFollowUp.data.followup_remarks + '<br>'
        }
        desc += '回访计划状态：' + this.$options.filters.showText('followup.action_status', resFollowUp.data.followup_action_status)

        curBoard[0].tasks.push({
          id: '_followup_plan',
          title: '随访计划',
          description_multiple: desc,
        })

        // 加载回访记录
        getPatientFollowupRecord({ patient: this.currentServiceRecord.patient_id, enterprise: this.$store.getters.userInfo.user.target_enterprise, plan_id: resFollowUp.data.followup_id }).then((resFollow) => {
          if (resFollow.status !== undefined && resFollow.status === 1 && resFollow.data.length > 0) {
            resFollow.data.forEach((follow, index) => {
              index += 1
              let desc = '回访编号：' + follow.record_no + '<br>'
              desc += '回访时间：' + follow.record_time + '<br>'
              desc += '回访方式：' + this.$options.filters.showText('followup.method', follow.method) + '<br>'
              desc += '回访结果：' + follow.content

              curBoard[0].tasks.push({
                id: '_follow_record' + index,
                title: '回访记录' + index,
                description_multiple: desc,
              })
            })
          }
        })

      }
      if (curBoard[0].tasks.length === 0) {
        curBoard[0].display = 'none'
      }
      curBoard[0].spinning = false
    },
    xorArray (arr) {
      return arr.reduce((a, b) => a.spinning | b.spinning)
    }
  }
})

</script>