<template>
  <a-drawer
    width="550"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="patAppointDrawerVisibleCu"
    title="客户预约"
    @close="closeAptAppointDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="patAppointSpinning"
    >
      <div>
        <form-create
          v-model="cformPatAppointApi"
          :rule="cformpatAppointRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="9"
          :span="15"
        >
          <a-button
            @click="closeAptAppointDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { getHisPatientSelectList } from '@/api/dms/patient'
import { addPatientAppointInfo } from '@/api/dms/clientAppointment'
import { getSchedulesByUser } from '@/api/dms/schedule'
import { selectListUserAndTags } from '@/api/dms/user'

export default ({
  name: 'SurveyView',
  props: {
    patAppointVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    patAppointVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.patAppointDrawerVisibleCu = this.patAppointVisible
        if (this.patAppointVisible) {
          this.$nextTick(() => {
            // 是否隐藏新增客户的切换按钮
            const patMark = (this.parentParams.pat_mark !== undefined && this.parentParams.pat_mark === true)
            this.cformPatAppointApi.updateRules({ // 更新规则
              patient_mark: { hidden: patMark },
            })
            this.loadDocListData()
          })
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      patAppointDrawerVisibleCu: false,
      patAppointSpinning: false,
      cformPatAppointApi: {},
      cformpatAppointRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'dep_id',
          value: this.$store.getters.userInfo.user.department_id
        },
        {
          type: 'a-radio-group',
          title: '客户是否注册',
          value: '1',
          field: 'patient_mark',
          hidden: false,
          props: { buttonStyle: 'solid', placeholder: '请选择客户是否注册' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['已有账号客户']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['新客户']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ],
          on: {
            change: (v) => this.pmarkChange(v)
          }
        },
        {
          type: 'select',
          title: '客户姓名',
          field: 'choose_patient',
          props: { placeholder: '请选择客户姓名', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择客户姓名' }
          ],
          on: {
            change: () => this.patChange()
          }
        },
        {
          type: 'input',
          title: '客户姓名',
          field: 'name',
          hidden: true,
          props: { placeholder: '请填写客户姓名' },
          col: {
            span: 14
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          validate: [
            { required: true, message: '请确认输入客户姓名', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '',
          hidden: true,
          field: 'name_py',
          props: {
            addonBefore: ' / ', placeholder: '请填写客户英文姓名'
          },
          col: {
            span: 10
          },
          wrap: {
            labelCol: { span: 1 },
            wrapperCol: { span: 23 }
          }
        },
        {
          type: 'DatePicker',
          title: '出生年月',
          hidden: true,
          field: 'birth_date', style: { width: '100%' },
          props: { placeholder: '请选择出生年月', type: 'date', allowClear: true }
        },
        {
          type: 'a-radio-group',
          title: '性别',
          hidden: true,
          value: '0',
          field: 'gender',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { buttonStyle: 'solid', placeholder: '请选择性别' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['保密']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['男']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['女']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '联系电话',
          hidden: true,
          field: 'contact_phone',
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请填写联系电话' }
        },
        {
          type: 'select',
          title: '预约服务医生',
          field: 'incharge_doc',
          props: { placeholder: '请选择预约服务医生', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择预约服务医生' }
          ],
          on: {
            change: (v) => this.docChange(v)
          }
        },
        {
          type: 'a-radio-group',
          title: '服务时间选择',
          value: '1',
          field: 'appoint_mark',
          hidden: false,
          props: { buttonStyle: 'solid', placeholder: '请选择服务时间' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['当前医生排班时间']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['直接指定时间']
            }
          ],
          validate: [
            { required: true, message: '请确认选择服务时间', whitespace: true }
          ],
          on: {
            change: (v) => this.appointTimeMarkChange(v)
          }
        },
        {
          type: 'select',
          title: '预约服务时间',
          field: 'schedule_id',
          props: { placeholder: '请选择预约服务时间', mode: 'multiple', maxTagCount: 1, maxTagTextLength: 30, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择预约服务时间' }
          ]
        },
        {
          type: 'DatePicker',
          title: '预约服务时间',
          hidden: true,
          field: 'appoint_date', style: { width: '100%' },
          props: { placeholder: '请选择预约服务时间', type: 'date', allowClear: true, showTime: true, format: 'YYYY-MM-DD HH:mm' },
          validate: [
            { required: true, message: '请选择预约服务时间' }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        }
      ],
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
      patientListArr: [],
      docListArr: [],
      iniMark: 0
    }
  },
  created () {

  },
  methods: {
    closeAptAppointDrawer () {
      this.iniMark = 0
      this.cformPatAppointApi.resetFields()
      this.patientListArr = []
      this.docListArr = []
      this.cformPatAppointApi.updateRules({ schedule_id: { options: [] } })
      this.$emit('patAppointVisibleChange', false)
    },
    loadDocListData () {
      // 获取医生下拉列表数据 enterprise: this.$store.getters.userInfo.user.target_enterprise,
      this.patAppointSpinning = true
      selectListUserAndTags({ user_type: 50 }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.docListArr = res.data
          this.cformPatAppointApi.updateRules({ // 更新规则
            incharge_doc: { options: this.docListArr }
          })
          // 初始医生
          if (this.parentParams.incharge_doc !== undefined) {
            const filterArr = this.docListArr.filter((fv) => {
              return fv.value.toString() === this.parentParams.incharge_doc.toString()
            })
            if (filterArr.length > 0) {
              this.cformPatAppointApi.setValue({ incharge_doc: this.parentParams.incharge_doc })
              // 默认选择当前用户则去获取当前用户的排班信息
              this.docChange(this.parentParams.incharge_doc)
              // 有初始医生的时候是营养师添加预约设为不可选择
              this.cformPatAppointApi.updateRules({ // 更新规则
                incharge_doc: { props: { disabled: true } }
              })
            }
          }
        }
        this.loadPatListData()
      })
    },
    loadPatListData () {
      // this.patAppointSpinning = true
      let ptListParm = {}
      // 平台获取的时候不需要指定当前企业和负责医生
      if (this.parentParams.pat_mark === undefined || this.parentParams.pat_mark !== true) {
        ptListParm = { enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }
      }
      // 获取客户下拉列表数据
      getHisPatientSelectList(ptListParm).then(res => {
        if (res.status !== undefined && res.status === 1) {
          // 页面渲染的时候就执行了，那时候窗口还未打开没有初始cformPatAppointApi，不能使用
          this.patientListArr = res.data.map(dv => {
            let gender = this.$options.filters.showText('ryx.gender', dv.gender.toString())
            gender = gender === '-' ? '保密' : gender
            const tagIdArr = dv.his_patient_tag.map(tg => {
              return tg.tag_id
            })
            return {
              value: dv.id, label: dv.name + ' - ' + gender + ' - ' + dv.patient_no, tags: tagIdArr
            }
          })

          this.cformPatAppointApi.updateRules({ // 更新规则
            choose_patient: { options: this.patientListArr }
          })

          // 初始客户
          if (this.parentParams.choose_patient !== undefined) {
            const filterArr2 = this.patientListArr.filter((fv) => {
              return fv.value === this.parentParams.choose_patient
            })
            if (filterArr2.length > 0) {
              this.cformPatAppointApi.setValue({ choose_patient: this.parentParams.choose_patient })
              // 有初始客户的时候设为不可选择
              this.cformPatAppointApi.updateRules({ // 更新规则
                choose_patient: { props: { disabled: true } }
              })
              this.patChange()
            }
          }
        }
        this.patAppointSpinning = false
      })

    },
    pmarkChange (v) {
      if (v.target.value > 1) {
        // 新客户
        this.cformPatAppointApi.hidden(true, ['choose_patient'])
        this.cformPatAppointApi.hidden(false, ['name', 'name_py', 'birth_date', 'gender', 'contact_phone'])
      } else {
        // 直接选
        this.cformPatAppointApi.hidden(false, ['choose_patient'])
        this.cformPatAppointApi.hidden(true, ['name', 'name_py', 'birth_date', 'gender', 'contact_phone'])
      }
    },
    appointTimeMarkChange (v) {
      if (v.target.value > 1) {
        // 指定时间
        this.cformPatAppointApi.hidden(true, ['schedule_id'])
        this.cformPatAppointApi.hidden(false, ['appoint_date'])
      } else {
        // 排班下拉时间
        this.cformPatAppointApi.hidden(false, ['schedule_id'])
        this.cformPatAppointApi.hidden(true, ['appoint_date'])
      }
    },
    docChange (v) {
      // 根据选择的医生，获取医生的排班下拉列表数据
      this.patAppointSpinning = true
      this.cformPatAppointApi.updateRules({ schedule_id: { options: [] } })
      this.cformPatAppointApi.setValue({ schedule_id: undefined })
      getSchedulesByUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: v }).then(res => {
        this.patAppointSpinning = false
        if (res.status !== undefined && res.status === 1) {
          const avaArr = res.data.filter(dv => {
            dv.available_count = (isNaN(dv.available_count) || dv.available_count === '' && dv.available_count === null) ? 0 : parseInt(dv.available_count)
            dv.add_count = (isNaN(dv.add_count) || dv.add_count === '' && dv.add_count === null) ? 0 : parseInt(dv.add_count)
            dv.appoint_count = (isNaN(dv.appoint_count) || dv.appoint_count === '' && dv.appoint_count === null) ? 0 : parseInt(dv.appoint_count)
            return dv.appoint_count < (dv.available_count + dv.add_count)
          })
          if (avaArr.length < 1) {
            this.$message.error('当前服务医生暂无可预约排班信息！')
            return false
          }
          this.cformPatAppointApi.updateRules({ // 更新规则
            'schedule_id': {
              options: avaArr.map(dv => {
                return { value: dv.id + '@@' + dv.service_date + '@@' + dv.appoint_count, label: dv.service_date.substr(0, 16) + ' 　可约: ' + (dv.available_count + dv.add_count - dv.appoint_count) + '人' }
              })
            }
          })

          // 初始服务时间
          if (this.parentParams.schedule_id !== undefined && this.iniMark < 1) {
            const filterArr = avaArr.filter((dv) => {
              return dv.id.toString() === this.parentParams.schedule_id.toString()
            })
            if (filterArr.length > 0) {
              this.cformPatAppointApi.setValue({ schedule_id: [filterArr[0].id + '@@' + filterArr[0].service_date + '@@' + filterArr[0].appoint_count] })
            } else {
              this.$message.error('当前排班不在可预约范围！请重新选择')
            }
            this.iniMark++
          }
        }
      })
    },
    submitBtnClick () {
      this.cformPatAppointApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformPatAppointApi.formData()
          this.patAppointSpinning = true
          addPatientAppointInfo(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformPatAppointApi.resetFields()
              this.cformPatAppointApi.updateRules({ schedule_id: { options: [] } })
              this.closeAptAppointDrawer()
              this.$message.success('预约成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.patAppointSpinning = false
          })

        }
      })
    },
    patChange () {
      // 选择客户的时候匹配标签最合适的医生默认推荐选中
      const cuform = this.cformPatAppointApi.formData()
      const filterArr = this.patientListArr.filter((fv) => {
        return fv.value === cuform.choose_patient
      })
      if (filterArr.length > 0) {
        let cuBest = {}
        let okNum = 0
        this.docListArr = this.docListArr.map((docv, di) => {
          docv.label = docv.label.replace('[推荐] ', '')
          // 取交集看哪个多就推荐哪个
          const sameArr = docv.tags.filter(item => filterArr[0].tags.includes(item.tag_id.toString()))
          if (cuBest.idx === undefined || cuBest.num < sameArr.length) {
            cuBest = { idx: di, num: sameArr.length, item: docv }
          }
          okNum++
          return docv
        })
        const that = this
        const timer = setInterval(() => {
          if (okNum >= that.docListArr.length) {// 处理最后显示顺序，将推荐的那个显示在第一个默认选中
            if (cuBest.idx !== undefined && that.docListArr[cuBest.idx] !== undefined) {
              that.docListArr.splice(cuBest.idx, 1)
              const btItem = JSON.parse(JSON.stringify(cuBest.item))
              btItem.label = '[推荐] ' + btItem.label
              that.docListArr.unshift(btItem)
              // 如果当前没有选择医生，则默认选择推荐的这个
              if (cuform.incharge_doc === undefined || cuform.incharge_doc === null || cuform.incharge_doc === '') {
                that.cformPatAppointApi.setValue({ incharge_doc: btItem.value, schedule_id: null })
                that.cformPatAppointApi.updateRules({ // 更新规则
                  incharge_doc: { options: that.docListArr }
                })
                // 默认选择当前用户则去获取当前用户的排班信息
                that.docChange(btItem.value)
              }
            }
            clearInterval(timer)
          }
        }, 100)
      }
    }
  }
})

</script>

<style lang="scss" scoped>
</style>