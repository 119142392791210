<template>
  <food-recipe-list>
  </food-recipe-list>
</template>

<script>
import { FoodRecipeList } from '@/components/FoodRecipes'

export default {
  name: 'ListFoodRecipe',
  components: {
    FoodRecipeList,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {

  },
  methods: {

  }
}
</script>
