<template>
  <!-- 病史及用药信息 Card -->
  <a-card
    class="header-solid h-full card-profile-information"
    :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
    :headStyle="{paddingRight: 0,}"
  >
    <template #title>
      <h6 class="font-semibold m-0">病史及用药信息</h6>
    </template>
    <template slot="extra">
      <div>
        <a-button
          type="link"
          size="small"
          :disabled="loadingMedical"
          @click="refreshMedicalOriInfo()"
        >
          刷新
        </a-button>
        <a-button
          type="link"
          size="small"
          class="ml-10 mr-10"
          :disabled="loadingMedical"
          @click="clickEditMedical()"
          v-if="Object.keys(medicalInfo).length > 0"
        >
          编辑
        </a-button>
        <a-button
          type="link"
          size="small"
          class="ml-10 mr-10"
          :disabled="loadingMedical"
          @click="clickEditMedical()"
          v-else
        >
          添加
        </a-button>
      </div>
    </template>

    <a-spin
      :tip="tip"
      :spinning="loadingMedical"
    >
      <a-descriptions
        title=""
        :column="1"
        v-if="Object.keys(medicalInfo).length > 0"
      >
        <a-descriptions-item label="疾病信息">
          <template v-for="(mdv,mdi) in medicalInfo.disease">
            <a-tag
              color="orange"
              :key="mdi"
              v-if="tagArr[mdv] !== undefined"
            >
              {{ tagArr[mdv] }}
            </a-tag>
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="疾病信息备注">{{ medicalInfo.disease_remarks }}</a-descriptions-item>

        <a-descriptions-item label="现病史及用药信息">{{ medicalInfo.medication }}</a-descriptions-item>
        <a-descriptions-item label="既往病史">{{ medicalInfo.personal_history }}</a-descriptions-item>

        <a-descriptions-item label="家族病史">{{ medicalInfo.family_history }}</a-descriptions-item>
        <a-descriptions-item label="其他备注">{{ medicalInfo.other_remarks }}</a-descriptions-item>
      </a-descriptions>

      <a-empty v-else>
      </a-empty>
    </a-spin>

    <!-- 病史及用药信息修改抽屉 -->
    <medical-info-drawer
      :parentParams="patMedicalParam"
      :medicalDrawerVisible="medicalDrawerVisible"
      @medicalDrawerVisibleChange="medicalDrawerVisibleChange"
    />
  </a-card>
  <!-- / 病史及用药信息 Card -->
</template>

<script>
import { getPatientMedicalInfo } from '@/api/dms/patient'
import MedicalInfoDrawer from './MedicalInfoDrawer'
import { getSelectListTag } from '@/api/manage'

export default ({
  name: 'CardMedicalInfo',
  components: { MedicalInfoDrawer },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      tip: '加载中...',
      loadingMedical: false,
      medicalInfo: {},
      medicalDrawerVisible: false,
      patMedicalParam: {},
      tagArr: {}
    }
  },
  created () {
    this.refreshMedicalOriInfo()
  },
  methods: {
    refreshMedicalOriInfo () {
      this.loadingMedical = true
      getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['1'] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          res.data.map(tv => {
            this.tagArr[tv.value] = tv.label
          })
        }
      })
      // 加载个人病史及用药信息数据
      getPatientMedicalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.medicalInfo = res.data
          this.medicalInfo.disease = JSON.parse(this.medicalInfo.disease)
          console.log(this.medicalInfo)
        }
        this.loadingMedical = false
      })
    },
    clickEditMedical () {
      this.patMedicalParam = { patient: this.parentParams.patient, oriInfo: this.medicalInfo }
      this.medicalDrawerVisible = true
    },
    medicalDrawerVisibleChange (v) {
      this.medicalDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.refreshMedicalOriInfo()
      }
    },
  }
})

</script>