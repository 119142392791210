<template>
  <a-spin
    :tip="tip"
    :spinning="spinning"
  >
    <a-card
      :bordered="false"
      id="personal_info"
      class="header-solid mb-24"
    >
      <template #title>
        <h5 class="mb-0 font-semibold">生活习惯</h5>
      </template>

      <!-- <a-button
      slot="extra"
      type="primary"
      icon="file-search"
      class="px-10 py-5 "
      @click="clickPrescriptionBtn"
    >查看历史记录</a-button> -->

      <form-create
        v-model="cformPersonalApi"
        :rule="cformPersonalRule"
        :option="cformOption"
        @submit="savePersonalRequest"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="11"
          :span="12"
        >
          <a-button
            @click="cformPersonalApi.submit()"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { savePatientPersonalInfo, getPatientPersonalInfo } from '@/api/dms/patient'
import moment from 'moment'

export default ({
  // 客户生活习惯信息card
  name: 'PersonalInfo',
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    cformOption: {
      type: Object,
      default: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    }
  },
  data () {
    return {
      cformPersonalApi: {},
      cformPersonalRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '婚姻状况',
          field: 'marriage',
          props: { placeholder: '请选择婚姻状况' },
          options: [],
          validate: [
            { required: true, message: '请选择婚姻状况' }
          ]
        },
        {
          type: 'input',
          title: '婚姻状况备注',
          field: 'marriage_remarks',
          props: { placeholder: '请填写婚姻状况备注' }
        },
        {
          type: 'select',
          title: '抽烟情况',
          field: 'smoker',
          props: { placeholder: '请选择抽烟情况' },
          options: [],
          validate: [
            { required: true, message: '请选择抽烟情况' }
          ]
        },
        {
          type: 'input',
          title: '抽烟情况备注',
          field: 'smoker_remarks',
          props: { placeholder: '请填写抽烟情况备注' }
        },
        {
          type: 'select',
          title: '喝酒情况',
          field: 'alcohol',
          props: { placeholder: '请选择喝酒情况' },
          options: [],
          validate: [
            { required: true, message: '请选择喝酒情况' }
          ]
        },
        {
          type: 'input',
          title: '喝酒情况备注',
          field: 'alcohol_remarks',
          props: { placeholder: '请填写喝酒情况备注' }
        },
        {
          type: 'select',
          title: '睡眠质量',
          field: 'sleep',
          props: { placeholder: '请选择睡眠质量' },
          options: [],
          validate: [
            { required: true, message: '请选择睡眠质量' }
          ]
        },
        {
          type: 'TimePicker',
          title: '睡眠时间',
          field: 'sleep_start_time', style: { width: 'calc(100% - 16px)' },
          suffix: ' ~',
          props: { placeholder: '请选择睡觉时间', format: 'HH:mm', inputReadOnly: true },
          col: {
            span: 7
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          validate: [
            { required: true, message: '请选择睡觉时间' }
          ]
        },
        {
          type: 'TimePicker',
          title: '',
          field: 'sleep_end_time', style: { width: '100%' },
          props: { placeholder: '请选择起床时间', format: 'HH:mm', inputReadOnly: true },
          col: {
            span: 5
          },
          wrap: {
            labelCol: { span: 2 },
            wrapperCol: { span: 19 }
          },
          validate: [
            { required: true, message: '请选择起床时间' }
          ]
        },
        {
          type: 'input',
          title: '睡眠质量备注',
          field: 'sleep_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写睡眠质量备注' }
        },
        {
          type: 'select',
          title: '排便习惯',
          field: 'defecation',
          props: { placeholder: '请选择排便习惯' },
          options: [],
          validate: [
            { required: true, message: '请选择排便习惯' }
          ]
        },
        {
          type: 'input',
          title: '排便习惯备注',
          field: 'defecation_remarks',
          props: { placeholder: '请填写喝酒情况备注' }
        },
        {
          type: 'input',
          title: '身体状况',
          field: 'physical_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写身体状况' }
        },
        {
          type: 'input',
          title: '其他备注说明',
          field: 'other_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他备注说明' }
        },
      ],
      spinning: false,
      tip: '处理中...'
    }
  },
  created () {
    this.$nextTick(() => {
      this.cformPersonalApi.setValue({ patient_id: this.parentParams.patient, sleep_start_time: moment('22:00', 'HH:mm'), sleep_end_time: moment('08:00', 'HH:mm') })
      this.cformPersonalApi.updateRules({ // 更新规则
        'marriage': { options: this.$options.filters.selectOptionsData('patient.marriage') },
        'smoker': { options: this.$options.filters.selectOptionsData('patient.smoker') },
        'alcohol': { options: this.$options.filters.selectOptionsData('patient.alcohol') },
        'sleep': { options: this.$options.filters.selectOptionsData('patient.sleep') },
        'defecation': { options: this.$options.filters.selectOptionsData('patient.defecation') },
      })
    })
    // this.loadPatientOriInfo()
  },
  methods: {
    moment,
    loadPatientOriInfo () {
      // 调用之前单独触发是否显示spinning，请求完成后统一取消
      this.tip = '加载中...'
      getPatientPersonalInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformPersonalApi.setValue({ id: res.data.id, defecation: res.data.defecation.toString(), defecation_remarks: res.data.defecation_remarks, sleep: res.data.sleep.toString(), sleep_start_time: moment(res.data.sleep_start_time, 'HH:mm'), sleep_end_time: moment(res.data.sleep_end_time, 'HH:mm'), sleep_remarks: res.data.sleep_remarks, smoker: res.data.smoker.toString(), smoker_remarks: res.data.smoker_remarks, alcohol: res.data.alcohol.toString(), alcohol_remarks: res.data.alcohol_remarks, marriage: res.data.marriage.toString(), marriage_remarks: res.data.marriage_remarks, physical_remarks: res.data.physical_remarks, other_remarks: res.data.other_remarks })
        }
        this.spinning = false
      })
    },
    savePersonalRequest (data, formObj) {
      if (this.parentParams.serviceRecord < 1) {
        this.$message.error('请先保存客户预约目的及期望相关信息！')
        return false
      }
      this.tip = '处理中...'
      this.spinning = true
      const that = this
      data.sleep_start_time = (typeof data.sleep_start_time === 'object' && data.sleep_start_time._i !== undefined) ? data.sleep_start_time._i : data.sleep_start_time
      data.sleep_end_time = (typeof data.sleep_end_time === 'object' && data.sleep_end_time._i !== undefined) ? data.sleep_end_time._i : data.sleep_end_time
      savePatientPersonalInfo(Object.assign({ service_record_id: this.parentParams.serviceRecord }, data)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '生活信息保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              this.spinning = false
              return that.$router.go(-1)
            },
            onCancel () {
              that.loadPatientOriInfo()
              return false
            }
          })
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    },
  },
})

</script>