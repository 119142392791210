import request from '@/utils/request'

const apiUrls = {
    selectListFoodCompositionCategory: '/FoodCompositions/selectListFoodCompositionCategory',
    listFoodComposition: '/FoodCompositions/listFoodComposition',
}

export function selectListFoodCompositionCategory (parameter) {
    return request({
        url: apiUrls.selectListFoodCompositionCategory,
        method: 'get',
        params: parameter
    })
}

export function listFoodComposition (parameter) {
    return request({
        url: apiUrls.listFoodComposition,
        method: 'get',
        params: parameter
    })
}
