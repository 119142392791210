<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-tabs>
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="desktop" />
              详情页展示内容
            </span>
            <div>
              <a-collapse defaultActiveKey="1">
                <template #expandIcon="props">
                  <a-icon
                    type="plus"
                    v-if="props.panelKey === '1'"
                  />
                  <a-icon
                    type="right"
                    v-else
                    :rotate="props.isActive ? 90 : 0"
                  />
                </template>
                <a-collapse-panel
                  key="1"
                  header="新增展示内容"
                  style="background: #2bff0430;"
                  disabled
                >
                  <a-form
                    @submit="handleSubmit"
                    :form="addShowcaseForm"
                  >
                    <a-row
                      class="form-row"
                      :gutter="16"
                    >
                      <a-col
                        :lg="8"
                        :md="8"
                        :sm="24"
                      >
                        <a-form-item
                          label="展示标题"
                          :labelCol="{ md: 6 }"
                          :wrapperCol="{ md: 18 }"
                        >
                          <a-input
                            placeholder="请填写展示标题"
                            v-decorator="[
                              'title',
                              { rules: [{ required: true, message: '请填写展示标题', whitespace: true }] },
                            ]"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col
                        :lg="8"
                        :md="8"
                        :sm="24"
                      >
                        <a-form-item
                          label="展示类型"
                          :labelCol="{ md: 6 }"
                          :wrapperCol="{ md: 18 }"
                        >
                          <a-select
                            placeholder="请选择展示类型"
                            :options="'showcase.content_type' | selectOptionsData()"
                            @change="ctypeChange"
                            :disabled="ctypeDis"
                            v-decorator="[
                              'content_type',
                              { initialValue:'1',rules: [{ required: true, message: '请选择展示类型' }] },
                            ]"
                          >
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col
                        :lg="8"
                        :md="8"
                        :sm="24"
                      >
                        <a-form-item
                          label="所属模块"
                          :labelCol="{ md: 6 }"
                          :wrapperCol="{ md: 18 }"
                        >
                          <a-select
                            placeholder="请选择所属模块"
                            :options="showcaseTypeData"
                            v-decorator="[
                              'showcase_type',
                              { rules: [{ required: true, message: '请选择所属模块' }] },
                            ]"
                          >
                          </a-select>
                        </a-form-item>
                      </a-col>
                    </a-row>

                    <a-row
                      class="form-row"
                      :gutter="16"
                    >
                      <a-col
                        :lg="24"
                        :md="24"
                        :sm="24"
                      >
                        <a-form-item
                          label="展示内容"
                          :labelCol="{ md: 2 }"
                          :wrapperCol="{ md: 20 }"
                          required
                        >
                          <a-upload
                            v-if="cuCtype === '2'"
                            list-type="picture-card"
                            action="/auth/UploadFiles/upload"
                            method="post"
                            :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                            :beforeUpload="beforeUpload"
                            :file-list="swFileList"
                            :disabled="uploadDisabled"
                            :remove="f=>{rmUpImgFile(f,'ctype2')}"
                            @preview="upImgPreview"
                            @change="fo=>{upImgChange(fo,'ctype2')}"
                          >
                            <div v-if="swFileList.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">
                                点击上传
                              </div>
                            </div>
                          </a-upload>

                          <div :class="cuCtype === '1' ? 'showDiv' : 'noneDiv'">
                            <div id="editorDiv"></div>
                          </div>

                        </a-form-item>
                      </a-col>
                    </a-row>
                    <a-row style="text-align:center;">
                      <a-button
                        htmlType="submit"
                        type="primary"
                      >确认保存</a-button>
                      <a-button
                        class="ml-5"
                        @click="resetSwForm"
                      >重置表单</a-button>
                    </a-row>
                  </a-form>
                </a-collapse-panel>
                <a-collapse-panel
                  v-for="(sv,si) in showcaseList"
                  :key="si + 2"
                >
                  <template slot="header">
                    <span>【{{ sv.showcase_name }}】【{{ 'showcase.content_type' | showText(sv.content_type) }}】</span>
                    <span :class="sv.is_display === 1 ? 'font-success' : 'font-error'">【{{ sv.is_display === 1 ? '显示' : '不显示' }}】</span>
                    <span>{{ sv.title }}</span>
                  </template>
                  <template slot="extra">
                    <div class="mt--5">
                      <a-button
                        :type="sv.is_display === 1 ? 'danger' : 'primary'"
                        :icon="sv.is_display === 1 ? 'eye-invisible' : 'eye'"
                        :title="sv.is_display === 1 ? '设为不显示' : '设为显示'"
                        size="small"
                        @click="evn=>{clickChangeStatus(evn,sv,sv.is_display === 1 ? 0 : 1)}"
                      />
                      <a-button
                        type="primary"
                        icon="form"
                        size="small"
                        title="编辑"
                        class="ml-5"
                        @click="evn=>{clickEditSw(evn,sv)}"
                      />
                      <a-button
                        type="danger"
                        ghost
                        icon="close"
                        size="small"
                        title="删除"
                        class="ml-5"
                        @click="evn=>{clickChangeStatus(evn,sv,-10)}"
                      />
                    </div>
                  </template>
                  <div
                    class="w-e-text"
                    v-if="sv.content_type === 1"
                    v-html="sv.content"
                  ></div>
                  <div
                    v-else
                    style="max-height:120px;"
                  >
                    <img
                      v-if="sv.content_show !== undefined && sv.content_show !== ''"
                      alt="展示内图"
                      style="height: 120px;cursor: pointer;"
                      :src="sv.content_show"
                      @click="handlePreview(sv.content_show)"
                    />
                    <span v-else>暂无展示信息...</span>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="interaction" />
              详情页轮播图
            </span>
            <div v-if="fileList.length > 0">
              <div
                style="display:inline-block;"
                v-for="(fv,fi) in fileList"
                :key="fi"
              >
                <div
                  v-if="fv.status === undefined || fv.status > -10"
                  class="imgDiv"
                >
                  <div
                    class="isShowIcon1"
                    v-if="fv.is_display === 1"
                    title="设为不显示"
                    @click="setOriImg(fi,0)"
                  >
                    <a-icon
                      v-if="fv.is_display === 1"
                      type="eye-invisible"
                    />
                  </div>
                  <div
                    class="isShowIcon2"
                    v-else
                    title="设为显示"
                    @click="setOriImg(fi,1)"
                  >
                    <a-icon type="eye" />
                  </div>
                  <div
                    class="delIcon"
                    title="删除"
                    @click="setOriImg(fi,-10)"
                  >
                    <a-icon type="close" />
                  </div>
                  <img
                    alt="点击查看大图"
                    class="bannerImgs"
                    :src="fv.content"
                    @click="handlePreview(fv.content)"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <h2 class="font-error">暂无轮播图片</h2>
            </div>
            <div>
              <a-upload
                list-type="picture-card"
                action="/auth/UploadFiles/upload"
                method="post"
                :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                :beforeUpload="beforeUpload"
                :file-list="newFileList"
                :remove="f=>{rmUpImgFile(f,'banner')}"
                @preview="upImgPreview"
                @change="fo=>{upImgChange(fo,'banner')}"
              >
                <div v-if="(newFileList.length + fileList.length) < 6">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    点击上传
                  </div>
                </div>
              </a-upload>
            </div>
            <a-button
              type="primary"
              @click="saveGallery"
            >确认保存轮播图</a-button>
          </a-tab-pane>
        </a-tabs>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="展示图"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getEmallGoodGallaryList, updateEmallGoodGallary, getEmallGoodShowcaseList, getEmallGoodShowcaseTypesList, addEmallGoodShowcaseType, changeShowcaseStatus } from '@/api/dms/emallGood'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'
import E from 'wangeditor'

export default {
  name: 'EmallGoodsList',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      fileList: [],
      newFileList: [],
      previewVisible: false,
      previewImg: '',
      showcaseTypeData: [],
      cuCtype: '1',
      addShowcaseForm: this.$form.createForm(this),
      swFileList: [],
      editor: null,
      editorData: '',
      showcaseList: [],
      ctypeDis: false,
      uploadDisabled: false
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoodsShowcases.showManage'))
    if (this.params === null || this.params.shopGood === undefined || this.params.shopGood < 1) {
      this.$message.error('请确认选择商城货品！')
      return this.$router.go(-1)
    }
    this.addShowcaseForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.addShowcaseForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    this.addShowcaseForm.getFieldDecorator('emg', { initialValue: this.params.shopGood, preserve: true, rules: [{ required: true }] })
    this.addShowcaseForm.getFieldDecorator('ctype2_image', { preserve: true })
    this.addShowcaseForm.getFieldDecorator('id', { preserve: true })

    // 获取新增显示内容的所属模块
    getEmallGoodShowcaseTypesList({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.showcaseTypeData = res.data
      }
    })
    this.loadShowcase()
    this.loadGallery()
  },
  mounted () {
    // 初始富文本编辑器
    this.initEditor()
  },
  methods: {
    initEditor () {
      this.editor = new E('#editorDiv')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      this.editor.config.onchange = (newHtml) => {
        this.editorData = newHtml
      }
      this.editor.config.height = 200
      this.editor.config.zIndex = 500
      this.editor.config.placeholder = '请填写展示内容'
      this.editor.config.menus = [
        'undo',
        'redo',
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'backColor',
        'link',
        'justify',
        'quote',
        'emoticon',
        'table',
        'splitLine'
      ]
      this.editor.create()
    },
    loadShowcase () {
      getEmallGoodShowcaseList({ shopGood: this.params.shopGood, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.showcaseList = res.data
        }
      })
    },
    loadGallery () {
      const that = this
      this.spinning = true
      getEmallGoodGallaryList({ shopGood: this.params.shopGood, enterprise: that.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          that.fileList = res.data
        }
        that.spinning = false
      })
    },
    handleCancel () {
      this.previewVisible = false
      // this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImg = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传图片不超过2MB！')
        return false
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'ctype2') {
            this.addShowcaseForm.setFieldsValue({ 'ctype2_image': upRes.data })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'banner') {
          this.newFileList = info.fileList
        }
        if (field === 'ctype2') {
          this.swFileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      if (file.response !== undefined && file.response.status !== undefined && file.response.data !== undefined && file.response.status === 1 && file.response.data !== '') {
        this.spinning = true
        rmFile({ fileName: file.response.data }).then((res) => {
          this.spinning = false
          if (res.status !== undefined && res.status === 1) {
            this.$message.success('删除成功！')
            if (field === 'ctype2') {
              this.addShowcaseForm.setFieldsValue({ 'ctype2_image': '' })
            }
          } else {
            this.$message.error(res.msg || '删除失败！请刷新后重试！')
          }
          return true
        }).catch(() => {
          this.spinning = false
          this.$message.error('删除出错！请刷新后重试！')
          return false
        })
      }
    },
    setOriImg (fidx, setVal) {
      if ([0, 1, -10].indexOf(setVal) < 0 || this.fileList[fidx] === undefined) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      this.spinning = true
      this.tip = '处理中...'
      if (setVal < 0) {
        this.fileList[fidx].status = setVal
        const timer = setTimeout(() => {
          this.$message.success('删除成功！')
          clearTimeout(timer)
          this.spinning = false
        }, 500)
      } else {
        this.fileList[fidx].is_display = setVal
        const timer = setTimeout(() => {
          this.$message.success('设置成功！')
          clearTimeout(timer)
          this.spinning = false
        }, 500)
      }
      this.fileList = [...this.fileList]
    },
    saveGallery () {
      const newOkFile = []
      this.newFileList.map(nv => {
        if (nv.response !== undefined && nv.response.status !== undefined && nv.response.data !== undefined && nv.response.status === 1 && nv.response.data !== '') {
          newOkFile.push(nv.response.data)
        }
      })
      let oriNums = 0
      this.fileList.map(fv => {
        if (fv.is_display === 1 && (fv.status === undefined || fv.status > -10)) {
          oriNums += 1
        }
      })
      const that = this
      if (oriNums < 1 && newOkFile.length < 1) {
        this.$confirm({
          title: '当前没有正常显示的轮播图！确认继续保存？',
          content: false,
          onOk () {
            that.saveGalleryRequest(newOkFile)
          },
          onCancel () { }
        })
      } else {
        that.saveGalleryRequest(newOkFile)
      }
    },
    saveGalleryRequest (newOkFile) {
      this.spinning = true
      this.tip = '处理中...'
      updateEmallGoodGallary({ new: newOkFile, ori: this.fileList, emg: this.params.shopGood, enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('保存成功！')
          this.fileList = []
          this.newFileList = []
          this.loadGallery()
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    ctypeChange (e) {
      this.cuCtype = e
      this.addShowcaseForm.setFieldsValue({ 'content_type': e })
    },
    // 表单提交
    handleSubmit (e) {
      // const that = this
      e.preventDefault()
      this.addShowcaseForm.validateFields((err, values) => {
        if (!err) {
          if (values.content_type === '2' && (values.ctype2_image === undefined || values.ctype2_image === '')) {
            this.$message.error('请确认上传展示图片！')
            return false
          }
          if (values.content_type === '1' && (this.editorData === undefined || this.editorData === '')) {
            this.$message.error('请确认输入展示内容！')
            return false
          }
          values.content = this.editorData
          this.spinning = true
          addEmallGoodShowcaseType(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.resetSwForm()
              this.showcaseList = []
              this.loadShowcase()
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    clickChangeStatus (evn, record, cstatus) {
      evn.stopPropagation()
      // 修改状态
      if ([0, 1, -10].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      const that = this
      this.$confirm({
        title: '确认修改该商城货品展示内容的状态？',
        content: false,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          that.tip = '处理中...'
          that.spinning = true
          changeShowcaseStatus({ swid: record.id, cstatus, shopGood: that.params.shopGood }).then(res => {
            if (res.status !== undefined && res.status === 1) {
              that.$message.success('操作成功！')
              that.showcaseList = []
              that.loadShowcase()
            } else {
              that.$message.error(res.msg || '操作失败！请刷新后重试！')
            }
            that.spinning = false
            that.tip = '加载中...'
          })
        },
        onCancel () { }
      })
    },
    clickEditSw (evn, record) {
      // 禁止触发折叠卡的事件
      evn.stopPropagation()
      // 滚动到顶部
      document.documentElement.scrollTop = 65
      this.ctypeDis = true
      this.uploadDisabled = false
      this.cuCtype = record.content_type.toString()
      this.addShowcaseForm.setFieldsValue({
        id: record.id,
        title: record.title,
        content_type: record.content_type.toString(),
        showcase_type: record.showcase_type.toString()
      })
      if (this.cuCtype === '2' && record.content_show !== undefined && record.content_show !== '') {
        this.uploadDisabled = true
        // 图片
        this.swFileList = [{
          uid: '-1',
          name: record.content,
          status: 'done',
          url: record.content_show
        }]
        this.addShowcaseForm.setFieldsValue({ ctype2_image: record.content })
        this.editorData = ''
      } else {
        this.addShowcaseForm.setFieldsValue({ ctype2_image: '' })
        this.editorData = record.content
        this.editor.txt.html(record.content) // 重新设置编辑器内容
      }
    },
    resetSwForm () {
      // 重置显示内容的表单
      this.ctypeDis = false
      this.uploadDisabled = false
      this.cuCtype = '1'
      this.addShowcaseForm.resetFields()
      this.swFileList = []
      this.editor.txt.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-badge-status-text {
  margin-left: 3px !important;
}
.bannerImgs {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.imgDiv {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  position: relative;
}
.isShowIcon1,
.isShowIcon2,
.delIcon {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.delIcon {
  right: 0;
  background: #f800004d;
  color: red;
}
.isShowIcon1 {
  right: 22px;
  background: #ffb10061;
  color: #ff8900;
}
.isShowIcon2 {
  right: 22px;
  background: #42fa058c;
  color: #29a600;
}
.showDiv {
  display: block;
}
.noneDiv {
  display: none;
}
.rightBtnDiv {
  position: absolute;
  top: 10px;
  right: 10px;
}
.mt--5 {
  margin-top: -5px;
}
</style>
