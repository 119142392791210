<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-row class="mb-20">
        <a-col
          :span="24"
          class="text-right pr-35"
        >
          <a-button
            @click="printClick"
            type="primary"
          >打印食谱</a-button>
          <a-button
            class="ml-10"
            @click="downloadClick(0)"
            type="primary"
          >下载食谱(压缩版)</a-button>
        </a-col>
      </a-row>

      <div v-if="patientInfo.id !== undefined && patientInfo.id > 0">
        <div
          id="printDiv"
          @click="printClick"
        >
          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage0"
              ref="alanberPage0"
            >
              <nutrition-page-1 :showTitle="{text:'定制食谱',textEn:'Customized Recipe'}" />
            </div>
          </a-card>

          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage1"
              ref="alanberPage1"
            >
              <nutrition-page-2 :showMark="'patientFood'" />
            </div>
          </a-card>

          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage3"
              ref="alanberPage3"
            >
              <food-page-3
                :patientInfo="patientInfo"
                :pageIndex="1"
                :pageTotal="pageTotal"
                :patientFoodOri="patientFoodOri"
              />
            </div>
          </a-card>
          <template v-if="patientFoodOri.id !== undefined && patientFoodOri.id > 0">
            <food-page-4
              :patientInfo="patientInfo"
              :pageIndex="2"
              :pageTotal="pageTotal"
              :patientFoodOri="patientFoodOri"
            />
          </template>
        </div>
      </div>

      <a-row class="mt-20">
        <a-col
          :span="24"
          class="text-center"
        >
          <a-button
            @click="printClick"
            type="primary"
          >打印食谱</a-button>
          <a-button
            class="ml-10"
            @click="downloadClick(0)"
            type="primary"
          >下载食谱(压缩版)</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getPatientInfo } from '@/api/dms/patient'
import { viewPatientFoodMenu } from '@/api/dms/patientFoodMenu'
import printJS from 'print-js'
import moment from 'moment'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { NutritionPage1, NutritionPage2, FoodPage3, FoodPage4, NutritionFooter } from './Pages'

export default ({
  name: 'PrintPatientFood',
  components: {
    NutritionPage1,
    NutritionPage2,
    FoodPage3,
    FoodPage4,
    NutritionFooter
  },
  props: {
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    parentParams: {
      handler (newVal, oldVal) {
        // 参数重置，父级关闭弹窗可以将传入的参数值为空，监听到变化将参数重置后页面不会显示dom
        this.patientInfo = {}
        this.patientFoodOri = {}
        this.requestOkNum = 0
        this.pageTotal = 1
        console.log(this.parentParams)
        // 当传入了fm的id的时候开始发起获取数据的请求
        if (this.parentParams.fm !== undefined && this.parentParams.fm > 0) {
          this.$emit('spinningChange', true)
          this.loadPatientInfo()
          this.loadPatientFoodInfo()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    },
    requestOkNum: {
      handler (newVal, oldVal) {
        if (this.requestOkNum === 2) {
          // 所有请求都回来了，弹出打印窗口
          const that = this
          const timer = setTimeout(() => {
            // printJS({
            //   printable: 'printDiv', type: 'html',
            //   targetStyles: ['*'], //处理css样式 *为处理所有，也可以指定})
            // })
            clearTimeout(timer)
            that.$emit('spinningChange', false)
          }, 500)
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    },
    cuIdx: {
      handler (newVal, oldVal) {
        this.$nextTick(function () {
          if (this.cuIdx === 0 || this.pageLen === 0) {
            return false
          }
          if (this.cuIdx > (this.pageLen - 1) || this.pageHtmls[this.cuIdx] === undefined || this.pageHtmls[this.cuIdx] === null || this.pageHtmls[this.cuIdx] === '') {
            this.pageDataOkDownload()
          } else {
            this.dealPageData()
          }
          return true
        })
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      tip: '数据处理中...',
      patientInfo: {},
      patientFoodOri: {},
      requestOkNum: 0,
      pageTotal: 1,
      pdfObj: null, // new JsPDF('p', 'pt', 'a4'),
      pageHtmls: null,
      pageLen: 0,
      cuIdx: 0,
      downloadParam: {
        scale: [2, 3],
        imgData: [0.3, 0.8]
      },
      paramIdx: 1
    }
  },
  created () {
  },
  methods: {
    moment,
    loadPatientInfo () {
      // 客户基础信息
      getPatientInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.parentParams.patient) {
          this.patientInfo = res.data
          console.log('patientInfo：', this.patientInfo)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        }
      })
    },
    loadPatientFoodInfo () {
      // 加载客户食谱数据
      viewPatientFoodMenu({ menu: this.parentParams.fm, user: this.$store.getters.userInfo.user.id, join: ['day', 'recipe'] }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          console.log('Food：', res.data)
          if (res.data.patient_food_menu_day.length > 0) {
            this.pageTotal = 1 + res.data.patient_food_menu_day.length
          }
          this.patientFoodOri = res.data
        }
      })
    },
    printClick () {
      this.$nextTick(() => {
        printJS({
          printable: 'printDiv',
          type: 'html',
          // targetStyles: ['*'], //处理css样式 *为处理所有，也可以指定})
          scanStyles: true,
          maxWidth: 1240,
          targetStyles: ['*'],
          font_size: ''
        })
      })
    },
    downloadClick (param) {
      this.tip = '努力加载数据中...'
      this.spinning = true
      this.btnLoading = true
      this.paramIdx = param
      this.pdfObj = null
      this.pageHtmls = null
      this.pageLen = 0
      this.cuIdx = 0
      const that = this
      const st = setTimeout(() => {
        that.downloadPdf()
        // 保存下载记录到后台 需要的时候在写
        clearTimeout(st)
      }, 100)
    },
    downloadPdf () {
      this.pdfObj = new JsPDF('p', 'pt', 'a4')
      this.pageHtmls = document.getElementsByClassName('nutritionPage')
      if (this.pageHtmls === undefined || this.pageHtmls.length < 1) {
        this.$message.error('获取报告数据失败！')
        return false
      }
      this.pageLen = this.pageHtmls.length
      this.dealPageData()
    },
    dealPageData () {
      const that = this
      if (this.pageHtmls[this.cuIdx] === undefined || this.pageHtmls[this.cuIdx] === null || this.pageHtmls[this.cuIdx] === '') {
        return false
      }
      // 检测到不是第一页就需要新增页
      if (this.cuIdx > 0) {
        this.pdfObj.addPage()
      }
      // scale:1大概1MB一页（明显模糊点），2大概2.5MB多一页（效果还行），3大概4MB多一页
      // toDataURL：1
      // 原3-1，3-0.5=1MB多，2-0.7=1MB，2-0.5=0.8MB，2-0.3=0.5MB   useCORS 允许html中的图片跨域, useCORS: true
      html2canvas(this.pageHtmls[this.cuIdx], { scale: this.downloadParam.scale[this.paramIdx] }).then(function (canvas) {
        that.tip = '方案处理中(' + (that.cuIdx + 1) + '/' + that.pageLen + ')...'
        console.log(canvas)
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const imgWidth = 595.28
        const imgHeight = (592.28 / contentWidth) * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', that.downloadParam.imgData[that.paramIdx])

        that.pdfObj.setPage(that.cuIdx + 1)
        that.pdfObj.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        that.cuIdx++
      })
    },
    pageDataOkDownload () {
      this.pdfObj.save(this.patientInfo.name + '.' + moment().format('yyyyMMDD') + '.pdf')
      this.spinning = false
    },
  }
})

</script>

<style lang="scss" scoped>
.nutritionPage {
  height: 1670px;
  width: 1175px;
  background-color: white;
  // border: 1px solid red;
  // margin-top: 10px;
  page-break-after: always;
}

.paddingDiv {
  height: 1670px;
  width: 1175px;
  margin-bottom: 10px;
}
</style>