<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入部门名称、负责人搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.listDepartmentTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/Enterprises/addDep'"
                      :hidden="'/Enterprises/addDep' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增部门</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="listDepartmentTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a
                v-if="record.is_admin !== 1"
                :hidden="'/Enterprises/editDep' | isShowButton"
                @click="clickEdit(record)"
              >编辑</a>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listDepartment } from '@/api/dms/department'

export default {
  name: 'DepartmentList',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 80,
          ellipsis: true,
          dataIndex: 'id'
        },
        {
          title: '部门名称',
          width: 150,
          ellipsis: true,
          dataIndex: 'department_name'
        },
        {
          title: '负责人',
          ellipsis: true,
          dataIndex: 'principal'
        },
        {
          title: '联系电话',
          ellipsis: true,
          dataIndex: 'telphone'
        },
        {
          title: '上级部门',
          ellipsis: true,
          dataIndex: 'pdep_name'
        },
        {
          title: '类型',
          ellipsis: true,
          dataIndex: 'type',
          width: 110,
          customRender: (text, row) => {
            return text === 0 ? '虚拟组' : '实体部门'
          }
        },
        {
          title: '部门描述',
          ellipsis: true,
          dataIndex: 'describle'
        },
        {
          title: '排序',
          ellipsis: true,
          width: 80,
          dataIndex: 'sort'
        },
        {
          title: '创建时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'add_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 85,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return listDepartment(requestParameters)
          .then(res => {
            console.log(res)
            return res
          })
      }
    }
  },
  created () {

  },
  computed: {

  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('Enterprises.editDep', JSON.stringify({ dep: record.id }))
      this.$router.push({ path: '/Enterprises/editDep' })
    }
  }
}
</script>
