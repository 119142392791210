import Vue from 'vue'
import store from '@/store'

// 过滤是否显示按钮的
Vue.filter('isShowButton', function (cuPermission) {
  if (typeof cuPermission === 'object') {
    // 返回true则会不显示
    for (const cp in cuPermission) {
      if (store.state.permission.permissionList.indexOf(cuPermission[cp]) >= 0) {
        return false
      }
    }
    return true
  } else {
    return store.state.permission.permissionList.indexOf(cuPermission) === -1
  }
})

// 过滤要显示的字典表的文本值 mark：标识 statusNum：要显示值
Vue.filter('showText', function (mark, statusNum, splitMark = '、') {
  if (store.getters.dictionariesList !== false && statusNum !== undefined && statusNum !== null) { // 没有加载过
    if (typeof statusNum === 'string' || typeof statusNum === 'number') {
      if (store.getters.dictionariesList[mark] !== undefined && store.getters.dictionariesList[mark][statusNum] !== undefined) {
        return store.getters.dictionariesList[mark][statusNum]
      }
    }
    if (typeof statusNum === 'object') {
      const cuStrArr = []
      if (store.getters.dictionariesList[mark] !== undefined) {
        statusNum.map(cusv => {
          if (store.getters.dictionariesList[mark][cusv] !== undefined) {
            cuStrArr.push(store.getters.dictionariesList[mark][cusv])
          }
        })
      }
      return cuStrArr.join(splitMark)
    }
  }
  return ''
})

// 过滤要显示的字典表的下拉列表 mark：标识 except：需要排除的选项 only：只要指定的选项
Vue.filter('selectOptionsData', function (mark, except = [], only = []) {
  const opData = []
  if (store.getters.dictionariesList !== false) { // 没有加载过
    if (store.getters.dictionariesList[mark] !== undefined) {
      for (const k in store.getters.dictionariesList[mark]) {
        // 只要指定的选项
        if (only.length >= 1) {
          if (only.indexOf(parseInt(k)) >= 0) {
            opData.push({ 'value': k, 'label': store.getters.dictionariesList[mark][k] })
          }
        } else {
          // 排除不要的选项
          if (except.length < 1 || except.indexOf(parseInt(k)) < 0) {
            opData.push({ 'value': k, 'label': store.getters.dictionariesList[mark][k] })
          }
        }
      }
    }
  }
  return opData
})

// 字典表text保存的json时，只获取其中的指定字段值【比如：食谱的营养映射关系中只要所有的字段名的情况】
// mark标识，field需要返回的json中数据的字段名，condField若有需要过滤的数据则传入json中的字段名,condArr要过滤掉的条件
Vue.filter('getDicTextFieldValueByKey', function (mark,field,condField='', condArr = []) {
  const valData = []
  if (store.getters.dictionariesList !== false) { // 没有加载过
    if (store.getters.dictionariesList[mark] !== undefined) {
      for (const k in store.getters.dictionariesList[mark]) {
        const cuTextObj = JSON.parse(store.getters.dictionariesList[mark][k])
        if (cuTextObj[field] === undefined) {
          continue
        }
        if (condField !== undefined && condField !== null && condField !== '' && cuTextObj[condField] !== undefined && condArr.indexOf(cuTextObj[condField]) >= 0) {
          // 满足排除条件直接跳过
          continue
        }
        valData.push(cuTextObj[field])
      }
    }
  }
  return valData
})

// 获取字典表的text数据并按value排序
// mark标识，sort是否安value值排序，condField若有需要过滤的数据则传入json中的字段名,condArr要过滤掉的条件
Vue.filter('getDicTextArrByKey', function (mark,condField='', condArr = [],sort=true,sortType='asc') {
  const valData = []
  if (store.getters.dictionariesList !== false) { // 没有加载过
    if (store.getters.dictionariesList[mark] !== undefined) {
      for (const k in store.getters.dictionariesList[mark]) {
        const cuTextObj = JSON.parse(store.getters.dictionariesList[mark][k])
        if (condField !== undefined && condField !== null && condField !== '' && cuTextObj[condField] !== undefined && condArr.indexOf(cuTextObj[condField]) >= 0) {
          // 满足排除条件直接跳过
          continue
        }
        cuTextObj.value = parseInt(k)
        valData.push(cuTextObj)
      }
      // 处理排序
      if (sort) {
        valData.sort((a, b) => {
          if (sortType === 'asc') {
            // 正序
            return a.value >= b.value
          } else {
            return b.value >= a.value
          }
        })
      }
    }
  }
  return valData
})

// 过滤是否显示两个按钮之间的|分隔符
Vue.filter('isShowSeparator', function (cuPermission, beforAll) {
  // 当前权限要显示且之前有大于等于1个的权限时显示的 则分隔符要显示
  let cuShow = true
  if (typeof cuPermission === 'object') {
    // 返回true则会不显示
    for (const cp in cuPermission) {
      if (store.state.permission.permissionList.indexOf(cuPermission[cp]) >= 0) {
        cuShow = false
        break
      }
    }
  } else {
    cuShow = store.state.permission.permissionList.indexOf(cuPermission) === -1
  }
  if (cuShow || typeof beforAll !== 'object') {
    // 返回true则会不显示
    return true
  } else {
    for (const cp in beforAll) {
      if (store.state.permission.permissionList.indexOf(beforAll[cp]) >= 0) {
        return false
      }
    }
    return true
  }
})

// 根据字符串从指定位置开始的指定长度标记，一般用于电话号码中间标识*
Vue.filter('noteString', function (str, start, len, mark = '*') {
  if (str === undefined || typeof str !== 'string' || str === '' || str === null) {
    return ' - '
  }
  let allMark = ''
  for (let l = 1; l <= len; l++) {
    allMark += mark
  }
  const end = start + len
  const resStr = str.substr(0, start) + allMark + str.substr(end)
  return resStr.substr(0, str.length)
})

// 获取数组中的指定几个值组成的字符串，一般用于数据库按,分隔的数据回显时使用，比如user表的allows_dep在显示详情的页面使用
Vue.filter('showAllowsText', function (values, arr, mark = '，') {
  if (values === '' || values === null) {
    return ''
  }
  // 转换为数组处理
  if (typeof values === 'string') {
    values = values.split(',')
  }
  if (values.length < 1 || typeof arr !== 'object' || arr.length < 1) {
    return ''
  }
  const showText = []
  arr.map(v => {
    if (values.indexOf(v.value) >= 0) {
      showText.push(v.label)
    }
  })
  return showText.join(mark)
})

// 过滤要显示的字段值，判断空值的情况，不为空返回自己，为空返回指定的替代空值内容
Vue.filter('showTextNotNull', function (cuVal, emptyText = '') {
  return cuVal !== undefined && cuVal !== null && cuVal !== '' ? cuVal : emptyText
})

// 过滤要显示的匹配中的tag文本内容
Vue.filter('showMatchingTagText', function (oriArr,oriMatchField,oriReturnField,condArr) {
  const matchArr = []
  oriArr.map(ov => {
    if (ov[oriMatchField] !== undefined && ov[oriReturnField] !== undefined && condArr.indexOf(ov[oriMatchField].toString()) >= 0) {
      matchArr.push(ov[oriReturnField])
    }
  })
  return matchArr
})
