<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveUserRequest"
          ></form-create>

          <a-table
            :columns="entUserColumns"
            :data-source="entUserData"
            bordered
            :pagination="false"
            rowKey="target_enterprise"
          >
            <span
              slot="action"
              slot-scope="text, record,idx"
            >
              <a-popconfirm
                title="确定删除该企业操作权限？"
                ok-text="删除"
                cancel-text="取消"
                @confirm="clickRemoveEu(idx)"
              >
                <a class="font-error">删除</a>
              </a-popconfirm>
            </span>
          </a-table>

          <a-row class="mt-20">
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListRole } from '@/api/dms/adminRole'
import { selectListDepartment } from '@/api/dms/department'
import { getAdminUserInfo, updateAdminUser, selectListUser, removeEntUser } from '@/api/dms/user'
import { selectListRelationEnterprise } from '@/api/dms/enterprise'
import { checkMobile, checkUserName, checkEmail } from '@/utils/customValidate'
import { rmFile } from '@/api/manage'

export default {
  name: 'EditPlatformUser',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '登录用户名',
          field: 'user_name',
          props: { placeholder: '请填写登录用户名' },
          validate: [
            { required: true, message: '请确认输入登录用户名', whitespace: true },
            { validator: checkUserName }
          ]
        },
        {
          type: 'input',
          title: '姓名/昵称',
          field: 'nick_name',
          props: { placeholder: '请填写姓名/昵称' },
          validate: [
            { required: true, min: 1, max: 12, message: '请填写1~12位姓名/昵称', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'mobile',
          props: { placeholder: '请填写联系电话' },
          validate: [
            { required: true, message: '请填写联系电话', whitespace: true },
            { validator: checkMobile }
          ]
        },
        {
          type: 'a-radio-group',
          title: '性别',
          value: '0',
          field: 'gender',
          props: { buttonStyle: 'solid', placeholder: '请选择性别' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['保密']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['男']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['女']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '操作人员类型',
          field: 'user_type',
          props: { placeholder: '请选择操作人员类型' },
          options: [],
          validate: [
            { required: true, message: '请选择操作人员类型' }
          ]
        },
        {
          type: 'select',
          title: '可查看医生',
          field: 'allows_rdc',
          props: { mode: 'multiple', placeholder: '请选择可查看医生', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'input',
          title: '邮箱',
          field: 'email',
          props: { placeholder: '请填写邮箱' },
          validate: [
            { validator: checkEmail }
          ]
        },
        {
          type: 'upload',
          field: 'header',
          title: '头像',
          // value: ['http://localhost:8233/logo_ryx.png'],
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            // fileList: ['http://localhost:8233/logo_ryx.png'],
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/avatar' },
            limit: 1,
            listType: 'picture-card'
          },
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['账号所属企业']
        },
        {
          type: 'select',
          title: '所属企业',
          field: 'target_enterprise',
          props: { placeholder: '请选择所属企业' },
          options: [],
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          validate: [
            { required: true, message: '请选择所属企业' }
          ],
          on: {
            change: (v) => this.enterpriseChange(v, '')
          }
        },
        {
          type: 'select',
          title: '所属部门',
          field: 'department_id',
          props: { placeholder: '请选择所属部门' },
          options: [],
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          validate: [
            { required: true, message: '请选择所属部门' }
          ]
        },
        {
          type: 'select',
          title: '可操作部门',
          field: 'allows_dep',
          props: { mode: 'multiple', placeholder: '请选择可操作部门', maxTagCount: 1, maxTagTextLength: 4, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          validate: [
            { required: true, message: '请选择可操作部门' }
          ]
        },
        {
          type: 'select',
          title: '用户角色',
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          field: 'role_id',
          props: { mode: 'multiple', placeholder: '请选择用户角色', maxTagCount: 1, maxTagTextLength: 4, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择用户角色' }
          ]
        },
        {
          type: 'a-divider',
          name: 'a-divider',
          style: { marginTop: 0 }
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['可操作企业设置']
        },
        {
          type: 'select',
          title: '可操作企业',
          field: 'target_enterprise2',
          props: { placeholder: '请选择可操作企业' },
          options: [],
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          on: {
            change: (v) => this.enterpriseChange(v, '2')
          }
        },
        {
          type: 'select',
          title: '所属部门',
          field: 'department_id2',
          props: { placeholder: '请选择所属部门' },
          options: [],
          col: {
            span: 5
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          }
        },
        {
          type: 'select',
          title: '可操作部门',
          field: 'allows_dep2',
          props: { mode: 'multiple', placeholder: '请选择可操作部门', maxTagCount: 1, maxTagTextLength: 3, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          col: {
            span: 5
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          }
        },
        {
          type: 'select',
          title: '用户角色',
          col: {
            span: 5
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          field: 'role_id2',
          props: { mode: 'multiple', placeholder: '请选择用户角色', maxTagCount: 1, maxTagTextLength: 3, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'a-button',
          class: 'mt-5 ml-10',
          name: 'a-button',
          props: { icon: 'plus', type: 'primary' },
          children: ['确认添加'],
          on: {
            click: () => this.addEntUser()
          }
        },
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      headerVal: '',
      selectListUser: [],
      entListArr: [],
      entUserColumns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: 70,
          ellipsis: true,
          align: 'center',
          customRender: (t, r, i) => {
            return i + 1
          }
        },
        {
          title: '企业名称',
          dataIndex: 'enterprise_name',
          ellipsis: true,
        },
        {
          title: '所属部门',
          dataIndex: 'dep_name',
          ellipsis: true,
        },
        {
          title: '可操作部门',
          dataIndex: 'allow_name',
          ellipsis: true,
        },
        {
          title: '用户角色',
          dataIndex: 'role_name',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'action' }
        },
      ],
      entUserData: [],
      entUserDataTmp: [],
      cuDepArrData: [],
      cuRoleArrData: [],
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.editPlatformUser'))
    if (this.params === null || this.params.user === undefined || this.params.user < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getAdminUserInfo({ user: this.params.user, join: ['ent_user'] }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.user) {
        this.headerVal = res.data.header
        res.data.header = res.data.header_show
        res.data.gender = res.data.gender.toString()
        res.data.user_type = res.data.user_type.toString()
        res.data.department_id = res.data.department_id > 0 ? res.data.department_id.toString() : undefined
        res.data.allows_dep = (res.data.allows_dep !== '' && res.data.allows_dep !== null) ? res.data.allows_dep.split(',') : []
        res.data.allows_rdc = (res.data.allows_rdc !== '' && res.data.allows_rdc !== null) ? res.data.allows_rdc.split(',') : []
        res.data.role_id = (res.data.role_id !== '' && res.data.role_id !== null) ? res.data.role_id.split(',') : []
        this.cformApi.setValue(res.data)
        this.enterpriseChange(res.data.target_enterprise, '', true)
        // this.initEntUserList(res.data)
        this.refreshList(res.data)
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch((err) => {
      console.log(err)
      return this.$router.go(-1)
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.headerVal = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.headerVal }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.cformApi.setValue({ header: [] })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    refreshList (oriData) {
      // this.spinning = true
      selectListRelationEnterprise({ main: this.$store.getters.userInfo.user.target_enterprise, type: 3 }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          res.data.map(ev => {
            this.entListArr.push({ label: ev.relation_name, value: ev.relation_enterprise_id })
          })
          this.cformApi.updateRules({ // 更新规则
            'target_enterprise': { options: this.entListArr },
            'target_enterprise2': { options: this.entListArr }
          })
          // 初始组装可操作企业数据
          oriData.enterprise_user.map((ev, ei) => {
            const entGet = this.entListArr.filter((v) => {
              return v.value === ev.enterprise_id && ev.enterprise_id !== oriData.target_enterprise
            })
            if (entGet.length > 0) {
              this.entUserDataTmp[ei] = { enterprise_name: entGet[0].label, dep_name: '', allow_name: '', role_name: '', target_enterprise: ev.enterprise_id, department_id: ev.department_id, allows_dep: ev.allows_dep, role_id: ev.role_id, eu_id: ev.id }
            }
          })
          // this.entUserData = [...this.entUserData]
          // console.log(this.entUserData)
          if (this.entUserDataTmp.length > 0) {
            this.initEntUserList()
          }
        }
      })

      // enterprise: this.$store.getters.userInfo.user.target_enterprise, 
      selectListUser({ user_type: 50 }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.docListData = res.data
          this.cformApi.updateRules({ // 更新规则
            'allows_rdc': { options: this.docListData },
            'user_type': { options: this.$options.filters.selectOptionsData('user.type', [], [50, 99]) }
          })
        }
        // this.spinning = false
      })
    },
    enterpriseChange (v, ext, iniMark = false) {
      if (!iniMark) {
        this.cformApi.setValue({ ['department_id' + ext]: null, ['allows_dep' + ext]: null, ['role_id' + ext]: null })
      }
      let fmData = this.cformApi.formData()
      if (fmData.target_enterprise2 === fmData.target_enterprise) {
        this.$message.error('上方账号所属企业已选择该企业！无需再次选择！')
        this.cformApi.setValue({ target_enterprise2: null })
        return false
      }
      // 检测列表中是否已选择
      const entCheck = this.entUserData.filter(ev2 => {
        return ev2.target_enterprise === fmData.target_enterprise2
      })
      if (entCheck.length > 0) {
        this.cformApi.setValue({ target_enterprise2: null })
        this.$message.error('当前选择企业已存在下方列表中！要修改请先删除下方列表中数据！')
        return false
      }
      this.loadDepAndRoleByEnt(v, ext)
    },
    loadDepAndRoleByEnt (ent, ext) {
      this.spinning = true
      this.cuDepArrData = []
      this.cuRoleArrData = []
      selectListDepartment({ enterprise: ent }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cuDepArrData = res.data
          this.cformApi.updateRules({ // 更新规则
            ['department_id' + ext]: { options: res.data },
            ['allows_dep' + ext]: { options: res.data }
          })
        }
      })
      selectListRole({ plattype: this.$store.getters.userInfo.platform.id, enterprise: ent }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cuRoleArrData = res.data
          this.cformApi.updateRules({ // 更新规则
            ['role_id' + ext]: { options: res.data }
          })
        } else {
          this.$message.error(res.msg || '获取可选角色失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    saveUserRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      data.header = this.headerVal
      data.platform = this.$store.getters.userInfo.platform.id
      data.ent_user = this.entUserData
      updateAdminUser(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              window.location.reload()
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    addEntUser () {
      let fmData = this.cformApi.formData()
      if (fmData.target_enterprise2 === undefined || fmData.department_id2 === undefined || fmData.allows_dep2 === undefined || fmData.role_id2 === undefined || fmData.target_enterprise2 === null || fmData.department_id2 === null || fmData.allows_dep2 === null || fmData.role_id2 === null || fmData.target_enterprise2 < 1 || fmData.department_id2 < 1 || fmData.allows_dep2 < 1 || fmData.role_id2 < 1) {
        this.$message.error('请确认企业操作权限4个选项都有值！')
        return false
      }
      if (fmData.target_enterprise2 === fmData.target_enterprise) {
        this.$message.error('上方账号所属企业已选择该企业！无需再次选择！')
        return false
      }
      // 检测企业数据是否存在
      const entGet = this.entListArr.filter((ev) => {
        return ev.value === fmData.target_enterprise2
      })
      if (entGet.length < 1) {
        this.$message.error('请确认选择的可操作企业存在！')
        return false
      }
      // 检测列表中是否已选择
      const entCheck = this.entUserData.filter(ev2 => {
        return ev2.target_enterprise === fmData.target_enterprise2
      })
      if (entCheck.length > 0) {
        this.$message.error('当前选择企业已存在下方列表中！要修改请先删除下方列表中数据！')
        return false
      }
      // 检测选择的部门和角色
      const getRole = []
      this.cuRoleArrData.map(rv => {
        if (fmData.role_id2.indexOf(rv.value) >= 0) {
          getRole.push(rv.label)
        }
      })
      let getDep = ''
      const getAllowDep = []
      this.cuDepArrData.map(dv => {
        if (fmData.allows_dep2.indexOf(dv.value) >= 0) {
          getAllowDep.push(dv.label)
        }
        if (fmData.department_id2 === dv.value) {
          getDep = dv.label
        }
      })
      this.entUserData.push({ enterprise_name: entGet[0].label, dep_name: getDep, allow_name: getAllowDep.join('、'), role_name: getRole.join('、'), target_enterprise: fmData.target_enterprise2, department_id: fmData.department_id2, allows_dep: fmData.allows_dep2, role_id: fmData.role_id2 })
    },
    clickRemoveEu (idx) {
      if (this.entUserData[idx] === undefined) {
        this.$message.error('请确认所选企业操作权限存在可操作！')
        return false
      }
      if (this.entUserData[idx].eu_id !== undefined && this.entUserData[idx].eu_id > 0) {
        this.spinning = true
        removeEntUser({ ent_user: this.entUserData[idx].eu_id, platform: this.$store.getters.userInfo.platform.id, user: this.params.user }).then(res => {
          if (res.status !== undefined && res.status === 1) {
            this.entUserData.splice(idx, 1)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
          }
          this.spinning = false
        })
      } else {
        this.entUserData.splice(idx, 1)
      }
    },
    initEntUserList () {
      // 根据原始已有的数据初始可操作企业列表数据
      this.spinning = true
      this.cuDepArrData = []
      this.cuRoleArrData = []
      const tmpAllNum = this.entUserDataTmp.length - 1
      let depOk = 0
      let roleOk = 0
      selectListDepartment().then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.entUserDataTmp = this.entUserDataTmp.map((ev, ei) => {
            let getDep = res.data.filter(av => {
              return av.value === ev.department_id.toString()
            })
            const getAllowDep = []
            ev.allows_dep.split(',').map(av => {
              res.data.map(v => {
                if (v.value === av) {
                  getAllowDep.push(v.label)
                }
              })
            })
            ev.dep_name = getDep.length > 0 ? getDep[0].label : ''
            ev.allow_name = getAllowDep.join('、')
            depOk++
            return ev
          })
        }
      })
      selectListRole({ plattype: this.$store.getters.userInfo.platform.id }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.entUserDataTmp = this.entUserDataTmp.map((ev, ei) => {
            const getRole = []
            ev.role_id.split(',').map(rv => {
              res.data.map(v => {
                if (v.value === rv) {
                  getRole.push(v.label)
                }
              })
            })
            ev.role_name = getRole.join('、')
            roleOk++
            return ev
          })
          // console.log(this.entUserData)
          // this.entUserData = [...this.entUserData]
        }
      })
      const timer = setInterval(() => {
        if (tmpAllNum === depOk && tmpAllNum === roleOk) {
          this.entUserData = Object.values(this.entUserDataTmp)
          this.spinning = false
          clearInterval(timer)
        }
      }, 100)
    }
  }
}

</script>

<style lang="scss" scoped>
</style>