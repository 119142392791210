<template>

  <!-- Task card -->
  <a-card
    @click="$emit('edit', task.id, boardId)"
    :bordered="false"
    class="kanban-card mb-10"
  >

    <img
      v-if="task.cover"
      :src="task.cover"
      class="mb-15"
    >

    <a-tag
      v-if="task.badge"
      class="mb-15"
      :class="[task.badge.class]"
    >{{ task.badge.text }}</a-tag>

    <h6
      v-if="task.title"
      class="font-regular text-md mb-0"
      :class="[task.description || task.progress || task.attachments || task.members ? 'mb-10' : '']"
    >{{ task.title }}</h6>

    <p v-if="task.description">{{ task.description }}</p>

    <div
      v-if="task.description_multiple"
      v-html="task.description_multiple"
    ></div>

    <a-row
      type="flex"
      v-if="task.type && task.type == 'dietary_pattern' "
    >
      <a-col
        :span="24"
        v-if="task.recommendation !== '' "
      >
        {{ task.recommendation}}
      </a-col>
      <a-col>
        {{ task.label }}
      </a-col>
      <a-col class="ml-auto list-settings-sessions">
        <a-button
          type="link"
          class="btn-more pr-0"
          @click="clickshowPattern(task.val)"
        >
          查看介绍 <a-icon type="arrow-right" />
        </a-button>
      </a-col>
    </a-row>

    <a-row
      type="flex"
      align="stretch"
      v-if="task.type && task.type == 'chartDoughnut' "
    >
      <a-col :span="24">
        <NutritionChartDoughnut
          :chartData="task.chartData"
          :height="200"
        >
        </NutritionChartDoughnut>
      </a-col>
    </a-row>

    <a-progress
      v-if="task.progress"
      :percent="task.progress.value"
      :status="task.progress.status"
      :strokeColor="task.progress.color"
      :show-info="false"
    />

    <a-row
      v-if="task.attachments || task.members"
      type="flex"
      class=""
    >
      <a-col>
        <div v-if="task.attachments">
          <a-icon
            type="paper-clip"
            class="m-0"
          /> {{ task.attachments }}
        </div>
      </a-col>
      <a-col class="ml-auto">
        <a-space
          v-if="task.members && task.members.length"
          :size="-8"
          class="avatar-chips"
        >
          <a-avatar
            :size="24"
            v-for="avatar in task.members"
            :key="avatar"
            :src="avatar"
          />
        </a-space>
      </a-col>
    </a-row>

    <!-- 饮食模式的抽屉窗口 -->
    <dietary-pattern-detail
      :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
      :parentParams="dietaryPatternParams"
      @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
    />
  </a-card>
  <!-- / Task card -->

</template>

<script>
import { DietaryPatternDetail } from '@/components/DietaryPattern'
import NutritionChartDoughnut from '@/components/PatientCards/NutritionChartDoughnut'


export default {
  props: {
    // Task object
    task: Object,

    // Task's board_id
    boardId: [String, Number],
  },
  components: { DietaryPatternDetail, NutritionChartDoughnut },

  data () {
    return {
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
    }
  },
  methods: {
    clickshowPattern (cuPattern) {
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
      console.log(this.dietaryPatternParams)
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
  }
}

</script>

<style lang="scss">
</style>