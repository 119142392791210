<template>
  <a-drawer
    width="450"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="dayAppointVisibleCu"
    title="我的日程管理"
    @close="closeAptAppointDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="dayAppointSpinning"
    >
      <div>
        <form-create
          v-model="cformPatAppointApi"
          :rule="cformpatAppointRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="9"
          :span="15"
        >
          <a-button
            @click="closeAptAppointDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { addDocDayAppoint } from '@/api/dms/clientAppointment'

export default ({
  name: 'DayAppointmentDrawer',
  props: {
    dayAppointVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    dayAppointVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.dayAppointVisibleCu = this.dayAppointVisible
        if (this.dayAppointVisible) {

        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      dayAppointVisibleCu: false,
      dayAppointSpinning: false,
      cformPatAppointApi: {},
      cformpatAppointRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'dep_id',
          value: this.$store.getters.userInfo.user.department_id
        },
        {
          type: 'TimePicker',
          title: '日程时间',
          field: 'appoint_date', style: { width: '100%' },
          props: { placeholder: '请选择日程时间', inputReadOnly: true, format: 'HH:mm' },
          validate: [
            { required: true, message: '请选择日程时间' }
          ]
        },
        {
          type: 'RangePicker',
          title: '日程有效期',
          field: 'expire_date', style: { width: '100%' },
          props: { type: 'date', format: 'YYYY-MM-DD', valueFormat: 'YYYY-MM-DD' },
          validate: [
            { required: true, message: '请确认选择日程有效期' }
          ]
        },
        {
          type: 'input',
          title: '日程内容',
          field: 'remarks',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写日程内容' },
          validate: [
            { required: true, message: '请确认填写日程内容', whitespace: true }
          ]
        }
      ],
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      }
    }
  },
  created () {

  },
  methods: {
    closeAptAppointDrawer () {
      this.$emit('dayAppointVisibleChange', false)
    },
    submitBtnClick () {
      this.cformPatAppointApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformPatAppointApi.formData()
          console.log(cuFormData)
          this.dayAppointSpinning = true
          addDocDayAppoint(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformPatAppointApi.resetFields()
              this.closeAptAppointDrawer()
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.dayAppointSpinning = false
          })
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
/deep/ .ant-calendar .ant-calendar-ok-btn {
  height: 35px !important;
}
/deep/ .ant-calendar-range-picker-separator {
  vertical-align: unset;
}
</style>