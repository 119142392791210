import request from '@/utils/request'

const apiUrls = {
  getTreeData: '/AdminMenus/getTreeData'
}

export function getTreeData (parameter) {
  return request({
    url: apiUrls.getTreeData,
    method: 'get',
    params: parameter
  })
}
