const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  enterprise_name: state => state.user.enterprise_name,
  nickname: state => state.user.name,
  roles: state => state.user.roles,
  userInfo: state => state.user.info,
  dictionariesList: state => state.user.dictionariesList,
  addRouters: state => state.permission.addRouters,
  title: state => state.app.title,
  company: state => state.app.company,
  companyEng: state => state.app.companyEng,
  support: state => state.app.support,
  year: state => state.app.year,
  slogan: state => state.app.slogan,
  mainIntro: state => state.app.mainIntro,
  logoOnly: state => state.app.logoOnly
}

export default getters
