<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveUserRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListDepartment } from '@/api/dms/department'
import { getAdminUserInfo, updateAdminUser, selectListUser } from '@/api/dms/user'
import { checkMobile, checkUserName, checkEmail } from '@/utils/customValidate'
import { rmFile } from '@/api/manage'

export default {
  name: 'EditUser',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '登录用户名',
          field: 'user_name',
          props: { placeholder: '请填写登录用户名' },
          validate: [
            { required: true, message: '请确认输入登录用户名', whitespace: true },
            { validator: checkUserName }
          ]
        },
        {
          type: 'input',
          title: '姓名/昵称',
          field: 'nick_name',
          props: { placeholder: '请填写姓名/昵称' },
          validate: [
            { required: true, min: 1, max: 12, message: '请填写1~12位姓名/昵称', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'mobile',
          props: { placeholder: '请填写联系电话' },
          validate: [
            { required: true, message: '请填写联系电话', whitespace: true },
            { validator: checkMobile }
          ]
        },
        {
          type: 'a-radio-group',
          title: '性别',
          value: '0',
          field: 'gender',
          props: { buttonStyle: 'solid', placeholder: '请选择性别' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['保密']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['男']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['女']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '操作人员类型',
          field: 'user_type',
          props: { placeholder: '请选择操作人员类型' },
          options: this.$options.filters.selectOptionsData('user.type', [], [50, 99]),
          validate: [
            { required: true, message: '请选择操作人员类型' }
          ]
        },
        {
          type: 'select',
          title: '所属部门',
          field: 'department_id',
          props: { placeholder: '请选择所属部门' },
          options: [],
          validate: [
            { required: true, message: '请选择所属部门' }
          ]
        },
        {
          type: 'select',
          title: '可查看医生',
          field: 'allows_rdc',
          props: { mode: 'multiple', placeholder: '请选择可查看医生', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'select',
          title: '可操作部门',
          field: 'allows_dep',
          props: { mode: 'multiple', placeholder: '请选择可操作部门', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择可操作部门' }
          ]
        },
        {
          type: 'input',
          title: '邮箱',
          field: 'email',
          props: { placeholder: '请填写邮箱' },
          validate: [
            { validator: checkEmail }
          ]
        },
        {
          type: 'upload',
          field: 'header',
          title: '头像',
          value: [],
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'img/avatar' },
            limit: 1,
            listType: 'picture-card'
          },
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      headerVal: '',
      departmentListData: [],
      selectListUser: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.editUser'))
    if (this.params === null || this.params.user === undefined || this.params.user < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getAdminUserInfo({ user: this.params.user }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.user) {
        // res.data.header_show
        console.log(res.data)
        this.headerVal = res.data.header
        res.data.header = res.data.header_show
        res.data.gender = res.data.gender.toString()
        res.data.user_type = res.data.user_type.toString()
        res.data.department_id = res.data.department_id > 0 ? res.data.department_id.toString() : undefined
        res.data.allows_dep = (res.data.allows_dep !== '' && res.data.allows_dep !== null) ? res.data.allows_dep.split(',') : []
        res.data.allows_rdc = (res.data.allows_rdc !== '' && res.data.allows_rdc !== null) ? res.data.allows_rdc.split(',') : []
        this.cformApi.setValue(res.data)
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch(() => {
      return this.$router.go(-1)
    })
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.departmentListData = res.data
        this.cformApi.updateRules({ // 更新规则
          'department_id': { options: this.departmentListData },
          'allows_dep': { options: this.departmentListData }
        })
      }
    })
    selectListUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user_type: 50 }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.docListData = res.data
        this.cformApi.updateRules({ // 更新规则
          'allows_rdc': { options: this.docListData }
        })
      }

      this.spinning = false
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl
          this.headerVal = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.headerVal }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.cformApi.setValue({ header: [] })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    saveUserRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      data.header = this.headerVal
      data.platform = this.$store.getters.userInfo.platform.id
      updateAdminUser(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '编写用户保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>