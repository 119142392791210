var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.tip,"size":"large","spinning":_vm.spinning}},[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('div',[_c('a-icon',{staticClass:"back-page-icon",attrs:{"title":"返回列表页","type":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('a-divider',{staticStyle:{"margin-top":"16px"}}),_c('a-form',{attrs:{"id":"addGoodsSpecDefForm","form":_vm.addGoodsSpecDefForm},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"title"},[_vm._v("规格参数信息")]),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"规格参数名称","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'spec_name',
                  { rules: [{ required: true, message: '请填写规格参数名称', whitespace: true }] } ]),expression:"[\n                  'spec_name',\n                  { rules: [{ required: true, message: '请填写规格参数名称', whitespace: true }] },\n                ]"}],attrs:{"placeholder":"请填写规格参数名称"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"商品类型","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'goods_type',
                  { rules: [{ required: true, message: '请选择商品类型' }] } ]),expression:"[\n                  'goods_type',\n                  { rules: [{ required: true, message: '请选择商品类型' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择商品类型","allow-clear":"","filterTreeNode":_vm.filterTreeNode,"tree-data":_vm.typeListData,"treeDataSimpleMode":""}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"展示类型","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'disp_type',
                  { rules: [{ required: true, message: '请选择展示类型' }] } ]),expression:"[\n                  'disp_type',\n                  { rules: [{ required: true, message: '请选择展示类型' }] },\n                ]"}],attrs:{"options":_vm._f("selectOptionsData")('emall_goods_spec.disp_type'),"show-search":"","optionFilterProp":"children","placeholder":"请选择展示类型"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排序号","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'sort',
                  { rules: [{ required: true, message: '请填写排序号' }] } ]),expression:"[\n                  'sort',\n                  { rules: [{ required: true, message: '请填写排序号' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写排序号","min":1,"step":1}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"规格值","labelCol":{ md: 3 },"wrapperCol":{ md: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'spec_val',
                  { rules: [{ required: true, message: '请填写规格值', whitespace: true }] } ]),expression:"[\n                  'spec_val',\n                  { rules: [{ required: true, message: '请填写规格值', whitespace: true }] },\n                ]"}],attrs:{"placeholder":"请填写规格值（多个值请用英文逗号 “ , ” 分割）"}})],1)],1)],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("确认保存")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }