import request from '@/utils/request'

const apiUrls = {
    saveFoodRecommend: '/HealthPatientFoodRecommends/saveFoodRecommend',
    getPatientFoodRecommendList: '/HealthPatientFoodRecommends/getPatientFoodRecommendList',
    removeFoodRecommend: '/HealthPatientFoodRecommends/removeFoodRecommend'
}

export function saveFoodRecommend (data) {
    return request({
        url: apiUrls.saveFoodRecommend,
        method: 'post',
        data
    })
}

export function getPatientFoodRecommendList (parameter) {
    return request({
      url: apiUrls.getPatientFoodRecommendList,
      method: 'get',
      params: parameter
    })
  }
  
export function removeFoodRecommend (data) {
    return request({
        url: apiUrls.removeFoodRecommend,
        method: 'post',
        data
    })
}
