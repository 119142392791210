import { render, staticRenderFns } from "./EditAuthGoods.vue?vue&type=template&id=f88d93ec&scoped=true"
import script from "./EditAuthGoods.vue?vue&type=script&lang=js"
export * from "./EditAuthGoods.vue?vue&type=script&lang=js"
import style0 from "./EditAuthGoods.vue?vue&type=style&index=0&id=f88d93ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88d93ec",
  null
  
)

export default component.exports