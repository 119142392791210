<template>
  <div>
    <a-card :bordered="false">
      <div class="mx-25">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="4"
                :sm="24"
              >
                <a-form-item label="当前状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="全部"
                    :options="'schedule.status' | selectOptionsData()"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="所属部门">
                  <a-select
                    v-model="queryParam.dep_id"
                    placeholder="全部"
                    :options="depList"
                    :allowClear="true"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入医生姓名搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="7"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.roleListTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                  <router-link
                    :to="'/HealthServiceScheduleDefs/addScheduleDef'"
                    :hidden="'/HealthServiceScheduleDefs/addScheduleDef' | isShowButton"
                  >
                    <a-button
                      type="primary"
                      icon="plus"
                    >新增排班规则定义</a-button>
                  </router-link>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <s-table
          ref="roleListTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="rule_type"
            slot-scope="text"
          >
            {{ 'schedule.rule_type' | showText(text) }}
          </span>
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'schedule.status' | showText(text)"
            />
          </span>
          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a
                :hidden="'/HealthServiceScheduleDefs/editScheduleDef' | isShowButton"
                @click="clickEdit(record)"
              >编辑</a>
              <a-divider
                v-if="record.status === 10"
                type="vertical"
                :hidden="'/HealthServiceScheduleDefs/setSchedules' | isShowSeparator(['/HealthServiceScheduleDefs/editScheduleDef'])"
              />
              <span
                v-if="record.status === 10"
                :hidden="'/HealthServiceScheduleDefs/setSchedules' | isShowButton"
              >
                <a @click="clickSetSchedule(record)">排班</a>
              </span>
            </template>
          </span>
        </s-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getScheduleDefList } from '@/api/dms/scheduleDef'
import { selectListDepartment } from '@/api/dms/department'

export default {
  name: 'ListScheduleDef',
  data () {
    return {
      columns: [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id'
        },
        {
          title: '姓名',
          width: 100,
          dataIndex: 'nick_name',
          ellipsis: true
        },
        {
          title: '所属部门',
          width: 100,
          dataIndex: 'department_name',
          ellipsis: true
        },
        {
          title: '规则类型',
          dataIndex: 'rule_type',
          ellipsis: true,
          scopedSlots: { customRender: 'rule_type' }
        },
        {
          title: '规则详情',
          dataIndex: 'schedule_rule',
          ellipsis: true,
          customRender: (text, row) => {
            let showTxt = ''
            const textObj = JSON.parse(text)
            if (textObj.length !== undefined && textObj.length > 0) {
              const ruleArr = textObj.map(tv => {
                return tv.schedule_rule
              })
              if (row.rule_type === 1) {
                showTxt = '每周星期' + ruleArr.join(',')
              }
              if (row.rule_type === 2) {
                showTxt = '每月' + ruleArr.join(',') + '号'
              }
              if (row.rule_type === 0) {
                showTxt = ruleArr.join(',')
              }
            }
            return showTxt
          }
        },
        {
          title: '当前状态',
          width: 80,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '是否默认',
          dataIndex: 'is_default',
          width: 70,
          customRender: (text) => {
            return text === 1 ? '是' : '否'
          }
        },
        {
          title: '备注',
          ellipsis: true,
          dataIndex: 'remarks',
          ellipsis: true
        },
        {
          title: '更新时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'update_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 95,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return getScheduleDefList(requestParameters)
          .then(res => {
            return res
          })
      },
      depList: []
    }
  },
  created () {
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.depList = res.data
      }
    })
  },
  filters: {
    statusTypeFilter (type) {
      if (type < 0) {
        return 'error'
      } else if (type >= 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('HealthServiceScheduleDefs.editScheduleDef', JSON.stringify({ def: record.id }))
      this.$router.push({ path: '/HealthServiceScheduleDefs/editScheduleDef' })
    },
    clickSetSchedule (record) {
      sessionStorage.setItem('HealthServiceScheduleDefs.setSchedules', JSON.stringify({ def: record.id }))
      this.$router.push({ path: '/HealthServiceScheduleDefs/setSchedules' })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>