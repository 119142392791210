<template>
  <div class="footerWrapper fontNuBaseFooter">
    <div>
      <div>客户姓名 <span class="fontNuBaseEngFooter">Name：</span>{{ patient.name }}</div>
      <div>客户编号 <span class="fontNuBaseEngFooter">Patient ID：</span>{{ patient.patient_no }}</div>
    </div>
    <div class="nuFooterTitle">
      <!-- <div class="nuFooterTitle1">
        L I V I N G
        <br>
        预 知 风 险
      </div>
      <div class="nuFooterTitle2">
        B E T T E R
        <br>
        健 康 生 活
      </div> -->
    </div>
    <div>
      <div class="nuDivFooterNumber fontNuBaseEngFooter">{{ pageIndex }} / {{ pageTotal }}</div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NutritionFooter',
  props: {
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    },
    patient: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      fsType: ''
    }
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.footerWrapper {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 60px;
  margin-top: 30px;
  height: 50px;
}

.fontNuBaseEng {
  font-size: 15px;
}

.fontNuBaseFooter {
  font-size: 16px;
}

.fontNuBaseEngFooter {
  font-size: 14px;
}

.nuFooterTitle {
  width: 200px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

.nuFooterTitle1 {
  width: 85px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}

.nuFooterTitle2 {
  width: 85px;
  margin-left: 30px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}

.nuFooterProduct {
  display: inline-block;
  margin-right: 62px;
  text-align: right;
}

.nuDivFooterNumber {
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 65px;
  text-align: center;
  background-color: #737ebc;
  color: #ffffff;
  padding: 4px;
}
</style>
