<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div class="pageNavBox">
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
            <div class="pageNavRight">
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存排班</a-button>
            </div>
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveScheduleRequest"
          ></form-create>

          <div class="calendarBox">
            <a-divider style="margin-top: 16px;" />
            <div class="title-font mb-25">
              排班日期列表
              <a-button
                v-if="clickSetScheduleTimes > 0"
                class="float-right"
                @click="addBtnClick"
                type="primary"
              >新增排班日期</a-button>
            </div>
            <a-table
              bordered
              :data-source="ruleDataArr"
              :columns="ruleColumns"
              :pagination="ruleTablePagination"
              @change="tableChange"
            >
              <div
                slot="schedule_date"
                slot-scope="text,record,idx"
              >
                <a-date-picker
                  :value="text"
                  style="width:100%;"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  @change="(e)=>ruleChangeVal(e,idx,'schedule_date')"
                />
              </div>
              <div
                slot="time_rule"
                slot-scope="text,record,idx"
              >
                <div
                  v-for="(tv,ti) in text"
                  :key="ti"
                  :class="ti > 0 ? 'mt-5' : ''"
                >
                  <a-time-picker
                    :value="tv.time_slot1"
                    :minuteStep="10"
                    format="HH:mm"
                    valueFormat="HH:mm"
                    @change="(e)=>timeChangeVal(e,idx,ti,'time_rule','time_slot1')"
                  />
                  <a-button disabled>
                    ~
                  </a-button>
                  <a-time-picker
                    :value="tv.time_slot2"
                    :minuteStep="10"
                    format="HH:mm"
                    valueFormat="HH:mm"
                    @change="(e)=>timeChangeVal(e,idx,ti,'time_rule','time_slot2')"
                  /> ：
                  <a-input
                    style="width:30%;"
                    addon-after="个"
                    :value="tv.available_count"
                    type="number"
                    :step="1"
                    :min="0"
                    :max="127"
                    @change="(e)=>timeChangeVal((e.target.value > 127 ? 127: (e.target.value < 0 ? 0 : e.target.value)),idx,ti,'time_rule','available_count')"
                  />
                  <a-button
                    v-if="text.length > 1"
                    @click="removeTimeSlotRow(idx,ti)"
                    type="danger"
                    icon="close"
                    title="删除该时段"
                    size="small"
                    class="ml-5"
                  ></a-button>
                  <a-button
                    v-if="ti === 0"
                    @click="addTimeSlotRow(idx)"
                    type="primary"
                    icon="plus"
                    title="新增时段"
                    size="small"
                    class="ml-5"
                  ></a-button>
                </div>
              </div>
              <span
                slot="action"
                slot-scope="text, record,idx"
              >
                <template>
                  <a
                    @click="clickRemoveRow(idx)"
                    class="font-error"
                  >删除</a>
                </template>
              </span>
            </a-table>
          </div>

          <div class="calendarBox">
            <a-divider style="margin-top: 16px;" />
            <div class="title-font mb-25">排班日历预览</div>
            <FullCalendar
              ref="calenderObj"
              :options="calendarOptions"
            />
          </div>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存排班</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getScheduleDefInfo } from '@/api/dms/scheduleDef'
import { addSchedules } from '@/api/dms/schedule'
// FullCalendar 相关组件
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'

export default {
  name: 'SetSchedules',
  components: {
    FullCalendar
  },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'enterprise_id'
        },
        {
          type: 'hidden',
          field: 'dep_id'
        },
        {
          type: 'hidden',
          field: 'service_user_id'
        },
        {
          type: 'RangePicker',
          title: '排班日期范围',
          field: 'datetime_range', style: { width: '100%' },
          value: [moment().format('YYYY-MM-DD'), moment().add(1, 'months').format('YYYY-MM-DD')],
          props: { ranges: { '最近一月': [moment(), moment().add(1, 'months')], '最近三月': [moment(), moment().add(3, 'months')], '本年': [moment(), moment().endOf('year')] }, valueFormat: 'YYYY-MM-DD' },
          validate: [
            { required: true, message: '请确认选择排班日期范围' }
          ]
        },
        // {
        //   type: 'input',
        //   title: '可预约数量',
        //   field: 'available_count', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写可预约数量', min: 0, step: 1, max: 127, addonAfter: '个' },
        //   validate: [
        //     { required: true, message: '请填写可预约数量', whitespace: true }
        //   ]
        // },
        // {
        //   type: 'input',
        //   title: '加号数量',
        //   field: 'add_count', style: { width: '100%' },
        //   props: { type: 'number', placeholder: '请填写加号数量', min: 0, step: 1, max: 127, addonAfter: '个' },
        // },
        // {
        //   type: 'select',
        //   title: '排班年份',
        //   field: 'year',
        //   props: { placeholder: '请选择排班年份' },
        //   options: [],
        //   validate: [
        //     { required: true, message: '请确认选择排班年份' }
        //   ]
        // },
        {
          type: 'a-button',
          props: {
            type: 'danger',
            icon: 'ordered-list'
          },
          class: 'setBtn',
          children: ['生成排班列表'],
          on: {
            click: () => this.clickSetSchedules()
          }
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      scheduleDef: {},
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],//要使用的组件需要先引入
        locale: 'zh-cn',
        allDayText: '全天',
        contentHeight: 'auto',
        initialView: 'dayGridMonth',
        selectable: true,
        initialDate: moment().format('YYYY-MM-DD'),
        // editable: true,
        headerToolbar: {//设置头部工具栏显示的按钮
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        },
        buttonText: {//设置头部工具栏的按钮文本
          today: '今天',
          dayGridMonth: '月',
          timeGridWeek: '周',
          timeGridDay: '天',
          list: '日程'
        },
        dayMaxEvents: 2,//设置日期视图中显示的事件个数，
        events: {
          // 统一设置背景色，边框，字体，在下面还可以单独设置
          backgroundColor: '#ffffff1a',
          textColor: '#141414',
          borderColor: '#ff0000',
          events: []
        }
      },
      calendarApi: null,
      schListLastKey: 0,
      clickSetScheduleTimes: 0,
      ruleDataArr: [],
      ruleColumns: [
        {
          title: '序号',
          width: 100,
          align: 'center',
          dataIndex: 'key',
          customRender: (text, row, idx) => {
            return (idx + 1) + (this.ruleTablePagination.pageSize * (this.ruleTablePagination.current - 1))
          }
        },
        {
          title: '排班日期',
          dataIndex: 'schedule_date',
          width: 250,
          ellipsis: true,
          scopedSlots: { customRender: 'schedule_date' }
        },
        {
          title: '排班时间段：可约数量',
          dataIndex: 'time_rule',
          ellipsis: true,
          scopedSlots: { customRender: 'time_rule' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      ruleTablePagination: {
        current: 1,
        pageSize: 10
      }
    }
  },
  mounted () {
    this.calendarApi = this.$refs.calenderObj.getApi()
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthServiceScheduleDefs.setSchedules'))
    if (this.params === null || this.params.def === undefined || this.params.def < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getScheduleDefInfo({ schedule: this.params.def }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.def) {
        const cuYear = moment().format('YYYY')
        const nextYear = moment().add(1, 'years').format('YYYY')
        const yearArr = [{ value: cuYear, label: cuYear + '年' }, { value: nextYear, label: nextYear + '年' }]
        this.cformApi.setValue({ enterprise_id: res.data.enterprise_id, dep_id: res.data.dep_id, service_user_id: res.data.service_user_id })
        this.cformApi.updateRules({ // 更新规则
          'year': { options: yearArr }
        })
        this.scheduleDef = res.data
        this.spinning = false
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveScheduleRequest (data, formObj) {
      if (this.calendarOptions.events.events.length < 1) {
        this.$message.error('请确认生成排班列表且存在排班数据！')
        return false
      }
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      data.dateArr = this.ruleDataArr
      addSchedules(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '排班数据保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    clickSetSchedules () {
      const cuFormData = this.cformApi.formData()
      if (cuFormData.datetime_range === undefined || cuFormData.datetime_range.length !== 2) {
        this.$message.error('请确认先择排班日期范围！')
        return false
      }
      if (cuFormData.datetime_range[0] > cuFormData.datetime_range[1]) {
        this.$message.error('请确认排班日期范围开始日期大于结束日期！')
        return false
      }
      this.clickSetScheduleTimes++
      const scheduleRule = JSON.parse(this.scheduleDef.schedule_rule)
      if (scheduleRule === undefined || scheduleRule.length < 1) {
        // 没有规则直接手动排班的情况
        return false
      }
      let allowArr = {}
      // 数据转换成方便匹配的格式
      scheduleRule.map((rv) => {
        allowArr[rv.schedule_rule] = rv
      })
      // 根据选择的时间范围计算开始结束时间
      // let startDate = startYear === moment().format('YYYY') ? Math.ceil((new Date() - new Date(new Date().getFullYear().toString())) / (24 * 60 * 60 * 1000)) + 1 : 1
      // let startDate = startYear === moment().format('YYYY') ? moment().format('YYYY-MM-DD') : startYear + '-01-01'
      let whileMark = true
      let addDayNum = 0
      // const calendarDateArr = []
      this.ruleDataArr = []
      this.schListLastKey = 0
      while (whileMark) {
        const dateStr = moment(cuFormData.datetime_range[0]).add(addDayNum, 'days').format('YYYY-MM-DD')
        const dateArr = dateStr.split('-')
        if (dateStr > cuFormData.datetime_range[1]) {
          // 大于结束日期则终止循环
          whileMark = false
          this.sameCalendarData()
          break
        }
        let cuCond = dateStr
        if (this.scheduleDef.rule_type === 1) { // 按周，获取时间是星期几 1-7
          cuCond = moment(dateStr).isoWeekday()
        }
        if (this.scheduleDef.rule_type === 2) {// 按月是获取当前号数
          cuCond = parseInt(dateArr[2])
        }
        if (allowArr[cuCond] !== undefined) {
          const timeRuleArr = JSON.parse(JSON.stringify(allowArr[cuCond].time_rule))
          this.schListLastKey += 1
          // timeRuleArr.map((tv, ti) => {
          //   calendarDateArr.push({
          //     id: this.schListLastKey + '_' + ti,
          //     title: ' 可约：' + tv.available_count,
          //     start: dateStr + ' ' + tv.time_slot1,
          //     end: dateStr + ' ' + tv.time_slot2,
          //     display: 'list-item'
          //   })
          // })
          this.ruleDataArr.push({
            key: this.schListLastKey,
            schedule_date: dateStr,
            time_rule: timeRuleArr
          })
        }
        addDayNum++
      }
      // this.calendarOptions.events.events = calendarDateArr
      // this.calendarApi.gotoDate(cuFormData.datetime_range[0])
    },
    sameCalendarData () {
      if (this.ruleDataArr.length > 0) {
        const calendarDateArr = []
        this.ruleDataArr.map(rv => {
          rv.time_rule.map((tv, ti) => {
            calendarDateArr.push({
              id: rv.key + '_' + ti,
              title: ' 可约：' + tv.available_count,
              start: rv.schedule_date + ' ' + tv.time_slot1,
              end: rv.schedule_date + ' ' + tv.time_slot2,
              display: 'list-item'
            })
          })
        })
        this.calendarOptions.events.events = calendarDateArr
        this.calendarApi.gotoDate(this.ruleDataArr[0].schedule_date)
      }
    },
    addBtnClick () {
      this.schListLastKey += 1
      this.ruleDataArr.push({
        key: this.schListLastKey,
        schedule_rule: undefined,
        time_rule: [{ time_slot1: undefined, time_slot2: undefined, available_count: undefined }]
      })
      // 将table翻到最后一页，展示新增的那行tr
      this.ruleTablePagination.current = Math.ceil(this.ruleDataArr.length / this.ruleTablePagination.pageSize)
    },
    // sortListData () {
    //   // 按时间排序，a-b小于0，a在前b在后
    //   this.schListData.sort((a, b) => {
    //     return a.service_date > b.service_date ? 1 : (a.service_date === b.service_date ? 0 : -1)
    //   })
    // },
    clickRemoveRow (idx) { // 列表中删除一行的事件
      this.ruleDataArr.splice(idx, 1)
      this.sameCalendarData()
    },
    ruleChangeVal (e, idx, field) {// 列表中规则排班的change事件
      this.ruleDataArr[idx][field] = e
      this.sameCalendarData()
    },
    timeChangeVal (e, idx, ti, field1, field2) {//列表中时间段和可约数量的change事件
      this.ruleDataArr[idx][field1][ti][field2] = e
      this.sameCalendarData()
    },
    removeTimeSlotRow (idx, ti) {//列表中删除时间段的事件
      this.ruleDataArr[idx].time_rule.splice(ti, 1)
      this.sameCalendarData()
    },
    addTimeSlotRow (idx) {//列表中新增时间段的事件
      const cuFormData = this.cformApi.formData()
      this.ruleDataArr[idx].time_rule.push({ time_slot1: undefined, time_slot2: undefined, available_count: cuFormData.available_count })
      this.sameCalendarData()
    },
    tableChange (pg, fl, sot) {
      this.ruleTablePagination = pg
    }
  }
}

</script>

<style lang="scss" scoped>
/deep/ .setBtn {
  margin-left: 12.5%;
  margin-top: 5px;
}
.calendarBox {
  padding: 0 25px;
}
</style>