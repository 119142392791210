import request from '@/utils/request'

const apiUrls = {
    listFoodRecipeDef: '/FoodRecipes/listFoodRecipeDef',
    addFoodRecipeDef: '/FoodRecipes/addFoodRecipeDef',
    updateFoodRecipeDef: '/FoodRecipes/updateFoodRecipeDef',
    getFoodRecipeDefDetail: '/FoodRecipes/getFoodRecipeDefDetail',
}

export function listFoodRecipeDef (parameter) {
    return request({
        url: apiUrls.listFoodRecipeDef,
        method: 'get',
        params: parameter
    })
}

export function addFoodRecipeDef (data) {
    return request({
        url: apiUrls.addFoodRecipeDef,
        method: 'post',
        data
    })
}

export function updateFoodRecipeDef (data) {
    return request({
        url: apiUrls.updateFoodRecipeDef,
        method: 'post',
        data
    })
}

export function getFoodRecipeDefDetail (data) {
    return request({
        url: apiUrls.getFoodRecipeDefDetail,
        method: 'post',
        data
    })
}
