<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <form-create
          v-model="cformApi"
          :rule="cformRule"
          :option="cformOption"
          @submit="saveScheduleRequest"
        ></form-create>

        <a-row v-if="showRuleBtn">
          <a-col
            :offset="9"
            :span="12"
          >
            <a-button
              @click="clickAddTimeSlot"
              type="primary"
              icon="plus"
            >增加默认时间段</a-button>
            <a-button
              class="ml-10"
              @click="clickSetSchedules"
              type="danger"
              icon="ordered-list"
            >重新生成排班规则列表</a-button>
          </a-col>
        </a-row>

        <div>
          <a-divider style="margin-top: 16px;" />
          <div class="title-font mb-25">
            排班规则列表
            <a-button
              v-if="showRuleBtn"
              class="float-right"
              @click="addBtnClick"
              type="primary"
            >新增排班规则</a-button>
          </div>
          <a-table
            bordered
            :data-source="ruleDataArr"
            :columns="ruleColumns"
          >
            <div
              slot="schedule_rule"
              slot-scope="text,record,idx"
            >
              <a-select
                v-if="cuScheduleType === '1' || cuScheduleType === '2'"
                :options="scheduleRuleOption"
                :value="text"
                style="width:100%;"
                @change="(e)=>ruleChangeVal(e,idx,'schedule_rule')"
              >
              </a-select>
              <a-date-picker
                v-else
                :value="text"
                style="width:100%;"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                @change="(e)=>ruleChangeVal(e,idx,'schedule_rule')"
              />
            </div>
            <div
              slot="time_rule"
              slot-scope="text,record,idx"
            >
              <div
                v-for="(tv,ti) in text"
                :key="ti"
                :class="ti > 0 ? 'mt-5' : ''"
              >
                <a-time-picker
                  :value="tv.time_slot1"
                  :minuteStep="10"
                  format="HH:mm"
                  valueFormat="HH:mm"
                  @change="(e)=>timeChangeVal(e,idx,ti,'time_rule','time_slot1')"
                />
                <a-button disabled>
                  ~
                </a-button>
                <a-time-picker
                  :value="tv.time_slot2"
                  :minuteStep="10"
                  format="HH:mm"
                  valueFormat="HH:mm"
                  @change="(e)=>timeChangeVal(e,idx,ti,'time_rule','time_slot2')"
                /> ：
                <a-input
                  style="width:30%;"
                  addon-after="个"
                  :value="tv.available_count"
                  type="number"
                  :step="1"
                  :min="0"
                  :max="127"
                  @change="(e)=>timeChangeVal((e.target.value > 127 ? 127: (e.target.value < 0 ? 0 : e.target.value)),idx,ti,'time_rule','available_count')"
                />
                <a-button
                  v-if="text.length > 1"
                  @click="removeTimeSlotRow(idx,ti)"
                  type="danger"
                  icon="close"
                  title="删除该时段"
                  size="small"
                  class="ml-5"
                ></a-button>
                <a-button
                  v-if="ti === 0"
                  @click="addTimeSlotRow(idx)"
                  type="primary"
                  icon="plus"
                  title="新增时段"
                  size="small"
                  class="ml-5"
                ></a-button>
              </div>
            </div>
            <span
              slot="action"
              slot-scope="text, record,idx"
            >
              <template>
                <a
                  @click="clickRemoveRow(idx)"
                  class="font-error"
                >删除</a>
              </template>
            </span>
          </a-table>
        </div>

        <a-row>
          <a-col
            :offset="11"
            :span="12"
          >
            <a-button
              @click="submitBtnClick"
              type="primary"
              class="mt-15"
            >确认保存</a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListDepartment } from '@/api/dms/department'
import { getScheduleDefInfo, updateScheduleDef } from '@/api/dms/scheduleDef'
import { selectListUser } from '@/api/dms/user'
import moment from 'moment'

export default {
  name: 'EditScheduleDef',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'select',
          title: '所属部门',
          field: 'dep_id',
          props: { placeholder: '请选择所属部门' },
          options: [],
          validate: [
            { required: true, message: '请确认选择所属部门', whitespace: true }
          ],
          on: {
            change: (e) => { this.depChange(e) }
          }
        },
        {
          type: 'select',
          title: '医生',
          field: 'service_user_id',
          props: { placeholder: '请先选择部门再选择医生' },
          options: [],
          validate: [
            { required: true, message: '请确认选择医生', whitespace: true }
          ]
        },
        {
          type: 'a-radio-group',
          title: '是否默认',
          value: '0',
          field: 'is_default',
          props: { buttonStyle: 'solid', placeholder: '请选择是否默认' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['否']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['是']
            }
          ],
          validate: [
            { required: true, message: '请确认选择是否默认', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        },
        // {
        //   type: 'RangePicker',
        //   title: '排班日期范围',
        //   field: 'datetime_range',
        //   value: [moment().format('YYYY-MM-DD'), moment().add(1, 'months').format('YYYY-MM-DD')],
        //   props: { ranges: { '最近一月': [moment(), moment().add(1, 'months')], '最近三月': [moment(), moment().add(3, 'months')], '本年': [moment(), moment().endOf('year')] }, valueFormat: 'YYYY-MM-DD' },
        //   validate: [
        //     { required: true, message: '请确认选择排班日期范围' }
        //   ]
        // },
        {
          type: 'select',
          title: '当前状态',
          field: 'status',
          props: { placeholder: '请选择当前状态' },
          options: [],
          validate: [
            { required: true, message: '请确认选择当前状态', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '规则类型',
          field: 'rule_type',
          props: { placeholder: '请选择规则类型' },
          options: [],
          validate: [
            { required: true, message: '请确认选择规则类型', whitespace: true }
          ],
          on: {
            change: (e) => { this.typeChange(e) }
          }
        },
        {
          type: 'select',
          title: '每周时间',
          hidden: true,
          field: 'week',
          props: { mode: 'multiple', placeholder: '请选择每周开放时间', maxTagCount: 3, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'select',
          title: '每月时间',
          field: 'month',
          hidden: true,
          props: { mode: 'multiple', placeholder: '请选择每月开放时间', maxTagCount: 3, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: []
        },
        {
          type: 'input',
          title: '自定义日期',
          field: 'customDay',
          hidden: true,
          props: { placeholder: '请输入自定义日期，例：2023-01-01,2023-01-31' }
        },
        {
          type: 'input',
          title: '默认可预约数量',
          field: 'available_count', style: { width: '100%' },
          hidden: true,
          props: { type: 'number', placeholder: '请填写默认可预约数量', min: 0, step: 1, max: 127, addonAfter: '个' }
        },
        {
          type: 'TimePicker',
          title: '默认时间段1',
          field: 'time_slot1_1',
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 12 },
            wrapperCol: { span: 12 }
          },
          // suffix: {
          //   type: 'AIcon', style: { fontSize: '20px', color: 'red', verticalAlign: 'text-top', marginLeft: '5px', position: 'absolute', right: '-25px', top: 0 },
          //   props: { type: 'close-circle' },
          //   on: {
          //     click: () => { this.tsDelClick(1) }
          //   }
          // },
          style: { width: '100%' },
          hidden: true,
          props: { placeholder: '开始时间', format: 'HH:mm', minuteStep: 10 }
        },
        {
          type: 'TimePicker',
          title: '',
          field: 'time_slot1_2',
          col: {
            span: 6
          },
          wrap: {
            labelCol: { span: 2 },
            wrapperCol: { span: 16 }
          },
          prefix: {
            type: 'AButton', children: ['~'], props: { disabled: true }
          },
          suffix: {
            type: 'AIcon', style: { fontSize: '20px', color: 'red', verticalAlign: 'text-top', marginLeft: '5px', position: 'absolute', right: '-25px', top: 0 },
            props: { type: 'close-circle' },
            on: {
              click: () => { this.tsDelClick(1) }
            }
          },
          style: { width: 'calc(100% - 40px)' },
          hidden: true,
          props: { placeholder: '结束时间', format: 'HH:mm', minuteStep: 10 }
        },
        // {
        //   type: 'a-button',
        //   field: 'add_time_btn',
        //   props: {
        //     type: 'primary'
        //   },
        //   col: {
        //     span: 3
        //   },
        //   hidden: true,
        //   class: 'setBtn mx-10',
        //   children: ['增加默认时间段'],
        //   on: {
        //     click: () => this.clickAddTimeSlot()
        //   }
        // },
        // {
        //   type: 'a-button',
        //   field: 'create_rule_btn',
        //   props: {
        //     type: 'danger'
        //   },
        //   col: {
        //     span: 3
        //   },
        //   hidden: true,
        //   class: 'setBtn',
        //   children: ['生成排班规则列表'],
        //   on: {
        //     click: () => this.clickSetSchedules()
        //   }
        // }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      departmentListData: [],
      docListData: [],
      monthList: [],
      ruleDataArr: [],
      ruleColumns: [
        {
          title: '序号',
          width: 100,
          align: 'center',
          dataIndex: 'key',
          customRender: (text, row, idx) => {
            return idx + 1
          }
        },
        {
          title: '排班规则',
          dataIndex: 'schedule_rule',
          width: 250,
          ellipsis: true,
          scopedSlots: { customRender: 'schedule_rule' }
        },
        {
          title: '排班时间段：可约数量',
          dataIndex: 'time_rule',
          ellipsis: true,
          scopedSlots: { customRender: 'time_rule' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      timeSlotNum: 2,
      timeSlotFieldArr: ['time_slot1_1', 'time_slot1_2'],
      showRuleBtn: false,
      ruleDataKey: 1,
      weekOptions: [
        { value: 1, label: '星期一' },
        { value: 2, label: '星期二' },
        { value: 3, label: '星期三' },
        { value: 4, label: '星期四' },
        { value: 5, label: '星期五' },
        { value: 6, label: '星期六' },
        { value: 7, label: '星期天' },
      ],
      scheduleRuleOption: [],
      cuScheduleType: 0
    }
  },
  created () {
    this.spinning = true
    for (let mi = 1; mi <= 31; mi++) {
      this.monthList.push({ value: mi, label: mi + '号' })
    }
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HealthServiceScheduleDefs.editScheduleDef'))
    if (this.params === null || this.params.def === undefined || this.params.def < 1) {
      this.$message.error('请确认选择用户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据
    getScheduleDefInfo({ schedule: this.params.def }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.def) {
        console.log(res.data)
        this.depChange(res.data.dep_id.toString())
        this.typeChange(res.data.rule_type.toString())
        this.cformApi.setValue({
          id: res.data.id,
          remarks: res.data.remarks,
          rule_type: res.data.rule_type.toString(),
          is_default: res.data.is_default.toString(),
          dep_id: res.data.dep_id.toString(),
          service_user_id: res.data.service_user_id.toString(),
          status: res.data.status.toString(),
        })
        // 处理列表回显数据
        const scRuleArr = JSON.parse(res.data.schedule_rule)
        this.ruleDataArr = scRuleArr
        this.ruleDataKey = (scRuleArr.length > 0 && scRuleArr[scRuleArr.length - 1].key !== undefined) ? scRuleArr[scRuleArr.length - 1].key + 1 : 1
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch(() => {
      return this.$router.go(-1)
    })
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.departmentListData = res.data
        this.cformApi.updateRules({ // 更新规则
          'dep_id': { options: this.departmentListData },
          'month': { options: this.monthList },
          'week': { options: this.weekOptions },
          'rule_type': { options: this.$options.filters.selectOptionsData('schedule.rule_type') },
          'status': { options: this.$options.filters.selectOptionsData('schedule.status') }
        })
      }
      this.spinning = false
    })
  },
  methods: {
    moment,
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveScheduleRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      if (this.ruleDataArr.length < 1) {
        this.$message.error('请确认排班规则列表有规则数据！')
        return false
      }
      data.ruleDataArr = this.ruleDataArr
      updateScheduleDef(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '新增排班定义保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              formObj.resetFields()
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    depChange (v) {
      this.spinning = true
      selectListUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user_type: 50, dep: v }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.docListData = res.data
        }
        this.cformApi.updateRules({ // 更新规则
          'service_user_id': { options: this.docListData }
        })
        this.spinning = false
      })
    },
    typeChange (v) {
      // 0自定义，1按周，2按月
      if (v === '1') {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: false },
          'month': { hidden: true },
          'customDay': { hidden: true },
          'available_count': { hidden: false },
          'time_slot1_1': { hidden: false },
          'time_slot1_2': { hidden: false },
          // 'add_time_btn': { hidden: false },
          // 'create_rule_btn': { hidden: false },
        })
        this.scheduleRuleOption = [...this.weekOptions]
      } else if (v === '2') {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: true },
          'month': { hidden: false },
          'customDay': { hidden: true },
          'available_count': { hidden: false },
          'time_slot1_1': { hidden: false },
          'time_slot1_2': { hidden: false },
          // 'add_time_btn': { hidden: false },
          // 'create_rule_btn': { hidden: false },
        })
        this.scheduleRuleOption = [...this.monthList]
      } else {
        this.cformApi.updateRules({ // 更新规则
          'week': { hidden: true },
          'month': { hidden: true },
          'customDay': { hidden: false },
          'available_count': { hidden: false },
          'time_slot1_1': { hidden: false },
          'time_slot1_2': { hidden: false },
          // 'add_time_btn': { hidden: false },
          // 'create_rule_btn': { hidden: false },
        })
        this.scheduleRuleOption = []
      }
      this.cuScheduleType = v
      this.showRuleBtn = true
    },
    addBtnClick () {
      const cuFormData = this.cformApi.formData()
      let ruleTmpArr = this.dealTimeSlotArr(cuFormData)
      if (ruleTmpArr === false) {
        return false
      }
      console.log(this.ruleDataArr, this.ruleDataKey)
      ruleTmpArr = ruleTmpArr.length < 1 ? [{ time_slot1: undefined, time_slot2: undefined, available_count: cuFormData.available_count }] : ruleTmpArr
      this.ruleDataArr.push({
        key: this.ruleDataKey,
        schedule_rule: undefined,
        time_rule: ruleTmpArr
      })
      this.ruleDataKey++
    },
    dealTimeSlotArr (formDara) {//根据表单的数据处理选择的默认时间段作为表格列表中的默认数据
      const ruleTmpArr = []
      let errMsg = ''
      for (let tii = 1; tii <= this.timeSlotNum; tii++) {
        const tk1 = 'time_slot' + tii + '_1'
        const tk2 = 'time_slot' + tii + '_2'
        if (this.timeSlotFieldArr.indexOf(tk1) < 0) {
          // 删除的跳过
          continue
        }
        // 剩下的要判断是否为空，有空值的要提示
        if (formDara[tk1] === undefined || formDara[tk1] === null || formDara[tk1] === '' || formDara[tk2] === undefined || formDara[tk2] === null || formDara[tk2] === '') {
          errMsg = '请确认默认时间段' + tii + '的开始时间和结束时间不为空！'
          this.$message.error(errMsg)
          // return false
          return false
        }
        ruleTmpArr.push({ time_slot1: formDara[tk1], time_slot2: formDara[tk2], available_count: formDara.available_count })
      }
      return ruleTmpArr
    },
    clickSetSchedules () {
      // 按选的默认数据生成排班规则列表数据RangePicker
      const cuFormData = this.cformApi.formData()
      if (cuFormData.rule_type === undefined || cuFormData.available_count === undefined) {
        this.$message.error('请确认选择排班类型和填写默认可预约数量！')
        return false
      }
      const ruleTmpArr = this.dealTimeSlotArr(cuFormData)
      if (ruleTmpArr === false) {
        return false
      }
      console.log(cuFormData, ruleTmpArr)
      let allowArr = []
      if (cuFormData.rule_type === '1') {
        // 周
        if (cuFormData.week !== undefined && cuFormData.week.length !== undefined && cuFormData.week.length > 0) {
          allowArr = cuFormData.week
        } else {
          this.$message.error('请确认选择每周排班时间！')
          return false
        }
      } else if (cuFormData.rule_type === '2') {
        // 月
        if (cuFormData.month !== undefined && cuFormData.month.length !== undefined && cuFormData.month.length > 0) {
          allowArr = cuFormData.month
        } else {
          this.$message.error('请确认选择每月排班时间！')
          return false
        }
      } else {
        // 自定义时间
        if (cuFormData.customDay !== undefined && cuFormData.customDay.length !== undefined && cuFormData.customDay.length > 0) {
          allowArr = cuFormData.customDay.replace('，', ',').split(',')
        }
        // 自定义可以为空，再手动选择下面的列表新增按钮
      }
      this.ruleDataKey = 1
      this.ruleDataArr = []
      allowArr.map(iv => {
        this.ruleDataArr.push({
          key: this.ruleDataKey,
          schedule_rule: iv,
          time_rule: JSON.parse(JSON.stringify(ruleTmpArr))
        })
        this.ruleDataKey++
      })
    },
    clickAddTimeSlot () { // 新增默认时段事件
      const cuNum = this.timeSlotNum
      this.timeSlotFieldArr.push('time_slot' + cuNum + '_1')
      this.timeSlotFieldArr.push('time_slot' + cuNum + '_2')
      // this.cformRule.splice(-2, 0, {
      this.cformRule.push({
        type: 'TimePicker',
        title: '默认时间段' + cuNum,
        field: 'time_slot' + cuNum + '_1',
        col: {
          span: 6
        },
        wrap: {
          labelCol: { span: 12 },
          wrapperCol: { span: 12 }
        },
        style: { width: '100%' },
        props: { placeholder: '开始时间', format: 'HH:mm', minuteStep: 10 }
      })
      this.cformRule.push({
        type: 'TimePicker',
        title: '',
        field: 'time_slot' + cuNum + '_2',
        col: {
          span: 6
        },
        wrap: {
          labelCol: { span: 2 },
          wrapperCol: { span: 16 }
        },
        prefix: {
          type: 'AButton', children: ['~'], props: { disabled: true }
        },
        suffix: {
          type: 'AIcon', style: { fontSize: '20px', color: 'red', verticalAlign: 'text-top', marginLeft: '5px', position: 'absolute', right: '-25px', top: 0 },
          props: { type: 'close-circle' },
          on: {
            click: () => { this.tsDelClick(cuNum) }
          }
        },
        style: { width: 'calc(100% - 40px)' },
        props: { placeholder: '结束时间', format: 'HH:mm', minuteStep: 10 }
      })
      this.timeSlotNum++
    },
    tsDelClick (cuNum) {
      // console.log(e) time_slot
      this.cformApi.removeField('time_slot' + cuNum + '_1')
      this.cformApi.removeField('time_slot' + cuNum + '_2')
      this.timeSlotFieldArr = this.timeSlotFieldArr.filter(item => (item !== 'time_slot' + cuNum + '_1' && item !== 'time_slot' + cuNum + '_2'))
    },
    clickRemoveRow (idx) { // 列表中删除一行的事件
      this.ruleDataArr.splice(idx, 1)
    },
    ruleChangeVal (e, idx, field) {// 列表中规则排班的change事件
      this.ruleDataArr[idx][field] = e
    },
    timeChangeVal (e, idx, ti, field1, field2) {//列表中时间段和可约数量的change事件
      console.log(idx, ti, field1, field2, this.ruleDataArr)
      this.ruleDataArr[idx][field1][ti][field2] = e
    },
    removeTimeSlotRow (idx, ti) {//列表中删除时间段的事件
      this.ruleDataArr[idx].time_rule.splice(ti, 1)
    },
    addTimeSlotRow (idx) {//列表中新增时间段的事件
      const cuFormData = this.cformApi.formData()
      this.ruleDataArr[idx].time_rule.push({ time_slot1: undefined, time_slot2: undefined, available_count: cuFormData.available_count })
    }
  }
}

</script>

<style lang="scss" scoped>
/deep/ .setBtn {
  margin-top: 4px;
}
</style>