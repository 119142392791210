import request from '@/utils/request'

const apiUrls = {
    savePatientServiceRecord: '/HealthServiceRecords/savePatientServiceRecord',
    listServiceRecord: '/HealthServiceRecords/listServiceRecord',
    listAllServiceRecord: '/HealthServiceRecords/listAllServiceRecord',
    getServiceRecordInfo: '/HealthServiceRecords/getServiceRecordInfo',
    dashboardServiceSummary: '/HealthServiceRecords/dashboardServiceSummary',
    savePatientServiceRecordOnly: '/HealthServiceRecords/savePatientServiceRecordOnly'
}

export function savePatientServiceRecord (data) {
    return request({
        url: apiUrls.savePatientServiceRecord,
        method: 'post',
        data
    })
}

export function listServiceRecord (parameter) {
    return request({
        url: apiUrls.listServiceRecord,
        method: 'get',
        params: parameter
    })
}

export function listAllServiceRecord (parameter) {
    return request({
        url: apiUrls.listAllServiceRecord,
        method: 'get',
        params: parameter
    })
}

export function getServiceRecordInfo (parameter) {
    return request({
        url: apiUrls.getServiceRecordInfo,
        method: 'get',
        params: parameter
    })
}

export function dashboardServiceSummary (data) {
    return request({
        url: apiUrls.dashboardServiceSummary,
        method: 'post',
        data
    })
}

export function savePatientServiceRecordOnly (data) {
    return request({
        url: apiUrls.savePatientServiceRecordOnly,
        method: 'post',
        data
    })
}

