<template>
  <a-drawer
    width="600"
    placement="right"
    :closable="true"
    :visible="dietaryPatternDrawerVisibleCu"
    :title="'推荐饮食模式介绍'"
    @close="closeDietaryPatternDrawer"
  >
    <!-- :title="dietaryPatternInfo.id !== undefined ? dietaryPatternInfo.pattern_name+'介绍' : '饮食介绍'" -->
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="dietaryPatternSpinning"
    >
      <div v-if="dietaryPatternInfoArr.length > 0">
        <!-- layout="vertical" -->
        <div
          v-for="(dietaryPatternInfo,dpi) in dietaryPatternInfoArr"
          :key="dpi"
        >
          <a-divider v-if="dpi > 0" />
          <h5>{{ dietaryPatternInfo.pattern_name+'介绍' }}</h5>
          <div v-if="dietaryPatternInfo.dietary_pattern_tag.length > 0">
            关联标签：
            <a-tag
              color="orange"
              v-for="tag in dietaryPatternInfo.dietary_pattern_tag"
              :key="tag.tag_id"
            >
              {{tag.tag_text}}
            </a-tag>
          </div>
          <div v-if="dietaryPatternInfo.dietary_pattern_item.length > 0">
            <div
              v-for="(itv,idx) in dietaryPatternInfo.dietary_pattern_item"
              :key="idx"
            >
              <h6>{{ showItemLabel (itv.section_type, 1) }}</h6>
              <template v-if="itv.section_type >= 4 && itv.section_type <= 5">
                <!-- 显示前面icon的样式 -->
                <template v-for="(itv2,idx2) in itv.itemArr">
                  <span
                    v-if="itv2.content_type === 2"
                    :key="idx2"
                  >
                    <img
                      class="show-img"
                      v-if="itv2.content_show !== undefined && itv2.content_show !== ''"
                      :src="itv2.content_show"
                    >
                  </span>
                  <p
                    :key="idx2"
                    v-else
                  ><a-icon
                      type="caret-right"
                      class="mr-5 ml-10"
                    />{{ itv2.content }}</p>
                </template>
              </template>
              <!-- 直接显示内容的样式 -->
              <template v-else>
                <template v-for="(itv3,idx3) in itv.itemArr">
                  <span
                    v-if="itv3.content_type === 2"
                    :key="idx3"
                  >
                    <img
                      class="show-img"
                      v-if="itv3.content_show !== undefined && itv3.content_show !== ''"
                      :src="itv3.content_show"
                    >
                  </span>
                  <p
                    :key="idx3"
                    v-else
                    class="text-span"
                  >{{ itv3.content }}</p>
                </template>
              </template>
            </div>
          </div>
          <div v-else>
            <h6>简介</h6>
            <p class="text-span">{{ dietaryPatternInfo.intro }}</p>
          </div>
        </div>
      </div>
      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
      />
    </a-spin>
  </a-drawer>
</template>

<script>
import { getDietaryPatternInfo } from '@/api/dms/dietaryPattern'

export default ({
  name: 'DietaryPatternDetail',
  props: {
    dietaryPatternDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    dietaryPatternDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.dietaryPatternDrawerVisibleCu = this.dietaryPatternDrawerVisible
        if (this.dietaryPatternDrawerVisible) {
          this.dietaryPatternSpinning = true
          this.dietaryPatternInfoArr = []
          this.ptIdx = 0
          this.cuPatternArr = typeof this.parentParams.pattern !== 'object' ? [this.parentParams.pattern] : this.parentParams.pattern
          this.loadDetailInfo()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      dietaryPatternDrawerVisibleCu: false,
      dietaryPatternSpinning: false,
      dietaryPatternInfoArr: [],
      ptIdx: 0,
      cuPatternArr: [],
      tagArr: []
    }
  },
  created () {
  },
  methods: {
    closeDietaryPatternDrawer () {
      this.dietaryPatternDrawerVisibleCu = false
      this.$emit('dietaryPatternDrawerVisibleChange', false)
    },
    showItemLabel (type, sort) {
      if (sort > 1) {
        return ''
      } else {
        // 第一个才显示
        return this.$options.filters.showText('pattern_item.section_type', type)
      }
    },
    loadDetailInfo () {
      if (this.cuPatternArr[this.ptIdx] === undefined || this.cuPatternArr[this.ptIdx] < 1) {
        // this.$message.error('暂无更多看查看信息！')
        this.dietaryPatternSpinning = false
        return false
      }
      // 获取指定饮食模式信息
      getDietaryPatternInfo({ enterprise: this.$store.getters.userInfo.user.target_enterprise, pattern: this.cuPatternArr[this.ptIdx] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined) {
          // console.log(res.data)
          this.ptIdx += 1
          this.dietaryPatternInfoArr.push(res.data)
          this.loadDetailInfo()
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
.text-span {
  text-indent: 28px;
  word-break: break-all;
}
.show-img {
  max-width: 100%;
  text-align: center;
}
</style>