<template>
  <food-calory-list>
  </food-calory-list>
</template>

<script>
import { FoodCaloryList } from '@/components/FoodCalory'

export default {
  name: 'ListFoodCalory',
  components: {
    FoodCaloryList,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {

  },
  methods: {

  }
}
</script>
