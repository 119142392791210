<template>
  <a-drawer
    width="650"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="bodyDrawerVisibleCu"
    title="客户最新体格指标信息修改"
    @close="closeBodyDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <form-create
        v-model="cformBodyApi"
        :rule="cformBodyRule"
        :option="cformOption"
      ></form-create>

      <a-row>
        <a-col
          :offset="8"
          :span="12"
        >
          <a-button
            icon="close"
            @click="closeBodyDrawer"
            type="default"
            class="mr-5"
          >关闭</a-button>
          <a-button
            icon="check"
            @click="clickSave"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { savePatientBodyInfo } from '@/api/dms/patient'
import { getAndRenderBodyItemFormData } from '@/utils/bodyItemCalc'

export default ({
  name: 'BodyInfoDrawer',
  props: {
    bodyDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    bodyDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.bodyDrawerVisibleCu = this.bodyDrawerVisible
        if (this.bodyDrawerVisible) {
          this.spinning = true

          this.cformBodyRule = [...this.cformBodyRuleInit]
          // 获取已有信息
          this.loadPatientOriData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      bodyDrawerVisibleCu: false,
      cformBodyApi: {},
      cformBodyRule: [],
      cformBodyRuleInit: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'patient_id'
        }
      ],
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 20 }
        },
        submitBtn: false
      },
      params: {}
    }
  },
  created () {

  },
  methods: {
    loadPatientOriData () {
      this.$nextTick(() => {
        this.params = { patient: this.parentParams.patient }
        // console.log(this.parentParams)
        // 获取渲染客户体格指标字段
        getAndRenderBodyItemFormData(this)
        // 有传入原体格数据才需要再渲染完后回显
        if (this.parentParams.bodyInfo !== undefined && this.parentParams.bodyInfo.length > 0) {
          const bodyFormField = {}
          this.parentParams.bodyInfo.map(rv => {
            const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.item_def_id + '@@' + rv.item_unit_name
            bodyFormField[fieldName] = rv.result_val
            if (rv.rptcode === 'bmr' && rv.remarks !== null && rv.remarks !== '') {
              // 回显bmr的选择公式
              const bmrFormula = JSON.parse(rv.remarks)
              bodyFormField['formula@@' + fieldName] = bmrFormula.value + '@@' + bmrFormula.label
            }
          })
          const timer = setInterval(() => {
            if (this.spinning === false) {
              this.cformBodyApi.setValue(bodyFormField)
              clearInterval(timer)
            }
          }, 100)
        }
      })
    },
    closeBodyDrawer () {
      this.bodyDrawerVisibleCu = false
      this.$emit('bodyDrawerVisibleChange', false)
    },
    clickSave () {
      this.cformBodyApi.validate((valid, fail) => {
        if (valid) {
          this.spinning = true
          let cuRecordParm = {}
          if (this.parentParams.recordOri !== undefined && this.parentParams.recordOri > 0) {
            cuRecordParm.service_record_id = this.parentParams.recordOri
          }
          savePatientBodyInfo(Object.assign({}, cuRecordParm, this.cformBodyApi.formData())).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.cformBodyApi.resetFields()
              this.closeBodyDrawer()
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })

        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
</style>