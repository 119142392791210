<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <a-descriptions
            bordered
            size="middle"
          >
            <a-descriptions-item label="角色名称">{{ roleInfo.name }}</a-descriptions-item>
            <a-descriptions-item
              label="角色描述"
              :span="2"
            >{{ roleInfo.remark }}</a-descriptions-item>
            <a-descriptions-item
              label="权限列表"
              :span="3"
            >
              <form-create
                v-model="cformApi"
                :rule="cformRule"
                :option="cformOption"
                @submit="saveRoleRequest"
              >
              </form-create>
            </a-descriptions-item>
          </a-descriptions>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center;margin-bottom: 0;margin-top: 15px;"
          >
            <a-button
              @click="handleSubmit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getRoleInfo, saveRoleMenu } from '@/api/dms/adminRole'
import { getTreeData } from '@/api/dms/adminMenu'
export default {
  name: 'GrantRole',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'tree',
          title: '',
          field: 'menu',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 9 }
          },
          on: {
            check: (checkeds, e) => {
              // console.log(checkeds)
              if (e.checkedNodes !== undefined && e.checkedNodes.length > 0) {
                // 选中菜单时判断当前的父级是否已选中，若没有则加入选中列表
                e.checkedNodes.map(cn => {
                  if (cn.data !== undefined && cn.data.props !== undefined && cn.data.props.pid !== undefined && cn.data.props.pid.length > 0) {
                    cn.data.props.pid.map(pv => {
                      if (checkeds.checked.indexOf(pv) === -1) {
                        checkeds.checked.push(pv)
                      }
                    })
                  }
                })
              }
              this.checkedKeys = checkeds
              if (e.checked === false && checkeds.checked.indexOf(e.node.eventKey) >= 0) {
                // 取消父级时还有子级是选中状态所以不能取消
                this.$message.error('当前菜单的子级菜单有选中状态！不可取消！请先取消子级菜单！')
              }
            },
            expand: (expandedKeys, e) => {
              this.expandedKeys = expandedKeys
            }
          },
          sync: ['expandedKeys', 'checkedKeys'],//双向绑定props中的配置
          props: {
            // treeData: [],
            // checkable: true,
            // checkStrictly: true,
            // showLine: true,
            // checkedKeys: [],
            // expandedKeys: [],
            // replaceFields: { children: 'children', title: 'title', key: 'key' }
          }
        }
      ],
      //组件参数配置
      cformOption: {
        submitBtn: false,
        resetBtn: false
      },
      roleInfo: {},
      checkedKeys: {},//双向绑定使用的
      expandedKeys: [],//双向绑定使用的
      vcheckedKeys: {},
      vexpandedKeys: [],
      treeData: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.grant'))
    if (this.params === null || this.params.role === undefined || this.params.role < 1) {
      this.$message.error('请确认选择角色！')
      return this.$router.go(-1)
    }
    this.spinning = true
    getRoleInfo({ role: this.params.role, join: ['roleMenu'] }).then(res2 => {
      if (res2.status !== undefined && res2.status === 1) {
        this.roleInfo = res2.data
        if (res2.data.role_menu !== undefined && res2.data.role_menu.length > 0) {
          this.vcheckedKeys = { checked: res2.data.role_menu }
        }
        getTreeData({ attr: this.$store.getters.userInfo.platform.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, role: this.$store.getters.userInfo.user.role_id, checked: this.vcheckedKeys }).then(res => {
          if (res.data !== undefined && res.data.menus !== undefined && res.data.menus.length > 0) {
            this.treeData = res.data.menus
            this.cformApi.setValue({ menu: this.vcheckedKeys })
            // 只能将有子级的菜单写入展开的值中，所有需要后端处理一下，避免将没有子级的菜单写入会出现展开/折叠的时候要点多次才有效果！
            this.vexpandedKeys = (res.data.expandedKeys !== undefined) ? res.data.expandedKeys : []
            this.cformApi.updateRules({ // 更新规则
              'menu': {
                props: {
                  treeData: this.treeData, expandedKeys: this.vexpandedKeys,// checkedKeys: this.checkedKeys, 
                  checkable: true, checkStrictly: true, showLine: true,
                  replaceFields: { children: 'children', title: 'title', key: 'key' }
                }
              }
            })
            // console.log(this.cformApi.getRule('menu'))
            // this.cformApi.refresh()
          }
          this.spinning = false
        })
      } else {
        this.$message.error(res2.msg || '请确认当前角色存在！')
        return this.$router.go(-1)
      }
    })
    // // 获取原始数据
    // this.spinning = true
    // getRoleInfo({ role: this.params.role }).then(res2 => {
    //   console.log(res2).data

    //   if (res2.status !== undefined && res2.status === 1) {
    //     this.cformApi.setValue(res2.data)
    //   }
    //   this.spinning = false
    // })
  },
  methods: {
    handleSubmit () {
      this.cformApi.submit()
    },
    saveRoleRequest (data, formObj) {
      // console.log(data, this.checkedKeys, this.vcheckedKeys)
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      saveRoleMenu({ role: this.params.role, menu: data.menu.checked }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '角色权限保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>