<template>
  <a-spin
    :tip="tip"
    :spinning="spinning"
  >
    <a-card
      :bordered="false"
      id="food_diary_info"
      class="header-solid mb-24"
    >
      <template #title>
        <h5 class="mb-0 font-semibold">饮食习惯</h5>
      </template>

      <!-- <a-button
      slot="extra"
      type="primary"
      icon="file-search"
      class="px-10 py-5 "
      @click="clickPrescriptionBtn"
    >查看历史记录</a-button> -->

      <form-create
        v-model="cformDietaryApi"
        :rule="cformDietaryRule"
        :option="cformOption"
        @submit="saveDietaryRequest"
      >
        <template
          slot="type-tree-select-custom"
          slot-scope="scope"
        >
          <a-tree-select
            allowClear
            :value="scope.model.value"
            style="width: 100%;"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="fsTreeData"
            placeholder="请选择过敏食物"
            tree-data-simple-mode
            tree-checkable
            :maxTagCount="2"
            :maxTagTextLength="10"
            @change="(v)=>scope.model.callback(v)"
          >
          </a-tree-select>
        </template>
      </form-create>

      <a-row>
        <a-col
          :offset="11"
          :span="12"
        >
          <a-button
            @click="cformDietaryApi.submit()"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { selectTreeDefData } from '@/api/dms/fsItem'
import { savePatientDietaryInfo, getPatientDietaryInfo } from '@/api/dms/patient'

export default ({
  // 客户饮食习惯信息card
  name: 'FoodDiaryInfo',
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    cformOption: {
      type: Object,
      default: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    }
  },
  data () {
    return {
      cformDietaryApi: {},
      cformDietaryRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'select',
          title: '喜好饮食类型',
          field: 'favorite_diet_type',
          props: { mode: 'multiple', placeholder: '请选择喜好饮食类型', maxTagCount: 2, maxTagTextLength: 10, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择饮食类型' }
          ]
        },
        {
          type: 'input',
          title: '喜好饮食类型及备注',
          field: 'favorite_diet_type_remarks',
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请填写喜好饮食类型及备注' }
        },
        {
          type: 'treeSelectCustom',
          field: 'allergies',
          title: '过敏食物',
          value: [],
          options: []
        },
        {
          type: 'input',
          title: '其他过敏食物及备注',
          field: 'allergies_remarks',
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请填写其他过敏食物及备注' }
        },
        {
          type: 'treeSelectCustom',
          field: 'food_intolerant',
          title: '不耐受食物',
          value: [],
          options: []
        },
        {
          type: 'input',
          title: '其他不耐受食物及备注',
          field: 'food_intolerant_remarks',
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请填写其他不耐受食物及备注' }
        },
        {
          type: 'treeSelectCustom',
          field: 'nutrition_deficiency',
          title: '缺乏营养',
          value: [],
          options: []
        },
        {
          type: 'input',
          title: '其他缺乏营养及备注',
          field: 'nutrition_deficiency_remarks',
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请填写其他缺乏营养及备注' }
        },
        {
          type: 'treeSelectCustom',
          field: 'food_favorite',
          title: '喜欢的食物',
          value: [],
          options: []
        },
        {
          type: 'treeSelectCustom',
          field: 'food_dislike',
          title: '不喜欢的食物',
          value: [],
          options: []
        },
        {
          type: 'input',
          title: '日饮水量',
          field: 'water_intake', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写日饮水量', min: 0, step: 1, max: 127, addonAfter: '升' },
        },
        {
          type: 'input',
          title: '其他备注信息',
          field: 'other_remarks',
          props: { placeholder: '请填写其他备注信息' }
        },
      ],
      fsTreeData: [],
      spinning: false,
      tip: '处理中...'
    }
  },
  created () {
    selectTreeDefData({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
        this.fsTreeData = res.data
        this.cformDietaryApi.setValue({ patient_id: this.parentParams.patient })
        this.cformDietaryApi.updateRules({ // 更新规则
          'allergies': { options: res.data },
          'food_favorite': { options: res.data },
          'food_dislike': { options: res.data },
          'food_intolerant': { options: res.data },
          'nutrition_deficiency': { options: res.data },
          'favorite_diet_type': { options: this.$options.filters.selectOptionsData('patient.favorite_diet_type') }
        })
      }
    })
    // this.loadPatientOriInfo()
  },
  methods: {
    loadPatientOriInfo () {
      // 调用之前单独触发是否显示spinning，请求完成后统一取消
      this.tip = '加载中...'
      getPatientDietaryInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.serviceRecord }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformDietaryApi.setValue({ id: res.data.id, favorite_diet_type: JSON.parse(res.data.favorite_diet_type), favorite_diet_type_remarks: res.data.favorite_diet_type_remarks, food_favorite: JSON.parse(res.data.food_favorite), food_dislike: JSON.parse(res.data.food_dislike), allergies: JSON.parse(res.data.allergies), allergies_remarks: res.data.allergies_remarks, food_intolerant: JSON.parse(res.data.food_intolerant), food_intolerant_remarks: res.data.food_intolerant_remarks, nutrition_deficiency: JSON.parse(res.data.nutrition_deficiency), nutrition_deficiency_remarks: res.data.nutrition_deficiency_remarks, water_intake: res.data.water_intake, other_remarks: res.data.other_remarks })
        }
        this.spinning = false
      })
    },
    saveDietaryRequest (data, formObj) {
      if (this.parentParams.serviceRecord < 1) {
        this.$message.error('请先保存客户预约目的及期望相关信息！')
        return false
      }
      this.tip = '处理中...'
      this.spinning = true
      const that = this
      savePatientDietaryInfo(Object.assign({ service_record_id: this.parentParams.serviceRecord }, data)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '饮食信息保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              this.spinning = false
              return that.$router.go(-1)
            },
            onCancel () {
              that.loadPatientOriInfo()
              return false
            }
          })
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    },
  },
})

</script>