<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="editShopGoodsForm"
        >
          <div class="title">商品基础信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示品名"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写展示品名（列表&详情页面展示）"
                  v-decorator="[
                    'goods_name',
                    { rules: [{ required: true, message: '请填写展示品名', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="商品类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择商品类型' }] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择商品类型"
                  allow-clear
                  :treeDefaultExpandedKeys="treeDefaultExpandedKeys"
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="展示标题"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  placeholder="请填写展示标题（列表页面展示）"
                  v-decorator="[
                    'title',
                    { rules: [{ required: true, message: '请填写展示标题', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="详细描述"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  placeholder="请填写详细描述（详情页面展示）"
                  v-decorator="[
                    'sub_title',
                    { rules: [{ required: true, message: '请填写详细描述', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="实体/虚拟商品"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择实体/虚拟商品"
                  :options="'emall_goods.is_virtual' | selectOptionsData()"
                  v-decorator="[
                    'is_virtual',
                    { initialValue: '0',rules: [{ required: true, message: '请选择实体/虚拟商品' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="销售时间"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-range-picker
                  inputReadOnly
                  :allowClear="false"
                  :showTime="true"
                  style="width:100%;"
                  :ranges="{ '本年': [moment(), moment().endOf('year')], '3年': [moment(), moment().add(3, 'years')], '5年': [moment(), moment().add(5, 'years')], '10年': [moment(), moment().add(10, 'years')] }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM-DD HH:mm:ss"
                  v-decorator="[
                    'express',
                    { rules: [{ required: true,message: '请确认选择合同有效期' }] },
                  ]"
                >
                  <a-icon
                    slot="suffixIcon"
                    type="calendar"
                  />
                </a-range-picker>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="成本价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写成本价"
                  style="width:100%;"
                  v-decorator="[
                    'cost_price',
                    { rules: [{ required: true, message: '请填写成本价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="销售标价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写销售标价"
                  style="width:100%;"
                  v-decorator="[
                    'unit_price',
                    { rules: [{ required: true, message: '请填写销售标价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="实际售价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写实际售价"
                  style="width:100%;"
                  v-decorator="[
                    'promotion_unit_price',
                    { rules: [{ required: true, message: '请填写实际售价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="运费规则"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                style="margin-bottom:0;"
                required
              >
                <a-input-group compact>
                  <a-form-item :style="{ display: 'inline-block',width:'55%' }">
                    <a-input
                      type="number"
                      :min="0"
                      style="width:100%;"
                      placeholder="请填写运费单价"
                      addon-after="元 /"
                      v-decorator="[
                        'freight',
                        { rules: [{ required: true, message: '请填写运费单价' }] }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item :style="{ display: 'inline-block',width:'45%' }">
                    <a-select
                      style="width:100%;"
                      placeholder="请选择运费计价单位"
                      :options="'emall_goods.freight_type' | selectOptionsData()"
                      v-decorator="[
                        'freight_type',
                        { initialValue: '3',rules: [{ required: true, message: '请选择运费计价单位' }] },
                      ]"
                    >
                    </a-select>
                  </a-form-item>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="库存管控"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择库存管控"
                  :options="'emall_goods.inventory_control' | selectOptionsData()"
                  @change="ivcontrolChange"
                  v-decorator="[
                    'inventory_control',
                    { initialValue: '0',rules: [{ required: true, message: '请选择库存管控' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
              v-if="noneDisplay === 1"
            >
              <a-form-item
                label="库存管控数量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写库存管控数量"
                  style="width:100%;"
                  v-decorator="[
                    'inventory',
                    { rules: [{ required: true, message: '请填写库存管控数量' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示主图"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                required
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="f=>{ return beforeUpload(f,'feature_image') }"
                  :file-list="fileList"
                  :remove="f=>{rmUpImgFile(f,'feature_image')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'feature_image')}"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
            class="mt-20"
          >
            <a-button
              v-if="cuStatus === -1"
              htmlType="submit"
              @click="tmpBtn(-1)"
              type="default"
              class="mr-10"
            >暂存</a-button>

            <a-button
              v-if="cuStatus === -1"
              htmlType="submit"
              @click="tmpBtn(20)"
              type="primary"
            >确认保存</a-button>
            <a-button
              v-else
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListTypeByTt } from '@/api/dms/goodsType'
import { getEmallGoodInfo, editEmallGood } from '@/api/dms/emallGood'
import moment from 'moment'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'

export default {
  name: 'EditShopGoodsForm',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      noneDisplay: 0,
      editShopGoodsForm: this.$form.createForm(this),
      typeListData: [],
      fileList: [],
      previewVisible: false,
      previewImage: '',
      cuStatus: 0,
      treeDefaultExpandedKeys: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoods.editShopGoods'))
    if (this.params === null || this.params.shopGood === undefined || this.params.shopGood < 1) {
      this.$message.error('请确认选择商城货品！')
      return this.$router.go(-1)
    }
    this.spinning = true
    this.editShopGoodsForm.getFieldDecorator('id', { initialValue: this.params.shopGood, preserve: true, rules: [{ required: true }] })
    this.editShopGoodsForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.editShopGoodsForm.getFieldDecorator('feature_image', { preserve: true, rules: [{ required: true }] })
    this.editShopGoodsForm.getFieldDecorator('status', { preserve: true, rules: [{ required: true }] })
    this.loadSelectData()
  },
  methods: {
    moment,
    loadSelectData () {
      this.tip = '加载中...'
      // 货品类型下拉数据
      selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.typeListData = res.data
        }
      })
      // 获取原数据
      getEmallGoodInfo({ shopGood: this.params.shopGood }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shopGood) {
          res.data.express = [res.data.start_time, res.data.end_time]
          if (res.data.inventory_control > 0) {
            this.editShopGoodsForm.getFieldDecorator('inventory', { preserve: true, rules: [{ required: true }] })
            this.ivcontrolChange(res.data.inventory_control)
          } else {
            delete res.data.inventory
          }
          if (res.data.feature_image_show !== undefined && res.data.feature_image_show !== null && res.data.feature_image_show !== '') {
            this.fileList = [
              {
                uid: '-1',
                name: '协议文本',
                status: 'done',
                url: res.data.feature_image_show
              }
            ]
          }
          delete res.data.start_time
          delete res.data.end_time
          delete res.data.feature_image_show
          this.cuStatus = res.data.status
          res.data.freight_type = res.data.freight_type !== null ? res.data.freight_type.toString() : 3
          res.data.is_virtual = res.data.is_virtual.toString()
          res.data.inventory_control = res.data.inventory_control.toString()
          this.treeDefaultExpandedKeys = [res.data.goods_type]
          this.editShopGoodsForm.setFieldsValue(res.data)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
        this.spinning = false
      }).catch(() => {
        return this.$router.go(-1)
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()

      this.editShopGoodsForm.validateFields((err, values) => {
        if (!err) {
          this.spinning = true
          editEmallGood(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.spinning = true
                  that.editShopGoodsForm.resetFields()
                  that.fileList = []
                  that.loadSelectData()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file, field) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传图片不超过2MB！')
        return false
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'feature_image') {
            this.editShopGoodsForm.setFieldsValue({ 'feature_image': upRes.data })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'feature_image') {
          this.fileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      let fname = (file.response !== undefined && file.response.data !== undefined) ? file.response.data : this.editShopGoodsForm.getFieldValue(field)
      fname = typeof fname === 'object' ? fname[0] : fname
      this.spinning = true
      rmFile({ fileName: fname }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          if (field === 'feature_image') {
            this.editShopGoodsForm.setFieldsValue({ 'feature_image': '' })
          }
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    tmpBtn (cm) {
      this.editShopGoodsForm.setFieldsValue({ status: cm })
    },
    ivcontrolChange (iv) {
      if (iv > 0) {
        this.noneDisplay = 1
      } else {
        this.noneDisplay = 0
      }
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.noneDisplay {
  display: none;
}
</style>
