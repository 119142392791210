import { render, staticRenderFns } from "./EditShopGoodsSku.vue?vue&type=template&id=608d040f&scoped=true"
import script from "./EditShopGoodsSku.vue?vue&type=script&lang=js"
export * from "./EditShopGoodsSku.vue?vue&type=script&lang=js"
import style0 from "./EditShopGoodsSku.vue?vue&type=style&index=0&id=608d040f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608d040f",
  null
  
)

export default component.exports