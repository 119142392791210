<template>
  <a-drawer
    width="600"
    placement="right"
    :closable="true"
    :visible="surveyViewDrawerVisibleCu"
    title="问卷调查表单"
    @close="closeSurveyInputDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveyViewSpinning"
    >
      <div v-if="surveyInfo.id !== undefined">
        <h6 class="font-semibold mx-0 mt-0 mb-30 text-center">{{ surveyInfo.title }}</h6>
        <form-create
          v-model="cformInputApi"
          :rule="cformInputRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
        class="mb-50"
      />

      <a-row>
        <a-col
          :offset="11"
          :span="13"
        >
          <a-button
            @click="closeSurveyInputDrawer()"
            class="mr-10"
          >关闭</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { setRuleByFormArr } from '@/utils/formCreateRuleDeal'

export default ({
  name: 'SurveyView',
  props: {
    surveyViewDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    surveyViewDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.surveyViewDrawerVisibleCu = this.surveyViewDrawerVisible
        if (this.surveyViewDrawerVisible) {
          if (this.parentParams.id === undefined || this.parentParams.id < 1) {
            this.$message.error('请确认选择问卷！')
            return false
          }
          this.loadSurveyData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      surveyViewDrawerVisibleCu: false,
      surveyViewSpinning: false,
      cformInputApi: {},
      cformInputRule: [],
      cformOption: {
        form: {
          layout: 'vertical'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      surveyInfo: {}
    }
  },
  created () {
  },
  methods: {
    closeSurveyInputDrawer () {
      this.surveyInfo = {}
      this.cformInputRule = []
      this.$emit('surveyViewDrawerVisibleChange', false)
    },
    loadSurveyData () {
      this.surveyInfo = this.parentParams
      const oriRule = this.parentParams.form_rule !== undefined && this.parentParams.form_rule !== null && this.parentParams.form_rule !== '' ? JSON.parse(this.parentParams.form_rule) : []
      // 获取rule数据，设置为实时保存答案
      // console.log(oriRule)
      const dealRule = setRuleByFormArr(oriRule)
      this.cformInputRule = dealRule.ruleArr
      // console.log(this.cformInputRule)
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>