<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>

    <!-- Dashboard Layout -->
    <a-layout
      class="layout-dashboard"
      id="layout-dashboard"
      :class="[navbarFixed ? 'navbar-fixed' : '', ! sidebarCollapsed ? 'has-sidebar' : '', layoutClass]"
    >
      <!-- Page Content -->
      <a-layout-content>
        <router-view />
      </a-layout-content>
      <!-- / Page Content -->

      <!-- Layout Footer -->
      <BasicFooter></BasicFooter>

    </a-layout>
    <!-- / Dashboard Layout -->

  </div>
</template>

<script>

import BasicFooter from '../components/Footers/BasicFooter'

export default ({
  components: {
    BasicFooter
  },
  data () {
    return {
      sidebarCollapsed: false,
      navbarFixed: false,
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass () {
      return this.$route.meta.layoutClass
    }
  },
  methods: {

  }
})

</script>
