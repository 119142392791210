<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="addEnterpriseGoodsform"
        >
          <h3 style="font-weight:bold;margin-bottom:15px;">货品基础信息</h3>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="货品类型">
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择货品类型' }] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择货品类型"
                  allow-clear
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                  @change="goodsTypeChange"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="{span: 6}"
              :md="{span: 12}"
              :sm="24"
            >
              <a-form-item label="货品类目">
                <a-select
                  :maxTagCount="1"
                  :maxTagTextLength="16"
                  :allowClear="true"
                  show-search
                  optionFilterProp="children"
                  placeholder="请选择货品类目"
                  :options="categoryListData"
                  @change="(v) => fillGoodsParamsFromCategory(v)"
                  v-decorator="[
                    'category_type',
                    { rules: [{ required: true, message: '请选择货品类目' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="{span: 6}"
              :md="{span: 12}"
              :sm="24"
            >
              <a-form-item label="物料编码">
                <a-input
                  placeholder="请填写物料编码"
                  v-decorator="[
                    'custom_code',
                    { rules: [{ required: true, message: '请填写物料编码', whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="{span: 6}"
              :md="{span: 12}"
              :sm="24"
            >
              <a-form-item label="货品名称">
                <a-input
                  placeholder="请填写货品名称"
                  v-decorator="[
                    'goods_name',
                    { rules: [{ required: true, message: '请填写货品名称', whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="生产厂家">
                <a-input
                  placeholder="请填写生产厂家"
                  v-decorator="[
                    'manufactor',
                    { rules: [{ required: true, message: '请填写生产厂家', whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="批准文号">
                <a-input
                  placeholder="请填写批准文号"
                  v-decorator="[
                    'license_no',
                    { rules: [{ required: true, message: '请填写批准文号', whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="批文效期">
                <a-date-picker
                  style="width:100%;"
                  format="YYYY-MM-DD"
                  v-decorator="[
                    'license_expire',
                    { rules: [{ required: true, message: '请填写批文效期' }] }
                  ]"
                >
                </a-date-picker>
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="品牌">
                <a-input
                  placeholder="请填写品牌"
                  v-decorator="['brand']"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item label="规格说明">
                <a-input
                  placeholder="请填写规格说明"
                  v-decorator="['spec_info']"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="备注">
                <a-input
                  placeholder="请填写备注"
                  v-decorator="['remarks']"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="货品图片"
                style="margin-bottom : 0px"
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="beforeUpload"
                  :file-list="fileList"
                  :remove="f=>{rmUpImgFile(f,'feature_image')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'feature_image')}"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>

          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
            v-show="showMore"
          >
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="生产批号">
                <a-input
                  placeholder="请填写生产批号"
                  v-decorator="['manu_no']"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="单件重量(Kg)">
                <a-input-number
                  placeholder="请填写单件重量(Kg)"
                  :min="0"
                  style="width:100%;"
                  v-decorator="[
                    'weight',
                    { initialValue: '15',rules: [{ required: true, message: '请填写单件重量(Kg)' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="单件尺寸(cm)"
                required
              >
                <a-input-group compact>
                  <a-form-item style="width:33%">
                    <a-input
                      placeholder="请填写长"
                      addon-before="长"
                      v-decorator="[
                        'volumn1',
                        { initialValue: '30',rules: [{ required: true, message: '请填写长', whitespace: true }] }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item style="width:34%">
                    <a-input
                      placeholder="请填写宽"
                      addon-before="宽"
                      v-decorator="[
                        'volumn2',
                        { initialValue: '40',rules: [{ required: true, message: '请填写宽', whitespace: true }] }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item style="width:33%">
                    <a-input
                      placeholder="请填写高"
                      addon-before="高"
                      v-decorator="[
                        'volumn3',
                        { initialValue: '50',rules: [{ required: true, message: '请填写高', whitespace: true }] }
                      ]"
                    />
                  </a-form-item>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
            >
              <a-form-item label="可使用权限">
                <a-radio-group
                  buttonStyle="solid"
                  v-decorator="[
                    'source_type',
                    { initialValue: '2', rules: [{ required: true, message: '请选择可使用权限', whitespace: true }] },
                  ]"
                >
                  <a-radio-button value="2">
                    仅当前平台
                  </a-radio-button>
                  <a-radio-button value="0">
                    所有平台
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="6"
              :md="12"
              :sm="24"
              v-for="(gParams) in goodsParams"
              :key="gParams.id"
            >
              <a-form-item :label="gParams.key">
                <a-input
                  v-if="gParams.type == 1"
                  style="width:100%;"
                  @change="event=>{fillGoodsParams(event.target.value,gParams.id)}"
                  v-decorator="[
                    'input_param['+gParams.id+']',
                    { rules: [{ required: true, message: '请填写' + gParams.key }] }
                  ]"
                />
                <a-select
                  v-else-if="gParams.type == 2"
                  :options="gParams.default_value"
                  @change="inputVal=>{fillGoodsParams(inputVal,gParams.id)}"
                  v-decorator="[
                    'input_param['+gParams.id+']',
                    { rules: [{ required: true, message: '请填写' + gParams.key }] }
                  ]"
                >
                </a-select>
                <a-date-picker
                  v-else-if="gParams.type == 3"
                  style="width:100%;"
                  format="YYYY-MM-DD"
                  @change="inputDate=>{fillGoodsParams(inputDate.format('YYYY-MM-DD'),gParams.id)}"
                  v-decorator="[
                    'input_param['+gParams.id+']',
                    { rules: [{ required: true, message: '请填写' + gParams.key }] }
                  ]"
                >
                </a-date-picker>
                <a-upload
                  v-else-if="gParams.type == 4"
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer ' + tmpToken}"
                  :beforeUpload="beforeUpload"
                  :remove="f=>{rmUpImgFile(f,gParams.index)}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,gParams.index)}"
                >
                  <div>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <a-divider
            dashed
            style="margin:10px 0;"
          />
          <h3 style="font-weight:bold;margin-bottom:15px;">销售信息</h3>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="不含税价">
                <a-input-number
                  style="width:100%;"
                  :min="0.01"
                  :step="0.01"
                  @blur="taxfreeChange"
                  v-decorator="[
                    'taxfree_unit_price',
                    { initialValue: 1,rules: [{ required: true, message: '请输入不含税价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="税率(%)">
                <a-input-number
                  style="width:100%;"
                  :min="0"
                  :step="1"
                  @blur="taxfreeChange"
                  v-decorator="[
                    'tax_rate',
                    { initialValue: 17,rules: [{ required: true, message: '请选择税率' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="含税价">
                <a-input-number
                  style="width:100%;"
                  :min="0.01"
                  :step="0.01"
                  v-decorator="[
                    'unit_price',
                    { initialValue: 1,rules: [{ required: true, message: '请填写价格' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="计价单位">
                <a-input
                  placeholder="请填写计价单位"
                  v-decorator="[
                    'unit',
                    { rules: [{ required: true, message: '请填写计价单位', whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="币种">
                <a-select
                  placeholder="请选择币种"
                  :options="'currency.type' | selectOptionsData()"
                  v-decorator="[
                    'currency',
                    { initialValue: '1',rules: [{ required: true, message: '请选择币种', whitespace: true }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
            >
              <a-form-item label="发票类型">
                <a-select
                  placeholder="请选择发票类型"
                  :options="invoiceTypeList"
                  v-decorator="[
                    'invoice_type',
                    { initialValue: 4,rules: [{ required: true, message: '请选择发票类型'}] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
              v-show="showMore"
            >
              <a-form-item label="销售类型">
                <a-select
                  placeholder="请选择销售类型"
                  :options="'enterprise_goods.sale_type' | selectOptionsData()"
                  v-decorator="[
                    'sale_type',
                    { initialValue: '1',rules: [{ required: true, message: '请选择销售类型', whitespace: true }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="3"
              :md="6"
              :sm="24"
              v-show="showMore"
            >
              <a-form-item label="结算类型">
                <a-select
                  placeholder="请选择结算类型"
                  :options="'enterprise_goods.settlement_type' | selectOptionsData()"
                  v-decorator="[
                    'settlement_type',
                    { initialValue: '1',rules: [{ required: true, message: '请选择结算类型', whitespace: true }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getInvoiceTypesSelectList } from '@/api/dms/invoice'
import { selectListTypeByTt, getSequence } from '@/api/dms/goodsType'
import { addEnterpriseGood } from '@/api/dms/enterpriseGood'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'
import moment from 'moment'

export default {
  name: 'AddEnterpriseGoodsForm',
  data () {
    return {
      showMore: false,
      spinning: false,
      tip: '加载中...',
      addEnterpriseGoodsform: this.$form.createForm(this),
      typeListData: [],
      categoryListData: [],
      fileList: [],
      basicParams: [],
      goodsParams: [],
      categoryParams: [],
      invoiceTypeList: [],
      previewVisible: false,
      previewImage: '',
      tmpToken: this.$store.state.user.token
    }
  },
  created () {
    // 初始头像字段
    this.addEnterpriseGoodsform.getFieldDecorator('feature_image', { preserve: true })
    this.addEnterpriseGoodsform.getFieldDecorator('target_enterprise', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.spinning = true
    selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise, addonParam: ['paramData', 'goodsCategory'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.typeListData = res.data
        this.basicParams = res.paramData
        this.categoryParams = res.categoryData
        this.basicParams.forEach((element) => {
          if (element.type === 2) {
            var optArr = element.default_value.split(',')
            if (optArr.length > 0) {
              element.default_value = []
              optArr.forEach(opt => {
                element.default_value.push({ value: opt, label: opt })
              })
            }
          }
        })
      }
      this.spinning = false
      this.tip = '处理中...'
    })
    getInvoiceTypesSelectList().then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.invoiceTypeList = res.data
      }
    })
  },
  methods: {
    moment,
    goodsTypeChange (v) {
      // 刷新下属货品类目
      this.getCategoryParams(v)
      // 页面动态组装
      this.getGoodsBasicParams(v)
    },
    // 获取对应的货品类目
    getCategoryParams (v) {
      this.categoryListData = []
      this.addEnterpriseGoodsform.setFieldsValue({ category_type: undefined })
      this.categoryParams.forEach((element) => {
        if (element.goods_type === v) {
          this.categoryListData.push(
            Object.assign({ value: element.id, label: element.custom_code + ' ' + element.goods_name + '(' + element.py_name + ')' }, element)
          )
        }
      })
    },
    // 根据货品类目生成默认的货品信息
    fillGoodsParamsFromCategory (v) {
      this.categoryListData.forEach((element) => {
        if (element.id === v) {
          console.log(element)

          this.addEnterpriseGoodsform.setFieldsValue({
            goods_name: element.goods_name,
            custom_code: element.custom_code,
            spec_info: element.spec_info
          })
          if (element.is_sub_code === 1) {
            // 查询最新的子码
            this.spinning = true
            getSequence({ enterprise: this.$store.getters.userInfo.user.target_enterprise, name: element.custom_code }).then((res) => {
              this.spinning = false
              if (res.data !== undefined && res.data > 0) {
                this.addEnterpriseGoodsform.setFieldsValue({ custom_code: element.custom_code + element.divid_char + res.data.toString().padStart(parseInt(element.sub_code_padding), '0') })
              }
            })
          }
        }
      })
    },
    // 获取货品基础参数，生成附加属性组件
    getGoodsBasicParams (v) {
      this.goodsParams = []
      let idx = 0
      this.basicParams.forEach((element) => {
        if (element.target_gt === v) {
          this.goodsParams.push({
            id: element.id,
            key: element.basic_param,
            type: element.type,
            default_value: element.default_value,
            val: element.type === 4 ? [] : '',
            index: idx
          })
          idx += 1
        }
      })
    },
    // 填写货品动态属性
    fillGoodsParams (inputVal, paramId) {
      this.goodsParams.forEach(element => {
        if (element.id === paramId) {
          element.val = inputVal
        }
      })
    },
    // 算税费
    taxfreeChange () {
      const taxfreePrice = this.addEnterpriseGoodsform.getFieldValue('taxfree_unit_price')
      const taxRate = this.addEnterpriseGoodsform.getFieldValue('tax_rate')
      const price = taxfreePrice * (1 + taxRate / 100)
      this.addEnterpriseGoodsform.setFieldsValue({ unit_price: price.toFixed(2) })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      this.addEnterpriseGoodsform.validateFields((err, values) => {
        if (!err) {
          const specDetail = []
          this.goodsParams.forEach(element => {
            if ((typeof (element.val) === 'string' && element.val !== '') || (typeof (element.val) === 'object' && element.val.length > 0)) {
              specDetail.push({
                id: element.id.toString(),
                key: element.key.toString(),
                type: element.type.toString(),
                val: element.val
              })
            }
          })
          values.specification_details = JSON.stringify(specDetail)
          delete values.input_param
          this.spinning = true
          values.weight = parseInt(values.weight)
          values.volumn1 = parseInt(values.volumn1)
          values.volumn2 = parseInt(values.volumn2)
          values.volumn3 = parseInt(values.volumn3)
          values.license_expire = values.license_expire.format('YYYY-MM-DD')
          addEnterpriseGood(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '新增货品保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.addEnterpriseGoodsform.resetFields()
                  that.fileList = []
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'feature_image') {
            this.addEnterpriseGoodsform.setFieldsValue({ feature_image: upRes.data })
          } else {
            this.goodsParams.forEach(element => {
              if (element.index === field) {
                element.val.push(upRes.data)
              }
            })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'feature_image') {
          this.fileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      let fname = (file.response !== undefined && file.response.data !== undefined) ? file.response.data : this.addEnterpriseGoodsform.getFieldValue(field)
      fname = typeof fname === 'object' ? fname[0] : fname
      this.spinning = true
      rmFile({ fileName: fname }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          if (field === 'feature_image') {
            this.addEnterpriseGoodsform.setFieldsValue({ feature_image: '' })
          } else {
            this.goodsParams.forEach(element => {
              if (element.index === field) {
                element.val.forEach((extfile, idx) => {
                  if (extfile === fname) {
                    element.val.splice(idx, 1)
                  }
                }
                )
              }
            })
          }
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>
