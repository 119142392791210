import request from '@/utils/request'

const apiUrls = {
    listSurveyData: '/MiscSurveys/listSurveyData',
    saveSurveyDefPublishInfo: '/MiscSurveys/saveSurveyDefPublishInfo',
    saveSurveyEdit: '/MiscSurveys/saveSurveyEdit',
    // 手机端要使用的请求走auth绕过权限
    getSurveyDetailInfo: '/auth/MiscSurveys/getSurveyDetailInfo'
}

export function listSurveyData (parameter) {
    return request({
        url: apiUrls.listSurveyData,
        method: 'get',
        params: parameter
    })
}

export function getSurveyDetailInfo (parameter) {
    return request({
        url: apiUrls.getSurveyDetailInfo,
        method: 'get',
        params: parameter
    })
}

export function saveSurveyDefPublishInfo (data) {
    return request({
        url: apiUrls.saveSurveyDefPublishInfo,
        method: 'post',
        data
    })
}

export function saveSurveyEdit (data) {
    return request({
        url: apiUrls.saveSurveyEdit,
        method: 'post',
        data
    })
}
