<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="16">
              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="货品类型">
                  <a-tree-select
                    show-search
                    style="width: 100%"
                    v-model="queryParam.goods_type"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="请选择货品类型"
                    allow-clear
                    :filterTreeNode="filterTreeNode"
                    :tree-data="typeListData"
                    treeDataSimpleMode
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>

              <a-col
                :md="6"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入货品名称、缩写搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="6"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.listGoodsCategoryTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                  <a-button
                    type="primary"
                    icon="plus"
                    :hidden="'/EnterpriseGoods/addGoodsCategory' | isShowButton"
                    @click="addClick()"
                  >新增货品类目</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="货品图片"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>

        <s-table
          ref="listGoodsCategoryTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'enterprise_goods.status' | showText(text)"
            />
          </span>

          <span
            slot="sale_type"
            slot-scope="text"
          >
            {{ 'enterprise_goods.sale_type' | showText(text) }}
          </span>

          <span
            slot="sub_code"
            slot-scope="text,record"
          >
            <span v-if=" text !==0 ">
              总长{{ record.sub_code_padding }}位
              <span v-if="record.divid_char !== ''">
                ，以“ {{ record.divid_char }} ”分割
              </span>
            </span>
          </span>

          <div
            slot="feature_image"
            slot-scope="text, record"
            style="max-width:65px;height:65px;cursor:pointer;"
          >
            <img
              v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.feature_image_show"
              @click="handlePreview(record.feature_image_show)"
            />
          </div>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EnterpriseGoods/editGoodsCategory' | isShowButton">
                <a @click="editClick(record)">编辑</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/EnterpriseGoods/viewGoodsCategoryDetail'] | isShowSeparator(['/EnterpriseGoods/editGoodsCategory'])"
              />
              <span :hidden="'/EnterpriseGoods/viewGoodsCategoryDetail' | isShowButton">
                <a @click="viewClick(record)">明细</a>
              </span>
            </template>
          </span>

        </s-table>
      </a-card>
    </a-spin>

    <a-modal
      id="goodsCategoryModal"
      width="50%"
      :visible="modalVisible"
      :title="modalTitle"
      okText="确认保存"
      :maskClosable="false"
      :okButtonProps="okButtonProps"
      @cancel="modalCancel"
      @ok="modalOk"
    >
      <a-spin
        tip="处理中..."
        :spinning="modalSpinning"
      >
        <a-form :form="goodsCategoryModalForm">
          <a-row
            class="form-row"
            :gutter="16"
          >

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="货品类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择货品类型'}] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择货物类型"
                  allow-clear
                  :disabled="model_gt_disable"
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                  @select="(e) => getPrefixSequence(e)"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="货品名称"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input-search
                  placeholder="货品名称"
                  :allowClear="true"
                  v-decorator="[
                    'goods_name',
                    { rules: [{ required: true, message: '请填写货品名称' , whitespace: true}] },
                  ]"
                  @search="transPy"
                >
                  <a-button slot="enterButton">
                    <a-icon type="retweet" />
                  </a-button>
                </a-input-search>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="名称缩写"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input-search
                  placeholder="名称缩写"
                  :allowClear="true"
                  v-decorator="[
                    'short_name',
                    { rules: [{ required: true, max: 10, message: '请填写10字以内的缩写' , whitespace: true}] },
                  ]"
                  @search="transPy"
                >
                  <a-button slot="enterButton">
                    <a-icon type="retweet" />
                  </a-button>
                </a-input-search>
              </a-form-item>
            </a-col>

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="缩写拼音"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  placeholder="缩写拼音"
                  :allowClear="true"
                  v-decorator="[
                    'py_name',
                    { rules: [{ required: true, max: 10, message: '请填写10字以内的缩写拼音' , whitespace: true}],
                      normalize: pY => pY != undefined ? pY.toUpperCase() : ''
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="规格说明"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  placeholder="规格说明"
                  :allowClear="true"
                  v-decorator="[
                    'spec_info',
                    { rules: [{ }] },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="物料编码"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  placeholder="物料编码"
                  :allowClear="true"
                  v-decorator="[
                    'custom_code',
                    { rules: [{ }] },
                  ]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="货品图片"
                style="margin-bottom : 0px"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="beforeUpload"
                  :file-list="fileList"
                  :remove="f=>{rmUpImgFile(f,'feature_image')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'feature_image')}"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="显示排序"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input-number
                  style="width:100%;"
                  :min="1"
                  v-decorator="['sort',{ initialValue: 10}]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="是否需要子码"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-radio-group
                  name="radioGroup"
                  v-decorator="[
                    'is_sub_code',
                    { initialValue: 0 },
                  ]"
                  @change="e => this.needSubCode = e.target.value"
                >
                  <a-radio :value="0">
                    不需要
                  </a-radio>
                  <a-radio :value="1">
                    需要
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col
              :lg="6"
              :md="6"
              :sm="24"
            >
              <a-form-item
                label="子码位长"
                :labelCol="{ md: 12 }"
                :wrapperCol="{ md: 12 }"
              >
                <a-input-number
                  placeholder=""
                  :min="0"
                  :max="8"
                  :disabled="needSubCode == 0"
                  v-decorator="[
                    'sub_code_padding',
                    { initialValue: 0 }
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col
              :lg="6"
              :md="6"
              :sm="24"
            >
              <a-form-item
                label="分隔符"
                :labelCol="{ md: 12 }"
                :wrapperCol="{ md: 12 }"
              >
                <a-input
                  placeholder=""
                  :disabled="needSubCode == 0"
                  :max-length="1"
                  v-decorator="[
                    'divid_char',
                    { initialValue: '-',rules: [{ whitespace: true }] }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
      </a-spin>
    </a-modal>

    <a-modal
      id="goodsCategoryDetailModal"
      :dialog-style="{ top: '20px' }"
      width="80%"
      :closable="false"
      :visible="modalVisibleDetail"
      title="产品明细"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button
          type="primary"
          @click="modalCancelDetail"
        >
          关闭
        </a-button>
      </template>

      <s-table
        ref="listCategoryDetailTable"
        size="default"
        rowKey="id"
        showPagination="auto"
        :columns="detailColumns"
        :data="loadDetailData"
        :pageSize="pageSize"
        :scroll="tableScrollDetail"
      >
        <div
          slot="detail_feature_image"
          slot-scope="text, record"
          style="max-width:25px;height:25px;cursor:pointer;"
        >
          <img
            v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
            title="点击查看原图"
            style="width: 100%"
            :src="record.feature_image_show"
            @click="handlePreview(record.feature_image_show)"
          />
        </div>
      </s-table>

    </a-modal>
  </div>
</template>

<script>
import { listGoodsCategory, saveGoodsCategory, listCategoryDetail } from '@/api/dms/enterpriseGood'
import { selectListTypeByTt, getSequence } from '@/api/dms/goodsType'
import pinyin from 'pinyin'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'

const statusMap = {
  '1': {
    status: 'success'
  },
  '-1': {
    status: 'error'
  },
  '0': {
    status: 'default'
  }
}

export default {
  name: 'GoodsCategoryList',
  data () {
    return {
      spinning: false,
      typeListData: [],
      modalVisible: false,
      modalVisibleDetail: false,
      modalTitle: '',
      okButtonProps: {},
      modalSpinning: false,
      goodsCategoryModalForm: this.$form.createForm(this),
      model_gt_disable: false,
      needSubCode: 0,
      py_option: {
        style: pinyin.STYLE_FIRST_LETTER // 设置拼音风格
      },
      fileList: [],
      previewImage: '',
      columns: [
        {
          title: 'ID',
          width: 80,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '货品类型',
          ellipsis: true,
          dataIndex: 'type_name'
        },
        {
          title: '货品名称',
          width: 150,
          dataIndex: 'goods_name'
        },
        {
          title: '缩写',
          width: 120,
          dataIndex: 'short_name'
        },
        {
          title: '拼音缩写',
          width: 120,
          dataIndex: 'py_name'
        },
        {
          title: '货品图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'feature_image' }
        },
        {
          title: '规格',
          ellipsis: true,
          width: 200,
          dataIndex: 'spec_info'
        },
        {
          title: '物料编码',
          ellipsis: true,
          dataIndex: 'custom_code'
        },
        {
          title: '子类编码',
          ellipsis: true,
          dataIndex: 'is_sub_code',
          scopedSlots: { customRender: 'sub_code' }

        },
        {
          title: '显示排序',
          ellipsis: true,
          dataIndex: 'sort'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      detailColumns: [
        {
          title: '供应商',
          ellipsis: false,
          dataIndex: 'supply_name'
        },
        {
          title: '货品名称',
          ellipsis: false,
          dataIndex: 'goods_name'
        },
        {
          title: '货品图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'detail_feature_image' }
        },
        {
          title: '物料编码',
          ellipsis: true,
          dataIndex: 'custom_code'
        },
        {
          title: '批准文号',
          ellipsis: true,
          dataIndex: 'license_no'
        },
        {
          title: '证书效期',
          ellipsis: true,
          dataIndex: 'license_expire'
        },
        {
          title: '生产商',
          ellipsis: false,
          dataIndex: 'manufactor'
        },
        {
          title: '品牌',
          ellipsis: false,
          dataIndex: 'brand'
        },
        {
          title: '规格',
          ellipsis: false,
          dataIndex: 'spec_info'
        },
        {
          title: '原价',
          ellipsis: true,
          dataIndex: 'unit_price',
          customRender: (v, row, index) => {
            return v > 0 ? v + '元/' + row.unit : ''
          }
        }
      ],
      catId: 0,
      tableScroll: {},
      tableScrollDetail: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listGoodsCategory(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: true }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      },
      loadDetailData: parameter => {
        const requestParameters = Object.assign({ enterprise: this.$store.getters.userInfo.user.target_enterprise, catetory_id: this.catId }, parameter)
        const that = this
        return listCategoryDetail(requestParameters)
          .then(res => {
            console.log(res)
            if (res.data.length > 0) {
              that.tableScrollDetail = { x: true }
            } else {
              that.tableScrollDetail = {}
            }
            return res
          })
      },
      previewVisible: false,
      previewImg: ''
    }
  },
  filters: {
    statusTypeFilter (type) {
      return (statusMap[type] !== undefined) ? statusMap[type].status : 'default'
    }
  },
  created () {
    // 注册参数到form
    this.goodsCategoryModalForm.getFieldDecorator('id', { preserve: true })
    this.goodsCategoryModalForm.getFieldDecorator('oper_mark', { preserve: true, rules: [{ required: true }] })
    this.goodsCategoryModalForm.getFieldDecorator('feature_image', { preserve: true })
    this.goodsCategoryModalForm.getFieldDecorator('enterprise', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })

    selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise, addonParam: ['paramData'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.typeListData = res.data
      }
      this.spinning = false
      this.tip = '处理中...'
    })
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    },
    addClick () {
      // 传递参数
      this.modalTitle = '新增货品类目'
      this.modalVisible = true
      this.modalSpinning = false
      this.okButtonProps = {}
      this.$nextTick(() => {
        // 写入操作标识
        var modalData = {}
        modalData.oper_mark = 'add'
        this.model_gt_disable = false
        this.goodsCategoryModalForm.setFieldsValue(modalData)
      })
    },
    editClick (record) {
      // 传递参数
      this.modalTitle = '修改货品类目'
      this.modalVisible = true
      this.modalSpinning = false
      this.okButtonProps = {}
      this.$nextTick(() => {
        // 写入操作标识
        var modalData = {
          id: record.id,
          goods_type: record.goods_type.toString(),
          goods_name: record.goods_name,
          short_name: record.short_name,
          py_name: record.py_name,
          feature_image: record.feature_image,
          spec_info: record.spec_info,
          custom_code: record.custom_code,
          is_sub_code: record.is_sub_code,
          divid_char: record.divid_char,
          sub_code_padding: record.sub_code_padding,
          sort: record.sort
        }
        if (record.feature_image_show !== undefined) {
          if (record.feature_image_show !== '' && record.feature_image_show !== null) {
            this.fileList = [
              {
                uid: '-1',
                name: '货品图片.png',
                status: 'done',
                url: record.feature_image_show
              }
            ]
          }
        }
        this.needSubCode = record.is_sub_code
        modalData.oper_mark = 'edit'
        this.model_gt_disable = true
        this.goodsCategoryModalForm.setFieldsValue(modalData)
      })
    },
    viewClick (record) {
      // 传递参数
      this.catId = record.id
      this.modalVisibleDetail = true
      this.$nextTick(() => {
        console.log(record)
        this.$refs.listCategoryDetailTable.refresh()
      })
    },
    transPy (value) {
      this.goodsCategoryModalForm.setFieldsValue({ py_name: pinyin(value, this.py_option).join('').toUpperCase() })
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'feature_image') {
            this.goodsCategoryModalForm.setFieldsValue({ feature_image: upRes.data })
          } else {
            this.goodsParams.forEach(element => {
              if (element.index === field) {
                element.val.push(upRes.data)
              }
            })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'feature_image') {
          this.fileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      let fname = (file.response !== undefined && file.response.data !== undefined) ? file.response.data : this.goodsCategoryModalForm.getFieldValue(field)
      fname = typeof fname === 'object' ? fname[0] : fname
      this.spinning = true
      rmFile({ fileName: fname }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          if (field === 'feature_image') {
            this.goodsCategoryModalForm.setFieldsValue({ feature_image: '' })
          } else {
            this.goodsParams.forEach(element => {
              if (element.index === field) {
                element.val.forEach((extfile, idx) => {
                  if (extfile === fname) {
                    element.val.splice(idx, 1)
                  }
                }
                )
              }
            })
          }
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    // 获取数据库序列
    getPrefixSequence (value) {
      this.goodsCategoryModalForm.setFieldsValue({ custom_code: '' })
      this.typeListData.forEach((element) => {
        if (element.id === value) {
          if (element.prefix !== '' && element.prefix != null) {
            this.modalSpinning = true
            getSequence({ enterprise: this.$store.getters.userInfo.user.target_enterprise, name: element.prefix }).then((res) => {
              this.modalSpinning = false
              if (res.data !== undefined && res.data > 0) {
                this.goodsCategoryModalForm.setFieldsValue({ custom_code: element.prefix + res.data.toString().padStart(parseInt(element.padding), '0') })
              }
            })
          }
        }
      })
    },
    modalCancel () {
      // 关闭弹窗 重置表单
      this.goodsCategoryModalForm.resetFields()
      this.goodsCategoryModalForm.setFieldsValue({ feature_image: '' })
      this.fileList = []
      this.modalVisible = false
    },
    modalOk () {
      this.handleSubmit()
    },
    modalCancelDetail () {
      this.catId = 0
      this.modalVisibleDetail = false
    },
    // 表单提交
    handleSubmit () {
      this.goodsCategoryModalForm.validateFields((err, values) => {
        if (!err) {
          // 判断是否要检测id
          if (values.oper_mark === 'edit' && (values.id === undefined || values.id < 1)) {
            this.$message.error('确认当前处理操作正确！')
            return false
          }

          this.modalSpinning = true
          this.okButtonProps = { props: { loading: true } }
          saveGoodsCategory(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.modalCancel()
              this.$refs.listGoodsCategoryTable.refresh(true)
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.modalSpinning = false
            this.okButtonProps = {}
          })
        }
      })
    }
  }
}
</script>
