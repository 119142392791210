<template>
  <a-drawer
    width="450"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="dayAppointDealVisibleCu"
    title="日程查看处理"
    @close="closeAptAppointDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="dayAppointSpinning"
    >
      <div>
        <a-row
          class="mb-20"
          v-if="parentParams.id !== undefined"
        >
          <a-col
            class="text-right"
            :span="6"
          >
            日程时间：
          </a-col>
          <a-col :span="18">
            {{ parentParams.appoint_date }}
          </a-col>
        </a-row>
        <a-row
          class="mb-20"
          v-if="parentParams.id !== undefined"
        >
          <a-col
            class="text-right"
            :span="6"
          >
            日程内容：
          </a-col>
          <a-col :span="18">
            {{ parentParams.remarks }}
          </a-col>
        </a-row>

        <form-create
          v-model="cformPatAppointApi"
          :rule="cformpatAppointRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="9"
          :span="15"
        >
          <a-button
            @click="closeAptAppointDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { saveClientAppointmentDealInfo } from '@/api/dms/clientAppointment'
import moment from 'moment'

export default ({
  name: 'DayAppointmentDeal',
  props: {
    dayAppointDealVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    dayAppointDealVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.dayAppointDealVisibleCu = this.dayAppointDealVisible
        if (this.dayAppointDealVisible) {
          this.$nextTick(() => {
            this.cformPatAppointApi.setValue({ id: this.parentParams.id, deal_time: moment().format('YYYY-MM-DD HH:mm:00') })
          })
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      dayAppointDealVisibleCu: false,
      dayAppointSpinning: false,
      cformPatAppointApi: {},
      cformpatAppointRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'DatePicker',
          title: '处理时间',
          field: 'deal_time', style: { width: '100%' },
          props: { type: 'date', placeholder: '请确认选择处理时间', showTime: true, format: 'YYYY-MM-DD HH:mm:00', valueFormat: 'YYYY-MM-DD HH:mm:00' },
          validate: [
            { required: true, message: '请确认选择处理时间' }
          ]
        },
        {
          type: 'input',
          title: '日程处理备注',
          field: 'deal_remarks',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写日程处理备注' },
          validate: [
            { required: true, message: '请确认填写日程处理备注', whitespace: true }
          ]
        }
      ],
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      }
    }
  },
  created () {

  },
  methods: {
    moment,
    closeAptAppointDrawer () {
      this.$emit('dayAppointDealVisibleChange', false)
    },
    submitBtnClick () {
      this.cformPatAppointApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformPatAppointApi.formData()
          console.log(cuFormData)
          this.dayAppointSpinning = true
          saveClientAppointmentDealInfo(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformPatAppointApi.resetFields()
              this.closeAptAppointDrawer()
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.dayAppointSpinning = false
          })
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
</style>