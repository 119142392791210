import router from './router'
import store from './store'
import defaultSettings from './config/defaultSettings'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.scss' // progress bar custom style
import { message } from 'ant-design-vue'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import VueCookies from 'vue-cookies'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = defaultSettings.whiteListRouteName // no redirect whitelist
const loginRoutePath = '/user/login'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${(to.meta.title)} - ${domTitle}`))
  /* has token storage.get(ACCESS_TOKEN) */
  if (VueCookies.isKey('AJ-DMS')) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.userInfo.user === undefined || store.getters.userInfo.user.id === undefined || store.getters.userInfo.user.id < 1) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then(res => {
            const roles = res.data
            // 获取基础参数字典表
            store.dispatch('GetDicList', { enterprise: roles.user.target_enterprise })
            // generate dynamic router
            store.dispatch('GenerateRoutes', { roles }).then((r3) => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              // // 当只有一个权限且还是完善企业资料的菜单，那就直接跳转到该菜单
              // if (store.getters.userInfo.menus !== undefined && store.getters.userInfo.menus.length === 1 && store.getters.userInfo.menus[0]['path'] === '/Enterprises/completeEnterpriseInfo') {
              //   redirect = '/Enterprises/completeEnterpriseInfo'
              // } else {
              //   if (redirect === '/Enterprises/completeEnterpriseInfo') {
              //     redirect = '/dashboard/analysis'
              //   }
              // }
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                const cookieUrl = VueCookies.get('AJ-DMS-NOW-URL')
                if (cookieUrl === undefined || cookieUrl === null || to.fullPath === cookieUrl) {
                  next({ ...to, replace: true })
                } else {
                  next({ path: cookieUrl })
                }
              } else {
                // 跳转到目的路由
                if (from.path === '/user/login') {
                  // 登录成功的时候不往历史浏览记录跳，直接到首页去
                  next({ path: '/dashboard' })
                } else {
                  next({ path: redirect })
                }
              }
            })
          })
          .catch((e3) => {
            message.error(e3.message || e3.msg || '暂无访问权限信息！')
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        // 正常跳转的时候记录当时最新的路由地址做刷新的时候使用
        VueCookies.set('AJ-DMS-NOW-URL', to.fullPath)
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
