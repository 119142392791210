var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.tip,"size":"large","spinning":_vm.spinning}},[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('div',[_c('a-icon',{staticClass:"back-page-icon",attrs:{"title":"返回列表页","type":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('a-divider',{staticStyle:{"margin-top":"16px"}}),_c('a-form',{attrs:{"form":_vm.editShopGoodsForm},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"title"},[_vm._v("商品基础信息")]),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"展示品名","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'goods_name',
                  { rules: [{ required: true, message: '请填写展示品名', whitespace: true }] } ]),expression:"[\n                  'goods_name',\n                  { rules: [{ required: true, message: '请填写展示品名', whitespace: true }] },\n                ]"}],attrs:{"placeholder":"请填写展示品名（列表&详情页面展示）"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"商品类型","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'goods_type',
                  { rules: [{ required: true, message: '请选择商品类型' }] } ]),expression:"[\n                  'goods_type',\n                  { rules: [{ required: true, message: '请选择商品类型' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择商品类型","allow-clear":"","treeDefaultExpandedKeys":_vm.treeDefaultExpandedKeys,"filterTreeNode":_vm.filterTreeNode,"tree-data":_vm.typeListData,"treeDataSimpleMode":""}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"展示标题","labelCol":{ md: 3 },"wrapperCol":{ md: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'title',
                  { rules: [{ required: true, message: '请填写展示标题', whitespace: true }] } ]),expression:"[\n                  'title',\n                  { rules: [{ required: true, message: '请填写展示标题', whitespace: true }] },\n                ]"}],attrs:{"placeholder":"请填写展示标题（列表页面展示）"}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"详细描述","labelCol":{ md: 3 },"wrapperCol":{ md: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'sub_title',
                  { rules: [{ required: true, message: '请填写详细描述', whitespace: true }] } ]),expression:"[\n                  'sub_title',\n                  { rules: [{ required: true, message: '请填写详细描述', whitespace: true }] },\n                ]"}],attrs:{"placeholder":"请填写详细描述（详情页面展示）"}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"实体/虚拟商品","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'is_virtual',
                  { initialValue: '0',rules: [{ required: true, message: '请选择实体/虚拟商品' }] } ]),expression:"[\n                  'is_virtual',\n                  { initialValue: '0',rules: [{ required: true, message: '请选择实体/虚拟商品' }] },\n                ]"}],attrs:{"placeholder":"请选择实体/虚拟商品","options":_vm._f("selectOptionsData")('emall_goods.is_virtual')}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"销售时间","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'express',
                  { rules: [{ required: true,message: '请确认选择合同有效期' }] } ]),expression:"[\n                  'express',\n                  { rules: [{ required: true,message: '请确认选择合同有效期' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"inputReadOnly":"","allowClear":false,"showTime":true,"ranges":{ '本年': [_vm.moment(), _vm.moment().endOf('year')], '3年': [_vm.moment(), _vm.moment().add(3, 'years')], '5年': [_vm.moment(), _vm.moment().add(5, 'years')], '10年': [_vm.moment(), _vm.moment().add(10, 'years')] },"valueFormat":"YYYY-MM-DD HH:mm:ss","format":"YYYY-MM-DD HH:mm:ss"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"calendar"},slot:"suffixIcon"})],1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"成本价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'cost_price',
                  { rules: [{ required: true, message: '请填写成本价' }] } ]),expression:"[\n                  'cost_price',\n                  { rules: [{ required: true, message: '请填写成本价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写成本价"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"销售标价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'unit_price',
                  { rules: [{ required: true, message: '请填写销售标价' }] } ]),expression:"[\n                  'unit_price',\n                  { rules: [{ required: true, message: '请填写销售标价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写销售标价"}})],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"实际售价","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'promotion_unit_price',
                  { rules: [{ required: true, message: '请填写实际售价' }] } ]),expression:"[\n                  'promotion_unit_price',\n                  { rules: [{ required: true, message: '请填写实际售价' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写实际售价"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"运费规则","labelCol":{ md: 6 },"wrapperCol":{ md: 16 },"required":""}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block',width:'55%' })},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'freight',
                      { rules: [{ required: true, message: '请填写运费单价' }] }
                    ]),expression:"[\n                      'freight',\n                      { rules: [{ required: true, message: '请填写运费单价' }] }\n                    ]"}],staticStyle:{"width":"100%"},attrs:{"type":"number","min":0,"placeholder":"请填写运费单价","addon-after":"元 /"}})],1),_c('a-form-item',{style:({ display: 'inline-block',width:'45%' })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'freight_type',
                      { initialValue: '3',rules: [{ required: true, message: '请选择运费计价单位' }] } ]),expression:"[\n                      'freight_type',\n                      { initialValue: '3',rules: [{ required: true, message: '请选择运费计价单位' }] },\n                    ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择运费计价单位","options":_vm._f("selectOptionsData")('emall_goods.freight_type')}})],1)],1)],1)],1)],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"库存管控","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'inventory_control',
                  { initialValue: '0',rules: [{ required: true, message: '请选择库存管控' }] } ]),expression:"[\n                  'inventory_control',\n                  { initialValue: '0',rules: [{ required: true, message: '请选择库存管控' }] },\n                ]"}],attrs:{"placeholder":"请选择库存管控","options":_vm._f("selectOptionsData")('emall_goods.inventory_control')},on:{"change":_vm.ivcontrolChange}})],1)],1),(_vm.noneDisplay === 1)?_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"库存管控数量","labelCol":{ md: 6 },"wrapperCol":{ md: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'inventory',
                  { rules: [{ required: true, message: '请填写库存管控数量' }] } ]),expression:"[\n                  'inventory',\n                  { rules: [{ required: true, message: '请填写库存管控数量' }] },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写库存管控数量"}})],1)],1):_vm._e()],1),_c('a-row',{staticClass:"form-row",attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"展示主图","labelCol":{ md: 6 },"wrapperCol":{ md: 16 },"required":""}},[_c('a-upload',{attrs:{"list-type":"picture-card","action":"/auth/UploadFiles/upload","method":"post","headers":{Authorization: 'Bearer '+this.$store.state.user.token},"beforeUpload":function (f){ return _vm.beforeUpload(f,'feature_image') },"file-list":_vm.fileList,"remove":function (f){_vm.rmUpImgFile(f,'feature_image')}},on:{"preview":_vm.upImgPreview,"change":function (fo){_vm.upImgChange(fo,'feature_image')}}},[(_vm.fileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 点击上传 ")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.cancelPreviewImage}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.previewImage}})])],1)],1)],1),_c('a-form-item',{staticClass:"mt-20",staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[(_vm.cuStatus === -1)?_c('a-button',{staticClass:"mr-10",attrs:{"htmlType":"submit","type":"default"},on:{"click":function($event){return _vm.tmpBtn(-1)}}},[_vm._v("暂存")]):_vm._e(),(_vm.cuStatus === -1)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"},on:{"click":function($event){return _vm.tmpBtn(20)}}},[_vm._v("确认保存")]):_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("确认保存")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }