<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveUserRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getCascaderList } from '@/api/manage'
import { editEnterprise } from '@/api/dms/enterprise'
import { checkMobile } from '@/utils/customValidate'

export default {
  name: 'EditEnterprise',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'h6',
          name: 'h6',
          children: ['企业基础信息']
        },
        {
          type: 'input',
          title: '企业名称',
          field: 'enterprise_name',
          props: { placeholder: '请填写企业名称' },
          validate: [
            { required: true, message: '请确认输入企业名称', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '企业电话',
          field: 'business_phone',
          props: { placeholder: '请填写企业电话' },
          validate: [
            { validator: checkMobile }
          ]
        },
        {
          type: 'input',
          title: '工商注册号',
          field: 'business_Code',
          props: { placeholder: '请填写工商注册号' },
          validate: [
            { required: true, message: '请确认输入工商注册号', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '企业口号',
          field: 'sub_title',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写企业口号' }
        },
        {
          type: 'input',
          title: '企业联系人',
          field: 'contact',
          props: { placeholder: '请填写企业联系人' }
        },
        {
          type: 'input',
          title: '联系人电话',
          field: 'contact_phone',
          props: { placeholder: '请填写企业联系人电话' },
          validate: [
            { validator: checkMobile }
          ]
        },
        {
          type: 'cascader',
          title: '企业所在地',
          field: 'province',
          props: { options: [], placeholder: '请选择企业所在地' }
        },
        {
          type: 'input',
          title: '地址详情',
          field: 'detail_address',
          props: { placeholder: '请填写企业地址街道门牌号详情' }
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.editEnterprise'))
    if (this.params === null || this.params.id === undefined || this.params.id < 1) {
      this.$message.error('请确认选择企业！')
      return this.$router.go(-1)
    }
    this.refreshList()
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    refreshList () {
      this.spinning = true
      // 不传入pid直接获取所有的数据
      getCascaderList().then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cformApi.updateRules({ // 更新规则
            'province': { props: { options: res.data, placeholder: '请选择企业所在地' } }
          })
        }
        this.spinning = false
      }).finally(() => {
        const proArr = []
        if (this.params.province !== null && this.params.province !== '') {
          proArr.push(this.params.province)
        }
        if (this.params.city !== null && this.params.city !== '') {
          proArr.push(this.params.city)
        }
        if (this.params.district_county !== null && this.params.district_county !== '') {
          proArr.push(this.params.district_county)
        }
        this.params.province = proArr
        this.cformApi.setValue(this.params)
      })
    },
    saveUserRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      editEnterprise(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>