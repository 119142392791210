<template>

  <!-- Line Chart widget -->
  <a-spin
    size="small"
    :spinning="loadingRecordSum"
  >
    <a-card
      :bordered="false"
      class="crm-bar-line header-solid"
      :bodyStyle="{padding: 0,}"
    >
      <template #title>
        <h6 class="text-md font-semibold text-muted">服务记录</h6>
        <h5
          class="mb-0"
          title="总计服务次数"
        > {{ allCount }} <small
            :class="countRate > 0 ? 'text-danger ml-10' : 'text-success ml-10' "
            title="本月与上月服务次数比"
          > {{ countRate }} %</small></h5>
      </template>
      <canvas
        ref="chartRecord"
        class="chart-line-widget"
        :style="{'height': height + 'px'}"
      ></canvas>

    </a-card>
    <!-- Line Chart widget -->

  </a-spin>
</template>

<script>

import { dashboardServiceSummary } from '@/api/dms/serviceRecord'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default ({
  components: {

  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      allCount: 0,
      lastMonthCount: 0,
      countRate: 100,
      height: 100,
      loadingRecordSum: false,
      chartRecord: null,
      // Data for line chart.
      lineChartData: {
        labels: [],
        datas: [],
        title: "服务记录",
      },
    }
  },
  created () {
    this.loadingRecordSum = true

    dashboardServiceSummary({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }).then((resSum) => {
      if (resSum.status !== undefined && resSum.status === 1) {
        this.allCount = resSum.data.all
        this.countRate = resSum.data.lastmonth === 0 ? resSum.data.thismonth : Math.round(((resSum.data.thismonth / resSum.data.lastmonth) - 1) * 100)
        this.lineChartData.labels = []
        this.lineChartData.datas = []
        resSum.data.monthdata.forEach(element => {
          this.lineChartData.labels.push(element.month)
          this.lineChartData.datas.push(element.s_count)
        })
        let ctx = this.$refs.chartRecord.getContext("2d")

        var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)

        gradientStroke.addColorStop(1, 'rgba(24, 144, 255, .1)')
        gradientStroke.addColorStop(0.2, 'rgba(24, 144, 255, 0)')
        gradientStroke.addColorStop(0, 'rgba(24, 144, 255, 0)') // Primary color

        this.chartRecord = new Chart(ctx, {
          type: "line",
          data: {
            labels: this.lineChartData.labels,
            datasets: [{
              label: this.lineChartData.title,
              tension: 0.5,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#1890FF",
              borderWidth: 2,
              backgroundColor: gradientStroke,
              data: this.lineChartData.datas,
              maxBarThickness: 6,
              fill: true
            }],
          },
          options: {
            layout: {
              padding: {
                top: 10,
                right: 10,
                left: 10,
                bottom: 10,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5]
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 1000,
                  display: true,
                  padding: 5,
                  color: '#9ca2b7',
                  font: {
                    size: 13,
                    lineHeight: 1.2,
                    weight: '400',
                    family: "Open Sans",
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5]
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: '#9ca2b7',
                  font: {
                    size: 13,
                    lineHeight: 1.2,
                    weight: '400',
                    family: "Open Sans",
                  },
                },
              },
            },
          }
        })
      }
      this.loadingRecordSum = false
    })
  },
  beforeDestroy: function () {
    if (this.chartRecord !== undefined) {
      this.chartRecord.destroy()
    }
  },
})

</script>