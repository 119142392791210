<template>
  <a-affix :offset-top="top">
    <!-- 客户定制食谱 Card -->
    <a-spin
      :tip="tip"
      size="large"
      :spinning="loadingFoodmenu"
    >
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
      >
        <a-row
          type="flex"
          :gutter="24"
          v-if="patFoodMenuInfoArr.length > 0"
        >
          <template v-for="(rdv,idx) in patFoodMenuInfoArr">
            <a-col
              :span="6"
              :key="idx"
              class="mb-24"
            >
              <a-card
                :bordered="false"
                class="card-project-2 header-solid"
                :bodyStyle="{paddingTop: '14px' }"
                :headStyle="{paddingBottom: '0' }"
              >
                <template #title>
                  <a-row
                    type="flex"
                    :gutter="[24,24]"
                    align="middle"
                  >
                    <a-col>
                      <a-avatar
                        :size="74"
                        shape="square"
                        style="border-radius: 8px; background-image: linear-gradient( 310deg, #141727, #3a416f );"
                      >
                        <img
                          :src="rdv.img_show"
                          style="width: 100%;"
                          alt=""
                        >
                      </a-avatar>
                    </a-col>
                    <a-col
                      @click="clickViewFoodmenu(rdv)"
                      class="more-div"
                      style="max-width: calc(100% - 140px); overflow: auto;"
                    >
                      <h6 class="font-semibold mb-10">{{ rdv.menu_name}}</h6>
                      {{ rdv.update_time !== undefined && rdv.update_time !== null ? rdv.update_time.substr(0,10) : '' }}
                    </a-col>
                    <a-col
                      class="ml-auto pt-10"
                      style="align-self: flex-start;"
                    >
                      <a
                        @click="clickViewFoodmenu(rdv)"
                        title="点击查看明细"
                      >
                        <a-icon
                          type="more"
                          class="text-muted"
                          style="font-size: 18px;"
                        />
                      </a>
                    </a-col>
                  </a-row>
                </template>
                <p class="mb-0">菜谱介绍： {{ rdv.menu_intro }}</p>
                <p class="mb-0">不喜欢食物： {{ rdv.food_dislike }}</p>
                <p class="mb-0">过敏食物： {{ rdv.allergies }}</p>
                <p class="mb-0">不耐受食物： {{ rdv.food_intolerant }}</p>
                <p class="mb-0">营养补充剂： {{ rdv.supplement }}</p>
                <hr>
                <a-row
                  type="flex"
                  :gutter="[24,24]"
                  align="middle"
                >
                  <a-col>
                    <!-- <h6 class="font-semibold text-md mb-0"></h6>
                    <p class="font-semibold text-muted mb-0">食谱日期</p> -->
                  </a-col>
                  <a-col class="ml-auto">
                    <p class="font-semibold text-muted mb-0">食谱日期：{{ rdv.start_date }} ~ {{ rdv.end_date }}</p>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>

            <a-col
              :span="24"
              :key="idx+1"
              v-if="idx >= patFoodMenuInfoArr.length - 1"
            >
              <div
                v-if="page <= lastPage "
                @click="clickLoadMore"
                class="more-div"
              >
                <a-divider>点击加载更多</a-divider>
              </div>
            </a-col>
          </template>
        </a-row>

        <!-- 定制食谱的详情弹窗 -->
        <CardFoodmenuOneModal ref="cardFoodmenuOneModal" />
      </a-card>
      <!-- / 客户定制食谱 Card -->
    </a-spin>
  </a-affix>
</template>

<script>
import { listPatientFoodMenu } from '@/api/dms/patientFoodMenu'
import DailyFoodMenuViewChart from '@/components/DailyFoodMenus/DailyFoodMenuViewChart'
import CardFoodmenuOneModal from './CardFoodmenuOneModal'

import moment from 'moment'

export default ({
  name: 'CardFoodmenuAll',
  components: {
    DailyFoodMenuViewChart,
    CardFoodmenuOneModal
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tip: '刷新中...',
      top: 10,
      loadingFoodmenu: false,
      pageSize: 2,
      page: 1,
      lastPage: 1,
      patFoodMenuInfoArr: []
    }
  },
  created () {
    this.refreshFoodmenuList()
  },
  methods: {
    moment,
    clickLoadMore () {
      console.log(this.page, this.lastPage)
      if (this.page > this.lastPage) {
        this.$message.error('下面没有数据啦~~！')
        return false
      }
      this.refreshFoodmenuList()
    },
    refreshFoodmenuList () {
      this.loadingFoodmenu = true
      listPatientFoodMenu({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id, page: this.page, pageSize: this.pageSize }).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1) {
          this.patFoodMenuInfoArr = this.patFoodMenuInfoArr.concat(res.data)
          this.lastPage = res.last_page
          this.page++
        }
        this.loadingFoodmenu = false
      })
    },
    clickViewFoodmenu (foodmenu) {
      this.$refs.cardFoodmenuOneModal.showModal(foodmenu)
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>