<template>
  <a-drawer
    width="450"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="serviceRateVisibleCu"
    title="健康服务导师评分"
    @close="closeServiceRateDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="serviceRateSpinning"
    >
      <div>
        <form-create
          v-model="cformSeRateApi"
          :rule="cformSeRateRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="9"
          :span="15"
        >
          <a-button
            @click="closeServiceRateDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { saveServiceRate } from '@/api/dms/serviceRecordRating'

export default ({
  name: 'ServiceRate',
  data () {
    return {
      serviceRateVisibleCu: false,
      serviceRateSpinning: false,
      cformSeRateApi: {},
      cformSeRateRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
        },
        {
          type: 'hidden',
          field: 'patient_id',
        },
        {
          type: 'hidden',
          field: 'rating_id',
        },
        {
          type: 'hidden',
          field: 'is_add',
        },
        {
          type: 'hidden',
          field: 'is_public',
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'service_record_id',
        },
        {
          type: 'rate',
          field: 'rating_val',
          title: '评分',
          props: {
            count: 5, allowHalf: true
          },
          validate: [
            { required: true, message: '请确认选择评分', type: 'number', trigger: 'change' }
          ]
        },
        {
          type: 'input',
          title: '评价内容',
          field: 'comment_content',
          props: { type: 'textarea', autoSize: { minRows: 3 }, placeholder: '请填写评价内容' },
          validate: [
            { required: true, message: '请确认填写评价内容', whitespace: true }
          ]
        }
      ],
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      }
    }
  },
  created () {

  },
  methods: {
    showDrawer (params) {
      this.serviceRateVisibleCu = true
      this.$nextTick(() => {
        this.cformSeRateApi.setValue(params)
      })
    },
    closeServiceRateDrawer () {
      this.serviceRateVisibleCu = false
    },
    submitBtnClick () {
      this.cformSeRateApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformSeRateApi.formData()
          console.log(cuFormData)
          this.serviceRateSpinning = true
          saveServiceRate(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.cformSeRateApi.resetFields()
              this.closeServiceRateDrawer()
              this.$emit('complete')
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.serviceRateSpinning = false
          })
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
</style>