import request from '@/utils/request'

const apiUrls = {
  saveSurveyDefInfo: '/MiscSurveyDefs/saveSurveyDefInfo',
  listSurveyDef: '/MiscSurveyDefs/listSurveyDef'
}

export function saveSurveyDefInfo (data) {
  return request({
    url: apiUrls.saveSurveyDefInfo,
    method: 'post',
    data
  })
}

export function listSurveyDef (parameter) {
  return request({
      url: apiUrls.listSurveyDef,
      method: 'get',
      params: parameter
  })
}
