<template>
  <a-modal
    v-model="visible"
    title="大图预览"
    width="60%"
  >
    <template slot="footer">
      <a-button
        key="back"
        @click="handleCancel"
      >
        关闭
      </a-button>
    </template>
    <div class="text-center">
      <img
        class="show-img"
        v-if="imgUrl !== undefined && imgUrl !== null && imgUrl !== ''"
        :src="imgUrl"
      >
      <a-empty
        v-else
        description="暂无图片信息"
      />
    </div>
  </a-modal>
</template>

<script>
export default ({
  name: 'ImageModalView',
  data () {
    return {
      visible: false,
      imgUrl: ''
    }
  },
  created () {
  },
  methods: {
    handleCancel () {
      this.imgUrl = ''
      this.visible = false
    },
    showModal (paramData) {
      this.visible = true
      this.$nextTick(() => {
        if (paramData.img_show !== undefined && paramData.img_show !== null) {
          this.imgUrl = paramData.img_show
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
.show-img {
  max-width: 100%;
  max-height: 100%;
}
</style>