<template>
  <a-card
    :bordered="false"
    id="profile"
    class="header-solid mb-24"
  >
    <template #title>
      <h5 class="mb-0 font-semibold">客户基础信息</h5>
    </template>

    <div class="ml-20">
      <a-descriptions
        :title="null"
        v-if="patientInfo.id !== undefined"
      >
        <a-descriptions-item label="客户姓名">{{ patientInfo.name }}</a-descriptions-item>
        <a-descriptions-item label="性别">{{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}</a-descriptions-item>
        <a-descriptions-item label="出生日期">{{ patientInfo.birth_date }}</a-descriptions-item>

        <a-descriptions-item label="编号">{{ patientInfo.patient_no }}</a-descriptions-item>
        <a-descriptions-item label="联系电话">{{ patientInfo.contact }}</a-descriptions-item>
        <a-descriptions-item label="身份证号">{{ patientInfo.id_card }}</a-descriptions-item>

        <!-- <a-descriptions-item label="身高(cm)">{{ patientInfo.height }}</a-descriptions-item>
        <a-descriptions-item label="体重(kg)">{{ patientInfo.weight }}</a-descriptions-item>
        <a-descriptions-item label="BMI">{{ patientInfo.bmi }}</a-descriptions-item>

        <a-descriptions-item label="基础代谢率(Kcal)">{{ patientInfo.bmr }}</a-descriptions-item>
        <a-descriptions-item label="腰围(cm)">{{ patientInfo.waistline }}</a-descriptions-item>
        <a-descriptions-item label="臀围(cm)">{{ patientInfo.hipline }}</a-descriptions-item> -->

        <a-descriptions-item
          label="关注问题"
          :span="3"
        >
          <a-tag
            color="orange"
            v-for="tag in patientInfo.his_patient_tag"
            :key="tag.tag_id"
          >
            {{tag.tag_text}}
          </a-tag>
        </a-descriptions-item>
      </a-descriptions>

      <template v-if="bodyInfo.length > 0">
        <a-divider style="margin: 10px 0;" />
        <a-descriptions
          title="最新体格指标"
          v-if="bodyInfo.length > 0"
        >
          <template v-for="(bv,bi) in bodyInfo">
            <a-descriptions-item
              :key="bi"
              :label="bv.item_name"
            >{{ bv.result_val + ' ' + bv.unit_name }}</a-descriptions-item>
          </template>
        </a-descriptions>
      </template>
    </div>
  </a-card>
</template>

<script>
import { getPatientNewestBodyInfo, getPatientInfo } from '@/api/dms/patient'

export default ({
  // 客户基础信息的card
  name: 'PatientBasicInfo',
  props: {
    parentParams: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      bodyInfo: [],
      patientInfo: {}
    }
  },
  created () {
    // 考虑获取客户基础信息+体格的地方一般在页面靠前的开始位置，所以此处的spinning跟随父级加载统一显示
    this.$emit('spinningChange', true)
    getPatientInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.parentParams.patient) {
        this.patientInfo = res.data
        getPatientNewestBodyInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
          if (res2.status !== undefined && res2.status === 1) {
            this.bodyInfo = res2.data
          }
          this.$emit('spinningChange', false)
        })
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch((e) => {
      console.log(e)
      return this.$router.go(-1)
    })

  }
})

</script>