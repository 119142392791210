<template>
  <div>
    <div class="my-20 mx-10">
      视频地址Url：{{ urlVedio }}
    </div>

    <div class="player-container mx-10 pb-50">
      <vue-core-video-player
        v-if="urlVedio !== ''"
        :autoplay="false"
        :loop="false"
        title="学习视频-课程1"
        logo="/logo_ryx.png"
        :src="urlVedio"
      ></vue-core-video-player>
      <!-- src="https://pic.renyaoxing.com/端午小视频-20240523120141764683.mp4?e=1716451334&token=l0Aa0zIddqS9G3X8S-GUFAtsUCKC9HsNATcjnLxJ:wEAB7f5Rv-PL6aVypdR8Z48UyT0=" -->
      <!-- src="https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4" -->
    </div>
  </div>

</template>

<script>
import { getShowUrlByFilename } from '@/api/manage'

export default ({
  data () {
    return {
      urlVedio: ''
    }
  },
  created () {
    getShowUrlByFilename({ storageMark: 'qiniu', filename: '端午小视频-20240523120141764683.mp4' }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.urlVedio = res.data
      }
    })
  },
  methods: {
  }
})

</script>

<style lang="scss">
.border-red {
  border: 1px solid red !important;
}
</style>