<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="saveDepRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getDepartmentInfo, updateDepartment, selectListDepartment } from '@/api/dms/department'
import { checkMobile } from '@/utils/customValidate'

export default {
  name: 'EditDep',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'input',
          title: '部门名称',
          field: 'department_name',
          props: { placeholder: '请填写部门名称' },
          validate: [
            { required: true, message: '请确认输入部门名称', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '上级部门',
          field: 'pid',
          props: { placeholder: '请选择上级部门' },
          options: []
        },
        {
          type: 'select',
          title: '部门类型',
          field: 'type',
          value: '1',
          props: { placeholder: '请选择部门类型' },
          options: [],
          validate: [
            { required: true, message: '请确认选择部门类型', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '部门描述',
          field: 'describle',
          props: { placeholder: '请填写部门描述' }
        },
        {
          type: 'input',
          title: '负责人',
          field: 'principal',
          props: { placeholder: '请填写负责人' }
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'telphone',
          props: { placeholder: '请填写联系电话' },
          validate: [
            { validator: checkMobile }
          ]
        },
        {
          type: 'inputNumber',
          title: '排序号',
          field: 'sort', style: { width: '100%' },
          props: { placeholder: '请填写排序号', min: 0, step: 1, max: 10000 }
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      departmentListData: []
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.editDep'))
    if (this.params === null || this.params.dep === undefined || this.params.dep < 1) {
      this.$message.error('请确认选择组织机构！')
      return this.$router.go(-1)
    }
    this.spinning = true
    getDepartmentInfo({ dep: this.params.dep }).then(res2 => {
      if (res2.status !== undefined && res2.status === 1) {
        res2.data.pid = res2.data.pid > 0 ? res2.data.pid.toString() : undefined
        res2.data.type = res2.data.type.toString()
        this.cformApi.setValue(res2.data)
      }
    })
    selectListDepartment({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.departmentListData = res.data
        this.cformApi.updateRules({ // 更新规则
          'pid': { options: this.departmentListData },
          'type': { options: this.$options.filters.selectOptionsData('department.type') }
        })
      }
      this.spinning = false
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    saveDepRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      updateDepartment(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '编辑部门保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () { }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>