<template>
  <div>
    <a-card :bordered="false">
      <div class="mx-25">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入角色名称搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="7"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.roleListTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                  <router-link
                    :to="'/Enterprises/addRole'"
                    :hidden="'/Enterprises/addRole' | isShowButton"
                  >
                    <a-button
                      type="primary"
                      icon="plus"
                    >新增企业角色</a-button>
                  </router-link>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>

      <s-table
        ref="roleListTable"
        size="default"
        rowKey="id"
        showPagination="auto"
        :columns="columns"
        :data="loadData"
        :pageSize="pageSize"
        :scroll="tableScroll"
      >
        <span
          slot="action"
          slot-scope="text, record"
        >
          <template>
            <a
              v-if="record.is_admin !== 1"
              :hidden="'/Enterprises/editRole' | isShowButton"
              @click="clickEdit(record)"
            >编辑</a>
            <a-divider
              v-if="record.is_admin !== 1"
              type="vertical"
              :hidden="'/Enterprises/grant' | isShowSeparator(['/Enterprises/editRole'])"
            />
            <span
              v-if="record.is_admin !== 1"
              :hidden="'/Enterprises/grant' | isShowButton"
            >
              <a @click="clickAuth(record)">角色授权</a>
            </span>
          </template>
        </span>
      </s-table>
    </a-card>
  </div>
</template>

<script>
import { getRoleList } from '@/api/dms/adminRole'
export default {
  name: 'ListRole',
  data () {
    return {
      columns: [
        {
          title: 'ID',
          width: 90,
          dataIndex: 'id'
        },
        {
          title: '角色名称',
          width: 180,
          dataIndex: 'name'
        },
        {
          title: '是否超管',
          dataIndex: 'is_admin',
          width: 100,
          customRender: (text) => {
            return text === 1 ? '是' : '否'
          }
        },
        {
          title: '说明',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          width: 180,
          ellipsis: true,
          dataIndex: 'create_date'
        },
        {
          title: '更新时间',
          width: 180,
          ellipsis: true,
          dataIndex: 'update_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 150,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ plattype: this.$store.getters.userInfo.platform.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return getRoleList(requestParameters)
          .then(res => {
            return res
          })
      }
    }
  },
  created () {

  },
  computed: {

  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('Enterprises.editRole', JSON.stringify({ role: record.id }))
      this.$router.push({ path: '/Enterprises/editRole' })
    },
    clickAuth (record) {
      sessionStorage.setItem('Enterprises.grant', JSON.stringify({ role: record.id }))
      this.$router.push({ path: '/Enterprises/grant' })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>