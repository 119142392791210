<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="editShopGoodsSkuForm"
        >
          <div class="title">商品SKU信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="商品展示名"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                required
              >
                <a-input
                  placeholder="商品展示名"
                  disabled
                  v-decorator="[
                    'goods_name',
                    { initialValue: skuInfo.goods_name }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示标题"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                required
              >
                <a-input
                  placeholder="展示标题"
                  disabled
                  v-decorator="[
                    'title',
                    { initialValue: skuInfo.title }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="商品SKU规格"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
                required
              >
                <a-input
                  placeholder="商品SKU规格"
                  disabled
                  v-decorator="[
                    'specs',
                    { initialValue: skuInfo.showSpec }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="SKU商品"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-select
                  style="margin: -5px 0;width:100%;"
                  placeholder="请选择SKU商品"
                  show-search
                  optionFilterProp="children"
                  :filter-option="goodsFilterOption"
                  v-decorator="[
                    'goods_id',
                    { initialValue: skuInfo.goods_id+'-'+skuInfo.pid,rules: [{ required: true, message: '请选择SKU商品' }] },
                  ]"
                >
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList1.length > 0"
                    >
                      <a-icon type="home" /> 自有货品
                    </span>
                    <a-select-option
                      v-for="(g1,i1) in goodsList1"
                      :key="i1"
                      :value="g1.value"
                      :disabled="g1.disabled"
                    >
                      <span>{{ g1.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g1.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList2.length > 0"
                    >
                      <a-icon type="branches" /> 代理货品
                    </span>
                    <a-select-option
                      v-for="(g2,i2) in goodsList2"
                      :key="i2"
                      :value="g2.value"
                      :disabled="g2.disabled"
                    >
                      <span>{{ g2.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g2.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="成本价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写成本价"
                  style="width:100%;"
                  :min="0"
                  :step="0.01"
                  v-decorator="[
                    'cost_price',
                    { initialValue: skuInfo.cost_price,rules: [{ required: true, message: '请填写成本价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="销售标价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写销售标价"
                  style="width:100%;"
                  :min="0"
                  :step="0.01"
                  v-decorator="[
                    'unit_price',
                    { initialValue: skuInfo.unit_price,rules: [{ required: true, message: '请填写销售标价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="实际售价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写实际售价"
                  style="width:100%;"
                  :min="0"
                  :step="0.01"
                  v-decorator="[
                    'promotion_unit_price',
                    { initialValue: skuInfo.promotion_unit_price,rules: [{ required: true, message: '请填写实际售价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="上架库存数"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写上架库存数"
                  style="width:100%;"
                  :min="0"
                  :step="1"
                  v-decorator="[
                    'inventory',
                    { initialValue: skuInfo.inventory,rules: [{ required: true, message: '请填写上架库存数' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
            class="mt-20"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getEnterpriseGoodsList } from '@/api/dms/enterpriseGood'
import { updateEmallGoodsSku, getEmallGoodsSkuInfo } from '@/api/dms/emallGoodSku'

export default {
  name: 'EditShopGoodsSkuForm',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      editShopGoodsSkuForm: this.$form.createForm(this),
      goodsList1: [],
      goodsList2: [],
      skuInfo: {}
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallGoods.editShopGoodsSku'))
    if (this.params === null || this.params.gsku === undefined || this.params.gsku < 1) {
      this.$message.error('请确认选择商城货品SKU信息！')
      return this.$router.go(-1)
    }
    this.spinning = true
    this.editShopGoodsSkuForm.getFieldDecorator('id', { initialValue: this.params.gsku, preserve: true, rules: [{ required: true }] })
    this.editShopGoodsSkuForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })

    this.loadInfoData()
  },
  methods: {
    loadInfoData () {
      // 获取sku详情信息
      getEmallGoodsSkuInfo({ gsku: this.params.gsku, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.skuInfo = res.data
        }
      })
      // 获取企业货品数据
      getEnterpriseGoodsList({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          if (res.data.baseGoods !== undefined && res.data.baseGoods.length > 0) {
            this.goodsList1 = res.data.baseGoods
          }
          if (res.data.contractGoods !== undefined && res.data.contractGoods.length > 0) {
            this.goodsList2 = res.data.contractGoods
          }
          if (this.goodsList1.length < 1 && this.goodsList2.length < 1) {
            this.$message.error('企业暂无货品信息！')
          }
        } else {
          this.$message.error(res.msg || '企业货品信息加载失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()

      this.editShopGoodsSkuForm.validateFields((err, values) => {
        if (!err) {
          this.tip = '处理中...'
          this.spinning = true
          updateEmallGoodsSku(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回商品SKU信息管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () { }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    goodsFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].children !== undefined && option.componentOptions.children[0].children[0] !== undefined && option.componentOptions.children[0].children[0].text !== undefined) {
        return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.noneDisplay {
  display: none;
}
</style>
