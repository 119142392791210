<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入客户姓名、客户编号、客户来源搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.hisPatientTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/HisPatients/addPatient'"
                      :hidden="'/HisPatients/addPatient' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增客户</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="hisPatientTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="gender"
            slot-scope="text"
          >
            {{ 'ryx.gender' | showText(text.toString()) }}
          </span>

          <span
            slot="disease_tag"
            slot-scope="text,record"
          >
            <template>
              <a-tag
                color="orange"
                v-for="tag in record.his_patient_tag"
                :key="tag.tag_id"
              >
                {{tag.tag_text}}
              </a-tag>
            </template>
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/HisPatients/viewPatient' | isShowButton">
                <a @click="clickView(record)">详情</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/HisPatients/addPatientAppointment'] | isShowSeparator(['/HisPatients/viewPatient'])"
              />
              <span :hidden="'/HisPatients/addPatientAppointment' | isShowButton">
                <a @click="clickSetAppoint(record)">预约</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/HisPatients/editPatient'] | isShowSeparator(['/HisPatients/addPatientAppointment'])"
              />

              <a-dropdown :hidden="['/HisPatients/editPatient','/HisPatients/addPatientMeasureInfo','/HisPatients/inputSurveyByUser','/HisPatients/addPatientAppointment','/HisPatients/setServiceRecord'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/HisPatients/editPatient' | isShowButton"
                    key="0"
                  >
                    <a @click="clickEdit(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/editPatient' | isShowButton"
                    key="1"
                  >
                    <a @click="clickManage(record)">档案管理</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/HisPatients/setServiceRecord' | isShowButton"
                    key="2"
                  >
                    <a @click="clickSetRecord(record)">问诊记录</a>
                  </a-menu-item>
                  <a-menu-divider :hidden="['/HisPatients/addPatientMeasureInfo'] | isShowSeparator(['/HisPatients/editPatient','/HisPatients/setServiceRecord'])" />
                  <a-menu-item
                    :hidden="'/HisPatients/addPatientMeasureInfo' | isShowButton"
                    @click="clickMeasure(record)"
                    key="3"
                  >检测结果录入</a-menu-item>
                  <!-- <a-menu-divider :hidden="['/HisPatients/inputSurveyByUser','/HisPatients/addPatientAppointment'] | isShowSeparator(['/HisPatients/addPatientMeasureInfo'])" />
                  <a-menu-item
                    :hidden="'/HisPatients/inputSurveyByUser' | isShowButton"
                    @click="showSurveyList(record)"
                    key="4"
                  >问卷调查</a-menu-item> -->
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>
      </a-card>

      <!-- 问卷列表的抽屉窗口 -->
      <!-- <survey-list
        :parentParams="surveyDrawerParams"
        :surveyDrawerVisible="surveyDrawerVisible"
        @surveyDrawerVisibleChange="surveyDrawerVisibleChange"
      /> -->

      <!-- 手动新增客户预约抽屉窗口 -->
      <schedule-appointment
        :parentParams="patAppointParam"
        :patAppointVisible="patAppointVisible"
        @patAppointVisibleChange="patAppointVisibleChange"
      />

      <!-- 档案管理抽屉窗口 -->
      <patient-archive-manage
        :parentParams="patArchiveParam"
        :archiveDrawerVisible="archiveDrawerVisible"
        @archiveDrawerVisibleChange="archiveDrawerVisibleChange"
      />

      <!-- 客户问诊服务记录列表 -->
      <ServiceRecordDrawer ref="serviceRecordDrawer" />
    </a-spin>
  </div>
</template>

<script>
import { listHisPlatformPatient } from '@/api/dms/patient'
// import { PrintReportDom } from '@/components/Report'PrintReportDom, 
// import { SurveyList } from '@/components/Surveys' SurveyList,
import { ScheduleAppointment } from '@/components/Schedule'
import { PatientArchiveManage } from '@/components/PatientCards'
import { ServiceRecordDrawer } from '@/components/ServiceRecord'

export default {
  name: 'HisPatientListManage',
  components: { ScheduleAppointment, PatientArchiveManage, ServiceRecordDrawer },
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 70,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '客户姓名',
          dataIndex: 'name',
          width: 170,
          ellipsis: true,
          customRender: (text, row) => {
            return text + ' / ' + row.name_py
          }
        },
        {
          title: '客户编号',
          dataIndex: 'patient_no',
          width: 200,
          ellipsis: true
        },
        {
          title: '客户来源',
          dataIndex: 'enterprise_name',
          width: 220,
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 50,
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '出身年月',
          dataIndex: 'birth_date',
          width: 95,
          ellipsis: true
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone',
          width: 110,
          ellipsis: true
        },
        // {
        //   title: '血型ABO',
        //   dataIndex: 'blood_abo',
        //   width: 95,
        //   ellipsis: true
        // },
        // {
        //   title: '血型RHD',
        //   dataIndex: 'blood_rhd',
        //   width: 95,
        //   ellipsis: true
        // },
        // {
        //   title: '身高(Kg)',
        //   dataIndex: 'height',
        //   width: 100,
        //   ellipsis: true
        // },
        // {
        //   title: '体重(Cm)',
        //   dataIndex: 'weight',
        //   width: 100,
        //   ellipsis: true
        // },
        // {
        //   title: 'BMI',
        //   dataIndex: 'bmi',
        //   width: 90,
        //   ellipsis: true
        // },
        {
          title: '关注问题',
          // width: 350,
          ellipsis: true,
          scopedSlots: { customRender: 'disease_tag' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ join: ['diseasetag', 'enterprise'] }, parameter, this.queryParam)
        // const that = this
        return listHisPlatformPatient(requestParameters)
          .then(res => {
            console.log(res)
            // if (res.data.length > 0) {
            //   that.tableScroll = { x: 1500 }
            // } else {
            //   that.tableScroll = {}
            // }
            return res
          })
      },
      surveyDrawerVisible: false,
      surveyDrawerParams: {},
      patAppointVisible: false,
      patAppointParam: {},
      archiveDrawerVisible: false,
      patArchiveParam: {}
    }
  },
  created () {
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('HisPatients.editPatient', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/editPatient' })
    },
    clickView (record) {
      sessionStorage.setItem('HisPatients.viewPatient', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/viewPatient' })
    },
    clickMeasure (record) {
      sessionStorage.setItem('HisPatients.addPatientMeasureInfo', JSON.stringify({ patient: record.id }))
      this.$router.push({ path: '/HisPatients/addPatientMeasureInfo' })
    },
    // surveyDrawerVisibleChange (v) {
    //   this.surveyDrawerVisible = v
    // },
    // showSurveyList (row) {
    //   this.surveyDrawerParams = { name: row.name, phone: row.contact_phone, patient: row.id }
    //   // 打开问卷调查列表
    //   this.surveyDrawerVisible = true
    // },
    clickSetAppoint (record = false) {
      // 平台分配医生，不带入当前用户
      let cuPatApParm = { pat_mark: true }
      if (record !== false && record.id !== undefined) {
        cuPatApParm.choose_patient = record.id
      }
      this.patAppointVisible = true
      this.patAppointParam = JSON.parse(JSON.stringify(cuPatApParm))
    },
    patAppointVisibleChange (v) {
      this.patAppointVisible = v
    },
    archiveDrawerVisibleChange (v) {
      this.archiveDrawerVisible = v
    },
    clickManage (record) {
      // 档案管理弹窗
      this.patArchiveParam = { patient: record.id }
      this.archiveDrawerVisible = true
    },
    clickSetRecord (record) {
      this.$refs.serviceRecordDrawer.showDrawer(record)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
