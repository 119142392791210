import jsRsasign from 'jsrsasign'
import CryptoJS from 'crypto-js'
import { Base64 } from 'js-base64'

const aesKey = CryptoJS.enc.Utf8.parse('/sKgQoghVh+wBimloQnWkxpuUGPVBEX+')

// rsa公钥验证签名
export function verifySign (source, signStr) {
	let signPublicKey = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMrZZNNzmZqJ2o+lAnRXe2LPNR
    Y9eAQoh6ISExOzh9HQD89vWH0g7kG1g/OHkg1pnIoOU6k3rnBN8JVWUiVfX4zVNC
    NmPLxcnHOOUA8WbeW/Jb1XWAZ7VV+OG8FPt/yxtwnIImHvDGMAezawQGGuM7lJln
    JedNJgeGfd63mr2xzwIDAQAB
    -----END PUBLIC KEY-----`
	// 将公钥转码
	signPublicKey = jsRsasign.KEYUTIL.getKey(signPublicKey)
	// 创建Signature对象，设置签名编码算法
	const signature = new jsRsasign.KJUR.crypto.Signature({ 'alg': 'SHA1withRSA', 'prov': 'cryptojs/jsrsa', 'prvkeypem': signPublicKey })
	// 初始化
	signature.init(signPublicKey)
	// 传入待加密字符串
	signature.updateString(source)
	// !接受的参数是16进制字符串!
	const sign = signature.verify(jsRsasign.b64tohex(signStr))
    return sign
}

// aes加密采用对象方式
export function aesEncryptArray (data) {
    // 转换为json字符串
    if (typeof data !== 'string') {
        data = JSON.stringify(data)
    }
    const iv = randomString()
    // const srcs = CryptoJS.enc.Utf8.parse(data)
    const encrypted = CryptoJS.AES.encrypt(data, aesKey, {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString()
    const encodeStr = JSON.stringify({ iv: Base64.encode(iv), value: Base64.encode(encrypted) })
    return { result: Base64.encode(encodeStr) }
}

// aes加密采用拼接字符串方式
export function aesEncrypt (data) {
    // 转换为json字符串
    if (typeof data !== 'string') {
        data = JSON.stringify(data)
    }
    const iv = randomString()
    // const srcs = CryptoJS.enc.Utf8.parse(data)
    const encrypted = CryptoJS.AES.encrypt(data, aesKey, {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString()
    const ivStr = Base64.encode(iv)
    const encodeStr = ivStr + Base64.encode(encrypted) + '@' + ivStr.length
    return { result: Base64.encode(encodeStr) }
}

// aes解密字符串方式的
export function aesDecrypt (data) {
    // data = JSON.parse(Base64.decode(data))
    // console.log(data)
    // console.log(Base64.decode(data.iv))
    // const key = CryptoJS.enc.Utf8.parse('/sKgQoghVh+wBimloQnWkxpuUGPVBEX+KZ9nex12m5I=')
    // const key = CryptoJS.enc.Utf8.parse(Base64.decode('/sKgQoghVh+wBimloQnWkxpuUGPVBEX+KZ9nex12m5I='))
    // const key = Base64.decode('/sKgQoghVh+wBimloQnWkxpuUGPVBEX+KZ9nex12m5I=')
    // const key = '/sKgQoghVh+wBimloQnWkxpuUGPVBEX+KZ9nex12m5I='
    // const iv = CryptoJS.enc.Utf8.parse(data.iv)
    // const iv = CryptoJS.enc.Utf8.parse(Base64.decode(data.iv))
    // const iv = Base64.decode(data.iv)
    // const iv = data.iv

    // var decrypted2 = CryptoJS.AES.decrypt(Base64.decode(data.value), key, {
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // })
    // decrypted2 = decrypted2.toString(CryptoJS.enc.Utf8)
    // console.log(decrypted2)
    // console.log(decrypted2.toString())
    // console.log(decrypted2.toString(CryptoJS.enc.Utf8))
    // console.log(CryptoJS.enc.Utf8.stringify(decrypted2))

    // data = JSON.parse(Base64.decode(data))
    data = Base64.decode(data)
    data = data.split('@')
    if (data.length !== 2) {
        return false
    }
    const ivStr = Base64.decode(data[0].substr(0, data[1]))
    const valStr = Base64.decode(data[0].substr(data[1]))
    // const iv = CryptoJS.enc.Utf8.parse(Base64.decode(data.iv))
    // var decrypted2 = CryptoJS.AES.decrypt(Base64.decode(data.value), aesKey, {
    const iv = CryptoJS.enc.Utf8.parse(ivStr)
    var decrypted2 = CryptoJS.AES.decrypt(valStr, aesKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    decrypted2 = decrypted2.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decrypted2)
}

// aes解密对象方式的
export function aesDecryptArray (data) {
    data = JSON.parse(Base64.decode(data))
    const iv = CryptoJS.enc.Utf8.parse(Base64.decode(data.iv))
    var decrypted2 = CryptoJS.AES.decrypt(Base64.decode(data.value), aesKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    decrypted2 = decrypted2.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decrypted2)
}

// 生成指定长度的随机字符串
export function randomString (len) {
    len = len || 16
    // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}
