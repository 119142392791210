<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-row class="mb-20">
        <a-col
          :span="24"
          class="text-right pr-35"
        >
          <a-button
            @click="printClick"
            type="primary"
          >打印方案</a-button>
          <a-button
            class="ml-10"
            @click="downloadClick(0)"
            type="primary"
          >下载方案(压缩版)</a-button>
        </a-col>
      </a-row>

      <div v-if="patientInfo.id !== undefined && patientInfo.id > 0">
        <div
          id="printDiv"
          @click="printClick"
        >
          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage0"
              ref="alanberPage0"
            >
              <nutrition-page-1 />
            </div>
          </a-card>

          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage1"
              ref="alanberPage1"
            >
              <nutrition-page-2 />
            </div>
          </a-card>

          <a-card
            :bordered="false"
            class="paddingDiv"
            :bodyStyle="{padding: 0}"
          >
            <div
              class="nutritionPage"
              id="alanberPage3"
              ref="alanberPage3"
            >
              <nutrition-page-3
                :patientInfo="patientInfo"
                :pageIndex="1"
                :pageTotal="pageTotal"
                :bodyInfo="bodyInfo"
                :preConsultationOri="preConsultationOri"
              />
            </div>
          </a-card>
          <template v-if="nutritionInfo.id !== undefined && nutritionInfo.id > 0">
            <div class="paddingDiv">
              <div
                class="nutritionPage"
                id="alanberPage4"
                ref="alanberPage4"
              >
                <nutrition-page-4
                  :nutritionInfo="nutritionInfo"
                  :dietaryPatternName="dietaryPatternName"
                  :patientInfo="patientInfo"
                  :pageIndex="2"
                  :pageTotal="pageTotal"
                />
              </div>
            </div>

            <div
              class="paddingDiv"
              v-if="nutritionInfo.recipe_id !== null && nutritionInfo.recipe_id > 0"
            >
              <div
                class="nutritionPage"
                id="alanberPage5"
                ref="alanberPage5"
              >
                <nutrition-page-5
                  :nutritionInfo="nutritionInfo"
                  :patientInfo="patientInfo"
                  :pageIndex="3"
                  :pageTotal="pageTotal"
                />
              </div>
            </div>

            <div
              class="paddingDiv"
              v-if="(nutritionInfo.life_id !== null && nutritionInfo.life_id > 0) || (nutritionInfo.prescription_sport.length > 0)"
            >
              <div
                class="nutritionPage"
                id="alanberPage6"
                ref="alanberPage6"
              >
                <nutrition-page-6
                  :nutritionInfo="nutritionInfo"
                  :patientInfo="patientInfo"
                  :pageIndex="nutritionInfo.recipe_id !== null && nutritionInfo.recipe_id > 0 ? 4 : 3"
                  :pageTotal="pageTotal"
                />
              </div>
            </div>

            <div class="paddingDiv">
              <div
                class="nutritionPage"
                id="alanberPage7"
                ref="alanberPage7"
              >
                <nutrition-page-7
                  :nutritionInfo="nutritionInfo"
                  :pageIndex="5 - pageDiff"
                  :pageTotal="pageTotal"
                  :patientInfo="patientInfo"
                />
              </div>
            </div>

            <div v-if="dietaryPatternInfo.length > 0">
              <div
                v-for="(dpv,dpi) in dietaryPatternInfo"
                :key="dpi"
                class="paddingDiv"
              >
                <div class="nutritionPage">
                  <nutrition-page-8
                    :dietaryPatternInfo="dpv"
                    :pageIndex="6 + dpi - pageDiff"
                    :pageTotal="pageTotal"
                    :patientInfo="patientInfo"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <a-row class="mt-20">
        <a-col
          :span="24"
          class="text-center"
        >
          <a-button
            @click="printClick"
            type="primary"
          >打印方案</a-button>
          <a-button
            class="ml-10"
            @click="downloadClick(0)"
            type="primary"
          >下载方案(压缩版)</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getPatientInfo, getPatientBodyInfoByServiceRecord, getPatientNutritionInfo } from '@/api/dms/patient'
import { getPreConsultationInfo } from '@/api/dms/consultation'
import { getServiceRecordInfo } from '@/api/dms/serviceRecord'
import { getPatientFollowPlanInfo } from '@/api/dms/followup'
import { getPatientMonitorRecordInfo } from '@/api/dms/monitorRecord'
import { getDietaryPatternInfo } from '@/api/dms/dietaryPattern'
import printJS from 'print-js'
import moment from 'moment'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { NutritionPage1, NutritionPage2, NutritionPage3, NutritionFooter, NutritionPage4, NutritionPage5, NutritionPage6, NutritionPage7, NutritionPage8 } from './Pages'

export default ({
  name: 'PrintReportDom',
  components: {
    NutritionPage1,
    NutritionPage2,
    NutritionPage3,
    NutritionPage4,
    NutritionPage5,
    NutritionPage6,
    NutritionPage7,
    NutritionPage8,
    NutritionFooter
  },
  props: {
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    parentParams: {
      handler (newVal, oldVal) {
        // 参数重置，父级关闭弹窗可以将传入的参数值为空，监听到变化将参数重置后页面不会显示dom
        this.bodyInfo = []
        this.patientInfo = {}
        this.patientRecordOri = {}
        this.preConsultationOri = {}
        this.nutritionInfo = {}
        this.followupInfo = {}
        this.monitorInfo = {}
        this.dietaryPatternInfo = []
        this.dietaryPatternName = []
        this.requestOkNum = 0
        this.cuDpIdArrLength = 0
        this.pageTotal = 5
        console.log(this.parentParams)
        // 当传入了record主记录id的时候开始发起获取数据的请求
        if (this.parentParams.record !== undefined && this.parentParams.record > 0) {
          this.$emit('spinningChange', true)
          this.loadPatientInfo()
          this.loadMedicalAndNutritionInfo()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    },
    requestOkNum: {
      handler (newVal, oldVal) {
        if (this.requestOkNum === (7 + this.cuDpIdArrLength)) {
          // 所有请求都回来了，弹出打印窗口
          const that = this
          const timer = setTimeout(() => {
            // printJS({
            //   printable: 'printDiv', type: 'html',
            //   targetStyles: ['*'], //处理css样式 *为处理所有，也可以指定})
            // })
            clearTimeout(timer)
            that.$emit('spinningChange', false)
          }, 500)
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    },
    cuIdx: {
      handler (newVal, oldVal) {
        this.$nextTick(function () {
          if (this.cuIdx === 0 || this.pageLen === 0) {
            return false
          }
          if (this.cuIdx > (this.pageLen - 1) || this.pageHtmls[this.cuIdx] === undefined || this.pageHtmls[this.cuIdx] === null || this.pageHtmls[this.cuIdx] === '') {
            this.pageDataOkDownload()
          } else {
            this.dealPageData()
          }
          return true
        })
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      tip: '数据处理中...',
      bodyInfo: [],
      patientInfo: {},
      patientRecordOri: {},
      nutritionInfo: {},
      followupInfo: {},
      monitorInfo: {},
      followupNumArr: [],
      requestOkNum: 0,
      dietaryPatternInfo: [],
      dietaryPatternName: [],
      preConsultationOri: {},
      pageTotal: 5,
      pageDiff: 0,
      cuDpIdArrLength: 0,
      pdfObj: null, // new JsPDF('p', 'pt', 'a4'),
      pageHtmls: null,
      pageLen: 0,
      cuIdx: 0,
      downloadParam: {
        scale: [2, 3],
        imgData: [0.3, 0.8]
      },
      paramIdx: 1
    }
  },
  created () {
  },
  methods: {
    moment,
    loadPatientInfo () {
      // 客户基础信息
      getPatientInfo({ patient_uid: this.parentParams.patient_uid, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.patient_uid === this.parentParams.patient_uid) {
          this.patientInfo = res.data
          console.log('patientInfo：', this.patientInfo)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        }
      })
      // 根据主记录id获取体格信息
      getPatientBodyInfoByServiceRecord({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.record }).then((res2) => {
        this.requestOkNum++
        console.log('BodyInfo：', this.patientInfo)
        if (res2.status !== undefined && res2.status === 1) {
          console.log(res2.data)
          this.bodyInfo = res2.data
        }
      })
      // 这里查询问诊数据      
      getServiceRecordInfo({ patient_id: this.parentParams.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id, id: this.parentParams.record }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.patientRecordOri = res.data
        }
      })
    },
    loadMedicalAndNutritionInfo () {
      // 加载问诊调查数据
      getPreConsultationInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.record }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          res.data.goal = JSON.parse(res.data.goal)
          res.data.disease = JSON.parse(res.data.disease)
          this.preConsultationOri = res.data
        }
      })
      // 加载指标监测计划数据
      getPatientMonitorRecordInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.record }).then((res2) => {
        this.requestOkNum++
        if (res2.status !== undefined && res2.status === 1 && res2.data.id !== undefined && res2.data.id > 0) {
          this.monitorInfo = res2.data
        }
      })
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.parentParams.record }).then((resNut) => {
        this.requestOkNum++
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.nutritionInfo = resNut.data
          this.nutritionInfo.intervention_method = (this.nutritionInfo.intervention_method !== undefined && this.nutritionInfo.intervention_method !== null && this.nutritionInfo.intervention_method !== '') ? this.nutritionInfo.intervention_method.split(',') : []
          if (!((resNut.data.life_id !== null && resNut.data.life_id > 0) || (resNut.data.prescription_sport.length > 0))) {
            this.pageDiff += 1
          }
          if (!(resNut.data.recipe_id !== null && resNut.data.recipe_id > 0 !== null)) {
            this.pageDiff += 1
          }
          console.log('Nutrition：', resNut.data)
          // 获取完了营养方案才能获取随访计划
          this.loadFollowupAndMonitorInfo()
        } else {
          // 没有营养方案的时候后面的随访计划数据没得，直接将 requestOkNum 累加
          this.requestOkNum++
          this.requestOkNum++
          // this.$emit('spinningChange', false)
        }
      })
    },
    loadFollowupAndMonitorInfo () {
      if (this.nutritionInfo.intervention_method.indexOf('1') >= 0 && this.nutritionInfo.dietary_pattern_id !== undefined && this.nutritionInfo.dietary_pattern_id !== null && this.nutritionInfo.dietary_pattern_id !== '') {
        const cuDpId = JSON.parse(this.nutritionInfo.dietary_pattern_id)
        if (cuDpId.length > 0) {
          this.cuDpIdArrLength = cuDpId.length
          cuDpId.map(dpid => {
            // 获取指定饮食模式信息
            getDietaryPatternInfo({ enterprise: this.$store.getters.userInfo.user.target_enterprise, pattern: dpid }).then((res) => {
              this.requestOkNum++
              if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined) {
                // 将第6举例单独拆分一页
                if (res.data.dietary_pattern_item.length > 0) {
                  let tmpDt = JSON.parse(JSON.stringify(res.data))
                  tmpDt.dietary_pattern_item = []
                  let dpInfoArr = JSON.parse(JSON.stringify([tmpDt, tmpDt]))
                  dpInfoArr[0].showTitle = true
                  res.data.dietary_pattern_item.map(itv => {
                    if (itv.section_type <= 5) {
                      dpInfoArr[0].dietary_pattern_item.push(itv)
                    } else {
                      dpInfoArr[1].dietary_pattern_item.push(itv)
                    }
                  })
                  this.dietaryPatternInfo.push(dpInfoArr[0], dpInfoArr[1])
                } else {
                  this.dietaryPatternInfo.push(res.data)
                }
                console.log(this.dietaryPatternInfo)
                this.pageTotal++
                console.log('dp：', res.data)
                this.dietaryPatternName.push(res.data.pattern_name)
              }
            })
          })
        }
      }

      // 加载随访计划数据
      getPatientFollowPlanInfo({ patient: this.parentParams.patient, prescription_no: this.nutritionInfo.prescription_no }).then((res) => {
        this.requestOkNum++
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.followupInfo = res.data
          this.followupNumArr = []
          if (res.data.all_count > 0) {
            for (let num = 1; num <= res.data.all_count; num++) {
              this.followupNumArr.push(num)
            }
          }
        }
      })
    },
    printClick () {
      this.$nextTick(() => {
        printJS({
          printable: 'printDiv',
          type: 'html',
          // targetStyles: ['*'], //处理css样式 *为处理所有，也可以指定})
          scanStyles: true,
          maxWidth: 1240,
          targetStyles: ['*'],
          font_size: ''
        })
      })
    },
    downloadClick (param) {
      this.tip = '努力加载数据中...'
      this.spinning = true
      this.btnLoading = true
      this.paramIdx = param
      this.pdfObj = null
      this.pageHtmls = null
      this.pageLen = 0
      this.cuIdx = 0
      const that = this
      const st = setTimeout(() => {
        that.downloadPdf()
        // 保存下载记录到后台 需要的时候在写
        clearTimeout(st)
      }, 100)
    },
    downloadPdf () {
      this.pdfObj = new JsPDF('p', 'pt', 'a4')
      this.pageHtmls = document.getElementsByClassName('nutritionPage')
      if (this.pageHtmls === undefined || this.pageHtmls.length < 1) {
        this.$message.error('获取报告数据失败！')
        return false
      }
      this.pageLen = this.pageHtmls.length
      this.dealPageData()
    },
    dealPageData () {
      const that = this
      if (this.pageHtmls[this.cuIdx] === undefined || this.pageHtmls[this.cuIdx] === null || this.pageHtmls[this.cuIdx] === '') {
        return false
      }
      // 检测到不是第一页就需要新增页
      if (this.cuIdx > 0) {
        this.pdfObj.addPage()
      }
      // scale:1大概1MB一页（明显模糊点），2大概2.5MB多一页（效果还行），3大概4MB多一页
      // toDataURL：1
      // 原3-1，3-0.5=1MB多，2-0.7=1MB，2-0.5=0.8MB，2-0.3=0.5MB   useCORS 允许html中的图片跨域, useCORS: true
      html2canvas(this.pageHtmls[this.cuIdx], { scale: this.downloadParam.scale[this.paramIdx] }).then(function (canvas) {
        that.tip = '方案处理中(' + (that.cuIdx + 1) + '/' + that.pageLen + ')...'
        console.log(canvas)
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const imgWidth = 595.28
        const imgHeight = (592.28 / contentWidth) * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', that.downloadParam.imgData[that.paramIdx])

        that.pdfObj.setPage(that.cuIdx + 1)
        that.pdfObj.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        that.cuIdx++
      })
    },
    pageDataOkDownload () {
      this.pdfObj.save(this.patientInfo.name + '.' + moment().format('yyyyMMDD') + '.pdf')
      this.spinning = false
    },
  }
})

</script>

<style lang="scss" scoped>
.nutritionPage {
  height: 1670px;
  width: 1175px;
  background-color: white;
  // border: 1px solid red;
  // margin-top: 10px;
  page-break-after: always;
}

.paddingDiv {
  height: 1670px;
  width: 1175px;
  margin-bottom: 10px;
}
</style>