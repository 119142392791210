import request from '@/utils/request'

const apiUrls = {
  listEmallGoodSku: '/EmallGoodsSkus/listEmallGoodSku',
  changeShopGoodsSku: '/EmallGoodsSkus/changeShopGoodsSku',
  addEmallGoodsSku: '/EmallGoodsSkus/addEmallGoodsSku',
  getSkuInfoByEmallGood: '/EmallGoodsSkus/getSkuInfoByEmallGood',
  updateEmallGoodsSku: '/EmallGoodsSkus/updateEmallGoodsSku',
  getEmallGoodsSkuInfo: '/EmallGoodsSkus/getEmallGoodsSkuInfo'
}

export function listEmallGoodSku (parameter) {
  return request({
    url: apiUrls.listEmallGoodSku,
    method: 'get',
    params: parameter
  })
}

export function changeShopGoodsSku (data) {
  return request({
    url: apiUrls.changeShopGoodsSku,
    method: 'post',
    data
  })
}

export function addEmallGoodsSku (data) {
  return request({
    url: apiUrls.addEmallGoodsSku,
    method: 'post',
    data
  })
}

export function getSkuInfoByEmallGood (data) {
  return request({
    url: apiUrls.getSkuInfoByEmallGood,
    method: 'post',
    data
  })
}

export function getEmallGoodsSkuInfo (parameter) {
  return request({
    url: apiUrls.getEmallGoodsSkuInfo,
    method: 'get',
    params: parameter
  })
}

export function updateEmallGoodsSku (data) {
  return request({
    url: apiUrls.updateEmallGoodsSku,
    method: 'post',
    data
  })
}
