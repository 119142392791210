import request from '@/utils/request'

const apiUrls = {
  selectTreeTestDef: '/HisTestItemDefs/selectTreeTestDef',
  getTestItemDefListArr: '/HisTestItemDefs/getTestItemDefListArr'
}

export function selectTreeTestDef (parameter) {
  return request({
    url: apiUrls.selectTreeTestDef,
    method: 'get',
    params: parameter
  })
}

export function getTestItemDefListArr (parameter) {
  return request({
    url: apiUrls.getTestItemDefListArr,
    method: 'get',
    params: parameter
  })
}

