<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformBodyApi"
            :rule="cformBodyRule"
            :option="cformOption"
            @submit="savePatientRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
// import { getTestItemDefListArr } from '@/api/dms/testItem'
import { savePatientBodyInfo } from '@/api/dms/patient'
import { getAndRenderBodyItemFormData } from '@/utils/bodyItemCalc'

export default {
  name: 'AddPatientBodyInfo',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformBodyApi: {},
      //表单生成规则
      cformBodyRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'patient_id'
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 8
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      formulaArr: {}
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.addPatientBodyInfo'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // this.formulaArr = {}
    // getTestItemDefListArr({ test_type: 1, enterprise: 0 }).then((res) => {
    //   if (res.status !== undefined && res.status === 1) {
    //     res.data.map(rv => {
    //       console.log(rv)
    //       const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.id + '@@' + rv.unit_name
    //       let newRule = {
    //         type: 'input',
    //         title: rv.name,
    //         field: fieldName, style: { width: '100%' }
    //       }
    //       if (rv.formula === undefined || rv.formula === null || rv.formula === '' || rv.formula === false) {
    //         // 非计算项直接填写
    //         newRule.props = { type: 'number', placeholder: '请填写' + rv.name, min: 0, step: 0.01, max: 10000, addonAfter: rv.unit_name }
    //         newRule.on = {
    //           blur: () => this.whChange()
    //         }
    //       } else {
    //         // 计算项禁用，自动计算
    //         newRule.props = { placeholder: '请填写相关项目后自动计算，且保留两位小数。', disabled: true, addonAfter: rv.unit_name }
    //         // 记录公式
    //         // this.formulaArr.push({ field: fieldName, formula: rv.formula })
    //         this.formulaArr[fieldName] = rv.formula
    //       }
    //       this.cformBodyApi.prepend(newRule, 'patient_id')
    //     })
    //     this.cformBodyApi.setValue({ patient_id: this.params.patient })
    //   } else {
    //     this.$message.error(res.msg || '暂无体格指标相关配置信息！')
    //     return this.$router.go(-1)
    //   }
    //   this.spinning = false
    // }).catch(() => {
    //   return this.$router.go(-1)
    // })
    // 获取渲染客户体格指标字段
    getAndRenderBodyItemFormData(this)
  },
  methods: {
    submitBtnClick () {
      this.cformBodyApi.submit()
    },
    savePatientRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      savePatientBodyInfo(data).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '客户体格指标保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              formObj.resetFields()
              that.cformBodyApi.setValue({ patient_id: that.params.patient })
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    }
    // whChange () {
    //   let fmData = this.cformBodyApi.formData()
    //   const formDataObj = {}
    //   const fmMax = Object.keys(fmData).length
    //   let fmCuNum = 0
    //   for (let fm in fmData) {
    //     const fmArr = fm.split('@@')
    //     formDataObj[fmArr[0]] = (isNaN(fmData[fm]) || fmData[fm] === null) ? fmData[fm] : parseFloat(fmData[fm]).toFixed(2)
    //     fmCuNum++
    //   }
    //   const that = this
    //   const tminner = setInterval(() => {
    //     if (fmCuNum >= fmMax) {
    //       for (let ffn in that.formulaArr) {
    //         let cuFormula = that.formulaArr[ffn]
    //         console.log(cuFormula)
    //         let isContinue = false // 是否终止当前循环的标识
    //         // 转换出公式中要使用的字段
    //         const formulaFields = cuFormula.replace(/\(|\)|\（|\）|\s/ig, '').replace(/\+|\-|\*|\/|÷|×/ig, '@@').split('@@')
    //         if (typeof formulaFields === 'object' && formulaFields.length !== undefined && formulaFields.length > 0) {
    //           const formulaFieldsArr = Array.from(new Set(formulaFields))
    //           let cuffNum = 0
    //           for (let fidx in formulaFieldsArr) {
    //             if (formDataObj[formulaFieldsArr[fidx]] === undefined) {
    //               console.log(formulaFieldsArr[fidx])
    //               // 公式中的相关字段还没有值的时候直接终止循环
    //               isContinue = true
    //               break
    //             }
    //             cuFormula = cuFormula.replaceAll(formulaFieldsArr[fidx], formDataObj[formulaFieldsArr[fidx]])
    //             cuffNum++
    //           }
    //           // 数据处理完了才去计算
    //           const ffinner = setInterval(() => {
    //             if (cuffNum >= formulaFieldsArr.length || isContinue) {
    //               if (!isContinue) {
    //                 const cuVal = eval(cuFormula).toFixed(2)
    //                 console.log(cuFormula)
    //                 const setValObj = {}
    //                 setValObj[ffn] = cuVal
    //                 that.cformBodyApi.setValue(setValObj)
    //               }
    //               clearInterval(ffinner)
    //             }
    //           }, 100)
    //         }
    //       }
    //       clearInterval(tminner)
    //     }
    //   }, 100)

    //   // if (wh.weight === undefined || wh.height === undefined || wh.weight === null || wh.height === null) {
    //   //   this.cformBodyApi.setValue({ bmi: undefined })
    //   //   return false
    //   // }
    //   // wh.weight = wh.weight <= 0 ? 0 : parseFloat(wh.weight).toFixed(2)
    //   // wh.height = wh.height <= 0 ? 0 : parseFloat(wh.height).toFixed(2)
    //   // if (wh.weight <= 0 || wh.height <= 0) {
    //   //   this.cformBodyApi.setValue({ bmi: 0, weight: wh.weight, height: wh.height })
    //   //   return false
    //   // }
    //   // const cuBmi = wh.weight / Math.pow((wh.height / 100), 2)
    //   // this.cformBodyApi.setValue({ bmi: cuBmi.toFixed(2), weight: wh.weight, height: wh.height })
    // }
  }
}

</script>

<style lang="scss" scoped>
</style>