<!-- 
	This is the Settings page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div id="settings">

    <!-- <a-card class="header-solid"> -->
    <a-row
      type="flex"
      :gutter="[24,24]"
    >

      <a-col
        :span="24"
        :lg="6"
      >

        <!-- Page Anchors -->
        <a-affix :offset-top=" navbarFixed ? 100 : 10 ">
          <a-card
            :bordered="false"
            class="header-solid mb-24"
          >
            <a-anchor
              :targetOffset=" navbarFixed ? 100 : 10 "
              :affix="false"
            >
              <a-anchor-link href="#profile">
                <div
                  slot="title"
                  class="ant-list-item-meta"
                >
                  <a-icon
                    type="appstore"
                    theme="filled"
                    class="text-gray-6 text-lg"
                  />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">账号头像</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#basic-info">
                <div
                  slot="title"
                  class="ant-list-item-meta"
                >
                  <a-icon
                    type="snippets"
                    theme="filled"
                    class="text-gray-6 text-lg"
                  />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">基本信息</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#introdce-info">
                <div
                  slot="title"
                  class="ant-list-item-meta"
                >
                  <a-icon
                    type="safety-certificate"
                    theme="filled"
                    class="text-gray-6 text-lg"
                  />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">个人介绍信息</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#change-password">
                <div
                  slot="title"
                  class="ant-list-item-meta"
                >
                  <a-icon
                    type="unlock"
                    theme="filled"
                    class="text-gray-6 text-lg"
                  />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">密码管理</span>
                  </h4>
                </div>
              </a-anchor-link>
            </a-anchor>
          </a-card>
        </a-affix>
        <!-- / Page Anchors -->

      </a-col>
      <a-col
        :span="24"
        :lg="18"
      >

        <!-- 账号头像 card -->
        <a-spin
          tip="正在上传头像..."
          size="small"
          :spinning="spinningAvatar"
        >
          <a-card
            :bordered="false"
            id="profile"
            class="card-profile-head"
            :bodyStyle="{padding: 0,}"
          >
            <template #title>
              <a-row
                type="flex"
                align="middle"
              >
                <a-col
                  :span="24"
                  :md="12"
                  class="col-info"
                >
                  <a-avatar
                    :size="74"
                    shape="square"
                    :src="$store.getters.avatar !== '' ? $store.getters.avatar : defAvatar"
                  />
                  <div class="avatar-info">
                    <h4 class="font-semibold m-0">{{ $store.getters.userInfo.user.nick_name }}</h4>
                    <p>{{ $store.getters.userInfo.user.enterprise_name }}</p>
                  </div>
                </a-col>
                <a-col
                  :span="24"
                  :md="12"
                  style="display: flex; align-items: center; justify-content: flex-end"
                >
                  <a-upload
                    :action="$store.getters.userInfo.upUrlPre+'dms/Users/changeAvatar'"
                    method="post"
                    accept=".jpg,.png"
                    :showUploadList="false"
                    :headers="{Authorization: 'Bearer '+$store.state.user.token}"
                    :data="{localPath:'img/avatar', id:$store.getters.userInfo.user.id}"
                    :beforeUpload="beforeUpload"
                    @change="upImgChange"
                  >
                    <a-button> <a-icon type="upload" /> 上传头像 </a-button>
                  </a-upload>
                </a-col>
              </a-row>
            </template>
          </a-card>
        </a-spin>
        <!-- 账号头像 card -->

        <!-- 基本信息 card -->
        <a-spin
          tip="正在更新基本信息..."
          size="small"
          :spinning="spinningbaseUserForm"
        >
          <a-form
            :hideRequiredMark="true"
            @submit="handleSubmit"
            name="baseUserForm"
            :form="baseUserForm"
          >
            <a-card
              :bordered="false"
              id="basic-info"
              class="header-solid mb-24"
            >
              <template #title>
                <h5 class="mb-0 font-semibold">基本信息</h5>
              </template>

              <a-button
                htmlType="submit"
                slot="extra"
                type="primary"
                class="px-10 py-5 "
                :disabled="btnDisabledbaseUserForm"
              >{{ btnTextbaseUserForm }}</a-button>

              <a-row :gutter="[24]">
                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="昵称"
                    :colon="false"
                  >
                    <a-input
                      type="input"
                      v-decorator="[
                      'nick_name',
                      { initialValue: this.$store.getters.userInfo.user.nick_name,rules: [{ required: true, message: '请填写昵称', whitespace: true }] }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="性别"
                    :colon="false"
                  >
                    <a-radio-group
                      buttonStyle="solid"
                      v-decorator="[
                'gender',
                { initialValue: this.$store.getters.userInfo.user.gender.toString(), rules: [{ required: true, message: '请选择性别', whitespace: true }] }
              ]"
                    >
                      <a-radio-button value="0">
                        保密
                      </a-radio-button>
                      <a-radio-button value="1">
                        男
                      </a-radio-button>
                      <a-radio-button value="2">
                        女
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>

                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="登录名"
                    :colon="false"
                  >
                    <a-input
                      disabled
                      v-decorator="[
                    'user_name_show',
                    { initialValue: this.$store.getters.userInfo.user.user_name,
                    rules: [{ required: true, message: '登录名', whitespace: true }] }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="所属企业"
                    :colon="false"
                  >
                    <a-input
                      disabled
                      v-decorator="[
                'enterprise_name_show',
                { initialValue: this.$store.getters.userInfo.user.enterprise_name,rules: [{ required: true, message: '所属企业', whitespace: true }] }
              ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="联系手机号"
                    :colon="false"
                  >
                    <a-input v-decorator="[
                    'mobile',
                    { initialValue: this.$store.getters.userInfo.user.mobile,rules: [{ required: true, message: '请输入联系手机号', whitespace: true },{ validator: checkMobile }] }
                    ]" />
                  </a-form-item>
                </a-col>
                <a-col
                  :span="24"
                  :lg="12"
                >
                  <a-form-item
                    class="mb-10"
                    label="邮箱"
                    :colon="false"
                  >
                    <a-input v-decorator="[
                      'email',
                      { initialValue: this.$store.getters.userInfo.user.email, rules: [{validator: checkEmail}]  }]" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-card>
          </a-form>
        </a-spin>
        <!-- / 基本信息 card -->

        <!-- 个人简介信息 card -->
        <a-spin
          tip="处理中..."
          size="small"
          :spinning="spinningProfile"
        >
          <a-card
            :bordered="false"
            id="introdce-info"
            class="header-solid mb-24"
          >
            <template #title>
              <h5 class="mb-0 font-semibold">个人介绍信息</h5>
            </template>

            <a-button
              slot="extra"
              type="primary"
              class="px-10 py-5"
              @click="introdceClick"
            >新增个人介绍</a-button>

            <div v-if="profileInfos.length >= 0">
              <a-collapse
                accordion
                :bordered="false"
              >
                <a-collapse-panel
                  v-for="(pfv,pfi) in profileInfos"
                  :key="pfi"
                  :header="'user_profile.section_type' | showText(pfv.section_type)"
                >
                  <div
                    v-for="(itv,i) in pfv.items"
                    :key="i"
                  >
                    <a-row class="mb-15">
                      <a-col :span="22">
                        <div v-if="itv.content_type.toString() === '2'">
                          <img :src="itv.content_img">
                        </div>
                        <div
                          v-else
                          v-html="itv.content"
                        ></div>
                      </a-col>
                      <a-col :span="2">
                        <a-button
                          icon="form"
                          type="danger"
                          size="small"
                          ghost
                          @click="introdceClick(itv)"
                        >
                          编辑
                        </a-button>
                      </a-col>
                    </a-row>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
            <a-empty
              class="mb-30"
              v-else
              description="暂无数据"
            />
          </a-card>
        </a-spin>
        <!-- / 个人简介信息 card -->

        <!-- 密码管理 card -->
        <a-spin
          tip="正在更新密码..."
          size="small"
          :spinning="spinningpasswordForm"
        >
          <a-form
            :hideRequiredMark="true"
            @submit="handleSubmit"
            name="passwordForm"
            :form="passwordForm"
          >
            <a-card
              :bordered="false"
              id="change-password"
              class="header-solid mb-24"
            >
              <template #title>
                <h5 class="mb-0 font-semibold">密码管理</h5>
              </template>

              <a-button
                htmlType="submit"
                slot="extra"
                type="primary"
                class="px-10 py-5 "
                :disabled="btnDisabledpasswordForm"
              >{{ btnTextpasswordForm }}</a-button>

              <a-row :gutter="[24]">
                <a-col :span="24">
                  <a-form-item
                    class="mb-10"
                    label="原密码"
                    :colon="false"
                  >
                    <a-input-password
                      placeholder="请填写原密码"
                      v-decorator="['old_passwd', { rules: [{ required: true,min:6,max:12, message: '请填写6~12位原登录密码', whitespace: true }] }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    class="mb-10"
                    label="新密码"
                    :colon="false"
                  >
                    <a-input-password
                      placeholder="请填写新密码"
                      v-decorator="['user_passwd', { rules: [{ required: true,min:6,max:12, message: '请填写6~12位新登录密码', whitespace: true }] }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    class="mb-10"
                    label="密码确认"
                    :colon="false"
                  >
                    <a-input-password
                      placeholder="请再次输入密码"
                      v-decorator="['user_passwd2', { rules: [{ required: true, message: '请再次填写登录密码', whitespace: true },{ validator: compareToPassword }] }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-card>
          </a-form>
        </a-spin>
        <!-- / 密码管理 card -->
      </a-col>

    </a-row>
    <!-- </a-card> -->

    <!-- 个人简介抽屉管理列表 -->
    <UserProfileAdd
      :profileAddDrawerVisible="profileAddDrawerVisible"
      :parentParams="profileParams"
      @profileAddDrawerVisibleChange="profileAddDrawerVisibleChange"
    />
  </div>

</template>

<script>
import { updateBaseInfo } from '@/api/manage'
import { listUserProfile } from '@/api/dms/user'
import { checkMobile } from '@/utils/customValidate'
import { UserProfileAdd } from '@/components/UserProfile'

export default {
  props: ['navbarFixed'],
  components: { UserProfileAdd },
  data () {
    return {
      defAvatar: '/images/avatar-0.jpg',
      // defAvatar: process.env.NODE_ENV !== 'production' ? '/images/avatar-0.jpg' : '/webDms/images/avatar-0.jpg',
      spinningAvatar: false,
      baseUserForm: this.$form.createForm(this),
      btnDisabledbaseUserForm: false,
      spinningbaseUserForm: false,
      btnTextbaseUserForm: '确认更新',
      passwordForm: this.$form.createForm(this),
      btnDisabledpasswordForm: false,
      spinningpasswordForm: false,
      btnTextpasswordForm: '确认更新',
      spinningProfile: false,
      profileInfos: [],
      profileAddDrawerVisible: false,
      profileParams: null
    }
  },
  computed: {
    days () {
      let days = []

      for (let i = 1; i <= 31; i++) {
        days.push(i)
      }

      return days
    },
    years () {
      let years = []

      for (let i = 1930; i <= (new Date()).getFullYear(); i++) {
        years.push(i)
      }

      return years
    }
  },
  created () {
    this.baseUserForm.getFieldDecorator('id', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    this.passwordForm.getFieldDecorator('id', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    console.log(this.$store.getters.userInfo)
    this.loadProfileInfo()
  },
  methods: {
    loadProfileInfo () {
      this.spinningProfile = true
      this.profileInfos = []
      listUserProfile({ page: 1, pageSize: 100, order: [['sys_user_profiles.section_sort', 'asc'], ['sys_user_profiles.content_sort', 'asc']] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          res.data.map(pv => {
            if (this.profileInfos[pv.section_type] == undefined) {
              this.profileInfos[pv.section_type] = Object.assign({ items: [] }, pv)
            }
            this.profileInfos[pv.section_type].items.push(pv)
          })
          this.profileInfos = Object.values(this.profileInfos)
        }
        this.spinningProfile = false
      })
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
      this.spinningAvatar = true
    },
    upImgChange (info) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          const userInfo = { ...this.$store.getters.userInfo }
          userInfo.user.header = info.file.response.data.header
          this.$store.commit('SET_INFO', userInfo)
          this.$store.commit('SET_AVATAR', info.file.response.data.header)
          console.log(this.$store)
        }
        this.spinningAvatar = false
      }
    },
    // 基础信息表单提交
    handleSubmit (e) {
      e.preventDefault()
      const formName = e.target.name
      console.log(e)

      this[formName].validateFields((err, values) => {
        if (!err) {
          this['btnDisabled' + formName] = true
          this['spinning' + formName] = true
          this['btnText' + formName] = '保存中...'
          updateBaseInfo(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              console.log(res.data)
              if (formName === 'baseUserForm') {
                const userInfo = { ...this.$store.getters.userInfo }
                userInfo.user.nick_name = res.data.nick_name
                userInfo.user.gender = res.data.gender
                userInfo.user.mobile = res.data.mobile
                userInfo.user.email = res.data.email
                this.$store.commit('SET_INFO', userInfo)
                this.$store.commit('SET_NAME', { name: res.data.nick_name })
              }
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this['btnDisabled' + formName] = false
            this['spinning' + formName] = false
            this['btnText' + formName] = '确认更新'
          })
        }
      })
    },
    compareToPassword (rule, value, callback) {
      let ct
      if (value && value !== this.passwordForm.getFieldValue('user_passwd')) {
        ct = '两次输入密码不一致！'
      }
      callback(ct)
    },
    checkMobile,
    checkEmail (rule, value, callback) {
      const reg1 = /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/
      let ct
      if (value && !reg1.test(value)) {
        ct = '请填写正确的邮箱地址！'
      }
      callback(ct)
    },
    profileAddDrawerVisibleChange (v) {
      this.profileAddDrawerVisible = v
      this.loadProfileInfo()
    },
    introdceClick (itv = false) {
      if (itv !== false) {
        this.profileParams = JSON.parse(JSON.stringify(itv))
      } else {
        this.profileParams = { id: -1 }
      }
      this.profileAddDrawerVisible = true
    }
  },
};
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }

  .card-profile-head {
    margin: 0 0 24px;
  }
  .tags-field .ant-form-item-control {
    line-height: 33px;
  }
  .form-tag.ant-tag {
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
  }
  .form-tag.ant-tag .anticon-close {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0, 0%, 100%, 0.3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: 0.75;
  }
  .form-tag.ant-tag .anticon-close:hover {
    color: #fff;
    opacity: 1;
  }
  .tags-field .ant-input {
    margin-bottom: 5px;
    margin-top: 4px;
  }

  .tags-field .ant-select {
    .ant-select-selection__choice__remove i {
      color: #fff;
      height: 16px;
      border-left: 1px solid hsla(0, 0%, 100%, 0.3);
      padding-left: 5px;
      padding-top: 2px;
      opacity: 0.75;

      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
    .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
      border-radius: 20px;
      padding: 2px 27px 2px 10px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 3.75px;
      margin-bottom: 3.75px;
      background-color: #3a416f;
      border: 1px solid #3a416f;
      color: #fff;
      line-height: 2;
      height: 30px;
    }
    .ant-select-selection--multiple {
      padding: 8px 10px;
    }
  }
}
</style>