import request from '@/utils/request'

const api = {
  changeAvatar: '/Users/changeAvatar',
  updateBaseInfo: '/Users/updateBaseInfo',
  rmFile: '/auth/UploadFiles/delete',
  getFileInfo: '/auth/UploadFiles/getInfo',
  getDownLoadUrlByFilename: '/auth/UploadFiles/getDownLoadUrlByFilename',
  getShowUrlByFilename: '/auth/UploadFiles/getShowUrlByFilename',
  getAllTypeSelectList: '/auth/GoodsTypes/getAllTypeSelectList',
  getSelectListTag:'/TagDefs/getSelectListTag',
  getCascaderList:'/auth/Cities/getCascaderList',
  readFoodData:'/Tts/readFoodData'
}

export default api

export function changeAvatar (data) {
  return request({
    url: api.changeAvatar,
    method: 'post',
    data: data
  })
}

export function updateBaseInfo (data) {
  return request({
    url: api.updateBaseInfo,
    method: 'post',
    data: data
  })
}

export function rmFile (data) {
  return request({
    url: api.rmFile,
    method: 'post',
    data: data
  })
}

export function getFileInfo (parameter) {
  return request({
    url: api.getFileInfo,
    method: 'get',
    params: parameter
  })
}

export function getDownLoadUrlByFilename (parameter) {
  return request({
    url: api.getDownLoadUrlByFilename,
    method: 'get',
    params: parameter
  })
}

export function getShowUrlByFilename (parameter) {
  return request({
    url: api.getShowUrlByFilename,
    method: 'get',
    params: parameter
  })
}

export function getAllTypeSelectList (parameter) {
  return request({
    url: api.getAllTypeSelectList,
    method: 'get',
    params: parameter
  })
}

export function getSelectListTag (parameter) {
  return request({
    url: api.getSelectListTag,
    method: 'get',
    params: parameter
  })
}

export function getCascaderList (parameter) {
  return request({
    url: api.getCascaderList,
    method: 'get',
    params: parameter
  })
}

export function readFoodData (parameter) {
  return request({
    url: api.readFoodData,
    method: 'get',
    params: parameter
  })
}
