<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="6">
                <a-col
                  :md="4"
                  :sm="24"
                >
                  <a-form-item label="操作用户">
                    <a-input
                      v-model="queryParam.user_search"
                      placeholder="操作用户搜索"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :md="4"
                  :sm="24"
                >
                  <a-form-item label="操作模块">
                    <a-input
                      v-model="queryParam.model_search"
                      placeholder="操作模块搜索"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :md="7"
                  :sm="24"
                >
                  <a-form-item label="操作时间">
                    <a-range-picker
                      inputReadOnly
                      :allowClear="false"
                      style="width:100%;"
                      :ranges="{ '今天': [moment(), moment()], '最近一周': [moment().add(-1,'weeks'), moment()], '最近一月': [moment().add(-1,'months'), moment()], '最近三月': [moment().add(-3, 'months'), moment()], '本年': [moment().startOf('year'), moment()] }"
                      valueFormat="YYYY-MM-DD"
                      v-model="queryParam.date"
                    >
                      <a-icon
                        slot="suffixIcon"
                        type="calendar"
                      />
                    </a-range-picker>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="5"
                  :sm="24"
                >
                  <a-form-item label="操作信息">
                    <a-input
                      v-model="queryParam.info_search"
                      placeholder="操作信息搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.logsListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = { date:[moment().add(-1, 'months'), moment()] })"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="logsListTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
        </s-table>
      </a-card>
    </a-spin>

  </div>
</template>

<script>
import { listLogs } from '@/api/dms/logs'
import moment from 'moment'

export default {
  name: 'LogsList',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 90,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '操作用户',
          width: 120,
          dataIndex: 'nick_name',
          ellipsis: true
        },
        {
          title: '操作模块',
          width: 180,
          dataIndex: 'option_model',
          ellipsis: true
        },
        {
          title: '操作时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'create_date'
        },
        {
          title: '操作信息',
          ellipsis: true,
          dataIndex: 'option_info'
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: { date: [moment().add(-1, 'months'), moment()] },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ plattype: this.$store.getters.userInfo.platform.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return listLogs(requestParameters)
          .then(res => {
            return res
          })
      }
    }
  },
  created () {

  },
  methods: {
    moment
  }
}
</script>
