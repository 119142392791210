<template>
  <div>

    <a-spin
      tip="处理中..."
      :spinning="spinningEdit"
    >
      <a-card :bordered="false">

        <a-form
          class="editRecipeForm"
          :form="editRecipeForm"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="8">
              <a-form-item
                label="菜谱名称"
                :labelCol="{ md: 4 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  size="small"
                  v-decorator="[
                    'recipe_name', { rules: [{ required: true, message: '请输入菜谱名称' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                label="显示名称"
                :labelCol="{ md: 4 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  size="small"
                  v-decorator="[
                    'recipe_name_show', { rules: [{ required: true, message: '请输入显示名称' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="所属"
                :labelCol="{ md: 8 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  size="small"
                  v-decorator="['owner_type',]"
                >
                  <a-select-option value="0">
                    公开
                  </a-select-option> <a-select-option value="10">
                    私有
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="适合"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input-number
                  size="small"
                  :min="1"
                  :max="10"
                  v-decorator="[
                    'person',
                  ]"
                />
                <span class="ant-form-text">
                  人食用
                </span>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="4">
              <a-form-item
                label="分量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input-group compact>
                  <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                    <a-input
                      size="small"
                      v-decorator="[
                    'eating_count',{ rules: [{ required: true, message: '请输入分量' }] }
                  ]"
                    />
                  </a-form-item>
                  <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft: '24px' }">
                    <a-input
                      size="small"
                      v-decorator="[
                    'eating_unit',{ rules: [{ required: true, message: '请输入单位' }] }
                  ]"
                    />
                  </a-form-item>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="20">
              <a-form-item
                label="适用场景"
                :labelCol="{ md: 2 }"
                :wrapperCol="{ md: 22 }"
              >
                <a-select
                  show-search
                  size="small"
                  :filter-option="tagFilterOption"
                  mode="tags"
                  placeholder="请选择适用场景"
                  :options="tagListData"
                  v-decorator="[
                    'tags',
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="24">
              <a-form-item
                label="描述"
                :labelCol="{ md: 1 }"
                :wrapperCol="{ md: 23 }"
              >
                <a-input
                  size="small"
                  v-decorator="['desc']"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="4">
              <a-form-item
                label="重量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="克"
                  v-decorator="[
                    'weight',{ rules: [{ required: true, message: '请输入重量' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="热量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="千卡"
                  v-decorator="[
                    'calory',{ rules: [{ required: true, message: '请输入热量' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="能量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="千焦"
                  v-decorator="[
                    'joule',{ rules: [{ required: true, message: '请输入能量' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="蛋白质"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="克"
                  v-decorator="[
                    'protein',{ rules: [{ required: true, message: '请输入所含蛋白质含量' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="脂肪"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="克"
                  v-decorator="[
                    'fat',{ rules: [{ required: true, message: '请输入脂肪含量' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item
                label="碳水"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 18 }"
              >
                <a-input
                  size="small"
                  addon-after="克"
                  v-decorator="[
                    'carbohydrate',{ rules: [{ required: true, message: '请输入碳水含量' }] }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="2">
              <a-form-item
                label="图示"
                :labelCol="{ md: 13 }"
                :wrapperCol="{ md: 11 }"
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="f=>{ return beforeUpload(f,'cover') }"
                  :file-list="fileList"
                  :remove="f=>{rmUpImgFile(f,'cover')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'cover')}"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>
            <a-col :span="22">
              <a-form-item
                label="其他营养素"
                :labelCol="{ md: 2 }"
                :wrapperCol="{ md: 22 }"
              >
                <a-row
                  class="form-row"
                  :gutter="16"
                >
                  <template v-for="(nInfo,idx) in nutrientInfoForm">
                    <a-col
                      :span="4"
                      :key="idx"
                      v-if="nInfo.type === 2"
                      :style="{ display : nInfo.display }"
                    >
                      <a-form-item>
                        <a-input
                          size="small"
                          :addon-before="nInfo.text"
                          :addon-after="nInfo.unit"
                          v-decorator="[ nInfo.column ]"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </template>
                  <a-col span="1">
                    <a-button
                      size="small"
                      icon="double-left"
                      v-if="flagShowAll"
                      @click="showAllItem"
                    ></a-button>
                    <a-button
                      size="small"
                      icon="double-right"
                      v-else
                      @click="showAllItem"
                    ></a-button>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col :span="24">
              <a-tabs
                type="card"
                size="small"
                v-model="currentMaterialType"
              >
                <a-tab-pane
                  :key="0"
                  tab="主料"
                >
                  <a-table
                    :columns="materialColumns"
                    :pagination="false"
                    :data-source="materialData[0]"
                    rowKey="id"
                  >
                    <span
                      slot="weight"
                      slot-scope="text,record"
                    >
                      <a-input
                        :value="text"
                        size="small"
                        addonAfter="克"
                        @change="(e) => record.weight = !isNaN(e.target.value) ? Number(e.target.value) : 0"
                      />
                    </span>

                    <span
                      slot="action"
                      slot-scope="text, record, index"
                    >
                      <a @click="clickViewCaloryDetail(record)">详情</a>
                      <a-divider type="vertical" />
                      <a @click="clickDeleteMaterial(index)">删除</a>
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane
                  :key="1"
                  tab="辅料"
                >
                  <a-table
                    :columns="materialColumns"
                    :pagination="false"
                    :data-source="materialData[1]"
                    rowKey="id"
                  >
                    <span
                      slot="weight"
                      slot-scope="text,record"
                    >
                      <a-input
                        :value="text"
                        size="small"
                        addonAfter="克"
                        @change="(e) => record.weight = !isNaN(e.target.value) ? Number(e.target.value) : 0"
                      />
                    </span>

                    <span
                      slot="action"
                      slot-scope="text, record, index"
                    >
                      <a @click="clickViewCaloryDetail(record)">详情</a>
                      <a-divider type="vertical" />
                      <a @click="clickDeleteMaterial(index)">删除</a>
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane
                  :key="2"
                  tab="配料"
                >
                  <a-table
                    :columns="materialColumns"
                    :pagination="false"
                    :data-source="materialData[2]"
                    rowKey="id"
                  >
                    <span
                      slot="weight"
                      slot-scope="text,record"
                    >
                      <a-input
                        :value="text"
                        size="small"
                        addonAfter="克"
                        @change="(e) => record.weight = !isNaN(e.target.value) ? Number(e.target.value) : 0"
                      />
                    </span>

                    <span
                      slot="action"
                      slot-scope="text, record, index"
                    >
                      <a @click="clickViewCaloryDetail(record)">详情</a>
                      <a-divider type="vertical" />
                      <a @click="clickDeleteMaterial(index)">删除</a>
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-button
                  slot="tabBarExtraContent"
                  size="small"
                  @click="clickAddMaterial"
                >
                  添加{{ tabTextArr[currentMaterialType] }}
                </a-button>
              </a-tabs>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :offset="11"
              :span="12"
            >
              <a-form-item>
                <a-button
                  @click="submitBtnClick"
                  type="primary"
                >确认保存</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

      </a-card>
    </a-spin>

    <a-modal
      v-model="modalVisibleCalory"
      title="食材列表"
      :width="1600"
      :dialog-style="{ top: '20px' }"
      :maskClosable="false"
      :forceRender="true"
      :footer="null"
    >
      <div class="layout-dashboard">
        <food-calory-list
          ref="foodCaloryList"
          :parentContainer="'modalAddRecipe'"
          @closeModalCalory="closeModalCalory"
          @chooseCalory="chooseCalory"
        >
        </food-calory-list>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rmFile, getSelectListTag } from '@/api/manage'
import { FoodCaloryList } from '@/components/FoodCalory'
import { getFoodRecipeDefDetail, updateFoodRecipeDef } from '@/api/dms/foodRecipe'

export default {
  name: 'EditFoodRecipe',
  components: {
    FoodCaloryList,
  },
  data () {
    return {
      spinningEdit: false,
      editRecipeForm: this.$form.createForm(this),
      fileList: [],
      previewVisible: false,
      previewImage: '',
      tagListData: [],
      currentMaterialType: 0,
      tabTextArr: ['主料', '辅料', '配料'],
      materialColumns: [
        {
          title: '名称',
          width: 240,
          dataIndex: 'food_name',
          ellipsis: true
        },
        {
          title: '热量',
          width: 85,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '能量',
          width: 85,
          ellipsis: true,
          dataIndex: 'joule',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.joule_unit : ''
        },
        {
          title: '蛋白质',
          width: 85,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 85,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 85,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '重量',
          ellipsis: true,
          width: 120,
          dataIndex: 'weight',
          scopedSlots: { customRender: 'weight' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 240,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      materialData: [[], [], []],
      modalVisibleCalory: false,
      nutrientInfo: [
        {
          type: 1,
          text: '重量',
          column: 'weight',
          count: 0,
          unit: '克',
          display: ''
        },
        {
          type: 1,
          text: '热量',
          column: 'calory',
          count: 0,
          unit: '千卡',
          display: ''
        },
        {
          type: 1,
          text: '热量',
          column: 'joule',
          count: 0,
          unit: '千焦',
          display: ''
        },
        {
          type: 1,
          text: '蛋白质',
          column: 'protein',
          count: 0,
          unit: '克',
          display: ''
        },
        {
          type: 1,
          text: '脂肪',
          column: 'fat',
          count: 0,
          unit: '克',
          display: ''
        },
        {
          type: 1,
          text: '碳水化合物',
          column: 'carbohydrate',
          count: 0,
          unit: '克',
          display: ''
        },
        {
          type: 2,
          text: '膳食纤维',
          column: 'fiber_dietary',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '糖',
          column: 'sugar',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '饱和脂肪',
          column: 'saturated_fat',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '反式脂肪',
          column: 'fatty_acid',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '单不饱和脂肪',
          column: 'mufa',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '多不饱和脂肪',
          column: 'pufa',
          count: 0,
          unit: '克',
          display: 'none'
        },
        {
          type: 2,
          text: '胆固醇',
          column: 'cholesterol',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '酒精度',
          column: 'alcohol',
          count: 0,
          unit: '%vol',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素A',
          column: 'vitamin_a',
          count: 0,
          unit: '微克RAE',
          display: 'none'
        },
        {
          type: 2,
          text: '胡萝卜素',
          column: 'carotene',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素B1',
          column: 'thiamine',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素B2',
          column: 'lactoflavin',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素B6',
          column: 'vitamin_b6',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素B12',
          column: 'vitamin_b12',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素C',
          column: 'vitamin_c',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素D',
          column: 'vitamin_d',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素E',
          column: 'vitamin_e',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '维生素K',
          column: 'vitamin_k',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '烟酸',
          column: 'niacin',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '叶酸',
          column: 'folacin',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '泛酸',
          column: 'pantothenic',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '生物素',
          column: 'biotin',
          count: 0,
          unit: '微克',
          display: 'none'
        },
        {
          type: 2,
          text: '胆碱',
          column: 'choline',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '钠',
          column: 'natrium',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '磷',
          column: 'phosphor',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '钾',
          column: 'kalium',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '镁',
          column: 'magnesium',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '钙',
          column: 'calcium',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '铁',
          column: 'iron',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '锌',
          column: 'zinc',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '碘',
          column: 'iodine',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '硒',
          column: 'selenium',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '铜',
          column: 'copper',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '氟',
          column: 'fluorine',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
        {
          type: 2,
          text: '锰',
          column: 'manganese',
          count: 0,
          unit: '毫克',
          display: 'none'
        },
      ],
      nutrientInfoForm: [],
      flagShowAll: false,
      calculateOn: false
    }
  },
  watch: {
    materialData: {
      handler (newVal, oldVal) {
        // 食材变动以后，计算营养素
        this.calculateNutrient(this.materialData)
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  props: {
    recipe: {
      type: String,
      default: ''
    },
  },
  filters: {

  },
  created () {
    console.log(this.recipe)
    this.editRecipeForm.getFieldDecorator('id', { initialValue: this.recipe, preserve: true, rules: [{ required: true }] })
    this.editRecipeForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.editRecipeForm.getFieldDecorator('create_user_id', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    this.editRecipeForm.getFieldDecorator('domain_platform_id', { initialValue: this.$store.getters.userInfo.platform.id, preserve: true, rules: [{ required: true }] })
    this.editRecipeForm.getFieldDecorator('uid', { preserve: true })
    this.editRecipeForm.getFieldDecorator('cover', { preserve: true })
    this.editRecipeForm.getFieldDecorator('major_materials', { preserve: true })
    this.editRecipeForm.getFieldDecorator('minor_materials', { preserve: true })
    this.editRecipeForm.getFieldDecorator('seasoning', { preserve: true })

    // 规格下拉
    this.spinningEdit = true
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.tagListData = res.data
      }
    })

    // 深拷贝，不影响原始定义
    this.nutrientInfoForm = JSON.parse(JSON.stringify(this.nutrientInfo))

    this.spinningEdit = true
    let fieldArr = [
      'health_food_recipe_defs.id', 'health_food_recipe_defs.uid', 'health_food_recipe_defs.owner_type', 'health_food_recipe_defs.recipe_name',
      'health_food_recipe_defs.recipe_name_show', 'health_food_recipe_defs.eating_count', 'health_food_recipe_defs.eating_unit',
      'health_food_recipe_defs.person', 'health_food_recipe_defs.cover', 'health_food_recipe_defs.desc', 'health_food_recipe_defs.major_materials',
      'health_food_recipe_defs.minor_materials', 'health_food_recipe_defs.seasoning'
    ]

    this.nutrientInfo.forEach((item) => {
      fieldArr.push('health_food_recipe_defs.' + item.column)
    })

    getFoodRecipeDefDetail({ recipe: this.recipe, enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, join: ['tags'], fields: fieldArr, })
      .then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.uid === this.recipe) {
          console.log(res.data)
          res.data.owner_type = res.data.owner_type.toString()
          // 处理标签
          res.data.tags = []
          if (res.data.recipe_tag !== undefined && res.data.recipe_tag.length > 0) {
            res.data.recipe_tag.forEach((tag) => {
              res.data.tags.push(tag.tag_id.toString())
            })
            delete res.data.recipe_tag
          }

          // 处理封面图
          if (res.data.cover_show !== undefined) {
            this.fileList = [
              {
                uid: '-1',
                name: '头像.png',
                status: 'done',
                url: res.data.cover_show
              }
            ]
            delete res.data.cover_show
          }

          let mData = []
          // 主料
          if (res.data.major_materials !== undefined && res.data.major_materials !== "") {
            mData = JSON.parse(res.data.major_materials)
            mData.forEach((m) => {
              this.materialData[0].push(m)
            })
          }
          // 辅料
          if (res.data.minor_materials !== undefined && res.data.minor_materials !== "") {
            mData = JSON.parse(res.data.minor_materials)
            mData.forEach((m) => {
              this.materialData[1].push(m)
            })
          }
          // 配料
          if (res.data.seasoning !== undefined && res.data.seasoning !== "") {
            mData = JSON.parse(res.data.seasoning)
            mData.forEach((m) => {
              this.materialData[2].push(m)
            })
          }

          // 其他营养素显示处理
          this.nutrientInfoForm.forEach((fmData) => {
            fmData.count = res.data[fmData.column]
            if (!isNaN(fmData.count) && Number(fmData.count) > 0) {
              fmData.display = ""
            }
          })

          this.editRecipeForm.setFieldsValue(res.data)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
        this.spinningEdit = false
      }).catch((e) => {
        console.log(e)
        return this.$router.go(-1)
      })
  },
  methods: {
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file, field) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传图片不超过2MB！')
        return false
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'cover') {
            this.editRecipeForm.setFieldsValue({ 'cover': upRes.data })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'cover') {
          this.fileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      let fname = (file.response !== undefined && file.response.data !== undefined) ? file.response.data : this.editRecipeForm.getFieldValue(field)
      fname = typeof fname === 'object' ? fname[0] : fname
      this.spinningEdit = true
      rmFile({ fileName: fname }).then((res) => {
        this.spinningEdit = false
        if (res.status !== undefined && res.status === 1) {
          if (field === 'cover') {
            this.editRecipeForm.setFieldsValue({ 'cover': '' })
          }
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinningEdit = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    tagFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].text !== undefined) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
    clickAddMaterial () {
      this.modalVisibleCalory = true
    },
    submitBtnClick () {
      const that = this
      this.editRecipeForm.validateFields((err, values) => {
        if (!err) {
          this.spinningEdit = true
          updateFoodRecipeDef(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '新增菜谱保存成功！',
                content: false,
                cancelText: '返回列表',
                okText: '继续编辑',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  console.log(res)
                },
                onCancel () {
                  return that.$router.go(-1)
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinningEdit = false
          })
        }
      })
    },
    closeModalCalory () {
      this.modalVisibleCalory = false
    },
    chooseCalory (calory) {
      // 选定原料后，判断是否有重复，没有则加入
      console.log(calory)
      let dupMaterial = false
      this.materialData.forEach((element) => {
        element.forEach((el) => {
          if (el.id === calory.id) {
            dupMaterial = true
          }
        })
      })
      if (dupMaterial) {
        this.$message.warn('已有相同食材！')
      } else {
        this.materialData[this.currentMaterialType].push(calory)
      }
      console.log(this.materialData)
      console.log(this.calculateOn)
    },
    clickViewCaloryDetail (record) {
      this.$refs.foodCaloryList.clickView(record)
    },
    clickDeleteMaterial (index) {
      console.log(this.materialData)
      this.materialData[this.currentMaterialType].splice(index, 1)
    },
    calculateNutrient (material) {
      // 深拷贝，不影响原始定义
      this.nutrientInfoForm = JSON.parse(JSON.stringify(this.nutrientInfo))
      material.forEach((element, index) => {
        // 主料、辅料、配料轮询
        element.forEach((el) => {
          // 每种食材数据各项轮询
          Object.keys(el).forEach(key => {
            // 每一项跟form里面的项目匹配，有数字且匹配上了，就加数
            if (!isNaN(el[key])) {
              this.nutrientInfoForm.forEach((fmData) => {
                if (fmData.column === key) {
                  // 重量直接加，其他要换算
                  fmData.count += key === 'weight' ? el[key] : Math.round(el[key] * el.weight) / 100
                }
              })
            }
          })
        })
        if (index === 0) {
          this.editRecipeForm.setFieldsValue({
            major_materials: JSON.stringify(element)
          })
        }
        if (index === 1) {
          this.editRecipeForm.setFieldsValue({
            minor_materials: JSON.stringify(element)
          })
        }
        if (index === 2) {
          this.editRecipeForm.setFieldsValue({
            seasoning: JSON.stringify(element)
          })
        }
      })
      // form 表单赋值
      this.nutrientInfoForm.forEach((fm) => {
        fm.display = fm.type === 2 && fm.count > 0 ? '' : 'none'
        this.editRecipeForm.setFieldsValue({
          [fm.column]: Math.round(fm.count * 100) / 100
        })
      })
    },
    showAllItem () {
      this.flagShowAll = !this.flagShowAll
      this.nutrientInfoForm.forEach((fm) => fm.display = this.flagShowAll || (fm.type === 2 && fm.count > 0) ? '' : 'none')
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.editRecipeForm {
  /deep/ .ant-form-item {
    margin-bottom: 10px;
  }
  /deep/ .ant-form-item:last-child {
    margin-bottom: 0;
  }
}
</style>
