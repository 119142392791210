import request from '@/utils/request'

const apiUrls = {
  listEnterprise: '/Enterprises/listEnterprise',
  saveEnterprise: '/Enterprises/saveEnterprise',
  editEnterprise: '/Enterprises/editEnterprise',
  changeEnterpriseStatus: '/Enterprises/changeEnterpriseStatus',
  selectListRelationEnterprise: '/Enterprises/selectListRelationEnterprise'
}

export function listEnterprise (parameter) {
  return request({
    url: apiUrls.listEnterprise,
    method: 'get',
    params: parameter
  })
}

export function saveEnterprise (data) {
  return request({
    url: apiUrls.saveEnterprise,
    method: 'post',
    data
  })
}

export function editEnterprise (data) {
  return request({
    url: apiUrls.editEnterprise,
    method: 'post',
    data
  })
}

export function changeEnterpriseStatus (data) {
  return request({
    url: apiUrls.changeEnterpriseStatus,
    method: 'post',
    data
  })
}

export function selectListRelationEnterprise (parameter) {
  return request({
    url: apiUrls.selectListRelationEnterprise,
    method: 'get',
    params: parameter
  })
}
