<template>
  <a-affix :offset-top="top">
    <!-- 身体状况 Card -->
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
    >
      <template #title>
        <h6 class="font-semibold m-0">身体状况</h6>
      </template>
      <template slot="extra">
        <a-button
          type="primary"
          size="small"
          :disabled="loadingMeasureInfo"
          @click="refreshMeasureInfo()"
        >
          刷新
        </a-button>

      </template>

      <a-spin
        :tip="tip"
        size="large"
        :spinning="loadingMeasureInfo"
      >
        <a-list
          class="invoice-list"
          item-layout="horizontal"
          size="small"
          :split="false"
          :data-source="bodyInfo"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item"
          >
            <div slot="actions">
              <a-button
                type="link"
                size="small"
                @click="modifyMeasureInfo(item)"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="fill-muted"
                    d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                    fill="#111827"
                  />
                  <path
                    class="fill-muted"
                    d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                    fill="#111827"
                  />
                </svg>
                <span class="text-gray-7">更新</span>

              </a-button>

              <a-button
                type="link"
                size="small"
                class="p-0"
                :title="item.is_monitor === 0 ? '关注此检测项' : '取消关注'"
                @click="modifyMonitorItem(item)"
              >
                <svg
                  t="1709545223548"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3265"
                  width="16"
                  height="16"
                  fill="none"
                  v-if="item.is_monitor === 0"
                >
                  <path
                    class="fill-muted"
                    d="M910.933333 458.666667c-61.866667 66.133333-164.266667 174.933333-164.266667 174.933333s19.2 155.733333 29.866667 243.2c4.266667 34.133333-49.066667 34.133333-49.066667 34.133333L725.333333 908.8c-81.066667-38.4-217.6-102.4-217.6-102.4s-140.8 66.133333-221.866667 104.533333c-32 14.933333-51.2-2.133333-46.933333-38.4 10.666667-89.6 29.866667-238.933333 29.866667-238.933333s-87.466667-93.866667-149.333333-160C85.333333 437.333333 87.466667 405.333333 123.733333 396.8c87.466667-17.066667 236.8-44.8 236.8-44.8S441.6 202.666667 484.266667 128c14.933333-25.6 34.133333-27.733333 46.933333-2.133333 40.533333 74.666667 123.733333 226.133333 123.733333 226.133333s162.133333 32 247.466667 46.933333C934.4 405.333333 936.533333 428.8 910.933333 458.666667zM631.466667 401.066667 507.733333 177.066667l-119.466667 219.733333-243.2 42.666667 172.8 181.333333-34.133333 245.333333 224-108.8 224 102.4L695.466667 618.666667l177.066667-179.2L631.466667 401.066667z"
                    p-id="1344"
                  ></path>
                </svg>
                <svg
                  t="1709711595625"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1497"
                  width="16"
                  height="16"
                  v-else
                >
                  <path
                    d="M925.866667 458.666667c-61.866667 66.133333-164.266667 174.933333-164.266667 174.933333s19.2 155.733333 29.866667 243.2c4.266667 34.133333-49.066667 34.133333-49.066667 34.133333l-2.133333-2.133333c-81.066667-38.4-217.6-102.4-217.6-102.4s-140.8 66.133333-221.866667 104.533333c-32 14.933333-51.2-2.133333-46.933333-38.4 10.666667-89.6 29.866667-238.933333 29.866667-238.933333s-87.466667-93.866667-149.333333-160c-34.133333-36.266667-32-68.266667 4.266667-74.666667 87.466667-17.066667 236.8-44.8 236.8-44.8s81.066667-147.2 123.733333-224C512 102.4 531.2 102.4 546.133333 128c40.533333 74.666667 123.733333 226.133333 123.733333 226.133333S832 384 919.466667 401.066667C949.333333 407.466667 951.466667 430.933333 925.866667 458.666667z"
                    p-id="1498"
                    fill="#d4237a"
                  ></path>
                </svg>

              </a-button>
            </div>

            <a-list-item-meta
              :title="item.item_name + ' （ '+ item.unit_name +' ）'"
              :description="item.result_test_time !== undefined ? item.result_test_time.substr(0,10) : ''"
            ></a-list-item-meta>
            <div class="amount">{{ item.result_val }}</div>
          </a-list-item>
        </a-list>

        <hr>

        <a-list
          class="invoice-list"
          item-layout="horizontal"
          size="small"
          :split="false"
          :data-source="measureInfo"
          v-if="measureInfo.length > 0"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item"
          >
            <div slot="actions">
              <a-button
                type="link"
                size="small"
                @click="modifyMeasureInfo(item)"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="fill-muted"
                    d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                    fill="#111827"
                  />
                  <path
                    class="fill-muted"
                    d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                    fill="#111827"
                  />
                </svg>
                <span class="text-gray-7">更新</span>
              </a-button>
              <a-button
                type="link"
                size="small"
                class="p-0"
                @click="modifyMonitorItem(item)"
              >
                <svg
                  t="1709545223548"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3265"
                  width="16"
                  height="16"
                  fill="none"
                  v-if="item.is_monitor === 0"
                >
                  <path
                    class="fill-muted"
                    d="M910.933333 458.666667c-61.866667 66.133333-164.266667 174.933333-164.266667 174.933333s19.2 155.733333 29.866667 243.2c4.266667 34.133333-49.066667 34.133333-49.066667 34.133333L725.333333 908.8c-81.066667-38.4-217.6-102.4-217.6-102.4s-140.8 66.133333-221.866667 104.533333c-32 14.933333-51.2-2.133333-46.933333-38.4 10.666667-89.6 29.866667-238.933333 29.866667-238.933333s-87.466667-93.866667-149.333333-160C85.333333 437.333333 87.466667 405.333333 123.733333 396.8c87.466667-17.066667 236.8-44.8 236.8-44.8S441.6 202.666667 484.266667 128c14.933333-25.6 34.133333-27.733333 46.933333-2.133333 40.533333 74.666667 123.733333 226.133333 123.733333 226.133333s162.133333 32 247.466667 46.933333C934.4 405.333333 936.533333 428.8 910.933333 458.666667zM631.466667 401.066667 507.733333 177.066667l-119.466667 219.733333-243.2 42.666667 172.8 181.333333-34.133333 245.333333 224-108.8 224 102.4L695.466667 618.666667l177.066667-179.2L631.466667 401.066667z"
                    p-id="1344"
                  ></path>
                </svg>
                <svg
                  t="1709711595625"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1497"
                  width="16"
                  height="16"
                  v-else
                >
                  <path
                    d="M925.866667 458.666667c-61.866667 66.133333-164.266667 174.933333-164.266667 174.933333s19.2 155.733333 29.866667 243.2c4.266667 34.133333-49.066667 34.133333-49.066667 34.133333l-2.133333-2.133333c-81.066667-38.4-217.6-102.4-217.6-102.4s-140.8 66.133333-221.866667 104.533333c-32 14.933333-51.2-2.133333-46.933333-38.4 10.666667-89.6 29.866667-238.933333 29.866667-238.933333s-87.466667-93.866667-149.333333-160c-34.133333-36.266667-32-68.266667 4.266667-74.666667 87.466667-17.066667 236.8-44.8 236.8-44.8s81.066667-147.2 123.733333-224C512 102.4 531.2 102.4 546.133333 128c40.533333 74.666667 123.733333 226.133333 123.733333 226.133333S832 384 919.466667 401.066667C949.333333 407.466667 951.466667 430.933333 925.866667 458.666667z"
                    p-id="1498"
                    fill="#d4237a"
                  ></path>
                </svg>

              </a-button>
            </div>
            <a-list-item-meta
              :title="item.item_name + ' （ '+ item.unit_name +' ）'"
              :description="item.result_test_time !== undefined ? item.result_test_time.substr(0,10) : ''"
            ></a-list-item-meta>
            <div class="amount">
              <span
                v-if="item.result_type > 3"
                class="text-danger mr-5"
              >&uarr;</span>
              <span
                v-if="item.result_type < 3"
                class="text-danger mr-5"
              >&darr;</span>
              {{ item.result_val }}
            </div>
          </a-list-item>
        </a-list>

      </a-spin>

      <a-modal
        v-model="modalVisible1"
        :title="'修改检测结果 ： ' + itemNameTitle "
        okText="确认保存"
        :maskClosable="false"
        :okButtonProps="okButtonProps1"
        @ok="handleModalOk1"
      >
        <a-spin
          tip="处理中..."
          :spinning="spinningM1"
        >
          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
          >
          </form-create>
        </a-spin>
      </a-modal>

    </a-card>
    <!-- / 身体状况 Card -->
  </a-affix>
</template>

<script>
import { getPatientAllMeasureInfo, savePatientMeasureInfo, changeMeasureMonitorStatus } from '@/api/dms/patient'

import moment from 'moment'

export default ({
  name: 'CardMeasureInfoAll',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tip: '刷新中...',
      top: 10,
      loadingMeasureInfo: false,
      bodyInfo: [],
      measureInfo: [],
      modalVisible1: false,
      okButtonProps1: {},
      spinningM1: false,
      itemNameTitle: '',

      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'test_def_id',
        },
        {
          type: 'hidden',
          field: 'item_def_id',
        },
        {
          type: 'hidden',
          field: 'unit_name',
        },
        {
          type: 'input',
          title: '检测值',
          field: 'result_val',
          props: { size: 'small', placeholder: '请填写检测值' },
          validate: [
            { required: true, message: '请选择检测值', whitespace: true }
          ]
        },
        {
          type: 'DatePicker',
          title: '检测时间',
          field: 'result_test_time', style: { width: '100%' },
          props: { size: 'small', placeholder: '请填写检测时间', type: 'date', format: 'YYYY-MM-DD HH:mm:00', showTime: true },
          validate: [
            { required: true, message: '请填写检测时间' }
          ]
        },
        {
          type: 'select',
          title: '对应指标区间',
          field: 'result_type',
          props: { size: 'small', placeholder: '请选择对应指标区间' },
          options: this.$options.filters.selectOptionsData('his.result_type', [0, 1, 5, 6, 7])
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { size: 'small', placeholder: '请填写备注' }
        },
      ],
      //组件参数配置
      cformOption: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 5 },
          wrapperCol: { span: 15 }
        },
        submitBtn: false
      },

    }
  },
  created () {
    this.refreshMeasureInfo()
  },
  methods: {
    moment,
    refreshMeasureInfo () {
      this.loadingMeasureInfo = true
      this.bodyInfo = []
      this.measureInfo = []
      getPatientAllMeasureInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          res.data.forEach(element => {
            if (element.test_type === 1) {
              this.bodyInfo.push(element)
            }
            if (element.test_type === 100) {
              this.measureInfo.push(element)
            }
          })
          console.log(this.bodyInfo, this.measureInfo)
        }
        this.loadingMeasureInfo = false
      })
    },
    modifyMeasureInfo (item) {
      this.itemNameTitle = item.item_name + ' （ ' + item.unit_name + ' ） '
      this.modalVisible1 = true

      this.$nextTick(() => {
        this.cformApi.setValue({
          patient_id: this.parentParams.patient,
          test_def_id: item.test_def_id,
          item_def_id: item.item_def_id,
          unit_name: item.unit_name,
          result_val: item.result_val,
          result_type: '3',
          result_test_time: moment().format('YYYY-MM-DD HH:mm:00')
        })

      })

    },
    modifyMonitorItem (item) {
      this.loadingMeasureInfo = true
      changeMeasureMonitorStatus({ id: item.id, enterprise_id: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          item.is_monitor ^= 1
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
        this.loadingMeasureInfo = false
      })
    },

    handleModalOk1 () {
      this.cformApi.validate((valid, fail) => {
        if (valid) {
          this.spinningM1 = true
          this.okButtonProps1 = { props: { loading: true } }

          savePatientMeasureInfo(Object.assign({}, this.cformApi.formData())).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')

              this.spinningM1 = false
              this.okButtonProps1 = {}
              this.modalVisible1 = false
              this.refreshMeasureInfo()
              // todo 修改检测值后，同步刷新history那边的显示

            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
              this.spinningM1 = false
              this.okButtonProps1 = {}

            }
          })
        }
      })
    }
  }
})

</script>