// 将文件转换为base64格式方便前端回显
export function file2Base64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// 加法计算，主要是避免小数精度问题
export function mathAddNum (a, b) {
  a = a === undefined || a === null || a === '' || isNaN(a) ? 0 : parseFloat(a)
  b = b === undefined || b === null || b === '' || isNaN(b) ? 0 : parseFloat(b)
  const aStr = a.toString()
  let aDecInx = aStr.indexOf('.')
  aDecInx = aDecInx === -1 ? 0 : aStr.length - aDecInx - 1
  const bStr = b.toString()
  let bDecInx = bStr.indexOf('.')
  bDecInx = bDecInx === -1 ? 0 : bStr.length - bDecInx - 1
  const precision = Math.max(aDecInx, bDecInx)
  const multiplier = Math.pow(10, precision)
  return (Math.round(a * multiplier) + Math.round(b * multiplier)) / multiplier
}

// 减法计算，主要是避免小数精度问题
export function mathSubtractNum (a,b) {
  return mathAddNum(a,-b)
}