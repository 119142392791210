import request from '@/utils/request'

const apiUrls = {
    listDailyFoodMenu: '/DailyFoodMenus/listDailyFoodMenu',
    viewDailyFoodMenu: '/DailyFoodMenus/viewDailyFoodMenu',
    saveDailyFoodMenu: '/DailyFoodMenus/saveDailyFoodMenu',
    removeOneFoodMenuRecipe: '/DailyFoodMenus/removeOneFoodMenuRecipe'
}

export function listDailyFoodMenu (parameter) {
    return request({
        url: apiUrls.listDailyFoodMenu,
        method: 'get',
        params: parameter
    })
}

export function viewDailyFoodMenu (parameter) {
    return request({
        url: apiUrls.viewDailyFoodMenu,
        method: 'get',
        params: parameter
    })
}

export function saveDailyFoodMenu (data) {
    return request({
        url: apiUrls.saveDailyFoodMenu,
        method: 'post',
        data
    })
}

export function removeOneFoodMenuRecipe (data) {
    return request({
        url: apiUrls.removeOneFoodMenuRecipe,
        method: 'post',
        data
    })
}
