import { getTestItemDefListArr } from '@/api/dms/testItem'

let formulaArr = {}
let patientInfo = {}

// 体格指标相关的检测项目的自动计算项目相关的change事件监听，触发自动计算结果回显
export function getAndRenderBodyItemFormData (prentObj) { 
    // 获取体格指标字段数据
    getTestItemDefListArr({ test_type: 1, enterprise: 0, patient: prentObj.params.patient }).then((res) => {
        prentObj.cformBodyApi.setValue({ patient_id: prentObj.params.patient })
        if (res.status !== undefined && res.status === 1) {
            patientInfo = res.patientInfo
            res.data.map(rv => {
                const fieldName = rv.rptcode + '@@' + rv.test_def_id + '@@' + rv.id + '@@' + rv.unit_name
                let newRule = {
                    type: 'input',
                    title: rv.name,
                    field: fieldName, style: { width: '100%' }
                }
                if (rv.formula === undefined || rv.formula === null || rv.formula === '' || rv.formula === false) {
                    // 非计算项直接填写
                    newRule.props = { type: 'number', placeholder: '请填写' + rv.name, min: 0, step: 0.01, max: 10000, addonAfter: rv.unit_name }
                    newRule.on = {
                        blur: () => clacItemChange(prentObj.cformBodyApi)
                    }
                } else {
                    // 计算项禁用，自动计算
                    newRule.props = { placeholder: '自动计算项，保留两位小数。', disabled: true, addonAfter: rv.unit_name }
                    // 记录公式[先写个默认的，后面有其他计算方式的债再单独处理]
                    formulaArr[fieldName] = rv.formula
                    if (rv.judgement_type === 11) {
                        // 按性别分公式计算的
                        const cuformulaObj = JSON.parse(rv.formula)
                        if (patientInfo.gender === undefined || patientInfo.gender === null || patientInfo.gender === '' || cuformulaObj[patientInfo.gender] === undefined) {
                            prentObj.$message.error('当前用户性别不确定！自动计算项有性别相关公式无法计算！请先维护客户信息！', 5)
                            delete formulaArr[fieldName]
                        } else {
                            // bmr的计算要手动选择 需要在前面增加下拉选择 默认选中第一个
                            if (rv.rptcode === 'bmr' && typeof cuformulaObj[patientInfo.gender] === 'object' && cuformulaObj[patientInfo.gender].length !== undefined && cuformulaObj[patientInfo.gender].length > 0) {
                                formulaArr[fieldName] = cuformulaObj[patientInfo.gender][0].value
                                const newRulePre = {
                                    type: 'select',
                                    title: rv.name+'公式',
                                    field: 'formula@@' + fieldName,
                                    col: {
                                        span: 24
                                    },
                                    wrap: {
                                        labelCol: { span: 4 },
                                        wrapperCol: { span: 19 }
                                    },
                                    on: {
                                        change: (v) => bmrFormulaChange(v,fieldName,prentObj)
                                    },
                                    props: { placeholder: '请选择计算公式' },
                                    options: cuformulaObj[patientInfo.gender].map(fv => {
                                        return { value: fv.value+'@@' + fv.label, label: fv.label }
                                    })
                                }
                                prentObj.cformBodyApi.prepend(newRulePre, 'patient_id')
                                prentObj.cformBodyApi.setValue({ ['formula@@' + fieldName]: cuformulaObj[patientInfo.gender][0].value + '@@' + cuformulaObj[patientInfo.gender][0].label })
                            } else {
                                formulaArr[fieldName] = cuformulaObj[patientInfo.gender]
                            }
                        }
                    }
                }
                prentObj.cformBodyApi.prepend(newRule, 'patient_id')
            })
            if (prentObj.spinning !== undefined && prentObj.spinning === true) {
                prentObj.spinning = false
            }
        } else {
            prentObj.$message.error(res.msg || '暂无体格指标相关配置信息！')
            return prentObj.$router.go(-1)
        }
    })
}

// 体格指标相关的检测项目的自动计算项目相关的change事件监听，触发自动计算结果回显
export function clacItemChange (cformApiObj) {
    let fmData = cformApiObj.formData()
    const formDataObj = { age: patientInfo.ageyear }
    const fmMax = Object.keys(fmData).length
    let fmCuNum = 0
    for (let fm in fmData) {
        const fmArr = fm.split('@@')
        formDataObj[fmArr[0]] = (isNaN(fmData[fm]) || fmData[fm] === null) ? fmData[fm] : parseFloat(fmData[fm]).toFixed(2)
        fmCuNum++
    }
    const tminner = setInterval(() => {
        if (fmCuNum >= fmMax) {
            for (let ffn in formulaArr) {
                let cuFormula = formulaArr[ffn]
                let isContinue = false // 是否终止当前循环的标识
                // 转换出公式中要使用的字段
                const formulaFields = cuFormula.replace(/\(|\)|\（|\）|\s/ig, '').replace(/\+|\-|\*|\/|÷|×/ig, '@@').split('@@')
                if (typeof formulaFields === 'object' && formulaFields.length !== undefined && formulaFields.length > 0) {
                    const formulaFieldsArr = Array.from(new Set(formulaFields))
                    let cuffNum = 0
                    for (let fidx in formulaFieldsArr) {
                        if (isNaN(formulaFieldsArr[fidx])) {
                            if (formDataObj[formulaFieldsArr[fidx]] === undefined) {
                                console.log(formulaFieldsArr[fidx],cuFormula)
                                // 公式中的相关字段还没有值的时候直接终止循环
                                isContinue = true
                                break
                            }
                            cuFormula = cuFormula.replaceAll(formulaFieldsArr[fidx], formDataObj[formulaFieldsArr[fidx]])
                        }
                        cuffNum++
                    }
                    // 数据处理完了才去计算
                    const ffinner = setInterval(() => {
                        if (cuffNum >= formulaFieldsArr.length || isContinue) {
                            clearInterval(ffinner)
                            if (!isContinue) {
                                const cuVal = eval(cuFormula).toFixed(2)
                                const setValObj = {}
                                setValObj[ffn] = cuVal
                                cformApiObj.setValue(setValObj)
                            }
                            // return true
                        }
                    }, 100)
                }
            }
            clearInterval(tminner)
        }
    }, 100)
}

// bmr的计算公式选择
export function bmrFormulaChange (v, field, prentObj) {
    const fvArr = v.split('@@')
    formulaArr[field] = fvArr[0]
    // 触发一次重新计算
    clacItemChange(prentObj.cformBodyApi)
}
// export function clacItemChange (cformApiObj,cuFormula) {
//     let fmData = cformApiObj.formData()
//     const formDataObj = {}
//     const fmMax = Object.keys(fmData).length
//     let fmCuNum = 0
//     for (let fm in fmData) {
//         const fmArr = fm.split('@@')
//         formDataObj[fmArr[0]] = (isNaN(fmData[fm]) || fmData[fm] === null) ? fmData[fm] : parseFloat(fmData[fm]).toFixed(2)
//         fmCuNum++
//     }
//     const tminner = setInterval(() => {
//         if (fmCuNum >= fmMax) {
//             for (let ffn in formulaArr) {
//                 let cuFormula = formulaArr[ffn]
//                 let isContinue = false // 是否终止当前循环的标识
//                 // 转换出公式中要使用的字段
//                 const formulaFields = cuFormula.replace(/\(|\)|\（|\）|\s/ig, '').replace(/\+|\-|\*|\/|÷|×/ig, '@@').split('@@')
//                 if (typeof formulaFields === 'object' && formulaFields.length !== undefined && formulaFields.length > 0) {
//                     const formulaFieldsArr = Array.from(new Set(formulaFields))
//                     let cuffNum = 0
//                     for (let fidx in formulaFieldsArr) {
//                         if (formDataObj[formulaFieldsArr[fidx]] === undefined) {
//                             console.log(formulaFieldsArr[fidx])
//                             // 公式中的相关字段还没有值的时候直接终止循环
//                             isContinue = true
//                             break
//                         }
//                         cuFormula = cuFormula.replaceAll(formulaFieldsArr[fidx], formDataObj[formulaFieldsArr[fidx]])
//                         cuffNum++
//                     }
//                     // 数据处理完了才去计算
//                     const ffinner = setInterval(() => {
//                         if (cuffNum >= formulaFieldsArr.length || isContinue) {
//                             clearInterval(ffinner)
//                             if (!isContinue) {
//                                 const cuVal = eval(cuFormula).toFixed(2)
//                                 const setValObj = {}
//                                 setValObj[ffn] = cuVal
//                                 cformApiObj.setValue(setValObj)
//                             }
//                             return true
//                         }
//                     }, 100)
//                 }
//             }
//             clearInterval(tminner)
//         }
//     }, 100)
// }