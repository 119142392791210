<template>
  <div>
    <!-- Line chart 2 -->
    <canvas
      ref="chart"
      class="chart-line-2"
      :style="{'height': height + 'px'}"
    ></canvas>
    <!-- / Line chart 2 -->
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default ({
  props: {
    chartData: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      height: 300,
    }
  },
  mounted () {
    let ctx = this.$refs.chart.getContext("2d")
    let labelArr = []
    let valArr = []
    if (this.chartData !== undefined) {
      this.chartData.forEach(element => {
        labelArr.push(element.result_test_time)
        valArr.push(element.result_val)
      })
    }

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labelArr,
        datasets: [{
          label: this.chartData.item_name,
          tension: 0.4,
          borderWidth: 0,
          pointRadius: 2,
          pointBackgroundColor: "#4ED9D9",
          borderColor: "#4ED9D9",
          borderWidth: 3,
          data: valArr,
          maxBarThickness: 6
        },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 30,
            right: 15,
            left: 10,
            bottom: 5,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5]
            },
            ticks: {
              display: true,
              padding: 10,
              color: '#b2b9bf',
              font: {
                size: 11,
                family: "Open Sans",
                style: 'normal',
                lineHeight: 2
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5]
            },
            ticks: {
              display: true,
              color: '#b2b9bf',
              padding: 10,
              font: {
                size: 11,
                family: "Open Sans",
                style: 'normal',
                lineHeight: 2
              },
            },
          },
        },
      }
    })
  },
  // Right before the component is destroyed,
  // also destroy the chart.
  beforeDestroy: function () {
    if (this.chart !== undefined) {
      this.chart.destroy()
    }
  },
})

</script>

<style lang="scss" scoped>
</style>