<template>
  <div>
    <!-- Doughnut chart -->
    <canvas
      ref="chart"
      class="chart-doughnut"
      :style="{'height': height + 'px'}"
    ></canvas>
    <!-- / Doughnut chart -->
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default ({
  props: {
    chartData: {
      type: Object,
      default: {},
    },
    height: {
      type: Number,
      default: 200,
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    let ctx = this.$refs.chart.getContext("2d")
    console.log(this.chartData)
    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: Object.keys(this.chartData),
        datasets: [{
          label: "Projects",
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: ['#FADB14', '#1890FF', '#F5222D'],
          data: Object.values(this.chartData),
          fill: false
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            }
          },
        },
      },
    })
  },
  // Right before the component is destroyed,
  // also destroy the chart.
  beforeDestroy: function () {
    if (this.chart !== undefined) {
      this.chart.destroy()
    }
  },
})

</script>

<style lang="scss" scoped>
</style>