<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div>
          <div>
            <a-icon
              title="返回列表页"
              class="back-page-icon"
              type="arrow-left"
              @click="$router.go(-1)"
            />
          </div>
          <a-divider style="margin-top: 16px;" />

          <form-create
            v-model="cformApi"
            :rule="cformRule"
            :option="cformOption"
            @submit="savePatientAppointRequest"
          ></form-create>

          <a-row>
            <a-col
              :offset="11"
              :span="12"
            >
              <a-button
                @click="submitBtnClick"
                type="primary"
              >确认保存</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getHisPatientSelectList } from '@/api/dms/patient'
import { addPatientAppointInfo } from '@/api/dms/clientAppointment'
import { getSchedulesByUser } from '@/api/dms/schedule'
import { selectListUser } from '@/api/dms/user'

export default {
  name: 'AddPatientAppointment',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'dep_id',
          value: this.$store.getters.userInfo.user.department_id
        },
        {
          type: 'a-radio-group',
          title: '客户是否注册',
          value: '1',
          field: 'patient_mark',
          props: { buttonStyle: 'solid', placeholder: '请选择客户是否注册' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['已有账号客户']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['新客户']
            }
          ],
          validate: [
            { required: true, message: '请确认选择客户是否注册', whitespace: true }
          ],
          on: {
            change: (v) => this.pmarkChange(v)
          }
        },
        {
          type: 'select',
          title: '客户姓名',
          field: 'choose_patient',
          props: { placeholder: '请选择客户姓名', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择客户姓名' }
          ]
        },
        {
          type: 'input',
          title: '客户姓名',
          field: 'name',
          hidden: true,
          props: { placeholder: '请填写客户姓名' },
          col: {
            span: 7
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          validate: [
            { required: true, message: '请确认输入客户姓名', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '',
          hidden: true,
          field: 'name_py',
          props: {
            addonBefore: ' / ', placeholder: '请填写客户英文姓名'
          },
          col: {
            span: 5
          },
          wrap: {
            labelCol: { span: 2 },
            wrapperCol: { span: 19 }
          }
        },
        {
          type: 'DatePicker',
          title: '出生年月',
          hidden: true,
          field: 'birth_date', style: { width: '100%' },
          props: { placeholder: '请选择出生年月', type: 'date', allowClear: true }
        },
        {
          type: 'a-radio-group',
          title: '性别',
          hidden: true,
          value: '0',
          field: 'gender',
          props: { buttonStyle: 'solid', placeholder: '请选择性别' },
          children: [
            {
              type: 'a-radio-button',
              props: {
                value: '0'
              },
              children: ['保密']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '1'
              },
              children: ['男']
            },
            {
              type: 'a-radio-button',
              props: {
                value: '2'
              },
              children: ['女']
            }
          ],
          validate: [
            { required: true, message: '请确认选择性别', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '联系电话',
          hidden: true,
          field: 'contact_phone',
          props: { placeholder: '请填写联系电话' }
        },
        {
          type: 'select',
          title: '预约服务医生',
          field: 'incharge_doc',
          props: { placeholder: '请选择预约服务医生', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择预约服务医生' }
          ],
          on: {
            change: (v) => this.docChange(v)
          }
        },
        {
          type: 'select',
          title: '预约服务时间',
          field: 'schedule_id',
          props: { placeholder: '请选择预约服务时间', mode: 'multiple', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择预约服务时间' }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    }
  },
  created () {
    this.spinning = true
    getHisPatientSelectList({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformApi.updateRules({ // 更新规则
          'choose_patient': {
            options: res.data.map(dv => {
              let gender = this.$options.filters.showText('ryx.gender', dv.gender.toString())
              gender = gender === '-' ? '保密' : gender
              return { value: dv.id, label: dv.name + ' - ' + gender + ' - ' + dv.patient_no }
            })
          }
        })
      }
    })

    selectListUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user_type: 50 }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.cformApi.updateRules({ // 更新规则
          'incharge_doc': { options: res.data }
        })
        const filterArr = res.data.filter((fv) => {
          return fv.value === this.$store.getters.userInfo.user.id.toString()
        })
        if (filterArr.length > 0) {
          this.cformApi.setValue({ incharge_doc: this.$store.getters.userInfo.user.id.toString() })
          // 默认选择当前用户则去获取当前用户的排班信息
          this.docChange(this.$store.getters.userInfo.user.id)
        }
      }
      this.spinning = false
    })
  },
  methods: {
    submitBtnClick () {
      this.cformApi.submit()
    },
    savePatientAppointRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      addPatientAppointInfo(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '新增客户预约记录成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              formObj.resetFields()
              that.cformApi.updateRules({ schedule_id: { options: [] } })
            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    pmarkChange (v) {
      if (v.target.value > 1) {
        // 新客户
        this.cformApi.hidden(true, ['choose_patient'])
        this.cformApi.hidden(false, ['name', 'name_py', 'birth_date', 'gender', 'contact_phone'])
      } else {
        // 直接选
        this.cformApi.hidden(false, ['choose_patient'])
        this.cformApi.hidden(true, ['name', 'name_py', 'birth_date', 'gender', 'contact_phone'])
      }
    },
    docChange (v) {
      this.spinning = true
      this.cformApi.updateRules({ schedule_id: { options: [] } })
      this.cformApi.setValue({ schedule_id: undefined })
      getSchedulesByUser({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: v }).then(res => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          const avaArr = res.data.filter(dv => {
            dv.available_count = (isNaN(dv.available_count) || dv.available_count === '' && dv.available_count === null) ? 0 : parseInt(dv.available_count)
            dv.add_count = (isNaN(dv.add_count) || dv.add_count === '' && dv.add_count === null) ? 0 : parseInt(dv.add_count)
            dv.appoint_count = (isNaN(dv.appoint_count) || dv.appoint_count === '' && dv.appoint_count === null) ? 0 : parseInt(dv.appoint_count)
            return dv.appoint_count < (dv.available_count + dv.add_count)
          })
          if (avaArr.length < 1) {
            this.$message.error('当前服务医生暂无排班信息！')
            return false
          }
          this.cformApi.updateRules({ // 更新规则
            'schedule_id': {
              options: avaArr.map(dv => {
                return { value: dv.id + '@@' + dv.service_date + '@@' + dv.appoint_count, label: dv.service_date.substr(0, 16) + ' 　可约: ' + (dv.available_count + dv.add_count - dv.appoint_count) + '个' }
              })
            }
          })
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>