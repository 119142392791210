import request from '@/utils/request'

const userApi = {
  login: '/auth/Users/login',
  logout: '/auth/Users/logout',
  registerDmsUser: '/auth/Users/registerDmsEntUser',
  selectListEnterprise: '/auth/Enterprises/selectListEnterprise',
  SendSms: '/auth/SendSms/sendSmsVcode',
  // get my info
  UserInfo: '/auth/Users/permissionInfo',
  getDicList: '/auth/BaseCodes/getDicList',
  getBasicPlatformInfo: '/auth/BaseCodes/getBasicPlatformInfo'
}

/**
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: userApi.login,
    method: 'post',
    data: parameter
  })
}

export function registerDmsUser (data) {
  return request({
    url: userApi.registerDmsUser,
    method: 'post',
    data
  })
}

export function selectListEnterprise (parameter) {
  return request({
    url: userApi.selectListEnterprise,
    method: 'get',
    params: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getDicList (parameter) {
  return request({
    url: userApi.getDicList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

export function getBasicPlatformInfo () {
  return request({
    url: userApi.getBasicPlatformInfo,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: { platform: process.env.VUE_APP_PLATFORM_MARK }
  })
}

export function logout () {
  return request({
    url: userApi.logout,
    method: 'post'
  })
}
