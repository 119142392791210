<template>
  <div>
    <a-drawer
      width="600"
      :maskClosable="!recordDrawerSpinning"
      placement="right"
      :closable="true"
      :visible="recordDrawerVisible"
      @close="closeRecordDrawer"
      id="drawer-body-div"
    >
      <div slot="title">
        <a-row>
          <a-col
            :span="12"
            class="title-text"
          >
            问诊记录列表
          </a-col>
          <a-col
            :span="12"
            class="text-right"
          >
            <a-button
              v-if="!cuRecord.notEdit"
              type="primary"
              icon="plus"
              size="small"
              class="mr-30"
              @click="clickNewOrUpdateRecord"
            >新增问诊记录</a-button>
          </a-col>
        </a-row>
      </div>
      <a-spin
        tip="处理中..."
        size="large"
        :spinning="recordDrawerSpinning"
      >
        <div v-if="recordData.length > 0">
          <div
            v-for="(rdv,idx) in recordData"
            :key="idx"
          >
            <a-row>
              <a-col :span="19">
                <a-row>
                  ID号： {{ rdv.id }}
                </a-row>
                <a-row>
                  营养师： {{ rdv.nick_name }} / {{ rdv.enterprise_name }}
                </a-row>
                <a-row>
                  问诊时间： {{ rdv.create_time !== undefined ? rdv.create_time.substr(0,10) : ''}}
                </a-row>
                <a-row>
                  问诊类型：
                  <span class="font-weight font-success">
                    {{ 'health_service_record.service_type' | showText(rdv.service_type.toString()) }}
                  </span>
                </a-row>
                <a-row>
                  <a-popover
                    title="问诊详情"
                    trigger="hover"
                    placement="left"
                  >
                    <template slot="content">
                      <p class="mb-0">问诊详情： {{ rdv.service_content }}</p>
                    </template>
                    问诊内容：{{ rdv.service_content !== null ? rdv.service_content.substr(0,22) : '' }}
                    <a-icon
                      type="small-dash"
                      theme="outlined"
                    />
                  </a-popover>

                </a-row>
                <a-row>
                  当前状态：
                  <span
                    :class="rdv.step_status < 90 ? 'font-error' : 'font-success'"
                    class="font-weight"
                  >
                    {{ 'service_records.step' | showText(rdv.step_status.toString()) }}
                  </span>
                </a-row>
                <a-row>
                  备注： {{ rdv.remarks }}
                </a-row>
                <a-row v-if="rdv.service_record_rating2 !== undefined && rdv.service_record_rating2.length > 0">
                  <a-popover
                    title=""
                    trigger="hover"
                    placement="left"
                  >
                    <template slot="content">
                      <p
                        v-for="(rt2,rti2) in rdv.service_record_rating2"
                        :key="rti2"
                        class="mb-0"
                      >
                        <span v-if="rt2.is_add === 0">导师评价：</span>
                        <span v-else>追评：</span>
                        [{{ parseFloat(rt2.rating_val).toFixed(1) }} 分] {{ rt2.comment_content }} {{ rt2.create_time }}
                      </p>
                    </template>
                    导师评价：[{{ parseFloat(rdv.service_record_rating2[0].rating_val).toFixed(1) }} 分] {{ rdv.service_record_rating2[0].comment_content !== null ? rdv.service_record_rating2[0].comment_content.substr(0,20) : '' }}
                    <a-icon
                      type="small-dash"
                      theme="outlined"
                    />
                  </a-popover>
                </a-row>
              </a-col>
              <a-col
                :span="5"
                class="text-right"
              >
                <div class="mb-5">
                  <a-button
                    v-if="rdv.service_type === 20 && rdv.fm > 0"
                    type="primary"
                    icon="file-text"
                    size="small"
                    @click="clickViewFoodMenuDetail(rdv)"
                  >食谱详情</a-button>
                  <a-button
                    v-else
                    type="primary"
                    icon="file-text"
                    size="small"
                    @click="clickViewDetail(rdv)"
                  >诊疗详情</a-button>
                </div>
                <template v-if="rdv.service_type >= 10">
                  <div
                    class="mb-5"
                    v-if="!cuRecord.notEdit"
                  >
                    <a-button
                      type="danger"
                      icon="form"
                      size="small"
                      @click="clickEditMenuFood(rdv)"
                    >食谱编辑</a-button>
                  </div>
                </template>
                <template v-if="rdv.service_type <= 10">
                  <div
                    class="mb-5"
                    v-if="!cuRecord.notEdit"
                  >
                    <a-button
                      type="danger"
                      icon="form"
                      size="small"
                      @click="clickNewOrUpdateRecord(rdv)"
                    >问诊编辑</a-button>
                  </div>
                  <div class="mb-5">
                    <!-- <a-button
                      type="primary"
                      icon="printer"
                      size="small"
                      @click="clickPrintRecord(rdv)"
                    >打印方案</a-button> -->
                    <a-dropdown placement="bottomLeft">
                      <a-button
                        type="primary"
                        icon="printer"
                        size="small"
                      ><span class="ml-5">打印方案</span></a-button>
                      <a-menu slot="overlay">
                        <a-menu-item v-if="rdv.service_type <= 10">
                          <a @click="clickPrintRecord(rdv)">诊疗方案</a>
                        </a-menu-item>
                        <a-menu-item v-if="rdv.service_type >= 10 && rdv.fm > 0">
                          <a @click="clickPrintFood(rdv)">定制食谱</a>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </template>
                <div v-if="cuRecord.rate">
                  <a-button
                    v-if="rdv.service_record_rating !== undefined && rdv.service_record_rating.length > 0"
                    type="primary"
                    icon="star"
                    size="small"
                    @click="clickRate(rdv,1)"
                  >追评</a-button>
                  <a-button
                    v-else
                    type="primary"
                    icon="star"
                    size="small"
                    @click="clickRate(rdv,0)"
                  >评分</a-button>
                </div>
              </a-col>
            </a-row>

            <div
              @click="clickLoadMore"
              v-if="idx >= recordData.length - 1"
              class="more-div"
            >
              <a-divider>{{ page > lastPage ? '下面没有啦~~' : '点击加载更多'}}</a-divider>
            </div>
            <a-divider v-else />
          </div>
        </div>
        <a-empty
          style="margin-top:30%;"
          v-else
          description="暂无相关数据"
        />
      </a-spin>
    </a-drawer>

    <!-- 食谱详情的弹窗 -->
    <CardFoodmenuOneModal ref="cardFoodmenuOneModal" />

    <!-- 评价抽屉 -->
    <ServiceRate
      ref="serviceRateDrawer"
      @complete="refreshListData"
    />
  </div>
</template>

<script>
import { listAllServiceRecord } from '@/api/dms/serviceRecord'
import { CardFoodmenuOneModal } from "@/components/PatientCards"
import { ServiceRate } from '@/components/ServiceRecord'

export default ({
  name: 'ServiceRecordDrawer',
  components: {
    CardFoodmenuOneModal,
    ServiceRate
  },
  data () {
    return {
      recordDrawerVisible: false,
      recordDrawerSpinning: false,
      recordData: [],
      pageSize: 10,
      page: 1,
      lastPage: 1,
      cuRecord: {}
    }
  },
  created () {
  },
  methods: {
    closeRecordDrawer () {
      this.recordDrawerVisible = false
      this.page = 1
      this.lastPage = 1
      this.recordData = []
    },
    showDrawer (paramData) {
      this.recordDrawerVisible = true
      this.recordDrawerSpinning = true
      this.$nextTick(() => {
        if (paramData.id !== undefined && paramData.id > 0) {
          // 根据企业获取企业角色
          this.cuRecord = paramData
          this.page = 1
          this.lastPage = 1
          this.recordData = []
          this.loadServiceRecordData()
        } else {
          this.modalVisible1 = false
          this.$message.error('请确认当前选择用户！')
        }
      })
    },
    refreshListData () {
      // 刷新当前列表页
      this.recordDrawerSpinning = true
      this.page = 1
      this.lastPage = 1
      this.recordData = []
      this.loadServiceRecordData()
    },
    clickNewOrUpdateRecord (record = {}) {
      let recordParam = { patient: this.cuRecord.id, name: this.cuRecord.name, phone: this.cuRecord.contact_phone }
      if (record.id !== undefined && record.id > 0) {
        recordParam.record = record.id
      }
      sessionStorage.setItem('HisPatients.setServiceRecord', JSON.stringify(recordParam))
      this.$router.push({ path: '/HisPatients/setServiceRecord' })
    },
    loadServiceRecordData () {
      console.log(this.cuRecord)
      listAllServiceRecord({ patient: this.cuRecord.patient_uid, enterprise: this.$store.getters.userInfo.user.target_enterprise, page: this.page, pageSize: this.pageSize, join: ['rating'] }).then((res2) => {
        console.log(res2)
        if (res2.status !== undefined && res2.status === 1) {
          res2.data.map((dv, di) => {
            dv.service_record_rating.map(v => {
              if (res2.data[di]['service_record_rating' + v.rating_id] === undefined) {
                res2.data[di]['service_record_rating' + v.rating_id] = []
              }
              res2.data[di]['service_record_rating' + v.rating_id].push(v)
            })
          })
          this.recordData = this.recordData.concat(res2.data)
          this.lastPage = res2.last_page
          this.page++
        }
        this.recordDrawerSpinning = false
      })
    },
    clickLoadMore () {
      if (this.page > this.lastPage) {
        this.$message.error('下面没有数据啦~~！')
        return false
      }
      this.recordDrawerSpinning = true
      this.loadServiceRecordData()
    },
    clickPrintRecord (record) {
      sessionStorage.setItem('HisPatients.printPatientReportView', JSON.stringify({ record: record.id, patient: record.patient_id, patient_uid: record.patient_uid }))
      this.$router.push({ path: '/HisPatients/printPatientReportView' })
    },
    clickPrintFood (record) {
      sessionStorage.setItem('HisPatients.printPatientFoodView', JSON.stringify({ record: record.id, patient: record.patient_id, fm: record.fm }))
      this.$router.push({ path: '/HisPatients/printPatientFoodView' })
    },
    clickViewDetail (record) {
      sessionStorage.setItem('HealthServiceRecords.viewServiceRecord', JSON.stringify(record))
      this.$router.push({ path: '/HealthServiceRecords/viewServiceRecord' })
    },
    clickEditMenuFood (v) {
      const cuPm = { patient: v.patient_id, fm: v.fm }
      if (v.fm === undefined || v.fm < 1) {
        this.$message.error('当前记录暂无食谱定制数据！')
        return false
      }
      sessionStorage.setItem('HealthPatientFoodMenus.setPatientFoodMenu', JSON.stringify(cuPm))
      this.$router.push({ path: '/HealthPatientFoodMenus/setPatientFoodMenu' })
    },
    clickViewFoodMenuDetail (record) {
      this.$refs.cardFoodmenuOneModal.showModal({ id: record.fm })
    },
    clickRate (record, is_add = 0) {
      this.$refs.serviceRateDrawer.showDrawer({ patient_id: record.patient_id, enterprise_id: record.enterprise_id, service_record_id: record.id, rating_id: 2, is_add: is_add, is_public: 0 })
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
#drawer-body-div {
  /deep/ .ant-drawer-close {
    height: 66px !important;
  }
  .title-text {
    line-height: 34px;
  }
  .text-collapse {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
</style>