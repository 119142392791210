import { login, getInfo, logout, getDicList } from '@/api/login'
import VueCookies from 'vue-cookies'

const user = {
  state: {
    token: '',
    name: '',
    avatar: '',
    enterprise_name: '',
    dictionariesList: (sessionStorage.getItem('dms.dictionariesList') === undefined || sessionStorage.getItem('dms.dictionariesList') === null) ? false : JSON.parse(sessionStorage.getItem('dms.dictionariesList')),
    roles: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token !== '' ? token + '@' + process.env.VUE_APP_PLATFORM_MARK : ''
    },
    SET_NAME: (state, { name }) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ENTERPRISE: (state, enterprise_name) => {
      state.enterprise_name = enterprise_name
    },
    SET_DIC_LIST: (state, dictionariesList) => {
      state.dictionariesList = dictionariesList
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      // 设置上传文件组件使用的地址前缀，避免地址中有端口导致的地址问题
      info.upUrlPre = process.env.VUE_APP_API_BASE_URL.replace('/dms', '/')
      state.info = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          // 登录成功写token和cookie
          if (response.status !== undefined && response.status === 1 && response.data.token !== undefined) {
            commit('SET_TOKEN', response.data.token)
            VueCookies.set('AJ-DMS', response.data.token)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          if (response.status !== undefined && response.status === 1 && response.data.menus !== undefined && response.data.menus.length > 0) {
            const role = response.data
            commit('SET_ROLES', role.permissions)
            commit('SET_INFO', role)
            commit('SET_NAME', { name: role.user.nick_name })
            commit('SET_AVATAR', role.user.header)
            commit('SET_ENTERPRISE', role.user.enterprise_name)
            // 数据获取成功则删除浏览器缓存
            sessionStorage.removeItem('dms.dictionariesList')
            resolve(response)
          } else {
            reject(new Error(response.msg || '暂无访问权限信息！'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          VueCookies.remove('AJ-DMS')
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          location.reload()
          resolve()
        }).catch(() => {
          VueCookies.remove('AJ-DMS')
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          resolve()
        })
      })
    },

    // 获取字典表信息
    GetDicList ({ commit }, params) {
      return new Promise((resolve, reject) => {
        getDicList(params).then(response => {
          if (response.status !== undefined && response.status === 1 && response.data !== undefined) {
            commit('SET_DIC_LIST', response.data)
            resolve(response)
          } else {
            reject(new Error(response.msg || '暂无访问权限信息！'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    }

  }
}

export default user
