<template>
  <a-drawer
    width="600"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="surveyInputDrawerVisibleCu"
    title="问卷调查填写"
    @close="closeSurveyInputDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveyInputSpinning"
    >
      <div v-if="surveyInfo.id !== undefined">
        <h6 class="font-semibold mx-0 mt-0 mb-30 text-center">{{ surveyInfo.title }}</h6>
        <form-create
          v-model="cformInputApi"
          :rule="cformInputRule"
          :option="cformOption"
        ></form-create>

        <a-row>
          <a-col
            :offset="8"
            :span="16"
          >
            <a-button
              @click="closeSurveyInputDrawer()"
              class="mr-10"
            >取消</a-button>
            <a-button
              @click="sureSaveInput()"
              type="primary"
              icon="check"
            >确认保存</a-button>
          </a-col>
        </a-row>
      </div>
      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
      />
    </a-spin>
  </a-drawer>
</template>

<script>
import { getSurveyDetailInfo } from '@/api/dms/survey'
import { setRuleByFormArr } from '@/utils/formCreateRuleDeal'
import { checkMobile } from '@/utils/customValidate'
import { sureSubmitSurveyResponse } from '@/api/dms/surveyResponse'

export default ({
  name: 'SurveyInput',
  data () {
    return {
      surveyInputDrawerVisibleCu: false,
      surveyInputSpinning: false,
      cformInputApi: {},
      cformInputRule: [],
      cformInputRuleNamePhone: [
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'input',
          title: '姓名',
          field: 'contact_name',
          col: {
            span: 10
          },
          wrap: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写姓名' }
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'contact_phone',
          col: {
            span: 10,
            offset: 4
          },
          wrap: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写联系电话' }
        },
        {
          type: 'hidden',
          field: 'source_type',
          value: 3
        },
        {
          type: 'hidden',
          field: 'survey_id'
        }
      ],
      cformOption: {
        form: {
          layout: 'vertical'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      surveyInfo: {},
      parentParams: {}
    }
  },
  created () {
  },
  methods: {
    closeSurveyInputDrawer () {
      this.surveyInfo = {}
      this.cformInputRule = []
      this.surveyInputDrawerVisibleCu = false
      this.$emit('complete')
    },
    showDrawer (pparms) {
      if (pparms.survey === undefined || pparms.survey < 1) {
        this.$message.error('请确认选择问卷！')
        return false
      }
      this.surveyInputDrawerVisibleCu = true
      this.parentParams = pparms
      // 获取列表信息
      this.loadSurveyData()
    },
    loadSurveyData () {
      this.surveyInputSpinning = true
      getSurveyDetailInfo({ enterprise: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id, survey: this.parentParams.survey, check_input: 1 }).then((res) => {
        // console.log(res, this.parentParams)
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          let inputValArr = { patient_id: this.parentParams.patient, contact_name: this.parentParams.name, contact_phone: this.parentParams.phone, survey_id: this.parentParams.survey }
          if (this.parentParams.record !== undefined) {
            inputValArr.service_record_id = this.parentParams.record
          }
          // 是否有问卷填写数据【v1版本直接客户列表中管理问卷填报：从后端根据当前uid标识获取填报数据的】
          // if (res.data.response !== undefined && res.data.response.id !== undefined) {
          //   this.parentParams.response = res.data.response.id
          //   inputValArr.contact_name = res.data.response.contact_name
          //   inputValArr.contact_phone = res.data.response.contact_phone
          //   if (res.data.response.misc_survey_response_item !== undefined) {
          //     res.data.response.misc_survey_response_item.map(rpitv => {
          //       if (rpitv.question_type === 'checkbox') {
          //         inputValArr[rpitv.question_id] = rpitv.answer.split(',')
          //       } else if (rpitv.question_type === 'a-radio-group') {
          //         inputValArr[rpitv.question_id] = parseInt(rpitv.answer)
          //       }
          //       else {
          //         inputValArr[rpitv.question_id] = rpitv.answer
          //       }
          //     })
          //   }
          // }
          // v2 改到问诊页面营养师代填，直接根据已填报数据回显
          if (this.parentParams.responseItems !== undefined && this.parentParams.responseItems.length > 0) {
            this.parentParams.responseItems.map(rpitv => {
              if (rpitv.question_type === 'checkbox') {
                inputValArr[rpitv.question_id] = rpitv.answer.split(',')
              } else if (rpitv.question_type === 'a-radio-group') {
                inputValArr[rpitv.question_id] = parseInt(rpitv.answer)
              } else {
                inputValArr[rpitv.question_id] = rpitv.answer
              }
            })
          }
          // 不是匿名的要填写电话姓名
          if (res.data.is_anonymous < 1) {
            this.cformInputRuleNamePhone[0].validate = [
              { required: true, message: '请确认输入姓名', whitespace: true }
            ]
            this.cformInputRuleNamePhone[1].validate = [
              { required: true, message: '请填写联系电话', whitespace: true },
              { validator: checkMobile }
            ]
          }
          this.surveyInfo = res.data
          // this.cformInputRule = res.data.form_rule !== undefined && res.data.form_rule !== null && res.data.form_rule !== '' ? JSON.parse(res.data.form_rule) : []
          const oriRule = res.data.form_rule !== undefined && res.data.form_rule !== null && res.data.form_rule !== '' ? JSON.parse(res.data.form_rule) : []
          // 获取rule数据，设置为实时保存答案
          const dealRule = setRuleByFormArr(oriRule, true, this)
          this.cformInputRule = [].concat(this.cformInputRuleNamePhone, dealRule.ruleArr)
          const timer = setTimeout(() => {
            this.cformInputApi.setValue(inputValArr)
            clearTimeout(timer)
          }, 100)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          this.closeSurveyInputDrawer()
        }
        this.surveyInputSpinning = false
      })
    },
    sureSaveInput () {
      const that = this
      // 确认保存
      this.cformInputApi.validate((valid2, fail2) => {
        if (valid2) {
          if (this.parentParams.response === undefined || this.parentParams.response < 1) {
            this.$message.error('请确认问卷信息同步填写完成！')
            return false
          }
          this.$confirm({
            title: '问卷确认提交保存之后将无法修改！确认提交？',
            content: false,
            cancelText: '取消',
            okText: '确认提交',
            onOk () {
              let fmData = that.cformInputApi.formData()
              fmData.is_finished = 1
              for (let fmk in fmData) {
                if (['contact_name', 'contact_phone', 'source_type', 'survey_id', 'patient_id', 'service_record_id'].indexOf(fmk) >= 0) {
                  // 不是问卷表单数据则跳过
                  continue
                }
                if (fmData[fmk] === undefined || fmData[fmk] === null || fmData[fmk] === '' || (fmData[fmk].length !== undefined && fmData[fmk].length < 1)) {
                  // 没填写数据的字段删除，后端才会将对应的项目设置为-10
                  delete fmData[fmk]
                  fmData.is_finished = 0
                }
              }
              // 开始保存问卷数据 
              that.surveyInputSpinning = true
              sureSubmitSurveyResponse(Object.assign({}, fmData, { response: that.parentParams.response })).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  that.$message.success('保存成功！')
                  that.closeSurveyInputDrawer()
                } else {
                  that.$message.error(res.msg || '问卷信息保存失败！')
                }
                that.surveyInputSpinning = false
              })
            },
            onCancel () {

            }
          })
        } else {
          console.log(fail2)
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>