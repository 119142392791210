import request from '@/utils/request'

const apiUrls = {
  listEnterpriseGood: '/EnterpriseGoods/listEnterpriseGood',
  addEnterpriseGood: '/EnterpriseGoods/addEnterpriseGood',
  getEnterpriseGoodInfo: '/EnterpriseGoods/getEnterpriseGoodInfo',
  updateEnterpriseGood: '/EnterpriseGoods/updateEnterpriseGood',
  changeStatus: '/EnterpriseGoods/changeStatus',
  getEnterpriseGoodsList: '/EnterpriseGoods/getEnterpriseGoodsList',
  listGoodsCategory: '/EnterpriseGoods/listGoodsCategory',
  saveGoodsCategory: '/EnterpriseGoods/saveGoodsCategory',
  listCategoryDetail: '/EnterpriseGoods/listCategoryDetail'
}

export function listEnterpriseGood (parameter) {
  return request({
    url: apiUrls.listEnterpriseGood,
    method: 'get',
    params: parameter
  })
}

export function addEnterpriseGood (data) {
  return request({
    url: apiUrls.addEnterpriseGood,
    method: 'post',
    data
  })
}

export function getEnterpriseGoodInfo (data) {
  return request({
    url: apiUrls.getEnterpriseGoodInfo,
    method: 'post',
    data
  })
}

export function updateEnterpriseGood (data) {
  return request({
    url: apiUrls.updateEnterpriseGood,
    method: 'post',
    data
  })
}

export function changeStatus (data) {
  return request({
    url: apiUrls.changeStatus,
    method: 'post',
    data
  })
}

export function getEnterpriseGoodsList (parameter) {
  return request({
    url: apiUrls.getEnterpriseGoodsList,
    method: 'get',
    params: parameter
  })
}

export function listGoodsCategory (parameter) {
  return request({
    url: apiUrls.listGoodsCategory,
    method: 'get',
    params: parameter
  })
}

export function saveGoodsCategory (data) {
  return request({
    url: apiUrls.saveGoodsCategory,
    method: 'post',
    data
  })
}

export function listCategoryDetail (parameter) {
  return request({
    url: apiUrls.listCategoryDetail,
    method: 'get',
    params: parameter
  })
}
