<template>
  <a-drawer
    width="550"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="surveyBindDrawerVisibleCu"
    title="问卷绑定客户"
    @close="closeSurveyBindDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveyBindSpinning"
    >
      <div>
        <form-create
          v-model="cformSurveyBindApi"
          :rule="cformSurveyBindRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="9"
          :span="15"
        >
          <a-button
            @click="closeSurveyBindDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { surveyResponseBindPatient } from '@/api/dms/surveyResponse'
import { getHisPatientSelectList } from '@/api/dms/patient'
import { listAllServiceRecord } from '@/api/dms/serviceRecord'

export default ({
  name: 'SurveyResponseBind',
  data () {
    return {
      surveyBindDrawerVisibleCu: false,
      surveyBindSpinning: false,
      cformSurveyBindApi: {},
      cformSurveyBindRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'select',
          title: '客户姓名',
          field: 'patient_id',
          props: { placeholder: '请选择客户姓名', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择客户姓名' }
          ],
          on: {
            change: (v) => this.patChange(v)
          }
        },
        {
          type: 'select',
          title: '问诊服务记录',
          field: 'service_record_id',
          props: { placeholder: '请选择问诊服务记录', maxTagCount: 1, maxTagTextLength: 30, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择问诊服务记录' }
          ]
        },
        {
          type: 'input',
          title: '备注',
          field: 'remarks',
          props: { placeholder: '请填写备注' }
        }
      ],
      cformOption: {
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
      patientListArr: []
    }
  },
  created () {

  },
  methods: {
    showDrawer (paramData) {
      this.surveyBindDrawerVisibleCu = true
      if (paramData.id === undefined || paramData.id < 1) {
        this.$message.error('请确认选择问卷填报数据！')
        return false
      }
      this.loadPatListData(paramData)
    },
    closeSurveyBindDrawer () {
      this.cformSurveyBindApi.resetFields()
      this.patientListArr = []
      this.surveyBindDrawerVisibleCu = false
    },
    loadPatListData (paramData) {
      this.surveyBindSpinning = true
      // 获取客户下拉列表数据
      getHisPatientSelectList({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.patientListArr = res.data.map(dv => {
            let gender = this.$options.filters.showText('ryx.gender', dv.gender.toString())
            gender = gender === '-' ? '保密' : gender
            return { value: dv.id, label: dv.name + ' - ' + gender + ' - ' + dv.patient_no, uid: dv.patient_uid }
          })

          this.cformSurveyBindApi.updateRules({ // 更新规则
            patient_id: { options: this.patientListArr }
          })
        }
        this.cformSurveyBindApi.setValue({ id: paramData.id })
        this.surveyBindSpinning = false
      })

    },
    submitBtnClick () {
      this.cformSurveyBindApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformSurveyBindApi.formData()
          this.surveyBindSpinning = true
          surveyResponseBindPatient(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.closeSurveyBindDrawer()
              this.$emit('complete')
              this.$message.success('绑定成功！')
            } else {
              this.$message.error(res.msg || '操作失败！请刷新后重试！')
            }
            this.surveyBindSpinning = false
          })

        }
      })
    },
    patChange (v) {
      console.log(v)
      if (v !== undefined && v !== null && v !== '') {
        const filterPat = this.patientListArr.filter(pv => {
          return pv.value === v
        })
        if (filterPat.length < 1) {
          this.$message.error('请确认选择客户！')
          return false
        }
        this.surveyBindSpinning = true
        // 获取客户的问诊记录数据
        listAllServiceRecord({ patient: filterPat[0].uid, enterprise: this.$store.getters.userInfo.user.target_enterprise, page: 1, pageSize: 1000 }).then((res2) => {
          console.log(res2)
          if (res2.status !== undefined && res2.status === 1) {
            const serviceListArr = res2.data.map(dv => {
              let serviceType = this.$options.filters.showText('health_service_record.service_type', dv.service_type.toString())
              return { value: dv.id, label: serviceType + ' - ' + dv.service_time.substr(0, 10) + ' - ' + dv.uid }
            })

            this.cformSurveyBindApi.updateRules({ // 更新规则
              service_record_id: { options: serviceListArr }
            })
          }
          this.surveyBindSpinning = false
        })
      }
    }
  }
})

</script>

<style lang="scss" scoped>
</style>