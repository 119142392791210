<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>饮食模式介绍</span> -->
          <br>
          <!-- <span>Introduction To Tietary Patterns</span> -->
        </div>
        <div>
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div
        v-if="dietaryPatternInfo.showTitle !== undefined && dietaryPatternInfo.showTitle === true"
        class="nuDivSectionTitle"
      >
        <span>{{ dietaryPatternInfo.pattern_name+'介绍' }}</span>
      </div>
      <!-- <h5></h5> -->
      <div v-if="dietaryPatternInfo.dietary_pattern_item.length > 0">
        <div
          v-for="(itv,idx) in dietaryPatternInfo.dietary_pattern_item"
          :key="idx"
        >
          <h6>{{ showItemLabel (itv.section_type, 1) }}</h6>
          <template v-if="itv.section_type >= 4 && itv.section_type <= 5">
            <!-- 显示前面icon的样式 -->
            <template v-for="(itv2,idx2) in itv.itemArr">
              <span
                v-if="itv2.content_type === 2"
                :key="idx2"
              >
                <img
                  class="show-img"
                  v-if="itv2.content_show !== undefined && itv2.content_show !== ''"
                  :src="itv2.content_show"
                >
              </span>
              <p
                :key="idx2"
                v-else
              ><a-icon
                  type="caret-right"
                  class="mr-5 ml-10"
                />{{ itv2.content }}</p>
            </template>
          </template>
          <!-- 直接显示内容的样式 -->
          <template v-else>
            <template v-for="(itv3,idx3) in itv.itemArr">
              <span
                v-if="itv3.content_type === 2"
                :key="idx3"
              >
                <img
                  class="show-img"
                  v-if="itv3.content_show !== undefined && itv3.content_show !== ''"
                  :src="itv3.content_show"
                >
              </span>
              <p
                :key="idx3"
                v-else
                class="text-span"
              >{{ itv3.content }}</p>
            </template>
          </template>
        </div>
      </div>
      <div v-else>
        <h6>简介</h6>
        <p class="text-span">{{ dietaryPatternInfo.intro }}</p>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import NutritionFooter from './NutritionFooter'

export default {
  name: 'NutritionPage8',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    dietaryPatternInfo: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    showItemLabel (type, sort) {
      if (sort > 1) {
        return ''
      } else {
        // 第一个才显示
        return this.$options.filters.showText('pattern_item.section_type', type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nuDivContent {
  display: block;
  overflow: auto;
}
.text-span {
  text-indent: 28px;
  word-break: break-all;
}
.show-img {
  max-width: 100%;
  text-align: center;
  max-height: 1345px;
}
</style>
