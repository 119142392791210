import request from '@/utils/request'

const apiUrls = {
  selectTreeDefData: '/HisFoodsenseItemDefs/selectTreeDefData'
}

export function selectTreeDefData (parameter) {
  return request({
    url: apiUrls.selectTreeDefData,
    method: 'get',
    params: parameter
  })
}

