<!-- 
	This is the Dashboards CRM page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>
    <a-row>
      <a-button
        type="primary"
        :loading="btnLoading"
        @click="clickDealFood"
      >
        {{ btnText }}
      </a-button>
    </a-row>
    <a-card :bordered="false">
      <FullCalendar :options="calendarOptions" />
    </a-card>

    <div class="my-20">
      <form-create
        v-model="cformApi"
        :rule="cformRule"
        :option="cformOption"
      ></form-create>
    </div>
    <div class="my-20">
      视频地址：{{ headerVal }}
      <!-- 端午小视频-20240523120141764683.mp4 -->
    </div>
    <div class="my-20">
      视频地址Url：{{ urlVedio }}
    </div>

    <div class="player-container mx-20">
      <!-- 注释起不加载，节约流量。。。 -->
      <!-- <vue-core-video-player
        v-if="urlVedio !== ''"
        :autoplay="false"
        :loop="false"
        title="学习视频-课程1"
        logo="/logo_ryx.png"
        :src="urlVedio"
      ></vue-core-video-player> -->
      <!-- src="https://pic.renyaoxing.com/端午小视频-20240523120141764683.mp4?e=1716451334&token=l0Aa0zIddqS9G3X8S-GUFAtsUCKC9HsNATcjnLxJ:wEAB7f5Rv-PL6aVypdR8Z48UyT0=" -->
      <!-- src="https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4" -->
    </div>
  </div>

</template>

<script>
// FullCalendar and plugins
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import { rmFile, getShowUrlByFilename, readFoodData } from '@/api/manage'

export default ({
  components: {
    FullCalendar,
    // VueCoreVideoPlayer
  },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],//要使用的组件需要先引入
        locale: 'zh-cn',
        allDayText: '全天',
        contentHeight: 'auto',
        initialView: 'dayGridMonth',
        selectable: true,
        initialDate: moment().format('YYYY-MM-DD'),
        // editable: true,
        headerToolbar: {//设置头部工具栏显示的按钮
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        },
        buttonText: {//设置头部工具栏的按钮文本
          today: '今天',
          dayGridMonth: '月',
          timeGridWeek: '周',
          timeGridDay: '天',
          list: '日程'
        },
        dayMaxEvents: 2,//设置日期视图中显示的事件个数，
        events: {
          // 统一设置背景色，边框，字体，在下面还可以单独设置
          backgroundColor: '#ffffff1a',
          textColor: '#141414',
          borderColor: '#ff0000',
          events: [
            {
              title: 'Call with Dave',
              start: '2023-12-01',
              end: '2023-12-01',
              // className: 'border-red'
            },
            {
              title: 'Call with Dave2',
              start: '2023-12-02',
              end: '2023-12-02',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'Call with Dave3',
              start: '2023-12-02',
              end: '2023-12-02',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'Call with Dave4',
              start: '2023-12-02',
              end: '2023-12-02',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'Call with Dave5',
              start: '2023-12-02',
              end: '2023-12-02',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'Call with Dave5',
              start: '2023-12-04 12:00:00',
              end: '2023-12-04 15:00:00',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'Call with Dave5',
              start: '2023-12-04 12:20:00',
              end: '2023-12-04 15:50:00',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }, {
              title: 'C顶顶顶顶',
              start: '2023-12-04 09:30:00',
              end: '2023-12-04 10:30:00',
              display: 'list-item' // 设置渲染样式
              // className: 'border-red'
            }
          ]
        }
      },
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'upload',
          field: 'header',
          title: '视频文件',
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            // accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { storageMark: 'qiniu' },
            limit: 1,
            listType: 'picture-card'
          },
        }
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
      headerVal: '',
      urlVedio: '',
      reqNum: 0,
      btnLoading: false,
      btnText: '开始处理数据'
    }
  },
  created () {
    getShowUrlByFilename({ storageMark: 'qiniu', filename: '端午小视频-20240523120141764683.mp4' }).then(res => {
      console.log(res)
      if (res.status !== undefined && res.status === 1) {
        this.urlVedio = res.data
      }
    })
  },
  methods: {
    moment,
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.headerVal = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 200
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过200MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.headerVal }).then((res) => {
        this.spinning = false
        this.headerVal = ''
        if (res.status !== undefined && res.status === 1) {
          this.cformApi.setValue({ header: [] })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    clickDealFood () {
      // this.btnLoading = true
      // this.btnText = '当前已成功返回：' + this.reqNum + ' 个'
      // this.getDataReq()
    },
    getDataReq () {
      readFoodData().then((res) => {
        // console.log(res)
        this.reqNum += 1
        this.btnText = '当前已成功返回：' + this.reqNum + ' 个'
        if (res.status !== undefined && res.status === 1) {
          // 为1就终止
          this.btnLoading = false
        } else {
          // console.log(123)
          // 0则5秒后再次请求
          const timer = setTimeout(() => {
            this.getDataReq()
            clearTimeout(timer)
          }, 5000)
        }
      })
    }
  },
})

</script>

<style lang="scss">
.border-red {
  border: 1px solid red !important;
}
</style>