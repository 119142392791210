<template>
  <div
    class="mx-20 my-20"
    ref="inputSurveyBox"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveyInputSpinning"
    >
      <div v-if="surveyInfo.id !== undefined">
        <!-- <template v-if="surveyInfo.bg_img_url_show !== undefined && surveyInfo.bg_img_url_show !== null && surveyInfo.bg_img_url_show !== ''">
          <img
            :src="surveyInfo.bg_img_url_show"
            class="bg-img-style"
            :style="imgStyle"
          ></template> -->
        <h6 class="font-semibold mx-0 mt-0 mb-30 text-center">{{ surveyInfo.title }}</h6>
        <form-create
          v-model="cformInputApi"
          :rule="cformInputRule"
          :option="cformOption"
        ></form-create>

        <a-row>
          <a-col
            :offset="7"
            :span="17"
          >
            <a-button
              @click="closeSurveyInputDrawer()"
              class="mr-10"
            >关闭</a-button>
            <a-button
              @click="sureSaveInput()"
              type="primary"
              icon="check"
            >确认保存</a-button>
          </a-col>
        </a-row>
      </div>
      <a-empty
        style="margin-top:50%;"
        v-else
        description="暂无相关数据"
      />
    </a-spin>
  </div>
</template>

<script>
import { getSurveyDetailInfo } from '@/api/dms/survey'
import { setRuleByFormArr } from '@/utils/formCreateRuleDeal'
import { checkMobile } from '@/utils/customValidate'
import { sureSubmitSurveyResponse } from '@/api/dms/surveyResponse'

export default ({
  name: 'MobileSurveyInput',
  data () {
    return {
      surveyInputSpinning: false,
      cformInputApi: {},
      cformInputRule: [],
      cformInputRuleNamePhone: [
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'input',
          title: '姓名',
          field: 'contact_name',
          col: {
            span: 11
          },
          wrap: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写姓名' }
        },
        {
          type: 'input',
          title: '联系电话',
          field: 'contact_phone',
          col: {
            span: 11,
            offset: 2
          },
          wrap: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写联系电话' }
        },
        {
          type: 'hidden',
          field: 'source_type',
          value: 1
        },
        {
          type: 'hidden',
          field: 'survey_id'
        }
      ],
      cformOption: {
        form: {
          layout: 'vertical'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      surveyInfo: {},
      parentParams: {},
      imgStyle: {}
    }
  },
  created () {
    if (this.$route.query === undefined || this.$route.query.survey === undefined || this.$route.query.survey < 1) {
      this.$router.push({ path: '/404' })
      return false
    }
    this.parentParams = this.$route.query
    this.loadSurveyData()
  },
  methods: {
    closeSurveyInputDrawer () {
      window.close()
    },
    loadSurveyData () {
      this.surveyInputSpinning = true
      getSurveyDetailInfo({ survey: this.parentParams.survey, ini_response: 1, source_type: 1, check_input: 1 }).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          let inputValArr = { survey_id: this.parentParams.survey }
          // 是否有问卷填写数据
          if (res.data.response !== undefined && res.data.response.id !== undefined) {
            this.parentParams.response = res.data.response.id
            inputValArr.contact_name = res.data.response.contact_name
            inputValArr.contact_phone = res.data.response.contact_phone
            if (res.data.response.misc_survey_response_item !== undefined) {
              res.data.response.misc_survey_response_item.map(rpitv => {
                if (rpitv.question_type === 'checkbox') {
                  inputValArr[rpitv.question_id] = rpitv.answer.split(',')
                }
                else {
                  inputValArr[rpitv.question_id] = rpitv.answer
                }
              })
            }
          }
          // 不是匿名的要填写电话姓名
          if (res.data.is_anonymous < 1) {
            this.cformInputRuleNamePhone[0].validate = [
              { required: true, message: '请确认输入姓名', whitespace: true }
            ]
            this.cformInputRuleNamePhone[1].validate = [
              { required: true, message: '请填写联系电话', whitespace: true },
              { validator: checkMobile }
            ]
          }
          this.surveyInfo = res.data
          const oriRule = res.data.form_rule !== undefined && res.data.form_rule !== null && res.data.form_rule !== '' ? JSON.parse(res.data.form_rule) : []
          // 获取rule数据，设置为实时保存答案
          const dealRule = setRuleByFormArr(oriRule, true, this)
          this.cformInputRule = [].concat(this.cformInputRuleNamePhone, dealRule.ruleArr)
          const timer = setTimeout(() => {
            this.cformInputApi.setValue(inputValArr)
            clearTimeout(timer)
            this.surveyInputSpinning = false
            this.imgStyle = { height: (this.$refs.inputSurveyBox.clientHeight + 40) + 'px' }
          }, 100)
        } else {
          const subMsg = res.msg !== undefined ? res.msg : '获取问卷信息失败！请确认问卷可访问！'
          this.$router.push({ path: '/error', query: { sub: subMsg, title: '请求失败', status: '500' } })
          return false
        }
      })
    },
    sureSaveInput () {
      const that = this
      // 确认保存
      this.cformInputApi.validate((valid2, fail2) => {
        if (valid2) {
          if (this.parentParams.response === undefined || this.parentParams.response < 1) {
            this.$message.error('请确认问卷信息同步填写完成！')
            return false
          }
          this.$confirm({
            title: '问卷确认提交保存之后将无法修改！确认提交？',
            content: false,
            cancelText: '取消',
            okText: '确认提交',
            onOk () {
              let fmData = that.cformInputApi.formData()
              fmData.is_finished = 1
              for (let fmk in fmData) {
                if (['contact_name', 'contact_phone', 'source_type', 'survey_id'].indexOf(fmk) >= 0) {
                  // 不是问卷表单数据则跳过
                  continue
                }
                if (fmData[fmk] === undefined || fmData[fmk] === null || fmData[fmk] === '' || (fmData[fmk].length !== undefined && fmData[fmk].length < 1)) {
                  // 没填写数据的字段删除，后端才会将对应的项目设置为-10
                  delete fmData[fmk]
                  fmData.is_finished = 0
                }
              }
              // 开始保存问卷数据 
              that.surveyInputSpinning = true
              sureSubmitSurveyResponse(Object.assign({}, fmData, { response: that.parentParams.response })).then((res) => {
                if (res.status !== undefined && res.status === 1) {
                  that.$message.success('保存成功！')
                  that.$router.push({ path: '/error', query: { sub: '', title: '保存成功', status: 'success' } })
                  // that.closeSurveyInputDrawer()
                } else {
                  that.$message.error(res.msg || '问卷信息保存失败！')
                }
                that.surveyInputSpinning = false
              })
            },
            onCancel () {

            }
          })
        } else {
          console.log(fail2)
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
.bg-img-style {
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: -1;
  opacity: 0.5;
}
</style>