<template>
  <a-spin
    :tip="tip"
    size="large"
    :spinning="loadingMeasureInfoHistory"
  >
    <a-row
      :gutter="24"
      type="flex"
      align="stretch"
    >
      <template v-for="(hisData) in hisDataArr">

        <!-- Line chart -->
        <a-col
          :span="24"
          :lg="12"
          class="mb-24"
          v-if="hisData.chart_type !== undefined && hisData.chart_type ==='line'"
          :key="hisData.id"
        >
          <a-card
            :bordered="false"
            class="header-solid"
            :bodyStyle="{padding: '0 12px 8px 3px'}"
          >
            <template #title>
              <h6>{{ hisData.item_name}}（{{hisData.unit_name}}）</h6>
            </template>
            <HistoryChartLine2 :chartData="hisData.his_val"></HistoryChartLine2>
          </a-card>
        </a-col>
        <!-- / Line chart -->

        <!-- Line chart with gradient -->
        <a-col
          :span="24"
          :lg="12"
          class="mb-24"
          v-if="hisData.chart_type !== undefined && hisData.chart_type ==='lineGradient'"
          :key="hisData.id"
        >
          <a-card
            :bordered="false"
            class="header-solid"
            :bodyStyle="{padding: '0 12px 8px 3px'}"
          >
            <template #title>
              <h6>{{ hisData.item_name}}（{{hisData.unit_name}}）</h6>
            </template>
            <HistoryChartLineGradient :chartData="hisData.his_val"></HistoryChartLineGradient>
          </a-card>
        </a-col>
        <!-- / Line chart with gradient -->

        <!-- Bar chart -->
        <a-col
          :span="24"
          :lg="12"
          class="mb-24"
          v-if="hisData.chart_type !== undefined && hisData.chart_type ==='bar'"
          :key="hisData.id"
        >
          <a-card
            :bordered="false"
            class="header-solid"
            :bodyStyle="{padding: '0 12px 8px 3px'}"
          >
            <template #title>
              <h6>{{ hisData.item_name}}（{{hisData.unit_name}}）</h6>
            </template>
            <HistoryChartBar2 :chartData="hisData.his_val"></HistoryChartBar2>
          </a-card>
        </a-col>
        <!-- / Bar chart -->

      </template>
    </a-row>
  </a-spin>
</template>

<script>
import { getPatientAllMeasureInfoHistory } from '@/api/dms/patient'
import HistoryChartLine2 from './HistoryChartLine2'
import HistoryChartLineGradient from './HistoryChartLineGradient'
import HistoryChartBar2 from './HistoryChartBar2'

export default ({
  name: 'CardMeasureInfoHistory',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  components: {
    HistoryChartLine2,
    HistoryChartLineGradient,
    HistoryChartBar2,
  },
  data () {
    return {
      tip: '刷新中...',
      loadingMeasureInfoHistory: false,
      hisDataArr: []
    }
  },
  created () {
    console.log(this.parentParams)
    this.refreshMeasureInfoHistory()
  },
  methods: {
    refreshMeasureInfoHistory () {
      this.loadingMeasureInfoHistory = true
      this.hisDataArr = []
      getPatientAllMeasureInfoHistory({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.hisDataArr = res.data
        }
        this.loadingMeasureInfoHistory = false
      })
    }
  }
})

</script>