import { asyncRouterMap } from '@/config/router.config'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

const allWebViews = require.context('@/views', true, /\.vue$/)
const allWebViewRouter = allWebViews.keys().map(key => {
  return '@/views' + key.slice(1)
})

// 获取的菜单数据处理渲染前端view
function filterRequestRouter (menus) {
  menus.map(item => {
    const pathArr = item.path.split('/')
    if (pathArr.length > 2) {
      // 文件夹名小写开头的驼峰
      const file1 = pathArr[1].slice(0, 1).toLowerCase() + pathArr[1].slice(1)
      // 文件名大写开头的驼峰
      const file3 = pathArr[2].slice(0, 1).toUpperCase() + pathArr[2].slice(1)
      const cuCpt = '@/views/' + file1 + '/' + pathArr[2] + '/' + file3
      if (allWebViewRouter.indexOf(cuCpt + '.vue') >= 0) {
        item.component = () => import('@/views/' + file1 + '/' + pathArr[2] + '/' + file3)
      } else {
        item.redirect = '/404'
      }
    }
    if (item.children !== undefined && item.children.length > 0) {
      // 有子菜单时需要指定路由组件才能走导航条的路由，页面才能显示，否则子菜单url路由都没问题但是页面是空白的！！！
      if (item.hideChildrenInMenu === undefined && item.hideChildrenInMenu !== true) {
        item.component = RouteView
      }
      filterRequestRouter(item.children)
    }
  })
  return menus
}

const permission = {
  state: {
    addRouters: [],
    permissionList: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
    },
    SET_PERMISSION_LIST: (state, permissionList) => {
      state.permissionList = permissionList
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const { roles } = data
        const requestRouters = filterRequestRouter(roles.menus)
        // 把前端本地的作为基础的共有权限
        const accessedRouters = asyncRouterMap
        accessedRouters[0]['children'] = [...accessedRouters[0]['children'], ...requestRouters]
        commit('SET_ROUTERS', accessedRouters)
        commit('SET_PERMISSION_LIST', roles.permissions)
        resolve()
      })
    }
  }
}

export default permission
