<template>
  <a-drawer
    width="600"
    placement="right"
    :closable="true"
    :visible="surveyDrawerVisibleCu"
    title="问卷调查列表"
    @close="closeSurveyDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveySpinning"
    >
      <a-row :gutter="24">
        <a-col
          :md="20"
          :sm="24"
        >
          <span>问卷标签：</span>
          <a-select
            showSearch
            v-model="cuChoosetag"
            mode="multiple"
            :maxTagCount="5"
            placeholder="全部"
            :options="patTagsArrForSelect"
            :allowClear="true"
            style="width: calc(100% - 70px);"
          >
          </a-select>
        </a-col>
        <a-col
          :md="4"
          :sm="24"
        >
          <a-button
            type="danger"
            icon="search"
            @click="clickLoadDataByCond"
          >查询</a-button>
        </a-col>
      </a-row>
      <a-divider />

      <div v-if="surveyListArr.length > 0">
        <div
          v-for="(itv,idx) in surveyListArr"
          :key="idx"
        >
          <a-row>
            <a-col :span="19">
              <a-row>
                问卷标题： {{ itv.title }}
              </a-row>
              <a-row>
                <a-col :span="14">
                  问卷编码： {{ itv.uid }}
                </a-col>
                <a-col :span="10">
                  进展状态：
                  <span
                    :class="itv.process_status !== '10' ? 'font-error' : 'font-success'"
                    class="font-weight"
                  >
                    {{ 'survey.process_status' | showText(itv.process_status.toString()) }}
                  </span>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="14">
                  已填数量： {{ itv.response_count }}
                </a-col>
                <a-col :span="10">
                  问卷总数： {{ itv.total_count }}
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="14">
                  结束时间： {{ itv.end_time }}
                </a-col>
                <a-col :span="10">
                  是否匿名： {{ itv.is_anonymous >= 1 ? '是' : '否' }}
                </a-col>
              </a-row>
            </a-col>
            <a-col
              :span="5"
              class="text-right"
            >
              <template v-if="parentParams.surveyHasIds !== undefined && parentParams.surveyHasIds.indexOf(itv.id) >= 0">
                <a-row>
                  <a-button
                    size="small"
                    disabled
                  >已填写</a-button>
                </a-row>
              </template>
              <template v-else>
                <a-row>
                  <a-button
                    type="primary"
                    icon="form"
                    size="small"
                    @click="clickInputSurvey(itv)"
                  >填写问卷</a-button>
                </a-row>
                <a-row class="mt-10">
                  <a-button
                    type="primary"
                    icon="qrcode"
                    size="small"
                    @click="clickShareQr(itv)"
                  >问卷二维码</a-button>
                </a-row></template>
            </a-col>
            <a-col
              v-if="itv.matchTag !== undefined && itv.matchTag.length > 0"
              :span="24"
            >
              匹配标签：
              <a-tag
                color="orange"
                v-for="(tag,ti) in itv.matchTag"
                :key="ti"
              >
                {{ tag }}
              </a-tag>
            </a-col>
          </a-row>

          <div
            @click="clickLoadMore"
            v-if="idx >= surveyListArr.length - 1"
            class="more-div"
          >
            <a-divider>{{ page > lastPage ? '下面没有啦~~' : '点击加载更多'}}</a-divider>
          </div>
          <a-divider v-else />
        </div>
      </div>
      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
      />
    </a-spin>

    <!-- 问卷填写的抽屉窗口 -->
    <survey-input
      ref="surveyInputDrawer"
      @complete="surveyInputOk"
    />

    <!-- 问卷分享二维码弹窗 -->
    <survey-share
      :parentParams="surveyShareParam"
      :surveyShareVisible="surveyShareVisible"
      @surveyShareVisibleChange="surveyShareVisibleChange"
    />
  </a-drawer>
</template>

<script>
import { listSurveyData } from '@/api/dms/survey'
import SurveyInput from './SurveyInput'
import SurveyShare from './SurveyShare'

export default ({
  name: 'SurveyList',
  components: { SurveyInput, SurveyShare },
  data () {
    return {
      surveyDrawerVisibleCu: false,
      surveySpinning: false,
      surveyListArr: [],
      patTagsArrForMatch: [],
      patTagsArrForSelect: [],
      pageSize: 10,
      page: 1,
      lastPage: 1,
      surveyShareParam: {},
      surveyShareVisible: false,
      parentParams: {},
      cuChoosetag: []
    }
  },
  created () {

  },
  methods: {
    closeSurveyDrawer () {
      this.surveyDrawerVisibleCu = false
      this.$emit('complete')
    },
    showDrawer (pparms) {
      this.surveyDrawerVisibleCu = true
      this.surveySpinning = true
      this.surveyListArr = []
      this.parentParams = pparms
      if (this.parentParams.patient_tags !== undefined && this.parentParams.patient_tags.length > 0) {
        this.parentParams.patient_tags.map((tv) => {
          this.patTagsArrForMatch.push(tv.tag_id.toString())
          this.patTagsArrForSelect.push({ label: tv.tag_text, value: tv.tag_id.toString() })
        })
      }
      console.log(this.parentParams, this.patTagsArrForMatch, this.patTagsArrForSelect)
      this.page = 1
      this.lastPage = 1
      // 获取列表信息
      this.loadServiceRecordData()
    },
    loadServiceRecordData () {
      // only_open只获取在进行中的问卷
      listSurveyData({ enterprise: this.$store.getters.userInfo.user.target_enterprise, create_user: this.$store.getters.userInfo.user.id, page: this.page, pageSize: this.pageSize, status: 10, only_open: 1, join: ['surveyTag'], tag: this.cuChoosetag }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          this.surveyListArr = res.data.map(dv => {
            dv.matchTag = this.$options.filters.showMatchingTagText(dv.misc_survey_tag, 'tag_id', 'tag_text', this.patTagsArrForMatch)
            return dv
          })
          console.log(this.surveyListArr)
          this.lastPage = res.last_page
          this.page++
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        }
        this.surveySpinning = false
      })
    },
    clickLoadMore () {
      if (this.page > this.lastPage) {
        this.$message.error('下面没有数据啦~~！')
        return false
      }
      this.surveySpinning = true
      this.loadServiceRecordData()
    },
    surveyInputOk () {
      this.surveySpinning = true
      this.surveyListArr = []
      this.page = 1
      this.lastPage = 1
      // 获取列表信息
      this.loadServiceRecordData()
    },
    surveyShareVisibleChange (v) {
      this.surveyShareVisible = v
    },
    clickInputSurvey (raw) {
      this.$refs.surveyInputDrawer.showDrawer(Object.assign({}, this.parentParams, { survey: raw.id }))
    },
    clickShareQr (raw) {
      this.surveyShareParam = raw
      this.surveyShareVisible = true
    },
    clickLoadDataByCond () {
      this.surveySpinning = true
      this.surveyListArr = []
      this.page = 1
      this.lastPage = 1
      // 获取列表信息
      this.loadServiceRecordData()
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>