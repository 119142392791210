import request from '@/utils/request'

const apiUrls = {
    selectListDietaryPattern: '/HealthDietaryPatterns/selectListDietaryPattern',
    getDietaryPatternInfo: '/HealthDietaryPatterns/getDietaryPatternInfo',
    listDietaryPattern: '/HealthDietaryPatterns/listDietaryPattern',
    saveDietaryPatternInfo: '/HealthDietaryPatterns/saveDietaryPatternInfo',
    savePatternItemInfo: '/HealthDietaryPatterns/savePatternItemInfo'
}

export function selectListDietaryPattern (parameter) {
    return request({
        url: apiUrls.selectListDietaryPattern,
        method: 'get',
        params: parameter
    })
}

export function getDietaryPatternInfo (parameter) {
    return request({
        url: apiUrls.getDietaryPatternInfo,
        method: 'get',
        params: parameter
    })
}

export function listDietaryPattern (parameter) {
    return request({
        url: apiUrls.listDietaryPattern,
        method: 'get',
        params: parameter
    })
}

export function saveDietaryPatternInfo (data) {
    return request({
        url: apiUrls.saveDietaryPatternInfo,
        method: 'post',
        data
    })
}

export function savePatternItemInfo (data) {
    return request({
        url: apiUrls.savePatternItemInfo,
        method: 'post',
        data
    })
}

