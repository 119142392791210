<template>
  <a-drawer
    width="500"
    placement="right"
    :closable="true"
    :visible="prescriptionDrawerVisibleCu"
    title="营养处方历史记录"
    @close="closePrescriptionDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="prescriptionSpinning"
    >
      <div v-if="prescriptionHisList.length > 0">
        <div
          v-for="(prtItem,pidx) in prescriptionHisList"
          :key="pidx"
        >
          <!-- :title="'营养处方 '+prtItem.create_time" -->
          <a-descriptions :column="3">
            <div slot="title">
              {{'营养处方 '+prtItem.create_time}}
              <a-button
                class="float-right"
                size="small"
                type="danger"
                icon="printer"
                @click="clickPrintBtn(prtItem)"
              >打印处方</a-button>
            </div>
            <a-descriptions-item
              :span="3"
              label="营养评定"
            >{{ prtItem.assessment }}</a-descriptions-item>
            <a-descriptions-item label="干预方法">{{ 'prescription_nutrition.intervention_method' | showText(prtItem.intervention_method.toString()) }}</a-descriptions-item>
            <a-descriptions-item
              :span="2"
              label="推荐方案"
            >{{ prtItem.recommendation }}</a-descriptions-item>

            <a-descriptions-item label="热量摄入建议">{{ prtItem.calorie > 0 ? prtItem.calorie + 'Kcal' : '' }}</a-descriptions-item>
            <a-descriptions-item label="碳水摄入建议">{{ prtItem.carbohydrates > 0 ? prtItem.carbohydrates +'%' : '' }}</a-descriptions-item>
            <a-descriptions-item label="蛋白质摄入">{{ prtItem.protein > 0 ? prtItem.protein +'%' : '' }}</a-descriptions-item>
            <a-descriptions-item label="脂肪摄入">{{ prtItem.fat > 0 ? prtItem.fat +'%' : '' }}</a-descriptions-item>

            <a-descriptions-item
              :span="2"
              label="营养补充剂"
            >{{ prtItem.supplement }}</a-descriptions-item>

            <template v-if="prtItem.recipe_id !== undefined && prtItem.recipe_id > 0">
              <a-descriptions-item
                :span="3"
                label=""
              >- - - - 营养食谱 - - - -</a-descriptions-item>
              <a-descriptions-item
                :span="2"
                label="早餐"
              >{{ prtItem.breakfast }}</a-descriptions-item>
              <a-descriptions-item label="早加餐">{{ prtItem.snack_morning }}</a-descriptions-item>

              <a-descriptions-item
                :span="2"
                label="午餐"
              >{{ prtItem.lunch }}</a-descriptions-item>
              <a-descriptions-item label="午加餐">{{ prtItem.snack_afternoon }}</a-descriptions-item>

              <a-descriptions-item
                :span="3"
                label="晚餐"
              >{{ prtItem.supper }}</a-descriptions-item>
            </template>

            <template v-if="prtItem.life_id !== undefined && prtItem.life_id > 0">
              <a-descriptions-item
                :span="3"
                label=""
              >- - - - 生活处方 - - - -</a-descriptions-item>

              <a-descriptions-item
                :span="3"
                label="生活方式"
              >{{ prtItem.life_plan }}</a-descriptions-item>

              <a-descriptions-item
                :span="3"
                label="运动计划"
              >{{ prtItem.sport_plan }}</a-descriptions-item>
            </template>

            <template v-if="prtItem.sport_id !== undefined && prtItem.sport_id > 0">
              <a-descriptions-item
                :span="3"
                label=""
              >- - - - 运动处方 - - - -</a-descriptions-item>
              <a-descriptions-item label="运动方式">{{ 'patient.activity_type' | showText(prtItem.sport_type.toString()) }}</a-descriptions-item>
              <a-descriptions-item
                :span="2"
                label="运动时间"
              >{{ prtItem.duration }}</a-descriptions-item>

              <a-descriptions-item label="运动频率">{{ prtItem.frequency }}</a-descriptions-item>
              <a-descriptions-item
                :span="2"
                label="运动举例"
              >{{ prtItem.example }}</a-descriptions-item>

              <a-descriptions-item
                :span="3"
                label="运动方案"
              >{{ prtItem.plan_content }}</a-descriptions-item>
            </template>
          </a-descriptions>
          <a-divider />
        </div>
      </div>
      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关营养处方历史数据"
      />

      <div v-if="cuPrtObj.id !== undefined && cuPrtObj.id > 0">
        <div id="printDiv">
          <div class="labMainDivPage1">
            <div class="labmainTitle">
              <div class="labmainTitleText">
                四川大学华西第二医院<br>
                营养处方
              </div>
              <div class="labmainTitleBarno">
                处方编号:
                <div class="barListDiv">
                  {{ cuPrtObj.prescription_no }}
                </div>
              </div>
            </div>

            <div class="divClientInfo">
              <a-descriptions
                :title="null"
                v-if="patientInfo.id !== undefined"
                :bordered="true"
              >
                <a-descriptions-item label="姓名">{{ patientInfo.name }}</a-descriptions-item>
                <a-descriptions-item label="性别">{{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}</a-descriptions-item>
                <a-descriptions-item label="出生日期">{{ patientInfo.birth_date }}</a-descriptions-item>

                <a-descriptions-item label="编号">{{ patientInfo.patient_no }}</a-descriptions-item>
                <a-descriptions-item label="联系电话">{{ patientInfo.contact }}</a-descriptions-item>
                <a-descriptions-item label="开具时间">{{ moment(cuPrtObj.create_time).format('YYYY-MM-DD HH:mm:ss') }}</a-descriptions-item>
              </a-descriptions>

              <a-descriptions
                title="身体成分"
                v-if="bodyInfo.length > 0"
                :bordered="true"
              >
                <template v-for="(bv,bi) in bodyInfo">
                  <a-descriptions-item
                    :key="bi"
                    :label="(bv.unit_name !== undefined && bv.unit_name !== null && bv.unit_name !== '') ? bv.item_name + '(' + bv.unit_name + ')' : bv.item_name"
                  >{{ bv.result_val }}</a-descriptions-item>
                </template>
              </a-descriptions>

              <a-descriptions
                title="营养处方"
                :column="4"
                :bordered="true"
              >
                <a-descriptions-item
                  :span="3"
                  label="营养评定"
                >{{ cuPrtObj.assessment }}</a-descriptions-item>
                <a-descriptions-item
                  :span="1"
                  label="干预方法"
                >{{ 'prescription_nutrition.intervention_method' | showText(cuPrtObj.intervention_method.toString()) }}</a-descriptions-item>

                <a-descriptions-item
                  :span="4"
                  label="推荐方案"
                >{{ cuPrtObj.recommendation }}</a-descriptions-item>

                <a-descriptions-item label="热量摄入建议">{{ cuPrtObj.calorie > 0 ? cuPrtObj.calorie + 'Kcal' : '' }}</a-descriptions-item>
                <a-descriptions-item label="碳水摄入建议">{{ cuPrtObj.carbohydrates > 0 ? cuPrtObj.carbohydrates +'%' : '' }}</a-descriptions-item>
                <a-descriptions-item label="蛋白质摄入">{{ cuPrtObj.protein > 0 ? cuPrtObj.protein +'%' : '' }}</a-descriptions-item>
                <a-descriptions-item label="脂肪摄入">{{ cuPrtObj.fat > 0 ? cuPrtObj.fat +'%' : '' }}</a-descriptions-item>

                <a-descriptions-item
                  :span="4"
                  label="营养补充剂"
                >{{ cuPrtObj.supplement }}</a-descriptions-item>
              </a-descriptions>

              <template v-if="cuPrtObj.recipe_id !== undefined && cuPrtObj.recipe_id > 0">
                <a-descriptions
                  title="营养食谱"
                  :column="2"
                  :bordered="true"
                >
                  <a-descriptions-item
                    :span="2"
                    label="早餐"
                  >{{ cuPrtObj.breakfast }}</a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    label="早加餐"
                  >{{ cuPrtObj.snack_morning }}</a-descriptions-item>

                  <a-descriptions-item
                    :span="2"
                    label="午餐"
                  >{{ cuPrtObj.lunch }}</a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    label="午加餐"
                  >{{ cuPrtObj.snack_afternoon }}</a-descriptions-item>

                  <a-descriptions-item
                    :span="2"
                    label="晚餐"
                  >{{ cuPrtObj.supper }}</a-descriptions-item>
                </a-descriptions>
              </template>

              <template v-if="cuPrtObj.life_id !== undefined && cuPrtObj.life_id > 0">
                <a-descriptions
                  title="生活处方"
                  :column="2"
                  :bordered="true"
                >
                  <a-descriptions-item
                    :span="2"
                    label="生活方式"
                  >{{ cuPrtObj.life_plan }}</a-descriptions-item>

                  <a-descriptions-item
                    :span="2"
                    label="运动计划"
                  >{{ cuPrtObj.sport_plan }}</a-descriptions-item>
                </a-descriptions>
              </template>

              <template v-if="cuPrtObj.sport_id !== undefined && cuPrtObj.sport_id > 0">
                <a-descriptions
                  title="运动处方"
                  :column="2"
                  :bordered="true"
                >
                  <a-descriptions-item
                    :span="2"
                    label="运动方式"
                  >{{ 'patient.activity_type' | showText(cuPrtObj.sport_type.toString()) }}</a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    label="运动时间"
                  >{{ cuPrtObj.duration }}</a-descriptions-item>

                  <a-descriptions-item
                    :span="2"
                    label="运动频率"
                  >{{ cuPrtObj.frequency }}</a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    label="运动举例"
                  >{{ cuPrtObj.example }}</a-descriptions-item>

                  <a-descriptions-item
                    :span="2"
                    label="运动方案"
                  >{{ cuPrtObj.plan_content }}</a-descriptions-item>
                </a-descriptions>
              </template>
            </div>

            <div class="divLabFooter">
              <table class="tableFooter fontlabBase">
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      class="tdFooterTopBorder"
                    >
                      <span class="tdFooterTitle">声明：</span>此处方只对本人本次就诊负责。如有疑问，请于当天在就诊营养师处咨询清楚。
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      class="tdFooterTopBorder"
                    >
                      <span class="tdFooterTitle">备注：</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="tableFooter fontlabBase">
                <tbody>
                  <tr>
                    <td class="tdFooterTopBorder tr1-1">
                      <span class="tdFooterTitle">营养师：</span>
                      <img
                        height="40px"
                        v-if="cuPrtObj.create_user_img !== undefined && cuPrtObj.create_user_img !== null && cuPrtObj.create_user_img !== ''"
                        :src="cuPrtObj.create_user_img"
                      >
                    </td>
                    <td class="tdFooterTopBorder tr1-2">
                      <span class="tdFooterTitle">审核者：</span>
                      <img
                        height="40px"
                        v-if="cuPrtObj.release_user_img !== undefined && cuPrtObj.release_user_img !== null && cuPrtObj.release_user_img !== ''"
                        :src="cuPrtObj.release_user_img"
                      >
                    </td>
                    <td class="tdFooterTopBorder rightUserTd tr1-3">
                      <span class="tdFooterTitle">打印时间：{{ moment().format('YYYY-MM-DD HH:mm') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="tableFooter fontlabBase">
                <tbody>
                  <tr>
                    <td class="tr2-1">
                      地址：成都市天府新区兴隆街道湖畔路南段49号
                    </td>
                    <td class="tr2-2">
                      联系电话：400-026-1699
                    </td>
                    <td class="tr2-3 rightUserTd">
                      第{{ 1 }}页,共{{ 1 }}页
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-drawer>
</template>

<script>
import { getPatientNutritionHisList, getPatientNewestBodyInfo, getPatientInfo } from '@/api/dms/patient'
import moment from 'moment'
import printJS from 'print-js'

export default ({
  // 客户营养处方历史信息 抽屉显示
  name: 'PrescriptionDrawer',
  props: {
    prescriptionDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    prescriptionDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.prescriptionDrawerVisibleCu = this.prescriptionDrawerVisible
        if (this.prescriptionDrawerVisible) {
          this.prescriptionSpinning = true
          this.prescriptionHisList = []
          getPatientNutritionHisList({ enterprise: this.$store.getters.userInfo.user.target_enterprise, patient: this.parentParams.patient }).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              console.log(res)
              this.prescriptionHisList = res.data
            } else {
              this.$message.error(res.msg || '获取失败！请刷新后重试！')
            }
            this.prescriptionSpinning = false
          })
          // 获取一次客户信息
          if (this.patientInfo.id === undefined) {
            getPatientInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
              if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.parentParams.patient) {
                this.patientInfo = res.data
                getPatientNewestBodyInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
                  if (res2.status !== undefined && res2.status === 1) {
                    this.bodyInfo = res2.data
                  }
                })
              } else {
                this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
                return this.$router.go(-1)
              }
            })
          }
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      prescriptionDrawerVisibleCu: false,
      prescriptionSpinning: false,
      prescriptionHisList: [],
      cuPrtObj: {},
      patientInfo: {},
      bodyInfo: {}
    }
  },
  created () {
  },
  methods: {
    moment,
    closePrescriptionDrawer () {
      this.prescriptionDrawerVisibleCu = false
      this.$emit('prescriptionDrawerVisibleChange', false)
    },
    clickPrintBtn (itv) {
      this.cuPrtObj = itv
      this.prescriptionSpinning = true
      this.$nextTick(() => {
        printJS({
          printable: 'printDiv', type: 'html',
          targetStyles: ['*'], //处理css样式 *为处理所有，也可以指定})
        })
        const that = this
        const timer = setTimeout(() => {
          that.cuPrtObj = {}
          that.prescriptionSpinning = false
          clearTimeout(timer)
        }, 1000)
      })
    }
  }
})

</script>

<style lang="scss" scoped>
.float-right {
  float: right;
}
.labMainDivPage1 {
  width: 100%;
  height: 100%;
  position: relative;
}

.labmainTitle {
  // padding: 20px 50px 20px 50px;
  width: 100%;
  // height: 170px;
}

.labmainTitleText {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  vertical-align: top;
  // margin-top: 20px;
}

.labmainTitleBarno {
  // float: right;
  text-align: right;
  // width: 35%;
  font-size: 18px;
  vertical-align: top;
  font-weight: 700;
}

.divClientInfo {
  width: 100%;
  margin-top: 10px;
  // padding: 10px 50px 0 50px;
}

.fontlabBase {
  font-size: 18px;
}

.divLabFooter {
  width: 100%;
  // padding: 0 50px;
  // position: absolute;
  height: 300px;
  // bottom: 0px;
  margin-top: 20px;
}

.tableFooter {
  width: 100%;
}

.tableFooter td {
  padding: 8px 5px;
}

.tableFooter .tr1-1 {
  width: 35%;
}

.tableFooter .tr1-2 {
  width: 30%;
}

.tableFooter .tr1-3 {
  width: 35%;
}
.tableFooter .tr2-1 {
  width: 50%;
}

.tableFooter .tr2-2 {
  width: 35%;
}

.tableFooter .tr2-3 {
  width: 15%;
}

.tdFooterTopBorder {
  border-top: 1px solid #000000;
}
.tdFooterTitle {
  font-weight: bold;
  // margin-right: 10px;
}
.rightUserTd {
  text-align: right;
  // padding-right: 5.3% !important;
}
.barListDiv {
  text-align: right;
  display: inline-flex;
}
#printDiv {
  width: 1240px;
  height: 1754px;
  /deep/ .ant-descriptions .ant-descriptions-row > th,
  /deep/ .ant-descriptions .ant-descriptions-row > td {
    padding: 10px !important;
  }
  /deep/ .ant-descriptions .ant-descriptions-title {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}
</style>