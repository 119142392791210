<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="全部"
                      :options="'enterprise.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入企业名称、联系人搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="7"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.enterpriseListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/Enterprises/addEnterprise'"
                      :hidden="'/Enterprises/addEnterprise' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增企业</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="enterpriseListTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'enterprise.status' | showText(text)"
            />
          </span>
          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a
                :hidden="'/Enterprises/editEnterprise' | isShowButton"
                @click="clickEdit(record)"
              >编辑</a>
              <a-divider
                type="vertical"
                :hidden="'/Enterprises/editEnterprise' | isShowSeparator(['/Enterprises/editEnterprise'])"
              />
              <span :hidden="'/Enterprises/editEnterprise' | isShowButton">
                <a-popconfirm
                  v-if="record.status.toString() === '2'"
                  title="确定将当前企业状态设为禁用？"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="clickChange(record,3)"
                >
                  <a class="font-error">禁用</a>
                </a-popconfirm>
                <a-popconfirm
                  v-else
                  title="确定将当前企业状态设为正常？"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="clickChange(record,2)"
                >
                  <a class="font-success">启用</a>
                </a-popconfirm>
              </span>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEnterprise, changeEnterpriseStatus } from '@/api/dms/enterprise'
export default {
  name: 'ListEnterprise',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 90,
          dataIndex: 'id'
        },
        {
          title: '企业名称',
          ellipsis: true,
          dataIndex: 'enterprise_name'
        },
        {
          title: '工商注册号',
          ellipsis: true,
          dataIndex: 'business_Code'
        },
        {
          title: '企业电话',
          ellipsis: true,
          dataIndex: 'business_phone'
        },
        {
          title: '企业联系人',
          ellipsis: true,
          dataIndex: 'contact'
        },
        {
          title: '联系人电话',
          ellipsis: true,
          dataIndex: 'contact_phone'
        },
        {
          title: '地址详情',
          ellipsis: true,
          dataIndex: 'detail_address'
        },
        {
          title: '主管理员',
          ellipsis: true,
          width: 90,
          dataIndex: 'master_name2'
        },
        {
          title: '状态',
          width: 90,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建时间',
          width: 145,
          ellipsis: true,
          dataIndex: 'create_date'
        },
        {
          title: '更新时间',
          width: 145,
          ellipsis: true,
          dataIndex: 'update_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 95,
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ main: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return listEnterprise(requestParameters)
          .then(res => {
            return res
          })
      }
    }
  },
  created () {

  },
  filters: {
    statusTypeFilter (type) {
      if (type.toString() === '2') {
        return 'success'
      }
      if (type.toString() === '3') {
        return 'error'
      }
      return 'default'
    }
  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('Enterprises.editEnterprise', JSON.stringify(record))
      this.$router.push({ path: '/Enterprises/editEnterprise' })
    },
    clickChange (record, status) {
      this.spinning = true
      changeEnterpriseStatus({ id: record.id, status }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg || '操作失败！请刷新后重试！')
        }
        this.spinning = false
        this.$refs.enterpriseListTable.refresh(true)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>