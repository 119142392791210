<template>
  <a-spin
    tip="处理中..."
    size="small"
    :spinning="spinning"
  >
    <a-row
      :gutter="24"
      type="flex"
      class="layout-dashboard"
      align="stretch"
      v-if="menuType === 'daily'"
    >
      <a-col
        :span="24"
        class="mb-10"
      >
        <a-card
          :bordered="false"
          class="card-categories header-solid h-full"
          :bodyStyle="{paddingTop: 0, paddingBottom: '12px' }"
        >
          <a-row
            type="flex"
            align="middle"
          >
            <a-col :span="4">
              <a-avatar
                :size="100"
                shape="square"
                :src="dailyFoodMenuDetail.img_show !== '' ? dailyFoodMenuDetail.img_show : ''"
              />
            </a-col>
            <a-col :span="20">
              <h6 class="font-semibold m-0">{{ dailyFoodMenuDetail.menu_name}}</h6>
              <p class="font-semibold">{{ dailyFoodMenuDetail.menu_intro}}</p>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>

    <a-row
      :gutter="24"
      type="flex"
      class="layout-dashboard"
      align="stretch"
      v-if="menuType === 'daily'"
    >
      <a-col
        :span="24"
        class="mb-10"
      >
        <a-card
          :bordered="false"
          class="card-categories header-solid h-full"
          :bodyStyle="{paddingBottom: '12px' }"
        >
          <a-tag
            color="green"
            v-for="(item,idx) in dailyFoodMenuDetail.daily_food_menu_tag"
            :key="idx"
          >
            {{ item.tag_text }}
          </a-tag>
        </a-card>
      </a-col>
    </a-row>

    <a-row
      :gutter="24"
      type="flex"
      class="layout-dashboard"
      align="stretch"
    >
      <template v-for="(etype) in eatingType">
        <a-col
          :span="24"
          class="mb-10"
          v-if="recipeArr[etype.value] !== undefined"
          :key="etype.value"
        >
          <a-card
            :bordered="false"
            class="card-categories header-solid h-full"
            :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
          >
            <template #title>
              <h6 class="font-semibold m-0"> {{ etype.label }}</h6>
            </template>
            <a-row>

              <a-table
                :columns="columns"
                :data-source="recipeArr[etype.value]"
                :pagination="false"
                rowKey="id"
                size="small"
              >
                <div
                  slot="cover"
                  slot-scope="text, record"
                  style="max-width:25px;height:25px;cursor:pointer;"
                >
                  <img
                    v-if="record.cover_show !== undefined && record.cover_show !== ''"
                    title="点击查看原图"
                    style="width: 100%"
                    :src="record.cover_show"
                    @click="handleRecipePreview(record.cover_show)"
                  />
                </div>

                <span
                  slot="action"
                  slot-scope="text, record"
                >
                  <template>
                    <a @click="clickView(record)">详情</a>
                  </template>
                </span>
              </a-table>
            </a-row>
          </a-card>
        </a-col>
      </template>
    </a-row>

    <a-modal
      :visible="previewRecipeVisible"
      :footer="null"
      @cancel="handleRecipeCancel"
      :zIndex="4000"
    >
      <img
        alt="货品主图"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>

    <!-- 详情弹窗组件 -->
    <FoodRecipeDetailModal
      :recipeDetail="recipeDetail"
      :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
      @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
    />
  </a-spin>
</template>

<script>
import FoodRecipeDetailModal from '../FoodRecipes/FoodRecipeDetailModal'

export default {
  name: 'DailyFoodMenuViewTable',
  components: {
    FoodRecipeDetailModal
  },
  data () {
    return {
      spinning: false,
      eatingType: this.$options.filters.selectOptionsData('health.eating_type'),
      recipeArr: [],
      columns: [
        {
          title: '图片',
          ellipsis: true,
          width: 60,
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '显示名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: false,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 80,
          dataIndex: 'eating_count',
          ellipsis: false,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '热量',
          width: 80,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '操作',
          ellipsis: true,
          width: 60,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      previewRecipeVisible: false,
      previewImg: '',
      recipeDetail: {},
      foodRecipeDetailModalVisible: false,


    }
  },
  props: {
    dailyFoodMenuDetail: {
      type: Object,
      default: null
    },
    menuType: {
      type: String,
      default: 'daily'
    }
  },
  watch: {
    dailyFoodMenuDetail: {
      handler (newVal, oldVal) {
        this.refreshTable()
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  mounted () {
    this.refreshTable()

  },
  unmounted: function () {

  },
  methods: {
    refreshTable () {
      console.log(this.dailyFoodMenuDetail, this.menuType)
      this.spinning = true
      this.recipeArr = []
      if (this.menuType === 'daily' && this.dailyFoodMenuDetail.daily_food_menu_recipe !== undefined) {
        this.dailyFoodMenuDetail.daily_food_menu_recipe.forEach(element => {
          console.log(element)
          if (this.recipeArr[element.eating_type] == undefined) {
            this.recipeArr[element.eating_type] = []
          }
          this.recipeArr[element.eating_type].push(element)

        })
      }

      if (this.menuType === 'patientDaily' && this.dailyFoodMenuDetail.patient_food_menu_day_recipe !== undefined) {
        this.dailyFoodMenuDetail.patient_food_menu_day_recipe.forEach(element => {
          console.log(element)
          if (this.recipeArr[element.eating_type] == undefined) {
            this.recipeArr[element.eating_type] = []
          }
          this.recipeArr[element.eating_type].push(element)

        })
      }

      this.spinning = false
    },
    handleRecipePreview (img) {
      this.previewImg = img
      this.previewRecipeVisible = true
    },
    handleRecipeCancel () {
      this.previewRecipeVisible = false
    },
    clickView (record) {
      console.log(record)
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    foodRecipeDetailModalVisibleChange (v) {
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
