<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="addShopGoodsForm"
        >
          <div class="title">商品基础信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示品名"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input
                  placeholder="请填写展示品名（列表&详情页面展示）"
                  v-decorator="[
                    'goods_name',
                    { rules: [{ required: true, message: '请填写展示品名', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="商品类型"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="[
                    'goods_type',
                    { rules: [{ required: true, message: '请选择商品类型' }] },
                  ]"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择商品类型"
                  allow-clear
                  :filterTreeNode="filterTreeNode"
                  :tree-data="typeListData"
                  treeDataSimpleMode
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="展示标题"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  placeholder="请填写展示标题（列表页面展示）"
                  v-decorator="[
                    'title',
                    { rules: [{ required: true, message: '请填写展示标题', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="详细描述"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-input
                  placeholder="请填写详细描述（详情页面展示）"
                  v-decorator="[
                    'sub_title',
                    { rules: [{ required: true, message: '请填写详细描述', whitespace: true }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="实体/虚拟商品"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择实体/虚拟商品"
                  :options="'emall_goods.is_virtual' | selectOptionsData()"
                  v-decorator="[
                    'is_virtual',
                    { initialValue: '0',rules: [{ required: true, message: '请选择实体/虚拟商品' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="销售时间"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-range-picker
                  inputReadOnly
                  :allowClear="false"
                  :showTime="true"
                  style="width:100%;"
                  :ranges="{ '本年': [moment(), moment().endOf('year')], '3年': [moment(), moment().add(3, 'years')], '5年': [moment(), moment().add(5, 'years')], '10年': [moment(), moment().add(10, 'years')] }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM-DD HH:mm:ss"
                  v-decorator="[
                    'express',
                    { rules: [{ required: true,message: '请确认选择合同有效期' }] },
                  ]"
                >
                  <a-icon
                    slot="suffixIcon"
                    type="calendar"
                  />
                </a-range-picker>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="成本价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写成本价"
                  style="width:100%;"
                  v-decorator="[
                    'cost_price',
                    { rules: [{ required: true, message: '请填写成本价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="销售标价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写销售标价"
                  style="width:100%;"
                  v-decorator="[
                    'unit_price',
                    { rules: [{ required: true, message: '请填写销售标价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="实际售价"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写实际售价"
                  style="width:100%;"
                  v-decorator="[
                    'promotion_unit_price',
                    { rules: [{ required: true, message: '请填写实际售价' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="运费规则"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                style="margin-bottom:0;"
                required
              >
                <a-input-group compact>
                  <a-form-item :style="{ display: 'inline-block',width:'55%' }">
                    <a-input
                      type="number"
                      :min="0"
                      style="width:100%;"
                      placeholder="请填写运费单价"
                      addon-after="元 /"
                      v-decorator="[
                        'freight',
                        { rules: [{ required: true, message: '请填写运费单价' }] }
                      ]"
                    />
                  </a-form-item>
                  <a-form-item :style="{ display: 'inline-block',width:'45%' }">
                    <a-select
                      style="width:100%;"
                      placeholder="请选择运费计价单位"
                      :options="'emall_goods.freight_type' | selectOptionsData()"
                      v-decorator="[
                        'freight_type',
                        { initialValue: '3',rules: [{ required: true, message: '请选择运费计价单位' }] },
                      ]"
                    >
                    </a-select>
                  </a-form-item>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="库存管控"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-select
                  placeholder="请选择库存管控"
                  :options="'emall_goods.inventory_control' | selectOptionsData()"
                  @change="ivcontrolChange"
                  v-decorator="[
                    'inventory_control',
                    { initialValue: '0',rules: [{ required: true, message: '请选择库存管控' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
              v-if="noneDisplay === 1"
            >
              <a-form-item
                label="库存管控数量"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
              >
                <a-input-number
                  placeholder="请填写库存管控数量"
                  style="width:100%;"
                  v-decorator="[
                    'inventory',
                    { rules: [{ required: true, message: '请填写库存管控数量' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="展示主图"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                required
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="f=>{ return beforeUpload(f,'feature_image') }"
                  :file-list="fileList"
                  :remove="f=>{rmUpImgFile(f,'feature_image')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'feature_image')}"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="cancelPreviewImage"
                >
                  <img
                    style="width: 100%"
                    :src="previewImage"
                  />
                </a-modal>
              </a-form-item>
            </a-col>
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="轮播图"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                required
              >
                <a-upload
                  list-type="picture-card"
                  action="/auth/UploadFiles/upload"
                  method="post"
                  :multiple="true"
                  :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
                  :beforeUpload="f=>{ return beforeUpload(f,'contents') }"
                  :file-list="bannerList"
                  :remove="f=>{rmUpImgFile(f,'contents')}"
                  @preview="upImgPreview"
                  @change="fo=>{upImgChange(fo,'contents')}"
                >
                  <div v-if="bannerList.length < maxBannerNum">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      点击上传
                    </div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="title">商品SKU信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="规格参数"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                help="建议选择规格参数不超过 3 个！"
                required
              >
                <a-select
                  show-search
                  :filter-option="specFilterOption"
                  mode="multiple"
                  :maxTagCount="3"
                  placeholder="请选择规格参数"
                  :options="specListData"
                  @change="chooseSpec"
                  v-decorator="[
                    'choose_specs',
                    { rules: [{ required: true, message: '请选择规格参数' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-spin
            tip="请先选择规格参数！"
            size="large"
            :spinning="spinning2"
          >
            <a-icon
              slot="indicator"
              type="warning"
            />
            <a-table
              :columns="columns"
              :dataSource="data"
              :pagination="false"
              rowKey="key"
            >
              <template
                slot="spec"
                slot-scope="text, record"
              >
                <a-select
                  :allowClear="true"
                  style="margin: -5px 0;width:100%;"
                  placeholder="请选择商品规格"
                  show-search
                  optionFilterProp="children"
                  :filter-option="specFilterOption"
                  @change="(v) => selectColData(v, record.key,'spec')"
                >
                  <a-select-option
                    v-for="(svv,si1) in specValDefData"
                    :key="si1"
                    :value="svv.value"
                    :title="svv.label"
                    :disabled="svv.disabled"
                  >
                    {{ svv.label }}
                  </a-select-option>
                </a-select>
              </template>
              <template
                slot="goods_id"
                slot-scope="text, record"
              >
                <a-select
                  style="margin: -5px 0;width:100%;"
                  placeholder="请选择SKU商品"
                  show-search
                  optionFilterProp="children"
                  :filter-option="goodsFilterOption"
                  @change="(v) => selectColData(v, record.key,'goods_id')"
                >
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList1.length > 0"
                    >
                      <a-icon type="home" /> 自有货品
                    </span>
                    <a-select-option
                      v-for="(g1,i1) in goodsList1"
                      :key="i1"
                      :value="g1.value"
                      :disabled="g1.disabled"
                    >
                      <span>{{ g1.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g1.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList2.length > 0"
                    >
                      <a-icon type="branches" /> 代理货品
                    </span>
                    <a-select-option
                      v-for="(g2,i2) in goodsList2"
                      :key="i2"
                      :value="g2.value"
                      :disabled="g2.disabled"
                    >
                      <span>{{ g2.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g2.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </template>

              <template
                slot="cost_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="成本价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'cost_price')"
                />
              </template>

              <template
                slot="unit_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="标价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'unit_price')"
                />
              </template>

              <template
                slot="promotion_unit_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="实际售价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'promotion_unit_price')"
                />
              </template>

              <template
                slot="inventory"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="库存数"
                  :min="0"
                  :step="1"
                  @change="(v) => selectColData(v, record.key,'inventory')"
                />
              </template>

              <template
                slot="operation"
                slot-scope="text, record"
              >
                <a-popconfirm
                  v-if="data.length > 0"
                  title="是否要删除此行？"
                  @confirm="removeMember(record.key)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <a-button
              style="width: 100%; margin-top: 16px; margin-bottom: 8px"
              type="dashed"
              icon="plus"
              @click="newMember"
            >
              继续添加商品SKU
            </a-button>
          </a-spin>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
            class="mt-20"
          >
            <a-button
              htmlType="submit"
              @click="tmpBtn(-1)"
              type="default"
              class="mr-10"
            >暂存</a-button>

            <a-button
              htmlType="submit"
              @click="tmpBtn(20)"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListTypeByTt } from '@/api/dms/goodsType'
import { selectListSpecByEnterpriseForGoods } from '@/api/dms/emallGoodSpec'
import { addEmallGoodsAndSku } from '@/api/dms/emallGood'
import { getEnterpriseGoodsList } from '@/api/dms/enterpriseGood'
import moment from 'moment'
import { rmFile } from '@/api/manage'
import { file2Base64 } from '@/utils/util'

export default {
  name: 'AddShopGoodsForm',
  data () {
    return {
      spinning: false,
      spinning2: true,
      tip: '加载中...',
      noneDisplay: 0,
      addShopGoodsForm: this.$form.createForm(this),
      typeListData: [],
      fileList: [],
      bannerList: [],
      bannerImgsChoose: 0,
      maxBannerNum: 6,
      previewVisible: false,
      previewImage: '',
      specListData: [],
      specValDefData: [],
      columns: [
        {
          title: '商品规格',
          dataIndex: 'spec',
          width: '20%',
          ellipsis: true,
          scopedSlots: { customRender: 'spec' }
        },
        {
          title: 'SKU商品',
          dataIndex: 'goods_id',
          width: '26%',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_id' }
        },
        {
          title: '成本价',
          dataIndex: 'cost_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'cost_price' }
        },
        {
          title: '标价',
          dataIndex: 'unit_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'unit_price' }
        },
        {
          title: '实际售价',
          dataIndex: 'promotion_unit_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'promotion_unit_price' }
        },
        {
          title: '库存数',
          dataIndex: 'inventory',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'inventory' }
        },
        {
          title: '操作',
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      data: [
        // {
        //   key: '1',
        //   spec: '',
        //   goods_id: '',
        //   cost_price: '',
        //   unit_price: '',
        //   promotion_unit_price: '',
        //   inventory: ''
        // }
      ],
      goodsList1: [],
      goodsList2: []
    }
  },
  created () {
    this.spinning = true
    this.addShopGoodsForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.addShopGoodsForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    this.addShopGoodsForm.getFieldDecorator('feature_image', { preserve: true, rules: [{ required: true }] })
    this.addShopGoodsForm.getFieldDecorator('banners_image', { initialValue: [], preserve: true, rules: [{ required: true }] })
    this.addShopGoodsForm.getFieldDecorator('status', { initialValue: 0, preserve: true, rules: [{ required: true }] })
    this.loadSelectData()
  },
  methods: {
    moment,
    loadSelectData () {
      this.tip = '加载中...'
      // 获取企业货品数据
      getEnterpriseGoodsList({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          if (res.data.baseGoods !== undefined && res.data.baseGoods.length > 0) {
            this.goodsList1 = res.data.baseGoods
          }
          if (res.data.contractGoods !== undefined && res.data.contractGoods.length > 0) {
            this.goodsList2 = res.data.contractGoods
          }
          if (this.goodsList1.length < 1 && this.goodsList2.length < 1) {
            this.$message.error('企业暂无货品信息！')
          }
        } else {
          this.$message.error(res.msg || '企业货品信息加载失败！请刷新后重试！')
        }
      })
      // 货品类型下拉数据
      selectListTypeByTt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.typeListData = res.data
        }
      })
      // 规格下拉
      selectListSpecByEnterpriseForGoods({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.specListData = res.data
        }
        this.spinning = false
        this.tip = '处理中...'
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      if (this.data.length > 0) {
        let checkAuth = true
        if (this.data.length < 1) {
          this.$error({
            title: '请确认选择商品SKU信息！'
          })
          return false
        }
        for (const di in this.data) {
          if (this.data[di].spec === undefined || this.data[di].spec === null || this.data[di].spec === '') {
            checkAuth = false
            this.$error({
              title: '请确认选择第' + (parseInt(di) + 1) + '行的商品规格！'
            })
            return false
          }
          if (this.data[di].goods_id === undefined || this.data[di].goods_id === null || this.data[di].goods_id === '') {
            checkAuth = false
            this.$error({
              title: '请确认选择第' + (parseInt(di) + 1) + '行的SKU商品！'
            })
            return false
          }
          if (this.data[di].cost_price === undefined || this.data[di].cost_price === null || this.data[di].cost_price === '' || this.data[di].cost_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的成本价且不小于0！'
            })
            return false
          }
          if (this.data[di].unit_price === undefined || this.data[di].unit_price === null || this.data[di].unit_price === '' || this.data[di].unit_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的标价且不小于0！'
            })
            return false
          }
          if (this.data[di].promotion_unit_price === undefined || this.data[di].promotion_unit_price === null || this.data[di].promotion_unit_price === '' || this.data[di].promotion_unit_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的实际售价且不小于0！'
            })
            return false
          }
          if (this.data[di].inventory === undefined || this.data[di].inventory === null || this.data[di].inventory === '' || this.data[di].inventory < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的库存数且不小于0！'
            })
            return false
          }
        }
        if (!checkAuth) {
          return false
        }
      }

      this.addShopGoodsForm.validateFields((err, values) => {
        if (!err) {
          this.spinning = true
          values['goods'] = this.data
          addEmallGoodsAndSku(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.spinning = true
                  that.addShopGoodsForm.resetFields()
                  that.spinning2 = true
                  that.fileList = []
                  that.bannerList = []
                  that.data = []
                  that.goodsList1 = []
                  that.goodsList2 = []
                  that.specValDefData = []
                  that.loadSelectData()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    cancelPreviewImage () {
      this.previewVisible = false
    },
    async upImgPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await file2Base64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload (file, field) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传图片不超过2MB！')
        return false
      }

      if (field === 'contents') {
        this.bannerImgsChoose += 1
        if (this.bannerImgsChoose > this.maxBannerNum) {
          this.bannerImgsChoose = this.maxBannerNum
          this.$message.error('轮播图最多支持上传' + this.maxBannerNum + '张图片！')
          return false
        }
      }
    },
    upImgChange (info, field) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          if (field === 'contents') {
            const contents = this.addShopGoodsForm.getFieldValue('banners_image')
            contents.push(upRes.data)
            this.addShopGoodsForm.setFieldsValue({ 'banners_image': contents })
          }
          if (field === 'feature_image') {
            this.addShopGoodsForm.setFieldsValue({ 'feature_image': upRes.data })
          }
        }
      }

      if (info.file.status !== undefined) {
        if (field === 'contents') {
          this.bannerList = info.fileList
        }
        if (field === 'feature_image') {
          this.fileList = info.fileList
        }
      }
    },
    rmUpImgFile (file, field) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      let fname = (file.response !== undefined && file.response.data !== undefined) ? file.response.data : this.addShopGoodsForm.getFieldValue(field)
      fname = typeof fname === 'object' ? fname[0] : fname
      this.spinning = true
      rmFile({ fileName: fname }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          if (field === 'contents') {
            const contents = this.addShopGoodsForm.getFieldValue('banners_image')
            const newContents = contents.filter(val => {
              return !(val === fname)
            })
            this.addShopGoodsForm.setFieldsValue({ 'banners_image': newContents })
            this.bannerImgsChoose -= 1
          }
          if (field === 'feature_image') {
            this.addShopGoodsForm.setFieldsValue({ 'feature_image': '' })
          }
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    newMember () {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        spec: '',
        goods_id: '',
        cost_price: '',
        unit_price: '',
        promotion_unit_price: '',
        inventory: ''
      })
    },
    removeMember (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    selectColData (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
      // 已选规格值则禁用
      if (column === 'spec') {
        this.specValDefData.map((v) => {
          let isCheck = false
          this.data.map((v2) => {
            if (v2.spec === v.value) {
              isCheck = true
            }
          })
          v.disabled = isCheck
        })
      }
    },
    chooseSpec (v) {
      // 重置表格
      this.data = []
      this.specValDefData = []
      if (v.length !== undefined && v.length > 0) {
        // 有数据，显示表格
        this.spinning2 = false
        this.specListData.forEach(sv => {
          if (v.indexOf(sv.value) >= 0) {
            this.specListDataCaculate(sv)
          }
        })
      } else {
        // 没有数据遮住表格
        this.spinning2 = true
      }
    },
    specListDataCaculate (cuArr) {
      const newSpecVal = []
      if (cuArr.spec_vals.length === undefined || cuArr.spec_vals.length < 1) {
        return false
      }
      if (this.specValDefData.length !== undefined && this.specValDefData.length > 0) {
        this.specValDefData.map(v => {
          cuArr.spec_vals.map(cv => {
            newSpecVal.push({ value: v.value + '@' + [cuArr.value, cv.id].join(','), label: v.label + ',' + cuArr.label + '(' + cv.spec_val + ')', disabled: false })
          })
        })
      } else {
        cuArr.spec_vals.map(cv => {
          newSpecVal.push({ value: [cuArr.value, cv.id].join(','), label: cuArr.label + '(' + cv.spec_val + ')', disabled: false })
        })
      }
      this.specValDefData = newSpecVal
    },
    goodsFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].children !== undefined && option.componentOptions.children[0].children[0] !== undefined && option.componentOptions.children[0].children[0].text !== undefined) {
        return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
    tmpBtn (cm) {
      this.addShopGoodsForm.setFieldsValue({ status: cm })
    },
    specFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].text !== undefined) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
    ivcontrolChange (iv) {
      if (iv > 0) {
        this.noneDisplay = 1
      } else {
        this.noneDisplay = 0
      }
    },
    filterTreeNode (val, treeNode) {
      return treeNode.data.props.title.indexOf(val) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.noneDisplay {
  display: none;
}
</style>
