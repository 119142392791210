<template>
  <div>
    <a-card :bordered="false">
      <div class="mx-25">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="8"
                :sm="24"
              >
                <a-form-item label="搜索">
                  <a-input
                    v-model="queryParam.search"
                    placeholder="输入饮食模式名称搜索"
                  />
                </a-form-item>
              </a-col>

              <a-col
                :md="7"
                :sm="24"
              >
                <span class="table-page-search-submitButtons">
                  <a-button
                    type="primary"
                    icon="search"
                    @click="$refs.dietaryPatternTable.refresh(true)"
                  >查询</a-button>
                  <a-button
                    class="mx-10"
                    icon="reload"
                    @click="() => (this.queryParam = {})"
                  >重置</a-button>
                  <a-button
                    :hidden="'/HealthDietaryPatterns/addDietaryPattern' | isShowButton"
                    type="primary"
                    icon="plus"
                    @click="clickShowPattern(false)"
                  >新增饮食模式</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>

      <s-table
        ref="dietaryPatternTable"
        size="default"
        rowKey="id"
        showPagination="auto"
        :columns="columns"
        :data="loadData"
        :pageSize="pageSize"
        :scroll="tableScroll"
      >
        <span
          slot="status"
          slot-scope="text"
        >
          <a-badge
            :status="text | statusTypeFilter"
            :text="'pattern.status' | showText(text)"
          />
        </span>
        <span
          slot="dietary_pattern_tag"
          slot-scope="tags"
        >
          <a-tag
            color="orange"
            v-for="tag in tags"
            :key="tag.tag_id"
          >
            {{tag.tag_text}}
          </a-tag>
        </span>
        <span
          slot="action"
          slot-scope="text, record"
        >
          <template>
            <span :hidden="'/HealthDietaryPatterns/viewDietaryPattern' | isShowButton">
              <a @click="clickView(record)">详情</a>
            </span>
            <a-divider
              type="vertical"
              :hidden="'/HealthDietaryPatterns/editDietaryPattern' | isShowSeparator(['/HealthDietaryPatterns/viewDietaryPattern'])"
            />
            <a
              :hidden="'/HealthDietaryPatterns/editDietaryPattern' | isShowButton"
              @click="clickShowPattern(record)"
            >编辑</a>
            <a-divider
              type="vertical"
              :hidden="'/HealthDietaryPatterns/editDietaryPattern' | isShowSeparator(['/HealthDietaryPatterns/viewDietaryPattern','/HealthDietaryPatterns/editDietaryPattern'])"
            />
            <a
              :hidden="'/HealthDietaryPatterns/editDietaryPattern' | isShowButton"
              @click="clickAddItem(record)"
            >新增内容</a>
          </template>
        </span>

        <a-table
          slot="expandedRowRender"
          slot-scope="item"
          rowKey="id"
          :columns="innerColumns"
          :data-source="item.dietary_pattern_item"
          :pagination="false"
          size="middle"
        >
          <span
            slot="section_type"
            slot-scope="text"
          >
            {{ 'pattern_item.section_type' | showText(text) }}
          </span>
          <span
            slot="content_type"
            slot-scope="text"
          >
            {{ 'pattern_item.content_type' | showText(text) }}
          </span>
          <span
            slot="content"
            slot-scope="text,record"
          >
            <span v-if="record.content_type === 2">
              <img
                @click="previewImage(record)"
                title="点击预览大图"
                class="show-img"
                v-if="record.content_img !== undefined && record.content_img !== ''"
                :src="record.content_img"
              >
              <span v-else>{{ text }}</span>
            </span>
            <span v-else>{{ text }}</span>
          </span>
          <span
            slot="status2"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'pattern.status' | showText(text)"
            />
          </span>
          <span
            slot="action2"
            slot-scope="text, record"
          >
            <template>
              <a
                :hidden="'/HealthDietaryPatterns/editDietaryPattern' | isShowButton"
                @click="clickEditItem(record)"
              >编辑</a>
            </template>
          </span>
        </a-table>
      </s-table>
      <!-- 饮食模式详情的抽屉窗口 -->
      <dietary-pattern-detail
        :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
        :parentParams="dietaryPatternParams"
        @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
      />

      <!-- 饮食模式的新增、编辑抽屉窗口 -->
      <a-drawer
        width="600"
        :maskClosable="false"
        placement="right"
        :closable="true"
        :visible="patternManageVisible"
        :title="cuDietaryPatternInfo.id !== undefined ? '编辑饮食模式信息' : '新增饮食模式信息'"
        @close="closePatternManageDrawer"
      >
        <a-spin
          tip="处理中..."
          size="large"
          :spinning="patternManageSpinning"
        >
          <div>
            <form-create
              v-model="cformPatternApi"
              :rule="cformPatternRule"
              :option="cformPatternOption"
            >
            </form-create>
            <a-row>
              <a-col
                :offset="8"
                :span="12"
              >
                <a-button
                  class="mr-10"
                  icon="close"
                  @click="closePatternManageDrawer"
                  type="default"
                >关闭</a-button>
                <a-button
                  icon="check"
                  @click="clickPatternSave"
                  type="primary"
                >确认保存</a-button>
              </a-col>
            </a-row>
          </div>
        </a-spin>
      </a-drawer>

      <!-- 饮食模式的内容新增、编辑抽屉 -->
      <DietaryPatternItemAdd
        ref="dietaryPatternItemAddDrawer"
        @complete="$refs.dietaryPatternTable.refresh(true)"
      />

      <!-- 查看图片的弹窗 -->
      <ImageModalView ref="imageModalView" />
    </a-card>
  </div>
</template>

<script>
import { listDietaryPattern, saveDietaryPatternInfo } from '@/api/dms/dietaryPattern'
import { DietaryPatternDetail, DietaryPatternItemAdd } from '@/components/DietaryPattern'
import { ImageModalView } from '@/components/ImageModal'
import { getSelectListTag } from '@/api/manage'

export default {
  name: 'ListRole',
  components: { DietaryPatternDetail, DietaryPatternItemAdd, ImageModalView },
  data () {
    return {
      columns: [
        {
          title: 'ID',
          width: 70,
          dataIndex: 'id'
        },
        {
          title: '饮食模式名称',
          width: 150,
          dataIndex: 'pattern_name'
        },
        {
          title: '英文名称',
          width: 120,
          dataIndex: 'pattern_name_e'
        },
        {
          title: '简介',
          ellipsis: true,
          dataIndex: 'intro'
        },
        {
          title: '关联标签',
          ellipsis: true,
          dataIndex: 'dietary_pattern_tag',
          scopedSlots: { customRender: 'dietary_pattern_tag' }
        },
        {
          title: '排序',
          width: 60,
          ellipsis: true,
          dataIndex: 'sort'
        },
        {
          title: '当前状态',
          width: 80,
          ellipsis: true,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 165,
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: 'ID',
          width: 70,
          dataIndex: 'id'
        },
        {
          title: '段落类型',
          width: 120,
          dataIndex: 'section_type',
          scopedSlots: { customRender: 'section_type' }
        },
        {
          title: '段落排序',
          width: 70,
          align: 'center',
          dataIndex: 'section_sort'
        },
        {
          title: '内容类型',
          width: 70,
          dataIndex: 'content_type',
          scopedSlots: { customRender: 'content_type' }
        },
        {
          title: '内容排序',
          width: 70,
          align: 'center',
          dataIndex: 'content_sort'
        },
        {
          title: '内容',
          ellipsis: true,
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' }
        },
        {
          title: '当前状态',
          width: 80,
          ellipsis: true,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status2' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 65,
          scopedSlots: { customRender: 'action2' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        return listDietaryPattern(requestParameters)
          .then(res => {
            console.log(res)
            return res
          })
      },
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
      patternManageVisible: false,
      cuDietaryPatternInfo: {},
      patternManageSpinning: false,
      cformPatternApi: {},
      cformPatternRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'input',
          title: '模式名称',
          field: 'pattern_name',
          props: { placeholder: '请填写模式名称' },
          validate: [
            { required: true, message: '请填写模式名称', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '模式名称英文',
          field: 'pattern_name_e',
          props: { placeholder: '请填写模式名称英文' }
        },
        {
          type: 'input',
          title: '简介',
          field: 'intro',
          props: { type: 'textarea', autoSize: { minRows: 4 }, placeholder: '请填写饮食模式的简介' }
        },
        {
          type: 'input',
          title: '排序',
          field: 'sort', style: { width: '100%' },
          props: { type: 'number', placeholder: '请填写排序', min: 0, step: 1, max: 127 }
        },
        {
          type: 'select',
          title: '关联标签',
          field: 'tags',
          props: { mode: 'tags', placeholder: '请选择关联标签', allowClear: true, optionFilterProp: 'children' },
          options: []
        }
      ],
      cformPatternOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      tagsArr: []
    }
  },
  created () {

  },
  filters: {
    statusTypeFilter (status) {
      return status >= 10 ? 'success' : 'error'
    }
  },
  methods: {
    clickAddItem (record) {
      this.$refs.dietaryPatternItemAddDrawer.showDrawer({ pattern_id: record.id })
    },
    clickEditItem (record) {
      this.$refs.dietaryPatternItemAddDrawer.showDrawer(record)
    },
    clickView (record) {
      this.dietaryPatternParams = { pattern: record.id }
      this.dietaryPatternDrawerVisible = true
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    closePatternManageDrawer () {
      this.patternManageVisible = false
      this.cformPatternApi.resetFields()
    },
    clickShowPattern (cuRow = false) {
      // cuRow默认为false新增，传入值为编辑
      this.cuDietaryPatternInfo = {}
      this.patternManageVisible = true
      this.patternManageSpinning = true
      getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: [0, 1, 20] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.cformPatternApi.updateRules({ // 更新规则
            'tags': { options: res.data }
          })
        }
        if (cuRow !== false && cuRow.id !== undefined) {
          this.cuDietaryPatternInfo = cuRow
          let cuRowSetVal = JSON.parse(JSON.stringify(cuRow))
          cuRowSetVal.tags = cuRowSetVal.dietary_pattern_tag.map(tgv => {
            return tgv.tag_id.toString()
          })
          delete cuRowSetVal.dietary_pattern_tag
          delete cuRowSetVal.dietary_pattern_item
          this.cformPatternApi.setValue(cuRowSetVal)
        } else {
          // 新增的时候默认排序为10
          this.cformPatternApi.setValue({ sort: 10 })
        }
        this.patternManageSpinning = false
      })
    },
    clickPatternSave () {
      this.cformPatternApi.validate((valid2, fail2) => {
        if (valid2) {
          this.patternManageSpinning = true
          saveDietaryPatternInfo(this.cformPatternApi.formData()).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.closePatternManageDrawer()
              this.$refs.dietaryPatternTable.refresh(true)
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.patternManageSpinning = false
          })
        } else {
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    previewImage (record) {
      this.$refs.imageModalView.showModal({ img_show: record.content_img })
    }
  }
}

</script>

<style lang="scss" scoped>
.show-img {
  max-width: 100px;
  max-height: 50px;
}
</style>