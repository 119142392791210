<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="16">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="全部"
                      :options="'user.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入用户名/手机号搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="6"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.adminUserTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = {})"
                    >重置</a-button>
                    <router-link
                      :to="'/Enterprises/addPlatformUser'"
                      :hidden="'/Enterprises/addPlatformUser' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增人员</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="adminUserTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="gender"
            slot-scope="text"
          >
            {{ 'ryx.gender' | showText(text.toString()) }}
          </span>

          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'user.status' | showText(text)"
            />
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/Enterprises/resetUserPwd' | isShowButton">
                <a @click="clickResetPwd(record)">修改密码</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/Enterprises/editPlatformUser','/Enterprises/changeUserStatus'] | isShowSeparator(['/Enterprises/resetUserPwd'])"
              />

              <a-dropdown :hidden="['/Enterprises/editPlatformUser','/Enterprises/changeUserStatus'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/Enterprises/editPlatformUser' | isShowButton"
                    key="2"
                  >
                    <a @click="clickEdit(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-divider :hidden="'/Enterprises/changeUserStatus' | isShowSeparator(['/Enterprises/editPlatformUser'])" />
                  <a-menu-item
                    :hidden="'/Enterprises/changeUserStatus' | isShowButton"
                    v-if="record.status === 2"
                    @click="clickChangeStatus(record,3)"
                    key="3"
                  >禁用</a-menu-item>
                  <a-menu-item
                    :hidden="'/Enterprises/changeUserStatus' | isShowButton"
                    v-else
                    @click="clickChangeStatus(record,2)"
                    key="4"
                  >启用</a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

    <ChangeUserPwd
      ref="changeUserPwdModal"
      @complete="$refs.adminUserTable.refresh(true)"
    />

  </div>
</template>

<script>
import { listUser, changeStatus } from '@/api/dms/user'
import { ChangeUserPwd } from '@/components/UserManage'

const statusMap = {
  1: {
    status: 'default',
    text: '待激活'
  },
  2: {
    status: 'success',
    text: '正常'
  },
  3: {
    status: 'error',
    text: '注销'
  }
}

export default {
  name: 'ListPlatformUser',
  components: { ChangeUserPwd },
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: 'ID',
          width: 90,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '用户名',
          width: 140,
          dataIndex: 'user_name',
          fixed: 'left'
        },
        {
          title: '姓名',
          dataIndex: 'nick_name',
          width: 120,
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 120,
          ellipsis: true
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 60,
          scopedSlots: { customRender: 'gender' }
        },
        {
          title: '当前所属企业名称',
          dataIndex: 'enterprise_name',
          ellipsis: true
        },
        {
          title: '部门名称',
          dataIndex: 'department_name',
          ellipsis: true
        },
        {
          title: '状态',
          width: 95,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '最后登录IP',
          dataIndex: 'last_ip',
          ellipsis: true
        },
        {
          title: '最后登录时间',
          width: 150,
          dataIndex: 'last_login'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 170,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ join: ['relation', 'department'], main: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listUser(requestParameters)
          .then(res => {
            console.log(res)
            if (res.data.length > 0) {
              that.tableScroll = { x: 1500 }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      }
    }
  },
  filters: {
    statusTypeFilter (type) {
      return statusMap[type] !== undefined ? statusMap[type].status : 'default'
    }
  },
  created () {
  },
  computed: {

  },
  methods: {
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('Enterprises.editPlatformUser', JSON.stringify({ user: record.id }))
      this.$router.push({ path: '/Enterprises/editPlatformUser' })
    },
    clickChangeStatus (record, cstatus) {
      if ([2, 3].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({
          title: '该操作不允许！'
        })
        return false
      }
      const that = this
      this.$confirm({
        title: '确认修改该账号状态？',
        content: false,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          that.spinning = true
          changeStatus({ enterprise: record.target_enterprise, platform: that.$store.getters.userInfo.platform.id, user: record.id, cstatus }).then(res => {
            if (res.status !== undefined && res.status === 1) {
              that.$message.success('操作成功！')
            } else {
              that.$message.error(res.msg || '操作失败！请刷新后重试！')
            }
            that.spinning = false
            that.$refs.adminUserTable.refresh(true)
          })
        },
        onCancel () { }
      })
    },
    clickResetPwd (record) {
      this.$refs.changeUserPwdModal.showModal({ user: record.id })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
