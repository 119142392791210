<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        id="detailBox"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          id="editShopGoodsForm"
          @submit="handleSubmit"
          :form="editShopGoodsForm"
        >
          <a-descriptions title="店铺基础信息">
            <a-descriptions-item label="店铺名称">{{ originInfo.shop_name }}</a-descriptions-item>
            <a-descriptions-item
              label="店铺地址"
              :span="2"
            >{{ originInfo.id !== undefined ? originInfo.province_name+' '+originInfo.city_name+' '+originInfo.district_county_name+' '+originInfo.detail_address : '' }}</a-descriptions-item>

            <a-descriptions-item label="所属企业">{{ originInfo.enterprise_name }}</a-descriptions-item>
            <a-descriptions-item label="所属部门">{{ originInfo.dep_name }}</a-descriptions-item>
            <a-descriptions-item label="当前状态"><span :class="originInfo.status === '2' ? 'font-success' : 'font-error'">{{ 'emall_shop.status' | showText(originInfo.status) }}</span></a-descriptions-item>

            <a-descriptions-item
              label="店铺宣传语"
              :span="3"
            >{{ originInfo.sub_title }}</a-descriptions-item>

            <a-descriptions-item
              label="店铺介绍"
              :span="3"
            >{{ originInfo.introduction }}</a-descriptions-item>

            <a-descriptions-item
              label="店铺特色"
              :span="3"
            >{{ originInfo.feature }}</a-descriptions-item>

            <a-descriptions-item label="代理人">{{ originInfo.contact }}</a-descriptions-item>
            <a-descriptions-item label="联系电话">{{ originInfo.contact_phone }}</a-descriptions-item>
            <a-descriptions-item label="更新时间">{{ originInfo.update_date }}</a-descriptions-item>

            <a-descriptions-item label="店铺图片">
              <div
                style="max-width:80px;height:80px;cursor:pointer;"
                v-if="originInfo.feature_image_show !== undefined && originInfo.feature_image_show !== ''"
              >
                <img
                  title="点击查看原图"
                  style="width: 100%"
                  :src="originInfo.feature_image_show"
                  @click="handlePreview(originInfo.feature_image_show)"
                />
              </div>
            </a-descriptions-item>
          </a-descriptions>

          <a-divider />
          <div class="title">已授权可售货品信息</div>
          <a-table
            :columns="goodsColumns"
            :dataSource="originInfo.emall_shop_good"
            :pagination="false"
            rowKey="id"
          >
            <span
              slot="goods_id"
              slot-scope="text,record,key"
            >
              {{ key+1 }}
            </span>
            <span
              slot="action"
              slot-scope="text, record"
            >
              <template>
                <span>
                  <a @click="clickDelete(record)">删除</a>
                </span>
              </template>
            </span>
          </a-table>

          <a-divider />
          <div class="title">店铺授权可售货品信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="可售货品"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-select
                  mode="multiple"
                  :maxTagCount="5"
                  :maxTagTextLength="16"
                  :allowClear="true"
                  show-search
                  optionFilterProp="children"
                  placeholder="请选择可售货品"
                  v-decorator="['goods']"
                >
                  <a-select-option
                    v-for="(g1,i1) in goodsData"
                    :key="i1"
                    :value="g1.value"
                    :disabled="oriEgIds.indexOf(g1.value) >= 0 ? true : false"
                    :title="g1.label+' '+g1.label2"
                  >
                    <span>{{ g1.label }} </span>
                    <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g1.label2 }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="店铺图片"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>
  </div>
</template>

<script>
import { getEmallShopDetailInfo, editEmallShopGoods } from '@/api/dms/emallShop'
import { getEmallGoodListByEnt } from '@/api/dms/emallGood'

export default {
  data () {
    return {
      spinning: true,
      tip: '数据加载中...',
      editShopGoodsForm: this.$form.createForm(this),
      originInfo: {},
      goodsData: [],
      goodsColumns: [
        {
          title: '#',
          dataIndex: 'id',
          width: 70,
          ellipsis: true,
          scopedSlots: { customRender: 'goods_id' }
        },
        {
          title: '货品名称',
          dataIndex: 'goods_name',
          width: 170,
          ellipsis: true
        },
        {
          title: '货品类型',
          dataIndex: 'type_name',
          width: 100,
          align: 'center',
          ellipsis: true
        },
        {
          title: '展示标题',
          dataIndex: 'title',
          ellipsis: true
        },
        {
          title: '售价',
          width: 90,
          dataIndex: 'promotion_unit_price',
          ellipsis: true
        },
        {
          title: '开售时间',
          width: 150,
          dataIndex: 'start_time',
          ellipsis: true
        },
        {
          title: '停售时间',
          width: 150,
          dataIndex: 'end_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 65,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      previewVisible: false,
      oriEgIds: [], // 商城货品的id  hsy_emall_goods
      oriEsgIds: [], // 商城店铺可售货品的id  hsy_emall_shop_goods
      previewImg: ''
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('EmallShops.editAuthGoods'))
    if (this.params === null || this.params.shop === undefined || this.params.shop < 1) {
      this.$message.error('请确认选择商城店铺！')
      return this.$router.go(-1)
    }
    this.editShopGoodsForm.getFieldDecorator('id', { initialValue: this.params.shop, preserve: true, rules: [{ required: true }] })
    this.editShopGoodsForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    // 获取数据
    getEmallGoodListByEnt({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.goodsData = res.data
      } else {
        this.$message.error(res.msg || '获取可售货品信息失败！请刷新后重试！')
      }
    })
    getEmallShopDetailInfo({ shop: this.params.shop }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.shop) {
        res.data.status = res.data.status.toString()
        this.originInfo = res.data
        this.loadOriEgIds()
      } else {
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
      this.spinning = false
      this.tip = '处理中...'
    }).catch(() => {
      return this.$router.go(-1)
    })
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    loadOriEgIds () {
      this.oriEgIds = []
      this.oriEsgIds = []
      this.originInfo.emall_shop_good.map(v => {
        this.oriEgIds.push(v.emall_goods_id)
        this.oriEsgIds.push(v.id)
      })
    },
    clickDelete (row) {
      const newData = this.originInfo.emall_shop_good.filter(item => item.id !== row.id)
      this.originInfo.emall_shop_good = newData
      this.loadOriEgIds()
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      this.editShopGoodsForm.validateFields((err, values) => {
        if (!err) {
          if ((values.goods === undefined || values.goods.length < 1) && this.oriEsgIds.length < 1) {
            that.$confirm({
              title: '当前店铺未授权可售货品！确认保存？',
              content: false,
              onOk () {
                that.submitSaveData(values)
              },
              onCancel () { }
            })
          } else {
            that.submitSaveData(values)
          }
        }
      })
    },
    submitSaveData (values) {
      const that = this
      that.spinning = true
      values.oriGoods = this.oriEsgIds
      editEmallShopGoods(values).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '授权店铺可售货品保存成功！',
            content: false,
            cancelText: '查看店铺详情',
            okText: '返回店铺管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {
              sessionStorage.setItem('EmallShops.viewEmallShop', JSON.stringify({ shop: that.params.shop }))
              that.$router.push({ path: '/EmallShops/viewEmallShop' })
            }
          })
        } else {
          that.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        that.spinning = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
#detailBox {
  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 16px 5px;
  }
}
</style>
