<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <a-col
                  :md="6"
                  :sm="24"
                >
                  <a-form-item label="当前状态">
                    <a-select
                      v-model="queryParam.status"
                      placeholder="请选择当前状态"
                      :options="'emall_shop.status' | selectOptionsData()"
                      :allowClear="true"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="输入店铺名称搜索"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.listEmallShopsTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      class="mx-10"
                      icon="reload"
                      @click="() => (this.queryParam = { status: '2' })"
                    >重置</a-button>
                    <router-link
                      :to="'/EmallShops/addShops'"
                      :hidden="'/EmallShops/addShops' | isShowButton"
                    >
                      <a-button
                        type="primary"
                        icon="plus"
                      >新增店铺</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
        >
          <img
            alt="货品图片"
            style="width: 100%"
            :src="previewImg"
          />
        </a-modal>

        <s-table
          ref="listEmallShopsTable"
          size="default"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >
          <span
            slot="status"
            slot-scope="text"
          >
            <a-badge
              :status="text | statusTypeFilter"
              :text="'emall_shop.status' | showText(text)"
            />
          </span>

          <div
            slot="feature_image"
            slot-scope="text, record"
            style="max-width:25px;height:25px;cursor:pointer;"
          >
            <img
              v-if="record.feature_image_show !== undefined && record.feature_image_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.feature_image_show"
              @click="handlePreview(record.feature_image_show)"
            />
          </div>

          <span
            slot="detail_address"
            slot-scope="text, record"
            :title="record.province_name+' '+record.city_name+' '+record.district_county_name+'\r\n'+text"
          >
            {{ record.province_name+' '+record.city_name+' '+record.district_county_name }}
            <br />
            {{ text }}
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <span :hidden="'/EmallShops/viewEmallShop' | isShowButton">
                <a @click="clickView(record)">详情</a>
              </span>
              <a-divider
                type="vertical"
                :hidden="['/EmallShops/changeStatus','/EmallShops/editShops','/EmallShops/editAuthGoods'] | isShowSeparator(['/EmallShops/viewEmallShop'])"
              />
              <a-dropdown :hidden="['/EmallShops/changeStatus','/EmallShops/editShops','/EmallShops/editAuthGoods'] | isShowButton">
                <a
                  class="ant-dropdown-link"
                  @click="(e) => e.preventDefault()"
                >更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item
                    :hidden="'/EmallShops/editShops' | isShowButton"
                    key="0"
                  >
                    <a @click="clickEdit(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-item
                    :hidden="'/EmallShops/editAuthGoods' | isShowButton"
                    key="1"
                  >
                    <a @click="clickGoods(record)">授权商品</a>
                  </a-menu-item>
                  <a-menu-divider :hidden="['/EmallShops/changeStatus'] | isShowSeparator(['/EmallShops/editShops','/EmallShops/editAuthGoods'])" />
                  <a-menu-item
                    v-if="record.status === 2"
                    :hidden="'/EmallShops/changeStatus' | isShowButton"
                    key="2"
                  >
                    <a @click="clickChangeStatus(record,3)">关闭店铺</a>
                  </a-menu-item>
                  <a-menu-item
                    v-else
                    :hidden="'/EmallShops/changeStatus' | isShowButton"
                    key="3"
                  >
                    <a @click="clickChangeStatus(record,2)">开启店铺</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { listEmallShop, changeStatus } from '@/api/dms/emallShop'

export default {
  name: 'EmallShopsList',
  data () {
    return {
      spinning: false,
      columns: [
        {
          title: '#',
          width: 65,
          dataIndex: 'id',
          fixed: 'left'
        },
        {
          title: '店铺名称',
          width: 150,
          dataIndex: 'shop_name',
          fixed: 'left'
        },
        {
          title: '店铺地址',
          ellipsis: true,
          dataIndex: 'detail_address',
          scopedSlots: { customRender: 'detail_address' }
        },
        {
          title: '所属企业',
          ellipsis: true,
          dataIndex: 'enterprise_name',
        },
        {
          title: '所属部门',
          ellipsis: true,
          width: 100,
          dataIndex: 'department_name',
        },
        {
          title: '货品图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'feature_image',
          scopedSlots: { customRender: 'feature_image' }
        },
        {
          title: '状态',
          ellipsis: true,
          width: 80,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '联系人',
          ellipsis: true,
          width: 80,
          dataIndex: 'contact'
        },
        {
          title: '联系电话',
          ellipsis: true,
          width: 110,
          dataIndex: 'contact_phone'
        },
        {
          title: '更新时间',
          width: 150,
          ellipsis: true,
          dataIndex: 'update_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 125,
          fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableScroll: {},
      pageSize: 10,
      // 查询参数
      queryParam: { status: '2' },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ main: this.$store.getters.userInfo.user.target_enterprise }, parameter, this.queryParam)
        const that = this
        return listEmallShop(requestParameters)
          .then(res => {
            if (res.data.length > 0) {
              that.tableScroll = { x: 1300 }
            } else {
              that.tableScroll = {}
            }
            return res
          })
      },
      previewVisible: false,
      previewImg: ''
    }
  },
  filters: {
    statusTypeFilter (type) {
      switch (type) {
        case 0:
          return 'default'
        case 2:
          return 'success'
        default:
          return 'error'
      }
    }
  },
  created () {

  },
  methods: {
    clickView (record) {
      // 查看
      sessionStorage.setItem('EmallShops.viewEmallShop', JSON.stringify({ shop: record.id }))
      this.$router.push({ path: '/EmallShops/viewEmallShop' })
    },
    clickEdit (record) {
      // 编辑
      sessionStorage.setItem('EmallShops.editShops', JSON.stringify({ shop: record.id }))
      this.$router.push({ path: '/EmallShops/editShops' })
    },
    clickGoods (record) {
      // 授权商品
      sessionStorage.setItem('EmallShops.editAuthGoods', JSON.stringify({ shop: record.id }))
      this.$router.push({ path: '/EmallShops/editAuthGoods' })
    },
    clickChangeStatus (record, cstatus) {
      // 修改状态
      if ([2, 3].indexOf(cstatus) < 0 || record.id === undefined || record.id < 1) {
        this.$error({ title: '该操作不允许！' })
        return false
      }
      this.spinning = true
      changeStatus({ shop: record.id, cstatus, enterprise_id: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.$refs.listEmallShopsTable.refresh(true)
        this.spinning = false
      })
    },
    handleCancel () {
      this.previewVisible = false
      this.previewImg = ''
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    }
  }
}
</script>
