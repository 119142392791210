import request from '@/utils/request'

const apiUrls = {
  addSchedules: '/HealthServiceSchedules/addSchedules',
  getSchedulesByUser: '/HealthServiceSchedules/getSchedulesByUser',
  getScheduleList: '/HealthServiceSchedules/getScheduleList',
  saveSchedule: '/HealthServiceSchedules/saveSchedule'
}

export function addSchedules (data) {
  return request({
    url: apiUrls.addSchedules,
    method: 'post',
    data
  })
}

export function getSchedulesByUser (parameter) {
  return request({
    url: apiUrls.getSchedulesByUser,
    method: 'get',
    params: parameter
  })
}

export function getScheduleList (parameter) {
  return request({
    url: apiUrls.getScheduleList,
    method: 'get',
    params: parameter
  })
}

export function saveSchedule (data) {
  return request({
    url: apiUrls.saveSchedule,
    method: 'post',
    data
  })
}
