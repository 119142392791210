import request from '@/utils/request'

const apiUrls = {
  listEmallGoodsSpecDef: '/EmallGoodsSpecDefs/listEmallGoodsSpecDef',
  addGoodsSpecDefs: '/EmallGoodsSpecDefs/addGoodsSpecDefs',
  getEmallGoodsSpecDefInfo: '/EmallGoodsSpecDefs/getEmallGoodsSpecDefInfo',
  editEmallGoodsSpecDef: '/EmallGoodsSpecDefs/editEmallGoodsSpecDef',
  selectListSpecByEnterpriseForGoods: '/EmallGoodsSpecDefs/selectListSpecByEnterpriseForGoods'
}

export function listEmallGoodsSpecDef (parameter) {
  return request({
    url: apiUrls.listEmallGoodsSpecDef,
    method: 'get',
    params: parameter
  })
}

export function addGoodsSpecDefs (data) {
  return request({
    url: apiUrls.addGoodsSpecDefs,
    method: 'post',
    data
  })
}

export function getEmallGoodsSpecDefInfo (parameter) {
  return request({
    url: apiUrls.getEmallGoodsSpecDefInfo,
    method: 'get',
    params: parameter
  })
}

export function editEmallGoodsSpecDef (data) {
  return request({
    url: apiUrls.editEmallGoodsSpecDef,
    method: 'post',
    data
  })
}

export function selectListSpecByEnterpriseForGoods (data) {
  return request({
    url: apiUrls.selectListSpecByEnterpriseForGoods,
    method: 'post',
    data
  })
}
