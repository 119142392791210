/**
 * 项目默认配置项
 */

export default {
  title: '营养师工作台',
  mainIntro: '营养师工作台',
  slogan: '欢迎使用营养师工作台！',
  company: ' 四川吃巴适科技有限公司',
  companyEng: 'Sichuan Eat Good Technology Co. Ltd',
  support: ' 四川吃巴适科技有限公司',
  mainLogo: '/chinatelecom.png',
  logoOnly: '/logo_alan.png',
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  // 根据router的name设置可以不登录直接访问的路由白名单
  whiteListRouteName: ['login', 'signUp', 'MobileInputSurvey', 'Error404', 'ErrorMsg','VedioPlayer']
}
