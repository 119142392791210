<template>
  <DailyFoodMenuList />
</template>

<script>
import { DailyFoodMenuList } from '@/components/DailyFoodMenus'

export default {
  name: 'ListDailyFoodMenu',
  components: {
    DailyFoodMenuList,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {

  },
  methods: {

  }
}
</script>
