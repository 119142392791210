import request from '@/utils/request'

const apiUrls = {
  savePatientFollowPlanInfo: '/HealthFollowupPlans/savePatientFollowPlanInfo',
  getPatientFollowPlanInfo: '/HealthFollowupPlans/getPatientFollowPlanInfo'
}

export function savePatientFollowPlanInfo (data) {
  return request({
    url: apiUrls.savePatientFollowPlanInfo,
    method: 'post',
    data
  })
}

export function getPatientFollowPlanInfo (parameter) {
  return request({
      url: apiUrls.getPatientFollowPlanInfo,
      method: 'get',
      params: parameter
  })
}

