<template>
  <div id="settings">
    <div
      class="profile-nav-bg"
      style="background-image: url('/images/bg-patient.jpg')"
    ></div>

    <!-- User Profile Card -->
    <a-card
      :bordered="false"
      class="card-profile-head"
      :bodyStyle="{padding: 0,}"
    >
      <template #title>
        <a-row
          type="flex"
          align="middle"
        >
          <a-col
            :span="24"
            :md="10"
            class="col-info"
          >
            <a-avatar
              :size="74"
              shape="square"
              :src="docInfo.header_show !== undefined && docInfo.header_show !== '' ? docInfo.header_show : '/images/avatar-' +  docInfo.gender  + '.jpg'"
            />
            <div class="avatar-info">
              <h4 class="font-semibold m-0">{{ docInfo.nick_name }}</h4>
              <p>{{ 'ryx.gender' | showText(docInfo.gender) }}/ 联系电话：{{ docInfo.mobile }} / 邮箱：{{ docInfo.email }} </p>
            </div>
          </a-col>
          <a-col
            :span="24"
            :md="6"
            class="col-info"
          >
            <div class="avatar-info">
              <p class="mt-15">当前所属机构：{{ docInfo.enterprise_name }}</p>
              <p>当前所属科室：{{ docInfo.department_name }}</p>
            </div>
          </a-col>
          <a-col
            :span="24"
            :md="8"
          >
            <div class="avatar-info">
              <p>擅长：
                <a-tag
                  color="orange"
                  v-for="tag in docInfo.user_tag"
                  :key="tag.tag_id"
                >
                  {{tag.tag_text}}
                </a-tag>
              </p>
            </div>
          </a-col>
        </a-row>
      </template>
    </a-card>

    <a-tabs
      class="tabs-sliding"
      v-model="cuTabKey"
    >
      <div slot="tabBarExtraContent">
        <a-range-picker
          :value="rangeDateVal"
          valueFormat="YYYY-MM-DD"
          style="max-width:250px;"
          @change="rangeDateChange"
        />
        <a-button
          type="primary"
          icon="search"
          class="ml-10"
          :loading="tabDisabled"
          @click="refreshCuData()"
        >
          查询
        </a-button>
      </div>
      <a-tab-pane
        key="serviceRecordTab"
        tab="问诊情况统计"
        :disabled="tabDisabled"
      >
        <a-row
          type="flex"
          :gutter="24"
        >
          <a-col
            :span="24"
            :md="8"
            class="mb-24"
          >
            <a-card
              class="header-solid h-full card-profile-information"
              :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
              :headStyle="{paddingRight: 0,}"
            >
              <template #title>
                <h6 class="font-semibold m-0">问诊总次数：89 次</h6>
              </template>
              <template slot="extra">
                <div>
                  <a-button
                    type="link"
                    icon="sync"
                    :disabled="tabDisabled"
                    @click="refreshServiceRecordLeftData()"
                  >
                    刷新
                  </a-button>
                </div>
              </template>

              <a-divider class="mt-0" />

              <canvas
                ref="serviceRecordPieChart"
                class="chart-pie"
                :style="{'height': '300px'}"
              ></canvas>
            </a-card>
          </a-col>

          <a-col
            :span="24"
            :md="16"
            class="mb-24"
          >
            <a-card
              class="header-solid h-full card-profile-information"
              :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
              :headStyle="{paddingRight: 0,}"
            ><template #title>
                <h6 class="font-semibold m-0">每月问诊次数趋势</h6>
              </template>
              <template slot="extra">
                <div>
                  <a-button
                    type="link"
                    icon="sync"
                    :disabled="tabDisabled"
                    @click="refreshServiceRecordRightData()"
                  >
                    刷新
                  </a-button>
                </div>
              </template>

              <a-divider class="mt-0" />

              <canvas
                ref="serviceRecordLineChart"
                class="chart-line"
                :style="{'height': '300px'}"
              ></canvas>
            </a-card>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane
        key="record"
        tab="客户反馈统计"
        :disabled="tabDisabled"
      >

      </a-tab-pane>

      <a-tab-pane
        key="measure"
        tab="问卷统计"
        :disabled="tabDisabled"
      >

      </a-tab-pane>

      <a-tab-pane
        key="survey"
        tab="定制食谱统计"
        :disabled="tabDisabled"
      >

      </a-tab-pane>

    </a-tabs>

  </div>
</template>

<script>
import { getAdminUserInfo } from '@/api/dms/user'
import moment from 'moment'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  name: 'ViewDocUser',
  data () {
    return {
      cuTabKey: 'serviceRecordTab',
      docInfo: {},
      tabDisabled: true,
      serviceRecordPie: null,
      serviceRecordLine: null,
      rangeDateVal: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    }
  },
  mounted () {
    let ctx1 = this.$refs.serviceRecordPieChart.getContext('2d')
    let ctx2 = this.$refs.serviceRecordLineChart.getContext('2d')

    this.serviceRecordPie = new Chart(ctx1, {
      type: 'doughnut',
      data: {
        labels: ['问诊中', '随访中', '已完成'],
        datasets: [{
          backgroundColor: ['#ff6384', '#36a2eb', '#00cc33'],
          data: [15, 20, 60],
        }],
      },
      options: {
        plugins: {
          // title: {
          //   display: true,
          //   text: '服务状态环形统计'
          // },
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      }
    })

    this.serviceRecordLine = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: ['2024-01', '2024-02', '2024-03', '2024-04', '2024-05', '2024-06', '2024-07'],
        datasets: [{
          data: [65, 59, 80, 81, 56, 55, 40, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.3
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        // scales: {
        //   y: {
        //     min: 0,
        //     max: 100
        //   }
        // }
      }
    })
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('Enterprises.viewDocUser'))
    if (this.params === null || this.params.user === undefined || this.params.user < 1) {
      this.$message.error('请确认选择医生！')
      return this.$router.go(-1)
    }
    // 获取数据
    this.loadDocUserInfo()
  },
  methods: {
    moment,
    loadDocUserInfo () {
      getAdminUserInfo({ user: this.params.user, join: ['tags', 'enterprise', 'department'] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.user) {
          console.log(res.data)
          this.docInfo = res.data
          this.tabDisabled = false
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
      }).catch((e) => {
        return this.$router.go(-1)
      })
    },
    refreshServiceRecordLeftData () {
      // this.tabDisabled = true
    },
    refreshServiceRecordRightData () {
      // this.tabDisabled = true
    },
    refreshCuData () {
      console.log(this.rangeDateVal)
      // 点击查询按钮，按当前所在tab刷新数据
      if (this.cuTabKey === 'serviceRecordTab') {
        this.refreshServiceRecordLeftData()
        this.refreshServiceRecordRightData()
      }
    },
    rangeDateChange (v) {
      this.rangeDateVal = v
    }
  },
  beforeDestroy: function () {
    this.serviceRecordPie.destroy()
    this.serviceRecordLine.destroy()
  },
};
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
  .step-box {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ghost-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 1px solid #4299e1;
  }
}
</style>