<template>
  <a-drawer
    width="600"
    placement="right"
    :closable="true"
    :visible="patternItemAddDrawerVisibleCu"
    title="饮食模式内容管理"
    @close="closePatternItemAddDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="patternItemAddSpinning"
    >
      <div>
        <form-create
          v-model="cformPaternItemApi"
          :rule="cformPaternItemRule"
          :option="cformOption"
        ></form-create>

        <a-row>
          <a-col
            :offset="8"
            :span="16"
          >
            <a-button
              @click="closePatternItemAddDrawer()"
              class="mr-10"
            >取消</a-button>
            <a-button
              @click="sureSavePatternItem()"
              type="primary"
              icon="check"
            >确认保存</a-button>
          </a-col>
        </a-row>
      </div>

    </a-spin>
  </a-drawer>
</template>

<script>
import { rmFile } from '@/api/manage'
import { savePatternItemInfo } from '@/api/dms/dietaryPattern'

export default ({
  name: 'DietaryPatternItemAdd',
  data () {
    return {
      patternItemAddDrawerVisibleCu: false,
      patternItemAddSpinning: false,
      cformPaternItemApi: {},
      cformPaternItemRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'pattern_id'
        },
        {
          type: 'select',
          title: '分类',
          field: 'section_type',
          props: { placeholder: '请选择分类' },
          options: [],
          validate: [
            { required: true, message: '请选择分类' }
          ]
        },
        {
          type: 'input',
          title: '分类排序',
          field: 'section_sort', style: { width: '100%' },
          props: { type: 'number', placeholder: '分类排序', min: 0, step: 1, max: 127 },
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          validate: [
            { required: true, message: '请填写分类排序' }
          ]
        },
        {
          type: 'select',
          title: '内容类型',
          field: 'content_type',
          props: { placeholder: '请选择内容类型' },
          options: [],
          validate: [
            { required: true, message: '请选择内容类型' }
          ],
          on: {
            change: (e) => this.contentTypeChange(e)
          }
        },
        {
          type: 'input',
          title: '内容类型排序',
          field: 'content_sort', style: { width: '100%' },
          props: { type: 'number', placeholder: '内容类型排序', min: 0, step: 1, max: 127 },
          col: {
            span: 12
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          validate: [
            { required: true, message: '请填写内容类型排序' }
          ]
        },
        {
          type: 'input',
          title: '内容',
          field: 'content',
          hidden: false,
          props: { type: 'textarea', autoSize: { minRows: 4 }, placeholder: '请填写内容' },
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          validate: [
            { required: true, message: '请填写内容' }
          ]
        },
        {
          type: 'upload',
          field: 'content_img',
          hidden: true,
          title: '展示图片', col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: {
            onSuccess: this.onSuccessUpload,
            beforeUpload: this.beforeUpload,
            remove: this.rmUpImgFile,
            accept: 'image/*',
            action: this.$store.getters.userInfo.upUrlPre + 'auth/UploadFiles/upload',
            headers: { Authorization: 'Bearer ' + this.$store.state.user.token },
            data: { localPath: 'dietary_pattern' },
            limit: 1,
            listType: 'picture-card'
          },
          validate: [
            { required: true, message: '请上传选择展示图片' }
          ]
        }
      ],
      cformOption: {
        form: {
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      },
      upImgUrl: ''
    }
  },
  created () {
  },
  methods: {
    closePatternItemAddDrawer () {
      this.cformPaternItemApi.clearValidateState()
      this.cformPaternItemApi.resetFields()
      this.upImgUrl = ''
      this.patternItemAddDrawerVisibleCu = false
    },
    showDrawer (paramData) {
      // Object.assign(this.$data, this.$options.data())
      console.log(paramData)
      this.patternItemAddDrawerVisibleCu = true
      this.$nextTick(() => {
        this.cformPaternItemApi.updateRules({ // 更新规则
          'section_type': { options: this.$options.filters.selectOptionsData('pattern_item.section_type') },
          'content_type': { options: this.$options.filters.selectOptionsData('pattern_item.content_type') }
        })
        if (paramData.pattern_id !== undefined && paramData.pattern_id > 0) {
          this.cformPaternItemApi.setValue({ pattern_id: paramData.pattern_id })
        }
        if (paramData.id !== undefined && paramData.id > 0) {
          const cuPm = JSON.parse(JSON.stringify(paramData))
          cuPm.section_type = cuPm.section_type.toString()
          cuPm.content_type = cuPm.content_type.toString()
          if (cuPm.content_type === '2') {
            this.upImgUrl = cuPm.content
          }
          this.cformPaternItemApi.setValue(cuPm)
          this.contentTypeChange(cuPm.content_type)
        }
      })
    },
    contentTypeChange (v) {
      if (parseInt(v) === 2) {
        this.cformPaternItemApi.updateRules({ // 更新规则
          'content_img': { hidden: false },
          'content': { hidden: true }
        })
      } else {
        this.cformPaternItemApi.updateRules({ // 更新规则
          'content_img': { hidden: true },
          'content': { hidden: false }
        })
      }
    },
    sureSavePatternItem () {
      this.cformPaternItemApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformPaternItemApi.formData()
          if (cuFormData.content_type.toString() === '2') {
            cuFormData.content = this.upImgUrl
            delete cuFormData.content_img
          }
          console.log(cuFormData)
          this.patternItemAddSpinning = true
          savePatternItemInfo(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.closePatternItemAddDrawer()
              this.$emit('complete')
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.patternItemAddSpinning = false
          })
        } else {
          console.log(fail2)
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
    onSuccessUpload (file, fileList) {
      // 上传完成后的事件监听
      if (file.status === 'done') {
        const upRes = file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          fileList.forEach((oneFile) => {
            if (oneFile.uid === file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
          file.url = file.thumbUrl //上传成功赋值base64的图片缩略地址给url页面回显
          this.upImgUrl = upRes.data
        }
      }
    },
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 2
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过2MB！')
        return false
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      this.spinning = true
      rmFile({ fileName: this.upImgUrl }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.upImgUrl = ''
          this.cformPaternItemApi.setValue({ content_img: '' })
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
</style>