<template>
  <a-drawer
    width="600"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="personalDrawerVisibleCu"
    title="个人信息修改"
    @close="closePersonalDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <form-create
        v-model="cformPersonalApi"
        :rule="cformPersonalRule"
        :option="cformOption"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="8"
          :span="12"
        >
          <a-button
            icon="close"
            @click="closePersonalDrawer"
            type="default"
            class="mr-5"
          >关闭</a-button>
          <a-button
            icon="check"
            @click="clickSave"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { savePatientPersonalInfo } from '@/api/dms/patient'
import { getSelectListTag } from '@/api/manage'
import moment from 'moment'

export default ({
  name: 'PersonalInfoDrawer',
  props: {
    personalDrawerVisible: {
      type: Boolean,
      default: false
    },
    parentParams: {
      type: Object,
      default: null
    }
  },
  watch: {
    personalDrawerVisible: {
      handler (newVal, oldVal) {
        // 要显示的时候发起请求获取最新的列表数据
        this.personalDrawerVisibleCu = this.personalDrawerVisible
        if (this.personalDrawerVisible) {
          this.spinning = true

          // 获取已有信息
          this.loadPatientOriData()
        }
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  data () {
    return {
      spinning: false,
      personalDrawerVisibleCu: false,
      cformPersonalApi: {},
      cformPersonalRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'hidden',
          field: 'domain_platform_id',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '诊疗原因',
          field: 'reason',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, size: 'small', placeholder: '请填写诊疗原因' }
        },
        {
          type: 'input',
          title: '期望',
          field: 'expectation',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, size: 'small', placeholder: '请填写期望' }
        },
        {
          type: 'select',
          title: '目标',
          field: 'goal',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { mode: 'tags', size: 'small', placeholder: '请填写目标', allowClear: true, optionFilterProp: 'children' }
        },
        {
          type: 'input',
          title: '目标备注',
          field: 'goal_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, size: 'small', placeholder: '请填写目标备注' }
        },
        {
          type: 'select',
          title: '婚姻状况',
          field: 'marriage',
          col: {
            span: 9
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请选择婚姻状况', size: 'small' },
          options: [],
          validate: [
            { required: true, message: '请选择婚姻状况' }
          ]
        },
        {
          type: 'input',
          title: '婚姻状况备注',
          field: 'marriage_remarks',
          col: {
            span: 15
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写婚姻状况备注', size: 'small' }
        },
        {
          type: 'select',
          title: '抽烟情况',
          field: 'smoker',
          col: {
            span: 9
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请选择抽烟情况', size: 'small' },
          options: [],
          validate: [
            { required: true, message: '请选择抽烟情况' }
          ]
        },
        {
          type: 'input',
          title: '抽烟情况备注',
          field: 'smoker_remarks',
          col: {
            span: 15
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写抽烟情况备注', size: 'small' }
        },
        {
          type: 'select',
          title: '喝酒情况',
          field: 'alcohol',
          col: {
            span: 9
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请选择喝酒情况', size: 'small' },
          options: [],
          validate: [
            { required: true, message: '请选择喝酒情况' }
          ]
        },
        {
          type: 'input',
          title: '喝酒情况备注',
          field: 'alcohol_remarks',
          col: {
            span: 15
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写喝酒情况备注', size: 'small' }
        },
        {
          type: 'select',
          title: '排便习惯',
          field: 'defecation',
          col: {
            span: 9
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请选择排便习惯', size: 'small' },
          options: [],
          validate: [
            { required: true, message: '请选择排便习惯' }
          ]
        },
        {
          type: 'input',
          title: '排便习惯备注',
          field: 'defecation_remarks',
          col: {
            span: 15
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写喝酒情况备注', size: 'small' }
        },
        {
          type: 'select',
          title: '睡眠质量',
          field: 'sleep',
          col: {
            span: 9
          },
          wrap: {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
          },
          props: { placeholder: '请选择睡眠质量', size: 'small' },
          options: [],
          validate: [
            { required: true, message: '请选择睡眠质量' }
          ]
        },
        {
          type: 'input',
          title: '睡眠质量备注',
          field: 'sleep_remarks',
          col: {
            span: 15
          },
          wrap: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
          },
          props: { placeholder: '请填写睡眠质量备注', size: 'small' }
        },
        {
          type: 'TimePicker',
          title: '睡眠时间',
          field: 'sleep_start_time', style: { width: 'calc(100% - 16px)' },
          suffix: ' ~',
          props: { placeholder: '请选择睡觉时间', format: 'HH:mm', inputReadOnly: true, size: 'small' },
          col: {
            span: 13
          },
          wrap: {
            labelCol: { span: 7 },
            wrapperCol: { span: 17 }
          },
          validate: [
            { required: true, message: '请选择睡觉时间' }
          ]
        },
        {
          type: 'TimePicker',
          title: '',
          field: 'sleep_end_time', style: { width: '100%' },
          props: { placeholder: '请选择起床时间', format: 'HH:mm', inputReadOnly: true, size: 'small' },
          col: {
            span: 11
          },
          wrap: {
            labelCol: { span: 1 },
            wrapperCol: { span: 22 }
          },
          validate: [
            { required: true, message: '请选择起床时间' }
          ]
        },
        {
          type: 'input',
          title: '身体状况',
          field: 'physical_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写身体状况' }
        },
        {
          type: 'input',
          title: '其他生活习惯',
          field: 'other_remarks',
          col: {
            span: 24
          },
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他生活习惯' }
        },
      ],
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 9
        },
        wrap: {
          labelCol: { span: 10 },
          wrapperCol: { span: 14 }
        },
        submitBtn: false
      },
      params: {}
    }
  },
  created () {

  },
  methods: {
    moment,
    loadPatientOriData () {
      this.$nextTick(() => {
        this.spinning = true

        getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['0', '1', '20'] }).then(res => {
          if (res.status !== undefined && res.status === 1) {
            this.cformPersonalApi.updateRules({ // 更新规则
              'goal': { options: res.data }
            })
            if (this.parentParams.oriInfo.id !== undefined && this.parentParams.oriInfo.id > 0) {
              this.cformPersonalApi.setValue({ goal: JSON.parse(this.parentParams.oriInfo.goal) })
            }
          }
          this.spinning = false
        })

        this.cformPersonalApi.updateRules({ // 更新规则
          'marriage': { options: this.$options.filters.selectOptionsData('patient.marriage') },
          'smoker': { options: this.$options.filters.selectOptionsData('patient.smoker') },
          'alcohol': { options: this.$options.filters.selectOptionsData('patient.alcohol') },
          'sleep': { options: this.$options.filters.selectOptionsData('patient.sleep') },
          'defecation': { options: this.$options.filters.selectOptionsData('patient.defecation') },
        })
        // 有传入原数据才需要回显
        if (this.parentParams.oriInfo.id !== undefined && this.parentParams.oriInfo.id > 0) {
          this.parentParams.oriInfo.sleep_start_time = moment(this.parentParams.oriInfo.sleep_start_time, 'HH:mm')
          this.parentParams.oriInfo.sleep_end_time = moment(this.parentParams.oriInfo.sleep_end_time, 'HH:mm')
          this.cformPersonalApi.setValue(this.parentParams.oriInfo)
        } else {
          this.cformPersonalApi.setValue({ patient_id: this.parentParams.patient, sleep_start_time: moment('22:00', 'HH:mm'), sleep_end_time: moment('08:00', 'HH:mm') })
        }
        this.spinning = false
      })
    },
    closePersonalDrawer () {
      this.personalDrawerVisibleCu = false
      this.$emit('personalDrawerVisibleChange', false)
    },
    clickSave () {
      this.cformPersonalApi.validate((valid, fail) => {
        if (valid) {
          this.spinning = true
          let perData = this.cformPersonalApi.formData()
          perData.sleep_start_time = (typeof perData.sleep_start_time === 'object' && perData.sleep_start_time._i !== undefined) ? perData.sleep_start_time._i : perData.sleep_start_time
          perData.sleep_end_time = (typeof perData.sleep_end_time === 'object' && perData.sleep_end_time._i !== undefined) ? perData.sleep_end_time._i : perData.sleep_end_time
          savePatientPersonalInfo(perData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.$message.success('保存成功！')
              this.cformPersonalApi.resetFields()
              this.closePersonalDrawer()
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })

        } else {
          this.$message.error('请确认必填项填写完成！')
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
/deep/ .ant-form-item {
  margin-bottom: 8px;
}
</style>