<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>随访日志</span> -->
          <br>
          <!-- <span>Follow-up Record</span> -->
        </div>
        <div>
          <!-- <img
            class="nuContentLogo"
            src="/images/foodsense_logo.png"
          /> -->
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div class="nuContentWithSider">
        <div class="nuDivSectionTitle">
          <span>随访日志 <small>Follow-up Record</small></span>
        </div>

        <table class="nuRecordTable fontNuBase">
          <thead>
            <tr>
              <th>
                <div class="textWeek">
                  <div>第一周</div>
                  <div class="fontNuBaseEng">Week 5</div>
                </div>
                <img
                  class="imgWeek"
                  src="/images/face1.png"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>饮食日记：</div>
                <div class="fontNuBaseEng">Food Record:</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>身体状况记录：</div>
                <div class="fontNuBaseEng">Symptom Record:</div>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="nuRecordTable fontNuBase">
          <thead>
            <tr>
              <th>
                <div class="textWeek">
                  <div>第二周</div>
                  <div class="fontNuBaseEng">Week 6</div>
                </div>
                <img
                  class="imgWeek"
                  src="/images/face2.png"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>饮食日记：</div>
                <div class="fontNuBaseEng">Food Record:</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>身体状况记录：</div>
                <div class="fontNuBaseEng">Symptom Record:</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="nuRecordTable fontNuBase">
          <thead>
            <tr>
              <th>
                <div class="textWeek">
                  <div>第三周</div>
                  <div class="fontNuBaseEng">Week 7</div>
                </div>
                <img
                  class="imgWeek"
                  src="/images/face3.png"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>饮食日记：</div>
                <div class="fontNuBaseEng">Food Record:</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>身体状况记录：</div>
                <div class="fontNuBaseEng">Symptom Record:</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="nuRecordTable fontNuBase">
          <thead>
            <tr>
              <th>
                <div class="textWeek">
                  <div>第四周</div>
                  <div class="fontNuBaseEng">Week 8</div>
                </div>
                <img
                  class="imgWeek"
                  src="/images/face4.png"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>饮食日记：</div>
                <div class="fontNuBaseEng">Food Record:</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>身体状况记录：</div>
                <div class="fontNuBaseEng">Symptom Record:</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="nuContentBg7">

        </div>
      </div>
      <div class="nuContentRightSider">
        <div class="nuContentRightSiderText2">
          <div class="fontNuBase">随访日志</div>
          <div class="fontNuS4">Follow-up Record</div>
        </div>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import NutritionFooter from './NutritionFooter'

export default {
  name: 'NutritionPage7',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    reportInfo: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./common";

.nuRecordTable {
  // height: 280px;
  width: 100%;
}

.nuRecordTable th {
  background-color: #7dcceb;
  border: 1px solid #7dcceb;
  color: #ffffff;
  text-align: left;
  font-weight: normal;
}

.nuRecordTable td {
  height: 55px;
  padding: 15px;
  border: 1px solid #7dcceb;
  color: #7dcceb;
  vertical-align: top;
}

.fontNuContentBase {
  font-size: 20px;
  line-height: 40px;
}

.fontNuContentBaseEng {
  font-size: 15px;
  line-height: 28px;
}

.textWeek {
  display: inline-block;
  margin-left: 15px;
  // margin-top: 5px;
  width: 150px;
}

.imgWeek {
  float: right;
  width: 40px;
  margin-right: 10px;
  margin-top: 5px;
}

.nuContentBg7 {
  width: 100%;
  height: 440px;
  margin-top: 40px;
  background-image: url("/images/nutrition_bg7_0301.jpg");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nuContentRightSiderText2 {
  top: 1120px;
}
</style>
