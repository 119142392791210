<template>
  <a-spin
    :tip="tip"
    :spinning="spinning"
  >
    <a-card
      :bordered="false"
      id="appoint-info"
      class="header-solid mb-24"
    >
      <template #title>
        <h5 class="mb-0 font-semibold">预约目的及期望</h5>
      </template>

      <!-- <a-button
      slot="extra"
      type="primary"
      icon="file-search"
      class="px-10 py-5 "
      @click="clickPrescriptionBtn"
    >查看历史记录</a-button> -->

      <form-create
        v-if="parentParams.clientAppointment !== undefined && parentParams.clientAppointment > 0"
        v-model="cformClientAptApi"
        :rule="cformClientAptRule"
        :option="cformOption"
        @submit="saveClientAptRequest"
      >
      </form-create>

      <form-create
        v-model="cformAppointApi"
        :rule="cformAppointRule"
        :option="cformAppointOption"
        @submit="saveAppointRequest"
      >
      </form-create>

      <a-row>
        <a-col
          :offset="11"
          :span="12"
        >
          <a-button
            @click="cformAppointApi.submit()"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { saveClientAppointmentDealInfo } from '@/api/dms/clientAppointment'
import { getPatientAppointInfo } from '@/api/dms/patient'
import { savePatientServiceRecord } from '@/api/dms/serviceRecord'

export default ({
  // 客户预约目的及期望card
  name: 'AppointInfo',
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    cformOption: {
      type: Object,
      default: { // 两列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 12
        },
        wrap: {
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        },
        submitBtn: false
      }
    },
    cformAppointOption: {
      type: Object,
      default: { // 一列布局
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 3 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      }
    }
  },
  data () {
    return {
      cformAppointApi: {},
      cformClientAptApi: {},
      cformAppointRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'client_appoint_id'
        },
        {
          type: 'hidden',
          field: 'service_record_id'
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'input',
          title: '预约原因',
          field: 'reason',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写预约原因' },
          validate: [
            { required: true, message: '请填写预约原因', whitespace: true }
          ]
        },
        {
          type: 'input',
          title: '期望达到效果',
          field: 'expectation',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写期望达到效果' },
          validate: [
            { required: true, message: '请填写期望达到效果', whitespace: true }
          ]
        },
        {
          type: 'select',
          title: '目标',
          field: 'goal',
          props: { placeholder: '请选择目标' },
          options: [],
          validate: [
            { required: true, message: '请选择目标' }
          ]
        },
        {
          type: 'input',
          title: '目标备注',
          field: 'goal_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写目标备注' }
        },
        {
          type: 'input',
          title: '其他说明',
          field: 'other_remarks',
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写其他说明' }
        },
      ],
      cformClientAptRule: [
        {
          type: 'hidden',
          field: 'id'
        },
        {
          type: 'hidden',
          field: 'patient_id'
        },
        {
          type: 'hidden',
          field: 'enterprise_id',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'DatePicker',
          title: '预约处理时间',
          field: 'deal_time',
          style: { width: '100%' },
          props: { showTime: true, format: 'YYYY-MM-DD HH:mm:ss' },
          validate: [
            { required: true, message: '请确认选择预约处理时间' }
          ]
        },
        {
          type: 'input',
          title: '预约处理结果',
          field: 'deal_remarks',
          wrap: {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 }
          },
          props: { type: 'textarea', autoSize: { minRows: 2 }, placeholder: '请填写预约处理结果' }
        },
      ],
      spinning: false,
      tip: '处理中...'
    }
  },
  created () {
    this.$nextTick(() => {
      this.cformAppointApi.setValue({ patient_id: this.parentParams.patient })
      if (this.parentParams.clientAppointment !== undefined && this.parentParams.clientAppointment > 0) {
        this.parentParams.dealTime = (this.parentParams.dealTime === undefined || this.parentParams.dealTime === null) ? moment().format('YYYY-MM-DD HH:mm:ss') : moment(this.parentParams.dealTime).format('YYYY-MM-DD HH:mm:ss')
        this.cformClientAptApi.setValue({ patient_id: this.parentParams.patient, id: this.parentParams.clientAppointment, deal_time: this.parentParams.dealTime, deal_remarks: this.parentParams.dealRemarks })
        this.cformAppointApi.setValue({ client_appoint_id: this.parentParams.clientAppointment })
      }
      this.cformAppointApi.updateRules({ // 更新规则
        'goal': { options: this.$options.filters.selectOptionsData('patient.appoint_goal') }
      })
    })
    this.loadPatientOriInfo()
  },
  methods: {
    loadPatientOriInfo () {
      // 调用之前单独触发是否显示spinning，请求完成后统一取消
      this.tip = '加载中...'
      getPatientAppointInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.cformAppointApi.setValue({ id: res.data.id, reason: res.data.reason, expectation: res.data.expectation, goal: res.data.goal.toString(), goal_remarks: res.data.goal_remarks, other_remarks: res.data.other_remarks })
        }
        this.spinning = false
      })
    },
    saveAppointRequest (data, formObj) {
      this.tip = '处理中...'
      this.spinning = true
      const that = this
      savePatientServiceRecord(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.cformAppointApi.setValue({ service_record_id: res.data.record })
          this.$emit('saveServiceRecordChange', res.data.record)
          if (that.parentParams.clientAppointment !== undefined && that.parentParams.clientAppointment > 0) {
            that.cformClientAptApi.submit()
          } else {
            that.$confirm({
              title: '预约服务信息保存成功！',
              content: false,
              cancelText: '留在当前页面',
              okText: '返回管理列表',
              icon: () => {
                return that.$createElement('a-icon', {
                  props: { type: 'check-circle' },
                  style: { color: '#52c41a' }
                })
              },
              onOk () {
                this.spinning = false
                return that.$router.go(-1)
              },
              onCancel () {
                that.loadPatientOriInfo()
                return false
              }
            })
          }
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    },
    saveClientAptRequest (data, formObj) {
      this.tip = '处理中...'
      this.spinning = true
      const that = this
      saveClientAppointmentDealInfo(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '预约服务信息保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              this.spinning = false
              return that.$router.go(-1)
            },
            onCancel () {
              that.loadPatientOriInfo()
              return false
            }
          })
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    },
  },
})

</script>