<template>
  <div class="nuContentPage">
    <div class="nuDivContentHeader">
      <div class="nuContentHeader">
        <div>
          <!-- <span>客户个人资料</span> -->
          <br>
          <!-- <span>Customer Personal Information</span> -->
        </div>
        <div>
          <!-- <img
            class="nuContentLogo"
            src="/images/foodsense_logo.png"
          /> -->
        </div>
      </div>
    </div>

    <div class="nuDivContent">
      <div class="nuContentWithSider">
        <div class="nuDivSectionTitle">
          <span>客户信息 <small>Client Information</small></span>
        </div>

        <table class="nuContentDetail31 fontNuContentBase">
          <tbody>
            <tr>
              <td>客户姓名 <span class="fontNuContentBaseEng">Name：</span>{{ patientInfo.name !== undefined ? patientInfo.name : '-' }}
                <!-- <span v-if="patientInfo.name_py !== undefined && patientInfo.name_py !== '' && patientInfo.name_py !== null"> / {{ patientInfo.name_py }}</span> -->
              </td>
              <td>出生日期 <span class="fontNuContentBaseEng">D.o.B：</span> {{ patientInfo.birth_date !== undefined ? patientInfo.birth_date : '-' }}</td>
              <td>报告日期 <span class="fontNuContentBaseEng">Report Date：</span> {{ moment().format('yyyy-MM-DD') }}</td>
            </tr>
            <tr>
              <td colspan="3">食谱名称 <span class="fontNuContentBaseEng">Recipe Name：</span> {{ patientFoodOri.menu_name !== undefined ? patientFoodOri.menu_name : '' }}
              </td>
            </tr>
            <tr>
              <td colspan="3">食谱开始 - 结束时间 <span class="fontNuContentBaseEng">Start - End Date：</span>{{ patientFoodOri.start_date !== undefined ? patientFoodOri.start_date : '' }} - {{ patientFoodOri.end_date !== undefined ? patientFoodOri.end_date : '' }}
              </td>
            </tr>
            <tr>
              <td colspan="3">不喜欢食物 <span class="fontNuContentBaseEng">Dislike Foods：</span> {{ patientFoodOri.food_dislike !== undefined ? patientFoodOri.food_dislike : '' }}
              </td>
            </tr>
            <tr>
              <td colspan="3">过敏信息 <span class="fontNuContentBaseEng">Allergy Information：</span> {{ patientFoodOri.allergies !== undefined ? patientFoodOri.allergies : '' }}
              </td>
            </tr>
            <tr>
              <td colspan="3">不耐受的食物 <span class="fontNuContentBaseEng">Intolerant Foods：</span> {{ patientFoodOri.food_intolerant !== undefined ? patientFoodOri.food_intolerant : '' }}
              </td>
            </tr>
            <tr>
              <td colspan="3">营养补剂 <span class="fontNuContentBaseEng">Nutritional Supplements：</span> {{ patientFoodOri.supplement !== undefined ? patientFoodOri.supplement : '' }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="nuDivSectionTitle mt-50">
          <span>食谱简介 <small>Recipe Introduction</small></span>
        </div>
        <div class="nuContentDetail32">
          <p class="fontNuBase">{{ patientFoodOri.menu_intro !== undefined ? patientFoodOri.menu_intro : '' }}</p>
        </div>

        <!-- <div class="nuContentBg34">

        </div> -->

        <div class="nuContentBg35">
          <div class="nuContentBg351">

          </div>
          <div class="nuContentBg352">

          </div>
        </div>
      </div>
      <div class="nuContentRightSider">
        <div class="nuContentRightSiderText2">
          <div class="fontNuBase">客户个人资料</div>
          <div class="fontNuS4">Client Information</div>
        </div>
      </div>
    </div>

    <NutritionFooter
      :pageIndex="pageIndex"
      :pageTotal="pageTotal"
      :patient="patientInfo"
    />
  </div>
</template>

<script>
import moment from 'moment'
import NutritionFooter from './NutritionFooter'

export default {
  name: 'FoodPage3',
  components: {
    NutritionFooter
  },
  props: {
    patientInfo: {
      type: Object,
      default: null
    },
    patientFoodOri: {
      type: Object,
      default: null
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
    }
  },
  methods: {
    moment
  }
}
</script>

<style lang="scss" scoped>
@import "./common";

.nuContentDetail31 {
  // height: 475px;
  width: 100%;
}

.nuContentDetail31 th {
  background-color: #59aacc;
  color: #ffffff;
  text-align: center;
  height: 60px;
  font-size: 26px;
}

.nuContentDetail31 tr:nth-child(odd) {
  background-color: #eaedf6;
}

.nuContentDetail31 td {
  height: 60px;
  padding: 5px;
}

.fontNuContentBase {
  font-size: 20px;
  line-height: 40px;
}

.fontNuContentBaseEng {
  font-size: 15px;
  line-height: 28px;
}

.nuContentDetail32 {
  width: 100%;
  height: 100px;
  background: #dff1f9;
  padding: 20px 10px;
  line-height: 50px;
}

.nuContentDetail32 p {
  text-indent: 40px;
}

// .nuContentDetail32 tr td:first-of-type {
//   padding: 5px 20px;
//   width: 190px;
//   text-align: center;
//   color: #ffffff;
// }

// .nuContentDetail32 tr td:last-of-type {
//   padding: 10px;
// }

// .nuContentDetail32 tr td:last-of-type div {
//   margin: 5px 15px;
//   max-width: 750px;
//   max-height: 235px;
//   overflow: hidden;
// }

// .nuContentDetail32negative {
//   height: 300px;
//   margin-top: 40px;
//   // margin-bottom: 60px;
//   width: 100%;
// }

// .nuContentDetail32negative td {
//   text-align: center;
//   padding: 20px;
//   border: 1px solid #59aacc;
// }

.trlevel4 td:first-of-type {
  background-color: #f00708;
}

.trlevel4 {
  border: 1px solid #f00708;
}

.trlevel3 td:first-of-type {
  background-color: #f67728;
}

.trlevel3 {
  border: 1px solid #f67728;
}

.trlevel2 td:first-of-type {
  background-color: #fabd03;
}

.trlevel2 {
  border: 1px solid #fabd03;
}

.nuContentDetail33 {
  margin-top: 40px;
  width: 100%;
  border: 1px solid #59aacc;
}

.nuContentDetail33 th {
  background-color: #59aacc;
  color: #ffffff;
  text-align: center;
  height: 60px;
  font-size: 26px;
}

.nuContentDetail33 td {
  height: 60px;
  padding: 5px;
  text-align: center;
  width: 50%;
}

.tdCheckBox {
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 18px;
}

.nuContentBg34 {
  width: 100%;
  height: 280px;
  margin-top: 40px;
  background-image: url("/images/nutrition_bg31_0301.jpg");
  background-position-x: center;
  background-position-y: middle;
  background-repeat: no-repeat;
  background-size: cover;
}

.nuContentBg35 {
  width: 100%;
  margin-top: 20px;
  flex: 1 1 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
}

.nuContentBg351 {
  width: 49%;
  height: 100%;
  background-image: url("/images/nutrition_bg32_0301.jpg");
  background-position-x: center;
  background-position-y: middle;
  background-repeat: no-repeat;
  background-size: cover;
}

.nuContentBg352 {
  width: 49%;
  height: 100%;
  background-image: url("/images/nutrition_bg33_0301.jpg");
  background-position-x: center;
  background-position-y: middle;
  background-repeat: no-repeat;
  background-size: cover;
}

.nuContentRightSiderText2 {
  color: #ffffff;
}

.checkImg {
  height: 24px;
  margin-top: 12px;
}
.medicalTd {
  font-size: 18px;
  padding: 5px !important;
  div:last-child {
    word-break: break-word;
    font-size: 15px;
  }
}
</style>
