<template>
  <!-- 饮食习惯 Card -->
  <a-card
    class="header-solid h-full card-profile-information"
    :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }"
    :headStyle="{paddingRight: 0,}"
  >
    <template #title>
      <h6 class="font-semibold m-0">饮食习惯</h6>
    </template>
    <template slot="extra">
      <div>
        <a-button
          type="link"
          size="small"
          :disabled="loadingDietary"
          @click="refreshDietaryOriInfo()"
        >
          刷新
        </a-button>
        <a-button
          type="link"
          size="small"
          class="ml-10 mr-10"
          :disabled="loadingDietary"
          @click="clickEditDietary()"
          v-if="Object.keys(dietaryInfo).length > 0"
        >
          编辑
        </a-button>
        <a-button
          type="link"
          size="small"
          class="ml-10 mr-10"
          :disabled="loadingDietary"
          @click="clickEditDietary()"
          v-else
        >
          添加
        </a-button>
      </div>
    </template>

    <a-spin
      :tip="tip"
      :spinning="loadingDietary"
    >
      <a-descriptions
        title=""
        :column="1"
        v-if="Object.keys(dietaryInfo).length > 0"
      >
        <a-descriptions-item label="一周运动情况">
          <span v-if="dietaryInfo.activity_level !== undefined && dietaryInfo.activity_level !== null">
            {{ 'calorie.calc' | showText(dietaryInfo.activity_level.toString())  }}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label="建议运动量">
          <span v-if="dietaryInfo.advise_activity_level !== undefined && dietaryInfo.advise_activity_level !== null">
            {{ 'calorie.calc' | showText(dietaryInfo.advise_activity_level.toString())  }}
          </span>
        </a-descriptions-item>

        <a-descriptions-item label="每日建议摄入热量">{{ dietaryInfo.calorie }} Kcal</a-descriptions-item>

        <a-descriptions-item label="三大营养素">
          <a-progress
            v-if="dietaryInfo.carbohydrates !== undefined"
            type="circle"
            :width="80"
            :percent="dietaryInfo.carbohydrates !== null ? dietaryInfo.carbohydrates : 0"
          >
            <template #format="percent">
              <span>碳水<br>{{ percent }}%</span>
            </template>
          </a-progress>
          <a-progress
            v-if="dietaryInfo.protein !== undefined"
            type="circle"
            :width="80"
            strokeColor="green"
            :percent="dietaryInfo.protein !== null ? dietaryInfo.protein : 0"
            class="ml-10"
          >
            <template #format="percent">
              <span>蛋白质<br>{{ percent }}%</span>
            </template>
          </a-progress>
          <a-progress
            v-if="dietaryInfo.fat !== undefined"
            type="circle"
            :width="80"
            strokeColor="red"
            :percent="dietaryInfo.fat !== null ? dietaryInfo.fat : 0"
            class="ml-10"
          >
            <template #format="percent">
              <span>脂肪<br>{{ percent }}%</span>
            </template>
          </a-progress>
        </a-descriptions-item>
        <a-descriptions-item label="日饮水量">
          {{ dietaryInfo.water_intake > 0 ? dietaryInfo.water_intake +' 毫升' : '' }}
          <span v-if="dietaryInfo.advise_water_intake !== undefined && dietaryInfo.advise_water_intake !== null && dietaryInfo.advise_water_intake > 0">
            （建议饮水 {{ dietaryInfo.advise_water_intake }} 毫升）
          </span>
        </a-descriptions-item>

        <a-descriptions-item label="营养补充剂">{{ dietaryInfo.supplement }}</a-descriptions-item>
        <a-descriptions-item label="营养建议">{{ dietaryInfo.recommendation }}</a-descriptions-item>
        <a-descriptions-item label="推荐饮食模式">
          <template v-if="dietaryInfo.patternArray !== undefined && dietaryInfo.patternArray.length > 0">
            <a-tag
              color="green"
              v-for="(da,pai) in dietaryInfo.patternArray"
              :key="pai"
              @click="clickshowPattern(da.id)"
              style="cursor:pointer;"
            >
              {{ da.pattern_name }}
            </a-tag>
          </template>
        </a-descriptions-item>

      </a-descriptions>

      <hr />

      <a-descriptions
        title=""
        :column="1"
        v-if="Object.keys(dietaryInfo).length > 0"
      >
        <a-descriptions-item label="喜好饮食类型">
          <a-tag
            color="blue"
            v-for="(mdv,mdi) in dietaryInfo.favorite_diet_type"
            :key="mdi"
          >
            {{ 'patient.favorite_diet_type' | showText(mdv) }}
          </a-tag>
          <span> {{ dietaryInfo.favorite_diet_type_remarks!== null ? "("+ dietaryInfo.favorite_diet_type_remarks + ")" : ""}} </span>
        </a-descriptions-item>
        <a-descriptions-item label="喜欢的食物">{{ dietaryInfo.food_favorite }}</a-descriptions-item>
        <a-descriptions-item label="不喜欢的食物">{{ dietaryInfo.food_dislike }}</a-descriptions-item>

        <a-descriptions-item label="过敏食物">{{ dietaryInfo.allergies_remarks }}</a-descriptions-item>
        <a-descriptions-item label="不耐受食物">{{ dietaryInfo.food_intolerant_remarks }}</a-descriptions-item>

        <a-descriptions-item label="缺乏营养">{{ dietaryInfo.nutrition_deficiency_remarks }}</a-descriptions-item>
        <a-descriptions-item label="饮食习惯备注">{{ dietaryInfo.other_remarks }}</a-descriptions-item>
      </a-descriptions>

      <a-empty v-else>
      </a-empty>
    </a-spin>

    <!-- 饮食模式的抽屉窗口 -->
    <dietary-pattern-detail
      :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
      :parentParams="dietaryPatternParams"
      @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
    />

    <!-- 饮食习惯修改抽屉 -->
    <dietary-info-drawer
      :parentParams="patDietaryParam"
      :dietaryDrawerVisible="dietaryDrawerVisible"
      @dietaryDrawerVisibleChange="dietaryDrawerVisibleChange"
    />

  </a-card>
  <!-- / 饮食习惯 Card -->
</template>

<script>
import { getPatientDietaryInfo } from '@/api/dms/patient'
import DietaryInfoDrawer from './DietaryInfoDrawer'
import { DietaryPatternDetail } from '@/components/DietaryPattern'

export default ({
  name: 'CardDietaryInfo',
  components: { DietaryInfoDrawer, DietaryPatternDetail },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    parentParams: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      tip: '加载中...',
      loadingDietary: false,
      dietaryInfo: {},
      dietaryDrawerVisible: false,
      patDietaryParam: {},
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {}
    }
  },
  created () {
    this.refreshDietaryOriInfo()
  },
  methods: {
    refreshDietaryOriInfo () {
      this.loadingDietary = true
      // 加载个人饮食习惯数据
      getPatientDietaryInfo({ patient: this.parentParams.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['pattern'] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.dietaryInfo = res.data
          console.log(res.data)
          this.dietaryInfo.favorite_diet_type = JSON.parse(this.dietaryInfo.favorite_diet_type)
        }
        this.loadingDietary = false
      })
    },
    clickEditDietary () {
      this.patDietaryParam = { patient: this.parentParams.patient, oriInfo: this.dietaryInfo }
      this.dietaryDrawerVisible = true
    },
    dietaryDrawerVisibleChange (v) {
      this.dietaryDrawerVisible = v
      if (!v) {
        // 关闭抽屉的时候重新加载数据
        this.refreshDietaryOriInfo()
      }
    },
    clickshowPattern (cuPattern) {
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
      console.log(this.dietaryPatternParams)
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
  }
})

</script>