<template>
  <div class="nuContentPage">
    <div class="nuDivHeader">
      <div class="nuMenuHeader">
        <span>目录</span>
        <span style="float:right;">CONTENT</span>
      </div>
    </div>

    <div class="nuDivMenu">
      <div class="nuMenuItem">
        <div>
          <div>客户个人资料</div>
          <div class="nuMenuItemEng">Customer Personal Information</div>
        </div>
        <div class="nuMenuLineDiv"> </div>
        <div class="nuMenuPage">01</div>
      </div>

      <div class="nuMenuItem">
        <div v-if="showMark === 'patientFood'">
          <div>一日食谱</div>
          <div class="nuMenuItemEng">Daily Recipe</div>
        </div>
        <div v-else>
          <div>诊断及建议</div>
          <div class="nuMenuItemEng">Diagnosis & Recommendations</div>
        </div>
        <div class="nuMenuLineDiv"> </div>
        <div class="nuMenuPage">02</div>
      </div>

      <div
        class="nuMenuItem"
        v-if="showMark !== 'patientFood'"
      >
        <div>
          <div>随访日志</div>
          <div class="nuMenuItemEng">Follow-up Record</div>
        </div>
        <div class=""> </div>
        <div class="nuMenuPage"></div>
      </div>
    </div>

    <div class="nuMenuPageImg">

    </div>
  </div>
</template>

<script>

export default {
  name: 'NutritionPage2',
  props: {
    showMark: {
      type: String,
      default: null
    },
  }

}
</script>

<style lang="scss" scoped>
@import "./common";

// .nuMainDivPageMenu {
//   width: 100%;
//   height: 100%;
//   background-image: url("/images/nutrition_bg_2.png");
//   background-position-x: center;
//   background-repeat: no-repeat;
//   background-size: contain;
// }

.nuDivHeader {
  padding: 60px 60px 40px 60px;
}

.nuMenuHeader {
  // display: flex;
  // position: relative;
  // align-items: center;
  // align-self: center;
  // flex-direction: row;
  // justify-content: space-between;
  color: #59aacc;
  // border-top: 3px solid #59AACC;
  border-bottom: 3px solid #59aacc;
  height: 80px;
  font-size: 30px;
  padding-top: 15px;
}

.nuMenuHeader div {
  display: inline-block;
}

.nuDivMenu {
  margin: 0 60px;
  height: 280px;
}

.nuMenuItem {
  height: 65px;
  // margin-bottom: 10px;
  font-size: 22px;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  color: #59aacc;
}

.nuMenuItem div:nth-of-type(1) {
  white-space: nowrap;
}

.nuMenuItem div:nth-of-type(2) {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.nuMenuItemEng {
  font-size: 15px;
}

.nuMenuLineDiv {
  height: 5px;
  margin: 0 20px;
  border-top: 1px dashed #595959;
}

.nuMenuPage {
  color: #595959;
  width: 40px;
  text-align: center;
}

.nuMenuPageImg {
  width: 100%;
  height: 1210px;
  background-image: url("/images/nutrition_bg2_0301.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
