<template>
  <div>
    <a-drawer
      width="400"
      :maskClosable="!recordDrawerSpinning"
      placement="right"
      :closable="true"
      :visible="recordDrawerVisible"
      @close="closeRecordDrawer"
      id="drawer-body-div"
    >
      <div slot="title">
        <a-row>
          <a-col
            :span="12"
            class="title-text"
          >
            预约记录列表
          </a-col>
        </a-row>
      </div>
      <a-spin
        tip="处理中..."
        size="large"
        :spinning="recordDrawerSpinning"
      >
        <div v-if="recordData.length > 0">
          <div
            v-for="(rdv,idx) in recordData"
            :key="idx"
          >
            <a-row>
              <a-col :span="18">
                <a-row>
                  ID号： {{ rdv.id }}
                </a-row>
                <a-row>
                  服务营养师： {{ rdv.nick_name }}
                </a-row>
                <a-row>
                  预约客户： {{ rdv.patient_name }}
                </a-row>
                <a-row>
                  预约时间： {{ rdv.appoint_date !== undefined ? rdv.appoint_date.substr(0,10) : ''}}
                </a-row>
                <a-row>
                  预约序号： {{ rdv.appoint_seq}}
                </a-row>
                <a-row>
                  当前状态：
                  <span
                    :class="rdv.status < 50 ? 'font-error' : 'font-success'"
                    class="font-weight"
                  >
                    {{ 'client_appointment.status' | showText(rdv.status.toString()) }}
                  </span>
                </a-row>
                <a-row>
                  备注： {{ rdv.remarks }}
                </a-row>
              </a-col>
              <a-col
                :span="6"
                class="text-right"
              >
                <div>
                  <a-button
                    type="primary"
                    icon="solution"
                    size="small"
                    @click="clickSetRecord(rdv)"
                  >问诊</a-button>
                </div>
              </a-col>
            </a-row>

            <div
              @click="clickLoadMore"
              v-if="idx >= recordData.length - 1"
              class="more-div"
            >
              <a-divider>{{ page > lastPage ? '下面没有啦~~' : '点击加载更多'}}</a-divider>
            </div>
            <a-divider v-else />
          </div>
        </div>
        <a-empty
          style="margin-top:30%;"
          v-else
          description="暂无相关数据"
        />
      </a-spin>
    </a-drawer>
  </div>
</template>

<script>
import { getClientAppointmentList } from '@/api/dms/clientAppointment'

export default ({
  name: 'ClientAppointmentDrawer',
  data () {
    return {
      recordDrawerVisible: false,
      recordDrawerSpinning: false,
      recordData: [],
      pageSize: 10,
      page: 1,
      lastPage: 1,
      cuRecord: {}
    }
  },
  created () {
  },
  methods: {
    closeRecordDrawer () {
      this.recordDrawerVisible = false
      this.page = 1
      this.lastPage = 1
      this.recordData = []
    },
    showDrawer (paramData) {
      this.recordDrawerVisible = true
      this.recordDrawerSpinning = true
      this.$nextTick(() => {
        if (paramData.service_user !== undefined && paramData.service_user > 0) {
          // 根据企业获取企业角色
          this.cuRecord = paramData
          this.page = 1
          this.lastPage = 1
          this.recordData = []
          this.loadAppointRecordData()
        } else {
          this.modalVisible1 = false
          this.$message.error('请确认当前医生！')
        }
      })
    },
    loadAppointRecordData () {
      console.log(this.cuRecord)
      getClientAppointmentList(Object.assign({ page: this.page, pageSize: this.pageSize, join: ['patient', 'user'], orderBy: ['health_client_appointments.appoint_date', 'desc'] }, this.cuRecord)).then((res2) => {
        console.log(res2)
        if (res2.status !== undefined && res2.status === 1) {
          this.recordData = this.recordData.concat(res2.data)
          this.lastPage = res2.last_page
          this.page++
        }
        this.recordDrawerSpinning = false
      })
    },
    clickLoadMore () {
      if (this.page > this.lastPage) {
        this.$message.error('下面没有数据啦~~！')
        return false
      }
      this.recordDrawerSpinning = true
      this.loadAppointRecordData()
    },
    clickSetRecord (record) {
      let recordParam = { patient: record.patient_id, name: record.patient_name, appoint: record.id }
      sessionStorage.setItem('HisPatients.setServiceRecord', JSON.stringify(recordParam))
      this.$router.push({ path: '/HisPatients/setServiceRecord' })
    }
  }
})

</script>

<style lang="scss" scoped>
.more-div {
  cursor: pointer;
}
#drawer-body-div {
  .text-collapse {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
</style>