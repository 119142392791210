<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :body-style="{padding: '24px 32px'}"
        :bordered="false"
      >
        <div>
          <a-icon
            title="返回列表页"
            class="back-page-icon"
            type="arrow-left"
            @click="$router.go(-1)"
          />
        </div>
        <a-divider style="margin-top: 16px;" />

        <a-form
          @submit="handleSubmit"
          :form="addShopGoodsSkuForm"
        >
          <div class="title">商品基础信息</div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="24"
              :md="24"
              :sm="24"
            >
              <a-form-item
                label="商城商品名称"
                :labelCol="{ md: 3 }"
                :wrapperCol="{ md: 20 }"
              >
                <a-select
                  show-search
                  optionFilterProp="children"
                  :filter-option="goodsFilterOption"
                  placeholder="请选择可售货品"
                  @change="emGoodsChange"
                  v-decorator="[
                    'emall_goods_id',
                    { initialValue:initEmallGoodsId,rules: [{ required: true, message: '请选择商城商品名称' }] },
                  ]"
                >
                  <a-select-option
                    v-for="(g1,i1) in emGoodsListData"
                    :key="i1"
                    :value="g1.value"
                    :title="g1.label+' '+g1.label2"
                  >
                    <span>{{ g1.label }} </span>
                    <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g1.label2 }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="title">商品SKU信息
            <span
              v-if="sameOriSkuNum > 0"
              class="font-error"
            >【当前商品已有SKU信息 {{ sameOriSkuNum }} 个！】</span>
          </div>
          <a-row
            class="form-row"
            :gutter="16"
          >
            <a-col
              :lg="12"
              :md="12"
              :sm="24"
            >
              <a-form-item
                label="规格参数"
                :labelCol="{ md: 6 }"
                :wrapperCol="{ md: 16 }"
                help="建议选择规格参数不超过 3 个！"
                required
              >
                <a-select
                  show-search
                  :filter-option="specFilterOption"
                  mode="multiple"
                  :maxTagCount="3"
                  placeholder="请选择规格参数"
                  :options="specListData"
                  @change="chooseSpec"
                  v-decorator="[
                    'choose_specs',
                    { rules: [{ required: true, message: '请选择规格参数' }] },
                  ]"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-spin
            tip="请先选择商城商品和规格参数！"
            size="large"
            :spinning="spinning2"
          >
            <a-icon
              slot="indicator"
              type="warning"
            />
            <a-table
              :columns="columns"
              :dataSource="data"
              :pagination="false"
              rowKey="key"
            >
              <template
                slot="spec"
                slot-scope="text, record"
              >
                <a-select
                  :allowClear="true"
                  style="margin: -5px 0;width:100%;"
                  placeholder="请选择商品规格"
                  show-search
                  optionFilterProp="children"
                  :filter-option="specFilterOption"
                  @change="(v) => selectColData(v, record.key,'spec')"
                >
                  <a-select-option
                    v-for="(svv,si1) in specValDefData"
                    :key="si1"
                    :value="svv.value"
                    :title="svv.label"
                    :disabled="svv.disabled"
                  >
                    {{ svv.label }}
                  </a-select-option>
                </a-select>
              </template>
              <template
                slot="goods_id"
                slot-scope="text, record"
              >
                <a-select
                  style="margin: -5px 0;width:100%;"
                  placeholder="请选择SKU商品"
                  show-search
                  optionFilterProp="children"
                  :filter-option="goodsFilterOption"
                  @change="(v) => selectColData(v, record.key,'goods_id')"
                >
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList1.length > 0"
                    >
                      <a-icon type="home" /> 自有货品
                    </span>
                    <a-select-option
                      v-for="(g1,i1) in goodsList1"
                      :key="i1"
                      :value="g1.value"
                      :disabled="g1.disabled"
                    >
                      <span>{{ g1.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g1.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group>
                    <span
                      slot="label"
                      v-if="goodsList2.length > 0"
                    >
                      <a-icon type="branches" /> 代理货品
                    </span>
                    <a-select-option
                      v-for="(g2,i2) in goodsList2"
                      :key="i2"
                      :value="g2.value"
                      :disabled="g2.disabled"
                    >
                      <span>{{ g2.label }}</span>
                      <span style="font-size:10px;color:rgba(0, 0, 0, 0.45)">{{ g2.label2 }}</span>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </template>

              <template
                slot="cost_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="成本价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'cost_price')"
                />
              </template>

              <template
                slot="unit_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="标价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'unit_price')"
                />
              </template>

              <template
                slot="promotion_unit_price"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="实际售价"
                  :min="0"
                  :step="0.01"
                  @change="(v) => selectColData(v, record.key,'promotion_unit_price')"
                />
              </template>

              <template
                slot="inventory"
                slot-scope="text, record"
              >
                <a-input-number
                  style="margin: -5px 0;width:100%;"
                  placeholder="库存数"
                  :min="0"
                  :step="1"
                  @change="(v) => selectColData(v, record.key,'inventory')"
                />
              </template>

              <template
                slot="operation"
                slot-scope="text, record"
              >
                <a-popconfirm
                  v-if="data.length > 0"
                  title="是否要删除此行？"
                  @confirm="removeMember(record.key)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <a-button
              style="width: 100%; margin-top: 16px; margin-bottom: 8px"
              type="dashed"
              icon="plus"
              @click="newMember"
            >
              继续添加商品SKU
            </a-button>
          </a-spin>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
            class="mt-20"
          >
            <a-button
              htmlType="submit"
              type="primary"
            >确认保存</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListSpecByEnterpriseForGoods } from '@/api/dms/emallGoodSpec'
import { addEmallGoodsSku, getSkuInfoByEmallGood } from '@/api/dms/emallGoodSku'
import { getEmallGoodListByEnt } from '@/api/dms/emallGood'
import { getEnterpriseGoodsList } from '@/api/dms/enterpriseGood'

export default {
  name: 'AddShopGoodsSkuForm',
  data () {
    return {
      spinning: false,
      spinning2: true,
      tip: '加载中...',
      addShopGoodsSkuForm: this.$form.createForm(this),
      specListData: [],
      specValDefData: [],
      columns: [
        {
          title: '商品规格',
          dataIndex: 'spec',
          width: '20%',
          ellipsis: true,
          scopedSlots: { customRender: 'spec' }
        },
        {
          title: 'SKU商品',
          dataIndex: 'goods_id',
          width: '26%',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_id' }
        },
        {
          title: '成本价',
          dataIndex: 'cost_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'cost_price' }
        },
        {
          title: '标价',
          dataIndex: 'unit_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'unit_price' }
        },
        {
          title: '实际售价',
          dataIndex: 'promotion_unit_price',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'promotion_unit_price' }
        },
        {
          title: '库存数',
          dataIndex: 'inventory',
          width: '12%',
          ellipsis: true,
          scopedSlots: { customRender: 'inventory' }
        },
        {
          title: '操作',
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      data: [
        // {
        //   key: '1',
        //   spec: '',
        //   goods_id: '',
        //   cost_price: '',
        //   unit_price: '',
        //   promotion_unit_price: '',
        //   inventory: ''
        // }
      ],
      goodsList1: [],
      goodsList2: [],
      emGoodsListData: [],
      chooseEmg: '',
      chooseSpecVal: [],
      emgOriSkus: [],
      sameOriSkuNum: 0,
      initEmallGoodsId: undefined
    }
  },
  created () {
    this.spinning = true
    if (this.$route.query.keyword !== undefined && this.$route.query.keyword !== '') {
      const Base64 = require('js-base64').Base64
      let keyword = Base64.decode(this.$route.query.keyword)
      keyword = keyword.split('(=)')
      if (keyword[1] !== undefined && keyword[1] !== '') {
        this.initEmallGoodsId = parseInt(keyword[1])
      }
    }
    this.addShopGoodsSkuForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
    this.addShopGoodsSkuForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
    this.loadSelectData()
  },
  methods: {
    loadSelectData () {
      this.tip = '加载中...'
      // 获取企业货品数据
      getEnterpriseGoodsList({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          if (res.data.baseGoods !== undefined && res.data.baseGoods.length > 0) {
            this.goodsList1 = res.data.baseGoods
          }
          if (res.data.contractGoods !== undefined && res.data.contractGoods.length > 0) {
            this.goodsList2 = res.data.contractGoods
          }
          if (this.goodsList1.length < 1 && this.goodsList2.length < 1) {
            this.$message.error('企业暂无货品信息！')
          }
        } else {
          this.$message.error(res.msg || '企业货品信息加载失败！请刷新后重试！')
        }
      })
      // 商城货品下拉
      getEmallGoodListByEnt({ enterprise: this.$store.getters.userInfo.user.target_enterprise, condition: [['emall_goods.status', '>', -10]] }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.emGoodsListData = res.data
        }
      })
      // 规格下拉
      selectListSpecByEnterpriseForGoods({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.specListData = res.data
        }
        this.spinning = false
        this.tip = '处理中...'
      })
    },
    // 表单提交
    handleSubmit (e) {
      const that = this
      e.preventDefault()
      if (this.data.length > 0) {
        let checkAuth = true
        if (this.data.length < 1) {
          this.$error({
            title: '请确认选择商品SKU信息！'
          })
          return false
        }
        for (const di in this.data) {
          if (this.data[di].spec === undefined || this.data[di].spec === null || this.data[di].spec === '') {
            checkAuth = false
            this.$error({
              title: '请确认选择第' + (parseInt(di) + 1) + '行的商品规格！'
            })
            return false
          }
          if (this.data[di].goods_id === undefined || this.data[di].goods_id === null || this.data[di].goods_id === '') {
            checkAuth = false
            this.$error({
              title: '请确认选择第' + (parseInt(di) + 1) + '行的SKU商品！'
            })
            return false
          }
          if (this.data[di].cost_price === undefined || this.data[di].cost_price === null || this.data[di].cost_price === '' || this.data[di].cost_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的成本价且不小于0！'
            })
            return false
          }
          if (this.data[di].unit_price === undefined || this.data[di].unit_price === null || this.data[di].unit_price === '' || this.data[di].unit_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的标价且不小于0！'
            })
            return false
          }
          if (this.data[di].promotion_unit_price === undefined || this.data[di].promotion_unit_price === null || this.data[di].promotion_unit_price === '' || this.data[di].promotion_unit_price < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的实际售价且不小于0！'
            })
            return false
          }
          if (this.data[di].inventory === undefined || this.data[di].inventory === null || this.data[di].inventory === '' || this.data[di].inventory < 0) {
            checkAuth = false
            this.$error({
              title: '请确认填写第' + (parseInt(di) + 1) + '行的库存数且不小于0！'
            })
            return false
          }
        }
        if (!checkAuth) {
          return false
        }
      }

      this.addShopGoodsSkuForm.validateFields((err, values) => {
        if (!err) {
          this.spinning = true
          values['goods'] = this.data
          addEmallGoodsSku(values).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              that.$confirm({
                title: '保存成功！',
                content: false,
                cancelText: '留在当前页面',
                okText: '返回商品SKU信息管理列表',
                icon: () => {
                  return that.$createElement('a-icon', {
                    props: { type: 'check-circle' },
                    style: { color: '#52c41a' }
                  })
                },
                onOk () {
                  return that.$router.go(-1)
                },
                onCancel () {
                  that.spinning = true
                  that.addShopGoodsSkuForm.resetFields()
                  that.spinning2 = true
                  that.data = []
                  that.goodsList1 = []
                  that.goodsList2 = []
                  that.specValDefData = []
                  that.emgOriSkus = []
                  that.emGoodsListData = []
                  that.chooseEmg = ''
                  that.chooseSpecVal = []
                  that.sameOriSkuNum = 0
                  that.loadSelectData()
                }
              })
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinning = false
          })
        }
      })
    },
    newMember () {
      const length = this.data.length
      // 第一个的时候来检测已有的sku禁用掉 必须条件：specValDefData、emgOriSkus都有数据，且对应的值长度一样
      if (length === 0) {
        // 当前组成的sku值长度
        this.specSelectIsCheck()
      }
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        spec: '',
        goods_id: '',
        cost_price: '',
        unit_price: '',
        promotion_unit_price: '',
        inventory: ''
      })
    },
    removeMember (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    selectColData (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
      // 已选规格值则禁用
      if (column === 'spec') {
        this.specSelectIsCheck()
      }
    },
    specSelectIsCheck () {
      this.specValDefData.map((v) => {
        let isCheck = false
        this.data.map((v2) => {
          if (v2.spec === v.value) {
            isCheck = true
          }
        })
        if (this.specValDefData.length > 0 && this.emgOriSkus.length > 0 && this.specValDefData[0].value.split('@').length === this.emgOriSkus[0].split('@').length && this.emgOriSkus.indexOf(v.value) >= 0) {
          isCheck = true
          this.sameOriSkuNum += 1
        }
        v.disabled = isCheck
      })
    },
    chooseSpec (v) {
      this.chooseSpecVal = v
      this.loadSpecDataShow()
    },
    loadSpecDataShow () {
      this.sameOriSkuNum = 0
      // 重置表格
      this.data = []
      this.specValDefData = []
      if (this.chooseSpecVal.length !== undefined && this.chooseSpecVal.length > 0 && this.chooseEmg > 0) {
        this.spinning = true
        this.tip = '加载中...'
        // 有数据，显示表格
        this.spinning2 = false
        this.specListData.forEach((sv, si) => {
          if (this.chooseSpecVal.indexOf(sv.value) >= 0) {
            this.specListDataCaculate(sv)
          }
          if ((si + 1) >= this.specListData.length) {
            this.spinning = false
            this.tip = '处理中...'
          }
        })
      } else {
        // 没有数据遮住表格
        this.spinning2 = true
      }
    },
    specListDataCaculate (cuArr) {
      const newSpecVal = []
      if (cuArr.spec_vals.length === undefined || cuArr.spec_vals.length < 1) {
        return false
      }
      if (this.specValDefData.length !== undefined && this.specValDefData.length > 0) {
        this.specValDefData.map(v => {
          cuArr.spec_vals.map(cv => {
            newSpecVal.push({ value: v.value + '@' + [cuArr.value, cv.id].join(','), label: v.label + ',' + cuArr.label + '(' + cv.spec_val + ')', disabled: false })
          })
        })
      } else {
        cuArr.spec_vals.map(cv => {
          newSpecVal.push({ value: [cuArr.value, cv.id].join(','), label: cuArr.label + '(' + cv.spec_val + ')', disabled: false })
        })
      }
      this.specValDefData = newSpecVal
    },
    goodsFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].children !== undefined && option.componentOptions.children[0].children[0] !== undefined && option.componentOptions.children[0].children[0].text !== undefined) {
        return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
    specFilterOption (input, option) {
      if (option.componentOptions.children[0] !== undefined && option.componentOptions.children[0].text !== undefined) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } else {
        return false
      }
    },
    emGoodsChange (e) {
      this.spinning = true
      this.tip = '加载中...'
      getSkuInfoByEmallGood({ emg: e, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
        if (res.status !== undefined && res.status === 1) {
          this.emgOriSkus = res.data
        }
        this.spinning = false
        this.tip = '处理中...'
        this.chooseEmg = e
        this.loadSpecDataShow()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
