<template>
  <div id="settings">
    <!-- Header Background Image -->
    <div
      class="profile-nav-bg"
      style="background-image: url('/images/bg-patient.jpg')"
    ></div>
    <!-- / Header Background Image -->

    <!-- User Profile Card -->
    <a-card
      :bordered="false"
      class="card-profile-head"
      :bodyStyle="{padding: 0,}"
    >
      <template #title>
        <a-row
          type="flex"
          align="middle"
        >
          <a-col
            :span="24"
            :md="10"
            class="col-info"
          >
            <a-avatar
              :size="74"
              shape="square"
              :src="'/images/avatar-' +  patientInfo.gender  + '.jpg'"
            />
            <div class="avatar-info">
              <h4 class="font-semibold m-0">{{ patientInfo.name }}</h4>
              <p>{{ 'ryx.gender' | showText(patientInfo.gender) }} / 出生日期：{{ patientInfo.birth_date }} / 联系电话：{{ patientInfo.contact_phone }}</p>
            </div>
          </a-col>
          <a-col
            :span="24"
            :md="6"
            class="col-info"
          >
            <div class="avatar-info">
              <p class="mt-15">客户编号：{{ patientInfo.patient_no }}</p>
              <p>所属机构：{{ patientInfo.enterprise_name }}</p>
            </div>
          </a-col>
          <a-col
            :span="24"
            :md="8"
          >
            <div class="avatar-info">
              <p>客户画像：
                <a-tag
                  color="orange"
                  v-for="tag in patientInfo.his_patient_tag"
                  :key="tag.tag_id"
                >
                  {{tag.tag_text}}
                </a-tag>
              </p>
            </div>
            <!-- <a-radio-group
              v-model="profileHeaderBtns"
              size="small"
              @change="changeHeaderRadio"
            >
              <a-radio-button value="profile">个人资料</a-radio-button>
              <a-radio-button value="record">诊疗记录</a-radio-button>
              <a-radio-button value="measure">身体状况</a-radio-button>
              <a-radio-button value="foodmenu">个人食谱</a-radio-button>
            </a-radio-group> -->
          </a-col>
        </a-row>
      </template>
    </a-card>
    <!-- User Profile Card -->

    <a-tabs
      class="tabs-sliding"
      v-model="profileHeaderBtns"
    >
      <!-- Default Tab ( 个人资料 ) -->
      <a-tab-pane
        key="profile"
        tab="个人资料"
        :disabled="tabDisabled"
      >
        <a-row
          type="flex"
          :gutter="24"
        >
          <!-- 生活习惯 Card -->
          <a-col
            :span="24"
            :md="8"
            class="mb-24"
          >
            <CardPersonalInfo :parentParams="params"></CardPersonalInfo>
          </a-col>
          <!-- / 生活习惯 Card -->

          <!-- 饮食习惯 Card -->
          <a-col
            :span="24"
            :md="8"
            class="mb-24"
          >
            <CardDietaryInfo :parentParams="params"></CardDietaryInfo>
          </a-col>
          <!-- / 饮食习惯 Card -->

          <!-- 病史及用药信息 Card -->
          <a-col
            :span="24"
            :md="8"
            class="mb-24"
          >
            <CardMedicalInfo :parentParams="params"></CardMedicalInfo>
          </a-col>
          <!-- / 病史及用药信息 Card -->
        </a-row>
      </a-tab-pane>
      <!-- / Default Tab ( 个人资料 ) -->

      <!-- 诊疗记录 Tab ) -->
      <a-tab-pane
        key="record"
        tab="诊疗记录"
        :disabled="tabDisabled"
      >

        <a-row
          type="flex"
          :gutter="24"
        >
          <!-- 诊疗列表 Column -->
          <a-col
            :span="24"
            :md="5"
            class="mb-24"
          >
            <a-row
              type="flex"
              :gutter="24"
            >
              <a-col :span="24">
                <!-- 营养诊疗记录列表 Card -->
                <card-service-record-all
                  :parentParams="params"
                  @changeServiceRecord="changeServiceRecord"
                ></card-service-record-all>
                <!-- / 营养诊疗记录列表 Card -->
              </a-col>
            </a-row>
          </a-col>
          <!-- / 诊疗列表 Column -->

          <!-- 最新诊疗记录 Column -->
          <a-col
            :span="24"
            :md="19"
            class="mb-24 kanban-page"
          >
            <!-- 最新诊疗记录 Card -->
            <card-service-record-one :currentServiceRecord="serviceRecord"></card-service-record-one>
            <!-- / 最新诊疗记录 Card -->

          </a-col>
          <!-- / 最新诊疗记录 Column -->
        </a-row>

      </a-tab-pane>
      <!-- / 诊疗记录 Tab ) -->

      <!-- 身体状况 Tab -->
      <a-tab-pane
        key="measure"
        tab="身体状况"
        :disabled="tabDisabled"
      >

        <a-row
          type="flex"
          :gutter="24"
        >
          <!-- 检测数据 Column -->
          <a-col
            :span="24"
            :md="6"
            class="mb-24"
          >
            <!-- 最新检测数据 Card -->
            <card-measure-info-all :parentParams="params"></card-measure-info-all>
            <!-- / 最新检测数据 Card -->
          </a-col>
          <!-- / 检测数据 Column -->

          <!-- 检测历史 Column -->
          <a-col
            :span="24"
            :md="18"
            class="mb-24"
          >
            <!-- 检测历史数据 Card -->
            <card-measure-info-history
              ref="CardMeasureInfoHistory"
              :parentParams="params"
            ></card-measure-info-history>
            <!-- / 检测历史数据 Card -->

          </a-col>
          <!-- / 检测历史 Column -->
        </a-row>

      </a-tab-pane>
      <!-- / 身体状况 Tab -->

      <!-- 调查表 Tab -->
      <a-tab-pane
        key="survey"
        tab="调查表"
        :disabled="tabDisabled"
      >

        <a-row
          type="flex"
          :gutter="24"
        >
          <a-col
            :span="24"
            :md="24"
            class="mb-24"
          >
            <!-- 调查表填报情况 Card -->
            <CardPersonalSurvey :parentParams="params" />
            <!-- / 调查表填报情况 Card -->

          </a-col>
        </a-row>

      </a-tab-pane>
      <!-- / 调查表 Tab -->

      <!-- Food Menu Tab -->
      <a-tab-pane
        key="foodmenu"
        tab="个人食谱"
        :disabled="tabDisabled"
      >

        <!-- 客户定制食谱 Card -->
        <card-foodmenu-all :parentParams="params"></card-foodmenu-all>
        <!-- / 客户定制食谱 Card -->

      </a-tab-pane>
      <!-- / Food Menu Tab -->

    </a-tabs>

  </div>
</template>

<script>
import { getPatientInfo } from '@/api/dms/patient'
import { CardMeasureInfoAll, CardMeasureInfoHistory, CardServiceRecordAll, CardServiceRecordOne, CardPersonalInfo, CardMedicalInfo, CardDietaryInfo, CardFoodmenuAll, CardPersonalSurvey } from "@/components/PatientCards"


export default {
  name: 'viewPatient',
  props: ['navbarFixed'],
  components: {
    CardMeasureInfoAll,
    CardServiceRecordOne,
    CardMeasureInfoHistory,
    CardServiceRecordAll,
    CardPersonalInfo,
    CardMedicalInfo,
    CardDietaryInfo,
    CardFoodmenuAll,
    CardPersonalSurvey
  },
  data () {
    return {
      profileHeaderBtns: 'profile',
      serviceRecord: {},
      patientInfo: {},
      tabDisabled: true
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.viewPatient'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    // 获取数据
    this.loadPatientInfo()
  },
  methods: {
    loadPatientInfo () {
      getPatientInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient) {
          this.patientInfo = res.data
          this.tabDisabled = false
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          return this.$router.go(-1)
        }
      }).catch((e) => {
        return this.$router.go(-1)
      })
    },
    // changeHeaderRadio (e) {
    //   this.profileHeaderBtns = e.target.value
    // },
    changeServiceRecord (serviceRecord) {
      this.serviceRecord = serviceRecord
    }
  }
};
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }
  .step-box {
    padding: 20px 32px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ghost-card {
    opacity: 0.5;
    background: #f7fafc;
    border: 1px solid #4299e1;
  }
}
</style>