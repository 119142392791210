import request from '@/utils/request'

const apiUrls = {
    savePatientMenuFoodInfo: '/HealthPatientFoodMenus/savePatientMenuFoodInfo',
    saveOneFoodMenuDay: '/HealthPatientFoodMenus/saveOneFoodMenuDay',
    removeOneFoodMenuDay: '/HealthPatientFoodMenus/removeOneFoodMenuDay',
    removeOneFoodMenuDayRecipe: '/HealthPatientFoodMenus/removeOneFoodMenuDayRecipe',
    listPatientFoodMenu: '/HealthPatientFoodMenus/listPatientFoodMenu',
    viewPatientFoodMenu: '/HealthPatientFoodMenus/viewPatientFoodMenu'
}

export function savePatientMenuFoodInfo (data) {
    return request({
        url: apiUrls.savePatientMenuFoodInfo,
        method: 'post',
        data
    })
}

export function saveOneFoodMenuDay (data) {
    return request({
        url: apiUrls.saveOneFoodMenuDay,
        method: 'post',
        data
    })
}

export function removeOneFoodMenuDay (data) {
    return request({
        url: apiUrls.removeOneFoodMenuDay,
        method: 'post',
        data
    })
}

export function removeOneFoodMenuDayRecipe (data) {
    return request({
        url: apiUrls.removeOneFoodMenuDayRecipe,
        method: 'post',
        data
    })
}

export function listPatientFoodMenu (parameter) {
    return request({
      url: apiUrls.listPatientFoodMenu,
      method: 'get',
      params: parameter
    })
}

export function viewPatientFoodMenu (parameter) {
    return request({
      url: apiUrls.viewPatientFoodMenu,
      method: 'get',
      params: parameter
    })
}
