<template>
  <a-drawer
    width="600"
    placement="right"
    :closable="true"
    :visible="surveyViewDrawerVisibleCu"
    title="问卷调查填写情况查看"
    @close="closeSurveyInputDrawer"
  >
    <a-spin
      tip="加载中..."
      size="large"
      :spinning="surveyViewSpinning"
    >
      <div v-if="surveyInfo.id !== undefined">
        <h6 class="font-semibold mx-0 mt-0 mb-30 text-center">{{ surveyInfo.title }}</h6>
        <form-create
          v-model="cformInputApi"
          :rule="cformInputRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-empty
        style="margin-top:30%;"
        v-else
        description="暂无相关数据"
        class="mb-50"
      />

      <a-row>
        <a-col
          :offset="11"
          :span="13"
        >
          <a-button
            @click="closeSurveyInputDrawer()"
            class="mr-10"
          >关闭</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
import { setRuleByFormArr } from '@/utils/formCreateRuleDeal'
import { getSurveyResponseDetail } from '@/api/dms/surveyResponse'

export default ({
  name: 'SurveyResponseView',
  data () {
    return {
      surveyViewDrawerVisibleCu: false,
      surveyViewSpinning: false,
      cformInputApi: {},
      cformInputRule: [],
      cformOption: {
        form: {
          layout: 'vertical'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 18 }
        },
        submitBtn: false
      },
      surveyInfo: {}
    }
  },
  created () {
  },
  methods: {
    showDrawer (paramData) {
      this.surveyViewDrawerVisibleCu = true
      if (paramData.id === undefined || paramData.id < 1) {
        this.$message.error('请确认选择问卷填报数据！')
        return false
      }
      this.surveyViewSpinning = true
      getSurveyResponseDetail({ surveyRes: paramData.id }).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          this.surveyInfo = res.data
          let inputValArr = { contact_name: res.data.contact_name, contact_phone: res.data.contact_phone }
          if (res.data.misc_survey_response_item !== undefined) {
            res.data.misc_survey_response_item.map(rpitv => {
              if (rpitv.question_type === 'checkbox') {
                inputValArr[rpitv.question_id] = rpitv.answer.split(',')
              } else if (rpitv.question_type === 'a-radio-group') {
                inputValArr[rpitv.question_id] = parseInt(rpitv.answer)
              }
              else {
                inputValArr[rpitv.question_id] = rpitv.answer
              }
            })
          }
          const oriRule = res.data.form_rule !== undefined && res.data.form_rule !== null && res.data.form_rule !== '' ? JSON.parse(res.data.form_rule) : []
          // 获取rule数据
          const dealRule = setRuleByFormArr(oriRule)
          this.cformInputRule = dealRule.ruleArr
          const timer = setTimeout(() => {
            this.cformInputApi.setValue(inputValArr)
            clearTimeout(timer)
            this.surveyViewSpinning = false
          }, 1000)
        } else {
          this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
          this.closeSurveyInputDrawer()
          this.surveyViewSpinning = false
        }
      })
    },
    closeSurveyInputDrawer () {
      this.surveyInfo = {}
      this.cformInputRule = []
      this.surveyViewDrawerVisibleCu = false
    }
  }
})

</script>

<style lang="scss" scoped>
</style>