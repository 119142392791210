export function checkMobile (rule, value, callback) {
  // 验证规则：区号+号码，区号以0开头，3位或4位 号码由7位或8位数字组成 区号与号码之间可以无连接符，也可以“-”连接
  const reg1 = /^0\d{2,3}-?\d{7,8}$/
  // 11位数字，以1开头
  const reg2 = /^1\d{10}$/
  let ct
  if (value && !(reg1.test(value) || reg2.test(value))) {
    ct = '请输入正确的电话或手机号！'
  }
  return callback(ct)
}

export function checkUserName (rule, value, callback) {
  // 验证规则：字母、数字、下划线组成，字母开头，6-50位
  const reg1 = /^[a-zA-z]+\.?\w{1,}$/
  let ct
  if (value && (!reg1.test(value) || value.length > 50)) {
    ct = '请以字母开头且为字母、数字、下划线组成的6~50位字符！'
  }
  return callback(ct)
}

export function checkEmail (rule, value, callback) {
  const reg1 = /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/
  let ct
  if (value && !reg1.test(value)) {
    ct = '请填写正确的邮箱地址！'
  }
  return callback(ct)
}
