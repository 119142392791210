<template>
  <AddFoodRecipe />
</template>

<script>
import { AddFoodRecipe } from '@/components/FoodRecipes'

export default {
  name: 'AddFoodRecipePage',
  components: {
    AddFoodRecipe,
  },
  data () {
    return {

    }
  },
  filters: {

  },
  created () {

  },
  methods: {

  }
}
</script>
