import storage from 'store'
import defaultSettings from '@/config/defaultSettings'
import moment from 'moment'

const app = {
  state: {
    title: defaultSettings.title,
    company: defaultSettings.company,
    companyEng: defaultSettings.companyEng,
    support: defaultSettings.support,
    logoOnly: defaultSettings.logoOnly,
    mainIntro: defaultSettings.mainIntro,
    slogan: defaultSettings.slogan,
    year: moment().format('Y')
  },
  mutations: {
    title: (state, title) => {
      state.title = title
      storage.set('title', title)
    },
    year: (state, year) => {
      state.year = year
      storage.set('year', year)
    },
    company: (state, company) => {
      state.company = company
      storage.set('company', company)
    },
    companyEng: (state, companyEng) => {
      state.companyEng = companyEng
      storage.set('companyEng', companyEng)
    },
    support: (state, support) => {
      state.support = support
      storage.set('support', support)
    },
    mainIntro: (state, mainIntro) => {
      state.mainIntro = mainIntro
      storage.set('mainIntro', mainIntro)
    },
    slogan: (state, slogan) => {
      state.slogan = slogan
      storage.set('slogan', slogan)
    },
    logoOnly: (state, logoOnly) => {
      state.logoOnly = logoOnly
      storage.set('logoOnly', logoOnly)
    }
  },
  actions: {
  }
}

export default app
