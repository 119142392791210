<template>
  <a-modal
    v-model="modalVisible1"
    title="修改用户角色"
    okText="确认保存"
    :maskClosable="false"
    :okButtonProps="okButtonProps1"
    @ok="handleModalOk1"
    @cancel="closeModal"
  >
    <a-spin
      tip="处理中..."
      :spinning="spinningM1"
    >
      <form-create
        v-model="cformApi"
        :rule="cformRule"
        :option="cformOption"
      ></form-create>
    </a-spin>
  </a-modal>
</template>

<script>
import { changeRoles } from '@/api/dms/user'
import { selectListRole } from '@/api/dms/adminRole'

export default ({
  name: 'ChangeUserRoles',
  data () {
    return {
      spinningM1: false,
      modalVisible1: false,
      okButtonProps1: {},
      //实例对象
      cformApi: {},
      //表单生成规则
      cformRule: [
        {
          type: 'hidden',
          field: 'user'
        },
        {
          type: 'hidden',
          field: 'platform',
          value: this.$store.getters.userInfo.platform.id
        },
        {
          type: 'hidden',
          field: 'enterprise',
          value: this.$store.getters.userInfo.user.target_enterprise
        },
        {
          type: 'select',
          title: '用户角色',
          field: 'role_id',
          props: { mode: 'multiple', placeholder: '请选择用户角色', maxTagCount: 1, maxTagTextLength: 16, allowClear: true, showSearch: true, optionFilterProp: 'children' },
          options: [],
          validate: [
            { required: true, message: '请选择用户角色' }
          ]
        },
      ],
      //组件参数配置
      cformOption: {
        form: {
          layout: 'horizontal'
        },
        col: {
          span: 24
        },
        wrap: {
          labelCol: { span: 4 },
          wrapperCol: { span: 19 }
        },
        submitBtn: false
      },
      roleListData: []
    }
  },
  created () {
  },
  methods: {
    closeModal () {
      this.cformApi.clearValidateState()
      this.cformApi.resetFields()
      this.modalVisible1 = false
      this.spinningM1 = false
    },
    showModal (paramData) {
      this.modalVisible1 = true
      this.spinningM1 = true
      this.$nextTick(() => {
        if (paramData.user !== undefined && paramData.user > 0) {
          // 根据企业获取企业角色
          selectListRole({ plattype: this.$store.getters.userInfo.platform.id, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
            if (res.status !== undefined && res.status === 1) {
              this.roleListData = res.data
              this.cformApi.updateRules({ // 更新规则
                'role_id': { options: this.roleListData }
              })
              let oldRole = (paramData.role_id !== null && paramData.role_id !== '') ? paramData.role_id.split(',') : []
              if (oldRole.indexOf('1') >= 0) {
                oldRole.splice(oldRole.indexOf('1'), 1)
              }
              const initRole = []
              this.roleListData.map(rv => {
                if (oldRole.indexOf(rv.value.toString()) >= 0) {
                  initRole.push(rv.value.toString())
                }
              })
              this.cformApi.setValue({ user: paramData.user, role_id: initRole })
            } else {
              this.$message.error(res.msg || '获取可选角色失败！请刷新后重试！')
            }
            this.spinningM1 = false
          })
        } else {
          this.modalVisible1 = false
          this.$message.error('请确认当前选择用户！')
        }
      })
    },
    handleModalOk1 () {
      this.cformApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformApi.formData()
          this.spinningM1 = true
          this.okButtonProps1 = { props: { loading: true } }
          changeRoles(cuFormData).then((res) => {
            if (res.status !== undefined && res.status === 1) {
              this.closeModal()
              this.$emit('complete')
              this.$message.success('保存成功！')
            } else {
              this.$message.error(res.msg || '保存失败！请刷新后重试！')
            }
            this.spinningM1 = false
            this.okButtonProps1 = {}
          })
        } else {
          console.log(fail2)
          this.$message.error('请确认必填项填写完成!！')
        }
      })
    },
  }
})

</script>

<style lang="scss" scoped>
</style>